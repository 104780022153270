import { Trans } from 'react-i18next';
import { Link } from '@amzn/awsui-components-react';
import i18n from 'i18n';
import { clearAllNotifications } from 'store/notifications';
import { useAppDispatch } from 'store/utils/hooks';

export const CognitoThrottleNotificationContent = (): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Trans i18nKey={'errors.CognitoThrottle.notificationContent'}>
      Too many racers
      <Link
        color="inverted"
        onFollow={() => {
          dispatch(clearAllNotifications());
        }}
      >
        walkup url
      </Link>
    </Trans>
  );
};

export const COGNITO_THROTTLE_NOTIFICATION = {
  header: i18n.t('errors.CognitoThrottle.notificationHeader'),
  content: <CognitoThrottleNotificationContent />,
};
