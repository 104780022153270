import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Alert, Box, Button, Container, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';

import pageCatalog from 'pages/pageCatalog';
import { DISCORD_COMMUNITY } from 'common/constants/urls';
import './style.css';

export const ImproveModel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const gridDefinition = [{ colspan: { default: 12, s: 6 } }, { colspan: { default: 12, s: 6 } }];

  return (
    <SpaceBetween direction="vertical" size="m" data-test-id="improveModelRoot">
      <Grid gridDefinition={gridDefinition}>
        <Box variant="div">
          <Container
            data-analytics="learnFromExperts-Container"
            data-analytics-type="eventContext"
            className="infoContainer"
            header={
              <Header
                variant="h2"
                actions={
                  <Button
                    data-analytics="getTips-Button"
                    data-analytics-type="eventDetail"
                    iconName="external"
                    onClick={() => {
                      window.open('https://aws.amazon.com/deepracer/racing-tips/', '_blank');
                    }}
                  >
                    {t('ImproveModel.expertsContainerButton')}
                  </Button>
                }
              >
                {t('ImproveModel.expertsContainerHeader')}
              </Header>
            }
          >
            {t('ImproveModel.expertsContainerContent')}
          </Container>
        </Box>
        <Box variant="div">
          <Container
            data-analytics="learnFromCommunity-Container"
            data-analytics-type="eventContext"
            className="infoContainer"
            header={
              <Header
                variant="h2"
                actions={
                  <Button
                    data-analytics="joinDiscord-Button"
                    data-analytics-type="eventDetail"
                    iconName="external"
                    onClick={() => {
                      window.open(DISCORD_COMMUNITY, '_blank');
                    }}
                  >
                    {t('ImproveModel.communityContainerButton')}
                  </Button>
                }
              >
                {t('ImproveModel.communityContainerHeader')}
              </Header>
            }
          >
            {t('ImproveModel.communityContainerContent')}
          </Container>
        </Box>
      </Grid>
      <Alert
        data-analytics="cloneModel-Alert"
        data-analytics-type="eventContext"
        dismissAriaLabel="Close alert"
        action={
          <Button
            data-analytics="cloneModel-Button"
            data-analytics-type="eventDetail"
            onClick={() => history.push(pageCatalog.ModelsList.getPath())}
          >
            Choose a model
          </Button>
        }
        header="Clone your model"
      >
        {t('ImproveModel.cloneAlertContent')}
      </Alert>
    </SpaceBetween>
  );
};
