import { useTranslation } from 'react-i18next';

import { Header } from '@amzn/awsui-components-react';

import { getOptedInEsperanza } from 'store/profile';
import { HOME_ESPERANZA, HOME_NON_ESPERANZA } from 'common/constants';
import { InfoLink } from 'common/components/HelpPanel';
import { useAppSelector } from 'store/utils/hooks';

export const PageHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  return (
    <Header variant="h1" info={<InfoLink helpId={optedInEsperanza ? HOME_ESPERANZA : HOME_NON_ESPERANZA} />}>
      {t('Home')}
    </Header>
  );
};
