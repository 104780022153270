import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { emptyResource, LeaderboardSubmissionAction, LEADERBOARD_SUBMISSION_SLICE } from 'common/constants';
import { getModel } from 'services/deepRacerLite/modelApi';
import { getLatestUserSubmission } from 'services/deepRacerLite/submissionApi';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResource } from 'store/types';
import { addLoadingCasesWithNestedState } from 'store/utils/loadingCases';
import { DeepRacerLiteTypes } from 'types/DeepRacerLiteTypes';

const MODEL_NOT_FOUND_MESSAGE = 'Could not find a model with';
export const DELETED_MODEL = '2df843b2-5cfa-11ea-bc55-0242ac130003';

export interface ExtendedLatestUserSubmission extends DeepRacerLiteTypes.LeaderboardSubmission {
  ModelName: string;
}

export interface LeaderboardSubmissionState {
  latestUserSubmission: AsyncResource<ExtendedLatestUserSubmission>;
}

const initialState: LeaderboardSubmissionState = {
  latestUserSubmission: emptyResource,
};

export const fetchLatestUserSubmission = createAsyncThunk<ExtendedLatestUserSubmission, string>(
  LeaderboardSubmissionAction.GET_LATEST_LEADERBOARD_SUBMISSION,
  async (leaderboardArn: string, { dispatch }) => {
    try {
      const result = await getLatestUserSubmission(leaderboardArn);
      let userModelName = '';
      if (result?.LeaderboardSubmission?.ModelArn) {
        try {
          const model = await getModel(result?.LeaderboardSubmission?.ModelArn);
          userModelName = model?.Model?.ModelName;
        } catch (error: any) {
          if (error?.message.includes(MODEL_NOT_FOUND_MESSAGE)) {
            userModelName = DELETED_MODEL;
          }
        }
      }
      return {
        ...result.LeaderboardSubmission,
        ModelName: userModelName,
      };
    } catch (error: any) {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      throw error;
    }
  }
);

export const leaderboardSubmissionSlice = createSlice({
  name: LEADERBOARD_SUBMISSION_SLICE,
  initialState,
  reducers: {
    clearLatestUserSubmission: (state) => {
      state.latestUserSubmission = emptyResource;
    },
  },
  extraReducers: (builder) => {
    addLoadingCasesWithNestedState(
      builder,
      LeaderboardSubmissionAction.GET_LATEST_LEADERBOARD_SUBMISSION,
      LEADERBOARD_SUBMISSION_SLICE,
      'latestUserSubmission'
    );
  },
});

export const { clearLatestUserSubmission } = leaderboardSubmissionSlice.actions;

export default leaderboardSubmissionSlice.reducer;
