import { useFormikContext } from 'formik';

import Avatar from 'common/components/Avatar';
import { AvatarConfigSelectOptionMap, AvatarSize } from 'common/constants';
import { convertSelectOptionsToAvatarConfig } from 'common/utils/avatar';
import { useAppSelector } from 'store/utils/hooks';
import { getCountry } from 'store/profile';
import './styles.css';

interface AvatarConfigDialogAvatarProps {
  competitionCountryCode?: string | undefined | null;
}

const AvatarConfigDialogAvatar: React.FC<AvatarConfigDialogAvatarProps> = (props) => {
  const { values: formValues } = useFormikContext<AvatarConfigSelectOptionMap>();
  const countryCode = useAppSelector(getCountry);

  return (
    <div className="avatarConfigDialogAvatar">
      <Avatar
        data-test-id="avatarConfigDialogAvatar"
        avatarSize={AvatarSize.AVATAR_CONFIG_DIALOG}
        {...convertSelectOptionsToAvatarConfig(formValues)}
        countryCode={(props?.competitionCountryCode || countryCode) as string}
      />
    </div>
  );
};

export default AvatarConfigDialogAvatar;
