import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Link, Grid } from '@amzn/awsui-components-react';

import pageCatalog from 'pages/pageCatalog';
import { clearAllNotifications, displayNotification } from 'store/notifications';
import { useAppDispatch } from 'store/utils/hooks';
import {
  IntroToML,
  ModelTrainingQuota,
  PracticeML,
  PreSeasonRace,
  Resources,
  UpcomingRaceEvents,
  WhatsNew,
} from './common';
import { ScholarshipTracker } from './common/ScholarshipTracker';
import './styles.css';

export const gridDefinition3Layout = [
  { colspan: { default: 12, m: 6 } },
  { colspan: { default: 12, m: 6 } },
  { colspan: { default: 12, m: 6 } },
];

export const gridDefinition2Layout = [{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }];

export const StudentHome = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      displayNotification({
        content: (
          <>
            Interested in the 2024 AWS AI & ML Scholarship program? Opt into the program and begin completing
            prerequisites today.{' '}
            <Link color="inverted" onFollow={() => history.push(pageCatalog.Profile.getPath())}>
              Get Started
            </Link>
          </>
        ),
        dismissible: true,
      })
    );
    return () => {
      dispatch(clearAllNotifications());
    };
  }, [dispatch, history]);

  return (
    <Grid
      data-test-id="studentHomeRoot"
      gridDefinition={[...gridDefinition3Layout, ...gridDefinition2Layout, ...gridDefinition3Layout]}
    >
      <IntroToML />
      <ScholarshipTracker />
      <WhatsNew />
      <Resources />
      <PracticeML />
      <ModelTrainingQuota />
      <PreSeasonRace />
      <UpcomingRaceEvents />
    </Grid>
  );
};
