import { SpaceBetween, Box, ExpandableSection, Header, Link } from '@amzn/awsui-components-react';
import { ALPHA_DEEPRACER_EDUCATOR, PROD_DEEPRACER_EDUCATOR } from 'common/constants';
import { isProd } from 'pages/utils';
import { Trans, useTranslation } from 'react-i18next';
import { useListLeaderboards } from 'store/leaderboard';
import { DeepRacerLiteTypes } from 'types';
import LeaderboardCard from './LeaderboardCard';
import './styles.css';

function CommunityRaces() {
  const { t } = useTranslation('communityRaces', { keyPrefix: 'LandingPage' });
  const { data: leaderboardsData } = useListLeaderboards(DeepRacerLiteTypes.LeagueType.COMMUNITY_LITE);
  const leaderboards = leaderboardsData ? leaderboardsData.Leaderboards : [];
  const openLeaderboards = leaderboards
    ?.filter((leaderboard) => leaderboard?.Status !== DeepRacerLiteTypes.LeaderboardStatus.CLOSED)
    .sort((leaderboard1, leaderboard2) => leaderboard1?.CloseTime - leaderboard2?.CloseTime);
  const closedLeaderboards = leaderboards
    ?.filter((leaderboard) => leaderboard?.Status === DeepRacerLiteTypes.LeaderboardStatus.CLOSED)
    .sort((leaderboard1, leaderboard2) => leaderboard1?.CloseTime - leaderboard2?.CloseTime);
  return (
    <SpaceBetween size="xxl" data-test-id="communityLandingRoot">
      <ExpandableSection
        data-analytics="upcomingRaces-ExpandableSection"
        data-analytics-type="eventContext"
        variant="container"
        header={<Header variant="h2">{`Upcoming races (${openLeaderboards?.length})`}</Header>}
        defaultExpanded
      >
        <Box className="communityRaceCards">
          {openLeaderboards?.map((leaderboard) => (
            <LeaderboardCard key={leaderboard.Arn} leaderboard={leaderboard} />
          ))}
        </Box>
        {openLeaderboards?.length === 0 && (
          <Box variant="div" textAlign="center">
            <Box color="text-body-secondary" fontWeight="bold">
              {t('OpenEmptyState.mainText')}
            </Box>
            <Box color="text-status-inactive">
              <Trans
                components={[
                  <Link
                    data-analytics="createCommunityRaces-Link"
                    data-analytics-type="eventDetail"
                    variant="primary"
                    external
                    href={isProd() ? PROD_DEEPRACER_EDUCATOR : ALPHA_DEEPRACER_EDUCATOR}
                  />,
                ]}
              >
                {t('OpenEmptyState.secondaryText')}
              </Trans>
            </Box>
          </Box>
        )}
      </ExpandableSection>
      <ExpandableSection
        data-analytics="completedRaces-ExpandableSection"
        data-analytics-type="eventContext"
        variant="container"
        header={<Header variant="h2">{`Completed races (${closedLeaderboards?.length})`}</Header>}
      >
        <Box className="communityRaceCards">
          {closedLeaderboards?.map((leaderboard) => (
            <LeaderboardCard key={leaderboard.Arn} leaderboard={leaderboard} />
          ))}
        </Box>
        {closedLeaderboards?.length === 0 && (
          <Box variant="div" textAlign="center">
            <Box color="text-body-secondary" fontWeight="bold">
              {t('ClosedEmptyState.mainText')}
            </Box>
            <Box color="text-status-inactive">
              <Trans
                components={[
                  <Link
                    variant="primary"
                    external
                    href={isProd() ? PROD_DEEPRACER_EDUCATOR : ALPHA_DEEPRACER_EDUCATOR}
                  />,
                ]}
              >
                {t('ClosedEmptyState.secondaryText')}
              </Trans>
            </Box>
          </Box>
        )}
      </ExpandableSection>
    </SpaceBetween>
  );
}

export default CommunityRaces;
