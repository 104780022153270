import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LMS_TRACKER_SLICE, LmsTrackerAction, emptyResourceMap } from 'common/constants';
import * as LmsTrackerApi from 'services/deepRacerLite/lmsTrackerApi';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResourceMap } from 'store/types';
import { addLoadingCasesWithNestedMapState } from 'store/utils/loadingCases';
import { DeepRacerLiteTypes } from 'types';

interface LmsTrackerState {
  lmsTrackerModules: AsyncResourceMap<any>;
  lmsTrackerChapters: AsyncResourceMap<any>;
  lmsTrackerSections: AsyncResourceMap<any>;
}

const initialState: LmsTrackerState = {
  lmsTrackerModules: emptyResourceMap,
  lmsTrackerChapters: emptyResourceMap,
  lmsTrackerSections: emptyResourceMap,
};

// Handle error cases and dispatch notifications
const handleErrorCase = (error, dispatch) => {
  const errorMessage = error?.message || '';
  switch (errorMessage) {
    // Add cases to ignore
    case '':
    case 'Chapter already in completed status.':
    case 'Module already exists.':
      return;
    default:
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
  }
};

export const addLmsTrackerModule = createAsyncThunk<[DeepRacerLiteTypes.ModuleData], string>(
  LmsTrackerAction.ADD_LMS_TRACKER_MODULE,
  async (moduleId: string, { dispatch }) => {
    try {
      const result = await LmsTrackerApi.addLmsTrackerModule(moduleId);
      return [result.moduleData];
    } catch (error: any) {
      handleErrorCase(error, dispatch);
      throw error;
    }
  }
);

export const fetchLmsTrackerModules = createAsyncThunk<DeepRacerLiteTypes.ModuleData[]>(
  LmsTrackerAction.GET_LMS_TRACKER_MODULES,
  async (args, { dispatch }) => {
    try {
      const result = await LmsTrackerApi.getLmsTrackerModules();
      return result.modulesData;
    } catch (error: any) {
      handleErrorCase(error, dispatch);
      throw error;
    }
  }
);

export const addLmsTrackerChapter = createAsyncThunk<
  [DeepRacerLiteTypes.ChapterData],
  {
    moduleId: string;
    chapterId: string;
  }
>(LmsTrackerAction.ADD_LMS_TRACKER_CHAPTER, async ({ moduleId, chapterId }, { dispatch }) => {
  try {
    const result = await LmsTrackerApi.addLmsTrackerChapter(moduleId, chapterId);
    return [result.chapterData];
  } catch (error: any) {
    handleErrorCase(error, dispatch);
    throw error;
  }
});

export const updateLmsTrackerChapter = createAsyncThunk<
  [DeepRacerLiteTypes.ChapterData],
  {
    moduleId: string;
    chapterId: string;
  }
>(LmsTrackerAction.UPDATE_LMS_TRACKER_CHAPTER, async ({ moduleId, chapterId }, { dispatch }) => {
  try {
    const result = await LmsTrackerApi.updateLmsTrackerChapter(
      moduleId,
      chapterId,
      DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED
    );
    return [result.chapterData];
  } catch (error: any) {
    handleErrorCase(error, dispatch);
    throw error;
  }
});

export const fetchLmsTrackerChapters = createAsyncThunk<DeepRacerLiteTypes.ChapterData[], string>(
  LmsTrackerAction.GET_LMS_TRACKER_CHAPTERS,
  async (moduleId, { dispatch }) => {
    try {
      const result = await LmsTrackerApi.getLmsTrackerChapters(moduleId);
      return result.chaptersData;
    } catch (error: any) {
      handleErrorCase(error, dispatch);
      throw error;
    }
  }
);

export const addLmsTrackerSection = createAsyncThunk<
  [DeepRacerLiteTypes.SectionData],
  {
    moduleId: string;
    chapterId: string;
    sectionId: string;
  }
>(LmsTrackerAction.ADD_LMS_TRACKER_SECTION, async ({ moduleId, chapterId, sectionId }, { dispatch }) => {
  try {
    const result = await LmsTrackerApi.addLmsTrackerSection(moduleId, chapterId, sectionId);
    return [result.sectionData];
  } catch (error: any) {
    handleErrorCase(error, dispatch);
    throw error;
  }
});

export const updateLmsTrackerSection = createAsyncThunk<
  [DeepRacerLiteTypes.SectionData],
  {
    moduleId: string;
    chapterId: string;
    sectionId: string;
  }
>(LmsTrackerAction.UPDATE_LMS_TRACKER_SECTION, async ({ moduleId, chapterId, sectionId }, { dispatch }) => {
  try {
    const result = await LmsTrackerApi.updateLmsTrackerSection(
      moduleId,
      chapterId,
      sectionId,
      DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED
    );
    return [result.sectionData];
  } catch (error: any) {
    handleErrorCase(error, dispatch);
    throw error;
  }
});

export const fetchLmsTrackerSections = createAsyncThunk<
  DeepRacerLiteTypes.SectionData[],
  {
    moduleId: string;
    chapterId: string;
  }
>(LmsTrackerAction.GET_LMS_TRACKER_SECTIONS, async ({ moduleId, chapterId }, { dispatch }) => {
  try {
    const result = await LmsTrackerApi.getLmsTrackerSections(moduleId, chapterId);
    return result.sectionsData;
  } catch (error: any) {
    handleErrorCase(error, dispatch);
    throw error;
  }
});

export const lmsTrackerSlice = createSlice({
  name: LMS_TRACKER_SLICE,
  initialState,
  reducers: {
    clearLmsTrackerModules: (state) => {
      state.lmsTrackerModules = emptyResourceMap;
    },
    clearLmsTrackerChapters: (state) => {
      state.lmsTrackerChapters = emptyResourceMap;
    },
    clearLmsTrackerSections: (state) => {
      state.lmsTrackerSections = emptyResourceMap;
    },
  },
  extraReducers: (builder) => {
    // When logout successful reset LMS tracking
    builder.addCase('auth/logout/fulfilled', () => initialState);
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.GET_LMS_TRACKER_MODULES,
      LMS_TRACKER_SLICE,
      'lmsTrackerModules',
      'moduleId'
    );
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.ADD_LMS_TRACKER_MODULE,
      LMS_TRACKER_SLICE,
      'lmsTrackerModules',
      'moduleId'
    );
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.GET_LMS_TRACKER_CHAPTERS,
      LMS_TRACKER_SLICE,
      'lmsTrackerChapters',
      'chapterId'
    );
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.ADD_LMS_TRACKER_CHAPTER,
      LMS_TRACKER_SLICE,
      'lmsTrackerChapters',
      'chapterId'
    );
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.UPDATE_LMS_TRACKER_CHAPTER,
      LMS_TRACKER_SLICE,
      'lmsTrackerChapters',
      'chapterId'
    );
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.GET_LMS_TRACKER_SECTIONS,
      LMS_TRACKER_SLICE,
      'lmsTrackerSections',
      'sectionId'
    );
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.ADD_LMS_TRACKER_SECTION,
      LMS_TRACKER_SLICE,
      'lmsTrackerSections',
      'sectionId'
    );
    addLoadingCasesWithNestedMapState(
      builder,
      LmsTrackerAction.UPDATE_LMS_TRACKER_SECTION,
      LMS_TRACKER_SLICE,
      'lmsTrackerSections',
      'sectionId'
    );
  },
});

export const { clearLmsTrackerModules, clearLmsTrackerChapters, clearLmsTrackerSections } = lmsTrackerSlice.actions;

export default lmsTrackerSlice.reducer;
