import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import { APP_CONTENT_ID, MEDIUM_LOGO_WIDTH } from 'common/constants';
import pageCatalog from 'pages/pageCatalog';
import { useAppDispatch } from 'store/utils/hooks';
import { useHistory } from 'react-router-dom';
import { logout } from 'store/auth';
import { clearAllNotifications } from 'store/notifications';
import DRLogo from 'common/assets/logo.png';
import './style.css';

export const WelcomeModal = (): JSX.Element => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const hideModal = () => setModalVisible(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const signOutHandler = () => {
    dispatch(logout()).then(() => {
      dispatch(clearAllNotifications());
      history.push(pageCatalog.SignIn.getPath());
    });
  };

  return (
    <Modal
      modalRoot={document.getElementById(APP_CONTENT_ID) || undefined}
      visible={modalVisible}
      onDismiss={hideModal}
      header={t('CompleteSignUp.welcomeToDR')}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal" onClick={signOutHandler}>
              {t('CompleteSignUp.IwillDoLater')}
            </Button>
            <Button variant="primary" onClick={hideModal}>
              {t('CompleteSignUp.completeSignUp')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Grid gridDefinition={[{ colspan: { default: 12, xxs: 4 } }, { colspan: { default: 12, xxs: 8 } }]}>
        <Box variant="div" textAlign="center" className="img-div-resp">
          <img className="img-size-resp" src={DRLogo} width={MEDIUM_LOGO_WIDTH} alt="deep racer logo" />
        </Box>
        <Box>
          <p>{t('CompleteSignUp.welcomeMsg1')}</p>
          <p>
            <b>{t('CompleteSignUp.welcomeMsg2')}</b>
          </p>
        </Box>
      </Grid>
    </Modal>
  );
};
