import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { VALID_EMAIL_PATTERN } from 'common/constants';
import { InputFormField } from 'common/components/FormFields';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { handleForgotPasswordRequest, getAuthIsLoading } from 'store/auth';

const initialValues = { email: '' };
export const ForgotPasswordRequestForm = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getAuthIsLoading);

  const onSubmit = (data) => {
    const { email } = data;
    dispatch(handleForgotPasswordRequest({ email }));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('required')).matches(VALID_EMAIL_PATTERN, t('emailFormat')),
  });

  return (
    <Container header={<Header description={t('forgotPasswordDesc')}>{t('forgotPassword')}</Header>}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form>
          <SpaceBetween size="m">
            <InputFormField label={t('emailLabel')} stretch name="email" type="email" />
          </SpaceBetween>
          <Box margin={{ top: 's' }}>
            <SpaceBetween size="s" direction="vertical">
              <Button variant="primary" formAction="submit" loading={isLoading}>
                {t('sendResetEmailLabel')}
              </Button>
            </SpaceBetween>
          </Box>
        </Form>
      </Formik>
    </Container>
  );
};
