import React from 'react';
import FormField, { FormFieldProps } from '@amzn/awsui-components-react/polaris/form-field';
import Multiselect, { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import { useField } from 'formik';

interface MultiselectFormWrapperProps extends Omit<MultiselectProps, 'selectedOptions'> {
  name: string;
  validate?: (value: any) => undefined | string | Promise<any>;
}

type MultiselectFormFieldPropTypes = FormFieldProps & MultiselectFormWrapperProps;

export const MultiselectFormFieldView: React.FC<MultiselectFormFieldPropTypes> = ({
  description,
  constraintText,
  label,
  stretch,
  onChange,
  onBlur,
  name,
  validate,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const handleChange: MultiselectProps['onChange'] = (event) => {
    helpers.setValue(event.detail.selectedOptions);
    onChange?.(event);
  };

  const handleBlur: MultiselectProps['onBlur'] = (event) => {
    helpers.setTouched(true);
    onBlur?.(event);
  };

  const formFieldProps = {
    description,
    errorText: meta.touched && meta.error ? meta.error : null,
    constraintText,
    label,
    stretch,
  };

  const selectFieldProps = {
    ...field,
    ...otherProps,
    selectedOptions: field.value || [],
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <FormField {...formFieldProps}>
      <Multiselect {...selectFieldProps} />
    </FormField>
  );
};

export const MultiselectFormField = React.memo(MultiselectFormFieldView);
