import { Formik, Form } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Box } from '@amzn/awsui-components-react';
import Container from '@amzn/awsui-components-react/polaris/container';
import PolarisForm from '@amzn/awsui-components-react/polaris/form';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
  CompleteSignUpFields,
  EDUCATOR_VAL,
  OTHER_VAL,
  SELF_IDENTIFY_OPTION,
  VALID_NAME_PATTERN,
} from 'common/constants';
import {
  CompleteSignUpForm,
  ConfirmSignOutButton,
  EsperanzaBanner,
  WelcomeModal,
} from 'common/components/CompleteSignUp';
import { convertFormDataToUpdateProfileRequest } from 'common/utils/profile';
import pageCatalog from 'pages/pageCatalog';
import { getProfileIsLoading, handleUpdateProfile, getFirstName } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import './style.css';

const initialValues = {
  [CompleteSignUpFields.FIRSTNAME]: '',
  [CompleteSignUpFields.MIDDLENAME]: '',
  [CompleteSignUpFields.LASTNAME]: '',
  [CompleteSignUpFields.SCHOOL]: '',
  [CompleteSignUpFields.SCHOOL_ENTERED]: '',
  [CompleteSignUpFields.MAJOR]: '',
  [CompleteSignUpFields.MAJOR_ENTERED]: '',
  [CompleteSignUpFields.YEAR_OF_GRAD]: '',
  [CompleteSignUpFields.COUNTRY]: '',
  [CompleteSignUpFields.SELF_CERTIFIED]: false,
  [CompleteSignUpFields.GENDER]: [],
  [CompleteSignUpFields.GENDER_ENTERED]: '',
  [CompleteSignUpFields.RACE]: [],
  [CompleteSignUpFields.RACE_ENTERED]: '',
  [CompleteSignUpFields.OPT_IN]: false,
};

export const CompleteSignUp = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const isLoading = useAppSelector(getProfileIsLoading);
  const firstName = useAppSelector(getFirstName);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (firstName) {
      // Redirect to hom if completeSignUp is filled
      history.push(pageCatalog.StudentHome.getPath());
    }
  }, [firstName, history]);

  const validationSchema = Yup.object().shape({
    [CompleteSignUpFields.FIRSTNAME]: Yup.string()
      .required(t('required'))
      .max(30, t('CompleteSignUp.invalidNameLength'))
      .matches(VALID_NAME_PATTERN, t('CompleteSignUp.invalidNameLength')),
    [CompleteSignUpFields.LASTNAME]: Yup.string()
      .required(t('required'))
      .max(30, t('CompleteSignUp.invalidNameLength'))
      .matches(VALID_NAME_PATTERN, t('CompleteSignUp.invalidNameLength')),
    [CompleteSignUpFields.MIDDLENAME]: Yup.string()
      .max(30, t('CompleteSignUp.invalidNameLength'))
      .matches(VALID_NAME_PATTERN, t('CompleteSignUp.invalidNameLength')),
    [CompleteSignUpFields.SCHOOL]: Yup.object().required(t('required')),
    [CompleteSignUpFields.MAJOR]: Yup.object().required(t('required')),
    [CompleteSignUpFields.YEAR_OF_GRAD]: Yup.number().when([CompleteSignUpFields.MAJOR], {
      is: (majorSelected) => majorSelected?.value !== EDUCATOR_VAL,
      then: () =>
        Yup.number()
          .typeError(t('CompleteSignUp.invalidYear'))
          .integer(t('CompleteSignUp.invalidYear'))
          .moreThan(2021, t('CompleteSignUp.invalidYearRange'))
          .lessThan(2031, t('CompleteSignUp.invalidYearRange'))
          .required(t('required')),
    }),
    [CompleteSignUpFields.COUNTRY]: Yup.object().required(t('required')),
    [CompleteSignUpFields.SELF_CERTIFIED]: Yup.boolean().isTrue(t('CompleteSignUp.certifyStudentError')),
    [CompleteSignUpFields.GENDER]: Yup.array().required(t('required')),
    [CompleteSignUpFields.SCHOOL_ENTERED]: Yup.string().when([CompleteSignUpFields.SCHOOL], {
      is: (schoolSelected) => schoolSelected?.value === OTHER_VAL,
      then: () => Yup.string().required(t('required')).max(150, t('CompleteSignUp.invalidSchoolEntered')),
    }),
    [CompleteSignUpFields.MAJOR_ENTERED]: Yup.string().when([CompleteSignUpFields.MAJOR], {
      is: (majorSelected) => majorSelected?.value === OTHER_VAL,
      then: () => Yup.string().required(t('required')).max(150, t('CompleteSignUp.invalidMajorEntered')),
    }),
    [CompleteSignUpFields.GENDER_ENTERED]: Yup.string().when([CompleteSignUpFields.GENDER], {
      is: (genderSelected) => genderSelected?.some((gender) => gender.value === SELF_IDENTIFY_OPTION.value),
      then: () => Yup.string().required(t('required')).max(50, t('CompleteSignUp.invalidGenderLength')),
    }),
    [CompleteSignUpFields.RACE_ENTERED]: Yup.string().when([CompleteSignUpFields.RACE], {
      is: (race) => race?.some((race) => race.value === SELF_IDENTIFY_OPTION.value),
      then: () => Yup.string().required(t('required')).max(50, t('CompleteSignUp.invalidRaceLength')),
    }),
  });
  const onSubmit = (data) => {
    const profileData = convertFormDataToUpdateProfileRequest(data);
    dispatch(handleUpdateProfile({ profileData }));
  };
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <PolarisForm
          actions={
            <Box margin={{ bottom: 'm' }}>
              <SpaceBetween direction="horizontal" size="xs">
                <ConfirmSignOutButton />
                <Button
                  variant="primary"
                  formAction="submit"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  loading={isLoading}
                >
                  {t('submit')}
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <WelcomeModal />
          <SpaceBetween size="l">
            <Container header={<Header>{t('CompleteSignUp.completeSignUpHeader')}</Header>}>
              <CompleteSignUpForm />
            </Container>
            <EsperanzaBanner />
          </SpaceBetween>
        </PolarisForm>
      </Form>
    </Formik>
  );
};
