import { Formik, Form } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box, ColumnLayout, Header, SpaceBetween, Link } from '@amzn/awsui-components-react';
import { CancelButton, SubmitButton } from 'common/components/FormButtons';
import { InputFormField } from 'common/components/FormFields';
import { KeyValuePair } from 'common/components/KeyValuePair';
import Modal from 'common/components/Modal';
import { CompleteSignUpFields } from 'common/constants';
import { getYearOfGraduation, getProfileIsLoading, handleUpdateProfile } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';

export const YearOfGraduation = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const yearOfGraduation = useAppSelector(getYearOfGraduation);
  const isLoading = useAppSelector(getProfileIsLoading);

  const onDismiss = () => {
    setVisible(false);
  };

  const handleSubmit = (data) => {
    dispatch(
      handleUpdateProfile({
        profileData: { schoolDetails: { yearOfGraduation: data[CompleteSignUpFields.YEAR_OF_GRAD] } },
      })
    ).then(onDismiss);
  };

  const initialValues = {
    [CompleteSignUpFields.YEAR_OF_GRAD]: yearOfGraduation,
  };
  const validationSchema = Yup.object().shape({
    [CompleteSignUpFields.YEAR_OF_GRAD]: Yup.number()
      .typeError(t('CompleteSignUp.invalidYear'))
      .required(t('required'))
      .integer(t('CompleteSignUp.invalidYear'))
      .moreThan(2021, t('CompleteSignUp.invalidYearRange'))
      .lessThan(2031, t('CompleteSignUp.invalidYearRange')),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          header={
            <Header description={t('ProfilePage.WhatIsYourNewYearOfGraduation')}>
              {t('ProfilePage.EditYearOfGraduation')}
            </Header>
          }
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <CancelButton onClick={onDismiss}>{t('cancel')}</CancelButton>
                <SubmitButton variant="primary" loading={isLoading}>
                  {t('save')}
                </SubmitButton>
              </SpaceBetween>
            </Box>
          }
        >
          <InputFormField
            label={t('CompleteSignUp.yearOfGraduationLabel')}
            description={t('CompleteSignUp.yearOfGraduationDesc')}
            placeholder={t('CompleteSignUp.yearOfGraduationPlaceholder')}
            name={CompleteSignUpFields.YEAR_OF_GRAD}
            type="text"
          />
        </Modal>
        <ColumnLayout columns={2}>
          <KeyValuePair label={t('ProfilePage.YearOfGraduation')}>{yearOfGraduation || t('emptyLabel')}</KeyValuePair>
          <Link onFollow={() => setVisible(true)}>{t('ProfilePage.EditYearOfGraduation')}</Link>
        </ColumnLayout>
      </Form>
    </Formik>
  );
};
