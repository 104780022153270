import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DARK_MODE_SLICE } from 'common/constants';

const getInitialIsDarkMode = (): boolean => window.matchMedia?.('(prefers-color-scheme: dark)').matches;

export interface DarkModeState {
  isDarkMode: boolean;
}

const initialState: DarkModeState = {
  isDarkMode: getInitialIsDarkMode(),
};

export const darkModeSlice = createSlice({
  name: DARK_MODE_SLICE,
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<{ isDarkMode: boolean }>) => {
      state.isDarkMode = action.payload.isDarkMode;
    },
  },
});

export const { setDarkMode } = darkModeSlice.actions;

export default darkModeSlice.reducer;
