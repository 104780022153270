import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import pageCatalog from 'pages/pageCatalog';
import { logout } from 'store/auth';
import { useAppDispatch } from 'store/utils/hooks';

export const ConfirmSignOutButton = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { touched } = useFormikContext();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();

  const signOutHandler = () => {
    dispatch(logout()).then(() => {
      history.push(pageCatalog.SignIn.getPath());
    });
  };

  const onClick = () => {
    if (Object.keys(touched).length > 0) {
      setVisible(true);
    } else {
      signOutHandler();
    }
  };

  const onDismiss = () => setVisible(false);

  return (
    <Box>
      <Modal
        header={t('CompleteSignUp.areYouSureSignOut')}
        onDismiss={onDismiss}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={onDismiss}>{t('cancel')}</Button>
              <Button variant="primary" onClick={signOutHandler}>
                {t('signout')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        visible={visible}
      >
        {t('CompleteSignUp.youWillLooseProgress')}
      </Modal>
      <Button formAction="none" onClick={onClick} data-testid="finish-later-button">
        {t('CompleteSignUp.finishLater')}
      </Button>
    </Box>
  );
};
