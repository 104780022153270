import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HELPPANEL_SLICE, HOME_NON_ESPERANZA } from 'common/constants';

export interface HelpPanelState {
  isToolsOpen: boolean;
  currPathSlug: string;
}

const initialState: HelpPanelState = {
  isToolsOpen: false,
  currPathSlug: HOME_NON_ESPERANZA, // Default Help panel id
};

export const helpPanelSlice = createSlice({
  name: HELPPANEL_SLICE,
  initialState,
  reducers: {
    setCurrPathSlug: (state, action: PayloadAction<{ pathSlug: string }>) => {
      state.currPathSlug = action.payload.pathSlug;
    },
    toggleToolsOpen: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isToolsOpen = action.payload.open;
    },
  },
});

export const { toggleToolsOpen, setCurrPathSlug } = helpPanelSlice.actions;

export default helpPanelSlice.reducer;
