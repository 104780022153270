import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Header } from '@amzn/awsui-components-react';
import Table from '@amzn/awsui-components-react/polaris/table';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';

enum EventTypes {
  HappeningNow = 'HAPPENING_NOW',
  Scheduled = 'SCHEDULED',
  Complete = 'COMPLETE',
}

type RaceEventType = {
  eventName: string;
  startDate: string;
  endDate: string;
  eventStatus: EventTypes;
};

const setEventStatus = (startTime: number, endTime: number): EventTypes => {
  return Date.now() < startTime
    ? EventTypes.Scheduled
    : Date.now() > endTime
    ? EventTypes.Complete
    : EventTypes.HappeningNow;
};

export const UpcomingRaceEvents = (): JSX.Element => {
  const { t } = useTranslation();

  const studentLeague: RaceEventType = {
    eventName: 'Student League Pre-Season',
    eventStatus: setEventStatus(1696118400000, 1709164799000),
    startDate: '10/1/23',
    endDate: '2/28/24',
  };

  const finaleEvent: RaceEventType = {
    eventName: 'Student League Regular Season',
    eventStatus: setEventStatus(1709251200000, 1727740799000),
    startDate: '3/1/24',
    endDate: '9/30/24',
  };

  const studentLeagueChampionship: RaceEventType = {
    eventName: 'Student League Championship Series',
    eventStatus: setEventStatus(1727740800000, 1730419199000),
    startDate: '10/1/24',
    endDate: '10/31/24',
  };

  const ScholarshipQualiSummer: RaceEventType = {
    eventName: 'Scholarship Qualification (Summer Cohort)',
    eventStatus: setEventStatus(1696118400000, 1717199999000),
    startDate: '10/1/23',
    endDate: '5/31/24',
  };

  const UdacitySummer: RaceEventType = {
    eventName: 'Scholarship Applications open on Udacity site (Summer Cohort)',
    eventStatus: setEventStatus(1706745600000, 1717199999000),
    startDate: '2/1/24',
    endDate: '5/31/24',
  };

  const ScholarshipQualiWinter: RaceEventType = {
    eventName: 'Scholarship Qualification (Winter Cohort)',
    eventStatus: setEventStatus(1717200000000, 1727740799000),
    startDate: '6/1/24',
    endDate: '9/30/24',
  };

  const UdacityWinter: RaceEventType = {
    eventName: 'Scholarship Applications open on Udacity site (Winter Cohort)',
    eventStatus: setEventStatus(1717200000000, 1727740799000),
    startDate: '6/1/24',
    endDate: '9/30/24',
  };

  const getStatusIndicator = (eventStatus: EventTypes) => {
    if (eventStatus === EventTypes.HappeningNow) {
      return <StatusIndicator type="success">{t('happeningNow')}</StatusIndicator>;
    }
    if (eventStatus === EventTypes.Complete) {
      return <StatusIndicator type="stopped">{t('complete')}</StatusIndicator>;
    }
    return <StatusIndicator type="pending">{t('scheduled')}</StatusIndicator>;
  };

  return (
    <Table
      data-analytics="upcomingRaceEvents-Table"
      data-analytics-type="eventContext"
      className="student-container-height"
      columnDefinitions={[
        {
          id: 'eventName',
          header: 'Event name',
          cell: (item) => item.eventName || '-',
          sortingField: 'name',
        },
        {
          id: 'eventStatus',
          header: 'Event status',
          cell: (item) => getStatusIndicator(item.eventStatus),
        },
        {
          id: 'startDate',
          header: 'Start Date',
          cell: (item) => item.startDate || '-',
          sortingField: 'alt',
        },
        {
          id: 'endDate',
          header: 'End Date',
          cell: (item: any) => item.endDate || '-',
        },
      ]}
      items={[
        studentLeague,
        finaleEvent,
        studentLeagueChampionship,
        ScholarshipQualiSummer,
        UdacitySummer,
        ScholarshipQualiWinter,
        UdacityWinter,
      ]}
      loadingText={t('HomePage.UpcomingRaceEvents.Loading')}
      sortingDisabled
      header={<Header>{t('HomePage.UpcomingRaceEvents.Heading')}</Header>}
      empty={
        <Box textAlign="center" color="inherit">
          <b>{t('HomePage.UpcomingRaceEvents.NoEvents')}</b>
        </Box>
      }
    />
  );
};
