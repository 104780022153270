import { IntroToMLChapterId, IntroToMLChapterTitle } from 'common/constants/learningModule/introToML';
import { IntroToRLChapterId, IntroToRLChapterTitle } from 'common/constants/learningModule/introToRL';
import { LearnModulePageId } from 'common/constants/learningModule/pageIds';

// Value should be chapter title translation key
export const ChapterIdToChapterTitle = {
  [IntroToMLChapterId.CHAPTER_1]: IntroToMLChapterTitle.CHAPTER_1,
  [IntroToMLChapterId.CHAPTER_2]: IntroToMLChapterTitle.CHAPTER_2,
  [IntroToMLChapterId.CHAPTER_3]: IntroToMLChapterTitle.CHAPTER_3,
  [IntroToMLChapterId.CHAPTER_4]: IntroToMLChapterTitle.CHAPTER_4,
  [IntroToMLChapterId.CHAPTER_5]: IntroToMLChapterTitle.CHAPTER_5,
  [IntroToMLChapterId.CHAPTER_6]: IntroToMLChapterTitle.CHAPTER_6,
  [IntroToMLChapterId.CHAPTER_7]: IntroToMLChapterTitle.CHAPTER_7,
  [IntroToMLChapterId.CHAPTER_8]: IntroToMLChapterTitle.CHAPTER_8,
  [IntroToMLChapterId.CHAPTER_9]: IntroToMLChapterTitle.CHAPTER_9,
  [IntroToMLChapterId.CHAPTER_10]: IntroToMLChapterTitle.CHAPTER_10,
  [IntroToMLChapterId.CHAPTER_11]: IntroToMLChapterTitle.CHAPTER_11,
  [IntroToMLChapterId.CHAPTER_12]: IntroToMLChapterTitle.CHAPTER_12,
  [IntroToRLChapterId.CHAPTER_1]: IntroToRLChapterTitle.CHAPTER_1,
  [IntroToRLChapterId.CHAPTER_2]: IntroToRLChapterTitle.CHAPTER_2,
  [IntroToRLChapterId.CHAPTER_3]: IntroToRLChapterTitle.CHAPTER_3,
  [IntroToRLChapterId.CHAPTER_4]: IntroToRLChapterTitle.CHAPTER_4,
  [IntroToRLChapterId.CHAPTER_5]: IntroToRLChapterTitle.CHAPTER_5,
  [IntroToRLChapterId.CHAPTER_6]: IntroToRLChapterTitle.CHAPTER_6,
  [IntroToRLChapterId.CHAPTER_7]: IntroToRLChapterTitle.CHAPTER_7,
  [IntroToRLChapterId.CHAPTER_8]: IntroToRLChapterTitle.CHAPTER_8,
  [IntroToRLChapterId.CHAPTER_9]: IntroToRLChapterTitle.CHAPTER_9,
};

export const ChapterIdToModuleId = {
  [IntroToMLChapterId.CHAPTER_1]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_2]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_3]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_4]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_5]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_6]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_7]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_8]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_9]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_10]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_11]: LearnModulePageId.INTRO_TO_ML,
  [IntroToMLChapterId.CHAPTER_12]: LearnModulePageId.INTRO_TO_ML,
  [IntroToRLChapterId.CHAPTER_1]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_2]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_3]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_4]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_5]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_6]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_7]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_8]: LearnModulePageId.INTRO_TO_RL,
  [IntroToRLChapterId.CHAPTER_9]: LearnModulePageId.INTRO_TO_RL,
};

export const ChapterIdToNextChapterId = {
  [IntroToMLChapterId.CHAPTER_1]: IntroToMLChapterId.CHAPTER_2,
  [IntroToMLChapterId.CHAPTER_2]: IntroToMLChapterId.CHAPTER_3,
  [IntroToMLChapterId.CHAPTER_3]: IntroToMLChapterId.CHAPTER_4,
  [IntroToMLChapterId.CHAPTER_4]: IntroToMLChapterId.CHAPTER_5,
  [IntroToMLChapterId.CHAPTER_5]: IntroToMLChapterId.CHAPTER_6,
  [IntroToMLChapterId.CHAPTER_6]: IntroToMLChapterId.CHAPTER_7,
  [IntroToMLChapterId.CHAPTER_7]: IntroToMLChapterId.CHAPTER_8,
  [IntroToMLChapterId.CHAPTER_8]: IntroToMLChapterId.CHAPTER_9,
  [IntroToMLChapterId.CHAPTER_9]: IntroToMLChapterId.CHAPTER_10,
  [IntroToMLChapterId.CHAPTER_10]: IntroToMLChapterId.CHAPTER_11,
  [IntroToMLChapterId.CHAPTER_11]: IntroToMLChapterId.CHAPTER_12,
  [IntroToMLChapterId.CHAPTER_12]: '',
  [IntroToRLChapterId.CHAPTER_1]: IntroToRLChapterId.CHAPTER_2,
  [IntroToRLChapterId.CHAPTER_2]: IntroToRLChapterId.CHAPTER_3,
  [IntroToRLChapterId.CHAPTER_3]: IntroToRLChapterId.CHAPTER_4,
  [IntroToRLChapterId.CHAPTER_4]: IntroToRLChapterId.CHAPTER_5,
  [IntroToRLChapterId.CHAPTER_5]: IntroToRLChapterId.CHAPTER_6,
  [IntroToRLChapterId.CHAPTER_6]: IntroToRLChapterId.CHAPTER_7,
  [IntroToRLChapterId.CHAPTER_7]: IntroToRLChapterId.CHAPTER_8,
  [IntroToRLChapterId.CHAPTER_8]: IntroToRLChapterId.CHAPTER_9,
  [IntroToRLChapterId.CHAPTER_9]: '',
};

export const ChapterIdToSectionIds = {
  [IntroToMLChapterId.CHAPTER_1]: [
    '6c24b885-5976-4c37-be55-274940c970db',
    'a9ad91ab-deab-46f2-ada5-a5324152d345',
    'bdd87474-a8fd-4020-bfe8-92d0fcd1f7ba',
  ],
  [IntroToMLChapterId.CHAPTER_2]: [
    'bb7e88d5-321d-4eac-9e74-b1db2404f2e4',
    '9ba1a0e7-e5e1-417c-8ccf-1a753c9f7444',
    '93d0c7ca-af47-45b1-9c31-e7d22cb78538',
    '7b39a08d-b674-46f8-9f32-dfbb4cef4488',
  ],
  [IntroToMLChapterId.CHAPTER_3]: [
    'ab13404d-9ef1-4507-9a28-1a6514978d27',
    '232067fd-e48b-49c2-a513-3149814301c5',
    '49dea7af-6ae7-4586-9aee-79bd474e5375',
    '50018558-3d78-46e8-a3c5-f186210f3428',
  ],
  [IntroToMLChapterId.CHAPTER_4]: ['9d3acd1e-c96e-4076-875c-9d3dff807980', '040967e1-30af-4856-b4ba-eb536eb4089b'],
  [IntroToMLChapterId.CHAPTER_5]: [
    'e0654d2c-623d-4fe6-8821-f1dfe816f202',
    '147bd688-7026-4285-a221-d6bcdaa07cc3',
    '8cfeba35-f942-4cdc-9fc2-2d084a4a5e94',
    '7af9dccb-0f62-43c9-8818-178aea08c1df',
  ],
  [IntroToMLChapterId.CHAPTER_6]: [
    'c75294ee-9246-46fc-86da-9bb5c96c246a',
    'fd19fbd2-a727-436b-9a96-6d3773aca0a7',
    '4b74f87d-071a-404d-b72f-5ea7fd9d0085',
    '9b451fa5-2eb5-4d03-8d25-d2fad432860a',
    '6eac2c79-b37e-4d18-b7e7-9b7481cd5cac',
    '650d5137-cc61-4c87-a5ae-09a92c70025f',
  ],
  [IntroToMLChapterId.CHAPTER_7]: [
    'a26dcf87-06f2-42b4-a5f7-059011172b71',
    'dca287a1-7c50-4d97-b4cc-498bf0db95a7',
    '854da258-2d92-4fae-9071-a8fd34d60d08',
    '3f57d6d6-7aa3-46cb-9bf4-b83d55f8ddf1',
    '18ae091d-7226-4fa8-b085-07b304f8d3e7',
    '9451b77c-7971-4f45-9b4d-ce89be4a05bb',
  ],
  [IntroToMLChapterId.CHAPTER_8]: [
    '9735dca7-3e30-4739-9b41-b2d2ff2c8afa',
    'ebe9e046-7352-4d84-9d36-3719a8727a9f',
    '7aab3f9f-ba4d-42c6-a2f6-6cadb0a9a6e0',
    '1c321d5a-d9bd-4fa7-809c-f7b6ec71645d',
    '65d3ac32-69ea-4a37-bf82-9349a2c662bc',
  ],
  [IntroToMLChapterId.CHAPTER_9]: [
    '33f6aa37-ba49-4349-bc86-7bec8afc58dd',
    'cf3334bd-0e03-4730-b445-9bbad4b8dea1',
    '61e991a4-08e3-4f87-82ae-270b30c47e5a',
  ],
  [IntroToMLChapterId.CHAPTER_10]: [
    '71801b4d-1ee2-4558-ae10-6a91e2cd9055',
    '0d60ae09-2a5b-4450-90b8-5559fb155fd9',
    'ee45e602-aeda-4da0-8577-21ba6a0db476',
    '83e3d164-5ec4-452f-8756-95e17b552288',
    'fb0e059d-c82a-45bf-ba8a-6ef57a74287e',
    'da00264e-c57b-4d87-a497-95e3cc5b0af5',
    'f2747e8a-0165-4580-8d53-cf9b76545e0e',
    '0af2d746-da77-4344-803b-e4fdaafc2f16',
  ],
  [IntroToMLChapterId.CHAPTER_11]: [
    '56b51dde-1cf1-417b-95d4-d61ab732be79',
    '6db50b57-1dd5-4f92-8a7b-91201a64ee82',
    '504b7476-9312-46ed-bb3b-7f7a16dfeb3d',
    '3ea7e03d-669a-4272-a331-86c0856d5bb1',
    'b5d679d3-78bc-4b0b-96ae-f5aab5dd1d7a',
    '3f12d6b8-a467-469c-b1a0-f8a26a2ff779',
    '8d2861de-6efe-49fe-8df8-2b695603267a',
  ],
  [IntroToMLChapterId.CHAPTER_12]: [
    '76fec81e-aaed-4814-a96b-1a81afbc08e4',
    '92dfd539-ff40-4c3d-8ec7-08dfd3e6ff7e',
    '452c7560-fed5-4035-9ba0-0c62e6604d51',
    'ccedd179-c6c6-43e0-8236-30bfb2697cc1',
    'd4b40540-6cb1-4b9a-88c6-070e4e64951d',
    '42b608d0-7463-4222-9169-22612f02811f',
    'e5364063-24f5-4cf6-a2b5-b02cc979a38a',
  ],
  [IntroToRLChapterId.CHAPTER_1]: [
    '53a98837-9fef-4b25-92f3-6dc62ca7928d',
    '50753a54-da20-44d5-9d7f-30eeccce32af',
    '676ec334-a0f6-4b35-a208-763531ea0aca',
    'f657b617-e7d2-482d-b7f3-04f228e969b7',
    '250f3ede-b6ce-411b-b3cb-330976353975',
  ],
  [IntroToRLChapterId.CHAPTER_2]: [
    '7e0fd104-130e-424b-a99c-ae9c778c682d',
    'e9746d7d-d2b9-43ef-b471-e89d3d64c2f3',
    '4728b3b0-cc4a-4ab9-aa4b-24209374f64d',
    '230ee86a-b616-4c3b-a63d-386c987f95d2',
    '2f0fae99-6af8-491d-a735-2fbac339729f',
  ],
  [IntroToRLChapterId.CHAPTER_3]: ['f7ba6fe5-4250-4bf9-b9ce-97394d63342a', '538b8130-a88e-4ef4-92a6-6daf7ec098b2'],
  [IntroToRLChapterId.CHAPTER_4]: ['19fbc3a7-47c8-424b-aa41-38b039a9dc54', '7eb87a39-5e57-4e6c-bf59-e6f241234f6f'],
  [IntroToRLChapterId.CHAPTER_5]: [
    '18af77ee-030d-4b5c-b198-ead4b8b9279e',
    'ffea2c9f-42a5-4546-aded-958e7e7658fd',
    'a4e4574d-c7a4-400f-a64f-54f7043cac5a',
    '355dcd26-c4c6-491c-bee5-2e83e9a2239c',
    '5c431d3b-a49d-4536-b0b5-18e481ec2aa5',
    '59419e8f-2400-486c-b4d3-2abca05259e0',
    'b22b2e8c-0a27-476d-ae18-23fb70d9f236',
    'f2cacca3-6a64-4d3d-9e1b-84329c5aaf19',
  ],
  [IntroToRLChapterId.CHAPTER_6]: [
    '3fd4b600-c42d-48b7-a829-a696726ce427',
    '811fd2cd-57df-4b6e-9c64-8f410e670d2e',
    'd2dc8074-0d56-46bc-ae0d-a70330f272b0',
    '874c532d-7be5-4ad3-b8a7-8cbec1aa19e5',
    'bb3051f1-0758-460b-bbd7-2a2417a64446',
  ],
  [IntroToRLChapterId.CHAPTER_7]: [
    '91b1e38d-603a-451a-9a6c-1038fe1d5550',
    '0b3669bc-176f-4659-bf0e-2fec7e5e9e4b',
    '2a9addee-e90b-4dfc-a0dd-5630bbe91107',
    'fb79b011-e03d-4594-b6ba-e877b3351a89',
  ],
  [IntroToRLChapterId.CHAPTER_8]: [
    'b8cda990-7f07-402e-bca0-dd19b079abdd',
    '79950e93-2f38-44c9-8799-5fcbc56b3e6e',
    '26d23d42-2daa-4e25-b075-f57704a215a5',
    '609fb970-c02d-4e2b-887e-9d28da821206',
    '11863b68-be09-476f-867f-1a788c4e35d3',
    'c1482da4-dc5b-48f3-a80e-2a3ff40c3549',
    '831d9e3e-efed-4899-b109-008cf773e753',
  ],
  [IntroToRLChapterId.CHAPTER_9]: ['7afcfcd8-9a19-45d5-a8b7-65f3fa1030ed', '12cc08a9-4b57-4034-9210-e45b7b650935'],
};
