/* istanbul ignore file */
import { PageHeader as ImproveModelHeader } from 'pages/improveModel';
import { PageHeader as EnterRaceHeader } from 'pages/enterRace';
import { PageHeader as HomeHeader } from 'pages/home';
import { PageHeader as AccountHeader } from 'pages/account';
import { PageHeader as ContactUsHeader } from 'pages/contactus';
import { PageHeader as ProfileHeader } from 'pages/profile';
import { PageHeader as LearnOverviewHeader } from 'pages/learnOverview';
import { PageHeader as ModelHeader } from 'pages/modelDetails';
import { PageHeader as ModelsListHeader } from 'pages/modelsList';
import { PageHeader as AssessmentHeader } from 'pages/assessment';
import { IntroToMLHeader } from 'pages/learningModule';
import { IntroToRLHeader } from 'pages/learningModule';
import { CompleteSignUpHeader } from 'common/components/CompleteSignUp';
import { PageHeader as CommunityRacesHeader } from 'pages/communityRaces';
import { PageHeader as StudentLeagueHeader } from 'pages/studentLeague';
import { DRLitePage } from 'pages/pageCatalog';
import { getPageIdByRoutePattern } from 'pages/utils';

export const getContentHeader = (pathname): JSX.Element | null => {
  const currentPageId = getPageIdByRoutePattern(pathname);
  switch (currentPageId) {
    case DRLitePage.ACCOUNT:
      return <AccountHeader />;
    case DRLitePage.CONTACT_US:
      return <ContactUsHeader />;
    case DRLitePage.ENTER_RACE:
      return <EnterRaceHeader />;
    case DRLitePage.IMPROVE_MODEL:
      return <ImproveModelHeader />;
    case DRLitePage.LEARNING_MODULE_WIZARD:
      return <div />;
    case DRLitePage.LEARNING_HOME:
      return <LearnOverviewHeader />;
    case DRLitePage.LEARNING_MODULE_1:
      return <IntroToMLHeader />;
    case DRLitePage.LEARNING_MODULE_2:
      return <IntroToRLHeader />;
    case DRLitePage.PROFILE:
      return <ProfileHeader />;
    case DRLitePage.STUDENT_HOME:
      return <HomeHeader />;
    case DRLitePage.STUDENT_LEAGUE:
      return <StudentLeagueHeader />;
    case DRLitePage.MODEL:
      return <ModelHeader />;
    case DRLitePage.MODELS_LIST:
      return <ModelsListHeader />;
    case DRLitePage.CREATE_MODEL:
      return <div />;
    case DRLitePage.CLONE_MODEL:
      return <div />;
    case DRLitePage.COMPLETE_SIGN_UP:
      return <CompleteSignUpHeader />;
    case DRLitePage.ASSESSMENT_START:
      return <AssessmentHeader enableCountDown={false} />;
    case DRLitePage.ASSESSMENT_RESULT:
      return <AssessmentHeader enableCountDown={false} />;
    case DRLitePage.ASSESSMENT_QUIZ:
      return <AssessmentHeader enableCountDown />;
    case DRLitePage.COMMUNITY_LANDING:
      return <CommunityRacesHeader />;
    case DRLitePage.COMMUNITY_ENTER:
      return <EnterRaceHeader />;
    default: {
      return null;
    }
  }
};

export const getContentMaxWidths = (pathname): number | undefined => {
  const currentPageId = getPageIdByRoutePattern(pathname);
  switch (currentPageId) {
    case DRLitePage.EXPLORATION:
      return undefined; // max width is defined in walk up experience
    case DRLitePage.SIGN_IN:
      return 720;
    case DRLitePage.SIGN_UP:
      return 720;
    case DRLitePage.VERIFY_EMAIL:
      return 720;
    case DRLitePage.FORGOT_PASSWORD_REQUEST:
      return 720;
    case DRLitePage.FORGOT_PASSWORD_RESET:
      return 720;
    default: {
      return 1400;
    }
  }
};
