/* istanbul ignore file */
import { Button, ColumnLayout, Container, Header, Link, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import pageCatalog, { DRLitePage, PageRouteParams } from 'pages/pageCatalog';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import {
  clearAssessmentTracker,
  fetchAssessmentTracker,
  fetchAssessmentTrackers,
  getAssessmentTracker,
  getAssessmentTrackerIsLoading,
  startAssessmentTracker,
} from 'store/lmsQuiz';
import { QuizAssessmentStatus } from '@amzn/aws-deep-racer-lite-external-service-type-script-client';
import { DR_LITE_SCHOLARSHIP_ASSESSMENT_ID } from 'services/constants';
import { getOptedInEsperanza, getProfileData } from 'store/profile';
import { Trans, useTranslation } from 'react-i18next';

const AssessmentStart = () => {
  const { t } = useTranslation('assessment', { keyPrefix: 'assessmentStart' });
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { assessmentTrackerId } = useParams<PageRouteParams[DRLitePage.ASSESSMENT_START]>();
  const assessmentTrackerData = useAppSelector(getAssessmentTracker);
  const assessmentTrackerIsLoading = useAppSelector(getAssessmentTrackerIsLoading);
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  const profileData = useAppSelector(getProfileData);

  // Redirect if not opted in esperanza
  useEffect(() => {
    if (!optedInEsperanza && profileData) {
      history.push(pageCatalog[DRLitePage.LEARNING_HOME].getPath());
    }
  }, [history, optedInEsperanza, profileData]);

  // Fetch assessmentTracker from server on mount
  useEffect(() => {
    dispatch(
      fetchAssessmentTracker({
        assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
        assessmentTrackerId,
      })
    );
    return () => {
      dispatch(clearAssessmentTracker());
    };
  }, [assessmentTrackerId, dispatch]);

  // if assessmentTracker is completed go back to result page
  useEffect(() => {
    if (
      assessmentTrackerData?.status === QuizAssessmentStatus.COMPLETED ||
      assessmentTrackerData?.status === QuizAssessmentStatus.IN_EVALUATION
    ) {
      history.push(pageCatalog.AssessmentResult.getPath());
    }
  }, [assessmentTrackerData, history]);

  const getAttemptsLeft = (): string => {
    const maxAttempts = 3;
    const attemptsUsed = assessmentTrackerData ? assessmentTrackerData.attempt - 1 : 0;
    const attemptsLeft = maxAttempts - attemptsUsed;
    return t('attemptsLeft', { attemptsLeft, maxAttempts });
  };

  const isTakeQuizButtonEnabled = (): boolean => {
    if (!assessmentTrackerData) return false;
    return (
      assessmentTrackerData?.attempt < 4 &&
      (assessmentTrackerData?.status === QuizAssessmentStatus.READY ||
        assessmentTrackerData?.status === QuizAssessmentStatus.IN_PROGRESS)
    );
  };

  const onTakeQuizButtonClick = (): void => {
    if (assessmentTrackerData?.status === QuizAssessmentStatus.READY) {
      dispatch(
        startAssessmentTracker({
          assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
          assessmentTrackerId,
        })
      );
      setTimeout(() => {
        dispatch(
          fetchAssessmentTrackers({
            assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
          })
        );
        history.push(pageCatalog.AssessmentQuiz.getPath({ assessmentTrackerId }));
      }, 1000);
    } else {
      history.push(pageCatalog.AssessmentQuiz.getPath({ assessmentTrackerId }));
    }
  };

  if (assessmentTrackerIsLoading) {
    return (
      <SpaceBetween size="l">
        <Container>
          {t('loadingText')} <Spinner />
        </Container>
      </SpaceBetween>
    );
  }

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  variant="primary"
                  loading={assessmentTrackerIsLoading}
                  disabled={!isTakeQuizButtonEnabled()}
                  onClick={onTakeQuizButtonClick}
                >
                  {t('headerButtonLabel')}
                </Button>
                {assessmentTrackerData?.status !== QuizAssessmentStatus.COMPLETED && <small>{getAttemptsLeft()}</small>}
              </div>
            }
          >
            {t('header')}
          </Header>
        }
      >
        <ColumnLayout columns={2}>
          <div>
            <b>{t('quizRules.header')}</b>
            <ul style={{ paddingLeft: '16px', marginTop: '0px' }}>
              {t('quizRules.listItems').map((quizRule, i) => (
                <li key={`quizRuleItem_${i}`}>{quizRule}</li>
              ))}
            </ul>
          </div>
          <div>
            <div>
              <b>{t('quizTips.header')}</b>
              <ul style={{ paddingLeft: '16px', marginTop: '0px' }}>
                <li>
                  Review the <Link onFollow={() => history.push('/learn')}>learning modules</Link>.
                </li>
                {t('quizTips.listItems').map((quizTip, i) => (
                  <li key={`quizTipItem_${i}`}>
                    <Trans>{quizTip}</Trans>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  );
};

export default AssessmentStart;
