import { ButtonProps } from '@amzn/awsui-components-react/polaris/button';
import { TFuncKey } from 'i18next';
import { DeepRacerLiteTypes } from 'types';

export interface PreRequisitesProps {
  preRequisitesLabel: TFuncKey; // URL label
  preRequisitesOnFollow?: () => void; // <Link onFollow={} /> if needed
  preRequisitesUrl: {
    href: string;
  }; // URL
}
export interface LearningModuleBaseProps {
  cardAction?: () => void; // Card button action. this can be no-op
  cardActionId: string; // uuid v4
  cardActionLabel: TFuncKey; // Card button label
  cardActionVariant?: ButtonProps.Variant; // Card button variant
  id: string; // uuid v4
  preRequisites?: PreRequisitesProps[] | undefined | null;
}

export interface LearningModuleHomeCardProps extends LearningModuleBaseProps {
  chapters: number; // Number of chapters
  comingSoon?: boolean; // Future upcoming chapters
  duration: TFuncKey; // Full string with xx min, xx hours, xx days
  level: TFuncKey; // Level
  title: TFuncKey; // Card heading
  topic: TFuncKey; // Topics name
  moduleData?: DeepRacerLiteTypes.ModuleData;
  isLoading?: boolean;
}

export interface LearningModuleChaptersCardProps extends LearningModuleBaseProps {
  chapterData?: DeepRacerLiteTypes.ChapterData;
  chapterDescription?: TFuncKey; // card description
  chapterTitle: TFuncKey; // Card heading
  comingSoon?: boolean;
  duration: TFuncKey; // Full string with xx min, xx hours, xx days
  isLoading?: boolean;
}

export interface LearningModuleChaptersSectionProps {
  title: TFuncKey;
  chaptersCard: LearningModuleChaptersCardData;
}

export interface LearningModulePageProps extends LearningModuleHomeCardProps {
  description: TFuncKey; // Card description
  helpId?: string;
  sections: LearningModuleChaptersSectionData;
  udacityUrl?: string;
}

export type LearningModuleHomeCardData = LearningModuleHomeCardProps[];

export type LearningModuleChaptersCardData = LearningModuleChaptersCardProps[];

export type LearningModuleChaptersSectionData = LearningModuleChaptersSectionProps[];

export enum WizardContentType {
  BULLET = 'BULLET',
  HEADER = 'HEADER',
  IMAGE = 'IMAGE',
  KEY_BULLET_PAIRS = 'KEY_BULLET_PAIRS',
  P_BODY = 'P_BODY',
  P_BODY_CUSTOM = 'P_BODY_CUSTOM',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
}

export enum WizardStepType {
  WIZARD_STEP = 'WIZARD_STEP',
  WIZARD_LAST_STEP = 'WIZARD_LAST_STEP',
}

export interface WizardI18n {
  cancelButton: string;
  collapsedStepsLabel: string;
  lastStepNumberLabel: string;
  nextButton: string;
  previousButton: string;
  stepNumberLabel: string;
  submitButton: string;
}

export interface InfoLink {
  linkLabel: string;
  link: {
    href: string;
  };
}

export interface BaseWidgetType {
  id: string;
  widgetType: WizardContentType;
}

export interface YoutubeContentProp extends BaseWidgetType {
  height: string;
  title: TFuncKey;
  width?: string;
  youtubeId: string;
}

export interface HeaderContentProp extends BaseWidgetType {
  variant: 'h1' | 'h2' | 'h3';
  text: TFuncKey;
}

export interface PbodyContentProp extends BaseWidgetType {
  text: TFuncKey;
}

export interface PbodayCustomProp extends BaseWidgetType {
  children: any;
}

export interface ImageContentProp extends BaseWidgetType {
  imageId: string; // key to image resource map
  imageAltText: TFuncKey;
  height?: string;
  width?: string;
}

export interface BulletItem {
  id: string;
  text: TFuncKey;
}
export interface BulletListProp extends BaseWidgetType {
  bulletType: string;
  bulletList?: BulletItem[];
  text?: TFuncKey;
}

export interface KeyBulletItem extends BulletItem {
  title: TFuncKey;
}

export interface KeyBulletPairsProps extends BaseWidgetType {
  bulletList?: KeyBulletItem[];
}

export interface BaseWizardStepsProp {
  id: string; // UUID V4
  wizardStepType: WizardStepType;
  title: TFuncKey;
  info?: InfoLink;
  description?: TFuncKey;
}

export interface WizardStepsProp extends BaseWizardStepsProp {
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
  contentData?: (
    | BulletListProp
    | HeaderContentProp
    | ImageContentProp
    | KeyBulletPairsProps
    | PbodayCustomProp
    | PbodyContentProp
    | YoutubeContentProp
  )[];
}

export interface WizardLastStepProp extends BaseWizardStepsProp {
  contentData?: any[];
}

export interface WizardContent {
  wizardId: string; // uuid v4
  wizardLength: number; // total number of wizard steps
  i18nStrings: WizardI18n;
  wizardStepsContent: (WizardStepsProp | WizardLastStepProp)[];
}
