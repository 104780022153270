import { useTranslation } from 'react-i18next';

import { SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { EndSection } from '../../common/EndSection';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz1',
  title: 'Chapters.Lesson1.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson1.Wizard1.videoTitle1',
      youtubeId: 'lutsouJpr7E',
    },
    {
      id: 'wz11',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson1.Wizard1.welcomeText',
    },
    {
      id: 'wz12',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson1.Wizard1.machineLearningRapid',
    },
    {
      id: 'wz13',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz13-1',
          text: 'Chapters.Lesson1.Wizard1.list1',
        },
        {
          id: 'wz13-2',
          text: 'Chapters.Lesson1.Wizard1.list2',
        },
        {
          id: 'wz13-3',
          text: 'Chapters.Lesson1.Wizard1.list3',
        },
        {
          id: 'wz13-4',
          text: 'Chapters.Lesson1.Wizard1.list4',
        },
        {
          id: 'wz13-5',
          text: 'Chapters.Lesson1.Wizard1.list5',
        },
      ],
    },
    {
      id: 'wz14',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson1.Wizard1.machineLearningSubject',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz2',
  title: 'Chapters.Lesson1.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz2001',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      title: 'Chapters.Lesson1.Wizard2.title',
      height: '550px',
      width: '100%',
      youtubeId: '6jOCiXdcg-w',
    },
    {
      id: 'wz202',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson1.Wizard2.content1',
    },
    {
      id: 'wz203',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz203-1',
          text: 'Chapters.Lesson1.Wizard2.list1',
        },
        {
          id: 'wz203-2',
          text: 'Chapters.Lesson1.Wizard2.list2',
        },
        {
          id: 'wz203-3',
          text: 'Chapters.Lesson1.Wizard2.list3',
        },
      ],
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContentWz1',
  title: 'Chapters.Lesson1.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'summaryContentWz10001',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson1.Wizard3.content1',
    },
  ],
};

export const Lesson1 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContentWz2',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'summaryContentWz20003',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson1.Wizard3.whatIsML',
      },
      {
        id: 'summaryContentWz20004',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson1.Wizard3.content2',
      },
      {
        id: 'summaryContentWz20005',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <EndSection
            level="Chapters.Lesson1.Wizard3.level"
            timeRequire="Chapters.Lesson1.Wizard3.time"
            prereq="Chapters.Lesson1.Wizard3.prerequisites"
            onClick={onClick}
          />
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
