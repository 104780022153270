export enum IntroToMLChapterId {
  CHAPTER_1 = 'e3843d2f-3ee9-4867-99e3-aa562547922e',
  CHAPTER_2 = 'c9ccc1c5-d8cb-476b-8bfe-fca6ed562bb0',
  CHAPTER_3 = '89cca255-6d4a-4bc3-8a20-2bd0e2d38878',
  CHAPTER_4 = '53db833a-b430-4677-86a2-2bfec7b4f803',
  CHAPTER_5 = '375734b0-2031-4b40-97b5-47dea82aced2',
  CHAPTER_6 = '4ae2e247-d50f-4223-87de-cc1189fbe18e',
  CHAPTER_7 = '70b67b16-f85c-41d0-8087-3d1ba1c63c08',
  CHAPTER_8 = 'c1619682-7413-4ef3-9fef-9b61667d2041',
  CHAPTER_9 = '5f30baf1-8643-4eba-a50f-df5ddfa77644',
  CHAPTER_10 = 'd656ba9b-52a1-4fd6-b188-81aee89e750e',
  CHAPTER_11 = '2d67c56d-682c-4224-a124-614a40c9391a',
  CHAPTER_12 = '9e781d04-fa17-4ccc-b5d0-15874ba3b30b',
}

// Values will be translation keys
export enum IntroToMLChapterTitle {
  CHAPTER_1 = 'IntroToMLChapters.lessonOutline',
  CHAPTER_2 = 'IntroToMLChapters.whatIsML',
  CHAPTER_3 = 'IntroToMLChapters.componentsOfML',
  CHAPTER_4 = 'IntroToMLChapters.introToTheFiveSteps',
  CHAPTER_5 = 'IntroToMLChapters.defineTheProblem',
  CHAPTER_6 = 'IntroToMLChapters.builtTheDataset',
  CHAPTER_7 = 'IntroToMLChapters.modelTraining',
  CHAPTER_8 = 'IntroToMLChapters.modelEvaluation',
  CHAPTER_9 = 'IntroToMLChapters.modelInference',
  CHAPTER_10 = 'IntroToMLChapters.caseStudiesUsingML',
  CHAPTER_11 = 'IntroToMLChapters.usingMLToPredict',
  CHAPTER_12 = 'IntroToMLChapters.usingMLToDetect',
}
