import { useTranslation } from 'react-i18next';

import { SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { EndSection } from '../../common/EndSection';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz6.18.1',
  title: 'Chapters.Lesson18.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.18.1.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson18.Wizard1.youtubeTitle',
      youtubeId: 'wtRYcwFeUks',
    },
    {
      id: 'wz6.18.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard1.content1',
    },
    {
      id: 'wz6.18.1.30',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        { id: 'wz6.18.1.30-1', text: 'Chapters.Lesson18.Wizard1.list1' },
        { id: 'wz6.18.1.30-2', text: 'Chapters.Lesson18.Wizard1.list2' },
      ],
    },
    {
      id: 'wz6.18.1.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard1.content2',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz6.18.2',
  title: 'Chapters.Lesson18.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.18.2.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson18.Wizard2.youtubeTitle',
      youtubeId: 'GtGXORN7Ju8',
    },
    {
      id: 'wz6.18.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard2.content1',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz6.18.3',
  title: 'Chapters.Lesson18.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.18.3.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard3.content1',
    },
    {
      id: 'wz6.18.3.20',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson18.Wizard3.youtubeTitle',
      youtubeId: 'YjSD1el5AQ0',
    },
    {
      id: 'wz6.18.3.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard3.content2',
    },
  ],
};

const wz4: WizardStepsProp = {
  id: 'wz6.18.4',
  title: 'Chapters.Lesson18.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.18.4.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard4.content1',
    },
    {
      id: 'wz6.18.4.20',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson18.Wizard4.youtubeTitle',
      youtubeId: 'gKRtfO-r8NI',
    },
    {
      id: 'wz6.18.4.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard4.content2',
    },
    {
      id: 'wz6.18.4.40',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        { id: 'wz6.18.4.40-1', text: 'Chapters.Lesson18.Wizard4.list1' },
        { id: 'wz6.18.4.40-2', text: 'Chapters.Lesson18.Wizard4.list2' },
      ],
    },
    {
      id: 'wz6.18.4.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard4.content3',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz6',
  title: 'Chapters.Lesson18.Wizard5.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'summaryContent1.wz6.18.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson18.Wizard5.content1',
    },
  ],
};

export const Lesson18 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent1.wz6.2',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'summaryContent1.wz6.2.18.10',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson18.Wizard5.heading1',
      },
      {
        id: 'summaryContent1.wz6.2.18.20',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson18.Wizard5.content2',
      },
      {
        id: 'summaryContent1.wz6.2.18.30',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <EndSection
            level="Chapters.Lesson18.Wizard5.level"
            timeRequire="Chapters.Lesson18.Wizard5.time"
            prereq="Chapters.Lesson18.Wizard5.prerequisites"
            onClick={onClick}
          />
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(wz4.title) as string,
          content: <WizardContent {...wz4} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
