/* eslint-disable no-case-declarations */
/* istanbul ignore file */
import { Container, Header, ColumnLayout, Tiles } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { DeepRacerLiteTypes } from 'types';

interface QuizCardProps {
  header: string;
  question: any; // todo use type provided by asteroid backend client - DeepRacerLiteTypes.LmsQuizAssessmentTrackerQuestionData
  onChange(value: any): void;
}

const QuizCard: React.FC<QuizCardProps> = ({ header, question, onChange }: QuizCardProps) => {
  const _question = { ...question, question: JSON.parse(question.question) };
  const [currentAnswer, setCurrentAnswer] = useState<string>(_question?.selectedAnswer?.[0] || null);
  const getAnswerInputField = () => {
    switch (_question.questionType) {
      case DeepRacerLiteTypes.QuestionType.MULTIPLE_CHOICE_WITH_SINGLE_ANSWER:
        const tileItems = _question.question.options.map((option) => {
          return {
            label: option.answerText,
            value: option.answerId,
          };
        });
        return (
          <div>
            <Tiles
              columns={1}
              onChange={({ detail }) => {
                setCurrentAnswer(detail.value);
                onChange(detail.value);
              }}
              value={currentAnswer}
              items={tileItems}
            />
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <Container header={<Header variant="h2">{header}</Header>}>
      <b>{_question.question.questionText}</b>
      <br />
      <br />
      <ColumnLayout columns={2}>{getAnswerInputField()}</ColumnLayout>
    </Container>
  );
};

export default QuizCard;
