import { useCallback, useState } from 'react';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Link from '@amzn/awsui-components-react/polaris/link';
import { GetAliasResponse } from '@amzn/aws-deep-racer-lite-external-service-type-script-client';
import { useTranslation } from 'react-i18next';

import Avatar from 'common/components/Avatar';
import AvatarConfigDialog from 'common/components/AvatarConfigDialog';
import { getAvatarConfig, getCountry } from 'store/profile';
import { useAppSelector } from 'store/utils/hooks';

interface AvatarFieldProps {
  userData: GetAliasResponse | undefined | null;
}

const ProfileAvatar: React.FC<AvatarFieldProps> = (props) => {
  const [isAvatarConfigDialogVisible, setIsAvatarConfigDialogVisible] = useState(false);
  const avatarProps = useAppSelector(getAvatarConfig);
  const countryCode = useAppSelector(getCountry);
  const { t } = useTranslation();

  const openAvatarConfigDialog = useCallback(() => {
    setIsAvatarConfigDialogVisible(true);
  }, []);

  return (
    <>
      <AvatarConfigDialog
        visible={isAvatarConfigDialogVisible}
        setVisible={setIsAvatarConfigDialogVisible}
        competitionCountryCode={props?.userData?.CompetitionCountryCode}
      />
      <ColumnLayout columns={2}>
        <Avatar
          data-test-id="profilePageAvatar"
          {...avatarProps}
          countryCode={(props?.userData?.CompetitionCountryCode || countryCode) as string}
        />
        <Link onFollow={openAvatarConfigDialog}>{t('ProfilePage.EditAvatar')}</Link>
      </ColumnLayout>
    </>
  );
};

export default ProfileAvatar;
