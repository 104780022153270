import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '../index';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// Use useAppDispatch, useAppSelector instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * A custom hook that builds on useLocation to parse the query string for you.
 *
 * Example usage to get "redirectUrl" query param:
 *
 * const query = useQuery();
 *
 * const redirectUrl = query.get('redirectUrl');
 */
export const useQuery = (): URLSearchParams => {
  const location = useLocation();
  return React.useMemo(() => new URLSearchParams(location.search), [location.search]);
};
