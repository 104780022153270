import { Auth } from '@aws-amplify/auth';
import { createAsyncThunk, createSlice, createAction, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { AuthAction, AuthException, AUTH_SLICE, SUCCESS, ALREADY_SIGNUP_ERROR } from 'common/constants';
import { history } from 'common/utils/history';
import pageCatalog from 'pages/pageCatalog';
import { RootState } from 'store';
import {
  clearAllNotifications,
  displayErrorNotification,
  displaySuccessNotification,
  displayWarningNotification,
} from 'store/notifications';
import { getAuthenticatedUser, getUserAttributes } from 'store/utils/authUtils';
import { addDefaultLoadingCases } from 'store/utils/loadingCases';
import { CodeDeliveryDetails } from 'types/auth';
import { getUsername } from './authSelectors';
import i18n from 'i18n';
import { ALREADY_SIGNED_UP_NOTIFICATION, COGNITO_THROTTLE_NOTIFICATION } from 'common/components/Notifications';

export interface AuthState {
  codeDeliveryDetails: CodeDeliveryDetails | null;
  error: string | null;
  forgotPasswordEmail: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  isResendingCode: boolean;
  registerAccountEmail: string | null;
  username: string | null;
}

export const initialState: AuthState = {
  codeDeliveryDetails: null,
  error: null,
  forgotPasswordEmail: null,
  isAuthenticated: false,
  isLoading: false,
  isResendingCode: false,
  registerAccountEmail: null,
  username: null,
};

export const login = createAsyncThunk<string, { username: string; password: string }>(
  AuthAction.LOGIN,
  async ({ username, password }) => {
    const user = await Auth.signIn(username, password);
    const userAttributes = await getUserAttributes(user);
    return userAttributes.email;
  }
);

export const logout = createAsyncThunk(AuthAction.LOGOUT, async () => {
  await Auth.signOut();
  return SUCCESS;
});

export const register = createAsyncThunk<{ email: string }, { username: string; password: string }>(
  AuthAction.INIT_REGISTRATION,
  async ({ username, password }) => {
    await Auth.signUp(username, password);
    return { email: username };
  }
);

export const confirmRegistration = createAsyncThunk<string, { code: string; username: string }>(
  AuthAction.CONFIRM_REGISTRATION,
  async ({ code, username }) => {
    await Auth.confirmSignUp(username as string, code);
    return SUCCESS;
  }
);

export const resendRegistrationCode = createAsyncThunk<CodeDeliveryDetails, { username: string }>(
  AuthAction.RESEND_REGISTRATION_CODE,
  async ({ username }) => {
    const resendRegistrationCodeResponse: CodeDeliveryDetails = await Auth.resendSignUp(username as string);
    return resendRegistrationCodeResponse;
  }
);

export const changePassword = createAsyncThunk<string, { oldPassword: string; newPassword: string }>(
  AuthAction.CHANGE_PASSWORD,
  async ({ oldPassword, newPassword }) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    return SUCCESS;
  }
);

export const forgotPasswordRequest = createAsyncThunk<
  { codeDeliveryDetails: CodeDeliveryDetails; email: string },
  { email: string }
>(AuthAction.FORGOT_PASSWORD_REQUEST, async ({ email }) => {
  const { CodeDeliveryDetails: codeDeliveryDetails } = await Auth.forgotPassword(email);
  return { codeDeliveryDetails, email };
});

export const handleForgotPasswordRequest = createAsyncThunk<void, { email: string }>(
  AuthAction.HANDLE_FORGOT_PASSWORD_REQUEST,
  async ({ email }, { dispatch }) => {
    return dispatch(forgotPasswordRequest({ email }))
      .then(unwrapResult)
      .then(() => {
        history.push(pageCatalog.ForgotPasswordReset.getPath());
      })
      .catch((e) => {
        dispatch(displayErrorNotification({ content: e?.message }));
      });
  }
);

export const forgotPasswordReset = createAsyncThunk<string, { email: string; code: string; newPassword: string }>(
  AuthAction.FORGOT_PASSWORD_RESET,
  async ({ email, code, newPassword }) => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return SUCCESS;
  }
);

export const checkAuthStatus = createAsyncThunk<string, void, { state: RootState }>(
  AuthAction.CHECK_AUTH_STATUS,
  async (_arg, { getState }) => {
    const user = await getAuthenticatedUser();
    const state = getState();
    const storedUsername = getUsername(state);
    if (!storedUsername) {
      const userAttributes = await getUserAttributes(user);
      return userAttributes.email;
    }
    return storedUsername;
  }
);

export const deleteUser = createAsyncThunk(AuthAction.DELETE_USER, async (_arg, { dispatch }) => {
  const user = await getAuthenticatedUser();
  user.deleteUser((error) => {
    if (error) {
      throw error;
    }
    dispatch(logout());
  });
});

export const setRegisterAccountEmail = createAction<{ email: string }>(AuthAction.SET_REGISTER_ACCOUNT_EMAIL);

export const handleSignUpErrors = createAsyncThunk<void, { username: string; error: any }>(
  AuthAction.SIGN_UP_ERROR,
  async ({ username, error }, { dispatch }) => {
    if (error?.code === AuthException.USERNAME_EXISTS) {
      dispatch(displayWarningNotification({ content: i18n.t('needToVerifyEmail') }));
      dispatch(setRegisterAccountEmail({ email: username }));
      dispatch(resendRegistrationCode({ username }))
        .then(unwrapResult)
        .then(() => {
          dispatch(displaySuccessNotification({ content: i18n.t('codeSentSuccessfully', { username }) }));
        })
        .catch((err) => {
          dispatch(displayErrorNotification({ content: err?.message }));
        });
      const searchParams = new URLSearchParams(history.location.search);
      history.push({ pathname: pageCatalog.VerifyEmail.getPath(), search: searchParams.toString() });
    } else if (error?.message.includes(AuthException.NOT_AUTHORIZED)) {
      // https://code.amazon.com/packages/AwsAsteroidExternalServiceLambda/blobs/mainline/--/src/com/amazon/aws/asteroid/external/service/cognito/triggers/lambda/PreSignUpLambda.java
      dispatch(displayErrorNotification({ content: i18n.t('userNotAuthorized') }));
    } else if (error?.code === AuthException.TRANSIENT_ERROR) {
      dispatch(displayErrorNotification({ content: i18n.t('tryAgainLater') }));
    } else if (error?.code === AuthException.THROTTLING_EXCEPTION) {
      dispatch(displayErrorNotification(COGNITO_THROTTLE_NOTIFICATION));
    } else if (error?.message?.includes(AuthException.DOMAIN_NOT_ALLOWED)) {
      dispatch(displayErrorNotification({ content: i18n.t('domainNotAllowed') }));
    } else {
      dispatch(displayErrorNotification({ content: error?.message }));
    }
  }
);

export const handleSignInErrors = createAsyncThunk<void, { username: string; error: any }>(
  AuthAction.SIGN_IN_ERROR,
  async ({ username, error }, { dispatch }) => {
    if (error.message.includes(AuthException.EMAIL_NOT_VERIFIED)) {
      dispatch(displayWarningNotification({ content: i18n.t('needToVerifyEmail') }));
      dispatch(setRegisterAccountEmail({ email: username }));
      dispatch(resendRegistrationCode({ username }))
        .then(unwrapResult)
        .then(() => {
          dispatch(displaySuccessNotification({ content: i18n.t('codeSentSuccessfully', { username }) }));
          const searchParams = new URLSearchParams(history.location.search);
          history.push({ pathname: pageCatalog.VerifyEmail.getPath(), search: searchParams.toString() });
        })
        .catch((err) => {
          dispatch(displayErrorNotification({ content: err?.message }));
        });
    } else if (error.message.includes(AuthException.NOT_AUTHORIZED)) {
      // https://code.amazon.com/packages/AwsAsteroidExternalServiceLambda/blobs/mainline/--/src/com/amazon/aws/asteroid/external/service/cognito/triggers/lambda/PreAuthLambda.java
      dispatch(displayErrorNotification({ content: i18n.t('userNotAuthorized') }));
    } else if (error?.code === AuthException.THROTTLING_EXCEPTION) {
      dispatch(displayErrorNotification(COGNITO_THROTTLE_NOTIFICATION));
    } else {
      dispatch(displayErrorNotification({ content: error?.message }));
    }
  }
);

export const signUp = createAsyncThunk<void, { username: string; password: string }>(
  AuthAction.SIGN_UP,
  async ({ username, password }, { dispatch }) => {
    dispatch(clearAllNotifications());
    return dispatch(register({ username, password }))
      .then(unwrapResult)
      .then(() => {
        const searchParams = new URLSearchParams(history.location.search);
        history.push({ pathname: pageCatalog.VerifyEmail.getPath(), search: searchParams.toString() });
      })
      .catch((error) => {
        dispatch(handleSignUpErrors({ username, error }));
      });
  }
);

export const signIn = createAsyncThunk<ReturnType<typeof unwrapResult>, { username: string; password: string }>(
  AuthAction.SIGN_IN,
  async ({ username, password }, { dispatch }) => {
    dispatch(clearAllNotifications());
    return dispatch(login({ username, password }))
      .then(unwrapResult)
      .catch((error) => {
        dispatch(handleSignInErrors({ username, error }));
      });
  }
);

export const verifyEmail = createAsyncThunk<void, { code: string; username: string }>(
  AuthAction.VERIFY_EMAIL,
  async ({ username, code }, { dispatch }) => {
    dispatch(clearAllNotifications());
    return dispatch(confirmRegistration({ username, code }))
      .then(unwrapResult)
      .then((result) => {
        if (result) {
          dispatch(displaySuccessNotification({ content: i18n.t('accountCreateSuccessfully') }));
          const searchParams = new URLSearchParams(history.location.search);
          history.push({ pathname: pageCatalog.SignIn.getPath(), search: searchParams.toString() });
        }
      })
      .catch((error) => {
        /**
         * Already signed up account also get NotAuthorizedException when we try to confirm registration.
         * Translate the error to user-friendly message.
         */
        if (error?.message === ALREADY_SIGNUP_ERROR) {
          dispatch(displayErrorNotification(ALREADY_SIGNED_UP_NOTIFICATION));
        } else if (error?.code === AuthException.THROTTLING_EXCEPTION) {
          dispatch(displayErrorNotification(COGNITO_THROTTLE_NOTIFICATION));
        } else {
          dispatch(displayErrorNotification({ content: error?.message }));
        }
      });
  }
);

export const resendCode = createAsyncThunk<void, { username: string }>(
  AuthAction.HANDLE_RESEND_CODE,
  async ({ username }, { dispatch }) => {
    dispatch(clearAllNotifications());
    return dispatch(resendRegistrationCode({ username }))
      .then(unwrapResult)
      .then(() => {
        dispatch(displaySuccessNotification({ content: i18n.t('codeResentSuccessfully', { username }) }));
      })
      .catch((error) => {
        dispatch(displayErrorNotification({ content: error?.message }));
      });
  }
);

const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState,
  reducers: {
    setRegisterAccountEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.registerAccountEmail = action.payload.email;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isAuthenticated = true;
        state.username = payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
      })
      .addCase(checkAuthStatus.fulfilled, (state, { payload }) => {
        state.isAuthenticated = true;
        state.username = payload;
      })
      .addCase(checkAuthStatus.rejected, (state) => {
        state.isAuthenticated = false;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.registerAccountEmail = payload.email;
      })
      .addCase(resendCode.fulfilled, (state) => {
        state.isResendingCode = false;
      })
      .addCase(resendCode.rejected, (state) => {
        state.isResendingCode = false;
      })
      .addCase(resendCode.pending, (state) => {
        state.isResendingCode = true;
      })
      .addCase(forgotPasswordRequest.fulfilled, (state, { payload }) => {
        state.codeDeliveryDetails = payload.codeDeliveryDetails;
        state.forgotPasswordEmail = payload.email;
      });

    // This handles setting state.isLoading and state.error for all actions
    // depending on status [fulfilled, pending, rejected]
    addDefaultLoadingCases(builder, AUTH_SLICE);
  },
});

export default authSlice.reducer;
