import * as React from 'react';
import './style.css';
import shaka from 'shaka-player';

export interface VideoPlayerProps {
  videoElementId: string;
}

/* tslint:disable-next-line:variable-name */
export const VideoPlayer: React.SFC<VideoPlayerProps> = (props: VideoPlayerProps) => {
  return <video id={props.videoElementId} controls autoPlay className="videoFrame" />;
};

export const InitPlayer = (videoElementId: string, url: string): any => {
  const videoElement = document.getElementById(videoElementId);
  const player = new shaka.Player(videoElement);
  player.addEventListener('error', (error: any) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });
  player.load(url).catch((error: any) => {
    // eslint-disable-next-line no-console
    console.log(error);
  });
  return player;
};

export const StopPlayer = (player: any): any => {
  if (player) {
    player.unload();
    player.destroy();
  }
  return null;
};
