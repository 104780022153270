import { useTranslation } from 'react-i18next';
import Header from '@amzn/awsui-components-react/polaris/header';
import { useGetAlias } from 'store/leaderboard';
import { InfoLink } from 'common/components/HelpPanel';
import { STUDENT_LEAGUE_HELP_PANEL_URL } from 'common/constants';

export const PageHeader = (): JSX.Element => {
  const { t } = useTranslation('studentLeague');
  const { data: profileData } = useGetAlias();
  return (
    <Header variant="h1" info={<InfoLink helpId={STUDENT_LEAGUE_HELP_PANEL_URL} />}>
      {t('Header', { year: '2024', name: profileData?.Alias })}
    </Header>
  );
};
