import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ExternalLinkItem } from '../links';
import { StorageUtil } from 'common/utils/storage';

export const CookiePrefrences = (): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    StorageUtil.consentHandler.checkForCookieConsent();
  }, []);

  return (
    <ExternalLinkItem
      external={false}
      color="inverted"
      href={''}
      text={t('footer.cookiePreferences')}
      onFollow={() => {
        StorageUtil.consentHandler.customizeCookies();
      }}
    />
  );
};
