import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CONFETTI_SLICE } from 'common/constants';

export interface ConfettiState {
  isConfettiRunning: boolean;
}

const initialState: ConfettiState = {
  isConfettiRunning: false,
};

export const confettiSlice = createSlice({
  name: CONFETTI_SLICE,
  initialState,
  reducers: {
    toggleConfettiRunning: (state, action: PayloadAction<{ isRunning: boolean }>) => {
      state.isConfettiRunning = action.payload.isRunning;
    },
  },
});

export const { toggleConfettiRunning } = confettiSlice.actions;

export default confettiSlice.reducer;
