import { getLocalFeatureFlagOverride } from './localOverrideFlags';
import { FeatureFlagsTreatment } from './types';

/**
 * More information on using feature flags can be found here:
 * https://w.amazon.com/index.php/NeoWeblab
 * @param feature: string -- Name of Feature flag to check
 * @returns -- returns treatment for the flags based on FeatureFlagsTreatment,
 *             if no treatment is found Control (C) is returned.
 */
export const getAllocation = (feature: string): FeatureFlagsTreatment => {
  const localFlags = getLocalFeatureFlagOverride();

  return localFlags[feature] || FeatureFlagsTreatment.Control;
};
