import React from 'react';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import AWSLogo from 'common/assets/AWS_Logo.svg';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { getProfileData } from 'store/profile';
import { useHistory } from 'react-router-dom';
import pageCatalog from 'pages/pageCatalog';
import { logout } from 'store/auth';
import { clearAllNotifications } from 'store/notifications';
import { toggleConfettiRunning } from 'store/confetti';
import { isDarkModeEnabled } from 'utils/featureFlags/currentFeatureFlags';
import { getIsDarkMode, setDarkMode } from 'store/darkMode';

function TopNavBar(): JSX.Element {
  const history = useHistory();
  const profileData = useAppSelector(getProfileData);
  const isDarkMode = useAppSelector(getIsDarkMode);
  const dispatch = useAppDispatch();
  const handleProfileDropdownButtonClick = (e: { detail: { id: string } }) => {
    switch (e?.detail?.id) {
      case 'profile':
        history.push(pageCatalog.Profile.getPath());
        return;
      case 'account':
        history.push(pageCatalog.Account.getPath());
        return;
      case 'contact-us':
        history.push(pageCatalog.ContactUs.getPath());
        return;
      case 'signout':
        dispatch(logout()).then(() => {
          dispatch(clearAllNotifications());
          history.push(pageCatalog.SignIn.getPath());
        });
        dispatch(
          toggleConfettiRunning({
            isRunning: false,
          })
        );
        return;
      default:
        return;
    }
  };
  const handleThemeDropDownButtonClick = (e: { detail: { id: string } }) => {
    dispatch(
      setDarkMode({
        isDarkMode: e?.detail?.id === 'dark-theme',
      })
    );
  };
  const topNavUtilities: any = [];
  if (isDarkModeEnabled()) {
    topNavUtilities.push({
      type: 'menu-dropdown',
      text: `${isDarkMode ? 'Dark' : 'Light'}mode`,
      iconName: 'settings',
      onItemClick: handleThemeDropDownButtonClick,
      items: [
        {
          id: 'light-theme',
          text: 'Light',
        },
        { id: 'dark-theme', text: 'Dark' },
      ],
    });
  }
  if (profileData) {
    topNavUtilities.push({
      type: 'menu-dropdown',
      text: profileData?.nickName,
      description: profileData?.associatedIdentities?.[0]?.associatedIdentity,
      iconName: 'user-profile',
      onItemClick: handleProfileDropdownButtonClick,
      items: [
        {
          id: 'profile',
          text: 'Profile',
        },
        { id: 'account', text: 'Account' },
        { id: 'signout', text: 'Sign out' },
      ],
    });
  }
  return (
    <TopNavigation
      id="headerSection"
      identity={{
        href: pageCatalog.StudentHome.getPath(),
        title: '',
        logo: {
          src: AWSLogo,
          alt: 'AWS logo',
        },
      }}
      utilities={topNavUtilities}
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu',
      }}
    />
  );
}

export default TopNavBar;
