import { Trans, useTranslation } from 'react-i18next';

import { Button, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz1.9',
  title: 'Chapters.Lesson9.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.9.1.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson9.Wizard1.videoTitle',
      youtubeId: 'MfXyh1XaKSc',
    },
    {
      id: 'wz1.9.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson9.Wizard1.content1',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz2.9',
  title: 'Chapters.Lesson9.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz2.9.2.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.stepSiter,
      imageAltText: 'Chapters.Lesson9.Wizard2.imageAltText',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz2.9.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson9.Wizard2.content1',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz1.9',
  title: 'Chapters.Lesson9.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'summaryContent1.wz1.9.001',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson9.Wizard3.content1',
    },
    {
      id: 'summaryContent1.wz1.9.002',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson9.Wizard3.content2',
    },
    {
      id: 'summaryContent1.wz1.9.003',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson9.Wizard3.content3',
    },
    {
      id: 'summaryContent1.wz1.9.004',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson9.Wizard3.content4',
    },
  ],
};

export const Lesson9 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz1.9',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'summaryContent2.wz1.9.1.001',
        widgetType: WizardContentType.HEADER,
        variant: 'h3',
        text: 'Chapters.Lesson9.Wizard3.whatsNext',
      },
      {
        id: 'summaryContent2.wz1.9.1.002',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson9.Wizard3.heading1',
      },
      {
        id: 'summaryContent2.wz1.9.1.003',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson9.Wizard3.content5',
      },
      {
        id: 'summaryContent2.wz1.5.1.003',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <Button variant="normal" onClick={onClick}>
            <Trans i18nKey="WizardI18nStrings.StartChapter"></Trans>
          </Button>
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
