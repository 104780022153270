/* istanbul ignore file */
import i18n from 'i18n';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createLeaderboardSubmission,
  getRankedUserSubmission,
  listLeaderboardSubmissions,
} from 'services/deepRacerLite/submissionApi';
import { displayErrorNotification, displaySuccessNotification } from 'store/notifications';
import { useAppDispatch } from 'store/utils/hooks';

export enum LeaderboardSubmissionStateKeysEnum {
  LeaderboardSubmissions = 'ListLeaderboardSubmissions',
  LeagueTopRacerSubmissions = 'LeagueTopRacerSubmissions',
  RankedUserSubmission = 'RankedUserSubmission',
}

export const useListLeaderboardSubmissions = (leaderboardArn = '') => {
  const dispatch = useAppDispatch();
  return useQuery(
    [LeaderboardSubmissionStateKeysEnum.LeaderboardSubmissions, leaderboardArn],
    () => listLeaderboardSubmissions(leaderboardArn),
    {
      enabled: !!leaderboardArn,
      onError: (error: any) => {
        dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      },
    }
  );
};

export const useGetLeagueTopRacerSubmissions = (leaderboardArn = '') => {
  const dispatch = useAppDispatch();
  return useQuery(
    [LeaderboardSubmissionStateKeysEnum.LeagueTopRacerSubmissions, leaderboardArn],
    () => listLeaderboardSubmissions(leaderboardArn, true),
    {
      enabled: !!leaderboardArn,
      onError: (error: any) => {
        dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      },
    }
  );
};

export const useGetRankedUserSubmission = (leaderboardArn = '') => {
  const dispatch = useAppDispatch();
  return useQuery(
    [LeaderboardSubmissionStateKeysEnum.RankedUserSubmission, leaderboardArn],
    () => getRankedUserSubmission(leaderboardArn),
    {
      enabled: !!leaderboardArn,
      onError: (error: any) => {
        dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      },
    }
  );
};

export const useCreateLeaderboardSubmission = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    ({ leaderboardArn, modelArn, termsAccepted, competitionCountryCode }: any) =>
      createLeaderboardSubmission(leaderboardArn, modelArn, termsAccepted, competitionCountryCode),
    {
      onSuccess: () => {
        dispatch(displaySuccessNotification({ content: i18n.t('APIsSuccessMessages.createSubmission') }));
        queryClient.invalidateQueries([LeaderboardSubmissionStateKeysEnum.LeaderboardSubmissions]);
      },
      onError: (error: any) => {
        dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      },
    }
  );
};
