import { useField } from 'formik';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { InputFormField, SelectFormField } from 'common/components/FormFields';
import { CompleteSignUpFields, MAJOR_LIST, OTHER_VAL } from 'common/constants';

export const MajorFieldsView = (): JSX.Element => {
  const { t } = useTranslation();
  const [majorField] = useField({ name: CompleteSignUpFields.MAJOR });
  return (
    <SpaceBetween size="m">
      <SelectFormField
        filteringType="auto"
        label={t('CompleteSignUp.chooseMajorLabel')}
        description={<Trans i18nKey="CompleteSignUp.chooseMajorDesc" />}
        placeholder={t('CompleteSignUp.majorPlaceholder')}
        options={MAJOR_LIST}
        name={CompleteSignUpFields.MAJOR}
      />
      {majorField.value.value === OTHER_VAL && (
        <Box margin={{ top: 's' }}>
          <InputFormField
            description={t('CompleteSignUp.enterMajor')}
            placeholder={t('CompleteSignUp.majorPlaceholder')}
            name={CompleteSignUpFields.MAJOR_ENTERED}
            type="text"
          />
        </Box>
      )}
    </SpaceBetween>
  );
};

export const MajorFields = memo(MajorFieldsView);
