import { AnyAction, AsyncThunk } from '@reduxjs/toolkit';
import { FULFILLED, PENDING, REJECTED } from 'common/constants';

export type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export function isPendingActionForSlice(action: AnyAction, sliceName: string): action is PendingAction {
  return action.type.endsWith(`/${PENDING}`) && action.type.startsWith(sliceName);
}

export function isRejectedActionForSlice(action: AnyAction, sliceName: string): action is RejectedAction {
  return action.type.endsWith(`/${REJECTED}`) && action.type.startsWith(sliceName);
}

export function isFulfilledActionForSlice(action: AnyAction, sliceName: string): action is FulfilledAction {
  return action.type.endsWith(`/${FULFILLED}`) && action.type.startsWith(sliceName);
}

export interface Async {
  isLoading: boolean;
  error?: string | null;
}

export interface Resource<R> {
  item?: R | null;
}

export interface ResourceList<R> {
  items?: R[] | null;
}
export interface ResourceMap<R> {
  items?: Record<string, R> | null;
}

export interface AsyncResource<R> extends Async, Resource<R> {}
export interface AsyncResourceList<R> extends Async, ResourceList<R> {}
export interface AsyncResourceMap<R> extends Async, ResourceMap<R> {}
