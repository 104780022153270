import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { emptyResource, emptyResourceList, LmsQuizAction, LMS_QUIZ_SLICE } from 'common/constants';
import {
  getAssessmentTracker,
  getAssessmentTrackers,
  getCurrentQuestion,
  getNextQuestion,
  getPrevQuestion,
  patchFinishAssessmentTracker,
  patchStartAssessmentTracker,
  postCreateAssessmentTracker,
  postAnswerQuestion,
} from 'services/deepRacerLite';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResource, AsyncResourceList } from 'store/types';
import { addLoadingCasesWithNestedListState, addLoadingCasesWithNestedState } from 'store/utils/loadingCases';
import { DeepRacerLiteTypes } from 'types';

export interface LmsQuizState {
  assessmentTrackers: AsyncResourceList<DeepRacerLiteTypes.LmsQuizAssessmentTrackerData>;
  assessmentTracker: AsyncResource<DeepRacerLiteTypes.LmsQuizAssessmentTrackerData>;
  createAssessmentTracker: AsyncResource<DeepRacerLiteTypes.LmsQuizAssessmentTrackerData>;
  startAssessmentTracker: AsyncResource<DeepRacerLiteTypes.LmsQuizAssessmentTrackerData>;
  finishAssessmentTracker: AsyncResource<DeepRacerLiteTypes.LmsQuizAssessmentTrackerData>;
  question: AsyncResource<DeepRacerLiteTypes.LmsQuizAssessmentTrackerQuestionData>;
  postAnswer: AsyncResource<DeepRacerLiteTypes.StringList>;
}

const initialState: LmsQuizState = {
  // todo: change emptyResourceList itself
  // todo: not sure of effect on other pages, adding here for now
  assessmentTrackers: { ...emptyResourceList, items: null },
  assessmentTracker: emptyResource,
  createAssessmentTracker: emptyResource,
  startAssessmentTracker: emptyResource,
  finishAssessmentTracker: emptyResource,
  question: emptyResource,
  postAnswer: emptyResource,
};

export const fetchAssessmentTrackers = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerList | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.GET_ASSESSMENT_TRACKERS, async ({ assessmentId }, { dispatch }) => {
  try {
    const result = await getAssessmentTrackers(assessmentId);
    return result.quizAssessmentTrackers;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const fetchAssessmentTracker = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerData | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
    assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.GET_ASSESSMENT_TRACKER, async ({ assessmentId, assessmentTrackerId }, { dispatch }) => {
  try {
    const result = await getAssessmentTracker(assessmentId, assessmentTrackerId);
    return result.quizAssessmentTracker;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const createAssessmentTracker = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerData | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.CREATE_ASSESSMENT_TRACKER, async ({ assessmentId }, { dispatch }) => {
  try {
    const result = await postCreateAssessmentTracker(assessmentId);
    return result.quizAssessmentTracker;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const startAssessmentTracker = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerData | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
    assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.START_ASSESSMENT_TRACKER, async ({ assessmentId, assessmentTrackerId }, { dispatch }) => {
  try {
    const result = await patchStartAssessmentTracker(assessmentId, assessmentTrackerId);
    return result.quizAssessmentTracker;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const finishAssessmentTracker = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerData | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
    assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.FINISH_ASSESSMENT_TRACKER, async ({ assessmentId, assessmentTrackerId }, { dispatch }) => {
  try {
    const result = await patchFinishAssessmentTracker(assessmentId, assessmentTrackerId);
    return result.quizAssessmentTracker;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const fetchCurrentQuestion = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerQuestionData | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
    assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.GET_CURRENT_QUESTION, async ({ assessmentId, assessmentTrackerId }, { dispatch }) => {
  try {
    const result = await getCurrentQuestion(assessmentId, assessmentTrackerId);
    return result.quizAssessmentTrackerQuestion;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const fetchNextQuestion = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerQuestionData | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
    assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.GET_NEXT_QUESTION, async ({ assessmentId, assessmentTrackerId }, { dispatch }) => {
  try {
    const result = await getNextQuestion(assessmentId, assessmentTrackerId);
    return result.quizAssessmentTrackerQuestion;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const fetchPrevQuestion = createAsyncThunk<
  DeepRacerLiteTypes.LmsQuizAssessmentTrackerQuestionData | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
    assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid;
  }
>(LmsQuizAction.GET_PREV_QUESTION, async ({ assessmentId, assessmentTrackerId }, { dispatch }) => {
  try {
    const result = await getPrevQuestion(assessmentId, assessmentTrackerId);
    return result.quizAssessmentTrackerQuestion;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const postAnswer = createAsyncThunk<
  string[] | undefined,
  {
    assessmentId: DeepRacerLiteTypes.AsteroidUuid;
    assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid;
    questionNo: DeepRacerLiteTypes.Long;
    answers: DeepRacerLiteTypes.StringList;
  }
>(LmsQuizAction.POST_ANSWER, async ({ assessmentId, assessmentTrackerId, questionNo, answers }, { dispatch }) => {
  try {
    const result = await postAnswerQuestion(assessmentId, assessmentTrackerId, questionNo, answers);
    return result.answers;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const lmsQuizSlice = createSlice({
  name: LMS_QUIZ_SLICE,
  initialState,
  reducers: {
    clearAssessmentTrackers: (state) => {
      state.assessmentTrackers = { ...emptyResourceList, items: null };
    },
    clearAssessmentTracker: (state) => {
      state.assessmentTracker = emptyResource;
    },
    clearQuestion: (state) => {
      state.question = emptyResource;
    },
    clearCreateAssessmentTracker: (state) => {
      state.createAssessmentTracker = emptyResource;
    },
    clearStartAssessmentTracker: (state) => {
      state.startAssessmentTracker = emptyResource;
    },
    clearFinishAssessmentTracker: (state) => {
      state.finishAssessmentTracker = emptyResource;
    },
    clearPostAnswer: (state) => {
      state.postAnswer = emptyResource;
    },
  },
  extraReducers: (builder) => {
    addLoadingCasesWithNestedListState(
      builder,
      LmsQuizAction.GET_ASSESSMENT_TRACKERS,
      LMS_QUIZ_SLICE,
      'assessmentTrackers'
    );
    addLoadingCasesWithNestedState(builder, LmsQuizAction.GET_ASSESSMENT_TRACKER, LMS_QUIZ_SLICE, 'assessmentTracker');
    addLoadingCasesWithNestedState(
      builder,
      LmsQuizAction.CREATE_ASSESSMENT_TRACKER,
      LMS_QUIZ_SLICE,
      'createAssessmentTracker'
    );
    addLoadingCasesWithNestedState(
      builder,
      LmsQuizAction.START_ASSESSMENT_TRACKER,
      LMS_QUIZ_SLICE,
      'startAssessmentTracker'
    );
    addLoadingCasesWithNestedState(
      builder,
      LmsQuizAction.FINISH_ASSESSMENT_TRACKER,
      LMS_QUIZ_SLICE,
      'finishAssessmentTracker'
    );
    addLoadingCasesWithNestedState(builder, LmsQuizAction.GET_CURRENT_QUESTION, LMS_QUIZ_SLICE, 'question');
    addLoadingCasesWithNestedState(builder, LmsQuizAction.GET_NEXT_QUESTION, LMS_QUIZ_SLICE, 'question');
    addLoadingCasesWithNestedState(builder, LmsQuizAction.GET_PREV_QUESTION, LMS_QUIZ_SLICE, 'question');
    addLoadingCasesWithNestedState(builder, LmsQuizAction.POST_ANSWER, LMS_QUIZ_SLICE, 'postAnswer');
  },
});

export const {
  clearAssessmentTrackers,
  clearAssessmentTracker,
  clearQuestion,
  clearCreateAssessmentTracker,
  clearStartAssessmentTracker,
  clearFinishAssessmentTracker,
  clearPostAnswer,
} = lmsQuizSlice.actions;

export default lmsQuizSlice.reducer;
