import { useField } from 'formik';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { SelectSchool } from 'common/components/CompleteSignUp';
import { InputFormField } from 'common/components/FormFields';
import { CompleteSignUpFields, OTHER_VAL } from 'common/constants';

export const SchoolFieldsView = (): JSX.Element => {
  const { t } = useTranslation();
  const [schoolField] = useField({ name: CompleteSignUpFields.SCHOOL });
  return (
    <SpaceBetween size="m">
      <SelectSchool
        label={t('CompleteSignUp.chooseSchoolLabel')}
        description={<Trans i18nKey="CompleteSignUp.chooseSchoolDesc" />}
        placeholder={t('CompleteSignUp.schoolPlaceholder')}
        name={CompleteSignUpFields.SCHOOL}
      />
      {schoolField.value?.value === OTHER_VAL && (
        <Box margin={{ top: 's' }}>
          <InputFormField
            description={t('CompleteSignUp.enterSchoolName')}
            placeholder={t('CompleteSignUp.schoolPlaceholder')}
            name={CompleteSignUpFields.SCHOOL_ENTERED}
            type="text"
          />
        </Box>
      )}
    </SpaceBetween>
  );
};

export const SchoolFields = memo(SchoolFieldsView);
