import { Formik, Form } from 'formik';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { VALID_EMAIL_PATTERN, VALID_PASSWORD_PATTERN } from 'common/constants';
import { InputFormField, PasswordFormField } from 'common/components/FormFields';
import pageCatalog from 'pages/pageCatalog';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { signUp, getAuthIsLoading } from 'store/auth';
import { getAccountDocEndpoint } from 'pages/utils';
import { PRIVACY_URL, CUSTOMER_AGREEMENT, SERVICE_TERM, USER_POLICY } from 'common/constants/urls';

const initialValues = { email: '', password: '' };

export const SignUpForm = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getAuthIsLoading);

  const onSubmit = (data) => {
    const { email: username, password } = data;
    dispatch(signUp({ username, password }));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('required')).matches(VALID_EMAIL_PATTERN, t('emailFormat')),
    password: Yup.string().required(t('required')).matches(VALID_PASSWORD_PATTERN, t('passwordFormat')),
  });
  return (
    <Container
      header={
        <Header
          description={
            <Trans i18nKey={'signUpTopDesc'}>
              AWS Player account
              <Link target="_blank" href={getAccountDocEndpoint()}>
                AWS Player account
              </Link>
            </Trans>
          }
        >
          {t('signUpLabel')}
        </Header>
      }
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form>
          <Box>
            <SpaceBetween size="m">
              <InputFormField label={t('emailLabel')} stretch name="email" type="email" />
              <PasswordFormField />
              <Button variant="primary" formAction="submit" loading={isLoading}>
                {t('signUpLabel')}
              </Button>
            </SpaceBetween>
          </Box>
          <Box margin={{ top: 'm' }}>
            <SpaceBetween size="s" direction="vertical">
              <Trans i18nKey={'alreadyHaveAcct'}>
                Sign in
                <Link onFollow={() => history.push(pageCatalog.SignIn.getPath())}>Sign in</Link>
              </Trans>
              <Box color="text-body-secondary">
                <Trans i18nKey={'signUpTerm'}>
                  By creating an account and using AWS DeepRacer Student, you agree to the
                  <Link target="_blank" href={CUSTOMER_AGREEMENT}>
                    AWS Customer Agreement ("Agreement"),
                  </Link>
                  <Link target="_blank" href={SERVICE_TERM}>
                    AWS Service Terms
                  </Link>
                  <Link target="_blank" href={PRIVACY_URL}>
                    AWS Privacy Notice
                  </Link>
                  <Link target="_blank" href={USER_POLICY}>
                    AWS Acceptable Use Policy
                  </Link>
                </Trans>
              </Box>
            </SpaceBetween>
          </Box>
        </Form>
      </Formik>
    </Container>
  );
};
