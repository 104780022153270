import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Header, Container, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import Button from '@amzn/awsui-components-react/polaris/button';
import pageCatalog from 'pages/pageCatalog';

import CarImage from 'common/assets/images/Evo_Black.png';

export const PreSeasonRace = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const messagesPath = 'HomePage.SeasonRace.';

  return (
    <Container
      data-analytics="preSeasonRace-Container"
      data-analytics-type="eventContext"
      className="student-container-height"
      header={<Header>{t(`${messagesPath}Heading`)}</Header>}
    >
      <Box fontWeight="bold" variant="div">
        <Box fontWeight="normal" variant="p">
          {t(`${messagesPath}Description`)}
        </Box>
      </Box>
      <Grid gridDefinition={[{ colspan: { default: 12, xs: 4 } }, { colspan: { default: 12, xs: 8 } }]}>
        <Box textAlign="center" className="img-div-resp">
          <img className="img-size-resp" src={CarImage} alt="Car-Image" width="50%" />
        </Box>
        <SpaceBetween size="m">
          <Box variant="div">
            <Box variant="p" fontWeight="bold">
              {t(`${messagesPath}RaceType`)}
            </Box>
            {t(`${messagesPath}TimeTrial`)}
          </Box>
          <Box variant="div">
            <Button
              data-analytics="viewRaces-Button"
              data-analytics-type="eventDetail"
              variant="normal"
              onClick={() => history.push(pageCatalog.StudentLeague.getPath())}
            >
              {t(`${messagesPath}Button`)}
            </Button>
          </Box>
        </SpaceBetween>
      </Grid>
    </Container>
  );
};
