import { getIdToken } from 'store/utils/authUtils';
import { DeepRacerLiteService } from '../../types/DeepRacerLiteTypes';
import ServiceProvider from '../ServiceProvider';

/**
 * Get the lists all the items
 * @param requestObj
 * @returns itemList
 */
export const listAllItems = async (
  operation: string,
  requestObj: any,
  responseObj: any,
  itemsKey: any
): Promise<any> => {
  try {
    const idToken = await getIdToken();
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();

    const result = await deepRacerLiteService[operation](requestObj, idToken);
    responseObj[itemsKey] = responseObj[itemsKey].concat(result[itemsKey]);

    if (result.NextToken) {
      requestObj.NextToken = result.NextToken;
      responseObj = listAllItems(operation, requestObj, responseObj, itemsKey);
    }
    return responseObj;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the object
 * @param requestObj
 * @returns object
 */
export const getObject = async (operation: string, requestObj: any, responseObj: any, objectKey: any): Promise<any> => {
  try {
    const idToken = await getIdToken();
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();

    const result = await deepRacerLiteService[operation](requestObj, idToken);
    responseObj[objectKey] = result[objectKey];

    return responseObj;
  } catch (e) {
    return Promise.reject(e);
  }
};
