import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Grid, Link, SpaceBetween, Wizard } from '@amzn/awsui-components-react';
import { WizardContent } from '../../common/WizardContent';
import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz1.6.1',
  title: 'Chapters.Lesson6.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.6.1.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson6.Wizard1.title',
      youtubeId: 'PIlWp3w936s',
    },
    {
      id: 'wz1.6.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard1.content1',
    },
    {
      id: 'wz1.6.1.30',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson6.Wizard1.heading1',
    },
    {
      id: 'wz1.6.1.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard1.content2',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz2.6.2',
  title: 'Chapters.Lesson6.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz2.6.2.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.datasteps,
      imageAltText: 'Chapters.Lesson6.Wizard2.imageAltText1',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz2.6.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard2.content1',
    },
    {
      id: 'wz2.6.2.30',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson6.Wizard2.heading1',
    },
    {
      id: 'wz2.6.2.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard2.content2',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz3.6.3',
  title: 'Chapters.Lesson6.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.6.3.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.datasteps,
      imageAltText: 'Chapters.Lesson6.Wizard3.imageAltText1',
      width: '50%',
      height: '60%',
    },
    {
      id: 'wz3.6.3.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard3.content1',
    },
    {
      id: 'wz3.6.3.30',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz3.6.3.30-1',
          text: 'Chapters.Lesson6.Wizard3.list1',
        },
        {
          id: 'wz3.6.3.30-2',
          text: 'Chapters.Lesson6.Wizard3.list2',
        },
        {
          id: 'wz3.6.3.30-3',
          text: 'Chapters.Lesson6.Wizard3.list3',
        },
      ],
    },
  ],
};

const wz4: WizardStepsProp = {
  id: 'wz4.6.4',
  title: 'Chapters.Lesson6.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz4.6.4.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.datasteps,
      imageAltText: 'Chapters.Lesson6.Wizard4.imageAltText1',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz4.6.4.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard4.content1',
    },
    {
      id: 'wz4.6.4.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard4.content2',
    },
    {
      id: 'wz4.6.4.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard4.content3',
    },
  ],
};

const wz5: WizardStepsProp = {
  id: 'wz5.6.5',
  title: 'Chapters.Lesson6.Wizard5.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.6.5.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.datasteps,
      imageAltText: 'Chapters.Lesson6.Wizard5.imageAltText1',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz5.6.5.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard5.content1',
    },
    {
      id: 'wz5.6.5.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard5.content2',
    },
    {
      id: 'wz5.6.5.40',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.plot2,
      imageAltText: 'Chapters.Lesson6.Wizard5.imageAltText2',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz5.6.5.50',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.plot,
      imageAltText: 'Chapters.Lesson6.Wizard5.imageAltText2',
      width: '30%',
      height: '30%',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz6',
  title: 'Chapters.Lesson6.Wizard6.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz6.6.7.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson6.Wizard6.content1',
    },
    {
      id: 'wz6.6.7.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz6.6.7.20-1',
          text: 'Chapters.Lesson6.Wizard6.list1',
        },
        {
          id: 'wz6.6.7.20-2',
          text: 'Chapters.Lesson6.Wizard6.list2',
        },
      ],
    },
    {
      id: 'wz6.6.7.30',
      widgetType: WizardContentType.HEADER,
      variant: 'h2',
      text: 'Chapters.Lesson6.Wizard6.heading1',
    },
    {
      id: 'wz6.6.7.40',
      widgetType: WizardContentType.P_BODY_CUSTOM,
      children: () => (
        <Box>
          <ul>
            <li>
              <Trans i18nKey="Chapters.Lesson6.Wizard6.link1">
                In machine learning, you use several statistical-based tools to better understand your data. The sklearn
                library has many examples and tutorials, such as this example demonstrating
                <Link
                  external
                  target="_blank"
                  href="https://scikit-learn.org/stable/auto_examples/applications/plot_outlier_detection_wine.html#sphx-glr-auto-examples-applications-plot-outlier-detection-wine-py"
                >
                  outlier detection on a real dataset
                </Link>
              </Trans>
            </li>
          </ul>
        </Box>
      ),
    },
  ],
};

export const Lesson6 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz7',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz6.6.7.40',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson6.Wizard6.heading2',
      },
      {
        id: 'wz6.6.7.50',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson6.Wizard6.content2',
      },
      {
        id: 'summaryContent2.wz1.5.1.003',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <Button variant="normal" onClick={onClick}>
            <Trans i18nKey="WizardI18nStrings.StartChapter"></Trans>
          </Button>
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(wz4.title) as string,
          content: <WizardContent {...wz4} />,
        },
        {
          title: t(wz5.title) as string,
          content: <WizardContent {...wz5} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <Grid gridDefinition={[{ colspan: { default: 12, xs: 12 } }, { colspan: { default: 12, xs: 12 } }]}>
                <WizardContent {...summaryContent2} />
              </Grid>
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
