import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Link } from '@amzn/awsui-components-react';
import pageCatalog from 'pages/pageCatalog';
import { clearAllNotifications } from 'store/notifications';
import { useAppDispatch } from 'store/utils/hooks';

export const AlreadySignedUpNotificationContent = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <Trans i18nKey={'alreadySignedUpError'}>
      Account registered
      <Link
        onFollow={() => {
          dispatch(clearAllNotifications());
          history.push(pageCatalog.SignUp.getPath());
        }}
        color="inverted"
      >
        sign up
      </Link>
    </Trans>
  );
};

export const AlreadySignedUpNotificationButton = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(clearAllNotifications());
        history.push(pageCatalog.SignIn.getPath());
      }}
    >
      {t('signInLabel')}
    </Button>
  );
};

export const ALREADY_SIGNED_UP_NOTIFICATION = {
  content: <AlreadySignedUpNotificationContent />,
  action: <AlreadySignedUpNotificationButton />,
};
