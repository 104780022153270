/**
 * FeatureFlagsTreatment can have various values
 * C - C is defined as Control, can be read as feature flag is turned off.
 * T1 - T1 is defined as Treatment 1, can be read as feature flag is turned on.
 */
export enum FeatureFlagsTreatment {
  Control = 'C',
  T1 = 'T1',
}

export interface FeatureFlags {
  [flagName: string]: any;
}
