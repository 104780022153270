import React from 'react';
import FormField, { FormFieldProps } from '@amzn/awsui-components-react/polaris/form-field';
import Input, { InputProps } from '@amzn/awsui-components-react/polaris/input';
import { useField } from 'formik';

interface InputFormWrapperProps extends Omit<InputProps, 'value'> {
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
  name: string;
  validate?: (value: any) => undefined | string | Promise<any>;
}

type InputFormFieldPropTypes = FormFieldProps & InputFormWrapperProps;

export const InputFormFieldView: React.FC<InputFormFieldPropTypes> = ({
  constraintText,
  dataAnalyticsLabel,
  dataAnalyticsTypeLabel,
  description,
  label,
  name,
  onChange,
  onBlur,
  secondaryControl,
  stretch,
  validate,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const handleChange: InputProps['onChange'] = (event) => {
    helpers.setValue(event.detail.value);
    onChange?.(event);
  };

  const handleBlur: InputProps['onBlur'] = (event) => {
    helpers.setTouched(true);
    onBlur?.(event);
  };

  const formFieldProps = {
    description,
    errorText: meta.touched && meta.error ? meta.error : null,
    constraintText,
    label,
    stretch,
    secondaryControl,
  };

  const inputFieldProps = {
    ...field,
    ...otherProps,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <FormField {...formFieldProps}>
      <Input data-analytics={dataAnalyticsLabel} data-analytics-type={dataAnalyticsTypeLabel} {...inputFieldProps} />
    </FormField>
  );
};

export const InputFormField = React.memo(InputFormFieldView);
