import { Formik, Form } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box, ColumnLayout, Header, SpaceBetween, Link } from '@amzn/awsui-components-react';
import { CancelButton, SubmitButton } from 'common/components/FormButtons';
import Modal from 'common/components/Modal';
import { getProfileData, handleUpdateProfile, getProfileIsLoading } from 'store/profile';

import { CompleteSignUpFields, VALID_NAME_PATTERN } from 'common/constants';
import { InputFormField } from 'common/components/FormFields';
import { KeyValuePair } from 'common/components/KeyValuePair';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { AsteroidTypes } from 'types';

export const FullName = (): JSX.Element => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const profileData = useAppSelector<AsteroidTypes.ProfileData | null | undefined>(getProfileData);
  const isLoading = useAppSelector(getProfileIsLoading);

  const onDismiss = () => {
    setVisible(false);
  };

  const fullName = [profileData?.firstName, profileData?.middleName, profileData?.lastName].filter(Boolean).join(' ');

  const handleSubmit = (data) => {
    dispatch(
      handleUpdateProfile({
        profileData: {
          firstName: data.firstname,
          middleName: data.middlename || ' ',
          lastName: data.lastname,
        },
      })
    ).then(onDismiss);
  };
  const initialValues = {
    [CompleteSignUpFields.FIRSTNAME]: profileData?.firstName,
    [CompleteSignUpFields.MIDDLENAME]: profileData?.middleName,
    [CompleteSignUpFields.LASTNAME]: profileData?.lastName,
  };
  const validationSchema = Yup.object().shape({
    [CompleteSignUpFields.FIRSTNAME]: Yup.string()
      .required(t('required'))
      .max(30, t('CompleteSignUp.invalidNameLength'))
      .matches(VALID_NAME_PATTERN, t('CompleteSignUp.invalidNameLength')),
    [CompleteSignUpFields.LASTNAME]: Yup.string()
      .required(t('required'))
      .max(30, t('CompleteSignUp.invalidNameLength'))
      .matches(VALID_NAME_PATTERN, t('CompleteSignUp.invalidNameLength')),
    [CompleteSignUpFields.MIDDLENAME]: Yup.string()
      .max(30, t('CompleteSignUp.invalidNameLength'))
      .matches(VALID_NAME_PATTERN, t('CompleteSignUp.invalidNameLength')),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          header={<Header description={t('ProfilePage.WhatIsYourNewName')}>{t('ProfilePage.ChangeYourName')}</Header>}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <CancelButton onClick={onDismiss}>{t('cancel')}</CancelButton>
                <SubmitButton variant="primary" loading={isLoading}>
                  {t('save')}
                </SubmitButton>
              </SpaceBetween>
            </Box>
          }
        >
          <SpaceBetween size="l">
            <InputFormField
              label={t('CompleteSignUp.firstNameLabel')}
              placeholder={t('CompleteSignUp.firstName')}
              name={CompleteSignUpFields.FIRSTNAME}
              type="text"
            />
            <InputFormField
              label={
                <>
                  {t('CompleteSignUp.middleNameLabel')} - <i>{t('optional')}</i>
                </>
              }
              placeholder={t('CompleteSignUp.middleName')}
              name={CompleteSignUpFields.MIDDLENAME}
              type="text"
            />
            <InputFormField
              label={t('CompleteSignUp.lastNameLabel')}
              placeholder={t('CompleteSignUp.lastName')}
              name={CompleteSignUpFields.LASTNAME}
              type="text"
            />
          </SpaceBetween>
        </Modal>
        <ColumnLayout columns={2}>
          <KeyValuePair label={t('ProfilePage.Name')}>{fullName || t('emptyLabel')}</KeyValuePair>
          <Link onFollow={() => setVisible(true)}>{t('ProfilePage.EditName')}</Link>
        </ColumnLayout>
      </Form>
    </Formik>
  );
};
