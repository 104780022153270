import { useTranslation } from 'react-i18next';
import { Box, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { PreRequisitesProps } from 'types/LearningModuleTypes';

interface PreRequisitesListProps {
  preRequisites?: PreRequisitesProps[] | null | undefined;
  showLabelOnEmpty?: boolean;
}

export const PreRequisitesList = ({ preRequisites, showLabelOnEmpty }: PreRequisitesListProps): JSX.Element | null => {
  const { t } = useTranslation();
  if (!preRequisites?.length) {
    if (showLabelOnEmpty) {
      return (
        <Box variant="div">
          <Box variant="p" fontWeight="bold">
            {t('LearningModuleHomeCard.prereq')}
          </Box>
          {t('emptyLabel')}
        </Box>
      );
    }
    return null;
  }
  const links = preRequisites.map((item: PreRequisitesProps, id) => (
    <Link
      data-analytics="prerequisite-Link"
      data-analytics-type="eventDetail"
      key={id}
      href={item.preRequisitesUrl.href}
    >
      {t(item.preRequisitesLabel)}
    </Link>
  ));
  return (
    <Box variant="div">
      <Box variant="p" fontWeight="bold">
        {t('LearningModuleHomeCard.prereq')}
      </Box>
      <SpaceBetween size="xxs">{links}</SpaceBetween>
    </Box>
  );
};
