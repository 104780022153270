import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { unwrapResult } from '@reduxjs/toolkit';
import { VALID_PASSWORD_PATTERN } from 'common/constants';
import { InputFormField, PasswordFormField } from 'common/components/FormFields';
import pageCatalog from 'pages/pageCatalog';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { getAuthIsLoading, getCodeDeliveryDetails, getForgotPasswordEmail, forgotPasswordReset } from 'store/auth';
import { displaySuccessNotification, displayErrorNotification } from 'store/notifications';

const initialValues = { code: '', password: '' };

export const ForgotPasswordResetForm = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getAuthIsLoading);

  const codeDeliveryDetails = useAppSelector(getCodeDeliveryDetails);
  const forgotPasswordEmail = useAppSelector(getForgotPasswordEmail);

  const onSubmit = async (data) => {
    const { code, password } = data;
    await dispatch(forgotPasswordReset({ email: forgotPasswordEmail as string, code, newPassword: password }))
      .then(unwrapResult)
      .then(() => {
        dispatch(
          displaySuccessNotification({
            content: t('resetSuccessfully'),
            action: <Button onClick={() => history.push(pageCatalog.SignIn.getPath())}>{t('returnToSignIn')}</Button>,
          })
        );
      })
      .catch((error) => {
        dispatch(displayErrorNotification({ content: error?.message }));
      });
  };

  // Redirect away from page if the code hasn't been requested
  if (!codeDeliveryDetails || !forgotPasswordEmail) {
    history.push(pageCatalog.ForgotPasswordRequest.getPath());
  }

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t('required')),
    password: Yup.string().required(t('required')).matches(VALID_PASSWORD_PATTERN, t('passwordFormat')),
  });

  return (
    <Container
      header={
        <Header description={t('checkEmailHint')}>
          {t('resetPasswordEmailSentTo', { email: codeDeliveryDetails?.Destination })}
        </Header>
      }
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form>
          <SpaceBetween size="m">
            <InputFormField
              label={t('verificationCode')}
              placeholder={t('verificationCodePlaceholder')}
              name="code"
              type="text"
              stretch
            />
            <PasswordFormField isNewPassword />
          </SpaceBetween>
          <Box margin={{ top: 'xxl' }}>
            <SpaceBetween size="s" direction="vertical">
              <Button variant="primary" formAction="submit" loading={isLoading}>
                {t('resetPassword')}
              </Button>
            </SpaceBetween>
          </Box>
        </Form>
      </Formik>
    </Container>
  );
};
