import * as React from 'react';
import { optionLabelsWithCategoryGroups as avatarOptions } from '@amzn/react-avataaar';
import { SelectProps } from '@amzn/awsui-components-react';
import { useFormikContext } from 'formik';

import {
  AvatarConfigDataOption,
  AvatarConfigSelectOptionMap,
  AvatarOptionType,
  DEFAULT_AVATAR_TOP_OPTION,
} from 'common/constants';
import {
  blankOption,
  convertAvatarConfigDataOptionToAvatarOption,
  getOptionLabel,
  isAccessoriesEnabled,
  isClothingColorEnabled,
  isFacialHairEnabled,
  isGraphicShirt,
  isHairColorEnabled,
  isHatColorEnabled,
} from 'common/utils/avatar';

export const useHandleAvatarOptionChange = () => {
  const {
    setFieldValue,
    values: {
      [AvatarConfigDataOption.CLOTHING]: currentClothingSelectOption,
      [AvatarConfigDataOption.FACIAL_HAIR]: currentFacialHairSelectOption,
      [AvatarConfigDataOption.TOP]: currentTopSelectOption,
    },
  } = useFormikContext<AvatarConfigSelectOptionMap>();

  const currentClothingType = currentClothingSelectOption?.value ?? '';
  const currentFacialHairType = currentFacialHairSelectOption?.value ?? '';
  const currentTopType = currentTopSelectOption?.value ?? '';

  const clearField = React.useCallback(
    (field: AvatarConfigDataOption) => {
      setFieldValue(field, blankOption);
    },
    [setFieldValue]
  );

  const resetFieldToDefault = React.useCallback(
    (field: AvatarConfigDataOption) => {
      const avatarOption = convertAvatarConfigDataOptionToAvatarOption(field) as Exclude<
        AvatarOptionType,
        AvatarOptionType.COUNTRY_CODE
      >;
      const defaultOptionValue = avatarOptions[avatarOption].defaultValue;
      if (!defaultOptionValue) return;

      const defaultOption = {
        label: getOptionLabel(field, defaultOptionValue),
        value: defaultOptionValue,
      };

      setFieldValue(field, defaultOption);
    },
    [setFieldValue]
  );

  const handleClothingChange = React.useCallback(
    ({ detail }: { detail: SelectProps.ChangeDetail }) => {
      const newClothingType = detail.selectedOption.value ?? '';

      if (!isClothingColorEnabled(currentClothingType) && isClothingColorEnabled(newClothingType)) {
        resetFieldToDefault(AvatarConfigDataOption.CLOTHING_COLOR);
      }
      if (isClothingColorEnabled(currentClothingType) && !isClothingColorEnabled(newClothingType)) {
        clearField(AvatarConfigDataOption.CLOTHING_COLOR);
      }
      if (!isGraphicShirt(currentClothingType) && isGraphicShirt(newClothingType)) {
        resetFieldToDefault(AvatarConfigDataOption.TSHIRT_GRAPHIC);
      }
      if (isGraphicShirt(currentClothingType) && !isGraphicShirt(newClothingType)) {
        clearField(AvatarConfigDataOption.TSHIRT_GRAPHIC);
      }
    },
    [clearField, currentClothingType, resetFieldToDefault]
  );

  const handleFacialHairChange = React.useCallback(
    ({ detail }: { detail: SelectProps.ChangeDetail }) => {
      const newFacialHairType = detail.selectedOption.value ?? '';

      if (currentFacialHairType === 'Blank' && newFacialHairType !== 'Blank') {
        resetFieldToDefault(AvatarConfigDataOption.FACIAL_HAIR_COLOR);
      }
      if (currentFacialHairType !== 'Blank' && newFacialHairType === 'Blank') {
        clearField(AvatarConfigDataOption.FACIAL_HAIR_COLOR);
      }
    },
    [clearField, currentFacialHairType, resetFieldToDefault]
  );

  const handleTopChange = React.useCallback(
    ({ detail }: { detail: SelectProps.ChangeDetail }) => {
      const newTopType = detail.selectedOption.value ?? '';

      if (currentTopType === DEFAULT_AVATAR_TOP_OPTION && newTopType !== DEFAULT_AVATAR_TOP_OPTION) {
        resetFieldToDefault(AvatarConfigDataOption.CLOTHING);
        resetFieldToDefault(AvatarConfigDataOption.EYEBROWS);
        resetFieldToDefault(AvatarConfigDataOption.EYES);
        resetFieldToDefault(AvatarConfigDataOption.MOUTH);
        resetFieldToDefault(AvatarConfigDataOption.SKIN_COLOR);

        if (isAccessoriesEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.ACCESSORIES);
        }
        if (isHairColorEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.HAIR_COLOR);
        }
        if (isHatColorEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.HAT_COLOR);
        }
        if (isFacialHairEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.FACIAL_HAIR);
        }
      }

      if (currentTopType !== DEFAULT_AVATAR_TOP_OPTION && newTopType !== DEFAULT_AVATAR_TOP_OPTION) {
        if (!isAccessoriesEnabled(currentTopType) && isAccessoriesEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.ACCESSORIES);
        }
        if (isAccessoriesEnabled(currentTopType) && !isAccessoriesEnabled(newTopType)) {
          clearField(AvatarConfigDataOption.ACCESSORIES);
        }
        if (!isHairColorEnabled(currentTopType) && isHairColorEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.HAIR_COLOR);
        }
        if (isHairColorEnabled(currentTopType) && !isHairColorEnabled(newTopType)) {
          clearField(AvatarConfigDataOption.HAIR_COLOR);
        }
        if (!isHatColorEnabled(currentTopType) && isHatColorEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.HAT_COLOR);
        }
        if (isHatColorEnabled(currentTopType) && !isHatColorEnabled(newTopType)) {
          clearField(AvatarConfigDataOption.HAT_COLOR);
        }
        if (!isFacialHairEnabled(currentTopType) && isFacialHairEnabled(newTopType)) {
          resetFieldToDefault(AvatarConfigDataOption.FACIAL_HAIR);
        }
        if (isFacialHairEnabled(currentTopType) && !isFacialHairEnabled(newTopType)) {
          clearField(AvatarConfigDataOption.FACIAL_HAIR);
          clearField(AvatarConfigDataOption.FACIAL_HAIR_COLOR);
        }
      }

      if (currentTopType !== DEFAULT_AVATAR_TOP_OPTION && newTopType === DEFAULT_AVATAR_TOP_OPTION) {
        for (const option of Object.values(AvatarConfigDataOption)) {
          if (
            option === AvatarConfigDataOption.TOP ||
            option === AvatarConfigDataOption.COUNTRY_CODE ||
            option === AvatarConfigDataOption.BACKGROUND_COLOR
          ) {
            continue;
          }
          clearField(option);
        }
      }
    },
    [clearField, currentTopType, resetFieldToDefault]
  );

  return {
    handleClothingChange,
    handleFacialHairChange,
    handleTopChange,
  };
};
