/* istanbul ignore file */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Header as PolarisHeader } from '@amzn/awsui-components-react';
import CountDown from './CountDown';

interface HeaderProps {
  content: string;
  description: string;
  enableCountDown?: boolean;
  remainingTime?: any;
  onComplete?(): void;
  info?: any;
}

const Header: React.FC<HeaderProps> = ({
  content,
  description,
  enableCountDown = false,
  remainingTime = 0,
  onComplete = () => null,
  info = null,
}: HeaderProps) => (
  <PolarisHeader
    variant="h1"
    description={description}
    info={info}
    actions={
      enableCountDown && remainingTime > -1 && <CountDown onComplete={onComplete} remainingTime={remainingTime} />
    }
  >
    {content}
  </PolarisHeader>
);

export default Header;
