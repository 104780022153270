import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, ColumnLayout, StatusIndicator } from '@amzn/awsui-components-react';
import { CheckboxField, InputFormField, SelectFormField } from 'common/components/FormFields';
import { MajorFields, SchoolFields } from 'common/components/CompleteSignUp';
import { CompleteSignUpFields } from 'common/constants';
import { COUNTRIES } from '@amzn/aws-deepracer-ui-components/data/countries';

export const CompleteSignUpForm = (): JSX.Element => {
  const { t } = useTranslation();
  const [, certifyMeta] = useField({ name: CompleteSignUpFields.SELF_CERTIFIED });

  return (
    <>
      <ColumnLayout columns={3}>
        <InputFormField
          label={t('CompleteSignUp.firstNameLabel')}
          placeholder={t('CompleteSignUp.firstName')}
          name={CompleteSignUpFields.FIRSTNAME}
          type="text"
        />
        <InputFormField
          label={
            <>
              {t('CompleteSignUp.middleNameLabel')} - <i>{t('optional')}</i>
            </>
          }
          placeholder={t('CompleteSignUp.middleName')}
          name={CompleteSignUpFields.MIDDLENAME}
          type="text"
        />
        <InputFormField
          label={t('CompleteSignUp.lastNameLabel')}
          placeholder={t('CompleteSignUp.lastName')}
          name={CompleteSignUpFields.LASTNAME}
          type="text"
        />
        <Box>
          <SchoolFields />
        </Box>
        <Box>
          <MajorFields />
        </Box>
        <InputFormField
          label={t('CompleteSignUp.yearOfGraduationLabel')}
          constraintText={t('CompleteSignUp.invalidYearRange')}
          description={t('CompleteSignUp.yearOfGraduationDesc')}
          placeholder={t('CompleteSignUp.yearOfGraduationPlaceholder')}
          name={CompleteSignUpFields.YEAR_OF_GRAD}
          type="text"
        />
      </ColumnLayout>
      <Box margin={{ top: 'l' }}>
        <CheckboxField name={CompleteSignUpFields.SELF_CERTIFIED}>
          {t('CompleteSignUp.certifyStudentHint')}
        </CheckboxField>
        {certifyMeta.touched && certifyMeta.error && (
          <StatusIndicator type="warning">{t('CompleteSignUp.certifyStudentError')}</StatusIndicator>
        )}
      </Box>
      <Box margin={{ top: 'm' }}>
        <ColumnLayout columns={3}>
          <SelectFormField
            label={t('CompleteSignUp.countryLabel')}
            placeholder={t('CompleteSignUp.countryLabel')}
            options={COUNTRIES}
            filteringType="auto"
            name={CompleteSignUpFields.COUNTRY}
          />
        </ColumnLayout>
      </Box>
    </>
  );
};
