import { ActionReducerMapBuilder, SerializedError } from '@reduxjs/toolkit';

import { isFulfilledActionForSlice, isPendingActionForSlice, isRejectedActionForSlice } from 'store/types';

type PayloadAction = {
  error?: SerializedError;
  payload: any;
  type: string;
};

export const addDefaultLoadingCases = (builder: ActionReducerMapBuilder<Record<string, any>>, sliceName: string) => {
  builder
    .addMatcher(
      (action) => isFulfilledActionForSlice(action, sliceName),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => isPendingActionForSlice(action, sliceName),
      (state) => {
        state.error = null;
        state.isLoading = true;
      }
    )
    .addMatcher<PayloadAction>(
      (action) => isRejectedActionForSlice(action, sliceName),
      (state, { error, payload }) => {
        state.error = error?.message || payload;
        state.isLoading = false;
      }
    );
};

export const addLoadingCasesWithNestedListState = (
  builder: ActionReducerMapBuilder<Record<string, any>>,
  actionName: string,
  sliceName: string,
  subStateObj: string
) => {
  builder.addMatcher<PayloadAction>(
    (action) => action.type.includes(actionName + '/'),
    (state, action) => {
      const { payload, error } = action;
      if (isPendingActionForSlice(action, sliceName)) {
        state[subStateObj].error = null;
        state[subStateObj].isLoading = true;
      } else if (isFulfilledActionForSlice(action, sliceName)) {
        state[subStateObj].items = payload;
        state[subStateObj].error = null;
        state[subStateObj].isLoading = false;
      } else if (isRejectedActionForSlice(action, sliceName)) {
        state[subStateObj].items = [];
        state[subStateObj].error = error?.message || payload;
        state[subStateObj].isLoading = false;
      }
    }
  );
};

export const addLoadingCasesWithNestedMapState = (
  builder: ActionReducerMapBuilder<Record<string, any>>,
  actionName: string,
  sliceName: string,
  subStateObjKey: string,
  objKey: string
) => {
  builder.addMatcher<PayloadAction>(
    (action) => action.type.includes(actionName + '/'),
    (state, action) => {
      const { payload, error } = action;
      if (isPendingActionForSlice(action, sliceName)) {
        state[subStateObjKey].error = null;
        state[subStateObjKey].isLoading = true;
      } else if (isFulfilledActionForSlice(action, sliceName)) {
        payload.forEach((obj) => {
          state[subStateObjKey].items[obj[objKey]] = obj;
        });
        state[subStateObjKey].error = null;
        state[subStateObjKey].isLoading = false;
      } else if (isRejectedActionForSlice(action, sliceName)) {
        state[subStateObjKey].error = (error as SerializedError).message || (payload as string);
        state[subStateObjKey].isLoading = false;
      }
    }
  );
};

export const addLoadingCasesWithNestedState = (
  builder: ActionReducerMapBuilder<Record<string, any>>,
  actionName: string,
  sliceName: string,
  subStateObj: string
) => {
  builder.addMatcher<PayloadAction>(
    (action) => action.type.includes(actionName + '/'),
    (state, action) => {
      const { payload, error } = action;
      if (isPendingActionForSlice(action, sliceName)) {
        state[subStateObj].error = null;
        state[subStateObj].isLoading = true;
      } else if (isFulfilledActionForSlice(action, sliceName)) {
        state[subStateObj].item = payload;
        state[subStateObj].error = null;
        state[subStateObj].isLoading = false;
      } else if (isRejectedActionForSlice(action, sliceName)) {
        state[subStateObj].item = null;
        state[subStateObj].error = error?.message || payload;
        state[subStateObj].isLoading = false;
      }
    }
  );
};
