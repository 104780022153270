import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

// Translation JSON files
import ENTranslations from 'i18n/en/translations.json';
import ModelsListTranslations from 'i18n/en/modelsList.json';
import LeaderboardTranslations from 'i18n/en/leaderboard.json';
import ModelDetailsTranslations from 'i18n/en/modelDetails.json';
import CreateModelTranslations from 'i18n/en/createModel.json';
import AvatarConfigDialogTranslations from 'i18n/en/avatarConfigDialog.json';
import AsssessmentTranslations from 'i18n/en/assessment.json';
import CommunityRacesTranslations from 'i18n/en/communityRaces.json';
import StudentLeagueTranslations from 'i18n/en/studentLeague.json';

const defaultNS = 'translation';

const resources = {
  en: {
    translation: ENTranslations, // import JSON translation strings for each languages here.
    modelsList: ModelsListTranslations,
    leaderboard: LeaderboardTranslations,
    modelDetails: ModelDetailsTranslations,
    createModel: CreateModelTranslations,
    avatarConfigDialog: AvatarConfigDialogTranslations,
    assessment: AsssessmentTranslations,
    communityRaces: CommunityRacesTranslations,
    studentLeague: StudentLeagueTranslations,
  },
} as const;

export type DefaultNS = typeof defaultNS;
export type Resources = typeof resources;

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    defaultNS,
    resources,
    lng: 'en',
    fallbackLng: 'en', // English as fallback language
    // debug: true, // keeping it here to help quickly debug i18n issues.
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    returnObjects: true,
  });

export default i18n;
