/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { displayErrorNotification } from 'store/notifications';
import { useAppDispatch } from 'store/utils/hooks';
import {
  getAlias,
  getLeaderboard,
  listLeaderboards,
  listLeaderboardEvaluationJobs,
  listLeaderboardWinners,
} from 'services/deepRacerLite/leaderboardApi';
import { DeepRacerLiteTypes } from 'types';

enum LeaderboardQueryKeysEnum {
  Alias = 'Alias',
  LeaderboardEvaluationJobs = 'LeaderboardEvaluationJobs',
  Leaderboard = 'Leaderboard',
  Leaderboards = 'Leaderboards',
  LeaderboardWinners = 'LeaderboardWinners',
}

const useGetLeaderboard = (leaderboardArn: string) => {
  const dispatch = useAppDispatch();
  return useQuery([LeaderboardQueryKeysEnum.Leaderboard, leaderboardArn], () => getLeaderboard(leaderboardArn), {
    enabled: !!leaderboardArn && leaderboardArn !== 'undefined',
    onError: (error: any) => {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    },
  });
};

const useListLeaderboards = (leagueType: DeepRacerLiteTypes.LeagueType = DeepRacerLiteTypes.LeagueType.LITE) => {
  const dispatch = useAppDispatch();
  return useQuery([LeaderboardQueryKeysEnum.Leaderboards, leagueType], () => listLeaderboards(leagueType), {
    onError: (error: any) => {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    },
  });
};

const useGetAlias = () => {
  const dispatch = useAppDispatch();
  return useQuery([LeaderboardQueryKeysEnum.Alias], () => getAlias(), {
    onError: (error: any) => {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    },
  });
};

const useListLeaderboardWinners = () => {
  const dispatch = useAppDispatch();
  return useQuery([LeaderboardQueryKeysEnum.LeaderboardWinners], () => listLeaderboardWinners(), {
    onError: (error: any) => {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    },
  });
};

const useListLeaderboardEvaluationJobs = (leaderboardArn: string) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [LeaderboardQueryKeysEnum.LeaderboardEvaluationJobs],
    () => listLeaderboardEvaluationJobs(leaderboardArn),
    {
      onError: (error: any) => {
        dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      },
    }
  );
};

export {
  LeaderboardQueryKeysEnum,
  useGetLeaderboard,
  useListLeaderboards,
  useGetAlias,
  useListLeaderboardEvaluationJobs,
  useListLeaderboardWinners,
};
