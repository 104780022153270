import { useTranslation } from 'react-i18next';
import { Box, ColumnLayout, Container, Header, Link } from '@amzn/awsui-components-react';
import { getOptedInEsperanza } from 'store/profile';
import { useAppSelector } from 'store/utils/hooks';
import { getUdacityDocEndPoint } from 'pages/utils';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

interface EsperanzaContainerProps {
  children?: React.ReactNode;
  status?: React.ReactNode;
}

export const EsperanzaContainer = ({ children, status }: EsperanzaContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const isOptedIn = useAppSelector(getOptedInEsperanza);

  return (
    <Box>
      <Container
        header={
          <Header variant="h2" description={t('ProfilePage.LaunchingSoon')}>
            <SpaceBetween direction="horizontal" size="s">
              {isOptedIn ? t('CompleteSignUp.esperanzaTitleOptedIn') : t('CompleteSignUp.esperanzaTitle')}
              <Box variant="div" margin={{ top: 'xxs' }}>
                <Link external target="_blank" href={getUdacityDocEndPoint()}>
                  {t('ProfilePage.LearnAboutEsperanza')}
                </Link>
              </Box>
            </SpaceBetween>
          </Header>
        }
      >
        <Box margin={{ bottom: 'm' }}>
          {status}
          <ColumnLayout columns={3}>
            <Box>
              <Box margin={{ bottom: 'xxxs' }} fontWeight="bold">
                {t('CompleteSignUp.whatIsEsperanzaTitle')}
              </Box>
              <Box>{t('CompleteSignUp.whatIsEsperanzaDesc')}</Box>
            </Box>
            <Box>
              <Box margin={{ bottom: 'xxxs' }} fontWeight="bold">
                {t('CompleteSignUp.whoIsEsperanzaForTitle')}
              </Box>
              <Box>{t('CompleteSignUp.whoIsEsperanzaForDesc')}</Box>
            </Box>
            <Box>
              <Box margin={{ bottom: 'xxxs' }} fontWeight="bold">
                {t('CompleteSignUp.whatDoesEsperanzaOfferTitle')}
              </Box>
              <Box>{t('CompleteSignUp.whatDoesEsperanzaOfferDesc')}</Box>
            </Box>
          </ColumnLayout>
        </Box>
        {children}
      </Container>
    </Box>
  );
};
