import { useCallback } from 'react';
import Avataaar, { AvatarStyle } from '@amzn/react-avataaar';

import defaultAvatar from 'common/assets/images/avatar/defaultAvatar.svg';
import Flag, { FlagSize } from 'common/components/Flag';
import {
  AvatarSize,
  DEFAULT_AVATAR_BACKGROUND_COLOR_DARK,
  DEFAULT_AVATAR_BACKGROUND_COLOR_LIGHT,
} from 'common/constants';
import { isDefaultAvatar } from 'common/utils/avatar';
import { AsteroidTypes } from 'types';
import './styles.css';
import { getIsDarkMode } from 'store/darkMode';
import { useAppSelector } from 'store/utils/hooks';
import { isDarkModeEnabled } from 'utils/featureFlags/currentFeatureFlags';

const avatarSizeMap = {
  [AvatarSize.LEADERBOARD]: {
    flag: FlagSize.SMALL,
    avatar: 40,
    flagClassName: 'avatarFlag-leaderboard',
  },
  [AvatarSize.PROFILE]: {
    avatar: 110,
    flag: FlagSize.SMALL,
    flagClassName: '',
  },
  [AvatarSize.AVATAR_CONFIG_DIALOG]: {
    avatar: 160,
    flag: FlagSize.MEDIUM,
    flagClassName: '',
  },
};

interface AvatarProps extends AsteroidTypes.AvatarConfigData {
  /**
   * The size of the avatar
   * @default AvatarSize.PROFILE
   */
  avatarSize?: AvatarSize;
  /**
   * Test selector id
   */
  'data-test-id'?: string;
  /**
   * Called when the avatar is clicked.
   */
  onClick?: () => void;
  /**
   * CSS style attributes passed to the avatar.
   */
  style?: Omit<React.CSSProperties, 'backgroundColor' | 'height' | 'width'>;
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    accessories,
    avatarSize = AvatarSize.PROFILE,
    backgroundColor,
    clothing,
    clothingColor,
    countryCode,
    ['data-test-id']: dataTestId,
    eyes,
    eyebrows,
    facialHair,
    facialHairColor,
    hairColor,
    hatColor,
    mouth,
    onClick,
    skinColor,
    style,
    top,
    tShirtGraphic,
  } = props;

  const isDarkMode = useAppSelector(getIsDarkMode);

  const avatarSizeInPixels = avatarSizeMap[avatarSize].avatar;
  const flagSize = avatarSizeMap[avatarSize].flag;
  const flagClassName = avatarSizeMap[avatarSize].flagClassName;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ENTER') {
        onClick?.();
      }
    },
    [onClick]
  );

  const defaultAvatarBackgroundColor =
    isDarkMode && isDarkModeEnabled() ? DEFAULT_AVATAR_BACKGROUND_COLOR_DARK : DEFAULT_AVATAR_BACKGROUND_COLOR_LIGHT;

  const avatarBackgroundColor = isDefaultAvatar(top) ? undefined : backgroundColor || defaultAvatarBackgroundColor;

  return (
    <div
      data-test-id={dataTestId}
      className={`avataaarComponentContainer${onClick ? ' avataaarOnClickHover' : ''}`}
      style={{ height: avatarSizeInPixels, width: avatarSizeInPixels, ...style }}
      tabIndex={onClick ? 0 : undefined}
      onClick={onClick}
      onKeyDown={onClick ? handleKeyDown : undefined}
      role={onClick ? 'button' : undefined}
    >
      <div
        className={`avataaarContainer`}
        style={{
          height: avatarSizeInPixels,
          width: avatarSizeInPixels,
          backgroundColor: avatarBackgroundColor,
          ...style,
        }}
      >
        {isDefaultAvatar(top) ? (
          <img
            alt="Avatar"
            src={defaultAvatar}
            style={{ height: avatarSizeInPixels, width: avatarSizeInPixels, ...style }}
          />
        ) : (
          <Avataaar
            accessoriesType={accessories}
            avatarStyle={AvatarStyle.Transparent}
            clotheColor={clothingColor}
            clotheType={clothing}
            eyeType={eyes}
            eyebrowType={eyebrows}
            facialHairType={facialHair}
            facialHairColor={facialHairColor}
            graphicType={tShirtGraphic}
            hairColor={hairColor}
            hatColor={hatColor}
            mouthType={mouth}
            skinColor={skinColor}
            topType={top}
            style={{ height: avatarSizeInPixels, width: avatarSizeInPixels, ...style }}
          />
        )}
      </div>
      {countryCode && (
        <div className={`countryFlag countryFlag-${avatarSize}`}>
          <Flag code={countryCode} className={flagClassName} size={flagSize} />
        </div>
      )}
    </div>
  );
};

export default Avatar;
