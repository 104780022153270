import { MAX_RESULTS } from 'common/constants';
import { getIdToken } from 'store/utils/authUtils';
import { DeepRacerLiteService, DeepRacerLiteTypes } from '../../types/DeepRacerLiteTypes';
import ServiceProvider from '../ServiceProvider';
import { listAllItems } from './common';

/**
 * Get the lists all the tracks
 * @param
 * @returns NextToken, TrackList
 */
export const listTracks = async (): Promise<DeepRacerLiteTypes.ListTracksResponse> => {
  try {
    const listTracksRequest: DeepRacerLiteTypes.ListTracksRequest = {
      MaxResults: MAX_RESULTS,
    };
    const listTracksResponse: DeepRacerLiteTypes.ListTracksResponse = {
      Tracks: [],
    };
    return listAllItems('listTracks', listTracksRequest, listTracksResponse, 'Tracks');
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the lists all the tracks
 * @param trackArn
 * @returns Track
 */
export const getTrack = async (trackArn: string): Promise<DeepRacerLiteTypes.GetTrackResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getTrackRequest: DeepRacerLiteTypes.GetTrackRequest = {
      TrackArn: trackArn,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getTrack(getTrackRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};
