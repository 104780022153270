import { RootState } from 'store';

export const getAssessmentTrackers = (state: RootState) => state?.lmsQuiz?.assessmentTrackers?.items;
export const getAssessmentTrackersIsLoading = (state: RootState) => state?.lmsQuiz?.assessmentTrackers?.isLoading;
export const getAssessmentTrackersError = (state: RootState) => state?.lmsQuiz?.assessmentTrackers?.error;

export const getAssessmentTracker = (state: RootState) => state?.lmsQuiz?.assessmentTracker?.item;
export const getAssessmentTrackerIsLoading = (state: RootState) => state?.lmsQuiz?.assessmentTracker?.isLoading;
export const getAssessmentTrackerError = (state: RootState) => state?.lmsQuiz?.assessmentTracker?.error;

export const getCreateAssessmentTracker = (state: RootState) => state?.lmsQuiz?.createAssessmentTracker?.item;
export const getCreateAssessmentTrackerIsLoading = (state: RootState) =>
  state?.lmsQuiz?.createAssessmentTracker?.isLoading;
export const getCreateAssessmentTrackerError = (state: RootState) => state?.lmsQuiz?.createAssessmentTracker?.error;

export const getStartAssessmentTracker = (state: RootState) => state?.lmsQuiz?.startAssessmentTracker?.item;
export const getStartAssessmentTrackerIsLoading = (state: RootState) =>
  state?.lmsQuiz?.startAssessmentTracker?.isLoading;
export const getStartAssessmentTrackerError = (state: RootState) => state?.lmsQuiz?.startAssessmentTracker?.error;

export const getFinishAssessmentTracker = (state: RootState) => state?.lmsQuiz?.finishAssessmentTracker?.item;
export const getFinishAssessmentTrackerIsLoading = (state: RootState) =>
  state?.lmsQuiz?.finishAssessmentTracker?.isLoading;
export const getFinishAssessmentTrackerError = (state: RootState) => state?.lmsQuiz?.finishAssessmentTracker?.error;

export const getQuestion = (state: RootState) => state?.lmsQuiz?.question?.item;
export const getQuestionIsLoading = (state: RootState) => state?.lmsQuiz?.question?.isLoading;
export const getQuestionError = (state: RootState) => state?.lmsQuiz?.question?.error;

export const getPostAnswer = (state: RootState) => state?.lmsQuiz?.postAnswer?.item;
export const getPostAnswerIsLoading = (state: RootState) => state?.lmsQuiz?.postAnswer?.isLoading;
export const getPostAnswerError = (state: RootState) => state?.lmsQuiz?.postAnswer?.error;
