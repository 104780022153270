import { useTranslation } from 'react-i18next';

import { SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';

const wz1: WizardStepsProp = {
  id: 'wz6.21.1',
  title: 'Chapters.Lesson21.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.21.1.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson21.Wizard1.content1',
    },
    {
      id: 'wz6.21.1.20',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson21.Wizard1.videoTitle',
      youtubeId: 'KiPVdKESroU',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz6.21',
  title: 'Chapters.Lesson21.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'summaryContent1.wz6.21.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson21.Wizard2.content1',
    },
  ],
};

export const Lesson21 = ({ activeStepIndex, onCancel, onNavigate, onSubmit }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
