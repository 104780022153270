import { useFormikContext } from 'formik';
import Button, { ButtonProps } from '@amzn/awsui-components-react/polaris/button';

interface FormButtonProps extends ButtonProps {
  onClick?: (event: any) => void;
}

export const SubmitButton = ({ onClick, ...props }: FormButtonProps): JSX.Element => {
  const { submitForm } = useFormikContext();

  const onButtonClick: ButtonProps['onClick'] = (event) => {
    submitForm();
    onClick?.(event);
  };

  return <Button {...props} onClick={onButtonClick} />;
};

export const CancelButton = ({ onClick, ...props }: FormButtonProps): JSX.Element => {
  const { handleReset } = useFormikContext();

  const onButtonClick: ButtonProps['onClick'] = (event) => {
    handleReset();
    onClick?.(event);
  };

  return <Button {...props} onClick={onButtonClick} />;
};
