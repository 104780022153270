import ServiceProvider from 'services/ServiceProvider';
import { AsteroidPublicService, AsteroidPublicTypes } from 'types';

export const getPublicGlobalScores = async (
  category: string,
  profileId?: string
): Promise<AsteroidPublicTypes.GetGlobalScoresResponse> => {
  try {
    const asteroidPublicService: AsteroidPublicService = ServiceProvider.getAsteroidPublicService();
    const request: AsteroidPublicTypes.GetGlobalScoresRequest = { category };
    if (profileId) {
      request.profileId = profileId;
    }
    return await asteroidPublicService.getGlobalScores(request);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPublicChallengeGlobalScores = async (
  category: string,
  challengeId: string,
  profileId?: string
): Promise<AsteroidPublicTypes.GetChallengeGlobalScoresResponse> => {
  try {
    const asteroidPublicService: AsteroidPublicService = ServiceProvider.getAsteroidPublicService();
    const request: AsteroidPublicTypes.GetChallengeGlobalScoresRequest = { category, challengeId };
    if (profileId) {
      request.profileId = profileId;
    }
    return await asteroidPublicService.getChallengeGlobalScores(request);
  } catch (e) {
    return Promise.reject(e);
  }
};
