import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Badge, Box, Button, Container, Header, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { LearningModuleChaptersCardProps } from 'types/LearningModuleTypes';
import { DeepRacerLiteTypes } from 'types';
import './style.css';

export const LearningModuleChaptersCard = ({
  cardAction,
  cardActionId,
  cardActionLabel,
  cardActionVariant,
  chapterDescription,
  chapterTitle,
  duration,
  chapterData,
  isLoading,
  comingSoon,
}: LearningModuleChaptersCardProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const status = chapterData?.chapterStatus;

  const handleCardAction = ({ cardAction, cardActionId }) => {
    cardAction?.();
    history.push(`/learn/chapter/${cardActionId}`);
  };

  const renderButtonText = (status?: DeepRacerLiteTypes.LmsCourseTrackerStatus) => {
    switch (status) {
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED:
        return t('LearnOverview.ReviewThisChapter');
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.IN_PROGRESS:
        return t('LearnOverview.ResumeLearning');
      default:
        return t('LearnOverview.StartLearning');
    }
  };

  const renderButtonDataAnalyticsLabel = (status?: DeepRacerLiteTypes.LmsCourseTrackerStatus) => {
    switch (status) {
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED:
        return 'review-Button';
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.IN_PROGRESS:
        return 'resume-Button';
      default:
        return 'start-Button';
    }
  };

  const renderProgressIcon = (status?: DeepRacerLiteTypes.LmsCourseTrackerStatus) => {
    switch (status) {
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED:
        return <StatusIndicator type="success">{t('LearnOverview.Completed')}</StatusIndicator>;
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.IN_PROGRESS:
        return <StatusIndicator type="in-progress">{t('LearnOverview.InProgress')}</StatusIndicator>;
      default:
        return (
          <StatusIndicator type="info" colorOverride="grey">
            {t('LearnOverview.NotStarted')}
          </StatusIndicator>
        );
    }
  };

  return (
    <Container
      data-analytics={`${String(t(chapterTitle)).replace(/\s/g, '')}-Container`}
      data-analytics-type="eventContext"
      header={
        <Header description={chapterDescription && t(chapterDescription)} variant="h3">
          {t(chapterTitle)}
        </Header>
      }
      className="learn-module-chapter-card"
    >
      <SpaceBetween size="s" className="top">
        <Box variant="div">
          <Box variant="p" fontWeight="bold">
            {t('LearningModuleHomeCard.duration')}
          </Box>
          {t(duration)}
        </Box>
      </SpaceBetween>
      <SpaceBetween size="s" className="bottom">
        {comingSoon ? (
          <Box variant="div" margin={{ top: 'xs' }}>
            <Badge color="grey">{t('LearningModuleHomeCard.comingSoon')}</Badge>
          </Box>
        ) : isLoading ? (
          <Box></Box>
        ) : (
          <>
            {renderProgressIcon(status)}
            <Button
              data-analytics={renderButtonDataAnalyticsLabel(status)}
              data-analytics-type="eventDetail"
              ariaLabel={t(cardActionLabel) as string}
              variant={cardActionVariant}
              onClick={() => handleCardAction({ cardAction, cardActionId })}
            >
              {renderButtonText(status)}
            </Button>
          </>
        )}
      </SpaceBetween>
    </Container>
  );
};
