import { Auth, CognitoUser } from '@aws-amplify/auth';
import { getConfig } from '@amzn/aws-deep-racer-lite-client-config';
import { TOKEN_EXPIRING_IN_SECONDS } from 'common/constants';

export const configureAuth = () => {
  const authConfig = getConfig('auth');

  Auth.configure({
    region: authConfig.userPoolRegion,
    userPoolId: authConfig.userPoolId,
    userPoolWebClientId: authConfig.userPoolAppClientId,
    endpoint: authConfig.endpoint,
  });
};

const refreshSession = async (currentSession): Promise<void> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    return new Promise((resolve) => {
      cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {
        resolve();
      });
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAccessToken = async (): Promise<string> => {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getAccessToken().getJwtToken();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getIdToken = async (): Promise<string> => {
  try {
    let currentSession = await Auth.currentSession();
    // If expiring soon, force refresh session to avoid request failure
    if (currentSession.getIdToken().getExpiration() < Date.now() / 1000 + TOKEN_EXPIRING_IN_SECONDS) {
      await refreshSession(currentSession);
      currentSession = await Auth.currentSession();
    }
    return currentSession.getIdToken().getJwtToken();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAuthenticatedUser = async (): Promise<CognitoUser> => {
  try {
    return await Auth.currentAuthenticatedUser(); // This is Amplify's recommended way to check authentication status
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserAttributes = async (user: CognitoUser): Promise<Record<string, string>> => {
  try {
    const userAttributesArr = await Auth.userAttributes(user);
    return userAttributesArr.reduce((acc, attrObj) => {
      // eslint-disable-next-line no-param-reassign
      acc[attrObj.Name] = attrObj.Value;
      return acc;
    }, {});
  } catch (e) {
    return Promise.reject(e);
  }
};
