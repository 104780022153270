import AutonomousCars from 'common/assets/images/learningModule/Lesson14AutonomousCars.png';
import BagOfWords from 'common/assets/images/learningModule/bagOfWords.png';
import BlackSpill from 'common/assets/images/learningModule/blackSpill.png';
import Books from 'common/assets/images/learningModule/books.png';
import Breakout from 'common/assets/images/learningModule/Lesson14Breakout.png';
import ChooseAlgorithm from 'common/assets/images/learningModule/ChooseAlgorithm.png';
import ChooseDuration from 'common/assets/images/learningModule/ChooseDuration.png';
import ChooseTrack from 'common/assets/images/learningModule/ChooseTrack.png';
import Clay from 'common/assets/images/learningModule/clay.png';
import Clay2 from 'common/assets/images/learningModule/clay2.png';
import Datasteps from 'common/assets/images/learningModule/datasteps.png';
import DataTable from 'common/assets/images/learningModule/datatable.png';
import Flowers from 'common/assets/images/learningModule/flowers.png';
import HouseValue from 'common/assets/images/learningModule/houseValue.png';
import Inf1 from 'common/assets/images/learningModule/inf1.png';
import Jackets from 'common/assets/images/learningModule/jackets.png';
import K19B from 'common/assets/images/learningModule/k19b.png';
import K2B from 'common/assets/images/learningModule/k2b.png';
import LabeledData from 'common/assets/images/learningModule/labeledData.png';
import LotSize from 'common/assets/images/learningModule/lotSize.png';
import ML from 'common/assets/images/learningModule/ml.png';
import MlTask from 'common/assets/images/learningModule/mltask.png';
import NameModel from 'common/assets/images/learningModule/NameModel.png';
import NoSpilled from 'common/assets/images/learningModule/nospilled.png';
import Plot from 'common/assets/images/learningModule/plot.png';
import Plot2 from 'common/assets/images/learningModule/plot2.png';
import RewardFunction from 'common/assets/images/learningModule/RewardFunction.png';
import RL from 'common/assets/images/learningModule/Lesson13ReinforcementLearning.png';
import RMS from 'common/assets/images/learningModule/rms.png';
import RMSChart from 'common/assets/images/learningModule/rmsChart.png';
import Silhou from 'common/assets/images/learningModule/silhou.png';
import SL from 'common/assets/images/learningModule/Lesson13SupervisedLearning.png';
import Snowcone from 'common/assets/images/learningModule/snowcone.png';
import SnowCones2 from 'common/assets/images/learningModule/snowcones2.png';
import Spilled from 'common/assets/images/learningModule/spilled.png';
import SpillNumbers from 'common/assets/images/learningModule/spillnumbers.png';
import Spills from 'common/assets/images/learningModule/spills.png';
import StepsInML from 'common/assets/images/learningModule/stepsInMachineLearning.png';
import StepSiter from 'common/assets/images/learningModule/stepsiter.png';
import Superclass from 'common/assets/images/learningModule/superclass.png';
import TradML from 'common/assets/images/learningModule/tradml.png';
import TrafficSignals from 'common/assets/images/learningModule/Lesson14TrafficSignals.png';
import TrainingConfig from 'common/assets/images/learningModule/TrainingConfig.png';
import Tree2 from 'common/assets/images/learningModule/tree2.png';
import Tution from 'common/assets/images/learningModule/tuition.png';
import UL from 'common/assets/images/learningModule/Lesson13UnsupervisedLearning.png';
import UnsuperClust from 'common/assets/images/learningModule/unsuperclust.png';
import YourModelList from 'common/assets/images/learningModule/YourModelList.png';

export const LearningModuleImageMap = {
  autonomousCars: AutonomousCars,
  bagOfWords: BagOfWords,
  blackSpill: BlackSpill,
  books: Books,
  breakout: Breakout,
  chooseAlgorithm: ChooseAlgorithm,
  chooseDuration: ChooseDuration,
  chooseTrack: ChooseTrack,
  clay: Clay,
  clay2: Clay2,
  datasteps: Datasteps,
  dataTable: DataTable,
  flowers: Flowers,
  houseValue: HouseValue,
  inf1: Inf1,
  jackets: Jackets,
  k19b: K19B,
  k2b: K2B,
  labeledData: LabeledData,
  lotSize: LotSize,
  ml: ML,
  mlTask: MlTask,
  nameModel: NameModel,
  noSpilled: NoSpilled,
  plot: Plot,
  plot2: Plot2,
  rewardFunction: RewardFunction,
  rl: RL,
  rms: RMS,
  rmsChart: RMSChart,
  silhou: Silhou,
  sl: SL,
  snowcone: Snowcone,
  snowcone2: SnowCones2,
  spilled: Spilled,
  spillNumbers: SpillNumbers,
  spills: Spills,
  stepsInML: StepsInML,
  stepSiter: StepSiter,
  superclass: Superclass,
  tradeML: TradML,
  trafficSignals: TrafficSignals,
  trainingConfig: TrainingConfig,
  tree2: Tree2,
  tution: Tution,
  ul: UL,
  unsuperClust: UnsuperClust,
  yourModelList: YourModelList,
};
