import React from 'react';
import { withTranslation } from 'react-i18next';
import { SelectProps } from '@amzn/awsui-components-react';
import { SelectFormField } from 'common/components/FormFields';
import { DropDownStatus } from 'common/constants';
import { getItemsFromSchoolList } from 'common/utils/schools';
import { TFunction } from 'i18next';

interface SchoolSelectProps {
  label: string;
  description?: string;
  placeholder: string;
  name: string;
  t: TFunction;
}

interface SchoolSelectState {
  status: SelectProps['statusType'];
  options: SelectProps.Options;
}

class SelectSchoolView extends React.Component<SchoolSelectProps, SchoolSelectState> {
  pageNumber: number;
  filteringText: string;
  constructor(props) {
    super(props);
    this.pageNumber = 0;
    this.filteringText = '';
    this.state = {
      options: [],
      status: DropDownStatus.PENDING,
    };
  }

  fetchItems = () => {
    try {
      const { filteringText, pageNumber } = this;
      // We start scanning after 2 characters entered
      if (filteringText && filteringText.length <= 2) return;
      const { items, hasNext } = getItemsFromSchoolList({
        filteringText,
        pageNumber,
      });
      if (this.filteringText !== filteringText) {
        // there is another request in progress, discard the result of this one
        return;
      }
      this.setState((prevState) => ({
        status: hasNext ? DropDownStatus.PENDING : DropDownStatus.FINISHED,
        options: this.pageNumber === 1 ? items : prevState.options.concat(items),
      }));
    } catch (error) {
      this.setState({
        status: DropDownStatus.ERROR,
      });
    }
  };

  handleLoadItems = ({ detail: { filteringText, firstPage, samePage } }) => {
    let { options } = this.state;
    this.filteringText = filteringText;
    if (firstPage) {
      this.pageNumber = 0;
      options = [];
    }
    if (!samePage) {
      this.pageNumber++;
    }
    this.setState({
      options,
      status: DropDownStatus.LOADING,
    });
    this.fetchItems();
  };

  render() {
    const { label, description, placeholder, name, t } = this.props;
    const { status, options } = this.state;
    return (
      <SelectFormField
        filteringType="manual"
        filteringPlaceholder={t('CompleteSignUp.findSchoolByName')}
        statusType={status}
        loadingText={t('loading')}
        errorText={t('error')}
        recoveryText={t('retry')}
        finishedText={this.filteringText ? t('endOfFiltering', { filteringText: this.filteringText }) : t('endOfAll')}
        empty={t('empty')}
        options={options}
        onLoadItems={this.handleLoadItems}
        label={label}
        description={description}
        placeholder={placeholder}
        name={name}
      />
    );
  }
}

export const SelectSchool = React.memo(withTranslation()(SelectSchoolView));
