/* istanbul ignore file */
import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';

export enum QuizNavigationEnums {
  'PREVIOUS_ONLY' = 'PREVIOUS_ONLY',
  'NEXT_ONLY' = 'NEXT_ONLY',
  'HAS_BOTH' = 'HAS_BOTH',
  'NONE' = 'NONE',
}

interface QuizNavigationProps {
  question: any; // todo use type provided by asteroid backend client
  nextDisableOverride: boolean;
  onExit(): void;
  onPrev(): void;
  onNext(): void;
  onSubmit(): void;
}

const QuizNavigation: React.FC<QuizNavigationProps> = ({
  question,
  nextDisableOverride,
  onExit,
  onPrev,
  onNext,
  onSubmit,
}: QuizNavigationProps) => {
  const getExitButton = () => (
    <Button variant="link" onClick={onExit}>
      Exit quiz
    </Button>
  );
  const getPrevButton = (isDisabled: boolean) => (
    <Button variant="normal" onClick={onPrev} disabled={isDisabled}>
      Previous
    </Button>
  );
  const getNextButton = (isDisabled: boolean) => {
    return (
      <Button variant="primary" onClick={onNext} disabled={isDisabled || nextDisableOverride}>
        Next
      </Button>
    );
  };
  const getSubmitButton = () => (
    <Button variant="primary" onClick={onSubmit} disabled={nextDisableOverride}>
      Submit
    </Button>
  );
  const getButtons = (_question: any) => {
    switch (_question.navigation) {
      case QuizNavigationEnums.PREVIOUS_ONLY:
        return (
          <>
            {getPrevButton(false)}
            {getSubmitButton()}
          </>
        );
      case QuizNavigationEnums.NEXT_ONLY:
        return (
          <>
            {getPrevButton(true)}
            {getNextButton(false)}
          </>
        );
      case QuizNavigationEnums.HAS_BOTH:
        return (
          <>
            {getPrevButton(false)}
            {getNextButton(false)}
          </>
        );
      case QuizNavigationEnums.NONE:
        return (
          <>
            {getPrevButton(true)}
            {getNextButton(true)}
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <div>
      <SpaceBetween direction="horizontal" size="xs">
        {getExitButton()}
        {getButtons(question)}
      </SpaceBetween>
    </div>
  );
};

export default QuizNavigation;
