import type { LearningModuleHomeCardData } from 'types/LearningModuleTypes';
import { LearnModulePageId } from './learningModule/pageIds';

export const LearningModuleHomeData: LearningModuleHomeCardData = [
  {
    cardActionId: LearnModulePageId.INTRO_TO_ML,
    cardActionLabel: 'LearnOverview.SeeAllChapter',
    cardActionVariant: 'primary',
    chapters: 12,
    duration: 'LearnOverview.10Hours',
    id: LearnModulePageId.INTRO_TO_ML,
    level: 'LearnOverview.Beginner',
    title: 'LearnOverview.IntroToML',
    topic: 'LearnOverview.TopicMLandDL',
    isLoading: false,
  },
  {
    cardActionId: LearnModulePageId.INTRO_TO_RL,
    cardActionLabel: 'LearnOverview.SeeAllChapter',
    cardActionVariant: 'primary',
    comingSoon: true,
    chapters: 9,
    duration: 'LearnOverview.10Hours',
    id: LearnModulePageId.INTRO_TO_RL,
    level: 'LearnOverview.Intermediate',
    preRequisites: [
      {
        preRequisitesLabel: 'LearnOverview.IntroToML',
        preRequisitesUrl: {
          href: `/learn/${LearnModulePageId.INTRO_TO_ML}`,
        },
      },
    ],
    title: 'LearnOverview.IntroToRL',
    topic: 'LearnOverview.TopicMLandDL',
    isLoading: false,
  },
];
