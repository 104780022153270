import { SpaceBetween } from '@amzn/awsui-components-react';
import { EsperanzaContainer } from 'common/components/CompleteSignUp/EsperanzaContainer';
import { EsperanzaSteps } from 'common/components/CompleteSignUp/EsperanzaSteps';
import { OptInEsperanzaFields } from 'common/components/CompleteSignUp/OptInEsperanzaFields';

export const EsperanzaBanner = (): JSX.Element => (
  <EsperanzaContainer>
    <SpaceBetween size="m">
      <EsperanzaSteps />
      <OptInEsperanzaFields />
    </SpaceBetween>
  </EsperanzaContainer>
);
