import React from 'react';
import FormField, { FormFieldProps } from '@amzn/awsui-components-react/polaris/form-field';
import Tiles, { TilesProps } from '@amzn/awsui-components-react/polaris/tiles';
import { useField } from 'formik';

interface TilesFormWrapperProps extends Omit<TilesProps, 'value'> {
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
  name: string;
  validate?: (value: any) => undefined | string | Promise<any>;
}

type TilesFormFieldPropTypes = FormFieldProps & TilesFormWrapperProps;

export const TilesFormFieldView: React.FC<TilesFormFieldPropTypes> = ({
  dataAnalyticsLabel,
  dataAnalyticsTypeLabel,
  description,
  constraintText,
  label,
  stretch = true,
  onChange,
  name,
  validate,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const handleChange: TilesProps['onChange'] = (event) => {
    helpers.setValue(event.detail.value);
    onChange?.(event);
  };

  const formFieldProps = {
    description,
    errorText: meta.touched && meta.error ? meta.error : null,
    constraintText,
    label,
    stretch,
  };

  const TilesFieldProps = {
    ...field,
    ...otherProps,
    onChange: handleChange,
  };

  return (
    <FormField {...formFieldProps}>
      <Tiles data-analytics={dataAnalyticsLabel} data-analytics-type={dataAnalyticsTypeLabel} {...TilesFieldProps} />
    </FormField>
  );
};

export const TilesFormField = React.memo(TilesFormFieldView);
