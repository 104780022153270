import { SelectProps } from '@amzn/awsui-components-react/polaris/select';

export const DEFAULT_AVATAR_TOP_OPTION = 'Helmet';
export const DEFAULT_AVATAR_BACKGROUND_COLOR_LIGHT = '#F0F0F0'; // Light gray
export const DEFAULT_AVATAR_BACKGROUND_COLOR_DARK = '#5f6b7a'; // Light gray

export enum AvatarSize {
  AVATAR_CONFIG_DIALOG = 'avatarConfigDialog',
  LEADERBOARD = 'leaderboard',
  PROFILE = 'profile',
}

export enum AvatarOptionType {
  ACCESSORIES = 'Accessories',
  CLOTHING = 'Clothing',
  CLOTHING_COLOR = 'ClothingColor',
  COUNTRY_CODE = 'CountryCode',
  EYEBROWS = 'Eyebrows',
  EYES = 'Eyes',
  FACIAL_HAIR_COLOR = 'FacialHairColor',
  FACIAL_HAIR = 'FacialHair',
  HAIR_COLOR = 'HairColor',
  HAT_COLOR = 'HatColor',
  MOUTH = 'Mouth',
  SKIN_COLOR = 'SkinColor',
  TOP = 'Top',
  TSHIRT_GRAPHIC = 'TshirtGraphic',
}

export enum AvatarConfigDataOption {
  ACCESSORIES = 'accessories',
  BACKGROUND_COLOR = 'backgroundColor',
  CLOTHING = 'clothing',
  CLOTHING_COLOR = 'clothingColor',
  COUNTRY_CODE = 'countryCode',
  EYEBROWS = 'eyebrows',
  EYES = 'eyes',
  FACIAL_HAIR = 'facialHair',
  FACIAL_HAIR_COLOR = 'facialHairColor',
  HAIR_COLOR = 'hairColor',
  HAT_COLOR = 'hatColor',
  MOUTH = 'mouth',
  SKIN_COLOR = 'skinColor',
  TOP = 'top',
  TSHIRT_GRAPHIC = 'tShirtGraphic',
}

export type AvatarConfigSelectOptionMap = {
  [Key in AvatarConfigDataOption]: SelectProps.Option;
};
