import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { checkAuthStatus, getIsAuthenticated } from 'store/auth';
import { checkCompletedSignUp, getProfileIsFetching } from 'store/profile';
import pageCatalog from 'pages/pageCatalog';
import { REDIRECT_URL } from 'common/components/RequiresAuth';

export interface RequiresAuthProps {
  children: React.ReactElement;
}

/**
 * This component acts as an authentication wrapper for protected routes. It will only render
 * if the user is authenticated, otherwise it will redirect to Login.
 */
export const RequiresAuth: React.FC<RequiresAuthProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isFetchingProfile = useAppSelector(getProfileIsFetching);
  const history = useHistory();

  useEffect(() => {
    const checkCurrentAuthStatus = async () => {
      try {
        // This returns SUCCESS or throws an auth error
        await dispatch(checkAuthStatus())
          .then(unwrapResult)
          .then(() => {
            dispatch(checkCompletedSignUp());
          });
      } catch (e) {
        if (location.pathname === pageCatalog.SignIn.getPath()) {
          return;
        }
        const queryParams = new URLSearchParams();
        queryParams.set(REDIRECT_URL, location.pathname);

        history.push({
          pathname: pageCatalog.SignIn.getPath(),
          search: queryParams.toString(),
        });
      }
    };
    checkCurrentAuthStatus();
  }, [dispatch, history]);

  if (!isAuthenticated || isFetchingProfile) {
    return <Spinner />;
  }

  return children;
};
