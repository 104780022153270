/* istanbul ignore file */
import { useState } from 'react';
import { default as ReactCountdown, zeroPad } from 'react-countdown';

interface CountDownProps {
  remainingTime: number;
  onComplete(): void;
}

const CountDown = ({ remainingTime, onComplete }: CountDownProps) => {
  const [initialTime] = useState(Date.now() + remainingTime);
  const [isTimeOver, setisTimeOver] = useState(false);

  const timeSeparator = () => (
    <div
      style={{
        position: 'relative',
        top: '-12px',
        fontWeight: 'bold',
        fontSize: '18px',
      }}
    >
      :
    </div>
  );

  const timeBlock = (duration: string, denomination: string) => (
    <div style={{ margin: '4px' }}>
      <div
        style={{
          padding: '8px',
          fontSize: '24px',
          backgroundColor: '#B5D6F4',
          color: 'black',
          borderRadius: '12px',
          fontWeight: 'bold',
        }}
      >
        {duration}
      </div>
      <div style={{ textAlign: 'center', fontSize: '12px' }}>{denomination}</div>
    </div>
  );

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed && !isTimeOver) {
      setisTimeOver(true);
      onComplete();
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {hours !== 0 && (
          <>
            {timeBlock(zeroPad(hours), 'Hours')}
            {timeSeparator()}
          </>
        )}
        {minutes !== 0 && (
          <>
            {timeBlock(zeroPad(minutes), 'Minutes')}
            {timeSeparator()}
          </>
        )}
        {timeBlock(zeroPad(seconds), 'Seconds')}
      </div>
    );
  };

  return (
    <>
      <ReactCountdown date={initialTime} renderer={renderer} zeroPadTime={2} />
    </>
  );
};

export default CountDown;
