import { Trans, useTranslation } from 'react-i18next';

import { Button, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz1.4',
  title: 'Chapters.Lesson4.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.4.1.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.stepsInML,
      imageAltText: 'Chapters.Lesson4.Wizard1.imageAltText',
      width: '70%',
      height: '50%',
    },
    {
      id: 'wz1.4.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson4.Wizard1.content1',
    },
    {
      id: 'wz1.4.1.30',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson4.Wizard1.youtubeTitle',
      youtubeId: 'Q5N7JGTNBHg',
    },
    {
      id: 'wz1.4.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson4.Wizard1.content2',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz1.4',
  title: 'Chapters.Lesson4.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'summaryContent1.wz1.4.001',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson4.Wizard2.content1',
    },
    {
      id: 'summaryContent1.wz1.4.002',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'summaryContent1.wz1.4.002-1',
          text: 'Chapters.Lesson4.Wizard2.list1',
        },
        {
          id: 'summaryContent1.wz1.4.002-2',
          text: 'Chapters.Lesson4.Wizard2.list2',
        },
        {
          id: 'summaryContent1.wz1.4.002-3',
          text: 'Chapters.Lesson4.Wizard2.list3',
        },
        {
          id: 'summaryContent1.wz1.4.002-4',
          text: 'Chapters.Lesson4.Wizard2.list4',
        },
        {
          id: 'summaryContent1.wz1.4.002-5',
          text: 'Chapters.Lesson4.Wizard2.list5',
        },
        {
          id: 'summaryContent1.wz1.4.002-6',
          text: 'Chapters.Lesson4.Wizard2.list6',
        },
      ],
    },
  ],
};

export const Lesson4 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz1.4.1',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'summaryContent2.wz1.4.1.002',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson4.Wizard2.heading1',
      },
      {
        id: 'summaryContent2.wz1.4.1.002',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson4.Wizard2.content2',
      },
      {
        id: 'summaryContent2.wz1.4.1.003',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <Button variant="normal" onClick={onClick}>
            <Trans i18nKey="WizardI18nStrings.StartChapter" />
          </Button>
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...(summaryContent2 as any)} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
