import { Formik, Form } from 'formik';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useContainerQuery } from '@amzn/awsui-component-toolkit';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import { VALID_EMAIL_PATTERN, VALID_PASSWORD_PATTERN } from 'common/constants';
import { InputFormField, PasswordFormField } from 'common/components/FormFields';
import { REDIRECT_URL } from 'common/components/RequiresAuth';
import pageCatalog from 'pages/pageCatalog';
import { useAppDispatch, useAppSelector, useQuery } from 'store/utils/hooks';
import { getAuthIsLoading, getIsAuthenticated, signIn } from 'store/auth';
import { getAccountDocEndpoint } from 'pages/utils';

interface SignInFormValues {
  email: string;
  password: string;
}

const initialValues: SignInFormValues = { email: '', password: '' };

const MOBILE_BREAKPOINT = 385;

export const SignInForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isLoading = useAppSelector(getAuthIsLoading);
  const [useMobileView, ref] = useContainerQuery((entry) => entry.contentBoxWidth <= MOBILE_BREAKPOINT);

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrlQuery = query.get(REDIRECT_URL);
      const redirectUrl =
        redirectUrlQuery !== pageCatalog.SignIn.getPath()
          ? redirectUrlQuery ?? pageCatalog.StudentHome.getPath()
          : pageCatalog.StudentHome.getPath();
      history.push(redirectUrl);
    }
  }, [history, isAuthenticated, query]);

  const onSubmit = (data: SignInFormValues) => {
    const { email, password } = data;
    dispatch(signIn({ username: email, password }));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('required')).matches(VALID_EMAIL_PATTERN, t('emailFormat')),
    password: Yup.string().required(t('required')).matches(VALID_PASSWORD_PATTERN, t('passwordFormat')),
  });

  return (
    <div ref={ref}>
      <Container header={<Header description={<div>{t('signInDesc')}</div>}>{t('signInLabel')}</Header>}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          <Form>
            <SpaceBetween size="l">
              <InputFormField
                id="SignInEmailField"
                data-test-id="emailField"
                label={t('emailLabel')}
                stretch
                name="email"
                type="email"
              />
              <PasswordFormField id="SignInPwField" showForgotPasswordLabel />
              <Button
                id="SignInBtn"
                variant="primary"
                formAction="submit"
                loading={!!isLoading}
                data-test-id="signInButton"
              >
                {t('signInLabel')}
              </Button>
              <div>
                <Box padding={{ bottom: useMobileView ? 'l' : 'xxs' }}>
                  <Trans
                    i18nKey="dontHaveAcct"
                    components={[
                      <Link
                        onFollow={() => {
                          history.push({
                            pathname: pageCatalog.SignUp.getPath(),
                            search: location.search,
                          });
                        }}
                      />,
                    ]}
                  />
                </Box>
                <Trans
                  i18nKey="dontHaveAcctSub"
                  components={[<Link target="_blank" href={getAccountDocEndpoint()} />]}
                />
              </div>
            </SpaceBetween>
          </Form>
        </Formik>
      </Container>
    </div>
  );
};
