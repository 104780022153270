import { useTranslation } from 'react-i18next';

import { Button, SpaceBetween } from '@amzn/awsui-components-react';

import { KeyValuePair } from 'common/components/KeyValuePair';
import { TFuncKey } from 'i18next';

interface EndSectionProps {
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
  timeRequire: TFuncKey;
  level: TFuncKey;
  prereq: TFuncKey;
  path?: string;
  onClick?: () => void;
}
export const EndSection = ({
  dataAnalyticsLabel = 'startNextChapter-Button',
  dataAnalyticsTypeLabel = 'eventDetail',
  timeRequire,
  level,
  prereq,
  onClick,
}: EndSectionProps) => {
  const { t } = useTranslation();
  return (
    <SpaceBetween direction="vertical" size="s">
      <KeyValuePair label={t('WizardI18nStrings.TimeRequired')}>{t(timeRequire)}</KeyValuePair>
      <KeyValuePair label={t('WizardI18nStrings.Level')}>{t(level)}</KeyValuePair>
      <KeyValuePair label={t('WizardI18nStrings.Prerequisites')}>{t(prereq)}</KeyValuePair>
      {onClick && (
        <Button
          data-analytics={dataAnalyticsLabel}
          data-analytics-type={dataAnalyticsTypeLabel}
          variant="normal"
          onClick={onClick}
        >
          {t('WizardI18nStrings.StartChapter')}
        </Button>
      )}
    </SpaceBetween>
  );
};
