import { useTranslation } from 'react-i18next';
import { Header } from '@amzn/awsui-components-react';
import { InfoLink } from 'common/components/HelpPanel';
import { LEARN_OVERVIEW_HELP_PANEL_URL } from 'common/constants';

export const PageHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Header
      variant="h1"
      description={t('LearnOverview.LearnOverviewDesc')}
      info={<InfoLink helpId={LEARN_OVERVIEW_HELP_PANEL_URL} />}
    >
      {t('LearnOverview.LearnOverviewTitle')}
    </Header>
  );
};
