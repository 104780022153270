import { RootState } from 'store';

export const getLmsTrackerModulesError = (state: RootState) => state.lmsTracker.lmsTrackerModules.error;
export const getLmsTrackerModulesIsLoading = (state: RootState) => state.lmsTracker.lmsTrackerModules.isLoading;
export const getLmsTrackerModules = (state: RootState) => state.lmsTracker.lmsTrackerModules?.items;
export const getLmsTrackerChaptersError = (state: RootState) => state.lmsTracker.lmsTrackerChapters.error;
export const getLmsTrackerChaptersIsLoading = (state: RootState) => state.lmsTracker.lmsTrackerChapters.isLoading;
export const getLmsTrackerChapters = (state: RootState) => state.lmsTracker.lmsTrackerChapters?.items;
export const getLmsTrackerSectionsError = (state: RootState) => state.lmsTracker.lmsTrackerSections.error;
export const getLmsTrackerSectionsIsLoading = (state: RootState) => state.lmsTracker.lmsTrackerSections.isLoading;
export const getLmsTrackerSections = (state: RootState) => state.lmsTracker.lmsTrackerSections?.items;
