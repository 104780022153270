import { SelectProps } from '@amzn/awsui-components-react';
import {
  optionLabels as avatarOptionLabels,
  optionLabelsWithCategoryGroups as avatarOptions,
} from '@amzn/react-avataaar';

import {
  AvatarConfigDataOption,
  AvatarConfigSelectOptionMap,
  AvatarOptionType,
  DEFAULT_AVATAR_TOP_OPTION,
} from 'common/constants';
import { AsteroidTypes, DeepRacerLiteTypes } from 'types';

// Add DeepRacer default avatar top option
const avatarTopNonGroupedOptions = avatarOptions.Top.nonGroupedOptions as string[];
avatarTopNonGroupedOptions.push(DEFAULT_AVATAR_TOP_OPTION);
avatarOptionLabels.Top[DEFAULT_AVATAR_TOP_OPTION] = 'Default - Helmet';

export const convertAvatarConfigDataOptionToAvatarOption = (
  avatarConfigOption: AvatarConfigDataOption
): AvatarOptionType => {
  const avatarConfigDataOptionToAvatarOptionMap = {
    [AvatarConfigDataOption.ACCESSORIES]: AvatarOptionType.ACCESSORIES,
    [AvatarConfigDataOption.CLOTHING]: AvatarOptionType.CLOTHING,
    [AvatarConfigDataOption.CLOTHING_COLOR]: AvatarOptionType.CLOTHING_COLOR,
    [AvatarConfigDataOption.COUNTRY_CODE]: AvatarOptionType.COUNTRY_CODE,
    [AvatarConfigDataOption.EYEBROWS]: AvatarOptionType.EYEBROWS,
    [AvatarConfigDataOption.EYES]: AvatarOptionType.EYES,
    [AvatarConfigDataOption.FACIAL_HAIR]: AvatarOptionType.FACIAL_HAIR,
    [AvatarConfigDataOption.FACIAL_HAIR_COLOR]: AvatarOptionType.FACIAL_HAIR_COLOR,
    [AvatarConfigDataOption.HAIR_COLOR]: AvatarOptionType.HAIR_COLOR,
    [AvatarConfigDataOption.HAT_COLOR]: AvatarOptionType.HAT_COLOR,
    [AvatarConfigDataOption.MOUTH]: AvatarOptionType.MOUTH,
    [AvatarConfigDataOption.SKIN_COLOR]: AvatarOptionType.SKIN_COLOR,
    [AvatarConfigDataOption.TOP]: AvatarOptionType.TOP,
    [AvatarConfigDataOption.TSHIRT_GRAPHIC]: AvatarOptionType.TSHIRT_GRAPHIC,
  };

  return avatarConfigDataOptionToAvatarOptionMap[avatarConfigOption];
};

export const convertAvatarOptionToAvatarConfigDataOption = (avatarOption: AvatarOptionType): AvatarConfigDataOption => {
  const avatarOptionToAvatarConfigDataOptionMap = {
    [AvatarOptionType.ACCESSORIES]: AvatarConfigDataOption.ACCESSORIES,
    [AvatarOptionType.CLOTHING]: AvatarConfigDataOption.CLOTHING,
    [AvatarOptionType.CLOTHING_COLOR]: AvatarConfigDataOption.CLOTHING_COLOR,
    [AvatarOptionType.COUNTRY_CODE]: AvatarConfigDataOption.COUNTRY_CODE,
    [AvatarOptionType.EYEBROWS]: AvatarConfigDataOption.EYEBROWS,
    [AvatarOptionType.EYES]: AvatarConfigDataOption.EYES,
    [AvatarOptionType.FACIAL_HAIR]: AvatarConfigDataOption.FACIAL_HAIR,
    [AvatarOptionType.FACIAL_HAIR_COLOR]: AvatarConfigDataOption.FACIAL_HAIR_COLOR,
    [AvatarOptionType.HAIR_COLOR]: AvatarConfigDataOption.HAIR_COLOR,
    [AvatarOptionType.HAT_COLOR]: AvatarConfigDataOption.HAT_COLOR,
    [AvatarOptionType.MOUTH]: AvatarConfigDataOption.MOUTH,
    [AvatarOptionType.SKIN_COLOR]: AvatarConfigDataOption.SKIN_COLOR,
    [AvatarOptionType.TOP]: AvatarConfigDataOption.TOP,
    [AvatarOptionType.TSHIRT_GRAPHIC]: AvatarConfigDataOption.TSHIRT_GRAPHIC,
  };

  return avatarOptionToAvatarConfigDataOptionMap[avatarOption];
};

export const convertUserAvatarConfigToAvatarConfigData = (userAvatarConfig: DeepRacerLiteTypes.UserAvatarConfig) => {
  const avatarConfigData = {} as AsteroidTypes.AvatarConfigData;

  if (!userAvatarConfig) {
    return avatarConfigData;
  }

  for (const [key, optionValue] of Object.entries(userAvatarConfig)) {
    const avatarOption = key as AvatarOptionType;
    const avatarConfigDataOption = convertAvatarOptionToAvatarConfigDataOption(avatarOption);
    avatarConfigData[avatarConfigDataOption] = optionValue;
  }

  return avatarConfigData;
};

export const getOptionLabel = (avatarConfigDataOption: AvatarConfigDataOption, optionValue: string): string => {
  const avatarOption = convertAvatarConfigDataOptionToAvatarOption(avatarConfigDataOption);
  return avatarOptionLabels[avatarOption]?.[optionValue] ?? '--';
};

export const convertAvatarConfigToSelectOptions = (avatarConfig: AsteroidTypes.AvatarConfigData) => {
  return Object.entries(avatarConfig).reduce((acc, [key, value]) => {
    const label = getOptionLabel(key as AvatarConfigDataOption, value);
    acc[key] = { label, value };
    return acc;
  }, {} as AvatarConfigSelectOptionMap);
};

export const convertSelectOptionsToAvatarConfig = (avatarConfig: AvatarConfigSelectOptionMap) => {
  return Object.entries(avatarConfig).reduce((acc, [key, value]) => {
    acc[key] = value?.value ?? '';
    return acc;
  }, {} as AsteroidTypes.AvatarConfigData);
};

export const getOptionsByType = (avatarConfigDataOption: AvatarConfigDataOption): SelectProps.Options => {
  const avatarOption = convertAvatarConfigDataOptionToAvatarOption(avatarConfigDataOption);
  const options = avatarOptions[avatarOption as keyof typeof avatarOptions];
  const nonGroupedOptions = options.nonGroupedOptions as string[];
  const optionGroups = options.optionGroups;

  const createSelectOption = (optionName: string): SelectProps.Option => {
    const label = avatarOptionLabels[avatarOption][optionName];
    return {
      label,
      value: optionName,
    };
  };

  const nonGroupedSelectOptions: SelectProps.Option[] = nonGroupedOptions.map(createSelectOption);

  let groupedSelectOptions = optionGroups.map((optionGroup: { label: string; options: string[] }) => {
    return {
      label: optionGroup.label,
      options: optionGroup.options.map(createSelectOption),
    };
  });

  const labelComparator = (a, b) => {
    if (a?.label && b?.label) {
      return a.label.localeCompare(b.label);
    }
    return 0;
  };

  if (avatarOption !== AvatarOptionType.SKIN_COLOR) {
    nonGroupedSelectOptions.sort(labelComparator);
    groupedSelectOptions = groupedSelectOptions
      .map((a) => {
        return {
          label: a.label,
          options: a.options.sort(labelComparator),
        };
      })
      .sort(labelComparator);
  }

  return nonGroupedSelectOptions.concat(groupedSelectOptions);
};

export const blankOption: SelectProps.Option = {
  value: '',
  label: '--',
};

export const isBlankOption = (id: string): boolean => {
  if (!id || id === 'Blank') {
    return true;
  }
  return false;
};

export const isAccessoriesEnabled = (topOption: string): boolean => {
  const topsWithAccessoriesDisabled = [DEFAULT_AVATAR_TOP_OPTION, 'Eyepatch'];
  return !topsWithAccessoriesDisabled.includes(topOption);
};

export const isClothingColorEnabled = (clothingOption: string): boolean => {
  const clothingWithClothingColorDisabled = ['BlazerShirt', 'BlazerSweater'];
  return !clothingWithClothingColorDisabled.includes(clothingOption);
};

export const isDefaultAvatar = (topOption: string): boolean => {
  const validTopOptionValues = Object.keys(avatarOptions[AvatarOptionType.TOP]).filter(
    (key: string) => key !== 'optionGroups' && key !== 'nonGroupedOptions' && key !== 'defaultValue'
  );
  return !topOption || topOption === DEFAULT_AVATAR_TOP_OPTION || !validTopOptionValues.includes(topOption);
};

export const isFacialHairEnabled = (topOption: string): boolean => {
  const topsWithFacialHairDisabled = [DEFAULT_AVATAR_TOP_OPTION, 'Hijab'];
  return !topsWithFacialHairDisabled.includes(topOption);
};

export const isFacialHairColorEnabled = (topOption: string, facialHairOption: string): boolean => {
  return isFacialHairEnabled(topOption) && !isBlankOption(facialHairOption);
};

export const isHairColorEnabled = (topOption: string): boolean => {
  const topsWithHairColorDisabled = [
    DEFAULT_AVATAR_TOP_OPTION,
    'NoHair',
    'Eyepatch',
    'Hat',
    'Hijab',
    'LongHairFrida',
    'Turban',
    'WinterHat1',
    'WinterHat2',
    'WinterHat3',
    'WinterHat4',
  ];
  return !topsWithHairColorDisabled.includes(topOption);
};

export const isHatColorEnabled = (topOption: string): boolean => {
  const hatsWithColorEnabled = ['Hijab', 'Turban', 'WinterHat1', 'WinterHat2', 'WinterHat3', 'WinterHat4'];
  return hatsWithColorEnabled.includes(topOption);
};

export const isGraphicShirt = (clothingOption: string): boolean => {
  return clothingOption === 'GraphicShirt';
};

export const accessoriesOptions = getOptionsByType(AvatarConfigDataOption.ACCESSORIES);
export const clothingOptions = getOptionsByType(AvatarConfigDataOption.CLOTHING);
export const clothingColorOptions = getOptionsByType(AvatarConfigDataOption.CLOTHING_COLOR);
export const eyebrowOptions = getOptionsByType(AvatarConfigDataOption.EYEBROWS);
export const eyeOptions = getOptionsByType(AvatarConfigDataOption.EYES);
export const facialHairOptions = getOptionsByType(AvatarConfigDataOption.FACIAL_HAIR);
export const facialHairColorOptions = getOptionsByType(AvatarConfigDataOption.FACIAL_HAIR_COLOR);
export const hairColorOptions = getOptionsByType(AvatarConfigDataOption.HAIR_COLOR);
export const hatColorOptions = getOptionsByType(AvatarConfigDataOption.HAT_COLOR);
export const mouthOptions = getOptionsByType(AvatarConfigDataOption.MOUTH);
export const skinColorOptions = getOptionsByType(AvatarConfigDataOption.SKIN_COLOR);
export const topOptions = getOptionsByType(AvatarConfigDataOption.TOP);
export const tShirtGraphicOptions = getOptionsByType(AvatarConfigDataOption.TSHIRT_GRAPHIC);
