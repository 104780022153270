import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, ColumnLayout, Link } from '@amzn/awsui-components-react';
import pageCatalog from 'pages/pageCatalog';

interface EsperanzaStepsProps {
  hasLink?: boolean;
}

export const EsperanzaSteps = ({ hasLink }: EsperanzaStepsProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <Box margin={{ bottom: 's' }} variant="h4">
        {t('ProfilePage.HereIsWhatToDo')}
      </Box>
      <ColumnLayout columns={3}>
        <Box>
          <Box margin={{ bottom: 'xxxs' }} fontWeight="bold">
            {t('ProfilePage.QualifyTitle1')}
          </Box>
          <Box>
            {hasLink ? (
              <Trans i18nKey={'ProfilePage.QualifyDesc1'}>
                complete
                <Link onFollow={() => history.push(pageCatalog.Learn.getPath())} data-testid="learning-module">
                  learning
                </Link>
                on
              </Trans>
            ) : (
              <Trans i18nKey={'ProfilePage.QualifyDesc1'}></Trans>
            )}
          </Box>
        </Box>
        <Box>
          <Box margin={{ bottom: 'xxxs' }} fontWeight="bold">
            {t('ProfilePage.QualifyTitle2')}
          </Box>
          <Box>
            {hasLink ? (
              <Trans i18nKey={'ProfilePage.QualifyDesc2'}>
                apply
                <Link onFollow={() => history.push(pageCatalog.CreateModel.getPath())}>create</Link>
                tosubmit
              </Trans>
            ) : (
              <Trans i18nKey={'ProfilePage.QualifyDesc2'}></Trans>
            )}
          </Box>
        </Box>
        <Box>
          <Box margin={{ bottom: 'xxxs' }} fontWeight="bold">
            {t('ProfilePage.QualifyTitle3')}
          </Box>
          <Box>{t('ProfilePage.QualifyDesc3')}</Box>
        </Box>
      </ColumnLayout>
    </>
  );
};
