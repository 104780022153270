import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { InputFormField } from 'common/components/FormFields';
import { VALID_PASSWORD_PATTERN, APP_CONTENT_ID } from 'common/constants';
import * as Yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { changePassword, getAuthIsLoading } from 'store/auth';
import { displayErrorNotification, displaySuccessNotification } from 'store/notifications';
import i18n from 'i18n';

interface PasswordDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ChangePasswordModal: React.FC<PasswordDialogProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialValues = { oldPassword: '', newPassword: '' };
  const isAuthLoading = useAppSelector(getAuthIsLoading);

  const handleClose = (props) => {
    setIsOpen(false);
    props?.resetForm({});
    props?.setErrors({});
    props?.setTouched({});
  };

  const onSubmit = async (data, props) => {
    const { oldPassword, newPassword } = data;
    await dispatch(changePassword({ oldPassword, newPassword }))
      .then(unwrapResult)
      .then(() => {
        dispatch(displaySuccessNotification({ content: i18n.t('ChangePasswordModal.SuccessMessage') }));
        handleClose(props);
      })
      .catch((error) => {
        dispatch(displayErrorNotification({ content: error.message }));
        handleClose(props);
      });
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('required')).matches(VALID_PASSWORD_PATTERN, t('passwordFormat')),
    newPassword: Yup.string().required(t('required')).matches(VALID_PASSWORD_PATTERN, t('passwordFormat')),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, props) => onSubmit(values, props)}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Modal
          modalRoot={document.getElementById(APP_CONTENT_ID) || undefined}
          onDismiss={() => handleClose(props)}
          visible={isOpen}
          closeAriaLabel={t('ChangePasswordModal.CloseModal')}
          size="medium"
          header={t('ChangePasswordModal.UpdatePasswordHeader')}
        >
          <Form>
            <SpaceBetween size="m">
              <InputFormField
                label={t('ChangePasswordModal.CurrPassword')}
                placeholder={t('ChangePasswordModal.YourPassword')}
                name="oldPassword"
                type="password"
              />
              <InputFormField
                label={t('ChangePasswordModal.NewPassword')}
                constraintText={t('ChangePasswordModal.passwordFormat')}
                placeholder={t('ChangePasswordModal.NewPassPlaceholder')}
                name="newPassword"
                type="password"
              />
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" onClick={() => handleClose(props)}>
                    {t('ChangePasswordModal.Cancel')}
                  </Button>
                  <Button loading={isAuthLoading} variant="primary" formAction="submit">
                    {t('ChangePasswordModal.ChangePassword')}
                  </Button>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
