import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Checkbox, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { InputFormField } from 'common/components/FormFields';
import pageCatalog from 'pages/pageCatalog';

interface PasswordFormFieldProps {
  showForgotPasswordLabel?: boolean;
  isNewPassword?: boolean;
  id?: string;
}

const PasswordFormFieldView = ({ showForgotPasswordLabel, isNewPassword, id }: PasswordFormFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    history.push(pageCatalog.ForgotPasswordRequest.getPath());
  };

  const label = isNewPassword ? t('newPassword') : t('passwordLabel');
  return (
    <SpaceBetween size="s">
      <InputFormField
        data-test-id="passwordField"
        label={label}
        name="password"
        stretch
        type={showPassword ? 'text' : 'password'}
        id={id}
        constraintText={!showForgotPasswordLabel && t('passwordFormat')}
      />
      <SpaceBetween direction="horizontal" size="xxs">
        <Checkbox checked={showPassword} onChange={toggleShowPassword}>
          {t('showPassword')}
        </Checkbox>
        {showForgotPasswordLabel && (
          <Link onFollow={handleForgotPassword} data-testid="forgot-password-link">
            {t('forgotPassword')}
          </Link>
        )}
      </SpaceBetween>
    </SpaceBetween>
  );
};

export const PasswordFormField = React.memo(PasswordFormFieldView);
