import { useTranslation } from 'react-i18next';

import { SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { EndSection } from '../../common/EndSection';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz5.17.1',
  title: 'Chapters.Lesson17.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.17.1.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard1.content1',
    },
    {
      id: 'wz5.17.1.20',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson17.Wizard1.youtubeTitle',
      youtubeId: 'pnc0z76bKzA',
    },
    {
      id: 'wz5.17.1.30',
      widgetType: WizardContentType.HEADER,
      variant: 'h2',
      text: 'Chapters.Lesson17.Wizard1.heading1',
    },
    {
      id: 'wz5.17.1.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard1.content2',
    },
    {
      id: 'wz5.17.1.50',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        { id: 'wz5.17.1.50-1', text: 'Chapters.Lesson17.Wizard1.list1' },
        { id: 'wz5.17.1.50-2', text: 'Chapters.Lesson17.Wizard1.list2' },
        { id: 'wz5.17.1.50-3', text: 'Chapters.Lesson17.Wizard1.list3' },
        { id: 'wz5.17.1.50-4', text: 'Chapters.Lesson17.Wizard1.list4' },
        { id: 'wz5.17.1.50-5', text: 'Chapters.Lesson17.Wizard1.list5' },
        { id: 'wz5.17.1.50-6', text: 'Chapters.Lesson17.Wizard1.list6' },
      ],
    },
    {
      id: 'wz5.17.1.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard1.content3',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz5.17.2',
  title: 'Chapters.Lesson17.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.17.2.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.nameModel,
      imageAltText: 'Chapters.Lesson17.Wizard2.imageAlt',
      width: '60%',
      height: '60%',
    },
    {
      id: 'wz5.17.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard2.content1',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz5.17.3',
  title: 'Chapters.Lesson17.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.17.3.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.chooseTrack,
      imageAltText: 'Chapters.Lesson17.Wizard3.imgAlt',
      width: '60%',
      height: '60%',
    },
    {
      id: 'wz5.17.3.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard3.content1',
    },
  ],
};

const wz4: WizardStepsProp = {
  id: 'wz5.17.4',
  title: 'Chapters.Lesson17.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.17.4.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.chooseAlgorithm,
      imageAltText: 'Chapters.Lesson17.Wizard4.imgAlt',
      width: '60%',
      height: '60%',
    },
    {
      id: 'wz5.17.4.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard4.content1',
    },
  ],
};

const wz5: WizardStepsProp = {
  id: 'wz5.17.5',
  title: 'Chapters.Lesson17.Wizard5.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.17.5.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.rewardFunction,
      imageAltText: 'Chapters.Lesson17.Wizard5.imgAlt',
      width: '60%',
      height: '60%',
    },
    {
      id: 'wz5.17.5.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard5.content1',
    },
  ],
};

const wz6: WizardStepsProp = {
  id: 'wz5.17.6',
  title: 'Chapters.Lesson17.Wizard6.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.17.6.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.chooseDuration,
      imageAltText: 'Chapters.Lesson17.Wizard6.imgAlt',
      width: '60%',
      height: '60%',
    },
    {
      id: 'wz5.17.6.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard6.content1',
    },
  ],
};

const wz7: WizardStepsProp = {
  id: 'wz5.17.7',
  title: 'Chapters.Lesson17.Wizard7.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.17.7.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.trainingConfig,
      imageAltText: 'Chapters.Lesson17.Wizard7.imgAlt1',
      width: '60%',
      height: '60%',
    },
    {
      id: 'wz5.17.7.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard7.content1',
    },
    {
      id: 'wz5.17.7.30',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.yourModelList,
      imageAltText: 'Chapters.Lesson17.Wizard7.imgAlt2',
      width: '60%',
      height: '60%',
    },
    {
      id: 'wz5.17.7.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard7.content2',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz5',
  title: 'Chapters.Lesson17.Wizard8.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz5.18.8.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson17.Wizard8.content1',
    },
  ],
};

export const Lesson17 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz5',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz5.17.8.10',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson17.Wizard8.heading1',
      },
      {
        id: 'wz5.17.8.20',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson17.Wizard8.content2',
      },
      {
        id: 'wz5.17.8.30',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <EndSection
            level="Chapters.Lesson17.Wizard8.level"
            timeRequire="Chapters.Lesson17.Wizard8.time"
            prereq="Chapters.Lesson17.Wizard8.prerequisites"
            onClick={onClick}
          />
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(wz4.title) as string,
          content: <WizardContent {...wz4} />,
        },
        {
          title: t(wz5.title) as string,
          content: <WizardContent {...wz5} />,
        },
        {
          title: t(wz6.title) as string,
          content: <WizardContent {...wz6} />,
        },
        {
          title: t(wz7.title) as string,
          content: <WizardContent {...wz7} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
