import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@amzn/awsui-components-react';
import { InputFormField, MultiselectFormField } from 'common/components/FormFields';
import { CompleteSignUpFields, RACE_LIST, SELF_IDENTIFY_OPTION } from 'common/constants';
import { onMultiselectChange } from 'common/utils/profile';

export const RaceFieldsView = (): JSX.Element => {
  const { t } = useTranslation();
  const [raceField, , raceFieldHelper] = useField({ name: CompleteSignUpFields.RACE });
  return (
    <Box>
      <MultiselectFormField
        label={
          <>
            {t('CompleteSignUp.raceLabel')} - <i>{t('optional')}</i>
          </>
        }
        placeholder={t('CompleteSignUp.raceLabel')}
        options={RACE_LIST}
        onChange={({ detail }) => onMultiselectChange(detail.selectedOptions, raceFieldHelper)}
        name={CompleteSignUpFields.RACE}
      />
      {raceField.value.some((option) => option === SELF_IDENTIFY_OPTION) && (
        <Box margin={{ top: 's' }}>
          <InputFormField
            description={t('CompleteSignUp.enterRace')}
            placeholder={t('CompleteSignUp.racePlaceholder')}
            name={CompleteSignUpFields.RACE_ENTERED}
            type="text"
          />
        </Box>
      )}
    </Box>
  );
};

export const RaceFields = memo(RaceFieldsView);
