import { matchPath } from 'react-router-dom';
import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import { PROFILE_SLICE } from 'common/constants';
import { history } from 'common/utils/history';
import pageCatalog from 'pages/pageCatalog';
import { getMyProfile, updateMyProfile, deleteMyProfile } from 'services/asteroid';
import { logout } from 'store/auth';
import { addDefaultLoadingCases } from 'store/utils/loadingCases';
import { displaySuccessNotification, displayErrorNotification, clearAllNotifications } from 'store/notifications';
import { AsyncResource } from 'store/types';
import { AsteroidTypes } from 'types';
import { ProfileAction } from 'common/constants';
import i18n from 'i18n';
import { REDIRECT_URL } from 'common/components/RequiresAuth';

interface UpdatingState {
  isFetching: boolean;
}
export type ProfileState = AsyncResource<AsteroidTypes.ProfileData> & UpdatingState;

export const initialState: ProfileState = {
  error: null,
  isLoading: false,
  isFetching: false,
  item: null,
};

export const fetchProfile = createAsyncThunk(ProfileAction.FETCH_PROFILE, async () => {
  const profile = await getMyProfile();
  return profile;
});

export const updateProfile = createAsyncThunk<
  AsteroidTypes.ProfileData,
  { profileData: AsteroidTypes.UpdateMyProfileRequest }
>(ProfileAction.UPDATE_PROFILE, async ({ profileData }) => {
  const profile = await updateMyProfile(profileData);
  return profile;
});

export const deleteProfile = createAsyncThunk(ProfileAction.DELETE_PROFILE, async (arg, { dispatch }) => {
  await deleteMyProfile();
  await dispatch(logout());
});

export const handleUpdateProfile = createAsyncThunk<void, { profileData: AsteroidTypes.UpdateMyProfileRequest }>(
  ProfileAction.HANDLE_UPDATE_PROFIE,
  async ({ profileData }, { dispatch }) => {
    const pathName = history.location.pathname;
    const urlParams = new URL(location.href).searchParams;
    const redirectUrl = urlParams.get(REDIRECT_URL);
    dispatch(clearAllNotifications());
    return dispatch(updateProfile({ profileData }))
      .then(unwrapResult)
      .then(() => {
        dispatch(displaySuccessNotification({ content: i18n.t('ProfilePage.ChangeSavedSuccess') }));
        // redirect if update is made in completeSignUp page
        if (matchPath(pathName, pageCatalog.CompleteSignUp.routeProps)) {
          if (redirectUrl) {
            history.push(redirectUrl);
          } else {
            history.push(pageCatalog.StudentHome.getPath());
          }
        }
      })
      .catch((error) => {
        dispatch(displayErrorNotification({ content: error.message }));
      });
  }
);

export const checkCompletedSignUp = createAsyncThunk(
  ProfileAction.CHECK_COMPLETE_SIGN_UP,
  async (_arg, { dispatch }) => {
    return dispatch(fetchProfile())
      .then(unwrapResult)
      .then((profile) => {
        // Redirect to complete sign up if lastName (required field) is not in profile
        if (!profile?.lastName) {
          const queryParams = new URLSearchParams();
          queryParams.set(REDIRECT_URL, location.pathname);
          history.replace({
            pathname: pageCatalog.CompleteSignUp.getPath(),
            search: queryParams.toString(),
          });
        }
      })
      .catch((error) => {
        dispatch(displayErrorNotification({ content: error.message }));
      });
  }
);

const profileSlice = createSlice({
  name: PROFILE_SLICE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.item = payload;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.item = payload;
      })
      // When logout successful rest profile
      .addCase('auth/logout/fulfilled', () => initialState);
    // This handles setting state.isLoading and state.error for all actions
    // depending on status [fulfilled, pending, rejected]
    addDefaultLoadingCases(builder, PROFILE_SLICE);
  },
});

export default profileSlice.reducer;
