import { truncateNicknameIdSuffix } from 'store/utils/strings';
import { RootState } from 'store';
import { AsteroidTypes, DeepRacerLiteTypes } from 'types';

export const getNickName = (state: RootState): string | undefined =>
  truncateNicknameIdSuffix(state.profile.item?.nickName as string);

export const getNicknameWithIdSuffix = (state: RootState): string | undefined => state.profile.item?.nickName;

export const getJoinDate = (state: RootState): number | undefined => state.profile.item?.createdOn;

export const getProfileError = (state: RootState): string | null | undefined => state.profile.error;

export const getProfileIsLoading = (state: RootState): boolean | undefined => state.profile.isLoading;

export const getProfileIsFetching = (state: RootState): boolean | undefined => state.profile.isFetching;

export const getProfileData = (state: RootState): AsteroidTypes.ProfileData | undefined | null => state.profile.item;

export const getProfileSchoolName = (state: RootState): string | null | undefined =>
  state.profile.item?.schoolDetails?.schoolName;

export const getOptedInEsperanza = (state: RootState): boolean =>
  !!state.profile.item?.programs?.includes(AsteroidTypes.Program.ESPERANZA_2024);

export const getAcademicMajor = (state: RootState): string | null | undefined =>
  state.profile.item?.schoolDetails?.academicMajor;

export const getYearOfGraduation = (state: RootState): string | null | undefined =>
  state.profile.item?.schoolDetails?.yearOfGraduation;

export const getCountry = (state: RootState): DeepRacerLiteTypes.CountryCode | undefined =>
  state.profile.item?.country as DeepRacerLiteTypes.CountryCode;

export const getFirstName = (state: RootState): string | null | undefined => state.profile.item?.firstName;

export const getAvatarConfig = (state: RootState): AsteroidTypes.AvatarConfigData =>
  state.profile.item?.avatarConfig || ({} as AsteroidTypes.AvatarConfigData);
