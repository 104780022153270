import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { emptyResourceList, LeaderboardAction, LEADERBOARD_SLICE } from 'common/constants';
import { listLeaderboardEvaluationJobs } from 'services/deepRacerLite/leaderboardApi';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResourceList } from 'store/types';
import { addLoadingCasesWithNestedListState } from 'store/utils/loadingCases';
import { DeepRacerLiteTypes } from 'types/DeepRacerLiteTypes';

export interface LeaderboardState {
  error: string | null;
  isLoading: boolean;
  leaderboardEvaluationJobs: AsyncResourceList<DeepRacerLiteTypes.EvaluationJob>;
}

export const initialState: LeaderboardState = {
  error: null,
  isLoading: false,
  leaderboardEvaluationJobs: emptyResourceList,
};

export const fetchLeaderboardEvaluations = createAsyncThunk(
  LeaderboardAction.LIST_LEADERBOARD_EVALUATIONS,
  async (leaderboardArn: string, { dispatch }) => {
    try {
      const result = await listLeaderboardEvaluationJobs(leaderboardArn);
      return result.LeaderboardEvaluationJobs;
    } catch (error: any) {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      throw error;
    }
  }
);

export const leaderboardSlice = createSlice({
  name: LEADERBOARD_SLICE,
  initialState,
  reducers: {
    clearLeaderboardEvaluations: (state) => {
      state.leaderboardEvaluationJobs = emptyResourceList;
    },
  },
  extraReducers: (builder) => {
    addLoadingCasesWithNestedListState(
      builder,
      LeaderboardAction.LIST_LEADERBOARD_EVALUATIONS,
      LEADERBOARD_SLICE,
      'leaderboardEvaluationJobs'
    );
  },
});

export const { clearLeaderboardEvaluations } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
