import { Trans, useTranslation } from 'react-i18next';

import { Box, Link, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { EndSection } from '../../common/EndSection';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz6.19.1',
  title: 'Chapters.Lesson19.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.19.1.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson19.Wizard1.youtubeTitle',
      youtubeId: 'ANIRYsZZ4XI',
    },
    {
      id: 'wz6.19.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson19.Wizard1.content1',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz6.19.2',
  title: 'Chapters.Lesson19.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.19.2.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson19.Wizard2.youtubeTitle',
      youtubeId: 'pov0afxAvlo',
    },
    {
      id: 'wz6.19.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson19.Wizard2.content1',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz6.19.3',
  title: 'Chapters.Lesson19.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.19.3.100',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson19.Wizard2.youtubeTitle',
      youtubeId: 'IVZlh_PPHw0',
    },
    {
      id: 'wz6.19.3.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson19.Wizard3.content1',
    },
    {
      id: 'wz6.19.3.20',
      widgetType: WizardContentType.P_BODY_CUSTOM,
      children: () => (
        <Box variant="p">
          <Trans i18nKey="Chapters.Lesson19.Wizard3.content2">
            These reward functions we looked at in this section are just examples, and you should experiment and find
            one which works well for you. A list of all the different input parameters available to the reward function
            can be found in the AWS DeepRacer Developer Guide, with full explanations and examples:
            <Link
              external
              target="_blank"
              href="https://docs.aws.amazon.com/deepracer/latest/developerguide/deepracer-reward-function-input.html"
            ></Link>
          </Trans>
        </Box>
      ),
    },
    {
      id: 'wz6.19.3.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson19.Wizard3.content3',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz4',
  title: 'Chapters.Lesson19.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz6.19.2.100',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson19.Wizard2.youtubeTitle',
      youtubeId: 'CDUvW0YVqCc',
    },
    {
      id: 'wz6.19.2.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson19.Wizard4.content1',
    },
  ],
};
export const Lesson19 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz19',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz6.19.2.20',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson19.Wizard4.heading1',
      },
      {
        id: 'wz6.19.2.30',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson19.Wizard4.content2',
      },
      {
        id: 'wz6.19.2.40',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <EndSection
            level="Chapters.Lesson19.Wizard4.level"
            timeRequire="Chapters.Lesson19.Wizard4.time"
            prereq="Chapters.Lesson19.Wizard4.prerequisites"
            onClick={onClick}
          />
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
