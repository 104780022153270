import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, type match } from 'react-router-dom';
import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import type { Page } from 'types/page';
import pageCatalog, { DRLitePage, DynamicRouteId, type PageRouteParams } from 'pages/pageCatalog';
import { getWizardTitle } from 'utils/wizardPageUtils';
import { useGetModel } from 'store/model';
import { useGetLeaderboard } from 'store/leaderboard';
import { ChapterIdToPageId } from './constants';
import type { IntroToMLChapterId } from 'common/constants/learningModule/introToML';
import type { IntroToRLChapterId } from 'common/constants/learningModule/introToRL';

interface BreadcrumbsProps {
  pageRef: Page<DRLitePage>;
}

export const Breadcrumbs = ({ pageRef }: BreadcrumbsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<PageRouteParams[DRLitePage]>(pageRef.routeProps);

  const { data: leaderboardData } = useGetLeaderboard(
    decodeURIComponent((match as match<PageRouteParams[DRLitePage.LEADERBOARD]>)?.params?.leaderboardArn)
  );
  const { data: modelData } = useGetModel(
    decodeURIComponent((match as match<PageRouteParams[DRLitePage.MODEL]>)?.params?.modelArn)
  );

  let breadcrumbText = '';

  if (pageRef === pageCatalog.LearningModuleWizard) {
    const wizardId = (match as match<PageRouteParams[DRLitePage.LEARNING_MODULE_WIZARD]>)?.params?.wizardId as
      | IntroToMLChapterId
      | IntroToRLChapterId;
    breadcrumbText = t(`${getWizardTitle(wizardId)}`);
    pageRef.breadcrumbConfig.parentRoute = ChapterIdToPageId[wizardId];
  }

  const getBreadcrumbPropsFromRouteDefinition = <P extends DRLitePage>(
    page: Page<P>,
    params: PageRouteParams[P],
    breadcrumbText?: string
  ): BreadcrumbGroupProps.Item => {
    if (page.breadcrumbConfig?.text === DynamicRouteId.LEADERBOARD) {
      const leaderboardName = leaderboardData?.Leaderboard?.Name;
      breadcrumbText = leaderboardName || 'Leaderboard';
    }

    if (page.breadcrumbConfig?.text === DynamicRouteId.MODEL) {
      const modelName = modelData?.Model?.ModelName;
      breadcrumbText = modelName || 'Model';
    }

    if (page.breadcrumbConfig?.text === DynamicRouteId.SEASON_POINTS_LEADERBOARD) {
      breadcrumbText = '2024 season standings';
    }

    return {
      text: `${breadcrumbText || page.breadcrumbConfig.text}`,
      href: `${page.getPath(params)}`,
    };
  };

  const buildBreadcrumbs = <P extends DRLitePage>(
    pageRef: Page<P>,
    params: PageRouteParams[P],
    breadcrumbText?: string
  ) => {
    if (!pageRef) {
      return [];
    }

    let route = pageRef;

    const breadcrumbs: BreadcrumbGroupProps.Item[] = [
      getBreadcrumbPropsFromRouteDefinition(route, params, breadcrumbText),
    ];

    while (route?.breadcrumbConfig?.parentRoute) {
      route = pageCatalog[route.breadcrumbConfig.parentRoute] as Page<P>;
      if (route) {
        breadcrumbs.push(getBreadcrumbPropsFromRouteDefinition(route, params));
      }
    }

    return breadcrumbs.reverse();
  };

  const showBreadcrumbs = pageRef?.breadcrumbConfig?.showBreadcrumbs;

  return showBreadcrumbs ? (
    <BreadcrumbGroup
      data-analytics="navigation-BreadcrumbGroup"
      data-analytics-type="eventContext"
      items={buildBreadcrumbs(pageRef, match?.params, breadcrumbText)}
      ariaLabel="Breadcrumbs"
      onFollow={(e) => {
        e.preventDefault();
        history.push(e.detail.href);
      }}
    />
  ) : null;
};
