import React, { ErrorInfo } from 'react';

import ErrorView from '../ErrorView';

class ErrorBoundary extends React.Component<any, { hasError: boolean; error: any }> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo: ErrorInfo) {
    // ToDo: Log error crash when logging is available.
    // ToDo: Report app crash.
    // ToDo: Remove this console.log() Added to easy debugging.
    // eslint-disable-next-line no-console
    console.log('App Crash ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
