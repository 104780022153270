import { useTranslation } from 'react-i18next';
import { Box, Header, Container } from '@amzn/awsui-components-react';
import PieChart from '@amzn/awsui-components-react/polaris/pie-chart';
import { useGetAlias } from 'store/leaderboard';
import { minsToHours } from '../../utils';

import '../styles.css';

export const ModelTrainingQuota = (): JSX.Element => {
  const { t } = useTranslation();
  const { data: aliasDataItem, refetch: aliasRefetch, isLoading, isError } = useGetAlias();
  const computeMinutesUsed: any = aliasDataItem?.MultiUserProfile?.ComputeMinutesUsed || 0;
  // ToDo: Remove Total_Hours once API is ready
  const TOTAL_HOURS = 30;
  const maxComputeMinutes = aliasDataItem?.MultiUserProfile?.MaxTotalComputeMinutes || TOTAL_HOURS * 60;
  const computedMinsUsedToHour = minsToHours(computeMinutesUsed);
  const maxComputeMinsToHour = minsToHours(maxComputeMinutes);
  const hoursRemaining = Number((maxComputeMinsToHour - computedMinsUsedToHour).toFixed(2));
  const statusType = isError ? 'error' : isLoading ? 'loading' : 'finished';
  return (
    <Container
      data-analytics="modelTrainingQuota-Container"
      data-analytics-type="eventContext"
      className="student-container-height"
      header={<Header>{t('HomePage.ModelTrainingQuota.Heading')}</Header>}
    >
      <Box variant="p">{t('HomePage.ModelTrainingQuota.Message')}</Box>
      <Box variant="div">
        <PieChart
          data-analytics="quota-PieChart"
          data-analytics-type="eventDetail"
          data={[
            { title: t('HomePage.ModelTrainingQuota.PieChart.AvailableTrainingHours'), value: hoursRemaining },
            { title: t('HomePage.ModelTrainingQuota.PieChart.UsedTrainingHours'), value: computedMinsUsedToHour },
          ]}
          i18nStrings={{
            detailsValue: t('HomePage.ModelTrainingQuota.PieChart.Hours'),
            detailsPercentage: t('HomePage.ModelTrainingQuota.PieChart.Percentage'),
            filterLabel: t('HomePage.ModelTrainingQuota.PieChart.FilterLabel'),
            filterPlaceholder: t('HomePage.ModelTrainingQuota.PieChart.FilterPlaceholder'),
            filterSelectedAriaLabel: t('HomePage.ModelTrainingQuota.PieChart.Selected'),
            detailPopoverDismissAriaLabel: t('HomePage.ModelTrainingQuota.PieChart.Dismiss'),
            legendAriaLabel: t('HomePage.ModelTrainingQuota.PieChart.Legend'),
            chartAriaRoleDescription: t('HomePage.ModelTrainingQuota.PieChart.AriaDescription'),
            segmentAriaRoleDescription: t('HomePage.ModelTrainingQuota.PieChart.Segment'),
          }}
          innerMetricValue={`${hoursRemaining}` || '0'}
          innerMetricDescription={t('HomePage.ModelTrainingQuota.PieChart.Hours')}
          ariaDescription={t('HomePage.ModelTrainingQuota.PieChart.AriaDescription')}
          ariaLabel={t('HomePage.ModelTrainingQuota.PieChart.AriaDescription')}
          errorText={t('HomePage.ModelTrainingQuota.PieChart.ErrorText')}
          onRecoveryClick={() => aliasRefetch()}
          hideDescriptions
          hideFilter
          hideLegend
          loadingText={t('HomePage.ModelTrainingQuota.PieChart.LoadingChart')}
          recoveryText={t('HomePage.ModelTrainingQuota.PieChart.Retry')}
          statusType={statusType}
          size="medium"
          variant="donut"
          empty={
            <Box textAlign="center" color="inherit">
              <b>{t('HomePage.ModelTrainingQuota.PieChart.NoDataAvailable')}</b>
              <Box variant="p" color="inherit">
                {t('HomePage.ModelTrainingQuota.PieChart.ThereIsNoData')}
              </Box>
            </Box>
          }
          noMatch={
            <Box textAlign="center" color="inherit">
              <b>{t('HomePage.ModelTrainingQuota.PieChart.NoMatchingData')}</b>
              <Box variant="p" color="inherit">
                {t('HomePage.ModelTrainingQuota.PieChart.ThereIsNoMatchingData')}
              </Box>
            </Box>
          }
        />
      </Box>
    </Container>
  );
};
