import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';

export const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <SpaceBetween size="xxl">
      <Container
        data-analytics="contact-Container"
        data-analytics-type="eventContext"
        header={<Header variant="h2">{t('ContactUs.Title')}</Header>}
      >
        <Box>
          <SpaceBetween size="xl">
            <SpaceBetween size="xxs">
              <Box variant="p" padding="n" fontWeight="bold">
                {t('ContactUs.EmailSupport')}
              </Box>
              <Box variant="p">
                <Link
                  data-analytics="email-Link"
                  data-analytics-type="eventDetail"
                  href="mailto:awsdeepracer-help@amazon.com"
                >
                  {t('ContactUs.Email')}
                </Link>
              </Box>
            </SpaceBetween>
            <SpaceBetween size="xxs">
              <Box variant="p" padding="n" fontWeight="bold">
                {t('ContactUs.DRossKey')}
              </Box>
              <Box variant="p">
                <Link
                  data-analytics="attribution-Link"
                  data-analytics-type="eventDetail"
                  external
                  href="/oss_attribution.txt"
                >
                  {t('ContactUs.DROss')}
                </Link>
              </Box>
            </SpaceBetween>
          </SpaceBetween>
        </Box>
      </Container>
    </SpaceBetween>
  );
};
