import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TrophyCase from '@amzn/aws-deepracer-ui-components/TrophyCase';
import { Box, Container, Grid, Header, Link, SpaceBetween, Spinner, ColumnLayout } from '@amzn/awsui-components-react';
import {
  FullName,
  EsperanzaStatus,
  RacerName,
  SchoolName,
  MajorName,
  YearOfGraduation,
  Country,
  ProfileAvatar,
} from './Profilefield';
import { InfoLink } from 'common/components/HelpPanel';
import { ACHIEVEMENTS_HELP_PANEL_URL } from 'common/constants';
import pageCatalog, { DRLitePage } from 'pages/pageCatalog';
import { getProfileIsFetching } from 'store/profile';
import { useAppSelector } from 'store/utils/hooks';
import { useGetAlias, useListLeaderboards } from 'store/leaderboard';
import { isShareableAchievementsEnabled } from 'utils/featureFlags/currentFeatureFlags';

const Profile: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isProfileLoading = useAppSelector(getProfileIsFetching);
  const { data: aliasDataItem } = useGetAlias();
  const { data: leaderboardsData } = useListLeaderboards();

  const latestLeaderboard = useMemo(
    () => leaderboardsData?.Leaderboards?.sort((a, b) => b.LaunchTime - a.LaunchTime)?.[0],
    [leaderboardsData]
  );

  const achievementPopOverContent = (
    <Box>
      {t('ProfilePage.trophyCasePopOverContentPartOne')}
      {
        <Link
          onFollow={() =>
            history.push(
              pageCatalog[DRLitePage.ENTER_RACE].getPath({
                leaderboardArn: latestLeaderboard?.Arn ?? '',
              })
            )
          }
        >
          {latestLeaderboard?.Name}
        </Link>
      }
      {t('ProfilePage.trophyCasePopOverContentPartTwo')}
    </Box>
  );

  return (
    <SpaceBetween size="l">
      {isProfileLoading && <Spinner />}
      {!isProfileLoading && (
        <Grid gridDefinition={[{ colspan: { default: 6 } }, { colspan: { default: 6 } }, { colspan: { default: 12 } }]}>
          <Container header={<Header variant="h2">{t('ProfilePage.YourProfileInfo')}</Header>}>
            <ColumnLayout columns={1} variant="text-grid">
              <ProfileAvatar userData={aliasDataItem} />
              <FullName />
              <RacerName />
              <SchoolName />
              <MajorName />
              <YearOfGraduation />
              <Country userData={aliasDataItem} />
            </ColumnLayout>
          </Container>
          <TrophyCase
            infoLink={<InfoLink helpId={ACHIEVEMENTS_HELP_PANEL_URL} />}
            isShareFeatureEnabled={isShareableAchievementsEnabled()}
            popOverContent={achievementPopOverContent}
            popOverLabel={t('ProfilePage.trophyCasePopOverLabel', { leaderboardName: latestLeaderboard?.Name })}
            userCountryCode={aliasDataItem?.CompetitionCountryCode}
            rewardList={aliasDataItem?.Rewards ?? []}
          />
          <EsperanzaStatus />
        </Grid>
      )}
    </SpaceBetween>
  );
};

export default Profile;
