import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Header, SpaceBetween, Link, ColumnLayout } from '@amzn/awsui-components-react';
import { useAppSelector } from 'store/utils/hooks';
import { getUsername } from 'store/auth';
import { DeleteAccountModal, ChangePasswordModal } from './modal';

const Account: React.FC = () => {
  const { t } = useTranslation();
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const userEmail = useAppSelector(getUsername);
  const handleDeleteAccountClick = () => {
    setIsDeleteAccountModalOpen(true);
  };

  const handleChangePasswordClick = () => {
    setIsChangePasswordModalOpen(true);
  };

  return (
    <Box>
      <SpaceBetween size="xxl">
        <Container header={<Header variant="h2">{t('AccountPage.YourAccountInfo')}</Header>}>
          <Box>
            <SpaceBetween size="xl">
              <SpaceBetween size="xxs">
                <Box variant="p" padding="n" fontWeight="bold">
                  {t('AccountPage.YourEmail')}
                </Box>
                <Box variant="p">{userEmail}</Box>
              </SpaceBetween>
              <Box>
                <Box variant="p" padding="n" fontWeight="bold">
                  {t('AccountPage.YourPassword')}
                </Box>
                <ColumnLayout columns={4}>
                  <Box fontSize="body-m" margin={{ bottom: 'm' }}>
                    {'xxxxxxxxxxxxxxxxxxxxxxxxxxxxx'}
                  </Box>
                  <Link onFollow={handleChangePasswordClick}>{t('AccountPage.ChangePassword')}</Link>
                </ColumnLayout>
              </Box>
            </SpaceBetween>
            <Box margin={{ top: 'xxl' }}>
              <Button variant="normal" onClick={handleDeleteAccountClick}>
                {t('AccountPage.DeleteYourAccount')}
              </Button>
            </Box>
          </Box>
        </Container>
      </SpaceBetween>
      <ChangePasswordModal isOpen={isChangePasswordModalOpen} setIsOpen={setIsChangePasswordModalOpen} />
      <DeleteAccountModal isOpen={isDeleteAccountModalOpen} setIsOpen={setIsDeleteAccountModalOpen} />
    </Box>
  );
};

export default Account;
