import { Trans, useTranslation } from 'react-i18next';

import { Button, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz1.5',
  title: 'Chapters.Lesson5.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.5.1.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.stepsInML,
      imageAltText: 'Chapters.Lesson5.Wizard1.imageAltText',
      width: '70%',
      height: '50%',
    },
    {
      id: 'wz1.5.1.20',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson5.Wizard1.heading1',
    },
    {
      id: 'wz1.5.1.30',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson5.Wizard1.videoTitle',
      youtubeId: 'DzhnpkXrRV4',
    },
    {
      id: 'wz1.5.1.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard1.content1',
    },
    {
      id: 'wz1.5.1.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard1.content2',
    },
    {
      id: 'wz1.5.1.60',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz1.5.1.60-1',
          text: 'Chapters.Lesson5.Wizard1.list1',
        },
        {
          id: 'wz1.5.1.60-2',
          text: 'Chapters.Lesson5.Wizard1.list2',
        },
      ],
    },
    {
      id: 'wz1.5.1.70',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard1.content3',
    },
    {
      id: 'wz1.5.1.80',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz1.5.1.80-1',
          text: 'Chapters.Lesson5.Wizard1.list3',
        },
      ],
    },
    {
      id: 'wz1.5.1.90',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson5.Wizard1.heading2',
    },
    {
      id: 'wz1.5.1.100',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard1.content4',
    },
    {
      id: 'wz1.5.1.110',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz1.5.1.110-1',
          text: 'Chapters.Lesson5.Wizard1.list4',
        },
        {
          id: 'wz1.5.1.110-2',
          text: 'Chapters.Lesson5.Wizard1.list5',
        },
      ],
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz1.5',
  title: 'Chapters.Lesson5.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.5.2.00',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard2.content1',
    },
    {
      id: 'wz1.5.2.10',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.mlTask,
      imageAltText: 'Chapters.Lesson5.Wizard2.imageAltText1',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz1.5.2.20',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson5.Wizard2.heading1',
    },
    {
      id: 'wz1.5.2.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard2.content2',
    },
    {
      id: 'wz1.5.2.50',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.snowcone2,
      imageAltText: 'Chapters.Lesson5.Wizard2.imageAltText2',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz1.5.2.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard2.content3',
    },
    {
      id: 'wz1.5.2.70',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson5.Wizard2.heading2',
    },
    {
      id: 'wz1.5.2.80',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard2.content4',
    },
    {
      id: 'wz1.5.2.90',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.tree2,
      imageAltText: 'Chapters.Lesson5.Wizard2.imageAltText3',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz1.5.2.100',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz1.5.2.100-1',
          text: 'Chapters.Lesson5.Wizard2.list1',
        },
        {
          id: 'wz1.5.2.100-2',
          text: 'Chapters.Lesson5.Wizard2.list2',
        },
      ],
    },
    {
      id: 'wz1.5.2.110',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson5.Wizard2.heading3',
    },
    {
      id: 'wz1.5.2.120',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard2.content5',
    },
  ],
};
const wz3: WizardStepsProp = {
  id: 'wz1.5',
  title: 'Chapters.Lesson5.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.5.3.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard3.content1',
    },
    {
      id: 'wz1.5.3.20',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.mlTask,
      imageAltText: 'Chapters.Lesson5.Wizard3.imageAltText',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz1.5.3.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard3.content2',
    },
    {
      id: 'wz1.5.3.40',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz1.5.3.40-1',
          text: 'Chapters.Lesson5.Wizard3.list1',
        },
        {
          id: 'wz1.5.3.40-2',
          text: 'Chapters.Lesson5.Wizard3.list2',
        },
      ],
    },
    {
      id: 'wz1.5.3.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard3.content3',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz1.5',
  title: 'Chapters.Lesson5.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'summaryContent1.wz1.5.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson5.Wizard4.content1',
    },
    {
      id: 'summaryContent1.wz1.5.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'summaryContent1.wz1.5.20-1',
          text: 'Chapters.Lesson5.Wizard4.list1',
        },
        {
          id: 'summaryContent1.wz1.5.20-2',
          text: 'Chapters.Lesson5.Wizard4.list2',
        },
        {
          id: 'summaryContent1.wz1.5.20-3',
          text: 'Chapters.Lesson5.Wizard4.list3',
        },
        {
          id: 'summaryContent1.wz1.5.20-4',
          text: 'Chapters.Lesson5.Wizard4.list4',
        },
        {
          id: 'summaryContent1.wz1.5.20-5',
          text: 'Chapters.Lesson5.Wizard4.list5',
        },
        {
          id: 'summaryContent1.wz1.5.20-6',
          text: 'Chapters.Lesson5.Wizard4.list6',
        },
      ],
    },
  ],
};

export const Lesson5 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz1.5',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'summaryContent2.wz1.5.002',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson5.Wizard4.heading1',
      },
      {
        id: 'summaryContent2.wz1.5.003',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson5.Wizard4.content2',
      },
      {
        id: 'summaryContent2.wz1.5.1.003',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <Button variant="normal" onClick={onClick}>
            <Trans i18nKey="WizardI18nStrings.StartChapter"></Trans>
          </Button>
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...(summaryContent2 as any)} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
