import { Formik, Form } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box, ColumnLayout, Header, SpaceBetween, Link } from '@amzn/awsui-components-react';
import { CancelButton, SubmitButton } from 'common/components/FormButtons';
import { MajorFields } from 'common/components/CompleteSignUp';
import Modal from 'common/components/Modal';
import { KeyValuePair } from 'common/components/KeyValuePair';
import { CompleteSignUpFields, OTHER_VAL } from 'common/constants';
import { getAcademicMajor, getProfileIsLoading, handleUpdateProfile } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';

export const MajorName = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const majorName = useAppSelector(getAcademicMajor);
  const isLoading = useAppSelector(getProfileIsLoading);

  const onDismiss = () => {
    setVisible(false);
  };

  const handleSubmit = (data) => {
    let major = data[CompleteSignUpFields.MAJOR_ENTERED];
    if (data[CompleteSignUpFields.MAJOR].value !== OTHER_VAL) {
      major = data[CompleteSignUpFields.MAJOR].value;
    }
    dispatch(handleUpdateProfile({ profileData: { schoolDetails: { academicMajor: major } } })).then(onDismiss);
  };
  const initialValues = {
    [CompleteSignUpFields.MAJOR]: '',
  };
  const validationSchema = Yup.object().shape({
    [CompleteSignUpFields.MAJOR]: Yup.object().required(t('required')),
    [CompleteSignUpFields.MAJOR_ENTERED]: Yup.string().when([CompleteSignUpFields.MAJOR], {
      is: (majorSelected) => majorSelected?.value === OTHER_VAL,
      then: () => Yup.string().required(t('required')).max(150, t('CompleteSignUp.invalidMajorEntered')),
    }),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          header={<Header description={t('ProfilePage.WhatIsYourNewMajor')}>{t('ProfilePage.EditMajor')}</Header>}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <CancelButton onClick={onDismiss}>{t('cancel')}</CancelButton>
                <SubmitButton variant="primary" loading={isLoading}>
                  {t('save')}
                </SubmitButton>
              </SpaceBetween>
            </Box>
          }
        >
          <MajorFields />
        </Modal>
        <ColumnLayout columns={2}>
          <KeyValuePair label={t('ProfilePage.NameOfMajor')}>{majorName || t('emptyLabel')}</KeyValuePair>
          <Link onFollow={() => setVisible(true)}>{t('ProfilePage.EditMajor')}</Link>
        </ColumnLayout>
      </Form>
    </Formik>
  );
};
