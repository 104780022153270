import { MAX_RESULTS } from 'common/constants';
import { getIdToken } from 'store/utils/authUtils';
import { DeepRacerLiteService, DeepRacerLiteTypes } from '../../types/DeepRacerLiteTypes';
import ServiceProvider from '../ServiceProvider';
import { getObject, listAllItems } from './common';

/**
 * Get the lists all the Leaderboard submissions
 * @param
 * @returns NextToken, LeaderboardSubmissionList
 */
export const listLeaderboardSubmissions = async (
  leaderboardArn: string,
  getTopRacers?: boolean
): Promise<DeepRacerLiteTypes.ListLeaderboardSubmissionsResponse> => {
  try {
    const listLeaderboardSubmissionsRequest: DeepRacerLiteTypes.ListLeaderboardSubmissionsRequest = {
      MaxResults: MAX_RESULTS,
      LeaderboardArn: leaderboardArn,
      GetTopRacer: getTopRacers,
    };
    const listLeaderboardSubmissionsResponse: DeepRacerLiteTypes.ListLeaderboardSubmissionsResponse = {
      LeaderboardSubmissions: [],
      LeagueTopRacers: {},
    };
    if (getTopRacers) {
      return getObject(
        'listLeaderboardSubmissions',
        listLeaderboardSubmissionsRequest,
        listLeaderboardSubmissionsResponse,
        'LeagueTopRacers'
      );
    }
    return listAllItems(
      'listLeaderboardSubmissions',
      listLeaderboardSubmissionsRequest,
      listLeaderboardSubmissionsResponse,
      'LeaderboardSubmissions'
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the latest leaderboard submission with arn
 * @param LeaderboardArn
 * @returns LeaderboardSubmission
 */
export const getLatestUserSubmission = async (
  leaderboardArn: string
): Promise<DeepRacerLiteTypes.GetLatestUserSubmissionResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getLatestUserSubmissionRequest: DeepRacerLiteTypes.GetLatestUserSubmissionRequest = {
      LeaderboardArn: leaderboardArn,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getLatestUserSubmission(getLatestUserSubmissionRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the ranked leaderboard submission with arn
 * @param LeaderboardArn
 * @returns LeaderboardSubmission
 */
export const getRankedUserSubmission = async (
  leaderboardArn: string
): Promise<DeepRacerLiteTypes.GetRankedUserSubmissionResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getRankedUserSubmissionRequest: DeepRacerLiteTypes.GetRankedUserSubmissionRequest = {
      LeaderboardArn: leaderboardArn,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getRankedUserSubmission(getRankedUserSubmissionRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Create new leaderboard submission
 * @param CreateLeaderboardSubmissionRequest
 * @returns
 */
export const createLeaderboardSubmission = async (
  leaderboardArn: string,
  modelArn: string,
  termsAccepted: boolean,
  competitionCountryCode: string
): Promise<DeepRacerLiteTypes.SubmitOutput> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const createLeaderboardSubmissionRequest: DeepRacerLiteTypes.SubmitInput = {
      LeaderboardArn: leaderboardArn,
      ModelArn: modelArn,
      TermsAccepted: termsAccepted,
      CompetitionCountryCode: competitionCountryCode as DeepRacerLiteTypes.CountryCode,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.submit(createLeaderboardSubmissionRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};
