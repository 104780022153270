import { MAX_RESULTS } from 'common/constants';
import { getIdToken } from 'store/utils/authUtils';
import { DeepRacerLiteService, DeepRacerLiteTypes } from '../../types/DeepRacerLiteTypes';
import ServiceProvider from '../ServiceProvider';
import { listAllItems } from './common';

/**
 * Get the lists all the training jobs
 * @param modelArn
 * @returns NextToken, TrainingJobList
 */
export const listTrainingJobs = async (modelArn: string): Promise<DeepRacerLiteTypes.ListTrainingJobsResponse> => {
  try {
    const listTrainingJobsRequest: DeepRacerLiteTypes.ListTrainingJobsRequest = {
      ModelArn: modelArn,
      MaxResults: MAX_RESULTS,
    };
    const listTrainingJobsResponse: DeepRacerLiteTypes.ListTrainingJobsResponse = {
      TrainingJobs: [],
    };
    return listAllItems('listTrainingJobs', listTrainingJobsRequest, listTrainingJobsResponse, 'TrainingJobs');
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the trainingJob
 * @param trainingJobArn
 * @returns Track
 */
export const getTrainingJob = async (trainingJobArn: string): Promise<DeepRacerLiteTypes.GetTrainingJobResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getTrainingJobRequest: DeepRacerLiteTypes.GetTrainingJobRequest = {
      TrainingJobArn: trainingJobArn,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getTrainingJob(getTrainingJobRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Stop the trainingJob
 * @param modelArn
 * @returns Track
 */
export const stopTrainingJob = async (
  modelArn: string
): Promise<DeepRacerLiteTypes.StopTrainingReinforcementLearningModelResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const stopTrainingReinforcementLearningModelRequest: DeepRacerLiteTypes.StopTrainingReinforcementLearningModelRequest =
      {
        ModelArn: modelArn,
      };
    const idToken = await getIdToken();
    return await deepRacerLiteService.stopTrainingReinforcementLearningModel(
      stopTrainingReinforcementLearningModelRequest,
      idToken
    );
  } catch (e) {
    return Promise.reject(e);
  }
};
