// Home Page
export const HOME_NON_ESPERANZA = 'deepracer/latest/console/hp-home.html';

export const HOME_ESPERANZA = 'deepracer/latest/console/hp-home.html';

// Quiz Page
export const QUIZ_PAGE_HELP_PANEL_URL = 'deepracer/latest/console/hp-learn-scholarship-quiz';

// Profile Page
export const PROFILE_PAGE_HELP_PANEL_URL = 'deepracer/latest/console/hp-account-profile.html';

export const ACHIEVEMENTS_HELP_PANEL_URL = 'deepracer/latest/console/hp-achievements.html';

// Account Page
export const ACCOUNT_PAGE_HELP_PANEL_URL = 'deepracer/latest/console/hp-account-change.html';

// Complete Signup Page
export const COMPLETE_SIGNUP_PAGE_HELP_PANEL_URL = 'deepracer/latest/console/hp-account.html';

// League & Leaderboard page
export const STUDENT_LEAGUE_HELP_PANEL_URL = 'deepracer/latest/console/hp-welcome.html';

export const SEASON_POINTS_HELP_PANEL_URL = 'deepracer/latest/console/hp-season-standings.html';

export const ENTER_RACE_HELP_PANEL_URL = 'deepracer/latest/console/hp-enter-race.html';

export const LEADERBOARD_HELP_PANEL_URL = 'deepracer/latest/console/hp-league.html';

export const GETTING_STARTED_HELP_PANEL_URL = 'deepracer/latest/console/hp-getting-started.html';

// Commmunity Races Landing & Leaderboard page
export const COMMUNITY_RACES_LANDING_HELP_PANEL_URL = 'deepracer/latest/console/hp-community-races.html';

export const COMMUNITY_LEADERBOARD_HELP_PANEL_URL = 'deepracer/latest/console/hp-community-race-name.html';

// create / clone model wizard flow infoLinks

export const MODEL_CREATE_HELP_PANEL_URL = 'deepracer/latest/console/hp-model-training-step-1.html';

export const MODEL_CLONE_HELP_PANEL_URL = 'deepracer/latest/console/hp-model-training-customize.html';

export const MODEL_WIZARD_OVERVIEW = 'deepracer/latest/console/hp-model-training.html';

export const MODEL_WIZARD_NAME = 'deepracer/latest/console/hp-model-training.html';

export const MODEL_WIZARD_TRACK = 'deepracer/latest/console/hp-model-training.html';

export const MODEL_WIZARD_ALGO = 'deepracer/latest/console/hp-model-training.html';

export const MODEL_WIZARD_REWARD = 'deepracer/latest/console/hp-model-training-customize.html';

export const MODEL_WIZARD_REWARD_CLONE = 'deepracer/latest/console/hp-model-training-customize-clone.html';

export const MODEL_WIZARD_DURATION = 'deepracer/latest/console/hp-model-training.html';

// models list page info link
export const MODELS_LIST_PAGE_LINK = 'deepracer/latest/console/hp-model.html';

export const MODEL_DETAILS_HELP_PANEL_URL = 'deepracer/latest/console/hp-model-details.html';

export const TRAINING_HELP_PANEL_URL = 'deepracer/latest/console/hp-model-sim-app.html';

// learn modules info link
export const LEARN_OVERVIEW_HELP_PANEL_URL = 'deepracer/latest/console/hp-learn.html';

// learning module lesson 1
export const LEARN_LESSON1_HELP_PANEL_URL = 'deepracer/latest/console/hp-learn-foundations.html';

// learning module lesson 2
export const LEARN_LESSON2_HELP_PANEL_URL = 'deepracer/latest/console/hp-learn-reinforcement-deepracer.html';

// walkup experience info link
export const WALKUP_HELP_PANEL_URL = 'deepracer/latest/console/hp-walkup.html';

// External Links
export const DEEP_RACER_CONSOLE_URL = 'https://aws.amazon.com/deepracer';

export const AWSUIHelpPanelUrl = 'https://cdn.assets.as2.amazonaws.com/AWS-UI-Widget-HelpPanel-Loader.js?version=1.x';

export const DEV_DOC_HELP_PANEL_ENDPOINT = 'https://doc-help-panel-test.aka.corp.amazon.com';

export const PROD_DOC_HELP_PANEL_ENDPOINT = 'https://us.help-panel.docs.aws.a2z.com';

export const YOUTUBE_NO_COOKIE_URL = 'https://www.youtube-nocookie.com/embed/';

export const DISCORD_COMMUNITY = 'https://discord.com/invite/G72rNQmJRg';

export const SLACK_COMMUNITY =
  'https://aws-ml-community.slack.com/join/shared_invite/zt-ovapd436-0_0Hfv9z8i7IvqjFomw59w#/shared-invite/email';

export const PROD_DEEPRACER_EDUCATOR =
  'https://docs.aws.amazon.com/deepracer/latest/developerguide/deepracer-educator.html';

export const ALPHA_DEEPRACER_EDUCATOR =
  'https://alpha-docs-aws.corp.amazon.com/deepracer/latest/developerguide/deepracer-educator.html';

export const ALPHA_JOIN_COMMUNITY_RACE =
  'https://alpha-docs-aws.corp.amazon.com/deepracer/latest/developerguide/deepracer-join-community-race.html';

export const PROD_JOIN_COMMUNITY_RACE =
  'https://docs.aws.amazon.com/deepracer/latest/developerguide/deepracer-join-community-race.html';

export const ALPHA_SCHOLARSHIP =
  'https://alpha-docs-aws.corp.amazon.com/deepracer/latest/student-userguide/what-is-scholarship.html';

export const PROD_SCHOLARSHIP =
  'https://docs.aws.amazon.com/deepracer/latest/student-userguide/what-is-scholarship.html';

export const PROD_WHAT_IS_DEEPRACER_STUDENT_LEAGUE =
  'https://docs.aws.amazon.com/deepracer/latest/student-userguide/what-is-deepracer-student-league.html';

export const ALPHA_WHAT_IS_DEEPRACER_STUDENT_LEAGUE =
  'https://alpha-docs-aws.corp.amazon.com/deepracer/latest/student-userguide/what-is-deepracer-student-league.html';

export const PROD_WHAT_IS_UDACITY =
  'https://docs.aws.amazon.com/deepracer/latest/student-userguide/what-is-scholarship.html#what-is-udacity';

export const ALPHA_WHAT_IS_UDACITY =
  'https://alpha-docs-aws.corp.amazon.com/deepracer/latest/student-userguide/what-is-scholarship.html#what-is-udacity';

export const ALPHA_ACCOUNT_DOC =
  'https://alpha-docs-aws.corp.amazon.com/deepracer/latest/student-userguide/setting-up.html';

export const PROD_ACCOUNT_DOC = 'https://docs.aws.amazon.com/deepracer/latest/student-userguide/setting-up.html';

export const PRIVACY_URL = 'https://aws.amazon.com/privacy/?nc1=f_pr';

export const CUSTOMER_AGREEMENT = 'https://aws.amazon.com/agreement/';

export const SERVICE_TERM = 'https://aws.amazon.com/service-terms/';

export const USER_POLICY = 'https://aws.amazon.com/aup/';

export const TERMS_AND_CONDITIONS = 'https://d1.awsstatic.com/deepracer/AWS_AI_ML_Scholarship_Official_Terms.pdf';

export const UDACITY_ESPERANZA_CONSOLE =
  'https://emc.udacity.com/c/aws-esperanza/catalog?token=MTYzOSxCaDhkVFY0RmdEZnhzcUVJ';

export const AI_ML_SCHOLARSHIP_LANDING_DOCS = 'https://aws.amazon.com/machine-learning/scholarship/';

export const DEEP_RACER_LEAGUE_RULES = 'https://aws.amazon.com/deepracer/student/#rules';
