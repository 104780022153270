import { useTranslation } from 'react-i18next';

import { SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { EndSection } from '../../common/EndSection';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz1.3.1',
  title: 'Chapters.Lesson3.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.3.1.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard1.content1',
    },
    {
      id: 'wz1.3.1.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz1.3.1.20-1',
          text: 'Chapters.Lesson3.Wizard1.list1',
        },
        {
          id: 'wz1.3.1.20-2',
          text: 'Chapters.Lesson3.Wizard1.list2',
        },
        {
          id: 'wz1.3.1.20-3',
          text: 'Chapters.Lesson3.Wizard1.list3',
        },
      ],
    },
    {
      id: 'wz1.3.1.30',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson3.Wizard1.videoTitle',
      youtubeId: 'w0R0VnImVK8',
    },
    {
      id: 'wz1.3.1.50',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson3.Wizard1.headingTitle',
    },
    {
      id: 'wz1.3.1.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard1.content2',
    },
    {
      id: 'wz1.3.1.40',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.clay,
      imageAltText: 'Chapters.Lesson3.Wizard1.imageAltText',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz1.3.1.70',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ol',
      bulletList: [
        {
          id: 'wz1.3.1.70-1',
          text: 'Chapters.Lesson3.Wizard1.list4',
        },
        {
          id: 'wz1.3.1.70-2',
          text: 'Chapters.Lesson3.Wizard1.list5',
        },
        {
          id: 'wz1.3.1.70-3',
          text: 'Chapters.Lesson3.Wizard1.list6',
        },
      ],
    },
    {
      id: 'wz1.3.1.80',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard1.content3',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz1.3.2',
  title: 'Chapters.Lesson3.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.3.2.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard2.content1',
    },
    {
      id: 'wz1.3.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard2.content2',
    },
    {
      id: 'wz1.3.2.30',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson3.Wizard2.heading1',
    },
    {
      id: 'wz1.3.2.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard2.content3',
    },
    {
      id: 'wz1.3.2.50',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.snowcone,
      imageAltText: 'Chapters.Lesson3.Wizard2.imageAltText1',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz1.3.2.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard2.content4',
    },
    {
      id: 'wz1.3.2.70',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.tution,
      imageAltText: 'Chapters.Lesson3.Wizard2.imageAltText2',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz1.3.2.80',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard2.content5',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz1.3.3',
  title: 'Chapters.Lesson3.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.3.3.10',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson3.Wizard3.heading1',
    },
    {
      id: 'wz1.3.3.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard3.content1',
    },
    {
      id: 'wz1.3.3.30',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson3.Wizard3.heading2',
    },
    {
      id: 'wz1.3.3.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson3.Wizard3.content2',
    },
    {
      id: 'wz1.3.3.50',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz1.3.3.50-1',
          text: 'Chapters.Lesson3.Wizard3.list1',
        },
        {
          id: 'wz1.3.3.50-2',
          text: 'Chapters.Lesson3.Wizard3.list2',
        },
        {
          id: 'wz1.3.3.50-3',
          text: 'Chapters.Lesson3.Wizard3.list3',
        },
      ],
    },
    {
      id: 'wz1.3.3.60',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.clay2,
      imageAltText: 'Chapters.Lesson3.Wizard3.imageAltText',
      width: '30%',
      height: '30%',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz4',
  title: 'Chapters.Lesson3.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'summaryContent1.wz4.001',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson2.Wizard4.content1',
    },
    {
      id: 'summaryContent1.wz4.002',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'summaryContent1.wz4.002-1',
          text: 'Chapters.Lesson3.Wizard4.list1',
        },
        {
          id: 'summaryContent1.wz4.002-2',
          text: 'Chapters.Lesson3.Wizard4.list2',
        },
        {
          id: 'summaryContent1.wz4.002-3',
          text: 'Chapters.Lesson3.Wizard4.list3',
        },
      ],
    },
  ],
};

export const Lesson3 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz4',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'summaryContent2.wz4.002',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson3.Wizard4.heading1',
      },
      {
        id: 'summaryContent2.wz4.003',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson3.Wizard4.content2',
      },
      {
        id: 'summaryContentWz20005',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <EndSection
            level="Chapters.Lesson3.Wizard4.level"
            timeRequire="Chapters.Lesson3.Wizard4.time"
            prereq="Chapters.Lesson3.Wizard4.prerequisites"
            onClick={onClick}
          />
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
