import { RootState } from 'store';

export const getCodeDeliveryDetails = (state: RootState) => state.auth.codeDeliveryDetails;
export const getForgotPasswordEmail = (state: RootState) => state.auth.forgotPasswordEmail;
export const getIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getRegisterAccountEmail = (state: RootState) => state.auth.registerAccountEmail;
export const getUsername = (state: RootState) => state.auth.username;
export const getAuthError = (state: RootState) => state.auth.error;
export const getAuthIsLoading = (state: RootState) => state.auth.isLoading;
export const getIsResendingCode = (state: RootState) => state.auth.isResendingCode;
