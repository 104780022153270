import { Trans, useTranslation } from 'react-i18next';
import { Header, Link } from '@amzn/awsui-components-react';
import { InfoLink } from 'common/components/HelpPanel';
import { LearningModulePageProps } from 'types/LearningModuleTypes';

export const LearningModulePageHeader = ({
  description,
  helpId,
  title,
  udacityUrl,
}: LearningModulePageProps): JSX.Element => {
  const { t } = useTranslation();
  const descriptionComp = udacityUrl ? (
    <Trans
      components={[
        <Link data-analytics="udacity-Link" data-analytics-type="eventContext" href={udacityUrl} external />,
      ]}
    >
      {description}
    </Trans>
  ) : (
    <Trans>{description}</Trans>
  );
  return (
    <Header variant="h1" description={descriptionComp} info={helpId && <InfoLink helpId={helpId} />}>
      {t(title)}
    </Header>
  );
};
