/**
 * Identifiers for data stored in cookies, local storage, or session storage.
 *
 * New storage items must get approval from AWS Legal before being used in production.
 * At the time of writing this, the approval process takes 3 - 14 days, sometimes faster.
 * @see https://w.amazon.com/bin/view/CookieJar/Onboarding
 */
export enum StorageKey {
  BANNERS = 'deepracer-student-banners',
  MODALS = 'deepracer-student-modals',
  USER_PREFERENCES = 'deepracer-student-user-preferences',
}
