import { useEffect } from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { LearningModuleHomeData } from 'common/constants/LearningModuleHomeCardData';
import { LearningModuleHomeCard } from 'common/components/LearningModuleHomeCard';
import { fetchLmsTrackerModules, getLmsTrackerModules, getLmsTrackerModulesIsLoading } from 'store/lmsTracker';
import { useAppSelector, useAppDispatch } from 'store/utils/hooks';

const LearnOverview = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const modules = useAppSelector(getLmsTrackerModules) as Record<string, any>;
  const isLoading = useAppSelector(getLmsTrackerModulesIsLoading);

  useEffect(() => {
    dispatch(fetchLmsTrackerModules());
  }, [dispatch]);

  return (
    <ColumnLayout columns={2}>
      {LearningModuleHomeData.map((cardProps) => (
        <LearningModuleHomeCard
          key={cardProps.id}
          {...cardProps}
          moduleData={modules[cardProps.id]}
          isLoading={isLoading}
        />
      ))}
    </ColumnLayout>
  );
};

export default LearnOverview;
