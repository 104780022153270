import { useTranslation } from 'react-i18next';
import { Header } from '@amzn/awsui-components-react';

export const PageHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Header variant="h1" description={t('ContactUs.Desc')}>
      {t('ContactUs.ContactUs')}
    </Header>
  );
};
