import { StorageUtil as StorageUtilBase } from '@amzn/aws-deepracer-ui-components/utils/storage';
import { cookieRegistry } from './cookieRegistry';
import type { StorageItems } from './types';
import { StorageKey } from './constants';

export const StorageUtil = new StorageUtilBase<typeof StorageKey, StorageItems>({
  cookieRegistry,
  isAWSConsoleApp: false,
  storageKeys: StorageKey,
});
