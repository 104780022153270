export const MAJOR_LIST = [
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Not applicable - I am an educator',
    value: 'N/A - not applicable',
  },
  {
    label: 'Accounting',
    value: 'Accounting',
  },
  {
    label: 'Accounting Technician',
    value: 'Accounting Technician',
  },
  {
    label: 'Actuarial Science',
    value: 'Actuarial Science',
  },
  {
    label: 'Adult Development & Aging/Gerontology',
    value: 'Adult Development & Aging/Gerontology',
  },
  {
    label: 'Advertising',
    value: 'Advertising',
  },
  {
    label: 'Aeronautical/Aerospace Engineering Technologies',
    value: 'Aeronautical/Aerospace Engineering Technologies',
  },
  {
    label: 'Aerospace/Aeronautical Engineering',
    value: 'Aerospace/Aeronautical Engineering',
  },
  {
    label: 'African American Studies',
    value: 'African American Studies',
  },
  {
    label: 'Agribusiness Operations',
    value: 'Agribusiness Operations',
  },
  {
    label: 'Agricultural Business & Management',
    value: 'Agricultural Business & Management',
  },
  {
    label: 'Agricultural Economics',
    value: 'Agricultural Economics',
  },
  {
    label: 'Agricultural Education',
    value: 'Agricultural Education',
  },
  {
    label: 'Agricultural Mechanization',
    value: 'Agricultural Mechanization',
  },
  {
    label: 'Agricultural Production',
    value: 'Agricultural Production',
  },
  {
    label: 'Agricultural/Bioengineering',
    value: 'Agricultural/Bioengineering',
  },
  {
    label: 'Agriculture, General',
    value: 'Agriculture, General',
  },
  {
    label: 'Agronomy & Crop Science',
    value: 'Agronomy & Crop Science',
  },
  {
    label: 'Aircraft Mechanics/Technology',
    value: 'Aircraft Mechanics/Technology',
  },
  {
    label: 'Aircraft Piloting & Navigation',
    value: 'Aircraft Piloting & Navigation',
  },
  {
    label: 'Alcohol/Drug Abuse Counseling',
    value: 'Alcohol/Drug Abuse Counseling',
  },
  {
    label: 'American Indian/Native American Studies',
    value: 'American Indian/Native American Studies',
  },
  {
    label: 'American/English Literature',
    value: 'American/English Literature',
  },
  {
    label: 'Animal Sciences',
    value: 'Animal Sciences',
  },
  {
    label: 'Anthropology',
    value: 'Anthropology',
  },
  {
    label: 'Applied Mathematics',
    value: 'Applied Mathematics',
  },
  {
    label: 'Architectural Drafting/CAD Technology',
    value: 'Architectural Drafting/CAD Technology',
  },
  {
    label: 'Architectural Engineering',
    value: 'Architectural Engineering',
  },
  {
    label: 'Architectural Engineering Technology',
    value: 'Architectural Engineering Technology',
  },
  {
    label: 'Architectural Environmental Design',
    value: 'Architectural Environmental Design',
  },
  {
    label: 'Architecture, General',
    value: 'Architecture, General',
  },
  {
    label: 'Area Studies, General',
    value: 'Area Studies, General',
  },
  {
    label: 'Art Education',
    value: 'Art Education',
  },
  {
    label: 'Art History, Criticism & Conservation',
    value: 'Art History, Criticism & Conservation',
  },
  {
    label: 'Art, General',
    value: 'Art, General',
  },
  {
    label: 'Asian Area Studies',
    value: 'Asian Area Studies',
  },
  {
    label: 'Asian Languages & Literatures',
    value: 'Asian Languages & Literatures',
  },
  {
    label: 'Astronomy',
    value: 'Astronomy',
  },
  {
    label: 'Athletic Training',
    value: 'Athletic Training',
  },
  {
    label: 'Atmospheric Sciences & Meteorology',
    value: 'Atmospheric Sciences & Meteorology',
  },
  {
    label: 'Autobody Repair/Technology',
    value: 'Autobody Repair/Technology',
  },
  {
    label: 'Automotive Engineering Technology',
    value: 'Automotive Engineering Technology',
  },
  {
    label: 'Automotive Mechanics/Technology',
    value: 'Automotive Mechanics/Technology',
  },
  {
    label: 'Aviation & Airway Science, General',
    value: 'Aviation & Airway Science, General',
  },
  {
    label: 'Aviation Management & Operations',
    value: 'Aviation Management & Operations',
  },
  {
    label: 'Avionics Technology',
    value: 'Avionics Technology',
  },
  {
    label: 'Banking & Financial Support Services',
    value: 'Banking & Financial Support Services',
  },
  {
    label: 'Bible/Biblical Studies',
    value: 'Bible/Biblical Studies',
  },
  {
    label: 'Biochemistry & Biophysics',
    value: 'Biochemistry & Biophysics',
  },
  {
    label: 'Biology, General',
    value: 'Biology, General',
  },
  {
    label: 'Biomedical Engineering',
    value: 'Biomedical Engineering',
  },
  {
    label: 'Business Administration & Management, General',
    value: 'Business Administration & Management, General',
  },
  {
    label: 'Business Education',
    value: 'Business Education',
  },
  {
    label: 'Business/Management Quantitative Methods, General',
    value: 'Business/Management Quantitative Methods, General',
  },
  {
    label: 'Business/Managerial Economics',
    value: 'Business/Managerial Economics',
  },
  {
    label: 'Career & Technical Education',
    value: 'Career & Technical Education',
  },
  {
    label: 'Cell/Cellular Biology',
    value: 'Cell/Cellular Biology',
  },
  {
    label: 'Chemical Engineering',
    value: 'Chemical Engineering',
  },
  {
    label: 'Chemistry',
    value: 'Chemistry',
  },
  {
    label: 'Child Care Services Management',
    value: 'Child Care Services Management',
  },
  {
    label: 'Child Development',
    value: 'Child Development',
  },
  {
    label: 'Chiropractic (Pre-Chiropractic)',
    value: 'Chiropractic (Pre-Chiropractic)',
  },
  {
    label: 'Cinema/Film',
    value: 'Cinema/Film',
  },
  {
    label: 'Cinematography/Film/Vide Production',
    value: 'Cinematography/Film/Vide Production',
  },
  {
    label: 'City/Urban/Regional Planning',
    value: 'City/Urban/Regional Planning',
  },
  {
    label: 'Civil Engineering',
    value: 'Civil Engineering',
  },
  {
    label: 'Civil Engineering Technology',
    value: 'Civil Engineering Technology',
  },
  {
    label: 'Classical/Ancient Languages & Literatures',
    value: 'Classical/Ancient Languages & Literatures',
  },
  {
    label: 'Communication Disorder Services',
    value: 'Communication Disorder Services',
  },
  {
    label: 'Communications Technology, General',
    value: 'Communications Technology, General',
  },
  {
    label: 'Communications, General',
    value: 'Communications, General',
  },
  {
    label: 'Community Organization & Advocacy',
    value: 'Community Organization & Advocacy',
  },
  {
    label: 'Comparative Literature',
    value: 'Comparative Literature',
  },
  {
    label: 'Computer & Information Sciences, General',
    value: 'Computer & Information Sciences, General',
  },
  {
    label: 'Computer Engineering',
    value: 'Computer Engineering',
  },
  {
    label: 'Computer Engineering Technology',
    value: 'Computer Engineering Technology',
  },
  {
    label: 'Computer Networking/Telecommunications',
    value: 'Computer Networking/Telecommunications',
  },
  {
    label: 'Computer Science & Programming',
    value: 'Computer Science & Programming',
  },
  {
    label: 'Computer Software & Media Applications',
    value: 'Computer Software & Media Applications',
  },
  {
    label: 'Computer System Administration',
    value: 'Computer System Administration',
  },
  {
    label: 'Construction Engineering/Management',
    value: 'Construction Engineering/Management',
  },
  {
    label: 'Construction Trades',
    value: 'Construction Trades',
  },
  {
    label: 'Construction/Building Technology',
    value: 'Construction/Building Technology',
  },
  {
    label: 'Consumer & Family Economics',
    value: 'Consumer & Family Economics',
  },
  {
    label: 'Corrections',
    value: 'Corrections',
  },
  {
    label: 'Cosmetology/Hairstyling',
    value: 'Cosmetology/Hairstyling',
  },
  {
    label: 'Counseling & Student Services',
    value: 'Counseling & Student Services',
  },
  {
    label: 'Court Reporting',
    value: 'Court Reporting',
  },
  {
    label: 'Creative Writing',
    value: 'Creative Writing',
  },
  {
    label: 'Criminal Justice',
    value: 'Criminal Justice',
  },
  {
    label: 'Criminology',
    value: 'Criminology',
  },
  {
    label: 'Culinary Arts/Chef Training',
    value: 'Culinary Arts/Chef Training',
  },
  {
    label: 'Curriculum & Instruction',
    value: 'Curriculum & Instruction',
  },
  {
    label: 'Dance',
    value: 'Dance',
  },
  {
    label: 'Data Management Technology',
    value: 'Data Management Technology',
  },
  {
    label: 'Dental Assisting',
    value: 'Dental Assisting',
  },
  {
    label: 'Dental Hygiene',
    value: 'Dental Hygiene',
  },
  {
    label: 'Dentistry (Pre-Dentistry)',
    value: 'Dentistry (Pre-Dentistry)',
  },
  {
    label: 'Design & Visual Communications, General',
    value: 'Design & Visual Communications, General',
  },
  {
    label: 'Diesel Mechanics/Technology',
    value: 'Diesel Mechanics/Technology',
  },
  {
    label: 'Digital Communications/Media',
    value: 'Digital Communications/Media',
  },
  {
    label: 'Divinity/Ministry',
    value: 'Divinity/Ministry',
  },
  {
    label: 'Drafting/CAD Technology, General',
    value: 'Drafting/CAD Technology, General',
  },
  {
    label: 'Early Childhood Education',
    value: 'Early Childhood Education',
  },
  {
    label: 'Ecology',
    value: 'Ecology',
  },
  {
    label: 'Economics',
    value: 'Economics',
  },
  {
    label: 'Educational Administration',
    value: 'Educational Administration',
  },
  {
    label: 'Electrical, Electronics & Communications Engineering',
    value: 'Electrical, Electronics & Communications Engineering',
  },
  {
    label: 'Electrical, Electronics Engineering Technologies',
    value: 'Electrical, Electronics Engineering Technologies',
  },
  {
    label: 'Electrical/Electronics Equip Installation & Repair',
    value: 'Electrical/Electronics Equip Installation & Repair',
  },
  {
    label: 'Electromechanical/Biomedical Engineering Technologies',
    value: 'Electromechanical/Biomedical Engineering Technologies',
  },
  {
    label: 'Elementary Education',
    value: 'Elementary Education',
  },
  {
    label: 'Emergency Medical Technology',
    value: 'Emergency Medical Technology',
  },
  {
    label: 'Engineering (Pre-Engineering), General',
    value: 'Engineering (Pre-Engineering), General',
  },
  {
    label: 'Engineering Technology, General',
    value: 'Engineering Technology, General',
  },
  {
    label: 'English Language & Literature, General',
    value: 'English Language & Literature, General',
  },
  {
    label: 'English-as-a-Second-Language Education',
    value: 'English-as-a-Second-Language Education',
  },
  {
    label: 'English/Language Arts Education',
    value: 'English/Language Arts Education',
  },
  {
    label: 'Environmental Control Technologies',
    value: 'Environmental Control Technologies',
  },
  {
    label: 'Environmental Health Engineering',
    value: 'Environmental Health Engineering',
  },
  {
    label: 'Environmental Science',
    value: 'Environmental Science',
  },
  {
    label: 'Ethnic & Minority Studies, General',
    value: 'Ethnic & Minority Studies, General',
  },
  {
    label: 'European Area Studies',
    value: 'European Area Studies',
  },
  {
    label: 'Exercise Science/Physiology/Kinesiology',
    value: 'Exercise Science/Physiology/Kinesiology',
  },
  {
    label: 'Family & Consumer Sciences, General',
    value: 'Family & Consumer Sciences, General',
  },
  {
    label: 'Fashion Merchandising',
    value: 'Fashion Merchandising',
  },
  {
    label: 'Fashion/Apparel Design',
    value: 'Fashion/Apparel Design',
  },
  {
    label: 'Finance, General',
    value: 'Finance, General',
  },
  {
    label: 'Financial Planning & Services',
    value: 'Financial Planning & Services',
  },
  {
    label: 'Fine/Studio Arts',
    value: 'Fine/Studio Arts',
  },
  {
    label: 'Fire Protection & Safety Technology',
    value: 'Fire Protection & Safety Technology',
  },
  {
    label: 'Food & Nutrition',
    value: 'Food & Nutrition',
  },
  {
    label: 'Food Sciences & Technology',
    value: 'Food Sciences & Technology',
  },
  {
    label: 'Foreign Languages Education',
    value: 'Foreign Languages Education',
  },
  {
    label: 'Foreign Languages/Literatures, General',
    value: 'Foreign Languages/Literatures, General',
  },
  {
    label: 'Forestry',
    value: 'Forestry',
  },
  {
    label: 'French Language & Literature',
    value: 'French Language & Literature',
  },
  {
    label: 'Funeral Services & Mortuary Science',
    value: 'Funeral Services & Mortuary Science',
  },
  {
    label: 'Genetics',
    value: 'Genetics',
  },
  {
    label: 'Geography',
    value: 'Geography',
  },
  {
    label: 'Geological & Earth Sciences',
    value: 'Geological & Earth Sciences',
  },
  {
    label: 'German Language & Literature',
    value: 'German Language & Literature',
  },
  {
    label: 'Graphic & Printing Equipment Operation',
    value: 'Graphic & Printing Equipment Operation',
  },
  {
    label: 'Graphic Design',
    value: 'Graphic Design',
  },
  {
    label: 'Health & Physical Education/Fitness',
    value: 'Health & Physical Education/Fitness',
  },
  {
    label: 'Health Education',
    value: 'Health Education',
  },
  {
    label: 'Health Services Administration,General',
    value: 'Health Services Administration,General',
  },
  {
    label: 'Health-Related Professions & Services, General',
    value: 'Health-Related Professions & Services, General',
  },
  {
    label: 'Health/Medical Technology, General',
    value: 'Health/Medical Technology, General',
  },
  {
    label: 'Heating/Air Cond/Refrig Install/Repair',
    value: 'Heating/Air Cond/Refrig Install/Repair',
  },
  {
    label: 'History',
    value: 'History',
  },
  {
    label: 'Horticulture Operations & Management',
    value: 'Horticulture Operations & Management',
  },
  {
    label: 'Horticulture Science',
    value: 'Horticulture Science',
  },
  {
    label: 'Hospital/Facilities Administration',
    value: 'Hospital/Facilities Administration',
  },
  {
    label: 'Hotel/Motel Management',
    value: 'Hotel/Motel Management',
  },
  {
    label: 'Human Resources Development/Training',
    value: 'Human Resources Development/Training',
  },
  {
    label: 'Human Resources Management',
    value: 'Human Resources Management',
  },
  {
    label: 'Industrial Design',
    value: 'Industrial Design',
  },
  {
    label: 'Industrial Engineering',
    value: 'Industrial Engineering',
  },
  {
    label: 'Industrial Production Technologies',
    value: 'Industrial Production Technologies',
  },
  {
    label: 'Information Science',
    value: 'Information Science',
  },
  {
    label: 'Insurance & Risk Management',
    value: 'Insurance & Risk Management',
  },
  {
    label: 'Interior Architecture',
    value: 'Interior Architecture',
  },
  {
    label: 'Interior Design',
    value: 'Interior Design',
  },
  {
    label: 'International Business Management',
    value: 'International Business Management',
  },
  {
    label: 'International Relations & Affairs',
    value: 'International Relations & Affairs',
  },
  {
    label: 'Investments & Securities',
    value: 'Investments & Securities',
  },
  {
    label: 'Journalism, Broadcast',
    value: 'Journalism, Broadcast',
  },
  {
    label: 'Journalism, Print',
    value: 'Journalism, Print',
  },
  {
    label: 'Junior High/Middle School Education',
    value: 'Junior High/Middle School Education',
  },
  {
    label: 'Labor/Industrial Relations',
    value: 'Labor/Industrial Relations',
  },
  {
    label: 'Landscape Architecture',
    value: 'Landscape Architecture',
  },
  {
    label: 'Latin American Area Studies',
    value: 'Latin American Area Studies',
  },
  {
    label: 'Latino/Chicano Studies',
    value: 'Latino/Chicano Studies',
  },
  {
    label: 'Law (Pre-Law)',
    value: 'Law (Pre-Law)',
  },
  {
    label: 'Law Enforcement',
    value: 'Law Enforcement',
  },
  {
    label: 'Legal Administrative Assisting/Secretarial',
    value: 'Legal Administrative Assisting/Secretarial',
  },
  {
    label: 'Legal Studies, General',
    value: 'Legal Studies, General',
  },
  {
    label: 'Liberal Arts & General Studies',
    value: 'Liberal Arts & General Studies',
  },
  {
    label: 'Library Science',
    value: 'Library Science',
  },
  {
    label: 'Linguistics',
    value: 'Linguistics',
  },
  {
    label: 'Logistics & Materials Management',
    value: 'Logistics & Materials Management',
  },
  {
    label: 'Machine Tool Technology',
    value: 'Machine Tool Technology',
  },
  {
    label: 'Management Information Systems',
    value: 'Management Information Systems',
  },
  {
    label: 'Marine/Aquatic Biology',
    value: 'Marine/Aquatic Biology',
  },
  {
    label: 'Marketing Management & Research',
    value: 'Marketing Management & Research',
  },
  {
    label: 'Mass Communications',
    value: 'Mass Communications',
  },
  {
    label: 'Massage Therapy',
    value: 'Massage Therapy',
  },
  {
    label: 'Mathematics Education',
    value: 'Mathematics Education',
  },
  {
    label: 'Mathematics, General',
    value: 'Mathematics, General',
  },
  {
    label: 'Mechanical Drafting/CAD Technology',
    value: 'Mechanical Drafting/CAD Technology',
  },
  {
    label: 'Mechanical Engineering',
    value: 'Mechanical Engineering',
  },
  {
    label: 'Mechanical Engineering Technology',
    value: 'Mechanical Engineering Technology',
  },
  {
    label: 'Mechanics & Repairers, General',
    value: 'Mechanics & Repairers, General',
  },
  {
    label: 'Medical Assisting',
    value: 'Medical Assisting',
  },
  {
    label: 'Medical Laboratory Technology',
    value: 'Medical Laboratory Technology',
  },
  {
    label: 'Medical Office/Secretarial',
    value: 'Medical Office/Secretarial',
  },
  {
    label: 'Medical Radiologic Technology',
    value: 'Medical Radiologic Technology',
  },
  {
    label: 'Medical Records',
    value: 'Medical Records',
  },
  {
    label: 'Medical/Clinical Assisting, General',
    value: 'Medical/Clinical Assisting, General',
  },
  {
    label: 'Medicine (Pre-Medicine)',
    value: 'Medicine (Pre-Medicine)',
  },
  {
    label: 'Mental Health Counseling',
    value: 'Mental Health Counseling',
  },
  {
    label: 'Microbiology & Immunology',
    value: 'Microbiology & Immunology',
  },
  {
    label: 'Middle Eastern Languages & Literatures',
    value: 'Middle Eastern Languages & Literatures',
  },
  {
    label: 'Military Technologies',
    value: 'Military Technologies',
  },
  {
    label: 'Multi/Interdisciplinary Studies',
    value: 'Multi/Interdisciplinary Studies',
  },
  {
    label: 'Multimedia/Animation/Special Effects',
    value: 'Multimedia/Animation/Special Effects',
  },
  {
    label: 'Music Education',
    value: 'Music Education',
  },
  {
    label: 'Music, General',
    value: 'Music, General',
  },
  {
    label: 'Music, Performance',
    value: 'Music, Performance',
  },
  {
    label: 'Music, Theory & Composition',
    value: 'Music, Theory & Composition',
  },
  {
    label: 'Natural Resources Conservation, General',
    value: 'Natural Resources Conservation, General',
  },
  {
    label: 'Natural Resources Management',
    value: 'Natural Resources Management',
  },
  {
    label: 'North American Area Studies',
    value: 'North American Area Studies',
  },
  {
    label: 'Nuclear Engineering',
    value: 'Nuclear Engineering',
  },
  {
    label: 'Nuclear Medicine Technology',
    value: 'Nuclear Medicine Technology',
  },
  {
    label: 'Nursing, Practical/Vocational (LPN)',
    value: 'Nursing, Practical/Vocational (LPN)',
  },
  {
    label: 'Nursing, Registered (BS/RN)',
    value: 'Nursing, Registered (BS/RN)',
  },
  {
    label: 'Occupational Therapy',
    value: 'Occupational Therapy',
  },
  {
    label: 'Occupational Therapy Assisting',
    value: 'Occupational Therapy Assisting',
  },
  {
    label: 'Office Supervision & Management',
    value: 'Office Supervision & Management',
  },
  {
    label: 'Operations Management & Supervision',
    value: 'Operations Management & Supervision',
  },
  {
    label: 'Optometry (Pre-Optometry)',
    value: 'Optometry (Pre-Optometry)',
  },
  {
    label: 'Organizational Behavior',
    value: 'Organizational Behavior',
  },
  {
    label: 'Osteopathic Medicine',
    value: 'Osteopathic Medicine',
  },
  {
    label: 'Paralegal/Legal Assistant',
    value: 'Paralegal/Legal Assistant',
  },
  {
    label: 'Parks, Recreation, & Leisure, General',
    value: 'Parks, Recreation, & Leisure, General',
  },
  {
    label: 'Parks/Rec/Leisure Facilities Management',
    value: 'Parks/Rec/Leisure Facilities Management',
  },
  {
    label: 'Personal Services, General',
    value: 'Personal Services, General',
  },
  {
    label: 'Pharmacy (Pre-Pharmacy)',
    value: 'Pharmacy (Pre-Pharmacy)',
  },
  {
    label: 'Philosophy',
    value: 'Philosophy',
  },
  {
    label: 'Photography',
    value: 'Photography',
  },
  {
    label: 'Physical Education & Coaching',
    value: 'Physical Education & Coaching',
  },
  {
    label: 'Physical Sciences, General',
    value: 'Physical Sciences, General',
  },
  {
    label: 'Physical Therapy (Pre-Physical Therapy)',
    value: 'Physical Therapy (Pre-Physical Therapy)',
  },
  {
    label: 'Physical Therapy Assisting',
    value: 'Physical Therapy Assisting',
  },
  {
    label: 'Physician Assisting',
    value: 'Physician Assisting',
  },
  {
    label: 'Physics',
    value: 'Physics',
  },
  {
    label: 'Political Science & Government',
    value: 'Political Science & Government',
  },
  {
    label: 'Postsecondary Education',
    value: 'Postsecondary Education',
  },
  {
    label: 'Precision Production Trades, General',
    value: 'Precision Production Trades, General',
  },
  {
    label: 'Protective Services, General',
    value: 'Protective Services, General',
  },
  {
    label: 'Psychiatric/Mental Health Technician',
    value: 'Psychiatric/Mental Health Technician',
  },
  {
    label: 'Psychology, Clinical & Counseling',
    value: 'Psychology, Clinical & Counseling',
  },
  {
    label: 'Psychology, General',
    value: 'Psychology, General',
  },
  {
    label: 'Public Administration',
    value: 'Public Administration',
  },
  {
    label: 'Public Administration & Services, General',
    value: 'Public Administration & Services, General',
  },
  {
    label: 'Public Affairs & Public Policy Analysis',
    value: 'Public Affairs & Public Policy Analysis',
  },
  {
    label: 'Public Health',
    value: 'Public Health',
  },
  {
    label: 'Public Relations & Organizational Communication',
    value: 'Public Relations & Organizational Communication',
  },
  {
    label: 'Public Speaking',
    value: 'Public Speaking',
  },
  {
    label: 'Purchasing/Procurement/Contracts Management',
    value: 'Purchasing/Procurement/Contracts Management',
  },
  {
    label: 'Quality Control & Safety Technologies',
    value: 'Quality Control & Safety Technologies',
  },
  {
    label: 'Radio & Television Broadcasting',
    value: 'Radio & Television Broadcasting',
  },
  {
    label: 'Radio & Television Broadcasting Technology',
    value: 'Radio & Television Broadcasting Technology',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Rehabilitation Therapy',
    value: 'Rehabilitation Therapy',
  },
  {
    label: 'Religion',
    value: 'Religion',
  },
  {
    label: 'Religious Education',
    value: 'Religious Education',
  },
  {
    label: 'Respiratory Therapy Technology',
    value: 'Respiratory Therapy Technology',
  },
  {
    label: 'Restaurant/Food Services Management',
    value: 'Restaurant/Food Services Management',
  },
  {
    label: 'Sales, Merchandising, & Marketing, General',
    value: 'Sales, Merchandising, & Marketing, General',
  },
  {
    label: 'Science Education',
    value: 'Science Education',
  },
  {
    label: 'Secondary Education',
    value: 'Secondary Education',
  },
  {
    label: 'Secretarial Studies & Office Administration',
    value: 'Secretarial Studies & Office Administration',
  },
  {
    label: 'Small Business Management/Operations',
    value: 'Small Business Management/Operations',
  },
  {
    label: 'Social Sciences, General',
    value: 'Social Sciences, General',
  },
  {
    label: 'Social Studies/Sciences Education',
    value: 'Social Studies/Sciences Education',
  },
  {
    label: 'Social Work',
    value: 'Social Work',
  },
  {
    label: 'Sociology',
    value: 'Sociology',
  },
  {
    label: 'Spanish Language & Literature',
    value: 'Spanish Language & Literature',
  },
  {
    label: 'Special Education',
    value: 'Special Education',
  },
  {
    label: 'Sport & Fitness Administration/Management',
    value: 'Sport & Fitness Administration/Management',
  },
  {
    label: 'Statistics',
    value: 'Statistics',
  },
  {
    label: 'Surgical Technology',
    value: 'Surgical Technology',
  },
  {
    label: 'Surveying Technology',
    value: 'Surveying Technology',
  },
  {
    label: 'Teacher Assisting/Aide Education',
    value: 'Teacher Assisting/Aide Education',
  },
  {
    label: 'Teacher Education, General',
    value: 'Teacher Education, General',
  },
  {
    label: 'Teacher Education, Subject-Specific',
    value: 'Teacher Education, Subject-Specific',
  },
  {
    label: 'Textile & Apparel',
    value: 'Textile & Apparel',
  },
  {
    label: 'Theatre Arts/Drama',
    value: 'Theatre Arts/Drama',
  },
  {
    label: 'Theology, General',
    value: 'Theology, General',
  },
  {
    label: 'Therapy & Rehabilitation, General',
    value: 'Therapy & Rehabilitation, General',
  },
  {
    label: 'Tourism & Travel Marketing',
    value: 'Tourism & Travel Marketing',
  },
  {
    label: 'Transportation & Materials Moving',
    value: 'Transportation & Materials Moving',
  },
  {
    label: 'Travel/Tourism Management',
    value: 'Travel/Tourism Management',
  },
  {
    label: 'Undecided',
    value: 'Undecided',
  },
  {
    label: 'Urban Studies/Urban Affairs',
    value: 'Urban Studies/Urban Affairs',
  },
  {
    label: 'Veterinarian Assisting/Technology',
    value: 'Veterinarian Assisting/Technology',
  },
  {
    label: 'Veterinary Medicine (Pre-Veterinarian)',
    value: 'Veterinary Medicine (Pre-Veterinarian)',
  },
  {
    label: 'Vocational Rehabilitation Counseling',
    value: 'Vocational Rehabilitation Counseling',
  },
  {
    label: 'Webpage Design',
    value: 'Webpage Design',
  },
  {
    label: 'Welding Technology',
    value: 'Welding Technology',
  },
  {
    label: 'Wildlife & Wildlands Management',
    value: 'Wildlife & Wildlands Management',
  },
  {
    label: 'Women’s Studies',
    value: 'Women’s Studies',
  },
  {
    label: 'Zoology',
    value: 'Zoology',
  },
];
