import { useTranslation } from 'react-i18next';
import { Header } from '@amzn/awsui-components-react';
import { InfoLink } from 'common/components/HelpPanel';
import { ENTER_RACE_HELP_PANEL_URL } from 'common/constants';
import { useGetLeaderboard } from 'store/leaderboard';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getPageIdByRoutePattern } from 'pages/utils';
import { DRLitePage } from 'pages/pageCatalog';

export const PageHeader = (): JSX.Element => {
  const { t } = useTranslation('leaderboard');
  const history = useHistory();
  const currentPageId = getPageIdByRoutePattern(history.location.pathname);
  const match: any = useRouteMatch(
    // eslint-disable-next-line prettier/prettier
    `/${currentPageId === DRLitePage.COMMUNITY_ENTER ? 'communityEnterRace' : 'enterRace'
    }/:leaderboardArn`
  );
  const leaderboardArn = match?.params?.leaderboardArn;
  const { data: leaderboardData } = useGetLeaderboard(decodeURIComponent(leaderboardArn as string));
  const leaderboard = leaderboardData?.Leaderboard;

  return (
    <Header variant="h1" info={<InfoLink helpId={ENTER_RACE_HELP_PANEL_URL} />}>
      {leaderboard?.Name ? leaderboard?.Name : t('leaderboardLabel')}
    </Header>
  );
};
