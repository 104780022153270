import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Box, StatusIndicator, SpaceBetween, Button } from '@amzn/awsui-components-react';
import { EsperanzaContainer, EsperanzaSteps, OptInEsperanzaFields } from 'common/components/CompleteSignUp';
import { CompleteSignUpFields, SELF_IDENTIFY_OPTION } from 'common/constants';
import { convertEsperanzaFormDataToUpdateProfileRequest } from 'common/utils/profile';
import { getOptedInEsperanza, getProfileIsLoading, handleUpdateProfile } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { AsteroidTypes } from 'types';

export const EsperanzaStatus = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  const isLoading = useAppSelector(getProfileIsLoading);

  const handleSubmit = (data) => {
    if (!data[CompleteSignUpFields.OPT_IN]) return;
    const profileData: AsteroidTypes.UpdateMyProfileRequest = convertEsperanzaFormDataToUpdateProfileRequest(data);
    dispatch(handleUpdateProfile({ profileData }));
  };

  const initialValues = {
    [CompleteSignUpFields.OPT_IN]: false,
    [CompleteSignUpFields.GENDER]: [],
    [CompleteSignUpFields.GENDER_ENTERED]: '',
    [CompleteSignUpFields.RACE]: [],
    [CompleteSignUpFields.RACE_ENTERED]: '',
  };

  const validationSchema = Yup.object().shape({
    [CompleteSignUpFields.OPT_IN]: Yup.boolean().isTrue(t('required')),
    [CompleteSignUpFields.GENDER]: Yup.array().required(t('required')),
    [CompleteSignUpFields.RACE]: Yup.array().required(t('required')),
    [CompleteSignUpFields.GENDER_ENTERED]: Yup.string().when([CompleteSignUpFields.GENDER], {
      is: (genderSelected) => genderSelected?.some((gender) => gender.value === SELF_IDENTIFY_OPTION.value),
      then: () => Yup.string().required(t('required')).max(50, t('CompleteSignUp.invalidGenderLength')),
    }),
    [CompleteSignUpFields.RACE_ENTERED]: Yup.string().when([CompleteSignUpFields.RACE], {
      is: (race) => race?.some((race) => race.value === SELF_IDENTIFY_OPTION.value),
      then: () => Yup.string().required(t('required')).max(50, t('CompleteSignUp.invalidRaceLength')),
    }),
  });
  return (
    <EsperanzaContainer
      status={
        optedInEsperanza && (
          <Box margin={{ bottom: 'l' }}>
            <StatusIndicator>{t('ProfilePage.OptedInEsperanza')}</StatusIndicator>
          </Box>
        )
      }
    >
      <SpaceBetween size="m">
        <EsperanzaSteps hasLink />
        {!optedInEsperanza && (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ values }) => (
              <Form>
                <OptInEsperanzaFields />
                <Box float="right">
                  {values[CompleteSignUpFields.OPT_IN] && (
                    <Button variant="primary" loading={isLoading}>
                      {t('submit')}
                    </Button>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </SpaceBetween>
    </EsperanzaContainer>
  );
};
