import { Box, Button, Container } from '@amzn/awsui-components-react';
import humanizeDuration from 'humanize-duration';
import { getTrackImage } from 'common/utils/deepracer';
import pageCatalog from 'pages/pageCatalog';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useListTracks } from 'store/track';
import { DeepRacerLiteTypes } from 'types/DeepRacerLiteTypes';
import './styles.css';

enum LeaderboardRaceType {
  TIME_TRIAL = 'Time trial',
}

interface LeaderboardCardProps {
  leaderboard: DeepRacerLiteTypes.Leaderboard;
}

function LeaderboardCard({ leaderboard }: LeaderboardCardProps) {
  const history = useHistory();
  const [trackImage, setTrackImage] = useState(null);
  const startDate = new Date(leaderboard?.LaunchTime as any);
  const startDateHuman = `${startDate.toLocaleString('default', { month: 'long', day: 'numeric' })}`;
  const endDate = new Date(leaderboard?.CloseTime as any);
  const endDateHuman = `${endDate.toLocaleString('default', { month: 'long', day: 'numeric' })}`;
  const { data: listTracksData } = useListTracks();
  const tracks = listTracksData?.Tracks;
  const currentTrack = tracks?.filter((track) => track?.TrackArn === leaderboard?.TrackArn)?.[0];
  useEffect(() => {
    const getImage = async () => {
      if (trackImage) return;
      if (currentTrack) {
        const image = await getTrackImage(currentTrack?.TrackArn as string);
        setTrackImage(image);
      }
    };
    getImage();
  }, [currentTrack, trackImage]);
  const getTimeRemaining = () => {
    if (!leaderboard?.CloseTime || !leaderboard.LaunchTime) return <></>;
    if (Date.now() >= leaderboard?.CloseTime) {
      return <div className="timeBannerPadding">Closed</div>;
    }
    if (Date.now() <= leaderboard?.LaunchTime) {
      return <div className="timeBannerPadding">Opening soon</div>;
    }
    return (
      humanizeDuration(Date.now() - leaderboard?.CloseTime, { units: ['d', 'h', 'm'], largest: 1, round: true }) +
      ' remaining!'
    );
  };
  return (
    <Container data-analytics="raceCard-Container" data-analytics-type="eventContext" disableContentPaddings>
      <div style={{ position: 'relative' }}>
        {leaderboard?.CloseTime && <div className="communityRaceCardTimeBanner">{getTimeRemaining()}</div>}
        <Box className="communityRaceCardTop">
          <h2 style={{ color: 'white', marginBottom: '0px' }}>
            <b>{leaderboard?.Name}</b>
          </h2>
          <p style={{ marginTop: '6px', marginBottom: '0px', color: 'white' }}>
            {LeaderboardRaceType[leaderboard.RaceType as string]} - {currentTrack?.TrackName}
          </p>
          <p style={{ marginTop: '2px', color: 'white' }}>
            Race dates: {startDateHuman} - {endDateHuman}
          </p>
          {trackImage && <img src={trackImage} />}
        </Box>
        <Box className="communityRaceCardBottom">
          <Button
            data-analytics="raceDetails-Button"
            data-analytics-type="eventDetail"
            onClick={() =>
              history.push(
                pageCatalog.CommunityRacesLeaderboard.getPath({
                  leaderboardArn: leaderboard?.Arn,
                })
              )
            }
          >
            See race details
          </Button>
        </Box>
      </div>
    </Container>
  );
}

export default LeaderboardCard;
