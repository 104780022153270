import { getAllocation } from './';
import { FeatureFlagsTreatment } from './types';

/**
 * When creating feature flags, please include description for
 * the feature flag, Estimate date of removal, tracking SIM
 * for flag removal.
 */
export enum CurrentFeatureFlags {
  /**
   * This feature flag disables PolarisRefreshed theme
   * in DeepRacer Lite portal.
   * Estimate date of removal: TBD(When polaris refreshed moves out of beta)
   * SIM: None
   */
  DisablePolarisRefreshed = 'DRL_DISABLE_POLARIS',
  /**
   * Adds react-query dev tools to console so that devs can turn on when testing react-query functions
   * Estimate date of removal: never
   * SIM: None
   */
  ReactQueryDevTools = 'REACT_QUERY_DEV_TOOLS',
  /**
   * Adds DarkMode toggle related
   * Estimate date of removal: N/A
   * SIM: None
   */
  DarkMode = 'DARK_MODE',
  /**
   * Adds League Season 2023 UI updates
   * Estimate date of removal: 4/10
   * SIM: None
   */
  LeagueSeason2023 = 'LEAGUE_SEASON_2023',
  EndOfMarchLeagueSeason2023 = 'END_OF_MARCH_LEAGUE_2023',
  LeaderboardPoints = 'LEADERBOARD_POINTS',
  ShareableAchievements = 'SHAREABLE_ACHIEVEMENTS',
}

/**
 *
 * @param featureFlag Name of feature flag defined.
 * @param treatment Treatment can be Control (C) or T1
 * @returns Boolean based on treatment passed.
 */
const isFeatureFlagEnabled = (
  featureFlag: string,
  treatment: FeatureFlagsTreatment = FeatureFlagsTreatment.T1
): boolean => getAllocation(featureFlag) === treatment;

export const isPolarisRefreshedDisabled = () => isFeatureFlagEnabled(CurrentFeatureFlags.DisablePolarisRefreshed);
export const isReactQueryDevToolsEnabled = () => isFeatureFlagEnabled(CurrentFeatureFlags.ReactQueryDevTools);
export const isDarkModeEnabled = () => isFeatureFlagEnabled(CurrentFeatureFlags.DarkMode);
export const isShareableAchievementsEnabled = () => true;
