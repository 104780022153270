import { Box, Container, Grid, Header, SpaceBetween, StatusIndicator, TextContent } from '@amzn/awsui-components-react';
import { InitPlayer, StopPlayer, VideoPlayer } from 'common/components/videoPlayer/VideoPlayer';
import { millisToTimeUnits } from 'common/utils/dates/index';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetModel } from 'store/model';
import { clearAllNotifications, displayInfoNotification, displaySuccessNotification } from 'store/notifications';
import { clearTrack, fetchTrack, getTrack } from 'store/track';
import { clearStopTrainingJob, clearTrainingJobs, fetchTrainingJobs, getTrainingJobs } from 'store/trainingJob';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { DeepRacerLiteTypes } from 'types';
import { InfoLink } from 'common/components/HelpPanel';
import { TRACK_DIRECTION, TRAINING_HELP_PANEL_URL } from 'common/constants';
import {
  isModelInTerminalState,
  isJobInTerminalState,
  isJobPreInProgressState,
  isJobPostInProgressState,
} from 'common/utils/deepracer';
import './style.css';
import { KeyValuePair } from 'common/components/KeyValuePair';
import NextSteps from './NextSteps';

interface ModelParamType {
  modelArn: string;
}

const MODEL_STATUS_UPDATE_INTERVAL_MS = 30000;
const TRAINING_STATUS_UPDATE_INTERVAL_MS = 15000;
const trainingVideoPlayerId = 'trainigVideoPlayer';
const TRAINING_START_BUFFER_TIME = 10 * 60 * 1000;

export const ModelDetails = () => {
  const { modelArn } = useParams<ModelParamType>();
  const dispatch = useAppDispatch();
  const { data: modelData, refetch: refetchModel } = useGetModel(decodeURIComponent(modelArn));
  const model = modelData?.Model;
  const trainingJobs: any = useAppSelector(getTrainingJobs);
  const track: any = useAppSelector(getTrack);
  const [isTraining, setIsTraining] = useState<boolean>(false);
  const { t } = useTranslation('modelDetails');

  const videoPlayer = useRef<ReturnType<any>>();
  const modelRefreshInterval = useRef<ReturnType<typeof setInterval>>();
  const trainingRefreshInterval = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    dispatch(fetchTrainingJobs(decodeURIComponent(modelArn)));
  }, [dispatch, modelArn]);

  useEffect(() => {
    if (trainingJobs?.length) {
      if (!track) {
        dispatch(fetchTrack(trainingJobs[0].Config.TrackConfig.TrackArn));
      }
      if (isJobInTerminalState(trainingJobs[0]?.ActivityJob?.Status?.JobStatus)) {
        setIsTraining(false);
        if (trainingRefreshInterval.current) {
          videoPlayer.current = StopPlayer(videoPlayer.current);
          clearInterval(trainingRefreshInterval.current);
        }
      } else {
        setIsTraining(
          trainingJobs[0]?.ActivityJob?.Status?.JobStatus === DeepRacerLiteTypes.ActivityJobStatus.IN_PROGRESS
        );
        dispatch(clearAllNotifications());
        if (isJobPreInProgressState(trainingJobs[0]?.ActivityJob?.Status?.JobStatus)) {
          dispatch(
            displayInfoNotification({
              content: t('modelInProgressMessage'),
              header: t('modelInProgressMessageHeader'),
            })
          );
        }
        if (trainingJobs[0]?.ActivityJob?.Videos?.FRONT?.KinesisVideoUrl && !videoPlayer.current?.getMediaElement()) {
          videoPlayer.current = InitPlayer(
            trainingVideoPlayerId,
            trainingJobs[0].ActivityJob.Videos.FRONT.KinesisVideoUrl
          );
        }
        if (!trainingRefreshInterval.current) {
          trainingRefreshInterval.current = setInterval(() => {
            dispatch(fetchTrainingJobs(decodeURIComponent(modelArn)));
          }, TRAINING_STATUS_UPDATE_INTERVAL_MS);
        }
      }
    }
  }, [dispatch, trainingJobs, track, modelArn, trainingRefreshInterval, t]);

  useEffect(() => {
    if (model) {
      if (isModelInTerminalState(model.Status)) {
        if (modelRefreshInterval.current) {
          clearInterval(modelRefreshInterval.current);
        }
        dispatch(clearAllNotifications());
        dispatch(
          displaySuccessNotification({
            content: t('modelCreationSuccessMessage'),
            header: t('modelCreationSuccessMessageHeader'),
          })
        );
      } else {
        if (!modelRefreshInterval.current) {
          modelRefreshInterval.current = setInterval(() => {
            refetchModel();
          }, MODEL_STATUS_UPDATE_INTERVAL_MS);
        }
      }
    }
  }, [dispatch, model, modelRefreshInterval, refetchModel, t]);

  useEffect(() => {
    return () => {
      dispatch(clearTrainingJobs());
      dispatch(clearTrack());
      dispatch(clearStopTrainingJob());
      if (modelRefreshInterval.current) {
        clearInterval(modelRefreshInterval.current);
      }
      if (trainingRefreshInterval.current) {
        clearInterval(trainingRefreshInterval.current);
      }
      if (videoPlayer.current) {
        videoPlayer.current = StopPlayer(videoPlayer.current);
      }
    };
  }, [dispatch]);

  const modelStatus = (modelStatus: string) => {
    const awaitingTrainingStatus = <StatusIndicator type="pending">{t('modelStatus.created')}</StatusIndicator>;
    const trainingInProgress = <StatusIndicator type="pending">{t('modelStatus.inProgress')}</StatusIndicator>;
    const completedTraining = <StatusIndicator type="success">{t('modelStatus.completed')}</StatusIndicator>;
    const deletingInProgress = <StatusIndicator type="pending">{t('modelStatus.deleting')}</StatusIndicator>;
    const stoppingInProgress = <StatusIndicator type="pending">{t('modelStatus.stopping')}</StatusIndicator>;
    const copyingInProgress = <StatusIndicator type="pending">{t('modelStatus.copying')}</StatusIndicator>;
    const errorStatus = <StatusIndicator type="error">{t('modelStatus.error')}</StatusIndicator>;

    switch (modelStatus) {
      case DeepRacerLiteTypes.ModelStatus.READY:
        return completedTraining;
      case DeepRacerLiteTypes.ModelStatus.CREATED:
        return awaitingTrainingStatus;
      case DeepRacerLiteTypes.ModelStatus.TRAINING:
        return trainingInProgress;
      case DeepRacerLiteTypes.ModelStatus.DELETING:
        return deletingInProgress;
      case DeepRacerLiteTypes.ModelStatus.STOPPING:
        return stoppingInProgress;
      case DeepRacerLiteTypes.ModelStatus.ERROR:
        return errorStatus;
      case DeepRacerLiteTypes.ModelStatus.COPYING:
        return copyingInProgress;
      default:
        return null;
    }
  };

  const getRemainingTime = (trainingJobs) => {
    if (
      !trainingJobs ||
      trainingJobs.length === 0 ||
      isJobInTerminalState(trainingJobs[0].ActivityJob?.Status?.JobStatus) ||
      isJobPostInProgressState(trainingJobs[0].ActivityJob?.Status?.JobStatus)
    ) {
      return millisToTimeUnits(0, false, true);
    }

    if (isTraining && trainingJobs[0]?.ActivityJob?.StartTime) {
      let remainingTimeMilliseconds =
        trainingJobs[0].Config.TerminationConditions.MaxTimeInMinutes * 60 * 1000 +
        TRAINING_START_BUFFER_TIME +
        trainingJobs[0].ActivityJob.StartTime -
        Date.now();

      // show at least one minute left until training is ongoing
      remainingTimeMilliseconds = Math.max(remainingTimeMilliseconds, 60 * 1000);

      remainingTimeMilliseconds = Math.min(
        remainingTimeMilliseconds,
        trainingJobs[0].Config.TerminationConditions.MaxTimeInMinutes * 60 * 1000
      );
      return millisToTimeUnits(remainingTimeMilliseconds, false, true);
    }

    return millisToTimeUnits(trainingJobs[0].Config.TerminationConditions.MaxTimeInMinutes * 60 * 1000, false, true);
  };

  const renderTrackDeatils = (trackName: string, direction: string) => {
    if (trackName && direction) {
      return track?.TrackName + ' - ' + TRACK_DIRECTION[direction];
    }
    return track?.TrackName ?? '-';
  };

  return (
    <SpaceBetween direction="vertical" size="m" data-test-id="modelRoot">
      <Container
        header={
          <Header variant="h2" info={<InfoLink helpId={TRAINING_HELP_PANEL_URL} />}>
            {t('trainingConfig')}
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="xxl">
          <Box className="trainingRow">
            <Box className="trainingCol">
              <KeyValuePair label={t('track')}>
                {renderTrackDeatils(
                  track?.TrackName,
                  trainingJobs?.[0]?.Config?.TrackConfig?.TrackFeatures?.TrackDirection
                )}
              </KeyValuePair>
            </Box>
            <Box className="trainingCol">
              <KeyValuePair label={t('algorithm')}>{model?.AgentAlgorithm ?? '-'}</KeyValuePair>
            </Box>
            <Box className="trainingCol">
              <KeyValuePair label={t('modelDescription')}>{model?.ModelDescription ?? '-'}</KeyValuePair>
            </Box>
            <Box className="trainingCol">
              <KeyValuePair label={t('timeRemaining')}>{getRemainingTime(trainingJobs)}</KeyValuePair>
            </Box>
            <Box className="trainingCol">
              <KeyValuePair label={t('status')}>{model?.Status ? modelStatus(model?.Status) : '-'}</KeyValuePair>
            </Box>
          </Box>

          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <TextContent>
              <b>{t('videoStream')}</b>
              <p>{isTraining ? t('videoStreamContentPart1') : t('videoStreamNotAvailable')}</p>
              <p>{isTraining && t('videoStreamContentPart2')}</p>
              <p>{isTraining && t('videoStreamContentPart3')}</p>
              <p>{isTraining && t('videoStreamContentPart4')}</p>
            </TextContent>
            {isTraining && <VideoPlayer videoElementId={trainingVideoPlayerId} />}
          </Grid>
        </SpaceBetween>
      </Container>
      {model?.Status === DeepRacerLiteTypes.ModelStatus.READY && <NextSteps />}
    </SpaceBetween>
  );
};

export default ModelDetails;
