import { getIdToken } from 'store/utils/authUtils';
import ServiceProvider from 'services/ServiceProvider';
import { DeepRacerLiteTypes, DeepRacerLiteService } from 'types';

/**
 * Gets the user's scholarship qualification progress
 *
 * @returns User scholarship qualification progress
 */
export const getQualificationProgress = async (): Promise<DeepRacerLiteTypes.GetQualificationProgressResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const idToken = await getIdToken();
    return await deepRacerLiteService.getQualificationProgress(idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};
