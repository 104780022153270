import React from 'react';
import { HelpPanel, InfoLink as CommonInfoLink } from '@amzn/deep-racer-walk-up';
import { useAppDispatch } from 'store/utils/hooks';
import { toggleToolsOpen } from 'store/helppanel';

export { HelpPanel };

interface HelpPanelProps {
  // Example: 'deepcomposer/latest/console/hp-train-model.html'
  helpId: string;
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
}

export const InfoLink = ({
  helpId,
  dataAnalyticsLabel = 'info-Link',
  dataAnalyticsTypeLabel = 'eventContext',
}: HelpPanelProps) => {
  const dispatch = useAppDispatch();

  return (
    // Adding Link component to handle onClick events and stop propagation to parent component
    <CommonInfoLink
      dataAnalyticsLabel={dataAnalyticsLabel}
      dataAnalyticsTypeLabel={dataAnalyticsTypeLabel}
      helpId={helpId}
      onClick={() => {
        dispatch(toggleToolsOpen({ open: true }));
      }}
    />
  );
};
