import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Header, Container, SpaceBetween } from '@amzn/awsui-components-react';
import Button from '@amzn/awsui-components-react/polaris/button';

import DRVirtualCircuit from 'common/assets/raceTrackImg.png';

import '../styles.css';

export const PracticeML = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container
      data-analytics="practiceMl-Container"
      data-analytics-type="eventContext"
      className="student-container-height"
      header={<Header>{t('HomePage.PracticeML.Heading')}</Header>}
    >
      <SpaceBetween size="l">
        <Box variant="div" textAlign="center" className="img-div-resp">
          <img className="img-size-resp practiceMLImage" alt="DR Virtual Circuit" src={DRVirtualCircuit} />
        </Box>
        <Box variant="p" margin={{ bottom: 'm' }}>
          {t('HomePage.PracticeML.Message')}
        </Box>
      </SpaceBetween>
      <Box variant="div">
        <Button
          data-analytics="createModel-Button"
          data-analytics-type="eventDetail"
          variant="normal"
          href="/createmodel"
        >
          {t('HomePage.PracticeML.Button')}
        </Button>
      </Box>
    </Container>
  );
};
