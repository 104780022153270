import { getConfig } from '@amzn/aws-deep-racer-lite-client-config';
import AsteroidPublicService from '@amzn/aws-asteroid-public-service-type-script-client';
import AsteroidService from '@amzn/aws-asteroid-external-service-typescript-client';
import DeepRacerLiteService from '@amzn/aws-deep-racer-lite-external-service-type-script-client';

class ServiceProvider {
  static getAsteroidPublicService(): AsteroidPublicService {
    const asteroidPublicServiceConfig = getConfig('asteroidPublicService');
    return new AsteroidPublicService({ endpoint: asteroidPublicServiceConfig?.endpoint });
  }

  static getAsteroidService(): AsteroidService {
    const asteroidServiceConfig = getConfig('asteroidService');
    return new AsteroidService({ endpoint: asteroidServiceConfig?.endpoint });
  }

  static getDeepRacerLiteService(): DeepRacerLiteService {
    const deepRacerLiteServiceConfig = getConfig('deepRacerLite');
    return new DeepRacerLiteService({ endpoint: deepRacerLiteServiceConfig?.endPoint });
  }
}

export default ServiceProvider;
