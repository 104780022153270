import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Grid, Header, SpaceBetween, PieChart } from '@amzn/awsui-components-react';
import { LearningModuleHomeCardProps } from 'types/LearningModuleTypes';
import { PreRequisitesList } from 'common/components/LearningModulePreRequisites';
import { DeepRacerLiteTypes } from 'types';
import './styles.css';

export const homeCardGridDef = [
  { colspan: { default: 7, m: 6 } },
  { colspan: { default: 5, m: 6 } },
  { colspan: { default: 12, m: 6 } },
  { colspan: { default: 12 } },
];

// ToDo: Handle button click events, once finalize.

export const LearningModuleHomeCard = (props: LearningModuleHomeCardProps): JSX.Element => {
  const {
    cardAction,
    cardActionId,
    cardActionLabel,
    cardActionVariant,
    chapters,
    duration,
    level,
    preRequisites = [],
    title,
    topic,
    moduleData,
    isLoading,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const handleCardAction = () => {
    cardAction?.();
    history.push(`/learn/${cardActionId}`);
  };

  const status = moduleData?.moduleStatus;
  const completedChapters = moduleData?.chaptersCompleted || 0;
  const inCompletedChapters = chapters - completedChapters;
  const completedPrecentage = ((completedChapters / chapters) * 100).toFixed(0) + '%';
  const renderButtonText = (status?: DeepRacerLiteTypes.LmsCourseTrackerStatus) => {
    switch (status) {
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED:
        return t('LearnOverview.ReviewThisModule');
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.IN_PROGRESS:
        return t('LearnOverview.ResumeLearning');
      default:
        return t('LearnOverview.StartLearning');
    }
  };

  const renderButtonDataAnalyticsLabel = (status?: DeepRacerLiteTypes.LmsCourseTrackerStatus) => {
    switch (status) {
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED:
        return 'completed-Button';
      case DeepRacerLiteTypes.LmsCourseTrackerStatus.IN_PROGRESS:
        return 'inProgress-Button';
      default:
        return 'start-Button';
    }
  };

  return (
    <Container
      data-analytics={`${String(t(title)).replace(/\s/g, '')}-Container`}
      data-analytics-type="eventContext"
      className="student-container-height"
      header={<Header>{t(title)}</Header>}
    >
      <Grid gridDefinition={homeCardGridDef} className="center">
        <Box variant="div" margin={{ top: 'm' }}>
          <SpaceBetween size="s" className="minHeight">
            <Box variant="div">
              <Box variant="p" fontWeight="bold">
                {t('LearningModuleHomeCard.level')}
              </Box>
              {t(level)}
            </Box>
            <Box variant="div">
              <Box variant="p" fontWeight="bold">
                {t('LearningModuleHomeCard.duration')}
              </Box>
              {t(duration)}
            </Box>
            <Box variant="div">
              <Box variant="p" fontWeight="bold">
                {t('LearningModuleHomeCard.noOfChapters')}
              </Box>
              {chapters}
            </Box>
            <Box variant="div">
              <Box variant="p" fontWeight="bold">
                {t('LearningModuleHomeCard.topics')}
              </Box>
              {t(topic)}
            </Box>
            <Box variant="div">
              <PreRequisitesList preRequisites={preRequisites} />
            </Box>
          </SpaceBetween>
        </Box>
        <Box variant="div">
          {isLoading ? (
            <Box />
          ) : (
            <PieChart
              data={[
                { title: 'Complete', value: completedChapters },
                { title: 'Incomplete', value: inCompletedChapters },
              ]}
              hideDescriptions
              hideFilter
              hideTitles
              innerMetricValue={completedPrecentage}
              size="small"
              variant="donut"
            />
          )}
        </Box>
        <Box variant="div" margin={{ top: 'xxs' }}>
          {isLoading ? (
            <Box></Box>
          ) : (
            <Button
              data-analytics={renderButtonDataAnalyticsLabel(status)}
              data-analytics-type="eventDetail"
              ariaLabel={t(cardActionLabel) as string}
              variant={cardActionVariant}
              onClick={handleCardAction}
            >
              {renderButtonText(status)}
            </Button>
          )}
        </Box>
      </Grid>
    </Container>
  );
};
