import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLinkItem } from '../links';
import { CookiePrefrences } from './CookiePreferences';

import './styles.css';

const PRIVACY_URL = 'https://aws.amazon.com/privacy/?nc1=f_pr';
const SITE_TERMS = 'https://aws.amazon.com/terms/?nc1=f_pr';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <footer className="footer" id="footer">
      <div className="footerWrapper">
        <ul>
          <li>
            <ExternalLinkItem color="inverted" href={PRIVACY_URL} text={t('footer.privacy')} />
          </li>
          <li>|</li>
          <li>
            <ExternalLinkItem color="inverted" href={SITE_TERMS} text={t('footer.siteTerms')} />
          </li>
          <li>|</li>
          <li>
            <CookiePrefrences />
          </li>
          <li>|</li>
          <li>
            <p>{t('footer.copyright')}</p>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
