import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Header, Container, SpaceBetween, Icon } from '@amzn/awsui-components-react';

import { ExternalLinkItem } from 'common/components/links';
import { DISCORD_COMMUNITY, DEEP_RACER_CONSOLE_URL } from 'common/constants/urls';
import { getDocumentEndpoint } from 'pages/utils';
import { useAppSelector } from 'store/utils/hooks';
import { getOptedInEsperanza } from 'store/profile';

import '../styles.css';

export const Resources = (): JSX.Element => {
  const { t } = useTranslation();
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  return (
    <Container
      data-analytics="resources-Container"
      data-analytics-type="eventContext"
      className="student-container-height"
      header={
        <Header>
          {t('HomePage.Resources.Heading')} <Icon size="medium" name="external" />
        </Header>
      }
    >
      <Box>
        <SpaceBetween size="xs">
          <ExternalLinkItem
            dataAnalyticsLabel="userGuide-Link"
            dataAnalyticsTypeLabel="eventDetail"
            external={false}
            href={getDocumentEndpoint()}
            text={t('LandingPage.Documentation')}
          />
          <ExternalLinkItem
            dataAnalyticsLabel="discord-Link"
            dataAnalyticsTypeLabel="eventDetail"
            external={false}
            href={DISCORD_COMMUNITY}
            text={t('LandingPage.DiscordCommunity')}
          />
          <ExternalLinkItem
            dataAnalyticsLabel="deepRacerWebsite-Link"
            dataAnalyticsTypeLabel="eventDetail"
            external={false}
            href={DEEP_RACER_CONSOLE_URL}
            text={t('LandingPage.DeerpacerWebsite')}
          />
        </SpaceBetween>
      </Box>
      {!optedInEsperanza && (
        <>
          {[...Array(5)].map((_, index) => (
            <Fragment key={`notOptedInEsperanzaBreak_${index}`}>
              <br />
            </Fragment>
          ))}
        </>
      )}
    </Container>
  );
};
