import React from 'react';
import { Box } from '@amzn/awsui-components-react/polaris';

interface KeyValuePairProps {
  label: React.ReactNode;
}

export const KeyValuePair: React.FC<KeyValuePairProps> = ({ label, children }) => (
  <Box>
    <Box margin={{ bottom: 'xxxs' }} fontWeight="bold">
      {label}
    </Box>
    <Box color="text-label">{children}</Box>
  </Box>
);
