import { useTranslation } from 'react-i18next';

import { Box, Header, Container } from '@amzn/awsui-components-react';

import '../styles.css';
import { ExternalLinkItem } from 'common/components/links';
import { DEEP_RACER_LEAGUE_RULES } from 'common/constants';

export const WhatsNew = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'HomePage.WhatsNew' });

  return (
    <Container
      data-analytics="whatsNew-Container"
      data-analytics-type="eventContext"
      disableContentPaddings
      disableHeaderPaddings
      className="student-container-height"
      header={
        <Box padding={{ top: 'l', bottom: 'xs', left: 'l', right: 'l' }}>
          <Header variant="h2">{t('Heading')}</Header>
        </Box>
      }
    >
      <Box padding={{ top: 'n', bottom: 'n', left: 'l', right: 'l' }}>
        <Box variant="h4" padding={{ top: 'n', bottom: 'n' }}>
          {t('paragraphOne.header')}
        </Box>
        <Box variant="p" margin={{ bottom: 's' }}>
          {t('paragraphOne.content')}{' '}
          {<ExternalLinkItem href={DEEP_RACER_LEAGUE_RULES} text={t('LearnMore')}></ExternalLinkItem>}
        </Box>
        <Box variant="h4" padding={{ top: 'n', bottom: 'n' }}>
          {t('paragraphTwo.header')}
        </Box>
        <Box variant="p" margin={{ bottom: 's' }}>
          {t('paragraphTwo.content')}
        </Box>
        <Box variant="h4" padding={{ top: 'n', bottom: 'n' }}>
          {t('paragraphThree.header')}
        </Box>
        <Box variant="p" margin={{ bottom: 's' }}>
          {t('paragraphThree.content')}
        </Box>
      </Box>
    </Container>
  );
};
