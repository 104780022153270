import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TRACK_SLICE, TrackAction, emptyResource, emptyResourceList } from 'common/constants';
import { getTrack, listTracks } from 'services/deepRacerLite';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResource, AsyncResourceList } from 'store/types';
import { addLoadingCasesWithNestedListState, addLoadingCasesWithNestedState } from 'store/utils/loadingCases';
import { DeepRacerLiteTypes } from 'types/DeepRacerLiteTypes';

export interface TrackState {
  track: AsyncResource<DeepRacerLiteTypes.Track>;
  tracks: AsyncResourceList<DeepRacerLiteTypes.Track>;
}

const initialState: TrackState = {
  track: emptyResource,
  tracks: emptyResourceList,
};

export const fetchTrack = createAsyncThunk<DeepRacerLiteTypes.Track | undefined, string>(
  TrackAction.GET_TRACK,
  async (trackArn: string, { dispatch }) => {
    try {
      const result = await getTrack(trackArn);
      return result.Track;
    } catch (error: any) {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      throw error;
    }
  }
);

export const fetchTracks = createAsyncThunk(TrackAction.LIST_TRACKS, async (args, { dispatch }) => {
  try {
    const result = await listTracks();
    return result.Tracks;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const trackSlice = createSlice({
  name: TRACK_SLICE,
  initialState,
  reducers: {
    clearTracks: (state) => {
      state.tracks = emptyResourceList;
    },
    clearTrack: (state) => {
      state.track = emptyResource;
    },
  },
  extraReducers: (builder) => {
    addLoadingCasesWithNestedState(builder, TrackAction.GET_TRACK, TRACK_SLICE, 'track');
    addLoadingCasesWithNestedListState(builder, TrackAction.LIST_TRACKS, TRACK_SLICE, 'tracks');
  },
});

export const { clearTracks, clearTrack } = trackSlice.actions;

export default trackSlice.reducer;
