import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@amzn/awsui-components-react';
import { InputFormField, MultiselectFormField } from 'common/components/FormFields';
import { CompleteSignUpFields, GENDER_LIST, SELF_IDENTIFY_OPTION } from 'common/constants';
import { onMultiselectChange } from 'common/utils/profile';

export const GenderFieldsView = (): JSX.Element => {
  const { t } = useTranslation();
  const [genderField, , genderFieldHelper] = useField({ name: CompleteSignUpFields.GENDER });
  return (
    <Box>
      <MultiselectFormField
        label={
          <>
            {t('CompleteSignUp.genderLabel')} - <i>{t('optional')}</i>
          </>
        }
        placeholder={t('CompleteSignUp.genderLabel')}
        options={GENDER_LIST}
        onChange={({ detail }) => onMultiselectChange(detail.selectedOptions, genderFieldHelper)}
        name={CompleteSignUpFields.GENDER}
      />
      {genderField.value.some((option) => option === SELF_IDENTIFY_OPTION) && (
        <Box margin={{ top: 's' }}>
          <InputFormField
            description={t('CompleteSignUp.enterGender')}
            placeholder={t('CompleteSignUp.genderPlaceholder')}
            name={CompleteSignUpFields.GENDER_ENTERED}
            type="text"
          />
        </Box>
      )}
    </Box>
  );
};

export const GenderFields = memo(GenderFieldsView);
