import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TRAINING_JOB_SLICE, TrainingJobAction, emptyResource, emptyResourceList } from 'common/constants';
import { getTrainingJob, listTrainingJobs, stopTrainingJob as stopTrainingJobService } from 'services/deepRacerLite';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResource, AsyncResourceList } from 'store/types';
import { addLoadingCasesWithNestedListState, addLoadingCasesWithNestedState } from 'store/utils/loadingCases';
import { DeepRacerLiteTypes } from 'types/DeepRacerLiteTypes';

export interface TrainingJobsState {
  trainingJob: AsyncResource<DeepRacerLiteTypes.TrainingJob>;
  trainingJobs: AsyncResourceList<DeepRacerLiteTypes.TrainingJob>;
  stopTrainingJob: AsyncResource<string>;
}

const initialState: TrainingJobsState = {
  trainingJob: emptyResource,
  trainingJobs: emptyResourceList,
  stopTrainingJob: emptyResource,
};

export const fetchTrainingJob = createAsyncThunk<DeepRacerLiteTypes.TrainingJob | undefined, string>(
  TrainingJobAction.GET_TRAINING_JOB,
  async (trainingArn: string, { dispatch }) => {
    try {
      const result = await getTrainingJob(trainingArn);
      return result.TrainingJob;
    } catch (error: any) {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      throw error;
    }
  }
);

export const fetchTrainingJobs = createAsyncThunk<DeepRacerLiteTypes.TrainingJob[] | undefined, string>(
  TrainingJobAction.LIST_TRAINING_JOBS,
  async (modelArn: string, { dispatch }) => {
    try {
      const result = await listTrainingJobs(modelArn);
      return result.TrainingJobs;
    } catch (error: any) {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      throw error;
    }
  }
);

export const stopTrainingJob = createAsyncThunk<string | undefined, string>(
  TrainingJobAction.STOP_TRAINING_JOB,
  async (modelArn: string, { dispatch }) => {
    try {
      await stopTrainingJobService(modelArn);
      return modelArn;
    } catch (error: any) {
      dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
      throw error;
    }
  }
);

export const trainingJobSlice = createSlice({
  name: TRAINING_JOB_SLICE,
  initialState,
  reducers: {
    clearTrainingJobs: (state) => {
      state.trainingJobs = emptyResourceList;
    },
    clearTrainingJob: (state) => {
      state.trainingJob = emptyResource;
    },
    clearStopTrainingJob: (state) => {
      state.stopTrainingJob = emptyResource;
    },
  },
  extraReducers: (builder) => {
    addLoadingCasesWithNestedState(builder, TrainingJobAction.GET_TRAINING_JOB, TRAINING_JOB_SLICE, 'trainingJob');
    addLoadingCasesWithNestedListState(
      builder,
      TrainingJobAction.LIST_TRAINING_JOBS,
      TRAINING_JOB_SLICE,
      'trainingJobs'
    );
    addLoadingCasesWithNestedState(builder, TrainingJobAction.STOP_TRAINING_JOB, TRAINING_JOB_SLICE, 'stopTrainingJob');
  },
});

export const { clearTrainingJobs, clearTrainingJob, clearStopTrainingJob } = trainingJobSlice.actions;

export default trainingJobSlice.reducer;
