import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';

import { DRLitePage } from 'pages/pageCatalog';
import pageCatalog from 'pages/pageCatalog';
import { logout, getIsAuthenticated } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { clearAllNotifications } from 'store/notifications';
import { getDocumentEndpoint, getRouteDefinitionByRoutePattern } from 'pages/utils';
import { getOptedInEsperanza } from 'store/profile';
import { toggleConfettiRunning } from 'store/confetti';
import { useEffect } from 'react';

export const Navigation = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeHref, setActiveHref] = React.useState('/home');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  const currentRoute = getRouteDefinitionByRoutePattern(location.pathname)?.route;

  // useEffect to change active href when route changes
  useEffect(() => {
    if (!currentRoute) return;
    setActiveHref(currentRoute);
  }, [currentRoute]);

  const signOutHandler = () => {
    dispatch(logout()).then(() => {
      dispatch(clearAllNotifications());
      history.push(pageCatalog.SignIn.getPath());
    });
    dispatch(
      toggleConfettiRunning({
        isRunning: false,
      })
    );
  };

  const LearnSectionItems: Array<SideNavigationProps.Item> = [
    {
      type: 'link',
      text: t('Navigation.Overview'),
      href: pageCatalog[DRLitePage.LEARNING_HOME].getPath(),
    },
  ];

  if (optedInEsperanza) {
    LearnSectionItems.push({
      type: 'link',
      text: t('Navigation.Quiz'),
      href: pageCatalog[DRLitePage.ASSESSMENT_RESULT].getPath(),
    });
  }

  const topNonSectionItems: Array<SideNavigationProps.Item> = [
    { type: 'link', text: 'Home', href: pageCatalog[DRLitePage.STUDENT_HOME].getPath() },
  ];

  const competeSectionItems: Array<SideNavigationProps.Item> = [
    {
      type: 'link',
      text: t('StudentLeague'),
      href: pageCatalog[DRLitePage.STUDENT_LEAGUE].getPath(),
    },
    {
      type: 'link',
      text: t('CommunityLeaderboardSideNav'),
      href: pageCatalog[DRLitePage.COMMUNITY_LANDING].getPath(),
    },
  ];

  const sectionItems: Array<SideNavigationProps.Item> = [
    {
      type: 'section',
      text: t('Navigation.Learn'),
      items: [...LearnSectionItems],
    },
    {
      type: 'section',
      text: t('Navigation.Practice'),
      items: [
        {
          type: 'link',
          text: t('YourModel'),
          href: pageCatalog[DRLitePage.MODELS_LIST].getPath(),
        },
        {
          type: 'link',
          text: t('ModelImpResource'),
          href: pageCatalog[DRLitePage.IMPROVE_MODEL].getPath(),
        },
      ],
    },
    {
      type: 'section',
      text: t('Navigation.Compete'),
      items: competeSectionItems,
    },
  ];

  const bottomNonSectionItems: Array<SideNavigationProps.Item> = [
    { type: 'divider' },
    {
      type: 'link',
      text: t('Documentation'),
      href: getDocumentEndpoint(),
      external: true,
    },
    {
      type: 'link',
      text: t('Navigation.ContactUs'),
      href: pageCatalog[DRLitePage.CONTACT_US].getPath(),
    },
  ];

  const items: Array<SideNavigationProps.Item> = [...topNonSectionItems, ...sectionItems];

  if (isAuthenticated) {
    items.push(...bottomNonSectionItems);
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: pageCatalog[DRLitePage.STUDENT_HOME].getPath(), text: t('DRStudentLeague') }}
      onFollow={(event) => {
        event.stopPropagation();
        if (event.detail.text === t('signout')) {
          event.preventDefault();
          signOutHandler();
        } else if (!event.detail.external) {
          event.preventDefault();
          setActiveHref(event.detail.href);
          history.push(event.detail.href);
        }
      }}
      items={items}
    />
  );
};
