import type { CookieRegistry } from '@amzn/aws-deepracer-ui-components/utils/storage';
import { StorageKey } from './constants';

/**
 * Shortbread registry of storage keys and their cookie consent categories
 */
export const cookieRegistry: CookieRegistry<typeof StorageKey> = {
  [StorageKey.BANNERS]: { category: 'functional' },
  [StorageKey.MODALS]: { category: 'functional' },
  [StorageKey.USER_PREFERENCES]: { category: 'functional' },
};
