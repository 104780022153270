import React, { useEffect } from 'react';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { NOTIFICATION_DISMISS_TIME_IN_MS } from 'common/constants';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { getNotifications, removeNotification } from 'store/notifications';

export const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(getNotifications);

  useEffect(() => {
    // Scroll to top of the page when there notifications change
    window.scrollTo(0, 0);
  }, [notifications]);

  const items = notifications.map((notification) => {
    const onDismiss = (event) => {
      dispatch(removeNotification(notification));
      notification.onDismiss?.(event);
    };
    if (notification.autoDismiss) {
      setTimeout((event) => onDismiss(event), NOTIFICATION_DISMISS_TIME_IN_MS);
    }
    return {
      ...notification,
      onDismiss,
    };
  });
  if (items?.length === 0) return null;
  return <Flashbar items={items} />;
};
