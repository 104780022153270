export const SCHOOL_LIST = [
  { label: 'Other', value: 'OTHER' },
  { label: '1 December University of Alba Iulia', value: '1 December University of Alba Iulia' },
  { label: '2nd Military Medical University', value: '2nd Military Medical University' },
  { label: '3rd Military Medical University', value: '3rd Military Medical University' },
  { label: '4th Military Medical University', value: '4th Military Medical University' },
  { label: 'Aalborg Business College', value: 'Aalborg Business College' },
  { label: 'Aalborg University', value: 'Aalborg University' },
  { label: 'Aarhus School of Architecture', value: 'Aarhus School of Architecture' },
  { label: 'Aarhus School of Business', value: 'Aarhus School of Business' },
  { label: 'Aarhus Technical College', value: 'Aarhus Technical College' },
  { label: 'Aarhus University', value: 'Aarhus University' },
  { label: 'Abai State University', value: 'Abai State University' },
  { label: 'Abant Izzet Baysal University', value: 'Abant Izzet Baysal University' },
  { label: 'Abasyn University Peshawar', value: 'Abasyn University Peshawar' },
  {
    label: 'Abdus Salam international centre for theoretical physics',
    value: 'Abdus Salam international centre for theoretical physics',
  },
  { label: 'Abfi Institute Perbanas Jakarta', value: 'Abfi Institute Perbanas Jakarta' },
  { label: 'Abia State Polytechnic', value: 'Abia State Polytechnic' },
  { label: 'Abia State University', value: 'Abia State University' },
  { label: 'Abilene Christian University', value: 'Abilene Christian University' },
  { label: 'ABM University College', value: 'ABM University College' },
  { label: 'Abo Akademi University', value: 'Abo Akademi University' },
  { label: 'ABTI American University of Nigeria', value: 'ABTI American University of Nigeria' },
  { label: 'Abu Dhabi University', value: 'Abu Dhabi University' },
  { label: 'Abubakar Tafawa Balewa University', value: 'Abubakar Tafawa Balewa University' },
  { label: 'Abylai Khan University', value: 'Abylai Khan University' },
  { label: 'Academia de Studii Economice din Bucuresti', value: 'Academia de Studii Economice din Bucuresti' },
  { label: 'Academia Nacional Superior de Orquesta', value: 'Academia Nacional Superior de Orquesta' },
  { label: 'Academia Tehnica Militara', value: 'Academia Tehnica Militara' },
  { label: 'Academy of Art College', value: 'Academy of Art College' },
  { label: 'Academy of Arts', value: 'Academy of Arts' },
  { label: 'Academy of Arts George Enescu Iasi', value: 'Academy of Arts George Enescu Iasi' },
  { label: 'Academy of Arts in Banská Bystrica', value: 'Academy of Arts in Banská Bystrica' },
  { label: 'Academy of Drama and Film', value: 'Academy of Drama and Film' },
  { label: 'Academy of Economic Studies of Moldova', value: 'Academy of Economic Studies of Moldova' },
  { label: 'Academy of Economics Dimitur A. Tscenov', value: 'Academy of Economics Dimitur A. Tscenov' },
  { label: 'Academy of Economics in Cracow', value: 'Academy of Economics in Cracow' },
  { label: 'Academy of Economics in Katowice', value: 'Academy of Economics in Katowice' },
  { label: 'Academy of Economics in Poznan', value: 'Academy of Economics in Poznan' },
  { label: 'Academy of Economics in Wroclaw', value: 'Academy of Economics in Wroclaw' },
  { label: 'Academy of Fine Arts', value: 'Academy of Fine Arts' },
  { label: 'Academy of Fine Arts and Design in Bratislava', value: 'Academy of Fine Arts and Design in Bratislava' },
  { label: 'Academy of Humanities and Economics in Lodz', value: 'Academy of Humanities and Economics in Lodz' },
  {
    label: 'Academy of International Economic and Political Relations, Gdynia',
    value: 'Academy of International Economic and Political Relations, Gdynia',
  },
  { label: 'Academy of Management and Entrepreneurship', value: 'Academy of Management and Entrepreneurship' },
  { label: 'Academy of Music Georghe Dima Cluj-Napoca', value: 'Academy of Music Georghe Dima Cluj-Napoca' },
  { label: 'Academy of Music, Dance and Fine Arts', value: 'Academy of Music, Dance and Fine Arts' },
  {
    label: 'Academy of Performing Arts, Film and TV Fakulty',
    value: 'Academy of Performing Arts, Film and TV Fakulty',
  },
  { label: 'Academy of Public Administration', value: 'Academy of Public Administration' },
  { label: 'Academy of Public Administration of Belarus', value: 'Academy of Public Administration of Belarus' },
  { label: 'Academy of Sports and Physical Training', value: 'Academy of Sports and Physical Training' },
  {
    label: 'Academy of the Ministry of Internal Affairs of the Republic of Belarus',
    value: 'Academy of the Ministry of Internal Affairs of the Republic of Belarus',
  },
  { label: 'Acadia University', value: 'Acadia University' },
  { label: 'Accra Polytechnic ', value: 'Accra Polytechnic ' },
  { label: 'Acdemic Center for Law and Business', value: 'Acdemic Center for Law and Business' },
  { label: 'Acharya Nagarjuna University', value: 'Acharya Nagarjuna University' },
  { label: 'Acharya Ranga Agricultural University', value: 'Acharya Ranga Agricultural University' },
  { label: 'Adam Mickiewicz University of Poznan', value: 'Adam Mickiewicz University of Poznan' },
  { label: 'Adama Science and Technology University', value: 'Adama Science and Technology University' },
  { label: 'Adamawa State University', value: 'Adamawa State University' },
  { label: 'Adams State College', value: 'Adams State College' },
  { label: 'Adamson University', value: 'Adamson University' },
  { label: 'Addis Ababa Science & Technology University', value: 'Addis Ababa Science & Technology University' },
  { label: 'Addis Ababa University', value: 'Addis Ababa University' },
  { label: 'Adekunle Ajasin University', value: 'Adekunle Ajasin University' },
  { label: 'Adeleke University', value: 'Adeleke University' },
  { label: 'Adelphi University', value: 'Adelphi University' },
  { label: 'Adeyemi College of Education', value: 'Adeyemi College of Education' },
  { label: 'Adiban Higher Education Institue', value: 'Adiban Higher Education Institue' },
  { label: 'Adigrat University', value: 'Adigrat University' },
  { label: 'Adler School of Professional Psychology', value: 'Adler School of Professional Psychology' },
  { label: 'Adnan Menderes University', value: 'Adnan Menderes University' },
  { label: 'Adrian College', value: 'Adrian College' },
  { label: 'Advance Tertiary College', value: 'Advance Tertiary College' },
  { label: 'Adventist University of Africa', value: 'Adventist University of Africa' },
  { label: 'Adventist University of Central Africa', value: 'Adventist University of Central Africa' },
  { label: 'Adventist University of the Philippines', value: 'Adventist University of the Philippines' },
  { label: 'Adygeja State University', value: 'Adygeja State University' },
  { label: 'Aegean University', value: 'Aegean University' },
  { label: 'Afe Babalola University', value: 'Afe Babalola University' },
  { label: 'Afeka Tel Aviv Academic College of Engineering', value: 'Afeka Tel Aviv Academic College of Engineering' },
  { label: 'Afghan University', value: 'Afghan University' },
  { label: 'Africa International University', value: 'Africa International University' },
  { label: 'Africa Nazarene University', value: 'Africa Nazarene University' },
  { label: 'Africa University', value: 'Africa University' },
  { label: 'African University of Science and Technology', value: 'African University of Science and Technology' },
  { label: 'African Virtual University', value: 'African Virtual University' },
  { label: 'Afyon Kocatepe University', value: 'Afyon Kocatepe University' },
  { label: 'Aga Khan University', value: 'Aga Khan University' },
  { label: 'Aga Khan University', value: 'Aga Khan University' },
  { label: 'Aga Khan University', value: 'Aga Khan University' },
  { label: 'Aga Khan University', value: 'Aga Khan University' },
  { label: 'Aga Khan University', value: 'Aga Khan University' },
  { label: 'Agder University College', value: 'Agder University College' },
  { label: 'AGH University of Science and Technology', value: 'AGH University of Science and Technology' },
  { label: 'Agnes Scott College', value: 'Agnes Scott College' },
  { label: 'Agricultural University of Athens', value: 'Agricultural University of Athens' },
  { label: 'Agricultural University of Cracow', value: 'Agricultural University of Cracow' },
  { label: 'Agricultural University of Georgia', value: 'Agricultural University of Georgia' },
  { label: 'Agricultural University of Lublin', value: 'Agricultural University of Lublin' },
  { label: 'Agricultural University of Norway', value: 'Agricultural University of Norway' },
  { label: 'Agricultural University of Plovdiv', value: 'Agricultural University of Plovdiv' },
  { label: 'Agricultural University of Poznan', value: 'Agricultural University of Poznan' },
  { label: 'Agricultural University of Szczecin', value: 'Agricultural University of Szczecin' },
  { label: 'Agricultural University of Tirane', value: 'Agricultural University of Tirane' },
  { label: 'Agricultural University of Warsaw', value: 'Agricultural University of Warsaw' },
  { label: 'Agricultural University of Wroclaw', value: 'Agricultural University of Wroclaw' },
  { label: 'Agricultural-Technical Academy in Olsztyn', value: 'Agricultural-Technical Academy in Olsztyn' },
  { label: 'Agriculture and Forestry University', value: 'Agriculture and Forestry University' },
  { label: 'AgroParisTech', value: 'AgroParisTech' },
  { label: 'Ahfad University for Women', value: 'Ahfad University for Women' },
  { label: 'Ahi Evran University', value: 'Ahi Evran University' },
  { label: 'Ahlulbait International University', value: 'Ahlulbait International University' },
  { label: 'Ahmadu Bello University', value: 'Ahmadu Bello University' },
  { label: 'Ahmedabad University', value: 'Ahmedabad University' },
  { label: 'Ahsanullah University of Science & Technology', value: 'Ahsanullah University of Science & Technology' },
  { label: 'Ahvaz University of Medical Sciences', value: 'Ahvaz University of Medical Sciences' },
  {
    label: 'Ahwaz Jondishapour University of Medical Sciences',
    value: 'Ahwaz Jondishapour University of Medical Sciences',
  },
  { label: 'Aichi Bunkyo University', value: 'Aichi Bunkyo University' },
  { label: 'Aichi Gakuin University', value: 'Aichi Gakuin University' },
  { label: 'Aichi Gakusen University', value: 'Aichi Gakusen University' },
  { label: 'Aichi Institute of Technology', value: 'Aichi Institute of Technology' },
  { label: 'Aichi Medical University', value: 'Aichi Medical University' },
  { label: 'Aichi Prefectural University', value: 'Aichi Prefectural University' },
  {
    label: 'Aichi Prefectural University of Fine Arts & Music',
    value: 'Aichi Prefectural University of Fine Arts & Music',
  },
  { label: 'Aichi Sangyo University', value: 'Aichi Sangyo University' },
  { label: 'Aichi Shukutoku University', value: 'Aichi Shukutoku University' },
  { label: 'Aichi University', value: 'Aichi University' },
  { label: 'Aichi University of Education', value: 'Aichi University of Education' },
  { label: 'Aikoku Gakuen University', value: 'Aikoku Gakuen University' },
  { label: 'Aimst University', value: 'Aimst University' },
  { label: 'Ain Shams University', value: 'Ain Shams University' },
  { label: 'Air Force Institute of Technology', value: 'Air Force Institute of Technology' },
  { label: 'Air University', value: 'Air University' },
  { label: 'AISECT University', value: 'AISECT University' },
  { label: 'AISTEDA', value: 'AISTEDA' },
  { label: 'Ajayi Crowther University', value: 'Ajayi Crowther University' },
  { label: 'Ajman University of Science & Technology', value: 'Ajman University of Science & Technology' },
  { label: 'Ajou University', value: 'Ajou University' },
  {
    label: 'AKAD Hochschulen für Berufstätige, Fachhochschule Leipzig',
    value: 'AKAD Hochschulen für Berufstätige, Fachhochschule Leipzig',
  },
  { label: 'Akademia Podlaska', value: 'Akademia Podlaska' },
  { label: 'Akademie der bildenden Künste Wien', value: 'Akademie der bildenden Künste Wien' },
  { label: 'Akaki Tsereteli State University', value: 'Akaki Tsereteli State University' },
  { label: 'Akanu Ibiam Federal Polytechnic, Unwana', value: 'Akanu Ibiam Federal Polytechnic, Unwana' },
  { label: 'Akdeniz University', value: 'Akdeniz University' },
  { label: 'Akhbar El Yom Academy', value: 'Akhbar El Yom Academy' },
  { label: 'Akita University', value: 'Akita University' },
  { label: 'Akita University of Economics and Law', value: 'Akita University of Economics and Law' },
  {
    label: 'Akrofi-Christaller Institute of Theeology, Mission and  Culture',
    value: 'Akrofi-Christaller Institute of Theeology, Mission and  Culture',
  },
  { label: 'Aksum University', value: 'Aksum University' },
  { label: 'Aktau State University', value: 'Aktau State University' },
  { label: 'Akwa Ibom State University of Technology', value: 'Akwa Ibom State University of Technology' },
  { label: 'Al Ahlia University', value: 'Al Ahlia University' },
  { label: 'Al Akhawayn University', value: 'Al Akhawayn University' },
  { label: 'Al al-Bayt University', value: 'Al al-Bayt University' },
  { label: 'Al Azhar University', value: 'Al Azhar University' },
  { label: 'Al Fashir University', value: 'Al Fashir University' },
  { label: 'Al Ghurair University', value: 'Al Ghurair University' },
  { label: 'Al Hussein Bin Talal University', value: 'Al Hussein Bin Talal University' },
  { label: 'Al Imam Al-Ouzai University', value: 'Al Imam Al-Ouzai University' },
  { label: 'Al Khawarizmi International College', value: 'Al Khawarizmi International College' },
  { label: 'Al Maarif University College', value: 'Al Maarif University College' },
  { label: 'Al Madinah International University', value: 'Al Madinah International University' },
  { label: 'Al Mamon University College', value: 'Al Mamon University College' },
  { label: 'Al Mansour University College', value: 'Al Mansour University College' },
  { label: 'Al Muthanna University', value: 'Al Muthanna University' },
  { label: 'Al Nahrain University', value: 'Al Nahrain University' },
  { label: 'Al Rafidain University College', value: 'Al Rafidain University College' },
  { label: 'Al Rasheed University College', value: 'Al Rasheed University College' },
  { label: 'Al Turath University College', value: 'Al Turath University College' },
  { label: 'Al Yarmouk University College', value: 'Al Yarmouk University College' },
  { label: 'Al-Ahliyya Amman University', value: 'Al-Ahliyya Amman University' },
  { label: 'Al-Aqsa University', value: 'Al-Aqsa University' },
  { label: 'Al-Asmarya University for Islamic Studies', value: 'Al-Asmarya University for Islamic Studies' },
  { label: 'Al-Azhar University of Gaza', value: 'Al-Azhar University of Gaza' },
  { label: 'Al-Baath University', value: 'Al-Baath University' },
  { label: 'Al-baha University', value: 'Al-baha University' },
  { label: 'Al-Balqa Applied University', value: 'Al-Balqa Applied University' },
  { label: 'Al-Batterjee Medical College', value: 'Al-Batterjee Medical College' },
  { label: 'Al-Birony University', value: 'Al-Birony University' },
  { label: 'Al-Bukhari International University', value: 'Al-Bukhari International University' },
  { label: 'Al-Buraimi University College', value: 'Al-Buraimi University College' },
  { label: 'Al-Eman University', value: 'Al-Eman University' },
  { label: 'Al-Farabi Kazakh National University', value: 'Al-Farabi Kazakh National University' },
  { label: 'Al-hikmah University', value: 'Al-hikmah University' },
  { label: 'Al-Imam Mohamed Ibn Saud Islamic University', value: 'Al-Imam Mohamed Ibn Saud Islamic University' },
  { label: 'Al-Islah University', value: 'Al-Islah University' },
  { label: 'Al-Isra University', value: 'Al-Isra University' },
  { label: 'Al-Jabal Al-Gharbi University', value: 'Al-Jabal Al-Gharbi University' },
  { label: 'Al-Khair University', value: 'Al-Khair University' },
  { label: 'Al-Manar University', value: 'Al-Manar University' },
  { label: 'Al-Nasser University', value: 'Al-Nasser University' },
  { label: 'Al-Quds Open University', value: 'Al-Quds Open University' },
  {
    label: 'Al-Quds University - The Arab University in Jerusalem',
    value: 'Al-Quds University - The Arab University in Jerusalem',
  },
  { label: 'Al-Wataniya Private University', value: 'Al-Wataniya Private University' },
  { label: 'Al-Yamamah College', value: 'Al-Yamamah College' },
  { label: 'Al-Zaiem Al-Azhari University', value: 'Al-Zaiem Al-Azhari University' },
  { label: 'Al-Zaytoonah University', value: 'Al-Zaytoonah University' },
  { label: 'Alabama Agricultural and Mechanical University', value: 'Alabama Agricultural and Mechanical University' },
  { label: 'Alabama State University', value: 'Alabama State University' },
  { label: 'Alagappa University', value: 'Alagappa University' },
  { label: 'Alahgaff University', value: 'Alahgaff University' },
  { label: 'Alain University of Science and Technology', value: 'Alain University of Science and Technology' },
  { label: 'Alaska Bible College', value: 'Alaska Bible College' },
  { label: 'Alaska Pacific University', value: 'Alaska Pacific University' },
  { label: 'Albany College of Pharmacy', value: 'Albany College of Pharmacy' },
  { label: 'Albany Law School', value: 'Albany Law School' },
  { label: 'Albany Medical Center', value: 'Albany Medical Center' },
  { label: 'Albany State University', value: 'Albany State University' },
  { label: 'Albert-Ludwigs-Universität Freiburg', value: 'Albert-Ludwigs-Universität Freiburg' },
  { label: 'Albertus Magnus College', value: 'Albertus Magnus College' },
  { label: 'Albion College', value: 'Albion College' },
  { label: 'Albright College', value: 'Albright College' },
  { label: 'Alcorn State University', value: 'Alcorn State University' },
  { label: 'Alderson Broaddus College', value: 'Alderson Broaddus College' },
  {
    label: 'Aleksander Gieysztor School of Humanities in Pultusk',
    value: 'Aleksander Gieysztor School of Humanities in Pultusk',
  },
  { label: 'Aleksander Zelwerowicz State Theatre Academy', value: 'Aleksander Zelwerowicz State Theatre Academy' },
  { label: 'Aletheia University', value: 'Aletheia University' },
  { label: 'Alexandria University', value: 'Alexandria University' },
  { label: 'Alfaisal University', value: 'Alfaisal University' },
  { label: 'Alfred Adler Graduate School', value: 'Alfred Adler Graduate School' },
  { label: 'Alfred Nobel University of Economics and Law ', value: 'Alfred Nobel University of Economics and Law ' },
  { label: 'Alfred University', value: 'Alfred University' },
  { label: 'Algonquin College', value: 'Algonquin College' },
  { label: 'Alhamd Islamic University', value: 'Alhamd Islamic University' },
  { label: 'Alhosn University', value: 'Alhosn University' },
  { label: 'Alice Lloyd College', value: 'Alice Lloyd College' },
  {
    label: 'Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin',
    value: 'Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin',
  },
  { label: 'Aligarh Muslim University', value: 'Aligarh Muslim University' },
  { label: 'Aljouf University', value: 'Aljouf University' },
  { label: 'Alkharj University', value: 'Alkharj University' },
  { label: 'All India Institute of Medical Sciences', value: 'All India Institute of Medical Sciences' },
  { label: 'All Nations University College', value: 'All Nations University College' },
  {
    label: 'Allahabad Agricultural Institute, Deemed University',
    value: 'Allahabad Agricultural Institute, Deemed University',
  },
  { label: 'Allahabad University', value: 'Allahabad University' },
  { label: 'Allama Iqbal Open University', value: 'Allama Iqbal Open University' },
  { label: 'Allameh Tabatabaie University', value: 'Allameh Tabatabaie University' },
  { label: 'Allegheny College', value: 'Allegheny College' },
  { label: 'Allen University', value: 'Allen University' },
  { label: 'Allianze College of Medical Sciences (ACMS)', value: 'Allianze College of Medical Sciences (ACMS)' },
  { label: 'Alma College', value: 'Alma College' },
  { label: 'Almustafa Open University', value: 'Almustafa Open University' },
  { label: 'Alpha Omega University', value: 'Alpha Omega University' },
  { label: 'Alsadrain University', value: 'Alsadrain University' },
  { label: 'Altai State Medical University', value: 'Altai State Medical University' },
  { label: 'Altai State Technical University', value: 'Altai State Technical University' },
  { label: 'Altai State University', value: 'Altai State University' },
  { label: 'Alvernia College', value: 'Alvernia College' },
  { label: 'Alverno College', value: 'Alverno College' },
  { label: 'Ama International University', value: 'Ama International University' },
  { label: 'Ambassador University', value: 'Ambassador University' },
  { label: 'Amber University', value: 'Amber University' },
  { label: 'Ambo University', value: 'Ambo University' },
  { label: 'Ambrose Alli University', value: 'Ambrose Alli University' },
  { label: 'American Academy of Nutrition', value: 'American Academy of Nutrition' },
  { label: 'American Business & Technology University', value: 'American Business & Technology University' },
  { label: 'American College Of Dubai', value: 'American College Of Dubai' },
  { label: 'American College of Greece', value: 'American College of Greece' },
  { label: 'American College of Thessaloniki', value: 'American College of Thessaloniki' },
  { label: 'American Conservatory of Music', value: 'American Conservatory of Music' },
  { label: 'American Conservatory Theater', value: 'American Conservatory Theater' },
  {
    label: 'American Film Institute Center for Advanced Film and Television Studies',
    value: 'American Film Institute Center for Advanced Film and Television Studies',
  },
  { label: 'American Graduate School in Paris', value: 'American Graduate School in Paris' },
  { label: 'American Indian College', value: 'American Indian College' },
  { label: 'American InterContinental University - Atlanta', value: 'American InterContinental University - Atlanta' },
  {
    label: 'American InterContinental University - Ft. Lauderdale',
    value: 'American InterContinental University - Ft. Lauderdale',
  },
  { label: 'American InterContinental University - Georgia', value: 'American InterContinental University - Georgia' },
  { label: 'American InterContinental University - London', value: 'American InterContinental University - London' },
  { label: 'American InterContinental University Online', value: 'American InterContinental University Online' },
  { label: 'American International College', value: 'American International College' },
  { label: 'American International University - Bangladesh', value: 'American International University - Bangladesh' },
  { label: 'American International University West Africa', value: 'American International University West Africa' },
  { label: 'American Jewish University', value: 'American Jewish University' },
  { label: 'American Military University', value: 'American Military University' },
  { label: 'American Public University', value: 'American Public University' },
  { label: 'American University', value: 'American University' },
  { label: 'American University', value: 'American University' },
  { label: 'American University College of Technology', value: 'American University College of Technology' },
  { label: 'American University Extension, Okinawa', value: 'American University Extension, Okinawa' },
  { label: 'American University in Bulgaria', value: 'American University in Bulgaria' },
  { label: 'American University in Cairo', value: 'American University in Cairo' },
  { label: 'American University in Dubai', value: 'American University in Dubai' },
  { label: 'American University in the Emirates', value: 'American University in the Emirates' },
  { label: 'American University of Afghanistan', value: 'American University of Afghanistan' },
  { label: 'American University of Antigua', value: 'American University of Antigua' },
  { label: 'American University of Armenia', value: 'American University of Armenia' },
  { label: 'American University of Beirut', value: 'American University of Beirut' },
  { label: 'American University of Central Asia', value: 'American University of Central Asia' },
  { label: 'American University of Hawaii, Punjab Campus', value: 'American University of Hawaii, Punjab Campus' },
  { label: 'American University of Kuwait ', value: 'American University of Kuwait ' },
  { label: 'American University of Middle East', value: 'American University of Middle East' },
  { label: 'American University of Paris', value: 'American University of Paris' },
  { label: 'American University of Science and Technology', value: 'American University of Science and Technology' },
  { label: 'American University of Sharjah', value: 'American University of Sharjah' },
  { label: 'American University of the Caribbean', value: 'American University of the Caribbean' },
  {
    label: 'American University of the Caribbean, School of Medicine',
    value: 'American University of the Caribbean, School of Medicine',
  },
  {
    label: 'American University of the Caribbean, Sint Maarten',
    value: 'American University of the Caribbean, Sint Maarten',
  },
  { label: 'American University of Tirana', value: 'American University of Tirana' },
  { label: 'American World University', value: 'American World University' },
  { label: 'American-European School of Management ', value: 'American-European School of Management ' },
  { label: 'Americanos College', value: 'Americanos College' },
  { label: 'Amherst College', value: 'Amherst College' },
  { label: 'Amirkabir College of Managmant & Technology', value: 'Amirkabir College of Managmant & Technology' },
  { label: 'Amirkabir University of Technology', value: 'Amirkabir University of Technology' },
  { label: 'Amity University', value: 'Amity University' },
  { label: 'Amman Arab University for Higher Studies', value: 'Amman Arab University for Higher Studies' },
  { label: 'Amman University', value: 'Amman University' },
  { label: 'Amoud University', value: 'Amoud University' },
  { label: 'Amravati University', value: 'Amravati University' },
  { label: 'Amrita Vishwa Vidyapeetham (Deemed University)', value: 'Amrita Vishwa Vidyapeetham (Deemed University)' },
  { label: 'Amur State University', value: 'Amur State University' },
  { label: 'An-Najah National University', value: 'An-Najah National University' },
  { label: 'Anadolu University', value: 'Anadolu University' },
  {
    label: 'Anambra State University of Science and Technology',
    value: 'Anambra State University of Science and Technology',
  },
  { label: 'Anderson College', value: 'Anderson College' },
  { label: 'Anderson University', value: 'Anderson University' },
  { label: 'Andhra University', value: 'Andhra University' },
  { label: 'Andon College - Modesto', value: 'Andon College - Modesto' },
  { label: 'Andon College - Stockton', value: 'Andon College - Stockton' },
  { label: 'Andong National University', value: 'Andong National University' },
  {
    label: 'Andrassy Gyula German Speaking University Budapest',
    value: 'Andrassy Gyula German Speaking University Budapest',
  },
  { label: 'Andrew Jackson University', value: 'Andrew Jackson University' },
  { label: 'Andrews University', value: 'Andrews University' },
  { label: 'Andrzej Frycz Modrzewski Cracow College', value: 'Andrzej Frycz Modrzewski Cracow College' },
  { label: 'Angeles University', value: 'Angeles University' },
  { label: 'Angelo State University', value: 'Angelo State University' },
  { label: 'Angkor University', value: 'Angkor University' },
  { label: 'Anglia Ruskin University', value: 'Anglia Ruskin University' },
  { label: 'Anglo-American University', value: 'Anglo-American University' },
  { label: 'Anhui Medical University', value: 'Anhui Medical University' },
  { label: 'Anhui Normal University', value: 'Anhui Normal University' },
  {
    label: 'Anhui Technical College of Water Resources and Hydroelectric Power',
    value: 'Anhui Technical College of Water Resources and Hydroelectric Power',
  },
  { label: 'Anhui University', value: 'Anhui University' },
  { label: 'Anhui University of Finance and Economics', value: 'Anhui University of Finance and Economics' },
  {
    label: 'Anhui University of Traditional Chinese Medicine',
    value: 'Anhui University of Traditional Chinese Medicine',
  },
  { label: 'Ankara University', value: 'Ankara University' },
  { label: 'Anna Maria College', value: 'Anna Maria College' },
  { label: 'Anna University', value: 'Anna University' },
  { label: 'Anna University of Technology, Tirunelveli', value: 'Anna University of Technology, Tirunelveli' },
  { label: 'Annamalai University', value: 'Annamalai University' },
  { label: 'Antioch New England Graduate School', value: 'Antioch New England Graduate School' },
  { label: 'Antioch University', value: 'Antioch University' },
  { label: 'Antioch University Los Angeles', value: 'Antioch University Los Angeles' },
  { label: 'Antioch University Santa Barbara', value: 'Antioch University Santa Barbara' },
  { label: 'Antioch University Seattle', value: 'Antioch University Seattle' },
  { label: 'Anton de Kom University', value: 'Anton de Kom University' },
  { label: 'Aomori Chuoh Gakuin University', value: 'Aomori Chuoh Gakuin University' },
  { label: 'Aomori Public College', value: 'Aomori Public College' },
  { label: 'Aomori University', value: 'Aomori University' },
  { label: 'Aomori University of Health and Welfare', value: 'Aomori University of Health and Welfare' },
  { label: 'Aoyama Gakuin University', value: 'Aoyama Gakuin University' },
  { label: 'Appalachian Bible College', value: 'Appalachian Bible College' },
  { label: 'Appalachian State University', value: 'Appalachian State University' },
  { label: 'Applied Science University', value: 'Applied Science University' },
  { label: 'Applied Science University', value: 'Applied Science University' },
  { label: 'Aquinas College', value: 'Aquinas College' },
  { label: 'Aquinas University', value: 'Aquinas University' },
  {
    label: 'Arab Academy for Banking and Financial Sciences',
    value: 'Arab Academy for Banking and Financial Sciences',
  },
  { label: 'Arab Academy for Science & Technology', value: 'Arab Academy for Science & Technology' },
  { label: 'Arab American University - Jenin', value: 'Arab American University - Jenin' },
  { label: 'Arab European University', value: 'Arab European University' },
  { label: 'Arab Open University', value: 'Arab Open University' },
  { label: 'Arab Open University', value: 'Arab Open University' },
  { label: 'Arab Open University', value: 'Arab Open University' },
  { label: 'Arab Open University', value: 'Arab Open University' },
  { label: 'Arab Open University', value: 'Arab Open University' },
  { label: 'Arab Open University', value: 'Arab Open University' },
  { label: 'Arab Open University, Kuwait Branch', value: 'Arab Open University, Kuwait Branch' },
  { label: 'Arabian Gulf University', value: 'Arabian Gulf University' },
  { label: 'Arak University', value: 'Arak University' },
  { label: 'Arak University of Medical Sciences', value: 'Arak University of Medical Sciences' },
  { label: 'Arak University of Technology', value: 'Arak University of Technology' },
  { label: 'Araullo University', value: 'Araullo University' },
  { label: 'Arba Minch University', value: 'Arba Minch University' },
  { label: 'Arcadia University', value: 'Arcadia University' },
  { label: 'Ardabil University of Medical Sciences', value: 'Ardabil University of Medical Sciences' },
  { label: 'Arellano University', value: 'Arellano University' },
  { label: 'Argosy University', value: 'Argosy University' },
  { label: 'Argosy University - Hawaii', value: 'Argosy University - Hawaii' },
  { label: 'Arhangelsk State Technical University', value: 'Arhangelsk State Technical University' },
  { label: 'Aria Institute of Higher Education', value: 'Aria Institute of Higher Education' },
  { label: 'Arid Agriculture University', value: 'Arid Agriculture University' },
  { label: 'Ariel University Center of Samaria', value: 'Ariel University Center of Samaria' },
  { label: 'Aristotle University of Thessaloniki', value: 'Aristotle University of Thessaloniki' },
  { label: 'Arizona Christian University', value: 'Arizona Christian University' },
  { label: 'Arizona State University', value: 'Arizona State University' },
  {
    label: 'Arizona State University, Downtown Phoenix Campus',
    value: 'Arizona State University, Downtown Phoenix Campus',
  },
  { label: 'Arizona State University, Polytechnic Campus', value: 'Arizona State University, Polytechnic Campus' },
  { label: 'Arizona State University, Tempe Campus', value: 'Arizona State University, Tempe Campus' },
  { label: 'Arizona State University, West Campus', value: 'Arizona State University, West Campus' },
  { label: 'Arkansas State University', value: 'Arkansas State University' },
  { label: 'Arkansas State University, Beebe', value: 'Arkansas State University, Beebe' },
  { label: 'Arkansas State University, Mountain Home', value: 'Arkansas State University, Mountain Home' },
  { label: 'Arkansas State University, Newport', value: 'Arkansas State University, Newport' },
  { label: 'Arkansas Tech University', value: 'Arkansas Tech University' },
  { label: 'Arlington Baptist College', value: 'Arlington Baptist College' },
  {
    label: 'Armed Forces Academy of General Milan Rastislav Štefánik',
    value: 'Armed Forces Academy of General Milan Rastislav Štefánik',
  },
  { label: 'Armenian State Agrarian University', value: 'Armenian State Agrarian University' },
  { label: 'Armenian State University of Economics', value: 'Armenian State University of Economics' },
  { label: 'Armstrong Atlantic State University', value: 'Armstrong Atlantic State University' },
  { label: 'Armstrong University', value: 'Armstrong University' },
  { label: 'Art Academy of Cincinnati', value: 'Art Academy of Cincinnati' },
  { label: 'Art Center College of Design', value: 'Art Center College of Design' },
  { label: 'Art Institute of Charlotte', value: 'Art Institute of Charlotte' },
  { label: 'Art Institute of Southern California', value: 'Art Institute of Southern California' },
  { label: 'Art University', value: 'Art University' },
  {
    label: 'Arthur C Clarke Institute of Modern Technologies',
    value: 'Arthur C Clarke Institute of Modern Technologies',
  },
  {
    label: 'Arthur D. Little Management Education Institute',
    value: 'Arthur D. Little Management Education Institute',
  },
  { label: 'Arts, Sciences and Technology University', value: 'Arts, Sciences and Technology University' },
  { label: 'ARYA Institute of Engineering & Technology', value: 'ARYA Institute of Engineering & Technology' },
  { label: 'Asa University Bangladesh', value: 'Asa University Bangladesh' },
  { label: 'Asahi University', value: 'Asahi University' },
  { label: 'Asahikawa Medical College', value: 'Asahikawa Medical College' },
  { label: 'Asahikawa University', value: 'Asahikawa University' },
  { label: 'Asbury College', value: 'Asbury College' },
  { label: 'Ashesi University', value: 'Ashesi University' },
  { label: 'Ashikaga Institute of Technology', value: 'Ashikaga Institute of Technology' },
  { label: 'Ashiya University', value: 'Ashiya University' },
  { label: 'Ashkelon Academic College', value: 'Ashkelon Academic College' },
  { label: 'Ashland University', value: 'Ashland University' },
  { label: 'Ashton College', value: 'Ashton College' },
  { label: 'Asia E University', value: 'Asia E University' },
  { label: 'Asia Europe University', value: 'Asia Europe University' },
  {
    label: 'Asia Pacific Institute of Information Technology (APIIT)',
    value: 'Asia Pacific Institute of Information Technology (APIIT)',
  },
  {
    label: 'Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)',
    value: 'Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)',
  },
  { label: 'Asia University', value: 'Asia University' },
  { label: 'Asian Business School', value: 'Asian Business School' },
  { label: 'Asian Institute of Technology', value: 'Asian Institute of Technology' },
  { label: 'Asian Management Institute', value: 'Asian Management Institute' },
  { label: 'Asian Medical Institute', value: 'Asian Medical Institute' },
  { label: 'Asian University of Bangladesh', value: 'Asian University of Bangladesh' },
  { label: 'Asian University of Science and Technology', value: 'Asian University of Science and Technology' },
  { label: 'Assam Agricultural University', value: 'Assam Agricultural University' },
  { label: 'Assam University', value: 'Assam University' },
  { label: 'Assiut University', value: 'Assiut University' },
  { label: 'Assosa University', value: 'Assosa University' },
  { label: 'Assumption College', value: 'Assumption College' },
  { label: 'Assumption University', value: 'Assumption University' },
  { label: 'Assumption University of Thailand', value: 'Assumption University of Thailand' },
  { label: 'Aston University', value: 'Aston University' },
  { label: 'Astrahan State Medical Academy', value: 'Astrahan State Medical Academy' },
  { label: 'Astrahan State Technical University', value: 'Astrahan State Technical University' },
  { label: 'Astrakhan State University', value: 'Astrakhan State University' },
  { label: 'Atatürk University', value: 'Atatürk University' },
  { label: 'Ateneo de Davao University', value: 'Ateneo de Davao University' },
  { label: 'Ateneo de Manila University', value: 'Ateneo de Manila University' },
  { label: 'Ateneo de Naga University', value: 'Ateneo de Naga University' },
  { label: 'Ateneo de Zamboanga University', value: 'Ateneo de Zamboanga University' },
  { label: 'Athabasca University', value: 'Athabasca University' },
  { label: 'Athenaeum of Ohio', value: 'Athenaeum of Ohio' },
  { label: 'Athenaeum Pontificium Regina Apostolorum', value: 'Athenaeum Pontificium Regina Apostolorum' },
  { label: 'Athens Graduate School of Management (AGSM)', value: 'Athens Graduate School of Management (AGSM)' },
  {
    label: 'Athens Laboratory of Business Administration (ALBA)',
    value: 'Athens Laboratory of Business Administration (ALBA)',
  },
  { label: 'Athens School of Fine Arts', value: 'Athens School of Fine Arts' },
  { label: 'Athens State College', value: 'Athens State College' },
  { label: 'Athens University of Economics and Business', value: 'Athens University of Economics and Business' },
  { label: 'Athlone Institute of Technology', value: 'Athlone Institute of Technology' },
  { label: 'Atilim University', value: 'Atilim University' },
  { label: 'Atish Dipankar University', value: 'Atish Dipankar University' },
  { label: 'Atlanta Christian College', value: 'Atlanta Christian College' },
  { label: 'Atlanta College of Art', value: 'Atlanta College of Art' },
  { label: 'Atlantic International University', value: 'Atlantic International University' },
  { label: 'Atlantic Union College', value: 'Atlantic Union College' },
  { label: 'Atlantic University', value: 'Atlantic University' },
  { label: 'Atomi College', value: 'Atomi College' },
  { label: 'Atyrau State University', value: 'Atyrau State University' },
  { label: 'Auburn University', value: 'Auburn University' },
  { label: 'Auburn University at Montgomery', value: 'Auburn University at Montgomery' },
  { label: 'Auchi Polytechnic', value: 'Auchi Polytechnic' },
  { label: 'Auckland University of Technology', value: 'Auckland University of Technology' },
  { label: 'Audrey Cohen College', value: 'Audrey Cohen College' },
  { label: 'Augsburg College', value: 'Augsburg College' },
  { label: 'Augusta State University', value: 'Augusta State University' },
  { label: 'Augustana College', value: 'Augustana College' },
  { label: 'Augustana College', value: 'Augustana College' },
  { label: 'Augustana Hochschule Neuendettelsau', value: 'Augustana Hochschule Neuendettelsau' },
  { label: 'Augustana University College', value: 'Augustana University College' },
  { label: 'Aurora University', value: 'Aurora University' },
  { label: 'Austin College', value: 'Austin College' },
  { label: 'Austin Community College', value: 'Austin Community College' },
  { label: 'Austin Peay State University', value: 'Austin Peay State University' },
  { label: 'Australian Catholic University', value: 'Australian Catholic University' },
  { label: 'Australian Correspondence Schools', value: 'Australian Correspondence Schools' },
  { label: 'Australian Defence Force Academy', value: 'Australian Defence Force Academy' },
  { label: 'Australian Lutheran College', value: 'Australian Lutheran College' },
  { label: 'Australian Maritime College', value: 'Australian Maritime College' },
  { label: 'Australian National University', value: 'Australian National University' },
  { label: 'Australlian College of Kuwait ', value: 'Australlian College of Kuwait ' },
  { label: 'Ave Maria University', value: 'Ave Maria University' },
  { label: 'Aventis School of Management', value: 'Aventis School of Management' },
  { label: 'Averett College', value: 'Averett College' },
  { label: 'Avicenna International College', value: 'Avicenna International College' },
  { label: 'Avila College', value: 'Avila College' },
  {
    label: 'Avinashilingam Institute for Home Science and Higher Education for Women',
    value: 'Avinashilingam Institute for Home Science and Higher Education for Women',
  },
  { label: 'Avondale College', value: 'Avondale College' },
  { label: 'Awadhesh Pratap Singh University', value: 'Awadhesh Pratap Singh University' },
  { label: 'Azabu University', value: 'Azabu University' },
  { label: 'Azad Jammu and Kashmir University', value: 'Azad Jammu and Kashmir University' },
  { label: 'Azerbaijan Diplomatic Academy', value: 'Azerbaijan Diplomatic Academy' },
  { label: 'Azerbaijan International University', value: 'Azerbaijan International University' },
  { label: 'Azerbaijan Medical University', value: 'Azerbaijan Medical University' },
  { label: 'Azerbaijan National Conservatorie', value: 'Azerbaijan National Conservatorie' },
  { label: 'Azerbaijan State Economic University', value: 'Azerbaijan State Economic University' },
  { label: 'Azerbaijan State Oil Academy', value: 'Azerbaijan State Oil Academy' },
  { label: 'Azerbaijan Technical University', value: 'Azerbaijan Technical University' },
  { label: 'Azerbaijan Technology University', value: 'Azerbaijan Technology University' },
  { label: 'Azerbaijan Toursim Institute', value: 'Azerbaijan Toursim Institute' },
  { label: 'Azerbaijan University', value: 'Azerbaijan University' },
  { label: 'Azerbaijan University ol Languages', value: 'Azerbaijan University ol Languages' },
  { label: 'Azusa Pacific University', value: 'Azusa Pacific University' },
  { label: 'Azzahra University', value: 'Azzahra University' },
  { label: 'B. R. Ambedkar Bihar University', value: 'B. R. Ambedkar Bihar University' },
  { label: 'B. S. Abdur Rahman University', value: 'B. S. Abdur Rahman University' },
  { label: 'B.P.Koirala Institute of Health Sciences', value: 'B.P.Koirala Institute of Health Sciences' },
  { label: 'Babasaheb Bhimrao Ambedkar University', value: 'Babasaheb Bhimrao Ambedkar University' },
  { label: 'Babcock University', value: 'Babcock University' },
  { label: 'Babes-Bolyai University of Cluj-Napoca', value: 'Babes-Bolyai University of Cluj-Napoca' },
  { label: 'Babol Noshirvani University of Technology', value: 'Babol Noshirvani University of Technology' },
  { label: 'Babol University of Medical Sciences', value: 'Babol University of Medical Sciences' },
  { label: 'Babson College', value: 'Babson College' },
  { label: 'Badakhshan University', value: 'Badakhshan University' },
  {
    label: 'Baghdad College of Economic Sciences University',
    value: 'Baghdad College of Economic Sciences University',
  },
  { label: 'Baghdad College of Pharmacy', value: 'Baghdad College of Pharmacy' },
  { label: 'Baghlan University', value: 'Baghlan University' },
  { label: 'Baghyatoolah Medical Sciences University', value: 'Baghyatoolah Medical Sciences University' },
  { label: "Baha'i Institute for Higher Education", value: "Baha'i Institute for Higher Education" },
  { label: 'Bahauddin Zakariya University, Multan', value: 'Bahauddin Zakariya University, Multan' },
  { label: 'Bahcesehir University', value: 'Bahcesehir University' },
  { label: 'Bahir Dar University', value: 'Bahir Dar University' },
  { label: 'Bahrain Polytechnic', value: 'Bahrain Polytechnic' },
  { label: 'Bahria University', value: 'Bahria University' },
  { label: "Baika Women's College", value: "Baika Women's College" },
  {
    label: 'Baikal National University of Economics and Law',
    value: 'Baikal National University of Economics and Law',
  },
  { label: "Baiko Women's College", value: "Baiko Women's College" },
  { label: 'Baitulmal Management Institute (IPB)', value: 'Baitulmal Management Institute (IPB)' },
  { label: 'Baker College of Auburn Hills', value: 'Baker College of Auburn Hills' },
  { label: 'Baker College of Cadillac', value: 'Baker College of Cadillac' },
  { label: 'Baker College of Flint', value: 'Baker College of Flint' },
  { label: 'Baker College of Mount Clemens', value: 'Baker College of Mount Clemens' },
  { label: 'Baker College of Muskegon', value: 'Baker College of Muskegon' },
  { label: 'Baker College of Owosso', value: 'Baker College of Owosso' },
  { label: 'Baker College of Port Huron', value: 'Baker College of Port Huron' },
  { label: 'Baker University', value: 'Baker University' },
  { label: 'Bakht Er-Ruda University', value: 'Bakht Er-Ruda University' },
  { label: 'Bakhtar University', value: 'Bakhtar University' },
  { label: 'Baki Business University', value: 'Baki Business University' },
  { label: 'Baku Slavic University', value: 'Baku Slavic University' },
  { label: 'Baku State University', value: 'Baku State University' },
  { label: 'Balamand University', value: 'Balamand University' },
  { label: 'Baldwin-Wallace College', value: 'Baldwin-Wallace College' },
  { label: 'Balikesir University', value: 'Balikesir University' },
  { label: 'Balkh University', value: 'Balkh University' },
  { label: 'Ball State University', value: 'Ball State University' },
  { label: 'Ballsbridge University ', value: 'Ballsbridge University ' },
  {
    label: 'Balochistan University of Engineering and Technology Khuzdar',
    value: 'Balochistan University of Engineering and Technology Khuzdar',
  },
  {
    label: 'Balochistan University of Information Technology & Management Sciences ',
    value: 'Balochistan University of Information Technology & Management Sciences ',
  },
  { label: 'Balqa Applied University', value: 'Balqa Applied University' },
  { label: 'Balti State University Alecu Russo', value: 'Balti State University Alecu Russo' },
  { label: 'Baltic International Academy', value: 'Baltic International Academy' },
  { label: 'Baltic State Technical University', value: 'Baltic State Technical University' },
  { label: 'Baltimore Hebrew University', value: 'Baltimore Hebrew University' },
  { label: 'Baltimore International College', value: 'Baltimore International College' },
  { label: 'Bamenda University of Science & Technology', value: 'Bamenda University of Science & Technology' },
  { label: 'Bamiyan University', value: 'Bamiyan University' },
  { label: 'Banaras Hindu University', value: 'Banaras Hindu University' },
  { label: 'Banasthali University', value: 'Banasthali University' },
  { label: "Banat's University of Agricultural Sciences", value: "Banat's University of Agricultural Sciences" },
  {
    label: 'Bangabandhu Sheikh Mujibur Rahman Agricultural University',
    value: 'Bangabandhu Sheikh Mujibur Rahman Agricultural University',
  },
  {
    label: 'Bangabandhu Sheikh Mujibur Rahman Medical University',
    value: 'Bangabandhu Sheikh Mujibur Rahman Medical University',
  },
  { label: 'Bangalore University', value: 'Bangalore University' },
  { label: 'Bangkok University', value: 'Bangkok University' },
  { label: 'Bangladesh Agricultural University', value: 'Bangladesh Agricultural University' },
  { label: 'Bangladesh Open University', value: 'Bangladesh Open University' },
  { label: 'Bangladesh University', value: 'Bangladesh University' },
  { label: 'Bangladesh University of Business & Technology', value: 'Bangladesh University of Business & Technology' },
  {
    label: 'Bangladesh University of Engineering and Technology',
    value: 'Bangladesh University of Engineering and Technology',
  },
  { label: 'Bangladesh University of Professionals', value: 'Bangladesh University of Professionals' },
  { label: 'Bangladesh University of Textiles', value: 'Bangladesh University of Textiles' },
  { label: 'Bank Street College of Education', value: 'Bank Street College of Education' },
  { label: 'Banking University of Ho Chi Minh City', value: 'Banking University of Ho Chi Minh City' },
  { label: 'Bankura University', value: 'Bankura University' },
  { label: 'Baptist Bible College of Missouri', value: 'Baptist Bible College of Missouri' },
  { label: 'Baptist Bible College of Pennsylvania', value: 'Baptist Bible College of Pennsylvania' },
  { label: 'Baqai Medical University', value: 'Baqai Medical University' },
  { label: 'Bar-Ilan University', value: 'Bar-Ilan University' },
  { label: 'Barat College', value: 'Barat College' },
  { label: 'Barber-Scotia College', value: 'Barber-Scotia College' },
  { label: 'Barcelona Graduate School of Economics', value: 'Barcelona Graduate School of Economics' },
  { label: 'Barclay College', value: 'Barclay College' },
  { label: 'Bard College', value: 'Bard College' },
  {
    label: 'Bard Graduate Center for Studies in the Decorative Arts',
    value: 'Bard Graduate Center for Studies in the Decorative Arts',
  },
  { label: 'Barkatullah University', value: 'Barkatullah University' },
  { label: 'Barnard College - Columbia University', value: 'Barnard College - Columbia University' },
  { label: 'Barry University', value: 'Barry University' },
  { label: 'Bartlesville Wesleyan College', value: 'Bartlesville Wesleyan College' },
  { label: 'Barton College', value: 'Barton College' },
  { label: 'Bashkir State Agrarian University', value: 'Bashkir State Agrarian University' },
  { label: 'Bashkir State Medical University', value: 'Bashkir State Medical University' },
  {
    label: 'Bashkir State Pedagogical University named After M. Akmullah',
    value: 'Bashkir State Pedagogical University named After M. Akmullah',
  },
  { label: 'Bashkir State University', value: 'Bashkir State University' },
  { label: 'Basilicata University Potenza', value: 'Basilicata University Potenza' },
  { label: 'Baskent University', value: 'Baskent University' },
  { label: 'Bastyr University', value: 'Bastyr University' },
  {
    label: 'Batchelor Institute of Indigenous Tertiary Education',
    value: 'Batchelor Institute of Indigenous Tertiary Education',
  },
  { label: 'Bates College', value: 'Bates College' },
  { label: 'Batterjee Medical College', value: 'Batterjee Medical College' },
  { label: 'Bauchi State University, Gadau', value: 'Bauchi State University, Gadau' },
  { label: 'Bauder College', value: 'Bauder College' },
  { label: 'Bauhaus Universität Weimar', value: 'Bauhaus Universität Weimar' },
  { label: 'Bauman Moscow State Technical University', value: 'Bauman Moscow State Technical University' },
  { label: 'Bayan College for Science & Technology', value: 'Bayan College for Science & Technology' },
  {
    label: 'Bayerische Julius-Maximilians-Universität Würzburg',
    value: 'Bayerische Julius-Maximilians-Universität Würzburg',
  },
  { label: 'Bayero University Kano', value: 'Bayero University Kano' },
  { label: 'Baylor College of Dentistry', value: 'Baylor College of Dentistry' },
  { label: 'Baylor College of Medicine', value: 'Baylor College of Medicine' },
  { label: 'Baylor University', value: 'Baylor University' },
  { label: 'Baze University Abuja', value: 'Baze University Abuja' },
  { label: 'Beder University', value: 'Beder University' },
  { label: 'Begum Rokeya University, Rangpur', value: 'Begum Rokeya University, Rangpur' },
  { label: 'Beijing Foreign Studies University', value: 'Beijing Foreign Studies University' },
  { label: 'Beijing Forestry University', value: 'Beijing Forestry University' },
  {
    label: 'Beijing Information Science and Technology University',
    value: 'Beijing Information Science and Technology University',
  },
  { label: 'Beijing Institute of Technology', value: 'Beijing Institute of Technology' },
  { label: 'Beijing International Studies University', value: 'Beijing International Studies University' },
  { label: 'Beijing Language and Culture University', value: 'Beijing Language and Culture University' },
  { label: 'Beijing Medical University', value: 'Beijing Medical University' },
  { label: 'Beijing New Asia University', value: 'Beijing New Asia University' },
  { label: 'Beijing Normal University', value: 'Beijing Normal University' },
  { label: 'Beijing Petroleum University', value: 'Beijing Petroleum University' },
  { label: 'Beijing Polytechnic University', value: 'Beijing Polytechnic University' },
  { label: 'Beijing Sport University', value: 'Beijing Sport University' },
  { label: 'Beijing Union University', value: 'Beijing Union University' },
  {
    label: 'Beijing University of Aeronautics and Astronautics',
    value: 'Beijing University of Aeronautics and Astronautics',
  },
  { label: 'Beijing University of Agriculture', value: 'Beijing University of Agriculture' },
  { label: 'Beijing University of Chemical Technology', value: 'Beijing University of Chemical Technology' },
  {
    label: 'Beijing University of Chinese Medicine and Pharmacology',
    value: 'Beijing University of Chinese Medicine and Pharmacology',
  },
  {
    label: 'Beijing University of Posts and Telecommunications',
    value: 'Beijing University of Posts and Telecommunications',
  },
  { label: 'Beijing University of Science and Technology', value: 'Beijing University of Science and Technology' },
  { label: 'Beirut Arab University', value: 'Beirut Arab University' },
  { label: 'Belarusian-Russian University', value: 'Belarusian-Russian University' },
  { label: 'Belarussian National Technical University', value: 'Belarussian National Technical University' },
  { label: 'Belarussian State Academy of Music', value: 'Belarussian State Academy of Music' },
  {
    label: 'Belarussian State Agrarian Technical University',
    value: 'Belarussian State Agrarian Technical University',
  },
  { label: 'Belarussian State Agricultural Academy', value: 'Belarussian State Agricultural Academy' },
  { label: 'Belarussian State Economic University', value: 'Belarussian State Economic University' },
  { label: 'Belarussian State Medical University', value: 'Belarussian State Medical University' },
  {
    label: 'Belarussian State Pedagogical University M. Tanka',
    value: 'Belarussian State Pedagogical University M. Tanka',
  },
  { label: 'Belarussian State Polytechnical Academy', value: 'Belarussian State Polytechnical Academy' },
  { label: 'Belarussian State Technological University', value: 'Belarussian State Technological University' },
  { label: 'Belarussian State University', value: 'Belarussian State University' },
  {
    label: 'Belarussian State University of Culture and Arts',
    value: 'Belarussian State University of Culture and Arts',
  },
  {
    label: 'Belarussian State University of Informatics and Radioelectronics',
    value: 'Belarussian State University of Informatics and Radioelectronics',
  },
  { label: 'Belarussian State University of Transport', value: 'Belarussian State University of Transport' },
  { label: 'Belgorod State Agricultural Academy', value: 'Belgorod State Agricultural Academy' },
  { label: 'Belgorod State Technical University', value: 'Belgorod State Technical University' },
  { label: 'Belgorod State University', value: 'Belgorod State University' },
  { label: 'Belhaven College', value: 'Belhaven College' },
  { label: 'Bellarmine College', value: 'Bellarmine College' },
  { label: 'Bellevue University', value: 'Bellevue University' },
  { label: 'Bellin College of Nursing', value: 'Bellin College of Nursing' },
  { label: 'Bells University of Technology', value: 'Bells University of Technology' },
  { label: 'Belmont Abbey College', value: 'Belmont Abbey College' },
  { label: 'Belmont University', value: 'Belmont University' },
  { label: 'Beloit College', value: 'Beloit College' },
  { label: 'Bemidji State Univeristy', value: 'Bemidji State Univeristy' },
  { label: 'Ben-Gurion University of the Negev', value: 'Ben-Gurion University of the Negev' },
  { label: 'Benadir University', value: 'Benadir University' },
  { label: 'Benedict College', value: 'Benedict College' },
  { label: 'Benedictine College', value: 'Benedictine College' },
  { label: 'Benedictine University', value: 'Benedictine University' },
  {
    label: 'Benedictine University, Springfield College in Illinois',
    value: 'Benedictine University, Springfield College in Illinois',
  },
  { label: 'Benemerita Universidad Autónoma de Puebla', value: 'Benemerita Universidad Autónoma de Puebla' },
  { label: 'Bengal Engineering College', value: 'Bengal Engineering College' },
  { label: 'Benguet State University', value: 'Benguet State University' },
  { label: 'Benha University', value: 'Benha University' },
  { label: 'Beni Suef University', value: 'Beni Suef University' },
  { label: 'Bennett College', value: 'Bennett College' },
  { label: 'Bennington College', value: 'Bennington College' },
  { label: 'Benson Idahosa University', value: 'Benson Idahosa University' },
  { label: 'Bentley College', value: 'Bentley College' },
  { label: 'Benue State University', value: 'Benue State University' },
  { label: 'Beppu University', value: 'Beppu University' },
  { label: 'Berea College', value: 'Berea College' },
  { label: 'Berean University of the Assemblies of God', value: 'Berean University of the Assemblies of God' },
  { label: 'Bergen University College', value: 'Bergen University College' },
  {
    label: 'Bergische Universität Gesamthochschule Wuppertal',
    value: 'Bergische Universität Gesamthochschule Wuppertal',
  },
  { label: 'Berhampur University', value: 'Berhampur University' },
  { label: 'Berklee College of Music', value: 'Berklee College of Music' },
  { label: 'Bermuda College', value: 'Bermuda College' },
  { label: 'Berne University', value: 'Berne University' },
  { label: 'Berry College', value: 'Berry College' },
  { label: 'Beth-El College of Nursing and Health Sciences', value: 'Beth-El College of Nursing and Health Sciences' },
  { label: 'Bethany College California', value: 'Bethany College California' },
  { label: 'Bethany College Kansas', value: 'Bethany College Kansas' },
  { label: 'Bethany College West Virginia', value: 'Bethany College West Virginia' },
  { label: 'Bethel College McKenzie', value: 'Bethel College McKenzie' },
  { label: 'Bethel College Mishawaka', value: 'Bethel College Mishawaka' },
  { label: 'Bethel College Newton', value: 'Bethel College Newton' },
  { label: 'Bethel College St. Paul', value: 'Bethel College St. Paul' },
  { label: 'Bethlehem University', value: 'Bethlehem University' },
  { label: 'Bethune-Cookman College', value: 'Bethune-Cookman College' },
  { label: 'Beykent University', value: 'Beykent University' },
  { label: 'Bezalel Academy of Art and Design', value: 'Bezalel Academy of Art and Design' },
  { label: 'BGC Trust University, Bangladesh', value: 'BGC Trust University, Bangladesh' },
  { label: 'Bhagwant University', value: 'Bhagwant University' },
  {
    label: 'Bharath Institue Of Higher Education & Research',
    value: 'Bharath Institue Of Higher Education & Research',
  },
  { label: 'Bharathiar University', value: 'Bharathiar University' },
  { label: 'Bharathidasan University', value: 'Bharathidasan University' },
  { label: 'Bharati Vidyapeeth University', value: 'Bharati Vidyapeeth University' },
  { label: 'Bhavnagar University', value: 'Bhavnagar University' },
  { label: 'Bhupendra Narayan Mandal University', value: 'Bhupendra Narayan Mandal University' },
  { label: 'Bicol University', value: 'Bicol University' },
  { label: 'Bidhan Chandra Agricultural University', value: 'Bidhan Chandra Agricultural University' },
  { label: 'Bifrost School of Business', value: 'Bifrost School of Business' },
  { label: 'Biju Pattnaik University of Technology', value: 'Biju Pattnaik University of Technology' },
  { label: 'Bila Cerkva State Agrarian University', value: 'Bila Cerkva State Agrarian University' },
  { label: 'Bilecik University', value: 'Bilecik University' },
  { label: 'Bilkent University', value: 'Bilkent University' },
  {
    label: 'Binary University College of Managemant & Entrepreneurship',
    value: 'Binary University College of Managemant & Entrepreneurship',
  },
  { label: 'Bindura University of Science Education', value: 'Bindura University of Science Education' },
  { label: 'Bingham University', value: 'Bingham University' },
  { label: 'Binh Duong University', value: 'Binh Duong University' },
  { label: 'Binus University', value: 'Binus University' },
  { label: 'Biola University', value: 'Biola University' },
  { label: 'Bircham International University', value: 'Bircham International University' },
  { label: 'Birjand University', value: 'Birjand University' },
  { label: 'Birjand University of Medical Sciences', value: 'Birjand University of Medical Sciences' },
  { label: 'Birkbeck College, University of London', value: 'Birkbeck College, University of London' },
  { label: 'Birla Institute of Technology and Science', value: 'Birla Institute of Technology and Science' },
  { label: 'Birla Institute of Technology, Ranchi', value: 'Birla Institute of Technology, Ranchi' },
  { label: 'Birmingham City University', value: 'Birmingham City University' },
  { label: 'Birmingham-Southern College', value: 'Birmingham-Southern College' },
  { label: 'Birsa Agricultural University', value: 'Birsa Agricultural University' },
  { label: 'Birsk State Pedagogical Institute', value: 'Birsk State Pedagogical Institute' },
  { label: 'Birzeit University', value: 'Birzeit University' },
  { label: 'Bishkek Humanities University', value: 'Bishkek Humanities University' },
  { label: "Bishop's University", value: "Bishop's University" },
  {
    label: 'BiTS - Business and Information Technology School gGmbH',
    value: 'BiTS - Business and Information Technology School gGmbH',
  },
  {
    label: 'Biysk State Padagogical University after V.M. Shukshin ',
    value: 'Biysk State Padagogical University after V.M. Shukshin ',
  },
  { label: 'Black Hawk College', value: 'Black Hawk College' },
  { label: 'Black Hills State University', value: 'Black Hills State University' },
  { label: 'Blackburn College', value: 'Blackburn College' },
  { label: 'Blekinge Institute of Technology', value: 'Blekinge Institute of Technology' },
  { label: 'Blessing-Rieman College of Nursing', value: 'Blessing-Rieman College of Nursing' },
  { label: 'Bloomfield College', value: 'Bloomfield College' },
  { label: 'Bloomsburg University of Pennsylvania', value: 'Bloomsburg University of Pennsylvania' },
  { label: 'Blue Mountain College', value: 'Blue Mountain College' },
  { label: 'Blue Mountains Hotel School', value: 'Blue Mountains Hotel School' },
  { label: 'Blue Nile University', value: 'Blue Nile University' },
  { label: 'Bluefield College', value: 'Bluefield College' },
  { label: 'Bluefield State College', value: 'Bluefield State College' },
  { label: 'Bluffton College', value: 'Bluffton College' },
  { label: 'Bob Jones University', value: 'Bob Jones University' },
  { label: 'Bodo Regional University', value: 'Bodo Regional University' },
  { label: 'Bogazici University', value: 'Bogazici University' },
  { label: 'Bogor Agricultural University', value: 'Bogor Agricultural University' },
  { label: 'Bohai University', value: 'Bohai University' },
  { label: 'Boise Bible College', value: 'Boise Bible College' },
  { label: 'Boise State University', value: 'Boise State University' },
  { label: 'Bond University', value: 'Bond University' },
  { label: 'Boricua College', value: 'Boricua College' },
  { label: 'Bosaso College', value: 'Bosaso College' },
  { label: 'Bost University', value: 'Bost University' },
  { label: 'Boston Architectural Center', value: 'Boston Architectural Center' },
  { label: 'Boston College', value: 'Boston College' },
  { label: 'Boston University', value: 'Boston University' },
  { label: 'Botho University', value: 'Botho University' },
  { label: 'Botswana Accountancy College', value: 'Botswana Accountancy College' },
  { label: 'Botswana College of Agriculture', value: 'Botswana College of Agriculture' },
  {
    label: 'Botswana International University of Science & Technology',
    value: 'Botswana International University of Science & Technology',
  },
  { label: 'Bourgas Free University', value: 'Bourgas Free University' },
  { label: 'Bourgas University Prof. Assen Zlatarov', value: 'Bourgas University Prof. Assen Zlatarov' },
  { label: 'Bournemouth University', value: 'Bournemouth University' },
  { label: 'Bow Valley College', value: 'Bow Valley College' },
  { label: 'Bowdoin College', value: 'Bowdoin College' },
  { label: 'Bowen University', value: 'Bowen University' },
  { label: 'Bowie State University', value: 'Bowie State University' },
  { label: 'Bowling Green State University', value: 'Bowling Green State University' },
  { label: 'Bowling Green State University, Firelands', value: 'Bowling Green State University, Firelands' },
  { label: 'Brac University', value: 'Brac University' },
  { label: 'Bradley University', value: 'Bradley University' },
  { label: 'Brandeis University', value: 'Brandeis University' },
  {
    label: 'Brandenburgische Technische Universität Cottbus',
    value: 'Brandenburgische Technische Universität Cottbus',
  },
  { label: 'Brandman University', value: 'Brandman University' },
  { label: 'Brandon University', value: 'Brandon University' },
  {
    label: 'Bratislava International School of Liberal Arts',
    value: 'Bratislava International School of Liberal Arts',
  },
  { label: 'Bratsk State Technical University', value: 'Bratsk State Technical University' },
  { label: 'Brenau University', value: 'Brenau University' },
  { label: 'Brescia College', value: 'Brescia College' },
  { label: 'Brescia University', value: 'Brescia University' },
  { label: 'Brest State Technical University', value: 'Brest State Technical University' },
  { label: 'Brest State University', value: 'Brest State University' },
  { label: 'Brevard College', value: 'Brevard College' },
  { label: 'Brewton-Parker College', value: 'Brewton-Parker College' },
  { label: 'Brexgata University Academy', value: 'Brexgata University Academy' },
  { label: 'Breyer State University (Virtual University)', value: 'Breyer State University (Virtual University)' },
  { label: 'Briar Cliff College', value: 'Briar Cliff College' },
  { label: 'Brickfields Asia College', value: 'Brickfields Asia College' },
  { label: 'Bridgewater College', value: 'Bridgewater College' },
  { label: 'Bridgewater State College', value: 'Bridgewater State College' },
  { label: 'Brigham Young University', value: 'Brigham Young University' },
  { label: 'Brigham Young University', value: 'Brigham Young University' },
  { label: 'Brigham Young University Hawaii', value: 'Brigham Young University Hawaii' },
  { label: 'British College of Osteopathic Medicine', value: 'British College of Osteopathic Medicine' },
  { label: 'British Columbia Institute of Technology', value: 'British Columbia Institute of Technology' },
  { label: 'British Columbia Open University', value: 'British Columbia Open University' },
  {
    label: 'British Institute in Paris, University of London',
    value: 'British Institute in Paris, University of London',
  },
  { label: 'British Malaysian Institute', value: 'British Malaysian Institute' },
  { label: 'British Royal University', value: 'British Royal University' },
  { label: 'British University in Dubai', value: 'British University in Dubai' },
  { label: 'Brjansk State Technical University', value: 'Brjansk State Technical University' },
  { label: 'Brno University of Technology', value: 'Brno University of Technology' },
  { label: 'Brock University', value: 'Brock University' },
  { label: 'Brookdale Community College', value: 'Brookdale Community College' },
  { label: 'Brooklyn Law School', value: 'Brooklyn Law School' },
  { label: 'Brooks Institute of Photography', value: 'Brooks Institute of Photography' },
  { label: 'Brown University', value: 'Brown University' },
  { label: 'Brunel University Uxbridge', value: 'Brunel University Uxbridge' },
  { label: 'Brunswick Community College', value: 'Brunswick Community College' },
  { label: 'Brussels Management School (ICHEC)', value: 'Brussels Management School (ICHEC)' },
  { label: 'Brussels School of International Studies', value: 'Brussels School of International Studies' },
  { label: 'Bryan College', value: 'Bryan College' },
  { label: 'Bryant and Stratton College', value: 'Bryant and Stratton College' },
  { label: 'Bryant University', value: 'Bryant University' },
  { label: 'Bryn Athyn College of the New Church', value: 'Bryn Athyn College of the New Church' },
  { label: 'Bryn Mawr College', value: 'Bryn Mawr College' },
  { label: 'Bu Ali Sina University', value: 'Bu Ali Sina University' },
  { label: 'Buckinghamshire New University', value: 'Buckinghamshire New University' },
  { label: 'Bucknell University', value: 'Bucknell University' },
  { label: 'Budapest Buddhist University', value: 'Budapest Buddhist University' },
  {
    label: 'Budapest University of Economic Sciences and Public Administration',
    value: 'Budapest University of Economic Sciences and Public Administration',
  },
  { label: 'Buddhasravaka Bhikshu University', value: 'Buddhasravaka Bhikshu University' },
  { label: 'Buddhist Acamedy of China', value: 'Buddhist Acamedy of China' },
  { label: 'Buddhist and Pali University of Sri Lanka', value: 'Buddhist and Pali University of Sri Lanka' },
  { label: 'Buena Vista University', value: 'Buena Vista University' },
  { label: 'Bugema University', value: 'Bugema University' },
  { label: 'Build Bright University', value: 'Build Bright University' },
  { label: 'Bukkyo University', value: 'Bukkyo University' },
  { label: 'Bulacan State University', value: 'Bulacan State University' },
  { label: 'Bule Hora  University', value: 'Bule Hora  University' },
  { label: 'Bundelkhand University', value: 'Bundelkhand University' },
  { label: "Bunka Women's University", value: "Bunka Women's University" },
  { label: 'Bunkyo University', value: 'Bunkyo University' },
  { label: 'Burapha University', value: 'Burapha University' },
  { label: 'Buraydah College for Applied Medical Sciences', value: 'Buraydah College for Applied Medical Sciences' },
  { label: 'Burdwan University', value: 'Burdwan University' },
  { label: 'Burjat State University', value: 'Burjat State University' },
  { label: 'Burlington College', value: 'Burlington College' },
  { label: 'Bushehr University of Medical Sciences', value: 'Bushehr University of Medical Sciences' },
  { label: 'Business & Computer University College', value: 'Business & Computer University College' },
  { label: 'Business and Hotel Management School', value: 'Business and Hotel Management School' },
  { label: 'Business School Lausanne (BSL)', value: 'Business School Lausanne (BSL)' },
  { label: 'Busitema University', value: 'Busitema University' },
  { label: 'Busoga University', value: 'Busoga University' },
  { label: 'Butler University', value: 'Butler University' },
  { label: 'Cabrini College', value: 'Cabrini College' },
  { label: 'Cag University', value: 'Cag University' },
  { label: 'Cagayan State University', value: 'Cagayan State University' },
  { label: 'Cairo University', value: 'Cairo University' },
  { label: 'Caldwell College', value: 'Caldwell College' },
  { label: 'Caleb University', value: 'Caleb University' },
  { label: 'California Baptist College', value: 'California Baptist College' },
  { label: 'California Coast University', value: 'California Coast University' },
  { label: 'California College for Health Sciences', value: 'California College for Health Sciences' },
  { label: 'California College of Arts and Crafts', value: 'California College of Arts and Crafts' },
  { label: 'California College of Podiatric Medicine', value: 'California College of Podiatric Medicine' },
  { label: 'California College San Diego', value: 'California College San Diego' },
  { label: 'California Institute of Integral Studies', value: 'California Institute of Integral Studies' },
  { label: 'California Institute of Technology', value: 'California Institute of Technology' },
  { label: 'California Institute of the Arts', value: 'California Institute of the Arts' },
  { label: 'California Lutheran University', value: 'California Lutheran University' },
  { label: 'California Maritime Academy', value: 'California Maritime Academy' },
  {
    label: 'California Polytechnic State University - San Luis Obispo',
    value: 'California Polytechnic State University - San Luis Obispo',
  },
  {
    label: 'California School of Professional Psychology - Berkley/Alameda',
    value: 'California School of Professional Psychology - Berkley/Alameda',
  },
  {
    label: 'California School of Professional Psychology - Fresno',
    value: 'California School of Professional Psychology - Fresno',
  },
  {
    label: 'California School of Professional Psychology - Los Angels',
    value: 'California School of Professional Psychology - Los Angels',
  },
  {
    label: 'California School of Professional Psychology - San Diego',
    value: 'California School of Professional Psychology - San Diego',
  },
  {
    label: 'California State Polytechnic University - Pomona',
    value: 'California State Polytechnic University - Pomona',
  },
  { label: 'California State University, Bakersfield', value: 'California State University, Bakersfield' },
  { label: 'California State University, Channel Islands', value: 'California State University, Channel Islands' },
  { label: 'California State University, Chico', value: 'California State University, Chico' },
  { label: 'California State University, Dominguez Hills', value: 'California State University, Dominguez Hills' },
  { label: 'California State University, Fresno', value: 'California State University, Fresno' },
  { label: 'California State University, Fullerton', value: 'California State University, Fullerton' },
  { label: 'California State University, Hayward', value: 'California State University, Hayward' },
  { label: 'California State University, Long Beach', value: 'California State University, Long Beach' },
  { label: 'California State University, Los Angeles', value: 'California State University, Los Angeles' },
  { label: 'California State University, Monterey Bay', value: 'California State University, Monterey Bay' },
  { label: 'California State University, Northridge', value: 'California State University, Northridge' },
  { label: 'California State University, Sacramento', value: 'California State University, Sacramento' },
  { label: 'California State University, San Bernadino', value: 'California State University, San Bernadino' },
  { label: 'California State University, San Marcos', value: 'California State University, San Marcos' },
  { label: 'California State University, Stanislaus', value: 'California State University, Stanislaus' },
  {
    label: 'California University of Management and Sciences',
    value: 'California University of Management and Sciences',
  },
  { label: 'California University of Pennsylvania', value: 'California University of Pennsylvania' },
  { label: 'California Western School of Law', value: 'California Western School of Law' },
  { label: 'Calumet College of St. Joseph', value: 'Calumet College of St. Joseph' },
  { label: 'Calvary Bible College', value: 'Calvary Bible College' },
  { label: 'Calvin College', value: 'Calvin College' },
  { label: 'Camberwell College of Arts', value: 'Camberwell College of Arts' },
  { label: 'Cambodia University of Specialties', value: 'Cambodia University of Specialties' },
  { label: 'Cambodian Mekong University', value: 'Cambodian Mekong University' },
  { label: 'Cambridge College', value: 'Cambridge College' },
  { label: 'Cameron University', value: 'Cameron University' },
  { label: 'Camosun College', value: 'Camosun College' },
  { label: 'Campbell University', value: 'Campbell University' },
  { label: 'Campbellsville College', value: 'Campbellsville College' },
  { label: 'Campion College', value: 'Campion College' },
  { label: 'Campus Bio-Medico University of Rome', value: 'Campus Bio-Medico University of Rome' },
  { label: 'Can-Tho University', value: 'Can-Tho University' },
  { label: 'Canadian College of Business & Computers', value: 'Canadian College of Business & Computers' },
  { label: 'Canadian Mennonite University', value: 'Canadian Mennonite University' },
  { label: 'Canadian Sudanese College', value: 'Canadian Sudanese College' },
  { label: 'Canakkale (18th March) University', value: 'Canakkale (18th March) University' },
  { label: 'Canisius College', value: 'Canisius College' },
  { label: 'Cankaya University', value: 'Cankaya University' },
  { label: 'Canterbury Christ Church University', value: 'Canterbury Christ Church University' },
  { label: 'Cape Coast Polytechnic ', value: 'Cape Coast Polytechnic ' },
  { label: 'Cape Peninsula University of Technology', value: 'Cape Peninsula University of Technology' },
  { label: 'Capella University', value: 'Capella University' },
  { label: 'Capilano College', value: 'Capilano College' },
  { label: 'Capital Normal University', value: 'Capital Normal University' },
  { label: 'Capital University', value: 'Capital University' },
  { label: 'Capital University Law School', value: 'Capital University Law School' },
  { label: 'Capital University of Economics and Business', value: 'Capital University of Economics and Business' },
  { label: 'Capital University of Medical Sciences', value: 'Capital University of Medical Sciences' },
  { label: 'Capitol College', value: 'Capitol College' },
  { label: 'Capitol University', value: 'Capitol University' },
  { label: 'Cardiff University', value: 'Cardiff University' },
  { label: 'Cardinal Stefan Wyszynski University in Warsaw', value: 'Cardinal Stefan Wyszynski University in Warsaw' },
  { label: 'Cardinal Stritch University', value: 'Cardinal Stritch University' },
  { label: 'Caribbean University', value: 'Caribbean University' },
  { label: 'Caritas University', value: 'Caritas University' },
  { label: 'Carl von Ossietzky Universität Oldenburg', value: 'Carl von Ossietzky Universität Oldenburg' },
  { label: 'Carleton College', value: 'Carleton College' },
  { label: 'Carleton University', value: 'Carleton University' },
  { label: 'Carlos Albizu University', value: 'Carlos Albizu University' },
  { label: 'Carlow College', value: 'Carlow College' },
  { label: 'Carlow Institute of Technology', value: 'Carlow Institute of Technology' },
  { label: 'Carnegie Mellon University', value: 'Carnegie Mellon University' },
  { label: 'Carroll College Helena', value: 'Carroll College Helena' },
  { label: 'Carroll College Waukesha', value: 'Carroll College Waukesha' },
  { label: 'Carson-Newman College', value: 'Carson-Newman College' },
  { label: 'Carthage College', value: 'Carthage College' },
  { label: 'Case Western Reserve University', value: 'Case Western Reserve University' },
  { label: 'Castleton State College', value: 'Castleton State College' },
  { label: 'Catawba College', value: 'Catawba College' },
  { label: 'Catholic Theological Union', value: 'Catholic Theological Union' },
  { label: 'Catholic University in Ruzomberok', value: 'Catholic University in Ruzomberok' },
  { label: 'Catholic University in Ružomberok', value: 'Catholic University in Ružomberok' },
  { label: 'Catholic University in Zimbabwe', value: 'Catholic University in Zimbabwe' },
  { label: 'Catholic University of Eastern Africa', value: 'Catholic University of Eastern Africa' },
  { label: 'Catholic University of Korea', value: 'Catholic University of Korea' },
  { label: 'Catholic University of Lublin', value: 'Catholic University of Lublin' },
  { label: 'Catholic University of Malawi', value: 'Catholic University of Malawi' },
  { label: 'Catholic University of Pusan', value: 'Catholic University of Pusan' },
  { label: 'Catholic University of Taegu-Hyosung', value: 'Catholic University of Taegu-Hyosung' },
  { label: 'Catholic University of the Sacred Heart', value: 'Catholic University of the Sacred Heart' },
  { label: 'Caucasus University', value: 'Caucasus University' },
  { label: 'Cavendish University', value: 'Cavendish University' },
  { label: 'CCS Haryana Agricultural University', value: 'CCS Haryana Agricultural University' },
  { label: 'Cecos Univeristy of Information Technology', value: 'Cecos Univeristy of Information Technology' },
  { label: 'Cedar Crest College', value: 'Cedar Crest College' },
  { label: 'Cedarville College', value: 'Cedarville College' },
  { label: 'Celal Bayar University', value: 'Celal Bayar University' },
  { label: 'Centenary College', value: 'Centenary College' },
  { label: 'Centenary College of Louisiana', value: 'Centenary College of Louisiana' },
  { label: 'Centennial College', value: 'Centennial College' },
  {
    label: 'Center for Entrepreneurship and Small Business Management',
    value: 'Center for Entrepreneurship and Small Business Management',
  },
  { label: 'Center for Humanistic Studies', value: 'Center for Humanistic Studies' },
  { label: 'Central Academy of  Fine Art', value: 'Central Academy of  Fine Art' },
  { label: 'Central Agricultural University', value: 'Central Agricultural University' },
  {
    label: 'Central America Health Sciences University (Belize Medical College)',
    value: 'Central America Health Sciences University (Belize Medical College)',
  },
  { label: 'Central Baptist College', value: 'Central Baptist College' },
  { label: 'Central Bible College', value: 'Central Bible College' },
  { label: 'Central Buganda University', value: 'Central Buganda University' },
  { label: 'Central China Normal University', value: 'Central China Normal University' },
  { label: 'Central Christian College of the Bible', value: 'Central Christian College of the Bible' },
  { label: 'Central College', value: 'Central College' },
  { label: 'Central Connecticut State University', value: 'Central Connecticut State University' },
  { label: 'Central European University', value: 'Central European University' },
  {
    label: 'Central Institute of English and Foreign Languages',
    value: 'Central Institute of English and Foreign Languages',
  },
  { label: 'Central Institute of Fisheries Education', value: 'Central Institute of Fisheries Education' },
  { label: 'Central Institute of Higher Tibetan Studies', value: 'Central Institute of Higher Tibetan Studies' },
  { label: 'Central Luzon State University', value: 'Central Luzon State University' },
  { label: 'Central Methodist College', value: 'Central Methodist College' },
  { label: 'Central Michigan University', value: 'Central Michigan University' },
  { label: 'Central Mindanao University', value: 'Central Mindanao University' },
  {
    label: 'Central Ostrobothnia University of Applied Sciences',
    value: 'Central Ostrobothnia University of Applied Sciences',
  },
  { label: 'Central Philippine University', value: 'Central Philippine University' },
  { label: 'Central Police University', value: 'Central Police University' },
  { label: 'Central Queensland University', value: 'Central Queensland University' },
  { label: 'Central Radio and TV University', value: 'Central Radio and TV University' },
  { label: 'Central Saint Martins College of Art & Design', value: 'Central Saint Martins College of Art & Design' },
  { label: 'Central South Forestry University', value: 'Central South Forestry University' },
  { label: 'Central South University', value: 'Central South University' },
  { label: 'Central State University', value: 'Central State University' },
  { label: 'Central University College', value: 'Central University College' },
  { label: 'Central University for Nationalities', value: 'Central University for Nationalities' },
  { label: 'Central University of Finance and Economics', value: 'Central University of Finance and Economics' },
  { label: 'Central University of Technology, Free State', value: 'Central University of Technology, Free State' },
  { label: 'Central Washington University', value: 'Central Washington University' },
  { label: 'Centre College', value: 'Centre College' },
  {
    label: "Centre d'Etudes Supérieures des Techniques Industrielles",
    value: "Centre d'Etudes Supérieures des Techniques Industrielles",
  },
  {
    label: "Centre d'Etudes Supérieures Industrielles Paris",
    value: "Centre d'Etudes Supérieures Industrielles Paris",
  },
  {
    label: 'Centre de Formation et de Perfectionnement des Journalistes',
    value: 'Centre de Formation et de Perfectionnement des Journalistes',
  },
  {
    label: "Centre National d'Etudes Agronomiques des Régions Chaudes",
    value: "Centre National d'Etudes Agronomiques des Régions Chaudes",
  },
  { label: "Centre Universitaire d'Oum El Bouaghi", value: "Centre Universitaire d'Oum El Bouaghi" },
  { label: 'Centre Universitaire de Jijel', value: 'Centre Universitaire de Jijel' },
  { label: 'Centre Universitaire de Tebessa', value: 'Centre Universitaire de Tebessa' },
  { label: 'Centre Universitaire de Technologie', value: 'Centre Universitaire de Technologie' },
  { label: 'Centre Universitaire de Tiaret', value: 'Centre Universitaire de Tiaret' },
  { label: 'Centro de Enseñanza Técnica Industrial', value: 'Centro de Enseñanza Técnica Industrial' },
  {
    label: 'Centro de Estudios Avanzados de Puerto Rico y el Caribe',
    value: 'Centro de Estudios Avanzados de Puerto Rico y el Caribe',
  },
  {
    label: 'Centro de Estudios Investigación y Tecnología (CEIT)',
    value: 'Centro de Estudios Investigación y Tecnología (CEIT)',
  },
  { label: 'Centro de Estudios Universitarios Monterrey', value: 'Centro de Estudios Universitarios Monterrey' },
  { label: 'Centro de Estudios Universitarios Xochicalco', value: 'Centro de Estudios Universitarios Xochicalco' },
  { label: 'Centro Escolar University', value: 'Centro Escolar University' },
  {
    label: 'Centro Regional Universitário de Espiríto Santo do Pinhal',
    value: 'Centro Regional Universitário de Espiríto Santo do Pinhal',
  },
  { label: 'Centro Universitário Adventista de São Paulo', value: 'Centro Universitário Adventista de São Paulo' },
  { label: 'Centro Universitário Barao de Maua', value: 'Centro Universitário Barao de Maua' },
  { label: 'Centro Universitario Ciudad Vieja', value: 'Centro Universitario Ciudad Vieja' },
  { label: 'Centro Universitário Claretiano', value: 'Centro Universitário Claretiano' },
  { label: 'Centro Universitário de Araraquara', value: 'Centro Universitário de Araraquara' },
  { label: 'Centro Universitário de João Pessoa', value: 'Centro Universitário de João Pessoa' },
  { label: 'Centro Universitario de Occidente', value: 'Centro Universitario de Occidente' },
  { label: 'Centro Universitario Ixtlahuaca', value: 'Centro Universitario Ixtlahuaca' },
  { label: 'Centro Universitário Monte Serrat', value: 'Centro Universitário Monte Serrat' },
  { label: 'Centro Universitário Newton Paiva', value: 'Centro Universitário Newton Paiva' },
  { label: 'Centro Universitário Plinio Leite', value: 'Centro Universitário Plinio Leite' },
  { label: 'Centro Universitário Senac', value: 'Centro Universitário Senac' },
  { label: 'Centro Universitário Serra dos Órgãos', value: 'Centro Universitário Serra dos Órgãos' },
  { label: 'Centro Universitario Villanueva', value: 'Centro Universitario Villanueva' },
  { label: 'CETYS Universidad', value: 'CETYS Universidad' },
  { label: 'Cevro Institut College', value: 'Cevro Institut College' },
  { label: 'Ch. Charan Singh University', value: 'Ch. Charan Singh University' },
  { label: 'Chadron State College', value: 'Chadron State College' },
  { label: 'Chalmers University of Technology', value: 'Chalmers University of Technology' },
  { label: 'Chaminade University of Honolulu', value: 'Chaminade University of Honolulu' },
  { label: 'Champlain College', value: 'Champlain College' },
  { label: 'Chamreun University of Poly Technology', value: 'Chamreun University of Poly Technology' },
  {
    label: 'Chandra Shekhar Azad University of Agriculture and Technology',
    value: 'Chandra Shekhar Azad University of Agriculture and Technology',
  },
  { label: 'Chang Gung University', value: 'Chang Gung University' },
  { label: 'Chang Jung University', value: 'Chang Jung University' },
  { label: 'Changchun Teachers College', value: 'Changchun Teachers College' },
  { label: 'Changchun University of Technology', value: 'Changchun University of Technology' },
  { label: 'Changsha Railway University', value: 'Changsha Railway University' },
  { label: 'Changsha University of Electric Power', value: 'Changsha University of Electric Power' },
  { label: 'Changwon National University', value: 'Changwon National University' },
  { label: 'Chao Yang University of Science and Technology', value: 'Chao Yang University of Science and Technology' },
  { label: 'Chaopraya University ', value: 'Chaopraya University ' },
  { label: 'Chapman University', value: 'Chapman University' },
  { label: 'Charisma University', value: 'Charisma University' },
  { label: 'Charles Darwin University', value: 'Charles Darwin University' },
  {
    label: 'Charles R. Drew University of Medicine and Science',
    value: 'Charles R. Drew University of Medicine and Science',
  },
  { label: 'Charles Sturt University', value: 'Charles Sturt University' },
  { label: 'Charles University Prague', value: 'Charles University Prague' },
  { label: 'Charleston Southern University', value: 'Charleston Southern University' },
  { label: 'Charter Oak State College', value: 'Charter Oak State College' },
  { label: 'Chatham College', value: 'Chatham College' },
  {
    label: 'Chaudhary Charan Singh Haryana Agricultural University',
    value: 'Chaudhary Charan Singh Haryana Agricultural University',
  },
  { label: 'Chaudhary Charan Singh University', value: 'Chaudhary Charan Singh University' },
  { label: 'Cheju National University', value: 'Cheju National University' },
  { label: 'Cheju National University of Education', value: 'Cheju National University of Education' },
  {
    label: 'Cheljabinsk State Institute of Teacher Training',
    value: 'Cheljabinsk State Institute of Teacher Training',
  },
  { label: 'Cheljabinsk State University', value: 'Cheljabinsk State University' },
  {
    label: 'Cheljabinsk University of Agricultural Engineering',
    value: 'Cheljabinsk University of Agricultural Engineering',
  },
  { label: 'Chelsea College of Art and Design', value: 'Chelsea College of Art and Design' },
  {
    label: 'Chengdu Institute of Sichuan International Studies University',
    value: 'Chengdu Institute of Sichuan International Studies University',
  },
  { label: 'Chengdu University', value: 'Chengdu University' },
  { label: 'Chengdu University of Technology', value: 'Chengdu University of Technology' },
  {
    label: 'Chengdu University of Traditional Chinese Medicine',
    value: 'Chengdu University of Traditional Chinese Medicine',
  },
  { label: 'Chernivci National University', value: 'Chernivci National University' },
  { label: 'Chestnut Hill College', value: 'Chestnut Hill College' },
  { label: 'Cheyney University of Pennsylvania', value: 'Cheyney University of Pennsylvania' },
  { label: 'Chhatrapati Shahu Ji Maharaj University', value: 'Chhatrapati Shahu Ji Maharaj University' },
  { label: 'Chia Nana College of Pharmacy and Science', value: 'Chia Nana College of Pharmacy and Science' },
  { label: 'Chiang Mai Rajabhat University', value: 'Chiang Mai Rajabhat University' },
  { label: 'Chiang Mai University', value: 'Chiang Mai University' },
  { label: 'Chiang Mai Vocational College', value: 'Chiang Mai Vocational College' },
  { label: 'Chiba Institute of Technology', value: 'Chiba Institute of Technology' },
  { label: 'Chiba Keizai University', value: 'Chiba Keizai University' },
  { label: 'Chiba University', value: 'Chiba University' },
  { label: 'Chiba University of Commerce', value: 'Chiba University of Commerce' },
  { label: 'Chicago State University', value: 'Chicago State University' },
  { label: 'Chikushi Jogakuen University', value: 'Chikushi Jogakuen University' },
  { label: 'China Academy of Art', value: 'China Academy of Art' },
  { label: 'China Agricultural University', value: 'China Agricultural University' },
  { label: 'China Agriculture University East', value: 'China Agriculture University East' },
  { label: 'China Foreign Affairs University', value: 'China Foreign Affairs University' },
  { label: 'China Medical College', value: 'China Medical College' },
  { label: 'China Medical University Shenyang', value: 'China Medical University Shenyang' },
  { label: 'China Pharmaceutical University Nanjing', value: 'China Pharmaceutical University Nanjing' },
  { label: 'China Three Gorges University', value: 'China Three Gorges University' },
  { label: 'China University of Geoscience Beijing', value: 'China University of Geoscience Beijing' },
  { label: 'China University of Geosciences Wuhan', value: 'China University of Geosciences Wuhan' },
  {
    label: 'China University of Mining Technology - Beijing',
    value: 'China University of Mining Technology - Beijing',
  },
  { label: 'China University of Mining Technology - Xuzhou', value: 'China University of Mining Technology - Xuzhou' },
  { label: 'China University of Political Science and Law', value: 'China University of Political Science and Law' },
  { label: 'China USA Business University', value: 'China USA Business University' },
  { label: 'China youth college for political science', value: 'China youth college for political science' },
  { label: 'Chinese Culture University', value: 'Chinese Culture University' },
  { label: "Chinese People's Public Security University", value: "Chinese People's Public Security University" },
  { label: 'Chinese University of Hong Kong', value: 'Chinese University of Hong Kong' },
  { label: 'Ching Kuo Institue of Management & Health', value: 'Ching Kuo Institue of Management & Health' },
  { label: 'Chinhoyi University of Technology', value: 'Chinhoyi University of Technology' },
  { label: 'Chinju National University', value: 'Chinju National University' },
  { label: 'Chinju National University of Education', value: 'Chinju National University of Education' },
  { label: 'Chittagong Independent University', value: 'Chittagong Independent University' },
  {
    label: 'Chittagong University of Engineering and Technology',
    value: 'Chittagong University of Engineering and Technology',
  },
  { label: 'Chodang University', value: 'Chodang University' },
  { label: 'Chonbuk National University', value: 'Chonbuk National University' },
  {
    label: 'Chonbuk Sanup University of Technology (Howon University)',
    value: 'Chonbuk Sanup University of Technology (Howon University)',
  },
  { label: 'Chongju National University of Education', value: 'Chongju National University of Education' },
  { label: 'Chongju University', value: 'Chongju University' },
  { label: 'Chongqing Education College', value: 'Chongqing Education College' },
  { label: 'Chongqing Medical University', value: 'Chongqing Medical University' },
  { label: 'Chongqing Normal University', value: 'Chongqing Normal University' },
  {
    label: 'Chongqing Normal University Foreign Trade and Business College',
    value: 'Chongqing Normal University Foreign Trade and Business College',
  },
  { label: 'Chongqing Technology and Business University', value: 'Chongqing Technology and Business University' },
  { label: 'Chongqing Telecommunication College', value: 'Chongqing Telecommunication College' },
  { label: 'Chongqing Three Gorges University', value: 'Chongqing Three Gorges University' },
  { label: 'Chongqing University', value: 'Chongqing University' },
  { label: 'Chongqing University of Communications', value: 'Chongqing University of Communications' },
  {
    label: 'Chongqing University of Post and Telecommunications',
    value: 'Chongqing University of Post and Telecommunications',
  },
  {
    label: 'Chongqing University of Science and Technology ',
    value: 'Chongqing University of Science and Technology ',
  },
  { label: 'Chongqing University of Technology', value: 'Chongqing University of Technology' },
  {
    label: 'Chongqing Vocational College of Public Transportation',
    value: 'Chongqing Vocational College of Public Transportation',
  },
  { label: 'Chongqing Wenli University', value: 'Chongqing Wenli University' },
  { label: 'Chonju National University of Education', value: 'Chonju National University of Education' },
  { label: 'Chonnam National University', value: 'Chonnam National University' },
  { label: 'Chosun University', value: 'Chosun University' },
  { label: 'Chowan College', value: 'Chowan College' },
  { label: 'Christ University', value: 'Christ University' },
  {
    label: 'Christchurch Polytechnic Institute of Technology',
    value: 'Christchurch Polytechnic Institute of Technology',
  },
  { label: 'Christelijke Hogeschool Windesheim', value: 'Christelijke Hogeschool Windesheim' },
  { label: 'Christendom College', value: 'Christendom College' },
  { label: 'Christian Brothers University', value: 'Christian Brothers University' },
  { label: 'Christian Heritage College', value: 'Christian Heritage College' },
  { label: 'Christian Theological Academy in Warszaw', value: 'Christian Theological Academy in Warszaw' },
  { label: 'Christian-Albrechts-Universität Kiel', value: 'Christian-Albrechts-Universität Kiel' },
  { label: 'Christopher Newport University', value: 'Christopher Newport University' },
  { label: 'Chu Hai College', value: 'Chu Hai College' },
  {
    label: "Chubu Gakuin University & Chubu Women's College",
    value: "Chubu Gakuin University & Chubu Women's College",
  },
  { label: 'Chubu University', value: 'Chubu University' },
  { label: 'Chuka University', value: 'Chuka University' },
  { label: 'Chukyo Gakuin University', value: 'Chukyo Gakuin University' },
  { label: 'Chukyo University', value: 'Chukyo University' },
  { label: "Chukyo Women's University", value: "Chukyo Women's University" },
  { label: 'Chulalongkorn University', value: 'Chulalongkorn University' },
  { label: 'Chunchon National University of Education', value: 'Chunchon National University of Education' },
  { label: 'Chung Hua University', value: 'Chung Hua University' },
  { label: 'Chung Shan Medical and Dental College', value: 'Chung Shan Medical and Dental College' },
  { label: 'Chung Yuan Christian University', value: 'Chung Yuan Christian University' },
  { label: 'Chung-Ang University', value: 'Chung-Ang University' },
  { label: 'Chung-Ju National University', value: 'Chung-Ju National University' },
  { label: 'Chungbuk National University', value: 'Chungbuk National University' },
  { label: 'Chungnam National University', value: 'Chungnam National University' },
  { label: 'Chuo Gakuin University', value: 'Chuo Gakuin University' },
  { label: 'Chuo University', value: 'Chuo University' },
  { label: 'Chuvash State University', value: 'Chuvash State University' },
  { label: 'Cihan University', value: 'Cihan University' },
  { label: 'Circleville Bible College', value: 'Circleville Bible College' },
  { label: 'City University', value: 'City University' },
  { label: 'City University', value: 'City University' },
  { label: 'City University', value: 'City University' },
  { label: 'City University Athens', value: 'City University Athens' },
  {
    label: 'City University College of Science and Technology',
    value: 'City University College of Science and Technology',
  },
  { label: 'City University of Hong Kong', value: 'City University of Hong Kong' },
  { label: 'City University of New York (CUNY) System', value: 'City University of New York (CUNY) System' },
  {
    label: 'City University of New York Medical School / Sophie Davis School of Biomedical Education',
    value: 'City University of New York Medical School / Sophie Davis School of Biomedical Education',
  },
  {
    label: 'City University of New York, Bernard M. Baruch College',
    value: 'City University of New York, Bernard M. Baruch College',
  },
  { label: 'City University of New York, Brooklyn College', value: 'City University of New York, Brooklyn College' },
  { label: 'City University of New York, City College', value: 'City University of New York, City College' },
  {
    label: 'City University of New York, College of Staten Island',
    value: 'City University of New York, College of Staten Island',
  },
  {
    label: 'City University of New York, Graduate School and University Center',
    value: 'City University of New York, Graduate School and University Center',
  },
  { label: 'City University of New York, Hunter College', value: 'City University of New York, Hunter College' },
  {
    label: 'City University of New York, John Jay College of Criminal Justice',
    value: 'City University of New York, John Jay College of Criminal Justice',
  },
  { label: 'City University of New York, Lehman College', value: 'City University of New York, Lehman College' },
  {
    label: 'City University of New York, Medgar Evers College',
    value: 'City University of New York, Medgar Evers College',
  },
  {
    label: 'City University of New York, New York City Technical College',
    value: 'City University of New York, New York City Technical College',
  },
  { label: 'City University of New York, Queens College', value: 'City University of New York, Queens College' },
  {
    label: 'City University of New York, School of Law at Queens College',
    value: 'City University of New York, School of Law at Queens College',
  },
  { label: 'City University of New York, York College', value: 'City University of New York, York College' },
  { label: 'City University Programs in Bulgaria ', value: 'City University Programs in Bulgaria ' },
  { label: 'Civil Aviation University of China', value: 'Civil Aviation University of China' },
  { label: 'Claflin College', value: 'Claflin College' },
  { label: 'Claremont Graduate University', value: 'Claremont Graduate University' },
  { label: 'Claremont Lincoln University', value: 'Claremont Lincoln University' },
  { label: 'Claremont McKenna College', value: 'Claremont McKenna College' },
  { label: 'Clarion University', value: 'Clarion University' },
  { label: 'Clark Atlanta University', value: 'Clark Atlanta University' },
  { label: 'Clark University', value: 'Clark University' },
  { label: 'Clarke College', value: 'Clarke College' },
  { label: 'Clarkson College', value: 'Clarkson College' },
  { label: 'Clarkson University', value: 'Clarkson University' },
  { label: 'Clayton College & State University', value: 'Clayton College & State University' },
  { label: 'Clearwater Christian College', value: 'Clearwater Christian College' },
  { label: 'Cleary College', value: 'Cleary College' },
  { label: 'Clemson University', value: 'Clemson University' },
  { label: 'Cleveland Chiropractic College, Kansas City', value: 'Cleveland Chiropractic College, Kansas City' },
  { label: 'Cleveland Chiropractic College, Los Angeles', value: 'Cleveland Chiropractic College, Los Angeles' },
  { label: 'Cleveland Institute of Art', value: 'Cleveland Institute of Art' },
  { label: 'Cleveland Institute of Music', value: 'Cleveland Institute of Music' },
  { label: 'Cleveland State University', value: 'Cleveland State University' },
  { label: 'CMJ University', value: 'CMJ University' },
  { label: 'Coastal Carolina University', value: 'Coastal Carolina University' },
  { label: 'Cochin University of Science and Technology', value: 'Cochin University of Science and Technology' },
  { label: 'Coe College', value: 'Coe College' },
  { label: 'Cogswell Polytechnical College', value: 'Cogswell Polytechnical College' },
  { label: 'Coker College', value: 'Coker College' },
  { label: 'Colby College', value: 'Colby College' },
  { label: 'Colby-Sawyer College', value: 'Colby-Sawyer College' },
  {
    label: 'Colegio de Estudios Superiores de Administración (CESA)',
    value: 'Colegio de Estudios Superiores de Administración (CESA)',
  },
  { label: 'Colegio de San Juan de Letran', value: 'Colegio de San Juan de Letran' },
  { label: 'Coleman College', value: 'Coleman College' },
  { label: 'Colgate University', value: 'Colgate University' },
  { label: 'Collège Boréal', value: 'Collège Boréal' },
  { label: 'College for Creative Studies', value: 'College for Creative Studies' },
  { label: 'College for Financial Planning', value: 'College for Financial Planning' },
  { label: 'College for Lifelong Learning', value: 'College for Lifelong Learning' },
  { label: 'College in Sládkovičovo', value: 'College in Sládkovičovo' },
  { label: 'College Misericordia', value: 'College Misericordia' },
  { label: 'College of Aeronautics', value: 'College of Aeronautics' },
  { label: 'College of Business Management ( CBM )', value: 'College of Business Management ( CBM )' },
  { label: 'College of Busniess Admnistration', value: 'College of Busniess Admnistration' },
  { label: 'College of Charleston', value: 'College of Charleston' },
  { label: 'College of Computer Science in Lodz', value: 'College of Computer Science in Lodz' },
  { label: 'College of Dunaujvaros', value: 'College of Dunaujvaros' },
  { label: 'College of DuPage', value: 'College of DuPage' },
  { label: 'College of Education Ikere', value: 'College of Education Ikere' },
  { label: 'College of Education Oju', value: 'College of Education Oju' },
  { label: 'College of Europe', value: 'College of Europe' },
  { label: 'College of Idaho', value: 'College of Idaho' },
  { label: 'College of Management', value: 'College of Management' },
  { label: 'College of Mount St. Joseph', value: 'College of Mount St. Joseph' },
  { label: 'College of Mount St. Vincent', value: 'College of Mount St. Vincent' },
  { label: 'College of New Caledonia', value: 'College of New Caledonia' },
  { label: 'College of New Rochelle', value: 'College of New Rochelle' },
  { label: 'College of Notre Dame', value: 'College of Notre Dame' },
  { label: 'College of Notre Dame of Maryland', value: 'College of Notre Dame of Maryland' },
  { label: 'College of Nursing and Allied Health Scinces', value: 'College of Nursing and Allied Health Scinces' },
  {
    label: 'College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)',
    value: 'College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)',
  },
  { label: 'College of Science, Baghdad University', value: 'College of Science, Baghdad University' },
  {
    label: "College of St. Benedict and St. John's University",
    value: "College of St. Benedict and St. John's University",
  },
  { label: 'College of St. Catherine', value: 'College of St. Catherine' },
  { label: 'College of St. Elizabeth', value: 'College of St. Elizabeth' },
  { label: 'College of St. Joseph', value: 'College of St. Joseph' },
  { label: 'College of St. Mary', value: 'College of St. Mary' },
  { label: 'College of St. Rose', value: 'College of St. Rose' },
  { label: 'College of St. Scholastica', value: 'College of St. Scholastica' },
  { label: 'College of Technology at Abha', value: 'College of Technology at Abha' },
  { label: 'College of Technology at Dammam', value: 'College of Technology at Dammam' },
  { label: 'College of Technology at Jazan', value: 'College of Technology at Jazan' },
  { label: 'College of Technology at Jeddah', value: 'College of Technology at Jeddah' },
  { label: 'College of Technology at Kharj', value: 'College of Technology at Kharj' },
  { label: 'College of Technology at Riyadh', value: 'College of Technology at Riyadh' },
  { label: 'College of Telecommunication & Information ', value: 'College of Telecommunication & Information ' },
  { label: 'College of the Atlantic', value: 'College of the Atlantic' },
  { label: 'College of the Holy Cross', value: 'College of the Holy Cross' },
  { label: 'College of the Holy Spirit', value: 'College of the Holy Spirit' },
  { label: 'College of the Ozarks', value: 'College of the Ozarks' },
  { label: 'College of the Rockies', value: 'College of the Rockies' },
  { label: 'College of Visual Arts', value: 'College of Visual Arts' },
  { label: 'College of William and Mary', value: 'College of William and Mary' },
  { label: 'CollegeAmerica, Denver', value: 'CollegeAmerica, Denver' },
  { label: 'CollegeAmerica, Phoenix', value: 'CollegeAmerica, Phoenix' },
  { label: 'Collegium Civitas', value: 'Collegium Civitas' },
  { label: 'Cologne Business School', value: 'Cologne Business School' },
  { label: 'Colorado Christian University', value: 'Colorado Christian University' },
  { label: 'Colorado College', value: 'Colorado College' },
  { label: 'Colorado School of Mines', value: 'Colorado School of Mines' },
  { label: 'Colorado State University', value: 'Colorado State University' },
  { label: 'Colorado State University-Pueblo', value: 'Colorado State University-Pueblo' },
  { label: 'Colorado Technical University', value: 'Colorado Technical University' },
  { label: 'Columbia College', value: 'Columbia College' },
  { label: 'Columbia College Chicago', value: 'Columbia College Chicago' },
  { label: 'Columbia College Hollywood', value: 'Columbia College Hollywood' },
  { label: 'Columbia College of Missouri', value: 'Columbia College of Missouri' },
  { label: 'Columbia College South Carolina', value: 'Columbia College South Carolina' },
  { label: 'Columbia Commonwealth University', value: 'Columbia Commonwealth University' },
  { label: 'Columbia International University', value: 'Columbia International University' },
  { label: 'Columbia Southern University', value: 'Columbia Southern University' },
  { label: 'Columbia Union College', value: 'Columbia Union College' },
  { label: 'Columbia University', value: 'Columbia University' },
  { label: 'Columbus College of Art and Design', value: 'Columbus College of Art and Design' },
  { label: 'Columbus State University', value: 'Columbus State University' },
  { label: 'Columbus University', value: 'Columbus University' },
  { label: 'Columbus University', value: 'Columbus University' },
  { label: 'Comenius University in Bratislava', value: 'Comenius University in Bratislava' },
  { label: 'Comilla University', value: 'Comilla University' },
  { label: 'Communication University of China', value: 'Communication University of China' },
  { label: 'Community College of Denver', value: 'Community College of Denver' },
  { label: 'Comrat State University', value: 'Comrat State University' },
  { label: 'COMSATS Institute of Information Technology', value: 'COMSATS Institute of Information Technology' },
  {
    label: 'COMSATS Institute of Information Technology, Abbottabad',
    value: 'COMSATS Institute of Information Technology, Abbottabad',
  },
  {
    label: 'COMSATS Institute of Information Technology, Attock',
    value: 'COMSATS Institute of Information Technology, Attock',
  },
  {
    label: 'COMSATS Institute of Information Technology, Lahore',
    value: 'COMSATS Institute of Information Technology, Lahore',
  },
  {
    label: 'COMSATS Institute of Information Technology, Wah',
    value: 'COMSATS Institute of Information Technology, Wah',
  },
  { label: 'Concord College', value: 'Concord College' },
  { label: 'Concordia College, Ann Arbor', value: 'Concordia College, Ann Arbor' },
  { label: 'Concordia College, Bronxville', value: 'Concordia College, Bronxville' },
  { label: 'Concordia College, Moorhead', value: 'Concordia College, Moorhead' },
  { label: 'Concordia College, Selma', value: 'Concordia College, Selma' },
  { label: 'Concordia College, Seward', value: 'Concordia College, Seward' },
  { label: 'Concordia College, St. Paul', value: 'Concordia College, St. Paul' },
  { label: 'Concordia University', value: 'Concordia University' },
  { label: 'Concordia University College of Alberta', value: 'Concordia University College of Alberta' },
  { label: 'Concordia University, Austin', value: 'Concordia University, Austin' },
  { label: 'Concordia University, Irvine', value: 'Concordia University, Irvine' },
  { label: 'Concordia University, Mequon', value: 'Concordia University, Mequon' },
  { label: 'Concordia University, Portland', value: 'Concordia University, Portland' },
  { label: 'Concordia University, River Forest', value: 'Concordia University, River Forest' },
  { label: 'Conestoga College', value: 'Conestoga College' },
  { label: 'Connecticut College', value: 'Connecticut College' },
  { label: 'Conservatoire National des Arts et Métiers', value: 'Conservatoire National des Arts et Métiers' },
  { label: 'Conservatorio del Tolima', value: 'Conservatorio del Tolima' },
  { label: 'Constantin Brancoveanu University Pitesti', value: 'Constantin Brancoveanu University Pitesti' },
  { label: 'Continental Theological Seminary', value: 'Continental Theological Seminary' },
  { label: 'Converse College', value: 'Converse College' },
  { label: 'Conway School of Landscape Design', value: 'Conway School of Landscape Design' },
  { label: 'Copenhagen Business School', value: 'Copenhagen Business School' },
  { label: 'Copenhagen University', value: 'Copenhagen University' },
  { label: 'Copperbelt University', value: 'Copperbelt University' },
  { label: 'Coppin State College', value: 'Coppin State College' },
  { label: 'Cork Institute of Technology', value: 'Cork Institute of Technology' },
  { label: 'Cornell College', value: 'Cornell College' },
  { label: 'Cornell University', value: 'Cornell University' },
  { label: 'Cornish College of the Arts', value: 'Cornish College of the Arts' },
  {
    label: 'Corporación Educativa Mayor del Desarrollo Simón Bolivar',
    value: 'Corporación Educativa Mayor del Desarrollo Simón Bolivar',
  },
  {
    label: 'Corporación Universitaria de Ibagué (CORUNIVERSITARIA)',
    value: 'Corporación Universitaria de Ibagué (CORUNIVERSITARIA)',
  },
  {
    label: 'Corporación Universitaria de la Costa (UNICOSTA)',
    value: 'Corporación Universitaria de la Costa (UNICOSTA)',
  },
  { label: 'Corporación Universitaria de Santander (UDES)', value: 'Corporación Universitaria de Santander (UDES)' },
  {
    label: 'Corporación Universitaria Tecnológica de Bolivar',
    value: 'Corporación Universitaria Tecnológica de Bolivar',
  },
  { label: 'COSMIQ Institute of Technology', value: 'COSMIQ Institute of Technology' },
  { label: 'Cosmopolitan University', value: 'Cosmopolitan University' },
  {
    label: 'Courtauld Institute of Art, University of London',
    value: 'Courtauld Institute of Art, University of London',
  },
  { label: 'Covenant College', value: 'Covenant College' },
  { label: 'Covenant University', value: 'Covenant University' },
  { label: 'Coventry University', value: 'Coventry University' },
  { label: 'Cranbrook Academy of Art', value: 'Cranbrook Academy of Art' },
  { label: 'Cranfield University', value: 'Cranfield University' },
  { label: 'Crawford University ', value: 'Crawford University ' },
  { label: 'Creighton University', value: 'Creighton University' },
  { label: 'Crescent University', value: 'Crescent University' },
  { label: 'Crichton College', value: 'Crichton College' },
  { label: 'Crimea State Medical University', value: 'Crimea State Medical University' },
  {
    label: 'Cross River University of Science and Technology',
    value: 'Cross River University of Science and Technology',
  },
  { label: 'Crown College', value: 'Crown College' },
  { label: 'Cubidor University Switzerland', value: 'Cubidor University Switzerland' },
  { label: 'Cukurova University', value: 'Cukurova University' },
  { label: 'Culver-Stockton College', value: 'Culver-Stockton College' },
  { label: 'Cumberland College', value: 'Cumberland College' },
  { label: 'Cumberland University', value: 'Cumberland University' },
  { label: 'Cumhuriyet (Republik) University', value: 'Cumhuriyet (Republik) University' },
  { label: 'Curry College', value: 'Curry College' },
  { label: 'Curtin University of Technology', value: 'Curtin University of Technology' },
  {
    label: 'Curtin University of Technology, Sarawak Campus',
    value: 'Curtin University of Technology, Sarawak Campus',
  },
  {
    label: 'Cyberjaya University College of Medical Science',
    value: 'Cyberjaya University College of Medical Science',
  },
  { label: 'Cyprus College', value: 'Cyprus College' },
  {
    label: 'Cyprus International Institute of Management (CIIM)',
    value: 'Cyprus International Institute of Management (CIIM)',
  },
  { label: 'Cyprus International University', value: 'Cyprus International University' },
  { label: 'Cyprus University of Technology', value: 'Cyprus University of Technology' },
  { label: 'Cyryx College', value: 'Cyryx College' },
  { label: 'Czech Technical University of Prague', value: 'Czech Technical University of Prague' },
  { label: 'Czech University of Agriculture Prague', value: 'Czech University of Agriculture Prague' },
  { label: "D'Youville College", value: "D'Youville College" },
  { label: 'Da-Yeh University', value: 'Da-Yeh University' },
  { label: 'Dadabhoy Institute of Higher Education', value: 'Dadabhoy Institute of Higher Education' },
  { label: 'Daebul University', value: 'Daebul University' },
  { label: 'Daemen College', value: 'Daemen College' },
  { label: 'Daffodil International University', value: 'Daffodil International University' },
  { label: 'Dagestan State University', value: 'Dagestan State University' },
  { label: 'Dai Ichi University, College of Technology', value: 'Dai Ichi University, College of Technology' },
  { label: 'Daido Institute of Technology', value: 'Daido Institute of Technology' },
  { label: 'Daiichi College of Pharmaceutical Sciences', value: 'Daiichi College of Pharmaceutical Sciences' },
  { label: 'Daiichi University of Economics', value: 'Daiichi University of Economics' },
  { label: 'Daito Bunka University', value: 'Daito Bunka University' },
  { label: 'Dakota State University', value: 'Dakota State University' },
  { label: 'Dakota Wesleyan University', value: 'Dakota Wesleyan University' },
  { label: 'Dalanj University', value: 'Dalanj University' },
  { label: 'Dalarna University College', value: 'Dalarna University College' },
  { label: 'Dalhousie University', value: 'Dalhousie University' },
  { label: 'Dalian Martime University', value: 'Dalian Martime University' },
  { label: 'Dalian Medical University', value: 'Dalian Medical University' },
  { label: 'Dalian Polytechnic University', value: 'Dalian Polytechnic University' },
  { label: 'Dalian University', value: 'Dalian University' },
  { label: 'Dalian University of Foreign Language', value: 'Dalian University of Foreign Language' },
  { label: 'Dalian University of Technology', value: 'Dalian University of Technology' },
  { label: 'Dallas Baptist University', value: 'Dallas Baptist University' },
  { label: 'Dallas Christian College', value: 'Dallas Christian College' },
  { label: 'Damanhour University', value: 'Damanhour University' },
  { label: 'Damascus University', value: 'Damascus University' },
  { label: 'Damghan University of Basic Sciences', value: 'Damghan University of Basic Sciences' },
  { label: 'Damietta University', value: 'Damietta University' },
  { label: 'Dammam Community College', value: 'Dammam Community College' },
  { label: 'Dana College', value: 'Dana College' },
  { label: 'Danang College Of Technology', value: 'Danang College Of Technology' },
  { label: 'Daneshestan Institute of Higher Education', value: 'Daneshestan Institute of Higher Education' },
  { label: 'Dániel Berzsenyi Teacher Training College', value: 'Dániel Berzsenyi Teacher Training College' },
  { label: 'Daniel Webster College', value: 'Daniel Webster College' },
  { label: 'Danish Business Academy', value: 'Danish Business Academy' },
  { label: 'Danish University of Education', value: 'Danish University of Education' },
  { label: 'Dankook University', value: 'Dankook University' },
  { label: 'Danville Area Community College', value: 'Danville Area Community College' },
  { label: 'Dar al Hekma College', value: 'Dar al Hekma College' },
  { label: 'Dar Al-Uloom Colleges', value: 'Dar Al-Uloom Colleges' },
  { label: 'Dares Salaam Institute of Technology', value: 'Dares Salaam Institute of Technology' },
  { label: 'Dartmouth College', value: 'Dartmouth College' },
  { label: 'Darton College', value: 'Darton College' },
  { label: 'Darul Hikmah Islamic College', value: 'Darul Hikmah Islamic College' },
  { label: 'Darul Huda Islamic University', value: 'Darul Huda Islamic University' },
  { label: 'Darul Ihsan University', value: 'Darul Ihsan University' },
  { label: 'Darul Naim College of Technology', value: 'Darul Naim College of Technology' },
  { label: 'Darul Quran Islamic College University', value: 'Darul Quran Islamic College University' },
  { label: 'Darul Takzim Institute of Technology', value: 'Darul Takzim Institute of Technology' },
  { label: 'Darul Ulum Islamic College', value: 'Darul Ulum Islamic College' },
  { label: 'Daugavpils University', value: 'Daugavpils University' },
  { label: 'Dav University', value: 'Dav University' },
  { label: 'Davao Doctors College', value: 'Davao Doctors College' },
  { label: 'Davenport College of Business, Grand Rapids', value: 'Davenport College of Business, Grand Rapids' },
  { label: 'Davenport College of Business, Kalamazoo', value: 'Davenport College of Business, Kalamazoo' },
  { label: 'Davenport College of Business, Lansing', value: 'Davenport College of Business, Lansing' },
  { label: 'Davidson College', value: 'Davidson College' },
  { label: 'Davis and Elkins College', value: 'Davis and Elkins College' },
  { label: 'Dawat University', value: 'Dawat University' },
  { label: 'Dawood College of Engineering and Technology', value: 'Dawood College of Engineering and Technology' },
  { label: 'Dayalbagh Educational Institute', value: 'Dayalbagh Educational Institute' },
  { label: 'Daystar University', value: 'Daystar University' },
  {
    label: 'DCT International Hotel & Business Management School',
    value: 'DCT International Hotel & Business Management School',
  },
  { label: 'De Haagse Hogeschool', value: 'De Haagse Hogeschool' },
  { label: 'De La Salle University', value: 'De La Salle University' },
  { label: 'De La Salle University, Araneta', value: 'De La Salle University, Araneta' },
  { label: 'De Montfort University Leicester', value: 'De Montfort University Leicester' },
  { label: 'Deaconess College of Nursing', value: 'Deaconess College of Nursing' },
  { label: 'Deakin University', value: 'Deakin University' },
  { label: 'Debre Birhan University', value: 'Debre Birhan University' },
  { label: 'Debre Markos University', value: 'Debre Markos University' },
  { label: 'Debrecen University of Agricultural Sciences', value: 'Debrecen University of Agricultural Sciences' },
  {
    label: 'Deccan College Postgraduate and Research Institute',
    value: 'Deccan College Postgraduate and Research Institute',
  },
  { label: 'Dedan Kimathi University of Technology', value: 'Dedan Kimathi University of Technology' },
  { label: 'Deen Dayal Upadhyay Gorakhpur University', value: 'Deen Dayal Upadhyay Gorakhpur University' },
  { label: 'DEI Bachelor & Master Degrees', value: 'DEI Bachelor & Master Degrees' },
  { label: 'Delaware State University', value: 'Delaware State University' },
  { label: 'Delaware Valley College', value: 'Delaware Valley College' },
  { label: 'Delft University of Technology', value: 'Delft University of Technology' },
  { label: 'Delhi College of Engineering (DCE)', value: 'Delhi College of Engineering (DCE)' },
  { label: 'Delijan Payame Noor University', value: 'Delijan Payame Noor University' },
  { label: 'Delta International University', value: 'Delta International University' },
  { label: 'Delta State University', value: 'Delta State University' },
  { label: 'Delta University', value: 'Delta University' },
  { label: 'Denison University', value: 'Denison University' },
  { label: 'Denver Paralegal Institute', value: 'Denver Paralegal Institute' },
  { label: 'Denver Technical College', value: 'Denver Technical College' },
  { label: 'DePaul University', value: 'DePaul University' },
  { label: 'DePauw University', value: 'DePauw University' },
  { label: 'DeSales University', value: 'DeSales University' },
  { label: 'Design Institute of San Diego', value: 'Design Institute of San Diego' },
  { label: 'Detroit College of Business', value: 'Detroit College of Business' },
  { label: 'Detroit College of Business - Flint', value: 'Detroit College of Business - Flint' },
  { label: 'Detroit College of Business - Warren', value: 'Detroit College of Business - Warren' },
  { label: 'Detroit College of Law', value: 'Detroit College of Law' },
  {
    label: 'Deutsch-Ordens Fachhochschule Riedlingen, Hochschule für Wirtschaft',
    value: 'Deutsch-Ordens Fachhochschule Riedlingen, Hochschule für Wirtschaft',
  },
  {
    label: 'Deutsche Hochschule für Verwaltungswissenschaften Speyer',
    value: 'Deutsche Hochschule für Verwaltungswissenschaften Speyer',
  },
  { label: 'Deutsche Sporthochschule Köln', value: 'Deutsche Sporthochschule Köln' },
  { label: 'Deutsche Telekom Fachhochschule Leipzig', value: 'Deutsche Telekom Fachhochschule Leipzig' },
  { label: 'Devi Ahilya University of Indore', value: 'Devi Ahilya University of Indore' },
  { label: 'DeVry Institute of Technology', value: 'DeVry Institute of Technology' },
  { label: 'DeVry Institute of Technology, Chicago', value: 'DeVry Institute of Technology, Chicago' },
  { label: 'DeVry Institute of Technology, Columbus', value: 'DeVry Institute of Technology, Columbus' },
  { label: 'DeVry Institute of Technology, Decatur', value: 'DeVry Institute of Technology, Decatur' },
  { label: 'DeVry Institute of Technology, DuPage', value: 'DeVry Institute of Technology, DuPage' },
  { label: 'DeVry Institute of Technology, Irving', value: 'DeVry Institute of Technology, Irving' },
  { label: 'DeVry Institute of Technology, Kansas City', value: 'DeVry Institute of Technology, Kansas City' },
  { label: 'DeVry Institute of Technology, Phoenix', value: 'DeVry Institute of Technology, Phoenix' },
  { label: 'DeVry Institute of Technology, Pomona', value: 'DeVry Institute of Technology, Pomona' },
  { label: 'Deylaman Institute of Higher Education', value: 'Deylaman Institute of Higher Education' },
  { label: 'Dhaka International University', value: 'Dhaka International University' },
  { label: 'Dhaka University of Engineering and Technology', value: 'Dhaka University of Engineering and Technology' },
  {
    label: 'Dhirubhai Ambani Institute of Information and Communication Technology',
    value: 'Dhirubhai Ambani Institute of Information and Communication Technology',
  },
  { label: 'Dhofar University', value: 'Dhofar University' },
  { label: 'Dhurakijpundit University', value: 'Dhurakijpundit University' },
  { label: 'Diaconia University of Applied Sciences', value: 'Diaconia University of Applied Sciences' },
  { label: 'Dibrugarh University', value: 'Dibrugarh University' },
  { label: 'Dickinson College', value: 'Dickinson College' },
  { label: 'Dickinson State University', value: 'Dickinson State University' },
  { label: 'Dicle (Tirgris) University', value: 'Dicle (Tirgris) University' },
  { label: 'Dijla University College', value: 'Dijla University College' },
  { label: 'Dilla University', value: 'Dilla University' },
  { label: 'Dillard University', value: 'Dillard University' },
  { label: 'Dimocritus University of Thrace', value: 'Dimocritus University of Thrace' },
  { label: 'DIPLOMA-Fachhochschule Ölsnitz/Vogtland', value: 'DIPLOMA-Fachhochschule Ölsnitz/Vogtland' },
  { label: 'Dire Dawa University', value: 'Dire Dawa University' },
  { label: 'Dirección General de Institutos Tecnológicos', value: 'Dirección General de Institutos Tecnológicos' },
  { label: 'Divine Word College', value: 'Divine Word College' },
  { label: 'Divine Word College of Legazpi', value: 'Divine Word College of Legazpi' },
  { label: 'Divine Word University', value: 'Divine Word University' },
  { label: 'Dixie College', value: 'Dixie College' },
  { label: 'Diyala University', value: 'Diyala University' },
  { label: 'Dneprodzerzhinsk State Technical University', value: 'Dneprodzerzhinsk State Technical University' },
  { label: 'Dnepropetrovsk National University', value: 'Dnepropetrovsk National University' },
  {
    label: 'Dnepropetrovsk National University of Railway Transport',
    value: 'Dnepropetrovsk National University of Railway Transport',
  },
  { label: 'Doane College', value: 'Doane College' },
  { label: 'Dogus University', value: 'Dogus University' },
  { label: 'Doho University', value: 'Doho University' },
  { label: 'Dohto University', value: 'Dohto University' },
  { label: 'Dokkyo University', value: 'Dokkyo University' },
  { label: 'Dokkyo University School of Medicine', value: 'Dokkyo University School of Medicine' },
  { label: 'Dokuz Eylül University', value: 'Dokuz Eylül University' },
  { label: 'Dominica State College', value: 'Dominica State College' },
  { label: 'Dominican College', value: 'Dominican College' },
  { label: 'Dominican College of Philosophy and Theology', value: 'Dominican College of Philosophy and Theology' },
  { label: 'Dominican College of San Rafael', value: 'Dominican College of San Rafael' },
  { label: 'Dominican School of Philosophy and Theology', value: 'Dominican School of Philosophy and Theology' },
  { label: 'Dominican University', value: 'Dominican University' },
  { label: 'Dominion College', value: 'Dominion College' },
  { label: 'Don Mariano Marcos Memorial State University', value: 'Don Mariano Marcos Memorial State University' },
  { label: 'Don State Agrarian University', value: 'Don State Agrarian University' },
  { label: 'Don State Technical University', value: 'Don State Technical University' },
  { label: 'Donau-Universität Krems', value: 'Donau-Universität Krems' },
  { label: 'Donetsk National Technical University', value: 'Donetsk National Technical University' },
  { label: 'Donetsk National University', value: 'Donetsk National University' },
  { label: 'Donetsk State Medical University', value: 'Donetsk State Medical University' },
  { label: 'Dong Eui University', value: 'Dong Eui University' },
  { label: 'Dong Yang University of Technology', value: 'Dong Yang University of Technology' },
  { label: 'Dong-A University', value: 'Dong-A University' },
  { label: 'Dongbei University of Finance And Economics', value: 'Dongbei University of Finance And Economics' },
  { label: "Dongduk Women's University", value: "Dongduk Women's University" },
  { label: 'Dongguk University', value: 'Dongguk University' },
  { label: 'Donghua University, Shanghai', value: 'Donghua University, Shanghai' },
  { label: 'Dongseo University', value: 'Dongseo University' },
  { label: 'Dongshin University', value: 'Dongshin University' },
  { label: 'Dordt College', value: 'Dordt College' },
  { label: 'Doshisha University', value: 'Doshisha University' },
  { label: "Doshisha Women's College of Liberal Arts", value: "Doshisha Women's College of Liberal Arts" },
  { label: 'Douglas College', value: 'Douglas College' },
  { label: 'Dow University of Health Sciences', value: 'Dow University of Health Sciences' },
  { label: 'Dowling College', value: 'Dowling College' },
  { label: 'Dr. B.R. Ambedkar Open University', value: 'Dr. B.R. Ambedkar Open University' },
  { label: 'Dr. Babasaheb Ambedkar Marathwada Universtiy', value: 'Dr. Babasaheb Ambedkar Marathwada Universtiy' },
  {
    label: 'Dr. Babasaheb Ambedkar Technological University',
    value: 'Dr. Babasaheb Ambedkar Technological University',
  },
  { label: 'Dr. Bhim Rao Abdekar University', value: 'Dr. Bhim Rao Abdekar University' },
  { label: 'Dr. C.V. Raman University', value: 'Dr. C.V. Raman University' },
  { label: 'Dr. D.Y. Patil University', value: 'Dr. D.Y. Patil University' },
  {
    label: 'Dr. Hari Singh Gour University, formerly University of Sagar',
    value: 'Dr. Hari Singh Gour University, formerly University of Sagar',
  },
  { label: 'Dr. Panjabrao Deshmukh Krishi Vidyapeeth', value: 'Dr. Panjabrao Deshmukh Krishi Vidyapeeth' },
  { label: 'Dr. Ram Manohar Lohia Avadh University', value: 'Dr. Ram Manohar Lohia Avadh University' },
  {
    label: 'Dr. YS Parmar University of Horticulture and Forestry',
    value: 'Dr. YS Parmar University of Horticulture and Forestry',
  },
  { label: 'Drake University', value: 'Drake University' },
  { label: 'Dravidian University', value: 'Dravidian University' },
  { label: 'Drew University', value: 'Drew University' },
  { label: 'Drexel University', value: 'Drexel University' },
  { label: 'Drury College', value: 'Drury College' },
  { label: 'Drzavni Univerzitet u Novom Pazaru', value: 'Drzavni Univerzitet u Novom Pazaru' },
  { label: 'Duale Hochschule Baden-Württemberg', value: 'Duale Hochschule Baden-Württemberg' },
  { label: 'Dubai Medical College for Girls', value: 'Dubai Medical College for Girls' },
  { label: 'Dubai Pharmacy College', value: 'Dubai Pharmacy College' },
  { label: 'Dublin City University', value: 'Dublin City University' },
  { label: 'Dublin Institute for Advanced Studies', value: 'Dublin Institute for Advanced Studies' },
  { label: 'Dublin Institute of Technology', value: 'Dublin Institute of Technology' },
  {
    label: 'Dubna International University for Nature, Society and Man',
    value: 'Dubna International University for Nature, Society and Man',
  },
  { label: 'Duke University', value: 'Duke University' },
  { label: "Duksung Women's University", value: "Duksung Women's University" },
  { label: 'Duluth Business University', value: 'Duluth Business University' },
  { label: 'Dumlupinar University', value: 'Dumlupinar University' },
  { label: 'Dunya Institute of Higher Education', value: 'Dunya Institute of Higher Education' },
  { label: 'Duquesne University', value: 'Duquesne University' },
  { label: 'Durban Institute of Technology', value: 'Durban Institute of Technology' },
  { label: 'Durham College', value: 'Durham College' },
  { label: 'Dutch Delta University', value: 'Dutch Delta University' },
  {
    label: 'Dutch University Institute for Art History (DUIA)',
    value: 'Dutch University Institute for Art History (DUIA)',
  },
  {
    label: 'E.A.P. Europäische Wirtschaftshochschule Berlin',
    value: 'E.A.P. Europäische Wirtschaftshochschule Berlin',
  },
  { label: 'Earlham College', value: 'Earlham College' },
  { label: 'EarthNet Institute', value: 'EarthNet Institute' },
  { label: 'East Africa University Bosaso', value: 'East Africa University Bosaso' },
  { label: 'East Carolina University', value: 'East Carolina University' },
  { label: 'East Central University', value: 'East Central University' },
  { label: 'East China Jiao Tong University', value: 'East China Jiao Tong University' },
  { label: 'East China Normal University', value: 'East China Normal University' },
  {
    label: 'East China University of Science and Technology',
    value: 'East China University of Science and Technology',
  },
  { label: 'East Delta University', value: 'East Delta University' },
  { label: 'East Kazakhstan State University', value: 'East Kazakhstan State University' },
  { label: 'East Kazakstan State Technical University', value: 'East Kazakstan State Technical University' },
  { label: 'East Somalia University', value: 'East Somalia University' },
  { label: 'East Stroudsburg State University', value: 'East Stroudsburg State University' },
  { label: 'East Tennessee State University', value: 'East Tennessee State University' },
  { label: 'East Texas Baptist University', value: 'East Texas Baptist University' },
  { label: 'East Ukrainian National University', value: 'East Ukrainian National University' },
  { label: 'East West University', value: 'East West University' },
  { label: 'East-Siberian State Institute of Culture', value: 'East-Siberian State Institute of Culture' },
  { label: 'East-Siberian State University', value: 'East-Siberian State University' },
  { label: 'East-Siberian State University of Technology', value: 'East-Siberian State University of Technology' },
  { label: 'East-West University', value: 'East-West University' },
  { label: 'East-West University, Mohakhali', value: 'East-West University, Mohakhali' },
  { label: 'Eastern Asia University', value: 'Eastern Asia University' },
  { label: 'Eastern College', value: 'Eastern College' },
  { label: 'Eastern Connecticut State University', value: 'Eastern Connecticut State University' },
  { label: 'Eastern Conservatory of Music', value: 'Eastern Conservatory of Music' },
  { label: 'Eastern Illinois University', value: 'Eastern Illinois University' },
  { label: 'Eastern Kentucky University', value: 'Eastern Kentucky University' },
  { label: 'Eastern Mediterranean University', value: 'Eastern Mediterranean University' },
  { label: 'Eastern Mennonite University', value: 'Eastern Mennonite University' },
  { label: 'Eastern Michigan University', value: 'Eastern Michigan University' },
  { label: 'Eastern Nazarene College', value: 'Eastern Nazarene College' },
  { label: 'Eastern New Mexico University', value: 'Eastern New Mexico University' },
  { label: 'Eastern Oregon University', value: 'Eastern Oregon University' },
  { label: 'Eastern University', value: 'Eastern University' },
  { label: 'Eastern University of Sri Lanka', value: 'Eastern University of Sri Lanka' },
  { label: 'Eastern Virginia Medical School', value: 'Eastern Virginia Medical School' },
  { label: 'Eastern Washington University', value: 'Eastern Washington University' },
  { label: 'Eberhard-Karls-Universität Tübingen', value: 'Eberhard-Karls-Universität Tübingen' },
  { label: 'Ebonyi State University', value: 'Ebonyi State University' },
  { label: 'ECAM - Institut Supérieur Industriel', value: 'ECAM - Institut Supérieur Industriel' },
  { label: 'Eckerd College', value: 'Eckerd College' },
  { label: "Ecole Catholique d'Arts & Metiers", value: "Ecole Catholique d'Arts & Metiers" },
  { label: "Ecole Centrale d'Electronique - ECE", value: "Ecole Centrale d'Electronique - ECE" },
  { label: 'Ecole Centrale de Lille', value: 'Ecole Centrale de Lille' },
  { label: 'Ecole Centrale de Lyon', value: 'Ecole Centrale de Lyon' },
  { label: 'Ecole Centrale de Nantes', value: 'Ecole Centrale de Nantes' },
  { label: "Ecole d'Architecture de Nancy", value: "Ecole d'Architecture de Nancy" },
  {
    label: "Ecole d'Ingénieurs en Informatique pour l'Industrie",
    value: "Ecole d'Ingénieurs en Informatique pour l'Industrie",
  },
  { label: "Ecole de l'Air", value: "Ecole de l'Air" },
  {
    label: 'École de technologie supérieure, Université du Québec',
    value: 'École de technologie supérieure, Université du Québec',
  },
  { label: 'Ecole des Hautes Etudes Commerciales', value: 'Ecole des Hautes Etudes Commerciales' },
  { label: 'École des Hautes Études Commerciales', value: 'École des Hautes Études Commerciales' },
  { label: 'Ecole des Hautes Etudes Commerciales du Nord', value: 'Ecole des Hautes Etudes Commerciales du Nord' },
  { label: 'Ecole des Hautes Etudes Commerciales MAROC', value: 'Ecole des Hautes Etudes Commerciales MAROC' },
  {
    label: 'Ecole des Hautes Etudes en Gestion Informatique et Communication',
    value: 'Ecole des Hautes Etudes en Gestion Informatique et Communication',
  },
  { label: 'Ecole des Hautes Etudes Industrielles de Lille', value: 'Ecole des Hautes Etudes Industrielles de Lille' },
  { label: 'Ecole des Ingénieurs de la Ville de Paris', value: 'Ecole des Ingénieurs de la Ville de Paris' },
  { label: 'Ecole Européen des Affaires', value: 'Ecole Européen des Affaires' },
  {
    label: 'Ecole Européenne de Chimie, Polymères et Matériaux de Strasbourg',
    value: 'Ecole Européenne de Chimie, Polymères et Matériaux de Strasbourg',
  },
  {
    label: "Ecole Française d'Electronique et d'Informatique",
    value: "Ecole Française d'Electronique et d'Informatique",
  },
  {
    label: 'Ecole Française de Papeterie et des Industries Graphiques',
    value: 'Ecole Française de Papeterie et des Industries Graphiques',
  },
  { label: "Ecole Mohammadia d'Ingénieurs", value: "Ecole Mohammadia d'Ingénieurs" },
  { label: "Ecole National d'Agriculture de Meknes", value: "Ecole National d'Agriculture de Meknes" },
  { label: "Ecole Nationale d'Administration", value: "Ecole Nationale d'Administration" },
  {
    label: "École nationale d'administration publique, Université du Québec",
    value: "École nationale d'administration publique, Université du Québec",
  },
  { label: "Ecole Nationale d'Architecture", value: "Ecole Nationale d'Architecture" },
  { label: "Ecole Nationale d'Ingénieurs de Metz", value: "Ecole Nationale d'Ingénieurs de Metz" },
  { label: "Ecole Nationale d'Ingénieurs de Saint-Etienne", value: "Ecole Nationale d'Ingénieurs de Saint-Etienne" },
  { label: "Ecole Nationale d'Ingénieurs de Tarbes", value: "Ecole Nationale d'Ingénieurs de Tarbes" },
  {
    label: "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
    value: "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
  },
  {
    label: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
    value: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
  },
  {
    label: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
    value: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
  },
  { label: "Ecole Nationale de l'Aviation Civile", value: "Ecole Nationale de l'Aviation Civile" },
  { label: 'Ecole Nationale de la Météorologie', value: 'Ecole Nationale de la Météorologie' },
  {
    label: "Ecole Nationale de la Statistique et de l'Administration Economique",
    value: "Ecole Nationale de la Statistique et de l'Administration Economique",
  },
  {
    label: "Ecole Nationale de la Statistique et de l'Analyse de l'information",
    value: "Ecole Nationale de la Statistique et de l'Analyse de l'information",
  },
  { label: 'Ecole Nationale des Ponts et Chausees', value: 'Ecole Nationale des Ponts et Chausees' },
  { label: 'Ecole Nationale des Sciences Géographiques', value: 'Ecole Nationale des Sciences Géographiques' },
  { label: "Ecole Nationale des Travaux Publics de l'Etat", value: "Ecole Nationale des Travaux Publics de l'Etat" },
  {
    label: "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
    value: "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
  },
  {
    label: "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
    value: "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
  },
  { label: 'Ecole Nationale Supérieur de Géologie de Nancy', value: 'Ecole Nationale Supérieur de Géologie de Nancy' },
  {
    label: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
    value: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
  },
  {
    label: 'Ecole Nationale Supérieur de Mécaniques et des Microtechniques',
    value: 'Ecole Nationale Supérieur de Mécaniques et des Microtechniques',
  },
  {
    label: "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
    value: "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
  },
  {
    label: 'Ecole Nationale Supérieure Agronomique de Toulouse',
    value: 'Ecole Nationale Supérieure Agronomique de Toulouse',
  },
  {
    label: "Ecole Nationale Supérieure d'Agronomie de Montpellier",
    value: "Ecole Nationale Supérieure d'Agronomie de Montpellier",
  },
  {
    label: "Ecole Nationale Supérieure d'Agronomie de Rennes",
    value: "Ecole Nationale Supérieure d'Agronomie de Rennes",
  },
  {
    label: "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
    value: "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
  },
  {
    label: "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
    value: "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
  },
  {
    label: "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble",
    value: "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble",
  },
  {
    label: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
    value: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
  },
  {
    label: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble",
    value: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble",
  },
  {
    label: "Ecole Nationale Supérieure d'Electronique, d'Electrotechnique, d'Informatique et d'Hydraulique de Toulouse",
    value: "Ecole Nationale Supérieure d'Electronique, d'Electrotechnique, d'Informatique et d'Hydraulique de Toulouse",
  },
  {
    label: "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble",
    value: "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble",
  },
  {
    label: "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble",
    value: "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble",
  },
  {
    label: "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques",
    value: "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques",
  },
  {
    label: "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique",
    value: "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique",
  },
  {
    label: "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble",
    value: "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble",
  },
  {
    label: "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
    value: "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
  },
  {
    label: "Ecole Nationale Supérieure de Biologie Appliquée à la Nutrition et à l'Alementation",
    value: "Ecole Nationale Supérieure de Biologie Appliquée à la Nutrition et à l'Alementation",
  },
  {
    label: 'Ecole Nationale Supérieure de Chimie de Clermont-Ferrand',
    value: 'Ecole Nationale Supérieure de Chimie de Clermont-Ferrand',
  },
  { label: 'Ecole Nationale Supérieure de Chimie de Lille', value: 'Ecole Nationale Supérieure de Chimie de Lille' },
  {
    label: 'Ecole Nationale Supérieure de Chimie de Montpellier',
    value: 'Ecole Nationale Supérieure de Chimie de Montpellier',
  },
  {
    label: 'Ecole Nationale Supérieure de Chimie de Mulhouse',
    value: 'Ecole Nationale Supérieure de Chimie de Mulhouse',
  },
  { label: 'Ecole Nationale Supérieure de Chimie de Paris', value: 'Ecole Nationale Supérieure de Chimie de Paris' },
  { label: 'Ecole Nationale Supérieure de Chimie de Rennes', value: 'Ecole Nationale Supérieure de Chimie de Rennes' },
  {
    label: 'Ecole Nationale Supérieure de Chimie de Toulouse',
    value: 'Ecole Nationale Supérieure de Chimie de Toulouse',
  },
  {
    label: 'Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux',
    value: 'Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux',
  },
  {
    label: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
    value: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
  },
  {
    label: "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
    value: "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
  },
  {
    label: 'Ecole Nationale Supérieure de Physique de Grenoble',
    value: 'Ecole Nationale Supérieure de Physique de Grenoble',
  },
  {
    label: 'Ecole Nationale Supérieure de Physique de Marseille',
    value: 'Ecole Nationale Supérieure de Physique de Marseille',
  },
  {
    label: 'Ecole Nationale Supérieure de Physique de Strasbourg',
    value: 'Ecole Nationale Supérieure de Physique de Strasbourg',
  },
  {
    label: 'Ecole Nationale Supérieure de Techniques Avancées',
    value: 'Ecole Nationale Supérieure de Techniques Avancées',
  },
  {
    label: 'Ecole Nationale Supérieure des Arts et Industries de Strasbourg',
    value: 'Ecole Nationale Supérieure des Arts et Industries de Strasbourg',
  },
  {
    label: 'Ecole Nationale Supérieure des Arts et Industries Textiles',
    value: 'Ecole Nationale Supérieure des Arts et Industries Textiles',
  },
  {
    label: 'Ecole Nationale Supérieure des Industries Chimiques de Nancy',
    value: 'Ecole Nationale Supérieure des Industries Chimiques de Nancy',
  },
  {
    label: 'Ecole Nationale Supérieure des Industries Textiles de Mulhouse',
    value: 'Ecole Nationale Supérieure des Industries Textiles de Mulhouse',
  },
  { label: "Ecole Nationale Supérieure des Mines d'Alès", value: "Ecole Nationale Supérieure des Mines d'Alès" },
  { label: 'Ecole Nationale Supérieure des Mines de Douai', value: 'Ecole Nationale Supérieure des Mines de Douai' },
  { label: 'Ecole Nationale Supérieure des Mines de Nancy', value: 'Ecole Nationale Supérieure des Mines de Nancy' },
  { label: 'Ecole Nationale Supérieure des Mines de Paris', value: 'Ecole Nationale Supérieure des Mines de Paris' },
  {
    label: 'Ecole Nationale Supérieure des Mines de St-Etienne',
    value: 'Ecole Nationale Supérieure des Mines de St-Etienne',
  },
  {
    label: 'Ecole Nationale Supérieure des Telecommunications de Bretagne',
    value: 'Ecole Nationale Supérieure des Telecommunications de Bretagne',
  },
  {
    label: 'Ecole Nationale Supérieure des Telecommunications de Paris',
    value: 'Ecole Nationale Supérieure des Telecommunications de Paris',
  },
  {
    label: 'Ecole Nationale Supérieure du Pétrole et des Monteurs',
    value: 'Ecole Nationale Supérieure du Pétrole et des Monteurs',
  },
  {
    label: 'Ecole Nationale Supérieure Electricité et Mécanique',
    value: 'Ecole Nationale Supérieure Electricité et Mécanique',
  },
  {
    label: 'Ecole Nationale Supérieure en Electrotechnique, Electronique, Informatique et Hydraulique de Toulouse',
    value: 'Ecole Nationale Supérieure en Electrotechnique, Electronique, Informatique et Hydraulique de Toulouse',
  },
  { label: "Ecole Nationale Vétérinaire d'Alfort", value: "Ecole Nationale Vétérinaire d'Alfort" },
  { label: 'Ecole Nationale Vétérinaire de Lyon', value: 'Ecole Nationale Vétérinaire de Lyon' },
  { label: 'Ecole Nationale Vétérinaire de Nantes', value: 'Ecole Nationale Vétérinaire de Nantes' },
  { label: 'Ecole Nationale Vétérinaire de Toulouse', value: 'Ecole Nationale Vétérinaire de Toulouse' },
  { label: 'Ecole Normale Supérieure de Cachan', value: 'Ecole Normale Supérieure de Cachan' },
  {
    label: 'Ecole Normale Supérieure de Fontenay-Saint Cloud',
    value: 'Ecole Normale Supérieure de Fontenay-Saint Cloud',
  },
  { label: 'Ecole Normale Supérieure de Lyon', value: 'Ecole Normale Supérieure de Lyon' },
  { label: 'Ecole Normale Supérieure de Paris', value: 'Ecole Normale Supérieure de Paris' },
  { label: 'Ecole Polytechnique', value: 'Ecole Polytechnique' },
  {
    label: 'École Polytechnique de Montréal, Université de Montréal',
    value: 'École Polytechnique de Montréal, Université de Montréal',
  },
  { label: 'Ecole Polytechnique Marseille', value: 'Ecole Polytechnique Marseille' },
  { label: 'Ecole Polytechnique Universitaire de Lille', value: 'Ecole Polytechnique Universitaire de Lille' },
  {
    label: 'Ecole pour les Etudes et la Recherche en Informatique et Electronique',
    value: 'Ecole pour les Etudes et la Recherche en Informatique et Electronique',
  },
  { label: "Ecole Spéciale de Mécanique et d'Electricité", value: "Ecole Spéciale de Mécanique et d'Electricité" },
  {
    label: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
    value: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
  },
  { label: "Ecole Superieur d'Ingenieurs Leonard de Vinci", value: "Ecole Superieur d'Ingenieurs Leonard de Vinci" },
  { label: "Ecole Supérieure d'Agriculture d'Angers", value: "Ecole Supérieure d'Agriculture d'Angers" },
  { label: "Ecole Supérieure d'Agriculture de Purpan", value: "Ecole Supérieure d'Agriculture de Purpan" },
  { label: "Ecole Supérieure d'Electricité", value: "Ecole Supérieure d'Electricité" },
  { label: "Ecole Supérieure d'Electronique de l'Ouest", value: "Ecole Supérieure d'Electronique de l'Ouest" },
  {
    label: "Ecole Supérieure d'Informatique et de Management",
    value: "Ecole Supérieure d'Informatique et de Management",
  },
  {
    label: "Ecole Supérieure d'Informatique-Electronique-Automatique",
    value: "Ecole Supérieure d'Informatique-Electronique-Automatique",
  },
  { label: "Ecole Supérieure d'Ingénieurs de Marseille", value: "Ecole Supérieure d'Ingénieurs de Marseille" },
  {
    label: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
    value: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
  },
  {
    label: "Ecole Supérieure d'Ingénieurs en Génie Electrique",
    value: "Ecole Supérieure d'Ingénieurs en Génie Electrique",
  },
  {
    label: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
    value: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
  },
  { label: "Ecole Supérieure d'Optique", value: "Ecole Supérieure d'Optique" },
  {
    label: 'Ecole Supérieure de Chimie Organique et Minérale',
    value: 'Ecole Supérieure de Chimie Organique et Minérale',
  },
  {
    label: 'Ecole Supérieure de Chimie Physique Electronique de Lyon',
    value: 'Ecole Supérieure de Chimie Physique Electronique de Lyon',
  },
  { label: 'Ecole Supérieure de Commerce de Bordeaux', value: 'Ecole Supérieure de Commerce de Bordeaux' },
  { label: 'Ecole Supérieure de Commerce de Brest', value: 'Ecole Supérieure de Commerce de Brest' },
  {
    label: 'Ecole Supérieure de Commerce de Clermont-Ferrand',
    value: 'Ecole Supérieure de Commerce de Clermont-Ferrand',
  },
  { label: 'Ecole Supérieure de Commerce de Dijon', value: 'Ecole Supérieure de Commerce de Dijon' },
  { label: 'Ecole Supérieure de Commerce de Grenoble', value: 'Ecole Supérieure de Commerce de Grenoble' },
  { label: 'Ecole Supérieure de Commerce de Le Havre/Caen', value: 'Ecole Supérieure de Commerce de Le Havre/Caen' },
  { label: 'Ecole Supérieure de Commerce de Lille', value: 'Ecole Supérieure de Commerce de Lille' },
  { label: 'Ecole Supérieure de Commerce de Lyon', value: 'Ecole Supérieure de Commerce de Lyon' },
  {
    label: 'Ecole Supérieure de Commerce de Marseille-Provence',
    value: 'Ecole Supérieure de Commerce de Marseille-Provence',
  },
  {
    label: 'Ecole Supérieure de Commerce de Nantes-Atlantique',
    value: 'Ecole Supérieure de Commerce de Nantes-Atlantique',
  },
  { label: 'Ecole Supérieure de Commerce de Paris', value: 'Ecole Supérieure de Commerce de Paris' },
  { label: 'Ecole Supérieure de Commerce de Pau', value: 'Ecole Supérieure de Commerce de Pau' },
  { label: 'Ecole Supérieure de Commerce de Reims', value: 'Ecole Supérieure de Commerce de Reims' },
  { label: 'Ecole Supérieure de Commerce de Rouen', value: 'Ecole Supérieure de Commerce de Rouen' },
  {
    label: 'Ecole Supérieure de Commerce de Sophia Antipolis',
    value: 'Ecole Supérieure de Commerce de Sophia Antipolis',
  },
  { label: 'Ecole Supérieure de Commerce de Toulouse', value: 'Ecole Supérieure de Commerce de Toulouse' },
  { label: 'Ecole Supérieure de Commerce et des Affaires', value: 'Ecole Supérieure de Commerce et des Affaires' },
  { label: 'Ecole Supérieure de Commerce et Management', value: 'Ecole Supérieure de Commerce et Management' },
  {
    label: 'Ecole Supérieure de Physique et de Chimie Industrielles',
    value: 'Ecole Supérieure de Physique et de Chimie Industrielles',
  },
  {
    label: "Ecole Supérieure des Sciences Commerciales d'Angers",
    value: "Ecole Supérieure des Sciences Commerciales d'Angers",
  },
  {
    label: 'Ecole Supérieure des Sciences Economiques et Commerciales',
    value: 'Ecole Supérieure des Sciences Economiques et Commerciales',
  },
  {
    label: "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
    value: "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
  },
  {
    label: 'Ecole Supérieure des Techniques Industrielles et des Textiles',
    value: 'Ecole Supérieure des Techniques Industrielles et des Textiles',
  },
  { label: 'Ecole Superieure des Télécommunications', value: 'Ecole Superieure des Télécommunications' },
  {
    label: "Ecole Supérieure Internationale d'Administration des Entreprises",
    value: "Ecole Supérieure Internationale d'Administration des Entreprises",
  },
  { label: 'Ecole Superieure Robert de Sorbon', value: 'Ecole Superieure Robert de Sorbon' },
  { label: "Ecole Universitaire d'Ingénieurs de Lille", value: "Ecole Universitaire d'Ingénieurs de Lille" },
  { label: 'Edgewood College', value: 'Edgewood College' },
  { label: 'Edinboro University of Pennsylvania', value: 'Edinboro University of Pennsylvania' },
  { label: 'Edison Community College', value: 'Edison Community College' },
  { label: 'Edith Cowan University', value: 'Edith Cowan University' },
  { label: 'Edogawa University', value: 'Edogawa University' },
  { label: 'Edward Waters College', value: 'Edward Waters College' },
  { label: 'Eelo American University', value: 'Eelo American University' },
  { label: 'Effat College', value: 'Effat College' },
  { label: 'Ege University', value: 'Ege University' },
  { label: 'Egerton University', value: 'Egerton University' },
  { label: 'Ehime University', value: 'Ehime University' },
  { label: 'EHSAL - Europese Hogeschool Brussel', value: 'EHSAL - Europese Hogeschool Brussel' },
  { label: 'Eichi University', value: 'Eichi University' },
  { label: 'Eiilm University', value: 'Eiilm University' },
  { label: 'Eindhoven University of Technology', value: 'Eindhoven University of Technology' },
  { label: 'Ekiti State University', value: 'Ekiti State University' },
  { label: 'El Colegio de México', value: 'El Colegio de México' },
  { label: 'El Shorouk Academy', value: 'El Shorouk Academy' },
  { label: 'Elisabeth University of Music', value: 'Elisabeth University of Music' },
  { label: 'Elizabeth City State University', value: 'Elizabeth City State University' },
  { label: 'Elizabethtown College', value: 'Elizabethtown College' },
  { label: 'Elmergib University', value: 'Elmergib University' },
  { label: 'Elmhurst College', value: 'Elmhurst College' },
  { label: 'Elmira College', value: 'Elmira College' },
  { label: 'Elms College', value: 'Elms College' },
  { label: 'Elon College', value: 'Elon College' },
  {
    label: 'Elrazi College Of Medical & Technological Sciences',
    value: 'Elrazi College Of Medical & Technological Sciences',
  },
  { label: 'Emanuel University', value: 'Emanuel University' },
  { label: 'Embry Riddle Aeronautical University, Prescott', value: 'Embry Riddle Aeronautical University, Prescott' },
  { label: 'Embry-Riddle Aeronautical University', value: 'Embry-Riddle Aeronautical University' },
  { label: 'Emeishan Buddhist College', value: 'Emeishan Buddhist College' },
  { label: 'Emeq Yizrael College', value: 'Emeq Yizrael College' },
  { label: 'Emerson College', value: 'Emerson College' },
  {
    label: 'EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória',
    value: 'EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória',
  },
  { label: 'Emily Carr Institute of Art + Design', value: 'Emily Carr Institute of Art + Design' },
  { label: 'Emmanuel College', value: 'Emmanuel College' },
  { label: 'Emmanuel College Georgia', value: 'Emmanuel College Georgia' },
  { label: 'Emmaus Bible College', value: 'Emmaus Bible College' },
  { label: 'Emory & Henry College', value: 'Emory & Henry College' },
  { label: 'Emory University', value: 'Emory University' },
  { label: 'Emporia State University', value: 'Emporia State University' },
  { label: 'Engineering College of Aarhus', value: 'Engineering College of Aarhus' },
  { label: 'Engineering College of Copenhagen', value: 'Engineering College of Copenhagen' },
  { label: 'Engineering Colleges in Tamil Nadu', value: 'Engineering Colleges in Tamil Nadu' },
  { label: 'Engineering Faculty of Asian University', value: 'Engineering Faculty of Asian University' },
  { label: 'ENIC Telecom Lille 1', value: 'ENIC Telecom Lille 1' },
  {
    label: 'Enugu State University of Science and Technology',
    value: 'Enugu State University of Science and Technology',
  },
  { label: 'Eötvös Lorand University', value: 'Eötvös Lorand University' },
  { label: "EPF Ecole d'Ingénieurs", value: "EPF Ecole d'Ingénieurs" },
  { label: 'Epoka University', value: 'Epoka University' },
  { label: 'Erasmus University Rotterdam', value: 'Erasmus University Rotterdam' },
  { label: 'Erasmushogeschool Brussel', value: 'Erasmushogeschool Brussel' },
  { label: 'Erciyes University', value: 'Erciyes University' },
  { label: 'Eritrea Institute of Technology', value: 'Eritrea Institute of Technology' },
  { label: 'Ernst-Moritz-Arndt Universität Greifswald', value: 'Ernst-Moritz-Arndt Universität Greifswald' },
  { label: 'Erskine College', value: 'Erskine College' },
  { label: 'Escola Nautica Infante D. Henrique', value: 'Escola Nautica Infante D. Henrique' },
  { label: 'Escola Superior de Artes e Design', value: 'Escola Superior de Artes e Design' },
  {
    label: 'Escola Superior de Hotelaria e Turismo do Estoril',
    value: 'Escola Superior de Hotelaria e Turismo do Estoril',
  },
  { label: 'Escuela Agricola Panamericana Zamorano', value: 'Escuela Agricola Panamericana Zamorano' },
  { label: 'Escuela Bancaria y Comercial', value: 'Escuela Bancaria y Comercial' },
  { label: 'Escuela Colombiana de Carreras Industriales', value: 'Escuela Colombiana de Carreras Industriales' },
  {
    label: 'Escuela Colombiana de Ingeniería Julio Garavito',
    value: 'Escuela Colombiana de Ingeniería Julio Garavito',
  },
  { label: 'Escuela de Administración de Negocios', value: 'Escuela de Administración de Negocios' },
  { label: 'Escuela de Arquitectura y Diseño', value: 'Escuela de Arquitectura y Diseño' },
  { label: 'Escuela de Ingeniería de Antioquia', value: 'Escuela de Ingeniería de Antioquia' },
  { label: 'Escuela de Policia General Santander', value: 'Escuela de Policia General Santander' },
  { label: 'Escuela Militar de Ingeniería', value: 'Escuela Militar de Ingeniería' },
  {
    label: 'Escuela Nacional de Estudios Superiores Unidad León',
    value: 'Escuela Nacional de Estudios Superiores Unidad León',
  },
  { label: 'Escuela Politécnica de Chimborazo', value: 'Escuela Politécnica de Chimborazo' },
  { label: 'Escuela Politécnica del Ejercito', value: 'Escuela Politécnica del Ejercito' },
  { label: 'Escuela Politécnica Nacional', value: 'Escuela Politécnica Nacional' },
  {
    label: 'Escuela Superior de Gestion Comercial y Marketing (ESIC)',
    value: 'Escuela Superior de Gestion Comercial y Marketing (ESIC)',
  },
  { label: 'Escuela Superior Politécnica del Litoral', value: 'Escuela Superior Politécnica del Litoral' },
  { label: 'Escuela Superiore de Administración Pública', value: 'Escuela Superiore de Administración Pública' },
  { label: 'Espam Formation University', value: 'Espam Formation University' },
  { label: 'Estonian Academy of Arts', value: 'Estonian Academy of Arts' },
  { label: 'Estonian Academy of Music and Theatre', value: 'Estonian Academy of Music and Theatre' },
  { label: 'Estonian Academy of Security Sciences', value: 'Estonian Academy of Security Sciences' },
  { label: 'Estonian Business School', value: 'Estonian Business School' },
  { label: 'Estonian University of Life Sciences', value: 'Estonian University of Life Sciences' },
  { label: 'Esztergom Theological College', value: 'Esztergom Theological College' },
  { label: 'Ethiopian Civil Service University', value: 'Ethiopian Civil Service University' },
  { label: 'Etisalat University College', value: 'Etisalat University College' },
  { label: 'EUCLID University', value: 'EUCLID University' },
  { label: 'Eugene Bible College', value: 'Eugene Bible College' },
  { label: 'Eurasia International University', value: 'Eurasia International University' },
  { label: 'Eurasian Institute of market', value: 'Eurasian Institute of market' },
  { label: 'Eureka College', value: 'Eureka College' },
  { label: 'Europa Fachhochschule Fresenius', value: 'Europa Fachhochschule Fresenius' },
  { label: 'Europa-Universität Viadrina Frankfurt (Oder)', value: 'Europa-Universität Viadrina Frankfurt (Oder)' },
  { label: 'Europäische Betriebswirtschafts-Akademie', value: 'Europäische Betriebswirtschafts-Akademie' },
  { label: 'Europäische Fachhochschule', value: 'Europäische Fachhochschule' },
  { label: 'European Academy of Arts in Warsaw', value: 'European Academy of Arts in Warsaw' },
  { label: 'European Business School', value: 'European Business School' },
  {
    label: 'European Business School Schloß Reichartshausen',
    value: 'European Business School Schloß Reichartshausen',
  },
  { label: 'European Carolus Magnus University', value: 'European Carolus Magnus University' },
  { label: 'European College of Liberal Arts', value: 'European College of Liberal Arts' },
  {
    label: 'European Graduate School, Media & Communications',
    value: 'European Graduate School, Media & Communications',
  },
  { label: 'European Humanities University', value: 'European Humanities University' },
  { label: 'European Institute of Education', value: 'European Institute of Education' },
  { label: 'European International University', value: 'European International University' },
  { label: 'European Management Center Paris', value: 'European Management Center Paris' },
  { label: 'European Management School', value: 'European Management School' },
  { label: 'European Open University', value: 'European Open University' },
  {
    label: 'European Regional Educational Academy of Armenia',
    value: 'European Regional Educational Academy of Armenia',
  },
  { label: 'European School of Economics', value: 'European School of Economics' },
  { label: 'European School of Economics', value: 'European School of Economics' },
  { label: 'European University', value: 'European University' },
  { label: 'European University', value: 'European University' },
  { label: 'European University at St.Petersburg', value: 'European University at St.Petersburg' },
  { label: 'European University Cyprus', value: 'European University Cyprus' },
  { label: 'European University Institute', value: 'European University Institute' },
  { label: 'European University of Lefke', value: 'European University of Lefke' },
  { label: 'European University Portugal', value: 'European University Portugal' },
  { label: 'European University, Athens Campus', value: 'European University, Athens Campus' },
  { label: 'Evangel University', value: 'Evangel University' },
  {
    label: 'Evangelische Fachhochschule Berlin, Fachhochschule für Sozialarbeit und Sozialpädagogik',
    value: 'Evangelische Fachhochschule Berlin, Fachhochschule für Sozialarbeit und Sozialpädagogik',
  },
  { label: 'Evangelische Fachhochschule Darmstadt', value: 'Evangelische Fachhochschule Darmstadt' },
  {
    label: 'Evangelische Fachhochschule Freiburg, Hochschule für Soziale Arbeit, Diakonie und Religionspädagogik',
    value: 'Evangelische Fachhochschule Freiburg, Hochschule für Soziale Arbeit, Diakonie und Religionspädagogik',
  },
  {
    label: 'Evangelische Fachhochschule für Religionspädagogik, und Gemeindediakonie Moritzburg',
    value: 'Evangelische Fachhochschule für Religionspädagogik, und Gemeindediakonie Moritzburg',
  },
  {
    label: 'Evangelische Fachhochschule für Sozialpädagogik der Diakonenanstalt des Rauhen Hauses Hamburg',
    value: 'Evangelische Fachhochschule für Sozialpädagogik der Diakonenanstalt des Rauhen Hauses Hamburg',
  },
  { label: 'Evangelische Fachhochschule Hannover', value: 'Evangelische Fachhochschule Hannover' },
  {
    label: 'Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen',
    value: 'Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen',
  },
  { label: 'Evangelische Fachhochschule Nürnberg', value: 'Evangelische Fachhochschule Nürnberg' },
  {
    label:
      'Evangelische Fachhochschule Reutlingen-Ludwigsburg, Hochschule für Soziale Arbeit, Religionspädagogik und Diakonie',
    value:
      'Evangelische Fachhochschule Reutlingen-Ludwigsburg, Hochschule für Soziale Arbeit, Religionspädagogik und Diakonie',
  },
  {
    label: 'Evangelische Fachhochschule Rheinland-Westfalen-Lippe',
    value: 'Evangelische Fachhochschule Rheinland-Westfalen-Lippe',
  },
  {
    label: 'Evangelische Hochschule für Soziale Arbeit Dresden (FH)',
    value: 'Evangelische Hochschule für Soziale Arbeit Dresden (FH)',
  },
  { label: 'Evangelische Theologische Faculteit, Leuven', value: 'Evangelische Theologische Faculteit, Leuven' },
  { label: 'Evergreen State College', value: 'Evergreen State College' },
  { label: 'EVTEK University of Applied Sciences', value: 'EVTEK University of Applied Sciences' },
  { label: "Ewha Women's University", value: "Ewha Women's University" },
  { label: 'Excel College', value: 'Excel College' },
  { label: 'Excelsior College', value: 'Excelsior College' },
  { label: 'FAAP - Fundação Armando Alvares Penteado', value: 'FAAP - Fundação Armando Alvares Penteado' },
  { label: 'Fachhochschule Aachen', value: 'Fachhochschule Aachen' },
  { label: 'Fachhochschule Aschaffenburg', value: 'Fachhochschule Aschaffenburg' },
  { label: 'Fachhochschule Augsburg', value: 'Fachhochschule Augsburg' },
  {
    label: 'Fachhochschule Biberach, Hochschule für Bauwesen und Wirtschaft',
    value: 'Fachhochschule Biberach, Hochschule für Bauwesen und Wirtschaft',
  },
  { label: 'Fachhochschule Bielefeld', value: 'Fachhochschule Bielefeld' },
  { label: 'Fachhochschule Bingen', value: 'Fachhochschule Bingen' },
  { label: 'Fachhochschule Bochum', value: 'Fachhochschule Bochum' },
  { label: 'Fachhochschule Bonn-Rhein-Sieg', value: 'Fachhochschule Bonn-Rhein-Sieg' },
  { label: 'Fachhochschule Brandenburg', value: 'Fachhochschule Brandenburg' },
  { label: 'Fachhochschule Braunschweig/Wolfenbüttel', value: 'Fachhochschule Braunschweig/Wolfenbüttel' },
  { label: 'Fachhochschule Burgenland', value: 'Fachhochschule Burgenland' },
  { label: 'Fachhochschule Deggendorf', value: 'Fachhochschule Deggendorf' },
  { label: 'Fachhochschule der Wirtschaft', value: 'Fachhochschule der Wirtschaft' },
  { label: 'Fachhochschule des Mittelstandes (FHM)', value: 'Fachhochschule des Mittelstandes (FHM)' },
  { label: 'Fachhochschule Dortmund', value: 'Fachhochschule Dortmund' },
  { label: 'Fachhochschule Düsseldorf', value: 'Fachhochschule Düsseldorf' },
  { label: 'Fachhochschule Eberswalde', value: 'Fachhochschule Eberswalde' },
  { label: 'Fachhochschule Erfurt', value: 'Fachhochschule Erfurt' },
  { label: 'Fachhochschule Flensburg', value: 'Fachhochschule Flensburg' },
  { label: 'Fachhochschule Frankfurt am Main', value: 'Fachhochschule Frankfurt am Main' },
  { label: 'Fachhochschule für Bank- und Finanzwirtschaft', value: 'Fachhochschule für Bank- und Finanzwirtschaft' },
  {
    label: 'Fachhochschule für das öffentliche Bibliothekswesen Bonn',
    value: 'Fachhochschule für das öffentliche Bibliothekswesen Bonn',
  },
  { label: 'Fachhochschule für die Wirtschaft', value: 'Fachhochschule für die Wirtschaft' },
  {
    label: 'Fachhochschule für Oekonomie und Management (FOM)',
    value: 'Fachhochschule für Oekonomie und Management (FOM)',
  },
  {
    label: 'Fachhochschule für Technik und Wirtschaft Berlin',
    value: 'Fachhochschule für Technik und Wirtschaft Berlin',
  },
  {
    label: 'Fachhochschule für Verwaltung und Rechtspflege Berlin',
    value: 'Fachhochschule für Verwaltung und Rechtspflege Berlin',
  },
  { label: 'Fachhochschule für Wirtschaft Berlin', value: 'Fachhochschule für Wirtschaft Berlin' },
  {
    label: 'Fachhochschule Furtwangen, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Furtwangen, Hochschule für Technik und Wirtschaft',
  },
  { label: 'Fachhochschule Gelsenkirchen', value: 'Fachhochschule Gelsenkirchen' },
  { label: 'Fachhochschule Gießen-Friedberg', value: 'Fachhochschule Gießen-Friedberg' },
  { label: 'Fachhochschule Hamburg', value: 'Fachhochschule Hamburg' },
  { label: 'Fachhochschule Hannover', value: 'Fachhochschule Hannover' },
  { label: 'Fachhochschule Heidelberg', value: 'Fachhochschule Heidelberg' },
  {
    label: 'Fachhochschule Heilbronn, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Heilbronn, Hochschule für Technik und Wirtschaft',
  },
  {
    label: 'Fachhochschule Hildesheim/Holzminden/Göttingen, Hochschule für angewandte Wissenschaft und Kunst',
    value: 'Fachhochschule Hildesheim/Holzminden/Göttingen, Hochschule für angewandte Wissenschaft und Kunst',
  },
  { label: 'Fachhochschule Hof', value: 'Fachhochschule Hof' },
  { label: 'Fachhochschule Ingolstadt', value: 'Fachhochschule Ingolstadt' },
  { label: 'Fachhochschule Jena', value: 'Fachhochschule Jena' },
  { label: 'Fachhochschule JOANNEUM', value: 'Fachhochschule JOANNEUM' },
  { label: 'Fachhochschule Kaiserslautern', value: 'Fachhochschule Kaiserslautern' },
  { label: 'Fachhochschule Kärnten', value: 'Fachhochschule Kärnten' },
  {
    label: 'Fachhochschule Kempten, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Kempten, Hochschule für Technik und Wirtschaft',
  },
  { label: 'Fachhochschule Kiel', value: 'Fachhochschule Kiel' },
  { label: 'Fachhochschule Koblenz', value: 'Fachhochschule Koblenz' },
  { label: 'Fachhochschule Köln', value: 'Fachhochschule Köln' },
  {
    label: 'Fachhochschule Konstanz, Hochschule für Technik, Wirtschaft und Gestaltung',
    value: 'Fachhochschule Konstanz, Hochschule für Technik, Wirtschaft und Gestaltung',
  },
  { label: 'Fachhochschule Krems', value: 'Fachhochschule Krems' },
  { label: 'Fachhochschule Kufstein (Tirol)', value: 'Fachhochschule Kufstein (Tirol)' },
  {
    label: 'Fachhochschule Landshut, Hochschule für Wirtschaft - Sozialwesen - Technik',
    value: 'Fachhochschule Landshut, Hochschule für Wirtschaft - Sozialwesen - Technik',
  },
  { label: 'Fachhochschule Lausitz', value: 'Fachhochschule Lausitz' },
  { label: 'Fachhochschule Lippe', value: 'Fachhochschule Lippe' },
  { label: 'Fachhochschule Lübeck', value: 'Fachhochschule Lübeck' },
  {
    label: 'Fachhochschule Ludwigshafen, Hochschule für Wirtschaft',
    value: 'Fachhochschule Ludwigshafen, Hochschule für Wirtschaft',
  },
  { label: 'Fachhochschule Mainz', value: 'Fachhochschule Mainz' },
  {
    label: 'Fachhochschule Mannheim, Hochschule für Sozialwesen',
    value: 'Fachhochschule Mannheim, Hochschule für Sozialwesen',
  },
  {
    label: 'Fachhochschule Mannheim, Hochschule für Technik und Gestaltung',
    value: 'Fachhochschule Mannheim, Hochschule für Technik und Gestaltung',
  },
  { label: 'Fachhochschule Merseburg', value: 'Fachhochschule Merseburg' },
  { label: 'Fachhochschule München', value: 'Fachhochschule München' },
  { label: 'Fachhochschule Münster', value: 'Fachhochschule Münster' },
  { label: 'Fachhochschule Neu-Ulm', value: 'Fachhochschule Neu-Ulm' },
  { label: 'Fachhochschule Neubrandenburg', value: 'Fachhochschule Neubrandenburg' },
  { label: 'Fachhochschule Niederrhein', value: 'Fachhochschule Niederrhein' },
  { label: 'Fachhochschule Nordhausen', value: 'Fachhochschule Nordhausen' },
  { label: 'Fachhochschule Nordhessen', value: 'Fachhochschule Nordhessen' },
  { label: 'Fachhochschule Nordostniedersachsen', value: 'Fachhochschule Nordostniedersachsen' },
  {
    label: 'Fachhochschule Nürtingen, Hochschule für Wirtschaft, Landwirtschaft und Landespflege',
    value: 'Fachhochschule Nürtingen, Hochschule für Wirtschaft, Landwirtschaft und Landespflege',
  },
  {
    label: 'Fachhochschule Offenburg, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Offenburg, Hochschule für Technik und Wirtschaft',
  },
  {
    label: 'Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven',
    value: 'Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven',
  },
  { label: 'Fachhochschule Osnabrück', value: 'Fachhochschule Osnabrück' },
  {
    label: 'Fachhochschule Pforzheim, Hochschule für Gestaltung, Technik und Wirtschaft',
    value: 'Fachhochschule Pforzheim, Hochschule für Gestaltung, Technik und Wirtschaft',
  },
  { label: 'Fachhochschule Potsdam', value: 'Fachhochschule Potsdam' },
  { label: 'Fachhochschule Pur', value: 'Fachhochschule Pur' },
  { label: 'Fachhochschule Ravensburg-Weingarten', value: 'Fachhochschule Ravensburg-Weingarten' },
  { label: 'Fachhochschule Regensburg', value: 'Fachhochschule Regensburg' },
  {
    label: 'Fachhochschule Reutlingen, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Reutlingen, Hochschule für Technik und Wirtschaft',
  },
  {
    label: 'Fachhochschule Rosenheim, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Rosenheim, Hochschule für Technik und Wirtschaft',
  },
  {
    label: 'Fachhochschule Rottenburg, Hochschule für Forstwirtschaft',
    value: 'Fachhochschule Rottenburg, Hochschule für Forstwirtschaft',
  },
  { label: 'Fachhochschule Salzburg', value: 'Fachhochschule Salzburg' },
  { label: 'Fachhochschule Schmalkalden', value: 'Fachhochschule Schmalkalden' },
  {
    label: 'Fachhochschule Schwäbisch Gmünd, Hochschule für Gestaltung',
    value: 'Fachhochschule Schwäbisch Gmünd, Hochschule für Gestaltung',
  },
  {
    label: 'Fachhochschule Schwäbisch Hall, Hochschule für Gestaltung',
    value: 'Fachhochschule Schwäbisch Hall, Hochschule für Gestaltung',
  },
  { label: 'Fachhochschule St. Gallen', value: 'Fachhochschule St. Gallen' },
  { label: 'Fachhochschule St. Pölten', value: 'Fachhochschule St. Pölten' },
  { label: 'Fachhochschule Stralsund', value: 'Fachhochschule Stralsund' },
  {
    label: 'Fachhochschule Stuttgart, Hochschule der Medien',
    value: 'Fachhochschule Stuttgart, Hochschule der Medien',
  },
  {
    label: 'Fachhochschule Stuttgart, Hochschule für Technik',
    value: 'Fachhochschule Stuttgart, Hochschule für Technik',
  },
  {
    label: 'Fachhochschule Trier, Hochschule für Technik, Wirtschaft und Gestaltung',
    value: 'Fachhochschule Trier, Hochschule für Technik, Wirtschaft und Gestaltung',
  },
  { label: 'Fachhochschule Ulm, Hochschule für Technik', value: 'Fachhochschule Ulm, Hochschule für Technik' },
  {
    label: 'Fachhochschule und Berufskollegs NTA, Prof.Dr. Grübler gemein. GmbH',
    value: 'Fachhochschule und Berufskollegs NTA, Prof.Dr. Grübler gemein. GmbH',
  },
  { label: 'Fachhochschule Vorarlberg', value: 'Fachhochschule Vorarlberg' },
  { label: 'Fachhochschule Wedel', value: 'Fachhochschule Wedel' },
  { label: 'Fachhochschule Weihenstephan', value: 'Fachhochschule Weihenstephan' },
  {
    label: 'Fachhochschule Westküste, Hochschule für Wirtschaft und Technik',
    value: 'Fachhochschule Westküste, Hochschule für Wirtschaft und Technik',
  },
  { label: 'Fachhochschule Wiener Neustadt', value: 'Fachhochschule Wiener Neustadt' },
  { label: 'Fachhochschule Wiesbaden', value: 'Fachhochschule Wiesbaden' },
  { label: 'Fachhochschule Worms', value: 'Fachhochschule Worms' },
  { label: 'Fachhochschule Würzburg - Schweinfurt', value: 'Fachhochschule Würzburg - Schweinfurt' },
  {
    label: 'Fachhochschulstudiengänge der Wiener Wirtschaft',
    value: 'Fachhochschulstudiengänge der Wiener Wirtschaft',
  },
  { label: 'Fachhochschulstudiengänge Hagenberg', value: 'Fachhochschulstudiengänge Hagenberg' },
  { label: 'Fachhochschulstudiengänge Krems IMC', value: 'Fachhochschulstudiengänge Krems IMC' },
  { label: 'Fachhochschulstudiengänge Steyr', value: 'Fachhochschulstudiengänge Steyr' },
  { label: 'Fachhochschulstudiengänge Wels', value: 'Fachhochschulstudiengänge Wels' },
  { label: 'Faculdade Integradas do Ceará', value: 'Faculdade Integradas do Ceará' },
  { label: 'Faculdade Italo Brasileira', value: 'Faculdade Italo Brasileira' },
  { label: 'Faculdade Jaguariúna', value: 'Faculdade Jaguariúna' },
  { label: 'Faculdades Integradas Curitiba', value: 'Faculdades Integradas Curitiba' },
  { label: 'Faculdades Integradas do Brasil - UNIBRASIL', value: 'Faculdades Integradas do Brasil - UNIBRASIL' },
  { label: 'Faculdades Integradas Toledo', value: 'Faculdades Integradas Toledo' },
  { label: 'Faculdades Integradas UPIS', value: 'Faculdades Integradas UPIS' },
  { label: 'Faculté Polytechnique de Mons', value: 'Faculté Polytechnique de Mons' },
  {
    label: 'Faculté Universitaire des Sciences Agronomiques de Gembloux',
    value: 'Faculté Universitaire des Sciences Agronomiques de Gembloux',
  },
  { label: 'Facultés Universitaires Catholiques de Mons', value: 'Facultés Universitaires Catholiques de Mons' },
  { label: 'Facultés Universitaires Notre-Dame de la Paix', value: 'Facultés Universitaires Notre-Dame de la Paix' },
  { label: 'Facultés Universitaires Saint-Louis', value: 'Facultés Universitaires Saint-Louis' },
  {
    label: 'FAE Business School - Faculdade de Administração e Economia',
    value: 'FAE Business School - Faculdade de Administração e Economia',
  },
  { label: 'Fahad Bin Sultan University', value: 'Fahad Bin Sultan University' },
  { label: 'Fairfield University', value: 'Fairfield University' },
  {
    label: 'Fairleigh Dickinson University, Florham-Madison Campus',
    value: 'Fairleigh Dickinson University, Florham-Madison Campus',
  },
  {
    label: 'Fairleigh Dickinson University, Teaneck-Hackensack Campus',
    value: 'Fairleigh Dickinson University, Teaneck-Hackensack Campus',
  },
  { label: 'Fairmont State College', value: 'Fairmont State College' },
  {
    label: 'Faisalabad Institute of Textile and Fashion Design',
    value: 'Faisalabad Institute of Textile and Fashion Design',
  },
  { label: 'Fakir Mohan University', value: 'Fakir Mohan University' },
  { label: 'Falmouth University', value: 'Falmouth University' },
  { label: 'Far East State Transport University', value: 'Far East State Transport University' },
  { label: 'Far Easten State University of Humanities', value: 'Far Easten State University of Humanities' },
  {
    label: 'Far Eastern State Technical Fisheries University',
    value: 'Far Eastern State Technical Fisheries University',
  },
  { label: 'Far Eastern State Technical University', value: 'Far Eastern State Technical University' },
  { label: 'Far Eastern State University', value: 'Far Eastern State University' },
  { label: 'Far Eastern University', value: 'Far Eastern University' },
  { label: 'Faryab Higher Education Institute', value: 'Faryab Higher Education Institute' },
  { label: 'Fasa Faculty of Medical Sciences', value: 'Fasa Faculty of Medical Sciences' },
  { label: 'Fashion Institute of New York', value: 'Fashion Institute of New York' },
  {
    label: 'FAST - National University of Computer and Emerging Sciences (NUCES)',
    value: 'FAST - National University of Computer and Emerging Sciences (NUCES)',
  },
  { label: 'Fatih University', value: 'Fatih University' },
  { label: 'Fatima mata national college kollam kerala', value: 'Fatima mata national college kollam kerala' },
  { label: 'Faulkner University', value: 'Faulkner University' },
  { label: 'Fayetteville State University', value: 'Fayetteville State University' },
  { label: 'Fayoum University', value: 'Fayoum University' },
  { label: 'Feati University', value: 'Feati University' },
  {
    label: 'Federal College Of Education (Technical), Akoka',
    value: 'Federal College Of Education (Technical), Akoka',
  },
  { label: 'Federal Polytechnic Bauchi, Nigeria', value: 'Federal Polytechnic Bauchi, Nigeria' },
  { label: 'Federal University of Petroleum Resources', value: 'Federal University of Petroleum Resources' },
  { label: 'Federal University of Technology, Akure', value: 'Federal University of Technology, Akure' },
  { label: 'Federal University of Technology, Minna', value: 'Federal University of Technology, Minna' },
  { label: 'Federal University of Technology, Owerri', value: 'Federal University of Technology, Owerri' },
  { label: 'Federal University of Technology, Yola', value: 'Federal University of Technology, Yola' },
  {
    label: 'Federal Urdu University of Arts,Science and Technology',
    value: 'Federal Urdu University of Arts,Science and Technology',
  },
  { label: 'Felician College', value: 'Felician College' },
  { label: 'Feng Chia University', value: 'Feng Chia University' },
  { label: 'Ferdowsi University of Mashhad', value: 'Ferdowsi University of Mashhad' },
  { label: 'Ferghana Politechnical Institute', value: 'Ferghana Politechnical Institute' },
  { label: 'Fern-Fachhochschule Hamburg', value: 'Fern-Fachhochschule Hamburg' },
  { label: 'Fernuniversität Gesamthochschule Hagen', value: 'Fernuniversität Gesamthochschule Hagen' },
  { label: 'Ferris State University', value: 'Ferris State University' },
  { label: 'Ferris University', value: 'Ferris University' },
  { label: 'Ferrum College', value: 'Ferrum College' },
  { label: 'Fielding Institute', value: 'Fielding Institute' },
  { label: 'Fiji National University ', value: 'Fiji National University ' },
  { label: 'Fiji School of Medicine', value: 'Fiji School of Medicine' },
  { label: 'Finance Academy', value: 'Finance Academy' },
  {
    label: 'Finch University of Health Sciences - The Chicago Medical School',
    value: 'Finch University of Health Sciences - The Chicago Medical School',
  },
  { label: 'Fine Arts Academy in Gdansk', value: 'Fine Arts Academy in Gdansk' },
  { label: 'Fine Arts Academy in Katowice', value: 'Fine Arts Academy in Katowice' },
  { label: 'Fine Arts Academy in Poznan', value: 'Fine Arts Academy in Poznan' },
  { label: 'Fine Arts Academy in Warsaw', value: 'Fine Arts Academy in Warsaw' },
  { label: 'Fine Arts Academy in Wroclaw', value: 'Fine Arts Academy in Wroclaw' },
  { label: 'Fine Arts Academy Jan Matejko in Cracow', value: 'Fine Arts Academy Jan Matejko in Cracow' },
  {
    label: 'Fine Arts Academy Wladyslaw Strzeminski in Lodz',
    value: 'Fine Arts Academy Wladyslaw Strzeminski in Lodz',
  },
  { label: 'Finlandia University', value: 'Finlandia University' },
  { label: 'Finnmark University College', value: 'Finnmark University College' },
  { label: 'Firat (Euphrates) University', value: 'Firat (Euphrates) University' },
  { label: 'First Global University to teaching Jainism', value: 'First Global University to teaching Jainism' },
  { label: 'First Nations University of Canada', value: 'First Nations University of Canada' },
  { label: 'Fisher College', value: 'Fisher College' },
  { label: 'Fisk University', value: 'Fisk University' },
  { label: 'Fitchburg State College', value: 'Fitchburg State College' },
  { label: 'Flagler College', value: 'Flagler College' },
  { label: 'Flinders University of South Australia', value: 'Flinders University of South Australia' },
  { label: 'Floret Global University', value: 'Floret Global University' },
  { label: 'Florida Agricultural and Mechanical University', value: 'Florida Agricultural and Mechanical University' },
  { label: 'Florida Atlantic University', value: 'Florida Atlantic University' },
  { label: 'Florida Christian College', value: 'Florida Christian College' },
  { label: 'Florida Community College at Jacksonville', value: 'Florida Community College at Jacksonville' },
  { label: 'Florida Gulf Coast University', value: 'Florida Gulf Coast University' },
  { label: 'Florida Institute of Technology', value: 'Florida Institute of Technology' },
  { label: 'Florida International University', value: 'Florida International University' },
  { label: 'Florida Memorial College', value: 'Florida Memorial College' },
  {
    label: 'Florida Metropolitan University, Fort Lauderdale College',
    value: 'Florida Metropolitan University, Fort Lauderdale College',
  },
  { label: 'Florida Metropolitan University, Tampa College', value: 'Florida Metropolitan University, Tampa College' },
  {
    label: 'Florida Metropolitan University, Tampa College Brandon',
    value: 'Florida Metropolitan University, Tampa College Brandon',
  },
  {
    label: 'Florida Metropolitan University, Tampa College Lakeland',
    value: 'Florida Metropolitan University, Tampa College Lakeland',
  },
  { label: 'Florida Southern College', value: 'Florida Southern College' },
  { label: 'Florida State University', value: 'Florida State University' },
  { label: 'Florida University of Medicine', value: 'Florida University of Medicine' },
  { label: 'Fomic Polytechnic', value: 'Fomic Polytechnic' },
  { label: 'FON University', value: 'FON University' },
  { label: 'Fondation Universitaire Luxembourgeoise', value: 'Fondation Universitaire Luxembourgeoise' },
  { label: 'Fondazione Sacro Cuore', value: 'Fondazione Sacro Cuore' },
  { label: 'Fontbonne College', value: 'Fontbonne College' },
  { label: 'Fontys University of Applied Sciences', value: 'Fontys University of Applied Sciences' },
  { label: 'Foothill-De Anza Community College District', value: 'Foothill-De Anza Community College District' },
  { label: 'Fordham University', value: 'Fordham University' },
  { label: 'Foreign Trade University', value: 'Foreign Trade University' },
  { label: 'Forest Institute of Professional Psychology', value: 'Forest Institute of Professional Psychology' },
  { label: 'Forest Research Institute Dehradun', value: 'Forest Research Institute Dehradun' },
  { label: 'Fort Hays State University', value: 'Fort Hays State University' },
  { label: 'Fort Lewis College', value: 'Fort Lewis College' },
  { label: 'Fort Valley State College', value: 'Fort Valley State College' },
  { label: 'Foshan University', value: 'Foshan University' },
  { label: 'Foundation University', value: 'Foundation University' },
  { label: 'Foundation University', value: 'Foundation University' },
  { label: 'Fountain University', value: 'Fountain University' },
  { label: 'Fourah Bay College, University of Sierra Leone', value: 'Fourah Bay College, University of Sierra Leone' },
  { label: 'FPT University', value: 'FPT University' },
  { label: 'Framingham State College', value: 'Framingham State College' },
  { label: 'Francis Marion University', value: 'Francis Marion University' },
  { label: 'Franciscan School of Theology', value: 'Franciscan School of Theology' },
  { label: 'Franciscan University of Steubenville', value: 'Franciscan University of Steubenville' },
  { label: 'Frank Lloyd Wright School of Architecture', value: 'Frank Lloyd Wright School of Architecture' },
  { label: 'Franklin and Marshall College', value: 'Franklin and Marshall College' },
  { label: 'Franklin College', value: 'Franklin College' },
  { label: 'Franklin College Switzerland', value: 'Franklin College Switzerland' },
  { label: 'Franklin Pierce College', value: 'Franklin Pierce College' },
  { label: 'Franklin Pierce Law Center', value: 'Franklin Pierce Law Center' },
  { label: 'Franklin University', value: 'Franklin University' },
  { label: 'Franklin W. Olin College of Engineering', value: 'Franklin W. Olin College of Engineering' },
  { label: 'Fred Hutchinson Cancer Research Center', value: 'Fred Hutchinson Cancer Research Center' },
  { label: 'Frederick University', value: 'Frederick University' },
  { label: 'Free International University of Moldova', value: 'Free International University of Moldova' },
  {
    label: 'Free International University of Social Studies',
    value: 'Free International University of Social Studies',
  },
  {
    label: 'Free University (Virginia Theological University)',
    value: 'Free University (Virginia Theological University)',
  },
  { label: 'Free University Amsterdam', value: 'Free University Amsterdam' },
  { label: 'Free University Institute Carlo Cattaneo', value: 'Free University Institute Carlo Cattaneo' },
  { label: 'Free University Maria Santissima Assunta', value: 'Free University Maria Santissima Assunta' },
  { label: 'Free University of Bozen', value: 'Free University of Bozen' },
  { label: 'Free University of Tbilisi', value: 'Free University of Tbilisi' },
  { label: 'Free University Stockholm', value: 'Free University Stockholm' },
  { label: 'Free Will Baptist Bible College', value: 'Free Will Baptist Bible College' },
  { label: 'Freed-Hardeman University', value: 'Freed-Hardeman University' },
  { label: 'Freie Kunst-Studienstätte Ottersberg', value: 'Freie Kunst-Studienstätte Ottersberg' },
  { label: 'Freie Universität Berlin', value: 'Freie Universität Berlin' },
  { label: 'French Institute of Management', value: 'French Institute of Management' },
  { label: 'French University in Armenia (UFAR)', value: 'French University in Armenia (UFAR)' },
  { label: 'Fresno City College', value: 'Fresno City College' },
  { label: 'Fresno Pacific University', value: 'Fresno Pacific University' },
  {
    label: 'Friedrich-Alexander Universität Erlangen-Nürnberg',
    value: 'Friedrich-Alexander Universität Erlangen-Nürnberg',
  },
  { label: 'Friedrich-Schiller Universität Jena', value: 'Friedrich-Schiller Universität Jena' },
  { label: 'Friends University', value: 'Friends University' },
  { label: 'Frostburg State University', value: 'Frostburg State University' },
  { label: 'FTMS Global Academy', value: 'FTMS Global Academy' },
  { label: 'Fu Jen Catholic University', value: 'Fu Jen Catholic University' },
  { label: 'Fudan University', value: 'Fudan University' },
  { label: 'Fuji University', value: 'Fuji University' },
  { label: "Fuji Women's College", value: "Fuji Women's College" },
  { label: 'Fujian Agricultural University', value: 'Fujian Agricultural University' },
  { label: 'Fujian Medical University', value: 'Fujian Medical University' },
  { label: 'Fujian Normal University', value: 'Fujian Normal University' },
  {
    label: 'Fujian University of Traditional Chinese Medicine',
    value: 'Fujian University of Traditional Chinese Medicine',
  },
  { label: 'Fujita Health University', value: 'Fujita Health University' },
  { label: 'Fukui Medical School', value: 'Fukui Medical School' },
  { label: 'Fukui Prefectural University', value: 'Fukui Prefectural University' },
  { label: 'Fukui University', value: 'Fukui University' },
  { label: 'Fukui University of Technology', value: 'Fukui University of Technology' },
  { label: 'Fukuoka Dental College', value: 'Fukuoka Dental College' },
  { label: 'Fukuoka Institute of Technology', value: 'Fukuoka Institute of Technology' },
  { label: 'Fukuoka International University', value: 'Fukuoka International University' },
  { label: 'Fukuoka Prefectural University', value: 'Fukuoka Prefectural University' },
  { label: 'Fukuoka University', value: 'Fukuoka University' },
  { label: 'Fukuoka University of Education', value: 'Fukuoka University of Education' },
  { label: "Fukuoka Women's University", value: "Fukuoka Women's University" },
  { label: 'Fukushima Medical College', value: 'Fukushima Medical College' },
  { label: 'Fukushima University', value: 'Fukushima University' },
  { label: 'Fukuyama Heisei University', value: 'Fukuyama Heisei University' },
  { label: 'Fukuyama University', value: 'Fukuyama University' },
  { label: 'Full Sail University', value: 'Full Sail University' },
  { label: 'Fundação Educacional de Ituverava', value: 'Fundação Educacional de Ituverava' },
  { label: 'Fundacion Escuela Colombiana de Rehabiliación', value: 'Fundacion Escuela Colombiana de Rehabiliación' },
  { label: 'Fundación Universitaria de Boyacá', value: 'Fundación Universitaria de Boyacá' },
  {
    label: 'Fundación Universitaria del Area Andina. Sede Pereira',
    value: 'Fundación Universitaria del Area Andina. Sede Pereira',
  },
  { label: 'Fundación Universitaria Luis Amigó', value: 'Fundación Universitaria Luis Amigó' },
  { label: 'Fundación Universitaria Manuela Beltrán', value: 'Fundación Universitaria Manuela Beltrán' },
  { label: 'Fundación Universitaria San Martín', value: 'Fundación Universitaria San Martín' },
  { label: 'Furman University', value: 'Furman University' },
  { label: 'Fushun Petroleum University', value: 'Fushun Petroleum University' },
  { label: 'Future University', value: 'Future University' },
  { label: 'Fuzhou University', value: 'Fuzhou University' },
  { label: 'Gaborone Universal College of Law', value: 'Gaborone Universal College of Law' },
  { label: 'Gakushuin University', value: 'Gakushuin University' },
  { label: 'Galatasaray University', value: 'Galatasaray University' },
  { label: 'Galillee College', value: 'Galillee College' },
  { label: 'Gallaudet University', value: 'Gallaudet University' },
  { label: 'Galway Mayo Institute of Technology', value: 'Galway Mayo Institute of Technology' },
  { label: 'Gandhara Institute of Medical Sciences', value: 'Gandhara Institute of Medical Sciences' },
  { label: 'Gandhi Institute of Technology and Managment', value: 'Gandhi Institute of Technology and Managment' },
  { label: 'Gandhigram Rural Institute', value: 'Gandhigram Rural Institute' },
  { label: 'Gangdara Institute Of Science & Technology', value: 'Gangdara Institute Of Science & Technology' },
  { label: 'Ganja State University ', value: 'Ganja State University ' },
  { label: 'Gannon University', value: 'Gannon University' },
  { label: 'Gansu Agricultural University', value: 'Gansu Agricultural University' },
  { label: 'Gansu University of Technology', value: 'Gansu University of Technology' },
  { label: 'Gardner Webb University', value: 'Gardner Webb University' },
  { label: 'Gauhati University', value: 'Gauhati University' },
  { label: 'Gazi University Ankara', value: 'Gazi University Ankara' },
  { label: 'Gaziantep University', value: 'Gaziantep University' },
  { label: 'Gaziosmanpasa University', value: 'Gaziosmanpasa University' },
  { label: 'GC University', value: 'GC University' },
  { label: 'Gdansk Management College', value: 'Gdansk Management College' },
  { label: 'Gdynia Maritime Academy', value: 'Gdynia Maritime Academy' },
  { label: 'Gebze Institute of Technology', value: 'Gebze Institute of Technology' },
  { label: 'Gediz University', value: 'Gediz University' },
  { label: 'Gemological Institute of America', value: 'Gemological Institute of America' },
  { label: 'Gemsville Technical University', value: 'Gemsville Technical University' },
  { label: 'Geneva Business School', value: 'Geneva Business School' },
  { label: 'Geneva College', value: 'Geneva College' },
  { label: 'Georg-August Universität Göttingen', value: 'Georg-August Universität Göttingen' },
  { label: 'Georg-Simon-Ohm-Fachhochschule Nürnberg', value: 'Georg-Simon-Ohm-Fachhochschule Nürnberg' },
  { label: 'George Brown College', value: 'George Brown College' },
  { label: 'George Fox University', value: 'George Fox University' },
  { label: 'George Mason University', value: 'George Mason University' },
  { label: 'George Washington University', value: 'George Washington University' },
  { label: 'George Wythe College', value: 'George Wythe College' },
  { label: 'Georgetown College', value: 'Georgetown College' },
  { label: 'Georgetown University', value: 'Georgetown University' },
  { label: 'Georgia Baptist College of Nursing', value: 'Georgia Baptist College of Nursing' },
  { label: 'Georgia College & State University', value: 'Georgia College & State University' },
  { label: 'Georgia Health Sciences University', value: 'Georgia Health Sciences University' },
  { label: 'Georgia Institute of Technology', value: 'Georgia Institute of Technology' },
  { label: 'Georgia School of Professional Psychology', value: 'Georgia School of Professional Psychology' },
  { label: 'Georgia Southern University', value: 'Georgia Southern University' },
  { label: 'Georgia Southwestern University', value: 'Georgia Southwestern University' },
  { label: 'Georgia State University', value: 'Georgia State University' },
  { label: 'Georgian Court College', value: 'Georgian Court College' },
  { label: 'Georgian Technical University', value: 'Georgian Technical University' },
  { label: 'German Jordanian University', value: 'German Jordanian University' },
  { label: 'German University in Cairo', value: 'German University in Cairo' },
  { label: 'Gettysburg College', value: 'Gettysburg College' },
  { label: 'Gezira College of Technology', value: 'Gezira College of Technology' },
  { label: 'Ghana Christian University College', value: 'Ghana Christian University College' },
  {
    label: 'Ghana Institute of Management and Public Administration (GIMPA)',
    value: 'Ghana Institute of Management and Public Administration (GIMPA)',
  },
  { label: 'Ghana Telecom University College', value: 'Ghana Telecom University College' },
  { label: 'Ghazni University', value: 'Ghazni University' },
  {
    label: 'Ghulam Ishaq Khan Institute of Science & Technology',
    value: 'Ghulam Ishaq Khan Institute of Science & Technology',
  },
  { label: 'Gift University', value: 'Gift University' },
  { label: 'Gifu Keizai University', value: 'Gifu Keizai University' },
  { label: 'Gifu Pharmaceutical University', value: 'Gifu Pharmaceutical University' },
  { label: 'Gifu Shotoku Gakuen University', value: 'Gifu Shotoku Gakuen University' },
  { label: 'Gifu University', value: 'Gifu University' },
  { label: 'Gifu University for Education and Languages', value: 'Gifu University for Education and Languages' },
  { label: "Gifu Women's University", value: "Gifu Women's University" },
  { label: 'Girne American University', value: 'Girne American University' },
  { label: 'Glasgow Caledonian University', value: 'Glasgow Caledonian University' },
  { label: 'Glasgow School of Art', value: 'Glasgow School of Art' },
  { label: 'Glendale University College of Law', value: 'Glendale University College of Law' },
  { label: 'Glenville State College', value: 'Glenville State College' },
  { label: 'Glion Institute of Higher Education', value: 'Glion Institute of Higher Education' },
  { label: 'Global Business School Barcelona', value: 'Global Business School Barcelona' },
  { label: 'Global Open University , Nagaland', value: 'Global Open University , Nagaland' },
  { label: 'Global University', value: 'Global University' },
  { label: 'Gnesins Russian Academy of Music', value: 'Gnesins Russian Academy of Music' },
  { label: 'Goa University', value: 'Goa University' },
  { label: "God's Bible School and College", value: "God's Bible School and College" },
  { label: 'Goddard College', value: 'Goddard College' },
  { label: 'Godfrey Okoye University', value: 'Godfrey Okoye University' },
  { label: 'Godfrey Okoye University ', value: 'Godfrey Okoye University ' },
  { label: 'Gokhale Institute of Politics and Economics', value: 'Gokhale Institute of Politics and Economics' },
  { label: 'Golden Gate University', value: 'Golden Gate University' },
  { label: 'Goldey-Beacom College', value: 'Goldey-Beacom College' },
  { label: 'Goldsmiths College, University of London', value: 'Goldsmiths College, University of London' },
  { label: 'Golestan University of Medical Sciences', value: 'Golestan University of Medical Sciences' },
  { label: 'Gollis University', value: 'Gollis University' },
  { label: 'Gomal University', value: 'Gomal University' },
  { label: 'Gombe State University', value: 'Gombe State University' },
  { label: 'Gomel State Medical University', value: 'Gomel State Medical University' },
  { label: 'Gomel State Technical University Pavel Sukhoi', value: 'Gomel State Technical University Pavel Sukhoi' },
  { label: 'Gomel State University Francisk Scarnia', value: 'Gomel State University Francisk Scarnia' },
  { label: 'Gonabad University of Medical Sciences', value: 'Gonabad University of Medical Sciences' },
  { label: 'Gondar University', value: 'Gondar University' },
  { label: 'Gonzaga University', value: 'Gonzaga University' },
  { label: 'Gordon College', value: 'Gordon College' },
  { label: 'Gordon Conwell Theological Seminary', value: 'Gordon Conwell Theological Seminary' },
  {
    label: 'Gorgan University of Agricultural Sciences and Natural Resources',
    value: 'Gorgan University of Agricultural Sciences and Natural Resources',
  },
  { label: 'Gorno-Altaisk State University', value: 'Gorno-Altaisk State University' },
  { label: 'Goshen College', value: 'Goshen College' },
  { label: 'Göteborg University', value: 'Göteborg University' },
  { label: 'Gotland University College', value: 'Gotland University College' },
  { label: 'Goucher College', value: 'Goucher College' },
  { label: 'Government College University Faisalabad', value: 'Government College University Faisalabad' },
  { label: 'Government College University Lahore', value: 'Government College University Lahore' },
  { label: 'Governors State University', value: 'Governors State University' },
  {
    label: 'Govind Ballabh Pant University of Agriculture and Technology',
    value: 'Govind Ballabh Pant University of Agriculture and Technology',
  },
  { label: 'Grace Bible College', value: 'Grace Bible College' },
  { label: 'Grace College', value: 'Grace College' },
  { label: 'Grace University', value: 'Grace University' },
  { label: 'Graceland College', value: 'Graceland College' },
  {
    label: 'Graduate School of Business Administration Zurich (GSBA Zurich)',
    value: 'Graduate School of Business Administration Zurich (GSBA Zurich)',
  },
  { label: 'Graduate Theological Union', value: 'Graduate Theological Union' },
  { label: 'Graduate University for Advanced Studies', value: 'Graduate University for Advanced Studies' },
  { label: 'Grambling State University', value: 'Grambling State University' },
  { label: 'Grand Canyon University', value: 'Grand Canyon University' },
  { label: 'Grand Valley State University', value: 'Grand Valley State University' },
  { label: 'Grand View College', value: 'Grand View College' },
  { label: 'Grantham University', value: 'Grantham University' },
  { label: 'Gratz College', value: 'Gratz College' },
  { label: 'Great Lakes Christian College', value: 'Great Lakes Christian College' },
  { label: 'Great Lakes University of Kisumu', value: 'Great Lakes University of Kisumu' },
  { label: 'Great Zimbabwe University', value: 'Great Zimbabwe University' },
  { label: 'Green Mountain College', value: 'Green Mountain College' },
  { label: 'Green University of Bangladesh', value: 'Green University of Bangladesh' },
  { label: 'Greenford International University', value: 'Greenford International University' },
  { label: 'Greenheart Medical School', value: 'Greenheart Medical School' },
  { label: 'Greensboro College', value: 'Greensboro College' },
  { label: 'Greenville College', value: 'Greenville College' },
  { label: 'Greenwich University', value: 'Greenwich University' },
  { label: 'Grenoble Ecole de Management', value: 'Grenoble Ecole de Management' },
  { label: 'Gretsa Universtiy', value: 'Gretsa Universtiy' },
  { label: 'Griffith College', value: 'Griffith College' },
  { label: 'Griffith University', value: 'Griffith University' },
  { label: 'Grinnell College', value: 'Grinnell College' },
  { label: 'Grodno State Agrarian University', value: 'Grodno State Agrarian University' },
  { label: 'Grodno State Medical University', value: 'Grodno State Medical University' },
  { label: 'Grodno State University Yanka Kupaly', value: 'Grodno State University Yanka Kupaly' },
  { label: 'Groupe Sup de Co Amiens Picardie', value: 'Groupe Sup de Co Amiens Picardie' },
  { label: 'Groupe Sup de Co Montpellier', value: 'Groupe Sup de Co Montpellier' },
  { label: 'Grove City College', value: 'Grove City College' },
  { label: 'Guangdong Peizheng College ', value: 'Guangdong Peizheng College ' },
  { label: 'Guangdong Polytechnic Normal University', value: 'Guangdong Polytechnic Normal University' },
  { label: 'Guangdong Radio & TV University', value: 'Guangdong Radio & TV University' },
  { label: 'Guangdong University of Foreign Studies', value: 'Guangdong University of Foreign Studies' },
  { label: 'Guangdong University of Technology', value: 'Guangdong University of Technology' },
  { label: 'Guangxi Medical University', value: 'Guangxi Medical University' },
  { label: 'Guangxi Normal University', value: 'Guangxi Normal University' },
  { label: 'Guangxi Traditional Chinese Medical University', value: 'Guangxi Traditional Chinese Medical University' },
  { label: 'Guangxi University', value: 'Guangxi University' },
  { label: 'Guangxi University for Nationalities', value: 'Guangxi University for Nationalities' },
  { label: 'Guangzhou Academy of Fine Art', value: 'Guangzhou Academy of Fine Art' },
  { label: 'Guangzhou Normal University', value: 'Guangzhou Normal University' },
  { label: 'Guangzhou University', value: 'Guangzhou University' },
  {
    label: 'Guangzhou University of Traditional Chinese Medicine',
    value: 'Guangzhou University of Traditional Chinese Medicine',
  },
  { label: 'Gubkin Russian State University of Oil and Gas', value: 'Gubkin Russian State University of Oil and Gas' },
  { label: 'Guilan University', value: 'Guilan University' },
  { label: 'Guilan University of Medical Sciences', value: 'Guilan University of Medical Sciences' },
  { label: 'Guilford College', value: 'Guilford College' },
  { label: 'Guizhou Normal University', value: 'Guizhou Normal University' },
  { label: 'Guizhou University', value: 'Guizhou University' },
  { label: 'Gujarat Ayurved University', value: 'Gujarat Ayurved University' },
  { label: 'Gujarat Technological University Ahmedabad', value: 'Gujarat Technological University Ahmedabad' },
  { label: 'Gujarat University Ahmedabad', value: 'Gujarat University Ahmedabad' },
  { label: 'Gulbarga University', value: 'Gulbarga University' },
  { label: 'Gulf Medical University', value: 'Gulf Medical University' },
  { label: 'Gulf University College', value: 'Gulf University College' },
  { label: 'Gulf University for Science and Technology', value: 'Gulf University for Science and Technology' },
  { label: 'Gulhane Military Medical Academy', value: 'Gulhane Military Medical Academy' },
  { label: 'Gulu University', value: 'Gulu University' },
  { label: "Gunma Prefectural Women's University", value: "Gunma Prefectural Women's University" },
  { label: 'Gunma University', value: 'Gunma University' },
  { label: 'Guru Ghasidas University', value: 'Guru Ghasidas University' },
  { label: 'Guru Gobind Singh Indraprastha University', value: 'Guru Gobind Singh Indraprastha University' },
  { label: 'Guru Jambeshwar University', value: 'Guru Jambeshwar University' },
  { label: 'Guru Nanak Dev University', value: 'Guru Nanak Dev University' },
  { label: 'Gurukul University', value: 'Gurukul University' },
  { label: 'Gurukula Kangri University', value: 'Gurukula Kangri University' },
  { label: 'Gustav-Siewerth-Akademie', value: 'Gustav-Siewerth-Akademie' },
  { label: 'Gustavus Adolphus College', value: 'Gustavus Adolphus College' },
  { label: 'Gwangju Catholic College', value: 'Gwangju Catholic College' },
  { label: 'Gwynedd-Mercy College', value: 'Gwynedd-Mercy College' },
  { label: 'Gyeongju University', value: 'Gyeongju University' },
  { label: 'Gyeongsang National University', value: 'Gyeongsang National University' },
  { label: 'Hacettepe University', value: 'Hacettepe University' },
  { label: 'Hachinohe Institute of Technology', value: 'Hachinohe Institute of Technology' },
  { label: 'Hachinohe University', value: 'Hachinohe University' },
  {
    label: 'Hadhramout University of Science and Technology ',
    value: 'Hadhramout University of Science and Technology ',
  },
  { label: 'Hafencity Universität Hamburg', value: 'Hafencity Universität Hamburg' },
  { label: 'Hagerstown Community College', value: 'Hagerstown Community College' },
  { label: 'Hahnamann Honoeopathic Medical College', value: 'Hahnamann Honoeopathic Medical College' },
  { label: 'Hai Duong University', value: 'Hai Duong University' },
  { label: 'Haigazian University', value: 'Haigazian University' },
  { label: 'Hainan Normal University', value: 'Hainan Normal University' },
  { label: 'Hainan University', value: 'Hainan University' },
  {
    label: 'Hajee Mohammad Danesh Science and Technology University',
    value: 'Hajee Mohammad Danesh Science and Technology University',
  },
  { label: 'Hajvery University Lahore for Women', value: 'Hajvery University Lahore for Women' },
  { label: 'Hakodate University', value: 'Hakodate University' },
  { label: 'Hakuoh University', value: 'Hakuoh University' },
  { label: 'Halic University', value: 'Halic University' },
  { label: 'Halla University', value: 'Halla University' },
  { label: 'Hallym University', value: 'Hallym University' },
  { label: 'Halmstad University College', value: 'Halmstad University College' },
  { label: 'Hamadan University of Medical Sciences', value: 'Hamadan University of Medical Sciences' },
  { label: 'Hamamatsu University', value: 'Hamamatsu University' },
  { label: 'Hamamatsu University School of Medicine', value: 'Hamamatsu University School of Medicine' },
  { label: 'Hamdan Bin Mohammed e-University', value: 'Hamdan Bin Mohammed e-University' },
  { label: 'Hamdard University', value: 'Hamdard University' },
  { label: 'Hamilton College', value: 'Hamilton College' },
  { label: 'Hamilton Technical College', value: 'Hamilton Technical College' },
  { label: 'Hamline University', value: 'Hamline University' },
  { label: 'Hampden-Sydney College', value: 'Hampden-Sydney College' },
  { label: 'Hampshire College', value: 'Hampshire College' },
  { label: 'Hampton College', value: 'Hampton College' },
  { label: 'Hampton University', value: 'Hampton University' },
  { label: 'Hanazono University', value: 'Hanazono University' },
  { label: 'Handelshochschule Leipzig', value: 'Handelshochschule Leipzig' },
  { label: 'Hankuk Aviation University', value: 'Hankuk Aviation University' },
  { label: 'Hankuk University of Foreign Studies', value: 'Hankuk University of Foreign Studies' },
  { label: 'Hankyong National University', value: 'Hankyong National University' },
  { label: 'Hannam University', value: 'Hannam University' },
  { label: 'Hannan University', value: 'Hannan University' },
  { label: 'Hannibal-Lagrange College', value: 'Hannibal-Lagrange College' },
  { label: 'Hanoi Medical University', value: 'Hanoi Medical University' },
  { label: 'Hanoi National Economics University', value: 'Hanoi National Economics University' },
  { label: 'Hanoi Open University', value: 'Hanoi Open University' },
  { label: 'Hanoi University of Architecture', value: 'Hanoi University of Architecture' },
  { label: 'Hanoi University of Civil Engineering', value: 'Hanoi University of Civil Engineering' },
  { label: 'Hanoi University of Mining and Geology', value: 'Hanoi University of Mining and Geology' },
  { label: 'Hanoi University of Science', value: 'Hanoi University of Science' },
  { label: 'Hanoi University of Science & Technology', value: 'Hanoi University of Science & Technology' },
  { label: 'Hanover College', value: 'Hanover College' },
  { label: 'Hansei University', value: 'Hansei University' },
  { label: 'Hanseo University', value: 'Hanseo University' },
  { label: 'Hanshin University', value: 'Hanshin University' },
  { label: 'Hansung University Seoul', value: 'Hansung University Seoul' },
  { label: 'Hanyang University', value: 'Hanyang University' },
  { label: 'Hanzehogeschool Groningen', value: 'Hanzehogeschool Groningen' },
  { label: 'Haramaya University', value: 'Haramaya University' },
  { label: 'Harare Institute of Technology', value: 'Harare Institute of Technology' },
  { label: 'Harbin Engineering University', value: 'Harbin Engineering University' },
  { label: 'Harbin Institute of Technology', value: 'Harbin Institute of Technology' },
  { label: 'Harbin Medical University', value: 'Harbin Medical University' },
  { label: 'Harbin Normal University', value: 'Harbin Normal University' },
  {
    label: 'Harbin University of Civil Engineering & Architecture',
    value: 'Harbin University of Civil Engineering & Architecture',
  },
  { label: 'Harbin University of Science and Technology', value: 'Harbin University of Science and Technology' },
  { label: 'Hardin-Simmons University', value: 'Hardin-Simmons University' },
  { label: 'Harding University', value: 'Harding University' },
  { label: 'Harding University Graduate School of Religion', value: 'Harding University Graduate School of Religion' },
  { label: 'Hariri Canadian University', value: 'Hariri Canadian University' },
  { label: 'Harokopio University', value: 'Harokopio University' },
  { label: 'Harran University', value: 'Harran University' },
  { label: 'Harrington Institute of Interior Design', value: 'Harrington Institute of Interior Design' },
  { label: 'Harris-Stowe State University', value: 'Harris-Stowe State University' },
  { label: 'Hartford College for Women', value: 'Hartford College for Women' },
  {
    label: 'Hartford Graduate Center (Rensselaer at Hartford)',
    value: 'Hartford Graduate Center (Rensselaer at Hartford)',
  },
  { label: 'Hartwick College', value: 'Hartwick College' },
  { label: 'Harvard University', value: 'Harvard University' },
  { label: 'Harvey Mudd College', value: 'Harvey Mudd College' },
  { label: 'Hashemite University', value: 'Hashemite University' },
  { label: 'Hasselt University', value: 'Hasselt University' },
  { label: 'Hastings College', value: 'Hastings College' },
  { label: 'Hatyai University', value: 'Hatyai University' },
  { label: 'Haverford College', value: 'Haverford College' },
  { label: 'Hawaii Pacific University', value: 'Hawaii Pacific University' },
  { label: 'Hawassa University', value: 'Hawassa University' },
  { label: 'Hawler Medical University', value: 'Hawler Medical University' },
  {
    label: 'Haynal Imre University of Health Sciences Budapest',
    value: 'Haynal Imre University of Health Sciences Budapest',
  },
  { label: 'Hazara University', value: 'Hazara University' },
  { label: 'Health sciences University of Mongolia', value: 'Health sciences University of Mongolia' },
  { label: 'Heartland Baptist Bible College', value: 'Heartland Baptist Bible College' },
  { label: 'Hebei Academy of Fine Art', value: 'Hebei Academy of Fine Art' },
  { label: 'Hebei Agricultural University', value: 'Hebei Agricultural University' },
  { label: 'Hebei Medical University', value: 'Hebei Medical University' },
  { label: 'Hebei Normal University', value: 'Hebei Normal University' },
  { label: 'Hebei United University', value: 'Hebei United University' },
  { label: 'Hebei University', value: 'Hebei University' },
  { label: 'Hebei University of Economics and Trade', value: 'Hebei University of Economics and Trade' },
  { label: 'Hebei University of Science and Technology', value: 'Hebei University of Science and Technology' },
  { label: 'Hebei University of Technology', value: 'Hebei University of Technology' },
  { label: 'Hebrew College', value: 'Hebrew College' },
  { label: 'Hebrew University of Jerusalem', value: 'Hebrew University of Jerusalem' },
  { label: 'Hebron University', value: 'Hebron University' },
  { label: 'Hefei University of Technology', value: 'Hefei University of Technology' },
  { label: 'Hehai University', value: 'Hehai University' },
  { label: 'Heidelberg College', value: 'Heidelberg College' },
  { label: 'Heilongjiang August 1st Reclamation University', value: 'Heilongjiang August 1st Reclamation University' },
  { label: 'Heilongjiang Commercial University', value: 'Heilongjiang Commercial University' },
  { label: 'Heilongjiang University', value: 'Heilongjiang University' },
  { label: 'Heinrich-Heine Universität Düsseldorf', value: 'Heinrich-Heine Universität Düsseldorf' },
  { label: 'Heisei International University', value: 'Heisei International University' },
  { label: 'Hellenic Army Academy', value: 'Hellenic Army Academy' },
  { label: 'Hellenic Open University', value: 'Hellenic Open University' },
  { label: 'Help University College', value: 'Help University College' },
  {
    label: 'Helsinki School of Economics and Business Administration',
    value: 'Helsinki School of Economics and Business Administration',
  },
  { label: 'Helsinki University of Technology', value: 'Helsinki University of Technology' },
  { label: 'Helwan University', value: 'Helwan University' },
  { label: 'Hemchandracharay North Gujarat University', value: 'Hemchandracharay North Gujarat University' },
  { label: 'Hemwati Nandan Bahuguna Garhwal University', value: 'Hemwati Nandan Bahuguna Garhwal University' },
  { label: 'Henan Agriculture University', value: 'Henan Agriculture University' },
  { label: 'Henan Buddhist College', value: 'Henan Buddhist College' },
  { label: 'Henan Normal University', value: 'Henan Normal University' },
  { label: 'Henan Univeristy', value: 'Henan Univeristy' },
  { label: 'Henderson State Univerisity', value: 'Henderson State Univerisity' },
  { label: 'Hendrix College', value: 'Hendrix College' },
  { label: 'Herat University', value: 'Herat University' },
  { label: 'Heriot-Watt University', value: 'Heriot-Watt University' },
  { label: 'Heritage College', value: 'Heritage College' },
  { label: 'Hertie School of Governance', value: 'Hertie School of Governance' },
  { label: 'Herzen State Pedagogical University of Russia', value: 'Herzen State Pedagogical University of Russia' },
  { label: 'Hesser College', value: 'Hesser College' },
  { label: 'Heythrop College, University of London', value: 'Heythrop College, University of London' },
  { label: 'Hidayatullah National Law University, Raipur', value: 'Hidayatullah National Law University, Raipur' },
  { label: 'Higashi Nippon International University', value: 'Higashi Nippon International University' },
  { label: 'High Institute for Banking & Financial Studies', value: 'High Institute for Banking & Financial Studies' },
  { label: 'High Point University', value: 'High Point University' },
  { label: 'Higher Colleges of Technology', value: 'Higher Colleges of Technology' },
  {
    label: 'Higher Institute of Agriculture and Animal Husbandry',
    value: 'Higher Institute of Agriculture and Animal Husbandry',
  },
  { label: 'Higher Institute of Business Administration', value: 'Higher Institute of Business Administration' },
  { label: 'Higher School o Business in Tarnow', value: 'Higher School o Business in Tarnow' },
  {
    label: 'Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz',
    value: 'Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz',
  },
  { label: 'Higher School of Economics', value: 'Higher School of Economics' },
  { label: 'Higher School of Psychology', value: 'Higher School of Psychology' },
  {
    label: 'Higher School of University and Advanced Studies Pisa',
    value: 'Higher School of University and Advanced Studies Pisa',
  },
  { label: 'Higher Technological Institute', value: 'Higher Technological Institute' },
  { label: 'Hiiraan University', value: 'Hiiraan University' },
  { label: 'Hijiyama University', value: 'Hijiyama University' },
  { label: 'Hilbert College', value: 'Hilbert College' },
  { label: 'Hillsdale College', value: 'Hillsdale College' },
  { label: 'Himachal Pradesh Agricultural University', value: 'Himachal Pradesh Agricultural University' },
  { label: 'Himachal Pradesh University', value: 'Himachal Pradesh University' },
  { label: 'Himeji Dokkyo University', value: 'Himeji Dokkyo University' },
  { label: 'Himeji Institute of Technology', value: 'Himeji Institute of Technology' },
  { label: 'Hiram College', value: 'Hiram College' },
  { label: 'Hirosaki Gakuin University', value: 'Hirosaki Gakuin University' },
  { label: 'Hirosaki University', value: 'Hirosaki University' },
  { label: "Hiroshima Bunkyo Women's University", value: "Hiroshima Bunkyo Women's University" },
  { label: 'Hiroshima City University', value: 'Hiroshima City University' },
  { label: 'Hiroshima Institute of Technology', value: 'Hiroshima Institute of Technology' },
  { label: 'Hiroshima International University', value: 'Hiroshima International University' },
  { label: 'Hiroshima Jogakuin University', value: 'Hiroshima Jogakuin University' },
  { label: 'Hiroshima Kokusai Gakuin University', value: 'Hiroshima Kokusai Gakuin University' },
  { label: 'Hiroshima Prefectural University', value: 'Hiroshima Prefectural University' },
  { label: 'Hiroshima Shudo University', value: 'Hiroshima Shudo University' },
  { label: 'Hiroshima University', value: 'Hiroshima University' },
  { label: 'Hiroshima University of Economics', value: 'Hiroshima University of Economics' },
  { label: "Hiroshima Women's University", value: "Hiroshima Women's University" },
  { label: 'Hitec University', value: 'Hitec University' },
  { label: 'Hitotsubashi University', value: 'Hitotsubashi University' },
  { label: 'Ho Chi Minh City Open University', value: 'Ho Chi Minh City Open University' },
  {
    label: 'Ho Chi Minh City University of Agriculture and Forestry',
    value: 'Ho Chi Minh City University of Agriculture and Forestry',
  },
  { label: 'Ho Chi Minh City University of Architecture', value: 'Ho Chi Minh City University of Architecture' },
  { label: 'Ho Chi Minh City University of Economics', value: 'Ho Chi Minh City University of Economics' },
  {
    label: 'Ho Chi Minh City University of Foreign Languages and Information Technology',
    value: 'Ho Chi Minh City University of Foreign Languages and Information Technology',
  },
  { label: 'Ho Chi Minh City University of Law', value: 'Ho Chi Minh City University of Law' },
  {
    label: 'Ho Chi Minh City University of Medicine and Pharmacy',
    value: 'Ho Chi Minh City University of Medicine and Pharmacy',
  },
  {
    label: 'Ho Chi Minh City University of Natural Sciences',
    value: 'Ho Chi Minh City University of Natural Sciences',
  },
  { label: 'Ho Chi Minh City University of Pedagogics', value: 'Ho Chi Minh City University of Pedagogics' },
  {
    label: 'Ho Chi Minh City University of Social Sciences and Humanities',
    value: 'Ho Chi Minh City University of Social Sciences and Humanities',
  },
  { label: 'Ho Chi Minh City University of Technology', value: 'Ho Chi Minh City University of Technology' },
  { label: 'Ho Chi Minh City University of Transport', value: 'Ho Chi Minh City University of Transport' },
  { label: 'Ho Polytechnic ', value: 'Ho Polytechnic ' },
  { label: 'Hobart and William Smith Colleges', value: 'Hobart and William Smith Colleges' },
  { label: 'Hobe Sound Bible College', value: 'Hobe Sound Bible College' },
  { label: 'Hochschule Albstadt-Sigmaringen', value: 'Hochschule Albstadt-Sigmaringen' },
  {
    label: 'Hochschule Anhalt (FH), Hochschule für angewandte Wissenschaften',
    value: 'Hochschule Anhalt (FH), Hochschule für angewandte Wissenschaften',
  },
  { label: 'Hochschule Bremen', value: 'Hochschule Bremen' },
  { label: 'Hochschule Bremerhaven', value: 'Hochschule Bremerhaven' },
  { label: 'Hochschule Coburg', value: 'Hochschule Coburg' },
  { label: 'Hochschule Darmstadt', value: 'Hochschule Darmstadt' },
  { label: 'Hochschule Esslingen', value: 'Hochschule Esslingen' },
  { label: 'Hochschule Fulda', value: 'Hochschule Fulda' },
  {
    label: 'Hochschule für Bankwirtschaft (HfB), Private Fachhochschule der Bankakademie',
    value: 'Hochschule für Bankwirtschaft (HfB), Private Fachhochschule der Bankakademie',
  },
  { label: 'Hochschule für Berufstätige Rendsburg', value: 'Hochschule für Berufstätige Rendsburg' },
  { label: 'Hochschule für Gestaltung und Kunst Zürich', value: 'Hochschule für Gestaltung und Kunst Zürich' },
  { label: 'Hochschule für Internationales Management', value: 'Hochschule für Internationales Management' },
  { label: 'Hochschule für Jüdische Studien Heidelberg', value: 'Hochschule für Jüdische Studien Heidelberg' },
  { label: 'Hochschule für Philosophie München', value: 'Hochschule für Philosophie München' },
  { label: 'Hochschule für Politik (HFP)', value: 'Hochschule für Politik (HFP)' },
  {
    label: 'Hochschule für Technik und Wirtschaft des Saarlandes',
    value: 'Hochschule für Technik und Wirtschaft des Saarlandes',
  },
  {
    label: 'Hochschule für Technik und Wirtschaft Dresden (FH)',
    value: 'Hochschule für Technik und Wirtschaft Dresden (FH)',
  },
  {
    label: 'Hochschule für Technik und Wirtschaft Karlsruhe',
    value: 'Hochschule für Technik und Wirtschaft Karlsruhe',
  },
  {
    label: 'Hochschule für Technik, Wirtschaft und Kultur Leipzig (FH)',
    value: 'Hochschule für Technik, Wirtschaft und Kultur Leipzig (FH)',
  },
  {
    label: 'Hochschule Harz, Hochschule für angewandte Wissenschaften (FH)',
    value: 'Hochschule Harz, Hochschule für angewandte Wissenschaften (FH)',
  },
  { label: 'Hochschule Magdeburg-Stendal (FH)', value: 'Hochschule Magdeburg-Stendal (FH)' },
  { label: 'Hochschule Mittweida (FH)', value: 'Hochschule Mittweida (FH)' },
  { label: 'Hochschule Vechta', value: 'Hochschule Vechta' },
  {
    label: 'Hochschule Wismar, Fachhochschule für Technik, Wirtschaft und Gestaltung',
    value: 'Hochschule Wismar, Fachhochschule für Technik, Wirtschaft und Gestaltung',
  },
  { label: 'Hochschule Zittau/Görlitz (FH)', value: 'Hochschule Zittau/Görlitz (FH)' },
  { label: 'Hodeidah University', value: 'Hodeidah University' },
  { label: 'Hodges University', value: 'Hodges University' },
  { label: 'Hofstra University', value: 'Hofstra University' },
  { label: 'Hogere Zeevaartschool - Maritime Academy', value: 'Hogere Zeevaartschool - Maritime Academy' },
  { label: 'Hogeschool Antwerpen', value: 'Hogeschool Antwerpen' },
  { label: 'Hogeschool Inholland', value: 'Hogeschool Inholland' },
  { label: 'Hogeschool Leiden', value: 'Hogeschool Leiden' },
  { label: 'Hogeschool Rotterdam', value: 'Hogeschool Rotterdam' },
  { label: 'Hogeschool Utrecht', value: 'Hogeschool Utrecht' },
  { label: 'Hogeschool van Amsterdam', value: 'Hogeschool van Amsterdam' },
  { label: 'Hogeschool voor de Kunsten Utrecht (HKU)', value: 'Hogeschool voor de Kunsten Utrecht (HKU)' },
  { label: 'Hogeschool voor Wetenschap & Kunst', value: 'Hogeschool voor Wetenschap & Kunst' },
  {
    label: 'Hogeschool voor Wetenschap en Kunst (VLEKHO), Brussel',
    value: 'Hogeschool voor Wetenschap en Kunst (VLEKHO), Brussel',
  },
  { label: 'Hogeschool West-Vlaanderen (TU)', value: 'Hogeschool West-Vlaanderen (TU)' },
  { label: 'Hohai University Changzhou', value: 'Hohai University Changzhou' },
  { label: 'Hokkaido Information University', value: 'Hokkaido Information University' },
  { label: 'Hokkaido Institute of Pharmaceutical Sciences', value: 'Hokkaido Institute of Pharmaceutical Sciences' },
  { label: 'Hokkaido Institute of Technology', value: 'Hokkaido Institute of Technology' },
  { label: 'Hokkaido Tokai University', value: 'Hokkaido Tokai University' },
  { label: 'Hokkaido University', value: 'Hokkaido University' },
  { label: 'Hokkaido University of Education', value: 'Hokkaido University of Education' },
  { label: 'Hokkaido University of Health Sciences', value: 'Hokkaido University of Health Sciences' },
  { label: 'Hokkaigakuen University', value: 'Hokkaigakuen University' },
  { label: 'Hokkaigakuen University of Kitami', value: 'Hokkaigakuen University of Kitami' },
  { label: 'Hokuriku University', value: 'Hokuriku University' },
  { label: 'Hokusei Gakuen University', value: 'Hokusei Gakuen University' },
  { label: 'Holar University College', value: 'Holar University College' },
  { label: 'Hollins University', value: 'Hollins University' },
  { label: 'Holmes Institute', value: 'Holmes Institute' },
  { label: 'Holy Angel University', value: 'Holy Angel University' },
  { label: 'Holy Apostles College', value: 'Holy Apostles College' },
  { label: 'Holy Cross College', value: 'Holy Cross College' },
  { label: 'Holy Family College', value: 'Holy Family College' },
  { label: 'Holy Names College', value: 'Holy Names College' },
  { label: 'Holy Spirit University of Kaslik', value: 'Holy Spirit University of Kaslik' },
  { label: 'Honam University', value: 'Honam University' },
  { label: 'Hong Bang University International', value: 'Hong Bang University International' },
  { label: 'Hong Kong Academy for Performing Arts ', value: 'Hong Kong Academy for Performing Arts ' },
  { label: 'Hong Kong Baptist University', value: 'Hong Kong Baptist University' },
  { label: 'Hong Kong Institute of Education', value: 'Hong Kong Institute of Education' },
  { label: 'Hong Kong Polytechnic University', value: 'Hong Kong Polytechnic University' },
  { label: 'Hong Kong Shue Yan College', value: 'Hong Kong Shue Yan College' },
  { label: 'Hong Kong University of Science and Technology', value: 'Hong Kong University of Science and Technology' },
  { label: 'Hongik University', value: 'Hongik University' },
  { label: 'Hood College', value: 'Hood College' },
  { label: 'Hope Africa University', value: 'Hope Africa University' },
  { label: 'Hope College', value: 'Hope College' },
  { label: 'Hope International University', value: 'Hope International University' },
  { label: 'Horizon College of Business and Technology', value: 'Horizon College of Business and Technology' },
  { label: 'Hormozgan University of Medical Sciences', value: 'Hormozgan University of Medical Sciences' },
  { label: 'Hosei University', value: 'Hosei University' },
  { label: 'Hoseo University', value: 'Hoseo University' },
  { label: 'Hoshi University', value: 'Hoshi University' },
  { label: 'Hotelschool The Hague', value: 'Hotelschool The Hague' },
  { label: 'Houdegbe North American University Benin', value: 'Houdegbe North American University Benin' },
  { label: 'Houghton College', value: 'Houghton College' },
  { label: 'Houston Baptist University', value: 'Houston Baptist University' },
  { label: 'Howard Payne University', value: 'Howard Payne University' },
  { label: 'Howard University', value: 'Howard University' },
  { label: 'Hsuan Chuang University', value: 'Hsuan Chuang University' },
  { label: 'Huachiew Chalermprakiet University', value: 'Huachiew Chalermprakiet University' },
  { label: 'Huafan University', value: 'Huafan University' },
  { label: 'Huaihai Institute of Technology', value: 'Huaihai Institute of Technology' },
  { label: 'Huaihua Medical College', value: 'Huaihua Medical College' },
  { label: 'Huaihua Radio and Television University', value: 'Huaihua Radio and Television University' },
  { label: 'Huaihua University', value: 'Huaihua University' },
  { label: 'Huanghe Science & Technology University', value: 'Huanghe Science & Technology University' },
  { label: 'Huaqiao University Quanzhuo', value: 'Huaqiao University Quanzhuo' },
  { label: 'Huazhong Agricultural University', value: 'Huazhong Agricultural University' },
  { label: 'Huazhong University of Science and Technology', value: 'Huazhong University of Science and Technology' },
  { label: 'Hubei University', value: 'Hubei University' },
  { label: 'Hubert Kairuki Memorial University', value: 'Hubert Kairuki Memorial University' },
  { label: 'Hue University', value: 'Hue University' },
  { label: 'Hue University of Agriculture and Forestry ', value: 'Hue University of Agriculture and Forestry ' },
  { label: 'Huizhou University', value: 'Huizhou University' },
  { label: 'Humber College', value: 'Humber College' },
  { label: 'Humboldt State University', value: 'Humboldt State University' },
  { label: 'Humboldt Universität Berlin', value: 'Humboldt Universität Berlin' },
  { label: 'Humphreys College', value: 'Humphreys College' },
  { label: 'Hunan Agricultural University', value: 'Hunan Agricultural University' },
  { label: 'Hunan Normal University', value: 'Hunan Normal University' },
  { label: 'Hunan University', value: 'Hunan University' },
  { label: 'Hungarian Academy of Craft and Design', value: 'Hungarian Academy of Craft and Design' },
  { label: 'Hungarian Academy of Fine Arts Budapest', value: 'Hungarian Academy of Fine Arts Budapest' },
  { label: 'Hungarian University of Physical Education', value: 'Hungarian University of Physical Education' },
  { label: 'Huntington College', value: 'Huntington College' },
  { label: 'Huron University', value: 'Huron University' },
  { label: 'Huron University College', value: 'Huron University College' },
  { label: 'Huron University USA in London', value: 'Huron University USA in London' },
  { label: 'Husson College', value: 'Husson College' },
  { label: 'Huston-Tillotson College', value: 'Huston-Tillotson College' },
  { label: 'Hvanneyri Agricultural University', value: 'Hvanneyri Agricultural University' },
  {
    label: 'HWP - Hamburger Universität für Wirtschaft und Politik',
    value: 'HWP - Hamburger Universität für Wirtschaft und Politik',
  },
  { label: 'Hyogo College of Medicine', value: 'Hyogo College of Medicine' },
  { label: 'Hyogo University', value: 'Hyogo University' },
  { label: 'Hyogo University of Education', value: 'Hyogo University of Education' },
  { label: 'Hyrcania Institute of Higher Education', value: 'Hyrcania Institute of Higher Education' },
  { label: 'Hyupsung University', value: 'Hyupsung University' },
  { label: 'I-Shou University', value: 'I-Shou University' },
  { label: 'I.M. Sechenov Moscow Medical Academy', value: 'I.M. Sechenov Moscow Medical Academy' },
  { label: 'Iact College', value: 'Iact College' },
  { label: 'Iasar University', value: 'Iasar University' },
  {
    label: 'IASE (Institute of Advanced Studies) Deemed University',
    value: 'IASE (Institute of Advanced Studies) Deemed University',
  },
  { label: 'IBAIS University', value: 'IBAIS University' },
  { label: 'Ibaraki Christian College', value: 'Ibaraki Christian College' },
  {
    label: 'Ibaraki Prefectural University of Health Sciences',
    value: 'Ibaraki Prefectural University of Health Sciences',
  },
  { label: 'Ibaraki University', value: 'Ibaraki University' },
  { label: 'Ibn Sina National College for Medical Studies ', value: 'Ibn Sina National College for Medical Studies ' },
  { label: 'Ibn Sina University', value: 'Ibn Sina University' },
  { label: 'Ibra College of Technology', value: 'Ibra College of Technology' },
  { label: 'Ibrahim Babangida University', value: 'Ibrahim Babangida University' },
  { label: 'Iceland Academy of the Arts', value: 'Iceland Academy of the Arts' },
  { label: 'Iceland University of Education', value: 'Iceland University of Education' },
  { label: 'ICFAI University', value: 'ICFAI University' },
  { label: 'ICI University', value: 'ICI University' },
  { label: 'ICT College', value: 'ICT College' },
  { label: 'Idaho State University', value: 'Idaho State University' },
  {
    label: 'IDRAC (Institut de recherche en action commerciale)',
    value: 'IDRAC (Institut de recherche en action commerciale)',
  },
  { label: 'IE University', value: 'IE University' },
  { label: 'ifs University College', value: 'ifs University College' },
  { label: 'Igbinedion University', value: 'Igbinedion University' },
  { label: 'Iglobal University', value: 'Iglobal University' },
  { label: 'Ilam University', value: 'Ilam University' },
  { label: 'Ilam University of Medical Sciences', value: 'Ilam University of Medical Sciences' },
  { label: 'Ilia Chavchavadze State University', value: 'Ilia Chavchavadze State University' },
  { label: 'Illinois Benedictine University', value: 'Illinois Benedictine University' },
  { label: 'Illinois College', value: 'Illinois College' },
  { label: 'Illinois College of Optometry', value: 'Illinois College of Optometry' },
  { label: 'Illinois Institute of Technology', value: 'Illinois Institute of Technology' },
  {
    label: 'Illinois School of Professional Psychology - Chicago Campus',
    value: 'Illinois School of Professional Psychology - Chicago Campus',
  },
  {
    label: 'Illinois School of Professional Psychology - Meadows Campus',
    value: 'Illinois School of Professional Psychology - Meadows Campus',
  },
  { label: 'Illinois State University', value: 'Illinois State University' },
  { label: 'Illinois Valley Community College', value: 'Illinois Valley Community College' },
  { label: 'Illinois Wesleyan University', value: 'Illinois Wesleyan University' },
  { label: 'Ilsa Independent College', value: 'Ilsa Independent College' },
  { label: 'Imam Khomeini International University', value: 'Imam Khomeini International University' },
  { label: 'Imam Reza University', value: 'Imam Reza University' },
  { label: 'Imam Sadiq University', value: 'Imam Sadiq University' },
  { label: 'Immaculata University', value: 'Immaculata University' },
  { label: 'Immanuel Kant State University of Russia', value: 'Immanuel Kant State University of Russia' },
  { label: 'Imo State University', value: 'Imo State University' },
  { label: 'IMPAC University', value: 'IMPAC University' },
  { label: 'Imperial College London', value: 'Imperial College London' },
  { label: 'Imperial College School of Medicine', value: 'Imperial College School of Medicine' },
  { label: 'Inchon National University of Education', value: 'Inchon National University of Education' },
  { label: 'Inchon University', value: 'Inchon University' },
  { label: 'Independent University, Bangladesh', value: 'Independent University, Bangladesh' },
  { label: 'Indian Agricultural Research Institute', value: 'Indian Agricultural Research Institute' },
  { label: 'Indian Board of Alternative Medicine', value: 'Indian Board of Alternative Medicine' },
  { label: 'Indian Institute of Information Technology', value: 'Indian Institute of Information Technology' },
  {
    label: 'Indian Institute of Information Technology and Management - Kerala',
    value: 'Indian Institute of Information Technology and Management - Kerala',
  },
  {
    label: 'Indian Institute of Management, Tiruchirappalli',
    value: 'Indian Institute of Management, Tiruchirappalli',
  },
  { label: 'Indian Institute of Science', value: 'Indian Institute of Science' },
  { label: 'Indian Institute of Technology, Bombay', value: 'Indian Institute of Technology, Bombay' },
  { label: 'Indian Institute of Technology, Delhi', value: 'Indian Institute of Technology, Delhi' },
  { label: 'Indian Institute of Technology, Guwahati', value: 'Indian Institute of Technology, Guwahati' },
  { label: 'Indian Institute of Technology, Hyderabad', value: 'Indian Institute of Technology, Hyderabad' },
  { label: 'Indian Institute of Technology, Kanpur', value: 'Indian Institute of Technology, Kanpur' },
  { label: 'Indian Institute of Technology, Kharagpur', value: 'Indian Institute of Technology, Kharagpur' },
  { label: 'Indian Institute of Technology, Madras', value: 'Indian Institute of Technology, Madras' },
  { label: 'Indian Institute of Technology, Roorkee', value: 'Indian Institute of Technology, Roorkee' },
  { label: 'Indian Institute of Technology, Roorkee', value: 'Indian Institute of Technology, Roorkee' },
  { label: 'Indian Law Institue', value: 'Indian Law Institue' },
  {
    label: 'Indian School of Business Management and Administration',
    value: 'Indian School of Business Management and Administration',
  },
  { label: 'Indian School of Mines (ISM)', value: 'Indian School of Mines (ISM)' },
  { label: 'Indian Statistical Institute', value: 'Indian Statistical Institute' },
  { label: 'Indian University', value: 'Indian University' },
  { label: 'Indian Veterinary Research Institute', value: 'Indian Veterinary Research Institute' },
  { label: 'Indiana Institute of Technologyy', value: 'Indiana Institute of Technologyy' },
  { label: 'Indiana State University', value: 'Indiana State University' },
  { label: 'Indiana University - East', value: 'Indiana University - East' },
  { label: 'Indiana University - Northwest', value: 'Indiana University - Northwest' },
  { label: 'Indiana University - Southeast', value: 'Indiana University - Southeast' },
  { label: 'Indiana University (System)', value: 'Indiana University (System)' },
  { label: 'Indiana University at Bloomington', value: 'Indiana University at Bloomington' },
  { label: 'Indiana University at Kokomo', value: 'Indiana University at Kokomo' },
  { label: 'Indiana University at South Bend', value: 'Indiana University at South Bend' },
  { label: 'Indiana University of Pennsylvania', value: 'Indiana University of Pennsylvania' },
  {
    label: 'Indiana University-Purdue University at Columbus',
    value: 'Indiana University-Purdue University at Columbus',
  },
  {
    label: 'Indiana University-Purdue University at Fort Wayne',
    value: 'Indiana University-Purdue University at Fort Wayne',
  },
  {
    label: 'Indiana University-Purdue University at Indianapolis',
    value: 'Indiana University-Purdue University at Indianapolis',
  },
  { label: 'Indiana Wesleyan University', value: 'Indiana Wesleyan University' },
  { label: 'Indira Gandhi Agricultural University', value: 'Indira Gandhi Agricultural University' },
  {
    label: 'Indira Gandhi Institute of Development and Research',
    value: 'Indira Gandhi Institute of Development and Research',
  },
  { label: 'Indira Gandhi Institute of Medical Sciences', value: 'Indira Gandhi Institute of Medical Sciences' },
  { label: 'Indira Gandhi National Open University', value: 'Indira Gandhi National Open University' },
  { label: 'Indus Institute of Higher Education', value: 'Indus Institute of Higher Education' },
  {
    label: 'Information and Communications Technology Academy',
    value: 'Information and Communications Technology Academy',
  },
  { label: 'Information and Communications University', value: 'Information and Communications University' },
  { label: 'Inha University', value: 'Inha University' },
  { label: 'Iniciativa Universidad del Atlantico', value: 'Iniciativa Universidad del Atlantico' },
  { label: 'Inje University', value: 'Inje University' },
  { label: 'Inner Mongolia Agricultural University', value: 'Inner Mongolia Agricultural University' },
  { label: 'Inner Mongolia Normal University', value: 'Inner Mongolia Normal University' },
  { label: 'Inner Mongolia Polytechnic University', value: 'Inner Mongolia Polytechnic University' },
  { label: 'Inner Mongolia University', value: 'Inner Mongolia University' },
  { label: 'Inönü University', value: 'Inönü University' },
  { label: 'Inoorero University', value: 'Inoorero University' },
  {
    label: 'Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh',
    value: 'Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh',
  },
  { label: 'Institucion Universitaria de Envigado', value: 'Institucion Universitaria de Envigado' },
  { label: 'Institución Universitaria Iberoamericana', value: 'Institución Universitaria Iberoamericana' },
  {
    label: 'Institue of Historical Research, University of London',
    value: 'Institue of Historical Research, University of London',
  },
  { label: 'Institue of Technology, Tralee', value: 'Institue of Technology, Tralee' },
  { label: 'Institut Armand-Frappier, Université du Québec', value: 'Institut Armand-Frappier, Université du Québec' },
  { label: "Institut Catholique d'Arts et Métiers Lille", value: "Institut Catholique d'Arts et Métiers Lille" },
  { label: "Institut Catholique d'Arts et Métiers Nantes", value: "Institut Catholique d'Arts et Métiers Nantes" },
  { label: 'Institut Catholique de Paris', value: 'Institut Catholique de Paris' },
  { label: 'Institut Catholique de Toulouse', value: 'Institut Catholique de Toulouse' },
  { label: 'Institut Commercial de Nancy', value: 'Institut Commercial de Nancy' },
  {
    label: "Institut d'Agriculture, de Technologie et d'Education de Kibungo",
    value: "Institut d'Agriculture, de Technologie et d'Education de Kibungo",
  },
  { label: "Institut d'Etudes Politiques de Bordeaux", value: "Institut d'Etudes Politiques de Bordeaux" },
  {
    label: "Institut d'Etudes Politiques de Paris (Sciences Po)",
    value: "Institut d'Etudes Politiques de Paris (Sciences Po)",
  },
  {
    label: "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
    value: "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
  },
  { label: 'Institut des Hautes Etudes de Management', value: 'Institut des Hautes Etudes de Management' },
  {
    label: 'Institut des hautes études économiques et commerciales',
    value: 'Institut des hautes études économiques et commerciales',
  },
  {
    label: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
    value: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
  },
  {
    label: "Institut des Sciences de l'Ingénieur de Montpellier",
    value: "Institut des Sciences de l'Ingénieur de Montpellier",
  },
  {
    label: 'Institut des Sciences de la Matière et du Rayonnement',
    value: 'Institut des Sciences de la Matière et du Rayonnement',
  },
  {
    label: 'Institut National de la Recherche Scientifique, Université du Québec',
    value: 'Institut National de la Recherche Scientifique, Université du Québec',
  },
  {
    label: 'Institut National des Postes et Télécommunications',
    value: 'Institut National des Postes et Télécommunications',
  },
  {
    label: 'Institut National des Sciences Appliquées de Lyon',
    value: 'Institut National des Sciences Appliquées de Lyon',
  },
  {
    label: 'Institut National des Sciences Appliquées de Rennes',
    value: 'Institut National des Sciences Appliquées de Rennes',
  },
  {
    label: 'Institut National des Sciences Appliquées de Rouen',
    value: 'Institut National des Sciences Appliquées de Rouen',
  },
  {
    label: 'Institut National des Sciences Appliquées de Toulouse',
    value: 'Institut National des Sciences Appliquées de Toulouse',
  },
  { label: 'Institut National des Télécommunications', value: 'Institut National des Télécommunications' },
  { label: 'Institut National Polytechnique de Grenoble', value: 'Institut National Polytechnique de Grenoble' },
  { label: 'Institut National Polytechnique de Lorraine', value: 'Institut National Polytechnique de Lorraine' },
  { label: 'Institut National Polytechnique de Toulouse', value: 'Institut National Polytechnique de Toulouse' },
  {
    label: 'Institut National Supérieur de Formation Agro-Alimentaire',
    value: 'Institut National Supérieur de Formation Agro-Alimentaire',
  },
  { label: 'Institut Prima Bestari - Pine Academy ', value: 'Institut Prima Bestari - Pine Academy ' },
  { label: 'Institut Sains & Teknologi Akprind', value: 'Institut Sains & Teknologi Akprind' },
  { label: 'Institut Sains dan Teknologi Al Kamal', value: 'Institut Sains dan Teknologi Al Kamal' },
  { label: 'Institut Supérieur Agricole de Beauvais', value: 'Institut Supérieur Agricole de Beauvais' },
  { label: "Institut Supérieur d'Agriculture Lille", value: "Institut Supérieur d'Agriculture Lille" },
  { label: "Institut Supérieur d'Agriculture Rhone-Alpes", value: "Institut Supérieur d'Agriculture Rhone-Alpes" },
  {
    label: "Institut Supérieur de Commerce et d'Administration des Entreprises",
    value: "Institut Supérieur de Commerce et d'Administration des Entreprises",
  },
  { label: 'Institut Supérieur de Gestion de Tunis', value: 'Institut Supérieur de Gestion de Tunis' },
  {
    label: 'Institut Supérieur de Management et de Technologie (MATCI)',
    value: 'Institut Supérieur de Management et de Technologie (MATCI)',
  },
  { label: "Institut Supérieure d'Electronique de Paris", value: "Institut Supérieure d'Electronique de Paris" },
  { label: "Institut Supérieure d'Electronique du Nord", value: "Institut Supérieure d'Electronique du Nord" },
  { label: 'Institut Teknologi Adhi Tama Surabaya', value: 'Institut Teknologi Adhi Tama Surabaya' },
  { label: 'Institut Teknologi Bandung', value: 'Institut Teknologi Bandung' },
  { label: 'Institut Teknologi Brunei', value: 'Institut Teknologi Brunei' },
  { label: 'Institut Teknologi Sepuluh Nopember', value: 'Institut Teknologi Sepuluh Nopember' },
  { label: 'Institut Teknologi Telkom', value: 'Institut Teknologi Telkom' },
  { label: 'Institut Textile et Chimique de Lyon', value: 'Institut Textile et Chimique de Lyon' },
  { label: 'Institute for Advanced Studies Lucca', value: 'Institute for Advanced Studies Lucca' },
  {
    label: 'Institute for Command Engineers of The Ministry for Emergency Situations',
    value: 'Institute for Command Engineers of The Ministry for Emergency Situations',
  },
  { label: 'Institute for the Economy in Transition', value: 'Institute for the Economy in Transition' },
  { label: 'Institute of Accountancy Arusha', value: 'Institute of Accountancy Arusha' },
  {
    label: 'Institute of Advanced Legal Studies, University of London',
    value: 'Institute of Advanced Legal Studies, University of London',
  },
  { label: 'Institute of Architecture Ion Mincu Bucharest', value: 'Institute of Architecture Ion Mincu Bucharest' },
  { label: 'Institute of Business & Technology', value: 'Institute of Business & Technology' },
  { label: 'Institute of Business Administration (IBA)', value: 'Institute of Business Administration (IBA)' },
  { label: 'Institute of Business Administration Sukkur', value: 'Institute of Business Administration Sukkur' },
  { label: 'Institute of Business and Politics', value: 'Institute of Business and Politics' },
  { label: 'Institute of Business Management ', value: 'Institute of Business Management ' },
  {
    label: 'Institute of Classical Studies, University of London',
    value: 'Institute of Classical Studies, University of London',
  },
  { label: 'Institute of Clinical Social Work', value: 'Institute of Clinical Social Work' },
  { label: 'Institute of Commerce and Business', value: 'Institute of Commerce and Business' },
  {
    label: 'Institute of Commonwealth Studies, University of London',
    value: 'Institute of Commonwealth Studies, University of London',
  },
  { label: 'Institute of Education, University of London', value: 'Institute of Education, University of London' },
  { label: 'Institute of Finance', value: 'Institute of Finance' },
  { label: 'Institute of Finance Management', value: 'Institute of Finance Management' },
  {
    label: 'Institute of Germanic Studies, University of London',
    value: 'Institute of Germanic Studies, University of London',
  },
  {
    label: 'Institute of Industrial Electronics Engineering',
    value: 'Institute of Industrial Electronics Engineering',
  },
  { label: 'Institute of Information Technology', value: 'Institute of Information Technology' },
  {
    label: 'Institute of Latin American Studies, University of London',
    value: 'Institute of Latin American Studies, University of London',
  },
  {
    label: 'Institute of Management & Enteurpreneurship of South East Europe',
    value: 'Institute of Management & Enteurpreneurship of South East Europe',
  },
  {
    label: 'Institute of Management and Business Technology',
    value: 'Institute of Management and Business Technology',
  },
  { label: 'Institute of Management and Economy', value: 'Institute of Management and Economy' },
  { label: 'Institute of Management and Technical Studies ', value: 'Institute of Management and Technical Studies ' },
  { label: 'Institute of Management Development - Ohrid', value: 'Institute of Management Development - Ohrid' },
  { label: 'Institute of Management Sciences, Lahore (IMS)', value: 'Institute of Management Sciences, Lahore (IMS)' },
  { label: 'Institute of Management Sciences, Peshawar', value: 'Institute of Management Sciences, Peshawar' },
  { label: 'Institute of Paper Science and Technology', value: 'Institute of Paper Science and Technology' },
  { label: 'Institute of Public Administration', value: 'Institute of Public Administration' },
  { label: 'Institute of Science and Technology', value: 'Institute of Science and Technology' },
  { label: 'Institute of Social Studies', value: 'Institute of Social Studies' },
  { label: 'Institute of Space Technology', value: 'Institute of Space Technology' },
  { label: 'Institute of Teachers Education, Batu Lintang', value: 'Institute of Teachers Education, Batu Lintang' },
  { label: 'Institute of Teachers Education, Darul Aman', value: 'Institute of Teachers Education, Darul Aman' },
  {
    label: "Institute of Teachers Education, Dato' Razali Ismail ",
    value: "Institute of Teachers Education, Dato' Razali Ismail ",
  },
  { label: 'Institute of Teachers Education, Ilmu Khas', value: 'Institute of Teachers Education, Ilmu Khas' },
  { label: 'Institute of Teachers Education, Ipoh', value: 'Institute of Teachers Education, Ipoh' },
  {
    label: 'Institute of Teachers Education, Islamic Education',
    value: 'Institute of Teachers Education, Islamic Education',
  },
  { label: 'Institute of Teachers Education, Keningau', value: 'Institute of Teachers Education, Keningau' },
  { label: 'Institute of Teachers Education, Kent', value: 'Institute of Teachers Education, Kent' },
  { label: 'Institute of Teachers Education, Kota Bharu', value: 'Institute of Teachers Education, Kota Bharu' },
  {
    label: 'Institute of Teachers Education, Malay Language',
    value: 'Institute of Teachers Education, Malay Language',
  },
  { label: 'Institute of Teachers Education, Melaka ', value: 'Institute of Teachers Education, Melaka ' },
  { label: 'Institute of Teachers Education, Penang', value: 'Institute of Teachers Education, Penang' },
  { label: 'Institute of Teachers Education, Perlis', value: 'Institute of Teachers Education, Perlis' },
  { label: 'Institute of Teachers Education, Perlis', value: 'Institute of Teachers Education, Perlis' },
  { label: 'Institute of Teachers Education, Raja Melewar', value: 'Institute of Teachers Education, Raja Melewar' },
  { label: 'Institute of Teachers Education, Rajang', value: 'Institute of Teachers Education, Rajang' },
  { label: 'Institute of Teachers Education, Sarawak', value: 'Institute of Teachers Education, Sarawak' },
  {
    label: 'Institute of Teachers Education, Sultan Abdul Halim',
    value: 'Institute of Teachers Education, Sultan Abdul Halim',
  },
  { label: 'Institute of Teachers Education, Sultan Mizan', value: 'Institute of Teachers Education, Sultan Mizan' },
  { label: 'Institute of Teachers Education, Tawau', value: 'Institute of Teachers Education, Tawau' },
  {
    label: 'Institute of Teachers Education, Technical Education ',
    value: 'Institute of Teachers Education, Technical Education ',
  },
  {
    label: 'Institute of Teachers Education, Temenggong Ibrahim',
    value: 'Institute of Teachers Education, Temenggong Ibrahim',
  },
  {
    label: 'Institute of Teachers Education, Tengku Ampuan Afzan',
    value: 'Institute of Teachers Education, Tengku Ampuan Afzan',
  },
  { label: 'Institute of Teachers Education, Tuanku Bainun', value: 'Institute of Teachers Education, Tuanku Bainun' },
  {
    label: 'Institute of Teachers Education, Tun Hussein Onn',
    value: 'Institute of Teachers Education, Tun Hussein Onn',
  },
  { label: 'Institute of Technology and Management', value: 'Institute of Technology and Management' },
  { label: 'Institute Of Technology, Australia', value: 'Institute Of Technology, Australia' },
  { label: 'Institute of Textile Technology', value: 'Institute of Textile Technology' },
  { label: 'Institute of Transpersonal Psychology', value: 'Institute of Transpersonal Psychology' },
  {
    label: 'Instituto de Artes Visuais, Design e Marketing - IADE',
    value: 'Instituto de Artes Visuais, Design e Marketing - IADE',
  },
  { label: 'Instituto de Enseñanza Superior del Ejército', value: 'Instituto de Enseñanza Superior del Ejército' },
  { label: 'Instituto de Enseñanza Superior Oteima', value: 'Instituto de Enseñanza Superior Oteima' },
  { label: 'Instituto de Estudios Superiores de la Sierra', value: 'Instituto de Estudios Superiores de la Sierra' },
  { label: 'Instituto Mauá de Tecnologia', value: 'Instituto Mauá de Tecnologia' },
  {
    label: 'Instituto Nacional de Educación Física General Manuel Belgrano',
    value: 'Instituto Nacional de Educación Física General Manuel Belgrano',
  },
  {
    label: 'Instituto Nacional de Matemática Pura e Aplicada - IMPA',
    value: 'Instituto Nacional de Matemática Pura e Aplicada - IMPA',
  },
  { label: 'Instituto Piaget', value: 'Instituto Piaget' },
  { label: 'Instituto Politécnico da Guarda', value: 'Instituto Politécnico da Guarda' },
  { label: 'Instituto Politécnico de Beja', value: 'Instituto Politécnico de Beja' },
  { label: 'Instituto Politécnico de Bragança', value: 'Instituto Politécnico de Bragança' },
  { label: 'Instituto Politécnico de Castelo Branco', value: 'Instituto Politécnico de Castelo Branco' },
  { label: 'Instituto Politécnico de Coimbra', value: 'Instituto Politécnico de Coimbra' },
  { label: 'Instituto Politécnico de Leiria', value: 'Instituto Politécnico de Leiria' },
  { label: 'Instituto Politécnico de Lisboa', value: 'Instituto Politécnico de Lisboa' },
  { label: 'Instituto Politécnico de Portalegre', value: 'Instituto Politécnico de Portalegre' },
  { label: 'Instituto Politécnico de Santarém', value: 'Instituto Politécnico de Santarém' },
  { label: 'Instituto Politécnico de Setúbal', value: 'Instituto Politécnico de Setúbal' },
  { label: 'Instituto Politécnico de Tomar', value: 'Instituto Politécnico de Tomar' },
  { label: 'Instituto Politécnico de Viana do Castelo', value: 'Instituto Politécnico de Viana do Castelo' },
  { label: 'Instituto Politécnico do Cávado e do Ave', value: 'Instituto Politécnico do Cávado e do Ave' },
  { label: 'Instituto Politecnico do Porto', value: 'Instituto Politecnico do Porto' },
  { label: 'Instituto Politécnico Nacional', value: 'Instituto Politécnico Nacional' },
  {
    label: 'Instituto Politécnico Nacional, Campus Guanajuato',
    value: 'Instituto Politécnico Nacional, Campus Guanajuato',
  },
  { label: 'Instituto Superior Bissaya Barreto', value: 'Instituto Superior Bissaya Barreto' },
  { label: 'Instituto Superior D. Afonso III - INUAF', value: 'Instituto Superior D. Afonso III - INUAF' },
  {
    label: 'Instituto Superior de Assistentes e Intérpretes',
    value: 'Instituto Superior de Assistentes e Intérpretes',
  },
  {
    label: 'Instituto Superior de Ciências do Trabalho e da Empresa',
    value: 'Instituto Superior de Ciências do Trabalho e da Empresa',
  },
  { label: 'Instituto Superior de Ciências Educativas', value: 'Instituto Superior de Ciências Educativas' },
  {
    label: 'Instituto Superior de Ciências Empresariais e de Turismo',
    value: 'Instituto Superior de Ciências Empresariais e de Turismo',
  },
  {
    label: 'Instituto Superior de Ciênicas e Tecnologia de Moçambique',
    value: 'Instituto Superior de Ciênicas e Tecnologia de Moçambique',
  },
  { label: 'Instituto Superior de Entre Douro e Vouga', value: 'Instituto Superior de Entre Douro e Vouga' },
  { label: 'Instituto Superior de Gestão - ISG', value: 'Instituto Superior de Gestão - ISG' },
  { label: 'Instituto Superior de Informática e Gestão', value: 'Instituto Superior de Informática e Gestão' },
  { label: 'Instituto Superior de Línguas e Administração', value: 'Instituto Superior de Línguas e Administração' },
  { label: 'Instituto Superior de Paços de Brandão', value: 'Instituto Superior de Paços de Brandão' },
  { label: 'Instituto Superior de Psicologia Aplicada', value: 'Instituto Superior de Psicologia Aplicada' },
  {
    label: 'Instituto Superior de Relações Internacionais (ISRI) ',
    value: 'Instituto Superior de Relações Internacionais (ISRI) ',
  },
  { label: 'Instituto Superior de Saúde do Alto Ave', value: 'Instituto Superior de Saúde do Alto Ave' },
  {
    label: 'Instituto Superior de Tecnologias Avançadas - ISTEC',
    value: 'Instituto Superior de Tecnologias Avançadas - ISTEC',
  },
  {
    label: 'Instituto Superior de Transportes e Comunicações',
    value: 'Instituto Superior de Transportes e Comunicações',
  },
  {
    label: 'Instituto Superior Minero Metalúrgico Dr. Antonio Núñez Jiménez',
    value: 'Instituto Superior Minero Metalúrgico Dr. Antonio Núñez Jiménez',
  },
  { label: 'Instituto Superior Novas Profissões - INP', value: 'Instituto Superior Novas Profissões - INP' },
  { label: 'Instituto Superior Politécnico e Universitário', value: 'Instituto Superior Politécnico e Universitário' },
  { label: 'Instituto Superior Politécnico Gaya', value: 'Instituto Superior Politécnico Gaya' },
  {
    label: 'Instituto Superior Politécnico José Antonio Echeverría',
    value: 'Instituto Superior Politécnico José Antonio Echeverría',
  },
  { label: 'Instituto Tecnológico Autonómo de México', value: 'Instituto Tecnológico Autonómo de México' },
  { label: 'Instituto Tecnológico de Aeronáutica', value: 'Instituto Tecnológico de Aeronáutica' },
  { label: 'Instituto Tecnológico de Aguascalientes', value: 'Instituto Tecnológico de Aguascalientes' },
  { label: 'Instituto Tecnológico de Buenos Aires', value: 'Instituto Tecnológico de Buenos Aires' },
  { label: 'Instituto Tecnológico de Celaya', value: 'Instituto Tecnológico de Celaya' },
  { label: 'Instituto Tecnológico de Costa Rica', value: 'Instituto Tecnológico de Costa Rica' },
  { label: 'Instituto Tecnologico de Durango', value: 'Instituto Tecnologico de Durango' },
  { label: 'Instituto Tecnológico de León', value: 'Instituto Tecnológico de León' },
  { label: 'Instituto Tecnologico de Minatitlan', value: 'Instituto Tecnologico de Minatitlan' },
  { label: 'Instituto Tecnológico de Morelia', value: 'Instituto Tecnológico de Morelia' },
  { label: 'Instituto Tecnológico de Querétaro', value: 'Instituto Tecnológico de Querétaro' },
  { label: 'Instituto Tecnológico de San Luis Potosí', value: 'Instituto Tecnológico de San Luis Potosí' },
  { label: 'Instituto Tecnológico de Santo Domingo', value: 'Instituto Tecnológico de Santo Domingo' },
  { label: 'Instituto Tecnológico de Sonora', value: 'Instituto Tecnológico de Sonora' },
  { label: 'Instituto Tecnológico Metropolitano', value: 'Instituto Tecnológico Metropolitano' },
  {
    label: 'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)',
    value: 'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)',
  },
  {
    label: 'Instituto Tecnológico y de Estudios Superiores de Occidente',
    value: 'Instituto Tecnológico y de Estudios Superiores de Occidente',
  },
  { label: 'Instituto Universitario Aeronáutico', value: 'Instituto Universitario Aeronáutico' },
  { label: 'Instituto Universitario CEMA', value: 'Instituto Universitario CEMA' },
  {
    label: 'Instituto Universitario de Ciencias de la Salud Fundación H.A. Barceló',
    value: 'Instituto Universitario de Ciencias de la Salud Fundación H.A. Barceló',
  },
  {
    label: 'Instituto Universitario de Estudios Navales y Marítimos',
    value: 'Instituto Universitario de Estudios Navales y Marítimos',
  },
  { label: 'Instituto Universitario de La Paz', value: 'Instituto Universitario de La Paz' },
  { label: 'Instituto Universitario de la Policia Federal', value: 'Instituto Universitario de la Policia Federal' },
  { label: 'Instituto Universitario Nacional del Arte', value: 'Instituto Universitario Nacional del Arte' },
  { label: 'Instituts Supérieurs de Formation des Maîtres', value: 'Instituts Supérieurs de Formation des Maîtres' },
  { label: 'Instituts Supérieurs des Etudes Technologiques', value: 'Instituts Supérieurs des Etudes Technologiques' },
  { label: 'Integral University', value: 'Integral University' },
  { label: 'IntelliTec College - Grand Junction', value: 'IntelliTec College - Grand Junction' },
  { label: 'Intercollege', value: 'Intercollege' },
  {
    label: 'International Academy of  Merchandising and Design Chicago',
    value: 'International Academy of  Merchandising and Design Chicago',
  },
  {
    label: 'International Academy of  Merchandising and Design Tampa',
    value: 'International Academy of  Merchandising and Design Tampa',
  },
  { label: 'International Ataturk Alatoo University', value: 'International Ataturk Alatoo University' },
  {
    label: 'International Banking Institute, St. Petersburg',
    value: 'International Banking Institute, St. Petersburg',
  },
  { label: 'International Bible College', value: 'International Bible College' },
  { label: 'International Blacksea University', value: 'International Blacksea University' },
  { label: 'International Buddhist College', value: 'International Buddhist College' },
  { label: 'International Buddhist University', value: 'International Buddhist University' },
  { label: 'International Budo University', value: 'International Budo University' },
  { label: 'International Burch University', value: 'International Burch University' },
  { label: 'International Business School Kelajak ILMI', value: 'International Business School Kelajak ILMI' },
  { label: 'International Business School of Scandinavia', value: 'International Business School of Scandinavia' },
  { label: 'International Business University of Beijing', value: 'International Business University of Beijing' },
  { label: 'International Centre for Isclamic Science', value: 'International Centre for Isclamic Science' },
  { label: 'International Christian University', value: 'International Christian University' },
  { label: 'International College', value: 'International College' },
  { label: 'International College of the Cayman Islands', value: 'International College of the Cayman Islands' },
  {
    label: 'International College of Tourism and Hotel Management',
    value: 'International College of Tourism and Hotel Management',
  },
  { label: 'International Colleges of Islamic Science', value: 'International Colleges of Islamic Science' },
  { label: 'International Culinary Institute (ICI)', value: 'International Culinary Institute (ICI)' },
  { label: 'International Culture University ', value: 'International Culture University ' },
  { label: 'International East-European University', value: 'International East-European University' },
  { label: 'International Hellenic University', value: 'International Hellenic University' },
  {
    label: 'International Higher School for Advanced Studies Trieste',
    value: 'International Higher School for Advanced Studies Trieste',
  },
  { label: 'International Hotel Management Institute', value: 'International Hotel Management Institute' },
  {
    label: 'International Institute for Geo - Information Science and Earth Observation',
    value: 'International Institute for Geo - Information Science and Earth Observation',
  },
  { label: 'International Institute for Higher Education', value: 'International Institute for Higher Education' },
  {
    label: 'International Institute for Population Sciences',
    value: 'International Institute for Population Sciences',
  },
  { label: 'International Islamic College', value: 'International Islamic College' },
  { label: 'International Islamic College of Penang', value: 'International Islamic College of Penang' },
  { label: 'International Islamic University', value: 'International Islamic University' },
  { label: 'International Islamic University Chittagong', value: 'International Islamic University Chittagong' },
  { label: 'International Islamic University, Islamabad', value: 'International Islamic University, Islamabad' },
  { label: 'International Kazakh-Turkish University', value: 'International Kazakh-Turkish University' },
  {
    label: 'International Medical & Technological University',
    value: 'International Medical & Technological University',
  },
  { label: 'International Medical University', value: 'International Medical University' },
  { label: "International People's College", value: "International People's College" },
  {
    label: 'International Sakharov Environmental University',
    value: 'International Sakharov Environmental University',
  },
  { label: 'International School of Business Management', value: 'International School of Business Management' },
  { label: 'International School of Management', value: 'International School of Management' },
  { label: 'International School of Management in Prešov', value: 'International School of Management in Prešov' },
  {
    label: 'International School of Management ISM Dortmund',
    value: 'International School of Management ISM Dortmund',
  },
  {
    label: 'International School of New Media, University of Lübeck',
    value: 'International School of New Media, University of Lübeck',
  },
  {
    label: 'International Theravada Buddhist Missionary University',
    value: 'International Theravada Buddhist Missionary University',
  },
  { label: 'International Tourism Institute', value: 'International Tourism Institute' },
  { label: 'International Turkmen Turkish University', value: 'International Turkmen Turkish University' },
  { label: 'International University', value: 'International University' },
  { label: 'International University College', value: 'International University College' },
  {
    label: 'International University College of Nursing (IUCN)',
    value: 'International University College of Nursing (IUCN)',
  },
  {
    label: 'International University College of Technology Twintech (IUCTT)',
    value: 'International University College of Technology Twintech (IUCTT)',
  },
  { label: 'International University for Graduate Studies', value: 'International University for Graduate Studies' },
  {
    label: 'International University for Science & Technology ',
    value: 'International University for Science & Technology ',
  },
  { label: 'International University in Geneva', value: 'International University in Geneva' },
  { label: 'International University in Germany', value: 'International University in Germany' },
  {
    label: 'International University Institute of Luxembourg',
    value: 'International University Institute of Luxembourg',
  },
  { label: 'International University of Africa (IUA)', value: 'International University of Africa (IUA)' },
  {
    label: 'International University of Business Agriculture and Technology',
    value: 'International University of Business Agriculture and Technology',
  },
  {
    label: 'International University of Engineering, Moscow',
    value: 'International University of Engineering, Moscow',
  },
  {
    label: 'International University of Fundamental Studies, St. Petersburg',
    value: 'International University of Fundamental Studies, St. Petersburg',
  },
  { label: 'International University of Health and Welfare', value: 'International University of Health and Welfare' },
  { label: 'International University of Japan', value: 'International University of Japan' },
  { label: 'International University of Kagoshima', value: 'International University of Kagoshima' },
  { label: 'International University of Kyrgyzstan', value: 'International University of Kyrgyzstan' },
  { label: 'International University of Management', value: 'International University of Management' },
  { label: 'International University of Novi Pazar', value: 'International University of Novi Pazar' },
  { label: 'International University of Sarajevo', value: 'International University of Sarajevo' },
  { label: 'International University of Southern Europe', value: 'International University of Southern Europe' },
  { label: 'International University of Travnik', value: 'International University of Travnik' },
  {
    label: 'International University School of Medicine (IUSOM)',
    value: 'International University School of Medicine (IUSOM)',
  },
  { label: 'Internationale Fachhochschule Aalen', value: 'Internationale Fachhochschule Aalen' },
  { label: 'Internationale Fachhochschule Bad Honnef', value: 'Internationale Fachhochschule Bad Honnef' },
  { label: 'Internationales Hochschulinstitut Zittau', value: 'Internationales Hochschulinstitut Zittau' },
  { label: 'Internationle Akademie für Philosophie', value: 'Internationle Akademie für Philosophie' },
  { label: 'Iona College', value: 'Iona College' },
  { label: 'Ionian University Corfu', value: 'Ionian University Corfu' },
  {
    label: 'Iowa State University of Science and Technology',
    value: 'Iowa State University of Science and Technology',
  },
  { label: 'Iowa Wesleyan College', value: 'Iowa Wesleyan College' },
  { label: 'Iqra University', value: 'Iqra University' },
  { label: 'Iraq University College', value: 'Iraq University College' },
  { label: 'Irbid National University', value: 'Irbid National University' },
  {
    label: 'Irish International University (Distance Education)',
    value: 'Irish International University (Distance Education)',
  },
  { label: 'Irish University Business School, Cebu', value: 'Irish University Business School, Cebu' },
  { label: 'Irkutsk State Academy of Economics', value: 'Irkutsk State Academy of Economics' },
  { label: 'Irkutsk State Technical University', value: 'Irkutsk State Technical University' },
  { label: 'Irkutsk State University', value: 'Irkutsk State University' },
  { label: 'Isabela State University', value: 'Isabela State University' },
  { label: 'Isfahan University', value: 'Isfahan University' },
  { label: 'Isfahan University of Medical Sciences', value: 'Isfahan University of Medical Sciences' },
  { label: 'Isfahan University of Technology', value: 'Isfahan University of Technology' },
  {
    label: 'ISFORT - Institut de Formation en Technologie Alimentaire',
    value: 'ISFORT - Institut de Formation en Technologie Alimentaire',
  },
  { label: 'Ishik University', value: 'Ishik University' },
  { label: 'Ishinomaki Senshu University', value: 'Ishinomaki Senshu University' },
  { label: 'Isik University', value: 'Isik University' },
  { label: 'Islamia University of Bahawalpur', value: 'Islamia University of Bahawalpur' },
  { label: 'Islamic Azad University, Ahar', value: 'Islamic Azad University, Ahar' },
  { label: 'Islamic Azad University, Aliabad ', value: 'Islamic Azad University, Aliabad ' },
  { label: 'Islamic Azad University, Arak', value: 'Islamic Azad University, Arak' },
  { label: 'Islamic Azad University, Ardabil', value: 'Islamic Azad University, Ardabil' },
  { label: 'Islamic Azad University, Bandar Abbas', value: 'Islamic Azad University, Bandar Abbas' },
  { label: 'Islamic Azad University, Bojnourd', value: 'Islamic Azad University, Bojnourd' },
  { label: 'Islamic Azad University, Bostanabad', value: 'Islamic Azad University, Bostanabad' },
  { label: 'Islamic Azad University, Boukan', value: 'Islamic Azad University, Boukan' },
  { label: 'Islamic Azad University, Central Tehran Branch', value: 'Islamic Azad University, Central Tehran Branch' },
  { label: 'Islamic Azad University, Dehaghan', value: 'Islamic Azad University, Dehaghan' },
  { label: 'Islamic Azad University, Falavarjan ', value: 'Islamic Azad University, Falavarjan ' },
  {
    label: 'Islamic Azad University, Fars Science & Research Branch',
    value: 'Islamic Azad University, Fars Science & Research Branch',
  },
  { label: 'Islamic Azad University, Garmsar', value: 'Islamic Azad University, Garmsar' },
  { label: 'Islamic Azad University, Gorgan', value: 'Islamic Azad University, Gorgan' },
  { label: 'Islamic Azad University, Hamedan', value: 'Islamic Azad University, Hamedan' },
  { label: 'Islamic Azad University, Janah', value: 'Islamic Azad University, Janah' },
  { label: 'Islamic Azad University, Karaj', value: 'Islamic Azad University, Karaj' },
  { label: 'Islamic Azad University, Kazeron', value: 'Islamic Azad University, Kazeron' },
  { label: 'Islamic Azad University, Kerman', value: 'Islamic Azad University, Kerman' },
  { label: 'Islamic Azad University, Khorasgan', value: 'Islamic Azad University, Khorasgan' },
  { label: 'Islamic Azad University, Lahijan', value: 'Islamic Azad University, Lahijan' },
  { label: 'Islamic Azad University, Mahshar', value: 'Islamic Azad University, Mahshar' },
  { label: 'Islamic Azad University, Majlesi', value: 'Islamic Azad University, Majlesi' },
  { label: 'Islamic Azad University, Mashhad', value: 'Islamic Azad University, Mashhad' },
  { label: 'Islamic Azad University, Maybod', value: 'Islamic Azad University, Maybod' },
  { label: 'Islamic Azad University, Mehriz', value: 'Islamic Azad University, Mehriz' },
  { label: 'Islamic Azad University, Mobarakeh', value: 'Islamic Azad University, Mobarakeh' },
  { label: 'Islamic Azad University, Najaf Abad', value: 'Islamic Azad University, Najaf Abad' },
  { label: 'Islamic Azad University, North Tehran', value: 'Islamic Azad University, North Tehran' },
  { label: 'Islamic Azad University, Parand', value: 'Islamic Azad University, Parand' },
  { label: 'Islamic Azad University, Qazvin', value: 'Islamic Azad University, Qazvin' },
  { label: 'Islamic Azad University, Qom', value: 'Islamic Azad University, Qom' },
  { label: 'Islamic Azad University, Quchan', value: 'Islamic Azad University, Quchan' },
  { label: 'Islamic Azad University, Region One', value: 'Islamic Azad University, Region One' },
  { label: 'Islamic Azad University, Roodehen', value: 'Islamic Azad University, Roodehen' },
  { label: 'Islamic Azad University, Sanandaj', value: 'Islamic Azad University, Sanandaj' },
  { label: 'Islamic Azad University, Saveh', value: 'Islamic Azad University, Saveh' },
  { label: 'Islamic Azad University, Semnan', value: 'Islamic Azad University, Semnan' },
  { label: 'Islamic Azad University, Shabestar', value: 'Islamic Azad University, Shabestar' },
  { label: 'Islamic Azad University, Shahr-e-rey Branch', value: 'Islamic Azad University, Shahr-e-rey Branch' },
  { label: 'Islamic Azad University, Shahrood', value: 'Islamic Azad University, Shahrood' },
  { label: 'Islamic Azad University, Shahryar', value: 'Islamic Azad University, Shahryar' },
  { label: 'Islamic Azad University, Shiraz', value: 'Islamic Azad University, Shiraz' },
  { label: 'Islamic Azad University, Shirvan', value: 'Islamic Azad University, Shirvan' },
  { label: 'Islamic Azad University, Sowmesara', value: 'Islamic Azad University, Sowmesara' },
  { label: 'Islamic Azad University, Tabriz', value: 'Islamic Azad University, Tabriz' },
  { label: 'Islamic Azad University, Tafresh', value: 'Islamic Azad University, Tafresh' },
  { label: 'Islamic Azad University, Tehran Medical', value: 'Islamic Azad University, Tehran Medical' },
  {
    label: 'Islamic Azad University, Tehran Science & Research Branch',
    value: 'Islamic Azad University, Tehran Science & Research Branch',
  },
  { label: 'Islamic Azad University, Tehran South', value: 'Islamic Azad University, Tehran South' },
  { label: 'Islamic Azad University, Varamin', value: 'Islamic Azad University, Varamin' },
  { label: 'Islamic Azad University, Yazd', value: 'Islamic Azad University, Yazd' },
  { label: 'Islamic College for Sciences and Technologies', value: 'Islamic College for Sciences and Technologies' },
  { label: 'Islamic University College', value: 'Islamic University College' },
  { label: 'Islamic University in Uganda', value: 'Islamic University in Uganda' },
  { label: 'Islamic University Kushtia', value: 'Islamic University Kushtia' },
  { label: 'Islamic University of Gaza', value: 'Islamic University of Gaza' },
  { label: 'Islamic University of Medinah', value: 'Islamic University of Medinah' },
  { label: 'Islamic University of Rotterdam', value: 'Islamic University of Rotterdam' },
  { label: 'Islamic University of Technology', value: 'Islamic University of Technology' },
  { label: 'Isra University', value: 'Isra University' },
  { label: 'Istanbul Arel University', value: 'Istanbul Arel University' },
  { label: 'Istanbul Bilgi University', value: 'Istanbul Bilgi University' },
  { label: 'Istanbul Kultur University', value: 'Istanbul Kultur University' },
  { label: 'İstanbul Şehir University', value: 'İstanbul Şehir University' },
  { label: 'Istanbul Technical University', value: 'Istanbul Technical University' },
  { label: 'Istanbul Ticaret University', value: 'Istanbul Ticaret University' },
  { label: 'Istanbul University', value: 'Istanbul University' },
  { label: 'Istituto Universitario di Studi Superiori', value: 'Istituto Universitario di Studi Superiori' },
  { label: 'IT University of Copenhagen', value: 'IT University of Copenhagen' },
  { label: 'Ithaca College', value: 'Ithaca College' },
  { label: 'ITT Technical Institute Fort Wayne', value: 'ITT Technical Institute Fort Wayne' },
  { label: 'ITT Technical Institute Indianapolis', value: 'ITT Technical Institute Indianapolis' },
  { label: 'ITT Technical Institute Maitland', value: 'ITT Technical Institute Maitland' },
  { label: 'ITT Technical Institute Portland', value: 'ITT Technical Institute Portland' },
  { label: 'ITT Technical Institute West Covina', value: 'ITT Technical Institute West Covina' },
  { label: 'Ittihad Private University', value: 'Ittihad Private University' },
  { label: 'Ittihad University', value: 'Ittihad University' },
  {
    label: 'Ivano-Frankivsk National University of Oli and Gas',
    value: 'Ivano-Frankivsk National University of Oli and Gas',
  },
  { label: 'Ivanovo State Academy of Medicine', value: 'Ivanovo State Academy of Medicine' },
  { label: 'Ivanovo State Power University', value: 'Ivanovo State Power University' },
  { label: 'Ivanovo State Textile Academy', value: 'Ivanovo State Textile Academy' },
  { label: 'Ivanovo State University', value: 'Ivanovo State University' },
  {
    label: 'Ivanovo State University of Chemistry and Technology',
    value: 'Ivanovo State University of Chemistry and Technology',
  },
  { label: 'Iwaki Meisei University', value: 'Iwaki Meisei University' },
  { label: 'Iwate Medical University', value: 'Iwate Medical University' },
  { label: 'Iwate Prefectural University', value: 'Iwate Prefectural University' },
  { label: 'Iwate University', value: 'Iwate University' },
  { label: 'Izhevsk State Technical University', value: 'Izhevsk State Technical University' },
  { label: 'Izmir Economic University', value: 'Izmir Economic University' },
  { label: 'Izmir Institute of Technology', value: 'Izmir Institute of Technology' },
  { label: 'Jackson State University', value: 'Jackson State University' },
  { label: 'Jacksonville State University', value: 'Jacksonville State University' },
  { label: 'Jacksonville University', value: 'Jacksonville University' },
  { label: 'Jacobs University Bremen', value: 'Jacobs University Bremen' },
  { label: 'Jadara University', value: 'Jadara University' },
  { label: 'Jadavpur University', value: 'Jadavpur University' },
  { label: 'Jagannath University', value: 'Jagannath University' },
  { label: 'Jagiellonian University Cracow', value: 'Jagiellonian University Cracow' },
  { label: 'Jagran Lakecity University', value: 'Jagran Lakecity University' },
  { label: 'Jahangirnagar University', value: 'Jahangirnagar University' },
  { label: 'Jahrom University of Medical Sciences', value: 'Jahrom University of Medical Sciences' },
  { label: 'Jai Narayan Vyas University', value: 'Jai Narayan Vyas University' },
  { label: 'Jai Prakash University', value: 'Jai Prakash University' },
  { label: 'Jain University, Centre for Management Studies', value: 'Jain University, Centre for Management Studies' },
  { label: 'Jain Vishva Bharati Institute', value: 'Jain Vishva Bharati Institute' },
  { label: 'Jaipur National University', value: 'Jaipur National University' },
  { label: 'Jalalabad State University Kyrgyzstan', value: 'Jalalabad State University Kyrgyzstan' },
  { label: 'James Cook University of North Queensland', value: 'James Cook University of North Queensland' },
  { label: 'James Madison University', value: 'James Madison University' },
  { label: 'Jamestown College', value: 'Jamestown College' },
  { label: 'Jami Institute of Technology', value: 'Jami Institute of Technology' },
  { label: 'Jamia Hamdard University', value: 'Jamia Hamdard University' },
  { label: 'Jamia Millia Islamia University', value: 'Jamia Millia Islamia University' },
  { label: 'Janos Selye University', value: 'Janos Selye University' },
  { label: 'Janus Pannonius University', value: 'Janus Pannonius University' },
  {
    label: 'Japan Advanced Institute of Science and Technology',
    value: 'Japan Advanced Institute of Science and Technology',
  },
  { label: 'Japan College of Social Work', value: 'Japan College of Social Work' },
  { label: "Japan Women's University", value: "Japan Women's University" },
  { label: 'Japanese Red Cross College of Nursing', value: 'Japanese Red Cross College of Nursing' },
  {
    label: 'Jaramogi Oginga Odinga University of Science and Technology',
    value: 'Jaramogi Oginga Odinga University of Science and Technology',
  },
  { label: 'Jarvis Christian College', value: 'Jarvis Christian College' },
  { label: 'Jatiya Kabi Kazi Nazrul Islam University', value: 'Jatiya Kabi Kazi Nazrul Islam University' },
  { label: 'Jawaharlal Nehru Agricultural University', value: 'Jawaharlal Nehru Agricultural University' },
  {
    label: 'Jawaharlal Nehru Centre for Advanced  Scientific Research',
    value: 'Jawaharlal Nehru Centre for Advanced  Scientific Research',
  },
  { label: 'Jawaharlal Nehru Technological University', value: 'Jawaharlal Nehru Technological University' },
  { label: 'Jawaharlal Nehru University', value: 'Jawaharlal Nehru University' },
  { label: 'Jawzjan University', value: 'Jawzjan University' },
  { label: 'Jaypee Institute of Information Technology', value: 'Jaypee Institute of Information Technology' },
  { label: 'Jazan University', value: 'Jazan University' },
  { label: 'Jeonju University', value: 'Jeonju University' },
  { label: 'Jerash Private University', value: 'Jerash Private University' },
  { label: 'Jerusalem University College', value: 'Jerusalem University College' },
  { label: 'Jewish University in Moscow', value: 'Jewish University in Moscow' },
  { label: 'Jiangsu University of Science and Technology', value: 'Jiangsu University of Science and Technology' },
  { label: 'Jiangxi Agricultural University', value: 'Jiangxi Agricultural University' },
  { label: 'Jiangxi Normal University', value: 'Jiangxi Normal University' },
  { label: 'Jiangxi University of Finance and Economics', value: 'Jiangxi University of Finance and Economics' },
  {
    label: 'Jiangxi University of Traditional Chinese Medicine',
    value: 'Jiangxi University of Traditional Chinese Medicine',
  },
  { label: 'Jiaying University', value: 'Jiaying University' },
  { label: 'Jichi Medical School', value: 'Jichi Medical School' },
  { label: 'Jigjiga University', value: 'Jigjiga University' },
  { label: 'Jikei University School of Medicine', value: 'Jikei University School of Medicine' },
  { label: 'Jilin Agricultural University', value: 'Jilin Agricultural University' },
  { label: 'Jilin University', value: 'Jilin University' },
  { label: 'Jilin University of Technology', value: 'Jilin University of Technology' },
  { label: 'Jimei University', value: 'Jimei University' },
  { label: 'Jimma University', value: 'Jimma University' },
  { label: 'Jinan University', value: 'Jinan University' },
  { label: 'Jinan University', value: 'Jinan University' },
  { label: 'Jingdezhen China Institute', value: 'Jingdezhen China Institute' },
  { label: 'Jinnah University for Women', value: 'Jinnah University for Women' },
  { label: 'Jishou University', value: 'Jishou University' },
  { label: "Jissen Women's University", value: "Jissen Women's University" },
  { label: 'Jiwaji University', value: 'Jiwaji University' },
  { label: 'Jobu University', value: 'Jobu University' },
  { label: 'Jodhpur National University', value: 'Jodhpur National University' },
  { label: 'Joetsu University of Education', value: 'Joetsu University of Education' },
  {
    label: 'Johann Wolfgang Goethe Universität Frankfurt am Main',
    value: 'Johann Wolfgang Goethe Universität Frankfurt am Main',
  },
  { label: 'Johannes-Gutenberg Universität Mainz', value: 'Johannes-Gutenberg Universität Mainz' },
  { label: 'John Brown University', value: 'John Brown University' },
  { label: 'John Cabot University', value: 'John Cabot University' },
  { label: 'John Carroll University', value: 'John Carroll University' },
  { label: 'John F. Kennedy University', value: 'John F. Kennedy University' },
  { label: 'John Marshall Law School', value: 'John Marshall Law School' },
  { label: 'John Paul the Great Catholic University', value: 'John Paul the Great Catholic University' },
  { label: 'John Wesley College', value: 'John Wesley College' },
  { label: 'Johns Hopkins University', value: 'Johns Hopkins University' },
  { label: 'Johns Hopkins University, SAIS Bologna Center', value: 'Johns Hopkins University, SAIS Bologna Center' },
  { label: 'Johnson & Wales University', value: 'Johnson & Wales University' },
  { label: 'Johnson & Wales University, Charleston', value: 'Johnson & Wales University, Charleston' },
  { label: 'Johnson Bible College', value: 'Johnson Bible College' },
  { label: 'Johnson C. Smith University', value: 'Johnson C. Smith University' },
  { label: 'Johnson County Community College', value: 'Johnson County Community College' },
  { label: 'Johnson State College', value: 'Johnson State College' },
  { label: 'Johore Bharu Primeir Polytechnic', value: 'Johore Bharu Primeir Polytechnic' },
  {
    label: 'Jomo Kenyatta University of Agriculture and Technology',
    value: 'Jomo Kenyatta University of Agriculture and Technology',
  },
  { label: 'Jones College', value: 'Jones College' },
  { label: 'Jones International University', value: 'Jones International University' },
  { label: 'Jönköping University College', value: 'Jönköping University College' },
  {
    label: 'Jönköping University College of Health Sciences',
    value: 'Jönköping University College of Health Sciences',
  },
  {
    label: 'Jordan Academy of Music / Higher Institute of Music',
    value: 'Jordan Academy of Music / Higher Institute of Music',
  },
  { label: 'Jordan University of Science and Technology', value: 'Jordan University of Science and Technology' },
  { label: 'Josai International University', value: 'Josai International University' },
  { label: 'Josai University', value: 'Josai University' },
  { label: 'Joseph Ayo Babalola University', value: 'Joseph Ayo Babalola University' },
  {
    label: 'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala',
    value: 'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala',
  },
  {
    label: 'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala',
    value: 'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala',
  },
  { label: 'Jubail Industrial College', value: 'Jubail Industrial College' },
  { label: 'Jubail University College', value: 'Jubail University College' },
  { label: 'Judson College Elgin', value: 'Judson College Elgin' },
  { label: 'Judson College Marion', value: 'Judson College Marion' },
  { label: 'Jumeira University', value: 'Jumeira University' },
  { label: 'Juniata College', value: 'Juniata College' },
  { label: 'Juntendo University', value: 'Juntendo University' },
  { label: 'Justus Liebig Universität Gießen', value: 'Justus Liebig Universität Gießen' },
  { label: 'K.N.Toosi University of Technology', value: 'K.N.Toosi University of Technology' },
  { label: 'Kabarak University', value: 'Kabarak University' },
  { label: 'Kabardino-Balkarian State University', value: 'Kabardino-Balkarian State University' },
  { label: 'Kaboora Institute of Higher Education', value: 'Kaboora Institute of Higher Education' },
  { label: 'Kabul Education University', value: 'Kabul Education University' },
  { label: 'Kabul Health Sciences Institute', value: 'Kabul Health Sciences Institute' },
  { label: 'Kabul Medical University', value: 'Kabul Medical University' },
  { label: 'Kabul University', value: 'Kabul University' },
  { label: 'Kadir Has University', value: 'Kadir Has University' },
  { label: 'Kaduna State University', value: 'Kaduna State University' },
  { label: 'Kafkas University', value: 'Kafkas University' },
  { label: 'Kafr El-Sheikh University', value: 'Kafr El-Sheikh University' },
  { label: 'Kagawa Institute of Nutrition', value: 'Kagawa Institute of Nutrition' },
  { label: 'Kagawa Medical School', value: 'Kagawa Medical School' },
  { label: 'Kagawa University', value: 'Kagawa University' },
  { label: 'Kagoshima Immaculate Heart University', value: 'Kagoshima Immaculate Heart University' },
  { label: 'Kagoshima University', value: 'Kagoshima University' },
  { label: "Kagoshima Women's College", value: "Kagoshima Women's College" },
  { label: 'Kahramanmaras Sütcü Imam University', value: 'Kahramanmaras Sütcü Imam University' },
  { label: 'Kakatiya University', value: 'Kakatiya University' },
  { label: 'Kalamazoo College', value: 'Kalamazoo College' },
  { label: 'Kalinga University', value: 'Kalinga University' },
  { label: 'Kaliningrad State Technical University', value: 'Kaliningrad State Technical University' },
  { label: 'Kalmar University College', value: 'Kalmar University College' },
  { label: 'Kalmyk State University', value: 'Kalmyk State University' },
  { label: 'Kaluga State Pedagogical University', value: 'Kaluga State Pedagogical University' },
  { label: "Kamakura Women's College", value: "Kamakura Women's College" },
  {
    label: 'Kamalolmolk Art & Architecture Higher Education Institute of Noshahr',
    value: 'Kamalolmolk Art & Architecture Higher Education Institute of Noshahr',
  },
  { label: 'Kamchatka State University Vitus Bering', value: 'Kamchatka State University Vitus Bering' },
  { label: 'Kameshwar Singh Darbhanga Sanskrit University', value: 'Kameshwar Singh Darbhanga Sanskrit University' },
  { label: 'Kampala International University', value: 'Kampala International University' },
  { label: 'Kampala University', value: 'Kampala University' },
  {
    label: 'Kamrah International Institute of Technology (KIIT)',
    value: 'Kamrah International Institute of Technology (KIIT)',
  },
  { label: 'Kanagawa Dental College', value: 'Kanagawa Dental College' },
  { label: 'Kanagawa Institute of Technology', value: 'Kanagawa Institute of Technology' },
  { label: 'Kanagawa University', value: 'Kanagawa University' },
  { label: 'Kanazawa College of Art', value: 'Kanazawa College of Art' },
  { label: 'Kanazawa College of Economics', value: 'Kanazawa College of Economics' },
  { label: 'Kanazawa Gakuin University', value: 'Kanazawa Gakuin University' },
  { label: 'Kanazawa Institute of Technology', value: 'Kanazawa Institute of Technology' },
  { label: 'Kanazawa Medical University', value: 'Kanazawa Medical University' },
  { label: 'Kanazawa University', value: 'Kanazawa University' },
  { label: 'Kanda University of International Studies', value: 'Kanda University of International Studies' },
  { label: 'Kandahar University', value: 'Kandahar University' },
  { label: 'Kangnam University', value: 'Kangnam University' },
  { label: 'Kangnung National University', value: 'Kangnung National University' },
  { label: 'Kangwon National University', value: 'Kangwon National University' },
  { label: 'Kankakee Community College', value: 'Kankakee Community College' },
  { label: 'Kannada University', value: 'Kannada University' },
  { label: 'Kannur University', value: 'Kannur University' },
  { label: 'Kano State University of Technology Wudil', value: 'Kano State University of Technology Wudil' },
  { label: 'Kanpur University', value: 'Kanpur University' },
  { label: 'Kansai Gaidai University', value: 'Kansai Gaidai University' },
  { label: 'Kansai Medical University', value: 'Kansai Medical University' },
  { label: 'Kansai University', value: 'Kansai University' },
  { label: 'Kansai University of International Studies', value: 'Kansai University of International Studies' },
  { label: 'Kansai University of Social Welfare', value: 'Kansai University of Social Welfare' },
  { label: 'Kansas City Art Institute', value: 'Kansas City Art Institute' },
  { label: 'Kansas State University', value: 'Kansas State University' },
  { label: 'Kansas Wesleyan University', value: 'Kansas Wesleyan University' },
  { label: 'Kanto Gakuen University', value: 'Kanto Gakuen University' },
  { label: 'Kanto Gakuin University', value: 'Kanto Gakuin University' },
  { label: 'Kaohsiung Medical College', value: 'Kaohsiung Medical College' },
  { label: 'Kaohsuing Open University', value: 'Kaohsuing Open University' },
  { label: 'Kaplan University', value: 'Kaplan University' },
  { label: 'Kar University', value: 'Kar University' },
  { label: 'Karachi Institute Of Economics And Technology', value: 'Karachi Institute Of Economics And Technology' },
  { label: 'Karachi Institute of Information Technology', value: 'Karachi Institute of Information Technology' },
  { label: 'Karachi Medical and Dental College', value: 'Karachi Medical and Dental College' },
  { label: 'Karachi School of Art', value: 'Karachi School of Art' },
  { label: 'Karadeniz Technical University', value: 'Karadeniz Technical University' },
  { label: 'Karaganda State Buketov University', value: 'Karaganda State Buketov University' },
  { label: 'Karaganda State Technical University', value: 'Karaganda State Technical University' },
  { label: 'Karakoram International University', value: 'Karakoram International University' },
  { label: 'Karatina University', value: 'Karatina University' },
  { label: 'Kardan University', value: 'Kardan University' },
  { label: 'Karel De Grote Hogeschool', value: 'Karel De Grote Hogeschool' },
  { label: 'Karelian State Pedagogical University', value: 'Karelian State Pedagogical University' },
  { label: 'Karlshochschule International University', value: 'Karlshochschule International University' },
  { label: 'Karlstad University', value: 'Karlstad University' },
  { label: 'Karnatak University', value: 'Karnatak University' },
  { label: 'Karnataka State Law University', value: 'Karnataka State Law University' },
  { label: 'Karnataka State Open University', value: 'Karnataka State Open University' },
  { label: 'Károl Gáspár University of the Reformed Church', value: 'Károl Gáspár University of the Reformed Church' },
  { label: 'Karolinska Institute Stockholm', value: 'Karolinska Institute Stockholm' },
  { label: 'Karpagam University', value: 'Karpagam University' },
  {
    label: 'Karunya Institute Of Technology ( Deemed University)',
    value: 'Karunya Institute Of Technology ( Deemed University)',
  },
  { label: 'Karwan Institute of Higher Education', value: 'Karwan Institute of Higher Education' },
  { label: 'KASB Institute of Technology', value: 'KASB Institute of Technology' },
  { label: 'Kasem Bundit University', value: 'Kasem Bundit University' },
  { label: 'Kasetsart University', value: 'Kasetsart University' },
  { label: 'Kashan University', value: 'Kashan University' },
  { label: 'Kashan University of Medical Sciences', value: 'Kashan University of Medical Sciences' },
  { label: 'Kassala University', value: 'Kassala University' },
  { label: 'Kateb Institute of Higher Education', value: 'Kateb Institute of Higher Education' },
  { label: 'Katharine Gibbs School', value: 'Katharine Gibbs School' },
  { label: 'Kathmandu University', value: 'Kathmandu University' },
  { label: 'Katholieke Hogeschool Kempen', value: 'Katholieke Hogeschool Kempen' },
  { label: 'Katholieke Hogeschool Leuven', value: 'Katholieke Hogeschool Leuven' },
  { label: 'Katholieke Hogeschool Limburg', value: 'Katholieke Hogeschool Limburg' },
  { label: 'Katholieke Universiteit Brussel', value: 'Katholieke Universiteit Brussel' },
  { label: 'Katholieke Universiteit Leuven', value: 'Katholieke Universiteit Leuven' },
  {
    label: 'Katholieke Universiteit Leuven, Campus Kortrijk',
    value: 'Katholieke Universiteit Leuven, Campus Kortrijk',
  },
  { label: 'Katholisch-Theologische Privatuniversität Linz', value: 'Katholisch-Theologische Privatuniversität Linz' },
  { label: 'Katholische Fachhochschule Berlin (KFB)', value: 'Katholische Fachhochschule Berlin (KFB)' },
  {
    label: 'Katholische Fachhochschule Freiburg, Hochschule für Sozialwesen, Religionspädagogik und Pflege',
    value: 'Katholische Fachhochschule Freiburg, Hochschule für Sozialwesen, Religionspädagogik und Pflege',
  },
  { label: 'Katholische Fachhochschule Mainz', value: 'Katholische Fachhochschule Mainz' },
  { label: 'Katholische Fachhochschule Norddeutschland', value: 'Katholische Fachhochschule Norddeutschland' },
  { label: 'Katholische Fachhochschule Nordrhein-Westfalen', value: 'Katholische Fachhochschule Nordrhein-Westfalen' },
  {
    label: 'Katholische Hochschule für Soziale Arbeit Saarbrücken',
    value: 'Katholische Hochschule für Soziale Arbeit Saarbrücken',
  },
  { label: 'Katholische Stiftungsfachhochschule München', value: 'Katholische Stiftungsfachhochschule München' },
  { label: 'Katholische Universität Eichstätt', value: 'Katholische Universität Eichstätt' },
  { label: 'Katowice School of Economics', value: 'Katowice School of Economics' },
  { label: 'Katsina University', value: 'Katsina University' },
  { label: 'Kaunas Medical Academy', value: 'Kaunas Medical Academy' },
  { label: 'Kaunas University of Technology', value: 'Kaunas University of Technology' },
  { label: "Kawamura Gakuen Woman's University", value: "Kawamura Gakuen Woman's University" },
  { label: 'Kawasaki Medical School', value: 'Kawasaki Medical School' },
  { label: 'Kawasaki University of Medical Care', value: 'Kawasaki University of Medical Care' },
  { label: 'Kaya University', value: 'Kaya University' },
  { label: 'Kazak American University', value: 'Kazak American University' },
  { label: 'Kazak National Technical University', value: 'Kazak National Technical University' },
  { label: 'Kazak State University of Agriculture', value: 'Kazak State University of Agriculture' },
  { label: 'Kazakh British Technical University', value: 'Kazakh British Technical University' },
  {
    label: 'Kazakhstan Institute of Management, Economics, and Strategic Research',
    value: 'Kazakhstan Institute of Management, Economics, and Strategic Research',
  },
  {
    label: 'Kazan State Academy of Architecture and Civil Engineering',
    value: 'Kazan State Academy of Architecture and Civil Engineering',
  },
  { label: 'Kazan State Finance and Economics Institute ', value: 'Kazan State Finance and Economics Institute ' },
  { label: 'Kazan State Music Conservatory', value: 'Kazan State Music Conservatory' },
  { label: 'Kazan State Pedagogical University', value: 'Kazan State Pedagogical University' },
  { label: 'Kazan State University', value: 'Kazan State University' },
  { label: 'Kazan State University of Medicine', value: 'Kazan State University of Medicine' },
  { label: 'Kazan State University of Technology', value: 'Kazan State University of Technology' },
  { label: 'KBU International College', value: 'KBU International College' },
  { label: 'KDI School of Public Policy and Management', value: 'KDI School of Public Policy and Management' },
  { label: 'KDU College Sdn Bhd', value: 'KDU College Sdn Bhd' },
  { label: 'Kean University of New Jersey', value: 'Kean University of New Jersey' },
  {
    label: 'Kebbi State University of Science and Technology',
    value: 'Kebbi State University of Science and Technology',
  },
  {
    label: 'Keck Graduate Institute of Applied Life Sciences',
    value: 'Keck Graduate Institute of Applied Life Sciences',
  },
  { label: 'Keele University', value: 'Keele University' },
  { label: 'Keene State College', value: 'Keene State College' },
  { label: 'Keiai University', value: 'Keiai University' },
  { label: 'Keimyung University', value: 'Keimyung University' },
  { label: 'Keio University', value: 'Keio University' },
  { label: 'Keisen Jogaku-en College', value: 'Keisen Jogaku-en College' },
  { label: 'Keiwa College', value: 'Keiwa College' },
  { label: 'Keller Graduate School of Management', value: 'Keller Graduate School of Management' },
  { label: 'Kemerovo State University', value: 'Kemerovo State University' },
  { label: 'Kendall College', value: 'Kendall College' },
  { label: 'Kendall College of Art and Design', value: 'Kendall College of Art and Design' },
  { label: 'Kennesaw State University', value: 'Kennesaw State University' },
  { label: 'Kent State University', value: 'Kent State University' },
  { label: 'Kent State University - Ashtabula', value: 'Kent State University - Ashtabula' },
  { label: 'Kent State University - East Liverpool', value: 'Kent State University - East Liverpool' },
  { label: 'Kent State University - Salem', value: 'Kent State University - Salem' },
  { label: 'Kent State University - Stark', value: 'Kent State University - Stark' },
  { label: 'Kent State University - Trumbull', value: 'Kent State University - Trumbull' },
  { label: 'Kent State University - Tuscarawas', value: 'Kent State University - Tuscarawas' },
  { label: 'Kentucky Christian College', value: 'Kentucky Christian College' },
  { label: 'Kentucky State University', value: 'Kentucky State University' },
  { label: 'Kentucky Wesleyan College', value: 'Kentucky Wesleyan College' },
  { label: 'Kenya College of Accountancy', value: 'Kenya College of Accountancy' },
  { label: 'Kenya Medical Training College', value: 'Kenya Medical Training College' },
  { label: 'Kenya Methodist University', value: 'Kenya Methodist University' },
  { label: 'Kenyatta University', value: 'Kenyatta University' },
  { label: 'Kenyon College', value: 'Kenyon College' },
  { label: 'Kerala Agricultural University', value: 'Kerala Agricultural University' },
  {
    label: 'Kerala University of Fisheries and Ocean Sciences',
    value: 'Kerala University of Fisheries and Ocean Sciences',
  },
  { label: 'Kerbala University', value: 'Kerbala University' },
  { label: 'Kerman Graduate University of Technology', value: 'Kerman Graduate University of Technology' },
  { label: 'Kerman University of Medical Sciences', value: 'Kerman University of Medical Sciences' },
  { label: 'Kermanshah University of Medical Sciences', value: 'Kermanshah University of Medical Sciences' },
  { label: 'Kermanshah University of Technology', value: 'Kermanshah University of Technology' },
  { label: 'Kettering University (GMI)', value: 'Kettering University (GMI)' },
  { label: 'Keuka College', value: 'Keuka College' },
  { label: 'Khabarovsk State Academy of Economics and Law', value: 'Khabarovsk State Academy of Economics and Law' },
  { label: 'Khabarovsk State University of Technology', value: 'Khabarovsk State University of Technology' },
  { label: 'Khalifa University', value: 'Khalifa University' },
  {
    label: 'Khalifa University of Science, Technology and Research',
    value: 'Khalifa University of Science, Technology and Research',
  },
  { label: 'Khana-e-Noor Institute of Higher Education', value: 'Khana-e-Noor Institute of Higher Education' },
  { label: 'Kharkiv National University', value: 'Kharkiv National University' },
  { label: 'Kharkiv National University of Economics', value: 'Kharkiv National University of Economics' },
  {
    label: 'Kharkiv National University of Radio Electronics',
    value: 'Kharkiv National University of Radio Electronics',
  },
  { label: 'Kharkiv State Medical University', value: 'Kharkiv State Medical University' },
  {
    label: 'Kharkiv State Technical University of Construction and Architecture',
    value: 'Kharkiv State Technical University of Construction and Architecture',
  },
  { label: 'Kharkiv State Transport Technical University', value: 'Kharkiv State Transport Technical University' },
  { label: 'Khazar University', value: 'Khazar University' },
  { label: 'Kherson State University', value: 'Kherson State University' },
  { label: 'Khmelnitsky National University', value: 'Khmelnitsky National University' },
  { label: 'Khomeinishahr Payame Noor University', value: 'Khomeinishahr Payame Noor University' },
  { label: 'Khon Kaen University', value: 'Khon Kaen University' },
  { label: 'Khulna University', value: 'Khulna University' },
  {
    label: 'Khulna University of Engineering And Technology',
    value: 'Khulna University of Engineering And Technology',
  },
  { label: 'Khurasan University', value: 'Khurasan University' },
  { label: 'Kibi International University', value: 'Kibi International University' },
  {
    label: 'Kiev National Economic University Named after Vadym Hetman',
    value: 'Kiev National Economic University Named after Vadym Hetman',
  },
  {
    label: 'Kiev National University of Construction and Architecture',
    value: 'Kiev National University of Construction and Architecture',
  },
  { label: 'Kiev School Of Economics', value: 'Kiev School Of Economics' },
  { label: 'Kiev Slavonic University', value: 'Kiev Slavonic University' },
  { label: 'Kigali Health Institute', value: 'Kigali Health Institute' },
  { label: 'Kigali Independent University', value: 'Kigali Independent University' },
  { label: 'Kigali Institute of Education', value: 'Kigali Institute of Education' },
  { label: 'Kigali Institute of Science & Technology', value: 'Kigali Institute of Science & Technology' },
  { label: 'Kilimanjaro Christian Medical College', value: 'Kilimanjaro Christian Medical College' },
  { label: 'Kilis 7 Aralık University', value: 'Kilis 7 Aralık University' },
  { label: 'King Abdul Aziz University', value: 'King Abdul Aziz University' },
  {
    label: 'King Abdullah University of Science and Technology ',
    value: 'King Abdullah University of Science and Technology ',
  },
  { label: 'King College', value: 'King College' },
  {
    label: 'King Fahad University of Petroleum and Minerals',
    value: 'King Fahad University of Petroleum and Minerals',
  },
  { label: 'King Fahd Security College', value: 'King Fahd Security College' },
  { label: 'King Faisal University', value: 'King Faisal University' },
  { label: 'King Khaled University', value: 'King Khaled University' },
  {
    label: "King Mongkut's University of Technology Ladkrabang",
    value: "King Mongkut's University of Technology Ladkrabang",
  },
  {
    label: "King Mongkut's University of Technology North Bangkok",
    value: "King Mongkut's University of Technology North Bangkok",
  },
  {
    label: "King Mongkut's University of Technology Thonburi",
    value: "King Mongkut's University of Technology Thonburi",
  },
  {
    label: 'King Saud bin Abdulaziz University for Health Sciences ',
    value: 'King Saud bin Abdulaziz University for Health Sciences ',
  },
  { label: 'King Saud University', value: 'King Saud University' },
  { label: "King's College", value: "King's College" },
  { label: "King's College", value: "King's College" },
  { label: "King's College London, University of London", value: "King's College London, University of London" },
  { label: "King's University College", value: "King's University College" },
  { label: 'Kingston College', value: 'Kingston College' },
  { label: 'Kingston University', value: 'Kingston University' },
  { label: 'Kinjo Gakuin University', value: 'Kinjo Gakuin University' },
  { label: 'Kinki University', value: 'Kinki University' },
  { label: 'Kirchliche Hochschule Bethel', value: 'Kirchliche Hochschule Bethel' },
  { label: 'Kirchliche Hochschule Wuppertal', value: 'Kirchliche Hochschule Wuppertal' },
  { label: 'Kirikkale University', value: 'Kirikkale University' },
  {
    label: 'Kiriri Womens University of Science and Technology',
    value: 'Kiriri Womens University of Science and Technology',
  },
  { label: 'Kirksville College of Osteopathic Medicine', value: 'Kirksville College of Osteopathic Medicine' },
  { label: 'Kirkwood Community College', value: 'Kirkwood Community College' },
  { label: 'Kisii University', value: 'Kisii University' },
  { label: 'Kitakyushu University', value: 'Kitakyushu University' },
  { label: 'Kitami Institute of Technology', value: 'Kitami Institute of Technology' },
  { label: 'Kitasato University', value: 'Kitasato University' },
  { label: 'Klaipeda University', value: 'Klaipeda University' },
  { label: 'Knightsbridge University', value: 'Knightsbridge University' },
  { label: 'Knowledge College for Science and Technology', value: 'Knowledge College for Science and Technology' },
  { label: 'Knowledge International University', value: 'Knowledge International University' },
  { label: 'Knox College', value: 'Knox College' },
  { label: 'Knoxville College', value: 'Knoxville College' },
  { label: 'Kobe City University of ForeignStudies', value: 'Kobe City University of ForeignStudies' },
  { label: 'Kobe Design University', value: 'Kobe Design University' },
  { label: 'Kobe Gakuin University', value: 'Kobe Gakuin University' },
  { label: 'Kobe International University', value: 'Kobe International University' },
  { label: 'Kobe Jogakuin University', value: 'Kobe Jogakuin University' },
  { label: 'Kobe Pharmaceutical University', value: 'Kobe Pharmaceutical University' },
  { label: "Kobe Shinwa Women's University", value: "Kobe Shinwa Women's University" },
  { label: "Kobe Shoin Women's University", value: "Kobe Shoin Women's University" },
  { label: 'Kobe University', value: 'Kobe University' },
  { label: 'Kobe University of Mercantile Marine', value: 'Kobe University of Mercantile Marine' },
  { label: "Kobe Women's University", value: "Kobe Women's University" },
  { label: 'Koc University', value: 'Koc University' },
  { label: 'Kocaeli University', value: 'Kocaeli University' },
  { label: 'Kochi Medical School', value: 'Kochi Medical School' },
  { label: 'Kochi University', value: 'Kochi University' },
  { label: 'Kochi University of Technology', value: 'Kochi University of Technology' },
  { label: "Kochi Women's University", value: "Kochi Women's University" },
  { label: 'Kodolanyi Janos University College', value: 'Kodolanyi Janos University College' },
  { label: 'Koforidua Polytechnic ', value: 'Koforidua Polytechnic ' },
  { label: 'Kogakkan University', value: 'Kogakkan University' },
  { label: 'Kogakuin University', value: 'Kogakuin University' },
  { label: 'Kogi State University', value: 'Kogi State University' },
  {
    label: 'Kohat University of Science and Technology  (KUST)',
    value: 'Kohat University of Science and Technology  (KUST)',
  },
  { label: 'Kohinoor School of Hospitality Management', value: 'Kohinoor School of Hospitality Management' },
  { label: "Koka Women's College", value: "Koka Women's College" },
  { label: 'Kokand State Pedagogical Institute', value: 'Kokand State Pedagogical Institute' },
  { label: 'Kokugakuin University', value: 'Kokugakuin University' },
  { label: 'Kokushikan University', value: 'Kokushikan University' },
  { label: 'Kolej Universiti Insaniah', value: 'Kolej Universiti Insaniah' },
  { label: 'Komar University of Science and Technology', value: 'Komar University of Science and Technology' },
  { label: 'Komazawa University', value: 'Komazawa University' },
  { label: 'Komsomolsk-on-Amur State Technical University', value: 'Komsomolsk-on-Amur State Technical University' },
  { label: 'Konan University', value: 'Konan University' },
  { label: "Konan Women's University", value: "Konan Women's University" },
  { label: 'Kongju National University', value: 'Kongju National University' },
  { label: 'Kongju National University of Education', value: 'Kongju National University of Education' },
  { label: 'Konkan Agricultural University', value: 'Konkan Agricultural University' },
  { label: 'Konkuk University', value: 'Konkuk University' },
  { label: 'Konyang University', value: 'Konyang University' },
  { label: 'Kookmin University', value: 'Kookmin University' },
  {
    label: 'Korea Advanced Institute of Science & Technology',
    value: 'Korea Advanced Institute of Science & Technology',
  },
  { label: 'Korea Maritime University', value: 'Korea Maritime University' },
  { label: 'Korea National Open University', value: 'Korea National Open University' },
  { label: 'Korea National University of Arts', value: 'Korea National University of Arts' },
  { label: 'Korea National University of Education', value: 'Korea National University of Education' },
  {
    label: 'Korea National University of Physical Education',
    value: 'Korea National University of Physical Education',
  },
  { label: 'Korea University', value: 'Korea University' },
  { label: 'Korea University', value: 'Korea University' },
  { label: "Koriyama Women's University and College", value: "Koriyama Women's University and College" },
  { label: 'Koshien University', value: 'Koshien University' },
  { label: 'Kosin University', value: 'Kosin University' },
  { label: 'Kossuth Lajos University', value: 'Kossuth Lajos University' },
  { label: 'Kostanai State University', value: 'Kostanai State University' },
  { label: 'Kostroma State Technological University', value: 'Kostroma State Technological University' },
  { label: 'Kota Bharu Polytechnic', value: 'Kota Bharu Polytechnic' },
  { label: 'Kota Kinabalu Polytechnic', value: 'Kota Kinabalu Polytechnic' },
  { label: 'Kotelawala Defence University', value: 'Kotelawala Defence University' },
  { label: 'Koya University (Kurdistan Region)', value: 'Koya University (Kurdistan Region)' },
  { label: 'Koyasan University', value: 'Koyasan University' },
  { label: 'Krasnoyarsk State Technical University', value: 'Krasnoyarsk State Technical University' },
  { label: 'Krirk University', value: 'Krirk University' },
  { label: 'Krishna University', value: 'Krishna University' },
  { label: 'KROK Economics and Law University', value: 'KROK Economics and Law University' },
  { label: 'Kuala Lumpur Infrastructure University College', value: 'Kuala Lumpur Infrastructure University College' },
  { label: 'Kuala Lumpur Metropolitan University', value: 'Kuala Lumpur Metropolitan University' },
  { label: 'Kuala Terengganu City Polytechnic', value: 'Kuala Terengganu City Polytechnic' },
  { label: 'Kuban State University', value: 'Kuban State University' },
  { label: 'Kuban State University of Agriculture', value: 'Kuban State University of Agriculture' },
  { label: 'Kuban State University of Technology', value: 'Kuban State University of Technology' },
  { label: 'Kuching Polytechnic', value: 'Kuching Polytechnic' },
  { label: 'Kumamoto Gakuen University', value: 'Kumamoto Gakuen University' },
  { label: 'Kumamoto Institute of Technology', value: 'Kumamoto Institute of Technology' },
  { label: 'Kumamoto Prefectural University', value: 'Kumamoto Prefectural University' },
  { label: 'Kumamoto University', value: 'Kumamoto University' },
  { label: 'Kumasi Polytechnic ', value: 'Kumasi Polytechnic ' },
  { label: 'Kumaun University', value: 'Kumaun University' },
  { label: 'Kumoh National University of Technology', value: 'Kumoh National University of Technology' },
  { label: 'Kun Shan University', value: 'Kun Shan University' },
  { label: 'Kung Shan Institute of Technology', value: 'Kung Shan Institute of Technology' },
  { label: 'Kunitachi College of Music', value: 'Kunitachi College of Music' },
  { label: 'Kunmimg University of Science and Technology', value: 'Kunmimg University of Science and Technology' },
  { label: 'Kunsan National University', value: 'Kunsan National University' },
  { label: 'Kunstakademie Düsseldorf.', value: 'Kunstakademie Düsseldorf.' },
  {
    label: 'Kunsthochschule Berlin-Weissensee, Hochschule für Gestaltung ',
    value: 'Kunsthochschule Berlin-Weissensee, Hochschule für Gestaltung ',
  },
  { label: 'Kurashiki Sakuyo University', value: 'Kurashiki Sakuyo University' },
  { label: 'Kurashiki University of Science and the Arts', value: 'Kurashiki University of Science and the Arts' },
  { label: 'Kurdistan University of Medical Sciences', value: 'Kurdistan University of Medical Sciences' },
  { label: 'Kure University', value: 'Kure University' },
  { label: 'Kurgan International University', value: 'Kurgan International University' },
  { label: 'Kursk State Medical University', value: 'Kursk State Medical University' },
  { label: 'Kursk State Technical University', value: 'Kursk State Technical University' },
  { label: 'Kursk State University', value: 'Kursk State University' },
  { label: 'Kurukshetra University', value: 'Kurukshetra University' },
  { label: 'Kurume Institute of Technology', value: 'Kurume Institute of Technology' },
  { label: 'Kurume University', value: 'Kurume University' },
  { label: 'Kushiro Public University of Economics', value: 'Kushiro Public University of Economics' },
  { label: 'Kutztown University of Pennsylvania', value: 'Kutztown University of Pennsylvania' },
  { label: 'Kuvempu University', value: 'Kuvempu University' },
  { label: 'Kuwait International Law School', value: 'Kuwait International Law School' },
  { label: 'Kuwait Maastricht Business School', value: 'Kuwait Maastricht Business School' },
  { label: 'Kuwait University', value: 'Kuwait University' },
  { label: 'Kuzbass State Technical University', value: 'Kuzbass State Technical University' },
  {
    label: 'Kwame Nkrumah University of Science and Technology',
    value: 'Kwame Nkrumah University of Science and Technology',
  },
  { label: 'Kwan Dong University', value: 'Kwan Dong University' },
  { label: 'Kwangju National University of Education', value: 'Kwangju National University of Education' },
  { label: 'Kwangju University', value: 'Kwangju University' },
  { label: 'Kwangwoon University', value: 'Kwangwoon University' },
  { label: 'Kwansei Gakuin University', value: 'Kwansei Gakuin University' },
  { label: 'Kwantleen University College', value: 'Kwantleen University College' },
  { label: 'Kwara State Polytecnic', value: 'Kwara State Polytecnic' },
  { label: 'Kwara State University ', value: 'Kwara State University ' },
  { label: 'Kwararafa University', value: 'Kwararafa University' },
  { label: "Kwassui Women's College", value: "Kwassui Women's College" },
  { label: 'Kyambogo University', value: 'Kyambogo University' },
  { label: 'Kyonggi University', value: 'Kyonggi University' },
  { label: 'Kyongju University', value: 'Kyongju University' },
  { label: 'Kyorin University', value: 'Kyorin University' },
  { label: 'Kyoritsu Pharmaceutical University', value: 'Kyoritsu Pharmaceutical University' },
  { label: "Kyoritsu Woman's University", value: "Kyoritsu Woman's University" },
  { label: 'Kyoto Bunkyo University', value: 'Kyoto Bunkyo University' },
  { label: 'Kyoto City University of Arts', value: 'Kyoto City University of Arts' },
  { label: 'Kyoto Gakuen University', value: 'Kyoto Gakuen University' },
  { label: 'Kyoto Institute of Technology', value: 'Kyoto Institute of Technology' },
  { label: 'Kyoto Pharmaceutical University', value: 'Kyoto Pharmaceutical University' },
  { label: 'Kyoto Prefectural University', value: 'Kyoto Prefectural University' },
  { label: 'Kyoto Prefectural University of Medicine', value: 'Kyoto Prefectural University of Medicine' },
  { label: 'Kyoto Sangyo University', value: 'Kyoto Sangyo University' },
  { label: 'Kyoto Seika University', value: 'Kyoto Seika University' },
  { label: "Kyoto Tachibana Women's University", value: "Kyoto Tachibana Women's University" },
  { label: 'Kyoto University', value: 'Kyoto University' },
  { label: 'Kyoto University of Art and Design', value: 'Kyoto University of Art and Design' },
  { label: 'Kyoto University of Education', value: 'Kyoto University of Education' },
  { label: 'Kyoto University of Foreign Studies', value: 'Kyoto University of Foreign Studies' },
  { label: "Kyoto Women's University", value: "Kyoto Women's University" },
  { label: 'Kyrgyz National Agrarian University', value: 'Kyrgyz National Agrarian University' },
  { label: 'Kyrgyz National University', value: 'Kyrgyz National University' },
  { label: 'Kyrgyz Russian Slavic University', value: 'Kyrgyz Russian Slavic University' },
  { label: 'Kyrgyz State Technical University', value: 'Kyrgyz State Technical University' },
  {
    label: 'Kyrgyz State University of Construction, Transportation and Architecture',
    value: 'Kyrgyz State University of Construction, Transportation and Architecture',
  },
  { label: 'Kyrgyz Turkish  Manas University', value: 'Kyrgyz Turkish  Manas University' },
  { label: 'Kyunghee University', value: 'Kyunghee University' },
  { label: 'Kyungil University', value: 'Kyungil University' },
  { label: 'Kyungnam University', value: 'Kyungnam University' },
  { label: 'Kyungpook National University', value: 'Kyungpook National University' },
  { label: 'Kyungsung University', value: 'Kyungsung University' },
  { label: 'Kyungwon University', value: 'Kyungwon University' },
  { label: 'Kyushu Dental College', value: 'Kyushu Dental College' },
  { label: 'Kyushu Institute of Design', value: 'Kyushu Institute of Design' },
  { label: 'Kyushu Institute of Technology', value: 'Kyushu Institute of Technology' },
  { label: 'Kyushu International University', value: 'Kyushu International University' },
  { label: 'Kyushu Kyoritsu University', value: 'Kyushu Kyoritsu University' },
  { label: 'Kyushu Sangyo University', value: 'Kyushu Sangyo University' },
  { label: 'Kyushu Tokai University', value: 'Kyushu Tokai University' },
  { label: 'Kyushu University', value: 'Kyushu University' },
  { label: 'Kyushu University of Nursing and SocialWelfare', value: 'Kyushu University of Nursing and SocialWelfare' },
  { label: "Kyushu Women's University", value: "Kyushu Women's University" },
  { label: 'L.D.College of Engineering', value: 'L.D.College of Engineering' },
  { label: 'L.N. Gumilyov Eurasian National University', value: 'L.N. Gumilyov Eurasian National University' },
  { label: "L'Université de l'Entreprise", value: "L'Université de l'Entreprise" },
  { label: 'La Roche College', value: 'La Roche College' },
  {
    label: 'La Salle Universities - International Programmes',
    value: 'La Salle Universities - International Programmes',
  },
  { label: 'La Salle University', value: 'La Salle University' },
  { label: 'La Sierra University', value: 'La Sierra University' },
  { label: 'La Trobe University', value: 'La Trobe University' },
  { label: 'Laboratory Institute of Merchandising', value: 'Laboratory Institute of Merchandising' },
  { label: 'Ladoke Akintola University of Technology', value: 'Ladoke Akintola University of Technology' },
  { label: 'Lafayette College', value: 'Lafayette College' },
  { label: 'Lagos City Polytechnic', value: 'Lagos City Polytechnic' },
  { label: 'Lagos State Polytechnic', value: 'Lagos State Polytechnic' },
  { label: 'Lagos State University', value: 'Lagos State University' },
  { label: 'Lagrange College', value: 'Lagrange College' },
  { label: 'Lahore College for Women University', value: 'Lahore College for Women University' },
  { label: 'Lahore University of Management Sciences', value: 'Lahore University of Management Sciences' },
  { label: 'Lahti Polytechnic', value: 'Lahti Polytechnic' },
  { label: 'Laikipia University', value: 'Laikipia University' },
  { label: 'Lake Erie College', value: 'Lake Erie College' },
  { label: 'Lake Forest College', value: 'Lake Forest College' },
  { label: 'Lake Forest Graduate School of Management', value: 'Lake Forest Graduate School of Management' },
  { label: 'Lake Superior State University', value: 'Lake Superior State University' },
  { label: 'Lakehead University', value: 'Lakehead University' },
  { label: 'Lakeland College', value: 'Lakeland College' },
  { label: 'Lakeview College of Nursing', value: 'Lakeview College of Nursing' },
  {
    label: 'Lakshmibai National Institute of Physical Education',
    value: 'Lakshmibai National Institute of Physical Education',
  },
  { label: 'Lalit Narayan Mithila University', value: 'Lalit Narayan Mithila University' },
  { label: 'Lamar University', value: 'Lamar University' },
  { label: 'Lamar University - Port Arthur', value: 'Lamar University - Port Arthur' },
  { label: 'Lambuth University', value: 'Lambuth University' },
  { label: 'Lampang College of Commerce and Technology', value: 'Lampang College of Commerce and Technology' },
  { label: 'Lampang Rajabhat University', value: 'Lampang Rajabhat University' },
  { label: 'Lampang Vocational College   ', value: 'Lampang Vocational College   ' },
  { label: 'Lamphun College of Agriculture and Technology', value: 'Lamphun College of Agriculture and Technology' },
  { label: 'Lancaster Bible College', value: 'Lancaster Bible College' },
  { label: 'Lancaster University Ghana', value: 'Lancaster University Ghana' },
  { label: 'Lander University', value: 'Lander University' },
  { label: 'Landmark University', value: 'Landmark University' },
  { label: 'Lane College', value: 'Lane College' },
  { label: 'Langara College', value: 'Langara College' },
  { label: 'Langston University', value: 'Langston University' },
  { label: 'Lankaran State University', value: 'Lankaran State University' },
  { label: 'Lansbridge University', value: 'Lansbridge University' },
  { label: 'Lanzhou University', value: 'Lanzhou University' },
  { label: 'Lappeenranta University of Technology', value: 'Lappeenranta University of Technology' },
  { label: 'Lasell College', value: 'Lasell College' },
  { label: 'Latvian Academy of Arts', value: 'Latvian Academy of Arts' },
  { label: 'Latvian Academy of Culture', value: 'Latvian Academy of Culture' },
  { label: 'Latvian Academy of Music', value: 'Latvian Academy of Music' },
  { label: 'Latvian Academy of Sports Education', value: 'Latvian Academy of Sports Education' },
  { label: 'Latvian Maritime Academy', value: 'Latvian Maritime Academy' },
  { label: 'Latvian University of Agriculture', value: 'Latvian University of Agriculture' },
  { label: 'Laurentian University of Sudbury', value: 'Laurentian University of Sudbury' },
  { label: 'Laurus Technical Institute', value: 'Laurus Technical Institute' },
  { label: 'Lausanne Hotel School (EHL)', value: 'Lausanne Hotel School (EHL)' },
  { label: 'Law University of Bratislava', value: 'Law University of Bratislava' },
  { label: 'Lawrence Technological University', value: 'Lawrence Technological University' },
  { label: 'Lawrence University', value: 'Lawrence University' },
  { label: 'Lazarski School of Commerce and Law', value: 'Lazarski School of Commerce and Law' },
  { label: 'Le Moyne College', value: 'Le Moyne College' },
  { label: 'Le Moyne-Owen College', value: 'Le Moyne-Owen College' },
  { label: 'Lead City University of Ibadan', value: 'Lead City University of Ibadan' },
  { label: 'Leading University', value: 'Leading University' },
  { label: 'Lebanese American University', value: 'Lebanese American University' },
  { label: 'Lebanese International University', value: 'Lebanese International University' },
  { label: 'Lebanese University', value: 'Lebanese University' },
  { label: 'Lebanon Valley College', value: 'Lebanon Valley College' },
  { label: 'Lee University', value: 'Lee University' },
  { label: 'Leeds Metropolitan University', value: 'Leeds Metropolitan University' },
  { label: 'Lees-McRae College', value: 'Lees-McRae College' },
  { label: 'Lehigh Univervsity', value: 'Lehigh Univervsity' },
  { label: 'Leiden University', value: 'Leiden University' },
  { label: 'Lenoir-Rhyne College', value: 'Lenoir-Rhyne College' },
  {
    label: 'Leon Kozminski Academy of Entrepreneurship and Mangement',
    value: 'Leon Kozminski Academy of Entrepreneurship and Mangement',
  },
  {
    label: 'Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw',
    value: 'Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw',
  },
  {
    label: 'Les Roches Gruyère University of Applied Sciences',
    value: 'Les Roches Gruyère University of Applied Sciences',
  },
  {
    label: 'Les Roches International School of Hotel Management',
    value: 'Les Roches International School of Hotel Management',
  },
  { label: 'Lesley University', value: 'Lesley University' },
  { label: 'Letourneau University', value: 'Letourneau University' },
  { label: 'Lewis & Clark Community College', value: 'Lewis & Clark Community College' },
  { label: 'Lewis and Clark College', value: 'Lewis and Clark College' },
  { label: 'Lewis University', value: 'Lewis University' },
  { label: 'Lewis-Clark State College', value: 'Lewis-Clark State College' },
  { label: 'Leyte Normal University', value: 'Leyte Normal University' },
  { label: 'Leyte State University ', value: 'Leyte State University ' },
  { label: 'Liaocheng Teachers University', value: 'Liaocheng Teachers University' },
  { label: 'Liaoning Normal University', value: 'Liaoning Normal University' },
  { label: 'Liaoning Technical University', value: 'Liaoning Technical University' },
  { label: 'Liaoning University', value: 'Liaoning University' },
  {
    label: 'Liaquat University of Medical & Health Sciences Jamshoro',
    value: 'Liaquat University of Medical & Health Sciences Jamshoro',
  },
  {
    label: 'Libera Università degli Studi di Scienze Umane e Tecnologiche',
    value: 'Libera Università degli Studi di Scienze Umane e Tecnologiche',
  },
  { label: 'Liberty University', value: 'Liberty University' },
  { label: 'Libya Open University', value: 'Libya Open University' },
  { label: 'Libyan International Medical University (LIMU)', value: 'Libyan International Medical University (LIMU)' },
  { label: 'LICCSAL Business College', value: 'LICCSAL Business College' },
  { label: 'Liceo de Cagayan University', value: 'Liceo de Cagayan University' },
  { label: 'Liepaja Pedagogical Higher School', value: 'Liepaja Pedagogical Higher School' },
  { label: 'Life Chiropractic College West', value: 'Life Chiropractic College West' },
  { label: 'Life University', value: 'Life University' },
  { label: 'Lillehammer University College', value: 'Lillehammer University College' },
  { label: 'Limestone College', value: 'Limestone College' },
  {
    label: 'Limkokwing University College of Creative Technology',
    value: 'Limkokwing University College of Creative Technology',
  },
  { label: 'Lincoln Memorial University', value: 'Lincoln Memorial University' },
  { label: 'Lincoln University', value: 'Lincoln University' },
  { label: 'Lincoln University Missouri', value: 'Lincoln University Missouri' },
  { label: 'Lincoln University Pennsylvania', value: 'Lincoln University Pennsylvania' },
  { label: 'Lincoln University San Francisco', value: 'Lincoln University San Francisco' },
  { label: 'Lindenwood University', value: 'Lindenwood University' },
  { label: 'Lindsey Wilson College', value: 'Lindsey Wilson College' },
  { label: 'Linfield College', value: 'Linfield College' },
  { label: 'Ling Tung University', value: 'Ling Tung University' },
  { label: 'Lingnan University', value: 'Lingnan University' },
  { label: 'Linguistic University of Nizhny Novgorod', value: 'Linguistic University of Nizhny Novgorod' },
  { label: 'Link Campus University of Malta', value: 'Link Campus University of Malta' },
  { label: 'Linköping University', value: 'Linköping University' },
  { label: 'Linton University College', value: 'Linton University College' },
  { label: 'Lipeck State Technical University', value: 'Lipeck State Technical University' },
  { label: 'Lipscomb University', value: 'Lipscomb University' },
  { label: 'Liszt Ferenc Academy of Music Budapest', value: 'Liszt Ferenc Academy of Music Budapest' },
  { label: 'Lithuania Christian College', value: 'Lithuania Christian College' },
  { label: 'Lithunian Academy of Music and Theatre', value: 'Lithunian Academy of Music and Theatre' },
  { label: 'Lithunian Institute of Physical Education', value: 'Lithunian Institute of Physical Education' },
  { label: 'Lithunian University of Agriculture', value: 'Lithunian University of Agriculture' },
  { label: 'Lithunian Veterinary Academy', value: 'Lithunian Veterinary Academy' },
  { label: 'Liverpool Hope University College', value: 'Liverpool Hope University College' },
  { label: 'Liverpool John Moores University', value: 'Liverpool John Moores University' },
  { label: 'Livingstone College', value: 'Livingstone College' },
  { label: 'Lock Haven University of Pennsylvania', value: 'Lock Haven University of Pennsylvania' },
  { label: 'Logan College of Chiropractic', value: 'Logan College of Chiropractic' },
  { label: 'Logistics Engineering University of PLA', value: 'Logistics Engineering University of PLA' },
  { label: 'Loma Linda University', value: 'Loma Linda University' },
  { label: 'London Business School', value: 'London Business School' },
  { label: 'London College of Science & Technology', value: 'London College of Science & Technology' },
  { label: 'London Guildhall University', value: 'London Guildhall University' },
  { label: 'London Institute of Management and Technology', value: 'London Institute of Management and Technology' },
  { label: 'London Metropolitan University', value: 'London Metropolitan University' },
  { label: 'London School of Business & Finance', value: 'London School of Business & Finance' },
  {
    label: 'London School of Economics and Political Science, University of London',
    value: 'London School of Economics and Political Science, University of London',
  },
  {
    label: 'London School of Hygiene & Tropical Medicine, University of London',
    value: 'London School of Hygiene & Tropical Medicine, University of London',
  },
  { label: 'London School of Jewish Studies', value: 'London School of Jewish Studies' },
  { label: 'Long Island University', value: 'Long Island University' },
  { label: 'Long Island University, C.W. Post Campus', value: 'Long Island University, C.W. Post Campus' },
  { label: 'Long Island University, Southampton College', value: 'Long Island University, Southampton College' },
  { label: 'Longwood College', value: 'Longwood College' },
  { label: 'Loras College', value: 'Loras College' },
  { label: 'Lorestan University', value: 'Lorestan University' },
  { label: 'Lorestan University of Medical Sciences', value: 'Lorestan University of Medical Sciences' },
  { label: 'Los Angeles College of Chiropractic', value: 'Los Angeles College of Chiropractic' },
  { label: 'Loughborough University', value: 'Loughborough University' },
  { label: 'Louisiana Baptist University', value: 'Louisiana Baptist University' },
  { label: 'Louisiana College', value: 'Louisiana College' },
  {
    label: 'Louisiana State University and Agricultural and Mechanical College',
    value: 'Louisiana State University and Agricultural and Mechanical College',
  },
  { label: 'Louisiana State University at Alexandria', value: 'Louisiana State University at Alexandria' },
  { label: 'Louisiana State University at Eunice', value: 'Louisiana State University at Eunice' },
  {
    label: 'Louisiana State University Health Sciences Center New Orleans',
    value: 'Louisiana State University Health Sciences Center New Orleans',
  },
  { label: 'Louisiana State University in Shreveport', value: 'Louisiana State University in Shreveport' },
  { label: 'Louisiana Tech University', value: 'Louisiana Tech University' },
  { label: 'Lourdes College', value: 'Lourdes College' },
  { label: 'Loyola College in Maryland', value: 'Loyola College in Maryland' },
  { label: 'Loyola Marymount University', value: 'Loyola Marymount University' },
  { label: 'Loyola University New Orleans', value: 'Loyola University New Orleans' },
  { label: 'Loyola University of Chicago', value: 'Loyola University of Chicago' },
  { label: 'LSB College', value: 'LSB College' },
  { label: 'Lubbock Christian University', value: 'Lubbock Christian University' },
  {
    label: 'Lucerne University of Applied Sciences and Arts ',
    value: 'Lucerne University of Applied Sciences and Arts ',
  },
  { label: 'Ludong University', value: 'Ludong University' },
  { label: 'Ludwig-Maximilians-Universität München', value: 'Ludwig-Maximilians-Universität München' },
  {
    label: 'Ludwik Solski State Academy of Theatre in Cracow',
    value: 'Ludwik Solski State Academy of Theatre in Cracow',
  },
  { label: 'Luhansk State Medical University', value: 'Luhansk State Medical University' },
  {
    label: 'Luhansk Taras Shevchenko National Pedagogical University',
    value: 'Luhansk Taras Shevchenko National Pedagogical University',
  },
  { label: 'Lulea University of Technology', value: 'Lulea University of Technology' },
  { label: 'Lumbini Buddhist University', value: 'Lumbini Buddhist University' },
  { label: 'Lund University', value: 'Lund University' },
  { label: 'LungHwa University of Science and Technology', value: 'LungHwa University of Science and Technology' },
  { label: 'Lupane State University', value: 'Lupane State University' },
  { label: 'Luther College', value: 'Luther College' },
  { label: 'Luther College', value: 'Luther College' },
  { label: 'Lutheran Bible Institute of Seattle', value: 'Lutheran Bible Institute of Seattle' },
  { label: 'Lutheran School Of Theology In Aarhus', value: 'Lutheran School Of Theology In Aarhus' },
  { label: 'Lutherische Theologische Hochschule Oberursel', value: 'Lutherische Theologische Hochschule Oberursel' },
  { label: 'Luxun Academy of Fine Art', value: 'Luxun Academy of Fine Art' },
  { label: 'Luzhou Medical College', value: 'Luzhou Medical College' },
  { label: 'Lviv Medical University', value: 'Lviv Medical University' },
  { label: 'Lviv National Medical Univeristy', value: 'Lviv National Medical Univeristy' },
  { label: 'Lviv National University Ivan Franko', value: 'Lviv National University Ivan Franko' },
  { label: 'Lviv Polytechnic National University', value: 'Lviv Polytechnic National University' },
  { label: 'Lyceum of the Philippines University', value: 'Lyceum of the Philippines University' },
  { label: 'Lycoming College', value: 'Lycoming College' },
  { label: 'Lynchburg College', value: 'Lynchburg College' },
  { label: 'Lyndon State College', value: 'Lyndon State College' },
  { label: 'Lynn University', value: 'Lynn University' },
  { label: 'Lyon College', value: 'Lyon College' },
  { label: 'Maakhir University', value: 'Maakhir University' },
  { label: 'Maasai Mara University', value: 'Maasai Mara University' },
  { label: 'Macalester College', value: 'Macalester College' },
  { label: 'Macau Polytechnic Institute', value: 'Macau Polytechnic Institute' },
  { label: 'MacEwan University', value: 'MacEwan University' },
  { label: 'MacMurray College', value: 'MacMurray College' },
  { label: 'Macon State College', value: 'Macon State College' },
  { label: 'Macquarie University', value: 'Macquarie University' },
  { label: 'Madawalabu University', value: 'Madawalabu University' },
  { label: 'Madenat Alelem University College', value: 'Madenat Alelem University College' },
  { label: 'Madhya Pradesh Bhoj (Open) University', value: 'Madhya Pradesh Bhoj (Open) University' },
  { label: 'Madison University (Distance Education)', value: 'Madison University (Distance Education)' },
  { label: 'Madonna University', value: 'Madonna University' },
  { label: 'Madonna University', value: 'Madonna University' },
  { label: 'Madurai Kamaraj University', value: 'Madurai Kamaraj University' },
  { label: 'Mae Fah Luang University ', value: 'Mae Fah Luang University ' },
  { label: 'Maejo University', value: 'Maejo University' },
  { label: 'Magadh University', value: 'Magadh University' },
  { label: 'Magnitogorsk State University', value: 'Magnitogorsk State University' },
  { label: 'Mahachulalongkorn Buddhist University', value: 'Mahachulalongkorn Buddhist University' },
  { label: 'Mahamakut Buddhist University', value: 'Mahamakut Buddhist University' },
  { label: 'Mahanakorn University of Technology', value: 'Mahanakorn University of Technology' },
  { label: 'Maharaja Ganga Singh University, Bikaner', value: 'Maharaja Ganga Singh University, Bikaner' },
  { label: 'Maharaja Sayajirao University of Baroda', value: 'Maharaja Sayajirao University of Baroda' },
  {
    label: 'Maharishi Dayanand University, Rohtak (Haryana )',
    value: 'Maharishi Dayanand University, Rohtak (Haryana )',
  },
  { label: 'Maharishi University of Management', value: 'Maharishi University of Management' },
  { label: 'Maharshi Dayanand Sarswati University Ajmer', value: 'Maharshi Dayanand Sarswati University Ajmer' },
  { label: 'Mahasarakham University', value: 'Mahasarakham University' },
  { label: 'Mahatma Gandhi Chitrakut Gramoday University', value: 'Mahatma Gandhi Chitrakut Gramoday University' },
  { label: 'Mahatma Gandhi Kashi Vidyapith ', value: 'Mahatma Gandhi Kashi Vidyapith ' },
  { label: 'Mahatma Gandhi University Meghalaya ', value: 'Mahatma Gandhi University Meghalaya ' },
  { label: 'Mahatma Gandhi University, Kerala', value: 'Mahatma Gandhi University, Kerala' },
  {
    label: 'Mahatma Jyotiba Phule Rohilkhand University Bareilly ',
    value: 'Mahatma Jyotiba Phule Rohilkhand University Bareilly ',
  },
  { label: 'Mahatma Phule Agricultural University', value: 'Mahatma Phule Agricultural University' },
  { label: 'Mahidol University', value: 'Mahidol University' },
  { label: 'Mahidol University International College', value: 'Mahidol University International College' },
  {
    label: 'Mahsa University College for Health and Medical Science',
    value: 'Mahsa University College for Health and Medical Science',
  },
  { label: 'Maine College of Art', value: 'Maine College of Art' },
  { label: 'Maine Maritime Academy', value: 'Maine Maritime Academy' },
  { label: 'Majan University College', value: 'Majan University College' },
  { label: 'Majmaah University', value: 'Majmaah University' },
  { label: 'Makanlal Chutur Vedi University', value: 'Makanlal Chutur Vedi University' },
  { label: 'Makerere University', value: 'Makerere University' },
  {
    label: 'Makhanlal Chaturvedi National University of Journalism and Communication',
    value: 'Makhanlal Chaturvedi National University of Journalism and Communication',
  },
  { label: 'Mälardalen University', value: 'Mälardalen University' },
  { label: 'Malaspina University College', value: 'Malaspina University College' },
  { label: 'Malayer University', value: 'Malayer University' },
  {
    label: 'Malaysia University of Science and Technology (MUST)',
    value: 'Malaysia University of Science and Technology (MUST)',
  },
  { label: 'Maldives National University', value: 'Maldives National University' },
  { label: 'Malekan Payame Nour University', value: 'Malekan Payame Nour University' },
  { label: 'Malmö University College', value: 'Malmö University College' },
  { label: 'Malone College', value: 'Malone College' },
  { label: 'Maltepe University', value: 'Maltepe University' },
  { label: 'Mamasani University of Technology', value: 'Mamasani University of Technology' },
  {
    label: 'Mamoun Private University for Science and Technology',
    value: 'Mamoun Private University for Science and Technology',
  },
  { label: 'Management and Science University', value: 'Management and Science University' },
  {
    label: 'Management Development Institute of Singapore, Tashkent',
    value: 'Management Development Institute of Singapore, Tashkent',
  },
  { label: 'Manarat International University', value: 'Manarat International University' },
  { label: 'Manchester College', value: 'Manchester College' },
  { label: 'Mandalay Technological University', value: 'Mandalay Technological University' },
  { label: 'Mandhu College', value: 'Mandhu College' },
  { label: 'Mangalore University', value: 'Mangalore University' },
  { label: 'Manhattan Christian College', value: 'Manhattan Christian College' },
  { label: 'Manhattan College', value: 'Manhattan College' },
  { label: 'Manhattan School of Music', value: 'Manhattan School of Music' },
  { label: 'Manhattanville College', value: 'Manhattanville College' },
  { label: 'Manila Central University', value: 'Manila Central University' },
  { label: 'Manipal University', value: 'Manipal University' },
  { label: 'Manipur University', value: 'Manipur University' },
  { label: 'Mankato State University', value: 'Mankato State University' },
  { label: 'Manonmaniam Sundaranar University', value: 'Manonmaniam Sundaranar University' },
  { label: 'Mansfield University of Pennsylvania', value: 'Mansfield University of Pennsylvania' },
  { label: 'Mansoura University', value: 'Mansoura University' },
  { label: 'Manuel L. Quezon University', value: 'Manuel L. Quezon University' },
  { label: 'Manuel S. Enverga University Foundation', value: 'Manuel S. Enverga University Foundation' },
  { label: 'Mar Athanasios College for Advanced Studies', value: 'Mar Athanasios College for Advanced Studies' },
  { label: 'Mara Poly-Tech College', value: 'Mara Poly-Tech College' },
  { label: 'Maranatha Baptist Bible College', value: 'Maranatha Baptist Bible College' },
  { label: 'Marathwada Agricultural University', value: 'Marathwada Agricultural University' },
  { label: 'Marcus Oldham College', value: 'Marcus Oldham College' },
  { label: 'Maria Curie-Sklodowska University Lublin', value: 'Maria Curie-Sklodowska University Lublin' },
  {
    label: 'Maria Grzegorzewska College for Special Education',
    value: 'Maria Grzegorzewska College for Special Education',
  },
  { label: 'Marian College', value: 'Marian College' },
  { label: 'Marian College of Fond Du Lac', value: 'Marian College of Fond Du Lac' },
  { label: 'Mariano Marcos State University', value: 'Mariano Marcos State University' },
  { label: 'Marietta College', value: 'Marietta College' },
  { label: 'Marij State Technical University', value: 'Marij State Technical University' },
  { label: 'Marij State University', value: 'Marij State University' },
  { label: 'Marist College', value: 'Marist College' },
  { label: 'Maritime University Constanta', value: 'Maritime University Constanta' },
  { label: 'Maritime University in Szczecin', value: 'Maritime University in Szczecin' },
  { label: 'Märkische Fachhochschule Iserlohn', value: 'Märkische Fachhochschule Iserlohn' },
  { label: 'Marlboro College', value: 'Marlboro College' },
  { label: 'Marmara University', value: 'Marmara University' },
  { label: 'Marquette University', value: 'Marquette University' },
  { label: 'Mars Hill College', value: 'Mars Hill College' },
  { label: 'Marshall University', value: 'Marshall University' },
  { label: 'Martin Luther College', value: 'Martin Luther College' },
  { label: 'Martin Methodist College', value: 'Martin Methodist College' },
  { label: 'Martin University', value: 'Martin University' },
  { label: 'Martin-Luther Universität Halle-Wittenberg', value: 'Martin-Luther Universität Halle-Wittenberg' },
  { label: 'Mary Baldwin College', value: 'Mary Baldwin College' },
  { label: 'Mary Washington College', value: 'Mary Washington College' },
  { label: 'Maryam Institute of Higher Education', value: 'Maryam Institute of Higher Education' },
  { label: 'Marycrest International University', value: 'Marycrest International University' },
  { label: 'Marygrove College', value: 'Marygrove College' },
  { label: 'Marylhurst University', value: 'Marylhurst University' },
  { label: 'Marymount College New York', value: 'Marymount College New York' },
  { label: 'Marymount Manhattan College', value: 'Marymount Manhattan College' },
  { label: 'Marymount University', value: 'Marymount University' },
  { label: 'Maryville College', value: 'Maryville College' },
  { label: 'Maryville University of St. Louis', value: 'Maryville University of St. Louis' },
  { label: 'Marywood University', value: 'Marywood University' },
  { label: 'Masaryk University', value: 'Masaryk University' },
  { label: 'Masdar University Of Science And Technology', value: 'Masdar University Of Science And Technology' },
  { label: 'Maseno University', value: 'Maseno University' },
  { label: 'Mashhad University of Medical Sciences', value: 'Mashhad University of Medical Sciences' },
  {
    label: 'Masinde Muliro University of Science and Technology',
    value: 'Masinde Muliro University of Science and Technology',
  },
  { label: 'Massachusetts College of Art', value: 'Massachusetts College of Art' },
  { label: 'Massachusetts College of Liberal Arts', value: 'Massachusetts College of Liberal Arts' },
  {
    label: 'Massachusetts College of Pharmacy and Allied Health Sciences',
    value: 'Massachusetts College of Pharmacy and Allied Health Sciences',
  },
  { label: 'Massachusetts Institute of Technology', value: 'Massachusetts Institute of Technology' },
  { label: 'Massachusetts Maritime Academy', value: 'Massachusetts Maritime Academy' },
  {
    label: 'Massachusetts School of Professional Psychology',
    value: 'Massachusetts School of Professional Psychology',
  },
  { label: 'Massey University', value: 'Massey University' },
  { label: 'Matej Bel University in Banská Bystrica', value: 'Matej Bel University in Banská Bystrica' },
  { label: 'Matn University', value: 'Matn University' },
  { label: 'Matsumoto Dental University', value: 'Matsumoto Dental University' },
  { label: 'Matsusaka University', value: 'Matsusaka University' },
  { label: 'Matsuyama University', value: 'Matsuyama University' },
  {
    label: 'Mawlana Bhashani Science And Technology University',
    value: 'Mawlana Bhashani Science And Technology University',
  },
  { label: 'Maxim Gorky Institute of Literature', value: 'Maxim Gorky Institute of Literature' },
  { label: 'Mayo Graduate School', value: 'Mayo Graduate School' },
  { label: 'Mayo Medical School', value: 'Mayo Medical School' },
  { label: 'Mayville State University', value: 'Mayville State University' },
  { label: 'Mazandaran University of Iran', value: 'Mazandaran University of Iran' },
  { label: 'Mazandaran University of Medical Science', value: 'Mazandaran University of Medical Science' },
  { label: 'Mazandaran University of Science & Technology', value: 'Mazandaran University of Science & Technology' },
  { label: 'Mbarara University of Science and Technology', value: 'Mbarara University of Science and Technology' },
  { label: 'McGill University', value: 'McGill University' },
  { label: 'MCI-Management Center Innsbruck', value: 'MCI-Management Center Innsbruck' },
  { label: 'McKendree College', value: 'McKendree College' },
  { label: 'McMaster University', value: 'McMaster University' },
  { label: 'McMurry University', value: 'McMurry University' },
  { label: 'McNeese State University', value: 'McNeese State University' },
  { label: 'McPherson College', value: 'McPherson College' },
  { label: 'Medaille College', value: 'Medaille College' },
  { label: 'Medcenter One College of Nursing', value: 'Medcenter One College of Nursing' },
  { label: 'Medical Academy in Bialystok', value: 'Medical Academy in Bialystok' },
  { label: 'Medical Academy in Gdansk', value: 'Medical Academy in Gdansk' },
  { label: 'Medical Academy in Lodz', value: 'Medical Academy in Lodz' },
  { label: 'Medical Academy in Lublin', value: 'Medical Academy in Lublin' },
  { label: 'Medical Academy in Warszawa', value: 'Medical Academy in Warszawa' },
  { label: 'Medical Academy in Wroclaw', value: 'Medical Academy in Wroclaw' },
  { label: 'Medical Academy Karol Marcinkowski in Poznan', value: 'Medical Academy Karol Marcinkowski in Poznan' },
  { label: 'Medical Academy Ludwik Rydygier in Bydgoszcz', value: 'Medical Academy Ludwik Rydygier in Bydgoszcz' },
  { label: 'Medical Academy of Latvia', value: 'Medical Academy of Latvia' },
  { label: 'Medical College of Georgia', value: 'Medical College of Georgia' },
  { label: 'Medical College of Ohio', value: 'Medical College of Ohio' },
  {
    label: 'Medical College of Pennsylvania and Hahnemann University',
    value: 'Medical College of Pennsylvania and Hahnemann University',
  },
  { label: 'Medical College of Wisconsin', value: 'Medical College of Wisconsin' },
  { label: 'Medical Faculty, Osh State University', value: 'Medical Faculty, Osh State University' },
  { label: 'Medical University of Sofia', value: 'Medical University of Sofia' },
  { label: 'Medical University of South Carolina', value: 'Medical University of South Carolina' },
  { label: 'Medical University Pleven', value: 'Medical University Pleven' },
  { label: 'Medical University Plovdiv', value: 'Medical University Plovdiv' },
  { label: 'Medical University Varna', value: 'Medical University Varna' },
  { label: 'Medizinische Hochschule Hannover', value: 'Medizinische Hochschule Hannover' },
  { label: 'Medizinische Universität Graz', value: 'Medizinische Universität Graz' },
  { label: 'Medizinische Universität Innsbruck', value: 'Medizinische Universität Innsbruck' },
  { label: 'Medizinische Universität Lübeck', value: 'Medizinische Universität Lübeck' },
  { label: 'Medizinische Universität Wien', value: 'Medizinische Universität Wien' },
  { label: 'Megatrend University of Applied Sciences', value: 'Megatrend University of Applied Sciences' },
  { label: 'Meharry Medical College', value: 'Meharry Medical College' },
  {
    label: 'Mehrabyan Medical Institute and Medical College',
    value: 'Mehrabyan Medical Institute and Medical College',
  },
  { label: 'Mehran University of Engineering & Technology', value: 'Mehran University of Engineering & Technology' },
  { label: 'Meiji College of Pharmacy', value: 'Meiji College of Pharmacy' },
  { label: 'Meiji Gakuin University', value: 'Meiji Gakuin University' },
  { label: 'Meiji University', value: 'Meiji University' },
  { label: 'Meiji University of Oriental Medicine', value: 'Meiji University of Oriental Medicine' },
  { label: 'Meijo University', value: 'Meijo University' },
  { label: 'Meikai University', value: 'Meikai University' },
  { label: 'Meio University', value: 'Meio University' },
  { label: 'Meisei University', value: 'Meisei University' },
  { label: 'Mejiro University', value: 'Mejiro University' },
  { label: 'Mekelle Institute of Technology', value: 'Mekelle Institute of Technology' },
  { label: 'Mekelle University', value: 'Mekelle University' },
  { label: 'Melaka City Polytechnic', value: 'Melaka City Polytechnic' },
  { label: 'Melaka Islamic University College', value: 'Melaka Islamic University College' },
  { label: 'Memorial University of Newfoundland', value: 'Memorial University of Newfoundland' },
  { label: 'Memphis College of Art', value: 'Memphis College of Art' },
  { label: 'Mendel University of Agriculture and Forestry', value: 'Mendel University of Agriculture and Forestry' },
  {
    label: 'Mendeleev Russian University of Chemical Technology',
    value: 'Mendeleev Russian University of Chemical Technology',
  },
  { label: 'Menlo College', value: 'Menlo College' },
  { label: 'Mennonite College of Nursing', value: 'Mennonite College of Nursing' },
  { label: 'Menoufia University', value: 'Menoufia University' },
  { label: 'Mercer University', value: 'Mercer University' },
  { label: 'Mercer University, Cecil B. Day Campus', value: 'Mercer University, Cecil B. Day Campus' },
  { label: 'Mercy College', value: 'Mercy College' },
  { label: 'Mercyhurst College', value: 'Mercyhurst College' },
  { label: 'Meredith College', value: 'Meredith College' },
  { label: 'Merkur Internationale FH Karlsruhe', value: 'Merkur Internationale FH Karlsruhe' },
  { label: 'Merlimau Polytechnic', value: 'Merlimau Polytechnic' },
  { label: 'Merrimack College', value: 'Merrimack College' },
  { label: 'Mersin University', value: 'Mersin University' },
  { label: 'Meru University of Science and Technology', value: 'Meru University of Science and Technology' },
  {
    label: 'Merz Akademie, Hochschule für Gestaltung Stuttgart',
    value: 'Merz Akademie, Hochschule für Gestaltung Stuttgart',
  },
  { label: 'Mesa State College', value: 'Mesa State College' },
  { label: 'Messiah College', value: 'Messiah College' },
  { label: 'Methodist College', value: 'Methodist College' },
  { label: 'Metropolitan State College of Denver', value: 'Metropolitan State College of Denver' },
  { label: 'Metropolitan State University', value: 'Metropolitan State University' },
  { label: 'Metropolitan University', value: 'Metropolitan University' },
  { label: 'Metropolitan University', value: 'Metropolitan University' },
  { label: 'MGH Institute of Health Professions', value: 'MGH Institute of Health Professions' },
  { label: 'Miami Dade College', value: 'Miami Dade College' },
  { label: 'Miami University of Ohio', value: 'Miami University of Ohio' },
  { label: 'Miami University of Ohio - Hamilton', value: 'Miami University of Ohio - Hamilton' },
  { label: 'Miami University of Ohio - Middletown', value: 'Miami University of Ohio - Middletown' },
  { label: 'Michael Okpara University of Agriculture', value: 'Michael Okpara University of Agriculture' },
  { label: 'Michigan School of Professional Psychology', value: 'Michigan School of Professional Psychology' },
  { label: 'Michigan State University', value: 'Michigan State University' },
  { label: 'Michigan Technological University', value: 'Michigan Technological University' },
  { label: 'Mid-America Nazarene University', value: 'Mid-America Nazarene University' },
  { label: 'Mid-American Bible College', value: 'Mid-American Bible College' },
  { label: 'Mid-Continent Baptist Bible College', value: 'Mid-Continent Baptist Bible College' },
  { label: 'Mid-Sweden University', value: 'Mid-Sweden University' },
  { label: 'Middle East Technical University', value: 'Middle East Technical University' },
  { label: 'Middle East University', value: 'Middle East University' },
  { label: 'Middle Tennessee State University', value: 'Middle Tennessee State University' },
  { label: 'Middlebury College', value: 'Middlebury College' },
  { label: 'Middlesex University', value: 'Middlesex University' },
  { label: 'Midland Lutheran College', value: 'Midland Lutheran College' },
  { label: 'Midlands State University', value: 'Midlands State University' },
  { label: 'Midway College', value: 'Midway College' },
  { label: 'Midwestern State University', value: 'Midwestern State University' },
  { label: 'Midwestern University', value: 'Midwestern University' },
  { label: 'Mie University', value: 'Mie University' },
  { label: 'Miles College', value: 'Miles College' },
  { label: 'Military academy of Lithuania', value: 'Military academy of Lithuania' },
  { label: 'Military Institute of Science and Technology', value: 'Military Institute of Science and Technology' },
  { label: 'Military Technical College', value: 'Military Technical College' },
  { label: 'Military University Shoumen', value: 'Military University Shoumen' },
  { label: 'Millennia Atlantic University', value: 'Millennia Atlantic University' },
  { label: 'Millersville University of Pennsylvania', value: 'Millersville University of Pennsylvania' },
  { label: 'Milligan College', value: 'Milligan College' },
  { label: 'Millikin University', value: 'Millikin University' },
  { label: 'Mills College', value: 'Mills College' },
  { label: 'Mills Grae University', value: 'Mills Grae University' },
  { label: 'Millsaps College', value: 'Millsaps College' },
  { label: 'Milwaukee Institute of Art and Design', value: 'Milwaukee Institute of Art and Design' },
  { label: 'Milwaukee School of Engineering', value: 'Milwaukee School of Engineering' },
  { label: 'Mimar Sinan University', value: 'Mimar Sinan University' },
  { label: "Mimasaka Women's College", value: "Mimasaka Women's College" },
  { label: 'Minamikyushu University', value: 'Minamikyushu University' },
  { label: 'Mindanao State University', value: 'Mindanao State University' },
  {
    label: 'Mindanao State University - Iligan Institute of Technology',
    value: 'Mindanao State University - Iligan Institute of Technology',
  },
  { label: 'Ming Chuan University', value: 'Ming Chuan University' },
  { label: 'Minhaj University Lahore', value: 'Minhaj University Lahore' },
  { label: 'Minia University', value: 'Minia University' },
  { label: 'Minneapolis College of Art and Design', value: 'Minneapolis College of Art and Design' },
  { label: 'Minnesota Bible College', value: 'Minnesota Bible College' },
  { label: 'Minnesota School of Professional Psychology', value: 'Minnesota School of Professional Psychology' },
  { label: 'Minot State University', value: 'Minot State University' },
  { label: 'Minsk Institute of Management', value: 'Minsk Institute of Management' },
  { label: 'Minsk State Linguistic University', value: 'Minsk State Linguistic University' },
  { label: 'Miryang National University', value: 'Miryang National University' },
  { label: 'Misamis University', value: 'Misamis University' },
  { label: 'Misan University', value: 'Misan University' },
  { label: 'Misr International University', value: 'Misr International University' },
  { label: 'Misr University for Sience and Technology', value: 'Misr University for Sience and Technology' },
  { label: 'Mississippi College', value: 'Mississippi College' },
  { label: 'Mississippi State University', value: 'Mississippi State University' },
  { label: 'Mississippi University for Women', value: 'Mississippi University for Women' },
  { label: 'Mississippi Valley State University', value: 'Mississippi Valley State University' },
  { label: 'Missouri Baptist College', value: 'Missouri Baptist College' },
  { label: 'Missouri Southern State College', value: 'Missouri Southern State College' },
  { label: 'Missouri Tech', value: 'Missouri Tech' },
  { label: 'Missouri University of Science and Technology', value: 'Missouri University of Science and Technology' },
  { label: 'Missouri Valley College', value: 'Missouri Valley College' },
  { label: 'Missouri Western State College', value: 'Missouri Western State College' },
  { label: "Miyagi Gakuin Women's College", value: "Miyagi Gakuin Women's College" },
  { label: 'Miyagi University', value: 'Miyagi University' },
  { label: 'Miyagi University of Education', value: 'Miyagi University of Education' },
  { label: 'Miyazaki Medical College', value: 'Miyazaki Medical College' },
  { label: 'Miyazaki Municipal University', value: 'Miyazaki Municipal University' },
  { label: 'Miyazaki Prefectural Nursing University', value: 'Miyazaki Prefectural Nursing University' },
  { label: 'Miyazaki University', value: 'Miyazaki University' },
  { label: 'Mizan Tepi University', value: 'Mizan Tepi University' },
  { label: 'Modern Acadmy', value: 'Modern Acadmy' },
  { label: 'Modern Institute of Managament', value: 'Modern Institute of Managament' },
  { label: 'Modern Sciences & Arts University', value: 'Modern Sciences & Arts University' },
  {
    label: 'Modern University For Technology and Information',
    value: 'Modern University For Technology and Information',
  },
  { label: 'Modern University for the Humanities', value: 'Modern University for the Humanities' },
  { label: 'Mofid University', value: 'Mofid University' },
  { label: 'Mogadishu University', value: 'Mogadishu University' },
  { label: 'Mogilev State University A.A. Kuleshov', value: 'Mogilev State University A.A. Kuleshov' },
  { label: 'Mohammad Ali Jinnah University', value: 'Mohammad Ali Jinnah University' },
  { label: 'Mohammad Ali Jinnah University, Karachi', value: 'Mohammad Ali Jinnah University, Karachi' },
  { label: 'Mohan Lal Sukhadia University', value: 'Mohan Lal Sukhadia University' },
  { label: 'Mohi-ud-Din Islamic University', value: 'Mohi-ud-Din Islamic University' },
  { label: 'Moi University', value: 'Moi University' },
  { label: 'Mokpo National Maritime University', value: 'Mokpo National Maritime University' },
  { label: 'Mokpo National University', value: 'Mokpo National University' },
  { label: 'Mokwon University Taejon', value: 'Mokwon University Taejon' },
  { label: 'Molde University College', value: 'Molde University College' },
  { label: 'Moldova Cooperative Trade University', value: 'Moldova Cooperative Trade University' },
  { label: 'Moldova State Agricultural University', value: 'Moldova State Agricultural University' },
  { label: 'Moldova State University', value: 'Moldova State University' },
  {
    label: 'Moldova State University of Medicine and Pharmacy N. Testemitsanu',
    value: 'Moldova State University of Medicine and Pharmacy N. Testemitsanu',
  },
  { label: 'Moldova Technical University', value: 'Moldova Technical University' },
  { label: 'Molloy College', value: 'Molloy College' },
  { label: 'Monash University', value: 'Monash University' },
  { label: 'Monash University, Malaysia Campus', value: 'Monash University, Malaysia Campus' },
  { label: 'Mondragon Univertsitatea', value: 'Mondragon Univertsitatea' },
  { label: 'Mongolian National University', value: 'Mongolian National University' },
  { label: 'Mongolian State University of Agriculture', value: 'Mongolian State University of Agriculture' },
  { label: 'Mongolian State University of Arts and Culture', value: 'Mongolian State University of Arts and Culture' },
  { label: 'Mongolian State University of Education', value: 'Mongolian State University of Education' },
  { label: 'Mongolian University of Science and Technology', value: 'Mongolian University of Science and Technology' },
  { label: 'Monmouth University', value: 'Monmouth University' },
  { label: 'Montana State University', value: 'Montana State University' },
  { label: 'Montana State University - Billings', value: 'Montana State University - Billings' },
  { label: 'Montana State University - Northern', value: 'Montana State University - Northern' },
  { label: 'Montana Tech', value: 'Montana Tech' },
  { label: 'Montanuniversität Leoben', value: 'Montanuniversität Leoben' },
  { label: 'Montclair State University', value: 'Montclair State University' },
  { label: 'Monterey Institute of International Studies', value: 'Monterey Institute of International Studies' },
  { label: 'Montreat College', value: 'Montreat College' },
  { label: 'Montserrat College of Art', value: 'Montserrat College of Art' },
  { label: 'Moody Bible Institute', value: 'Moody Bible Institute' },
  { label: 'Moore College of Art and Design', value: 'Moore College of Art and Design' },
  { label: 'Mooreland University', value: 'Mooreland University' },
  { label: 'Moorhead State University', value: 'Moorhead State University' },
  { label: 'Moraine Valley Community College', value: 'Moraine Valley Community College' },
  { label: 'Moravian College', value: 'Moravian College' },
  { label: 'Mordovian State University', value: 'Mordovian State University' },
  { label: 'Morehead State University', value: 'Morehead State University' },
  { label: 'Morehouse College', value: 'Morehouse College' },
  { label: 'Morehouse School of Medicine', value: 'Morehouse School of Medicine' },
  { label: 'Morgan State University', value: 'Morgan State University' },
  { label: 'Morioka College', value: 'Morioka College' },
  { label: 'Morningside College', value: 'Morningside College' },
  { label: 'Morris Brown College', value: 'Morris Brown College' },
  { label: 'Morris College', value: 'Morris College' },
  { label: 'Morrison College', value: 'Morrison College' },
  { label: 'Moscow External University of the Humanities', value: 'Moscow External University of the Humanities' },
  { label: 'Moscow Institute of Physics and Technology', value: 'Moscow Institute of Physics and Technology' },
  {
    label: 'Moscow International Higher Business School (MIRBIS)',
    value: 'Moscow International Higher Business School (MIRBIS)',
  },
  { label: 'Moscow P. I. Tchaikovsky Conservatory', value: 'Moscow P. I. Tchaikovsky Conservatory' },
  {
    label: 'Moscow Power Engineering Institute (Technical University)',
    value: 'Moscow Power Engineering Institute (Technical University)',
  },
  { label: 'Moscow State Academy of Applied Biotechnology', value: 'Moscow State Academy of Applied Biotechnology' },
  {
    label: 'Moscow State Automobile and Road Technical University',
    value: 'Moscow State Automobile and Road Technical University',
  },
  { label: 'Moscow State Industrial University', value: 'Moscow State Industrial University' },
  {
    label: 'Moscow State Institute of International Relations MFA Russia (MGIMO-University)',
    value: 'Moscow State Institute of International Relations MFA Russia (MGIMO-University)',
  },
  {
    label: 'Moscow State Institute of Radiotechnics, Electronics and Automation (Technical University)',
    value: 'Moscow State Institute of Radiotechnics, Electronics and Automation (Technical University)',
  },
  { label: 'Moscow State Institute of Steel and Alloys', value: 'Moscow State Institute of Steel and Alloys' },
  {
    label: 'Moscow State Technical University of Civil Aviation',
    value: 'Moscow State Technical University of Civil Aviation',
  },
  { label: 'Moscow State Textile University A.N. Kosygin', value: 'Moscow State Textile University A.N. Kosygin' },
  { label: 'Moscow State University', value: 'Moscow State University' },
  { label: 'Moscow State University M.V. Lomonosov', value: 'Moscow State University M.V. Lomonosov' },
  { label: 'Moscow State University of Civil Engineering', value: 'Moscow State University of Civil Engineering' },
  { label: 'Moscow State University of Commerce', value: 'Moscow State University of Commerce' },
  { label: 'Moscow State University of Culture', value: 'Moscow State University of Culture' },
  {
    label: 'Moscow State University of Ecological Engineering',
    value: 'Moscow State University of Ecological Engineering',
  },
  {
    label: 'Moscow State University of Economics, Statistics and Informatics',
    value: 'Moscow State University of Economics, Statistics and Informatics',
  },
  {
    label: 'Moscow State University of Environmental Engineering',
    value: 'Moscow State University of Environmental Engineering',
  },
  {
    label: 'Moscow State University of Forestry Engineering',
    value: 'Moscow State University of Forestry Engineering',
  },
  {
    label: 'Moscow State University of Geodesy and Cartography',
    value: 'Moscow State University of Geodesy and Cartography',
  },
  { label: 'Moscow State University of Railway Transport', value: 'Moscow State University of Railway Transport' },
  {
    label: 'Moscow State University of Technology (Stankin)',
    value: 'Moscow State University of Technology (Stankin)',
  },
  {
    label: 'Moscow Technical University of Informatics and Communication',
    value: 'Moscow Technical University of Informatics and Communication',
  },
  { label: 'Moscow University Touro', value: 'Moscow University Touro' },
  {
    label: 'Moshi University College of Cooperative and Business Studies',
    value: 'Moshi University College of Cooperative and Business Studies',
  },
  { label: "Mother Teresa Women's University", value: "Mother Teresa Women's University" },
  { label: 'Motilal Nehru National Institute Of Technology', value: 'Motilal Nehru National Institute Of Technology' },
  { label: 'Moulana Azad national Urdu University', value: 'Moulana Azad national Urdu University' },
  { label: 'Mount Allison University', value: 'Mount Allison University' },
  { label: 'Mount Aloysius College', value: 'Mount Aloysius College' },
  { label: 'Mount Carmel College of Nursing', value: 'Mount Carmel College of Nursing' },
  { label: 'Mount Holyoke College', value: 'Mount Holyoke College' },
  { label: 'Mount Ida College', value: 'Mount Ida College' },
  { label: 'Mount Kenya University', value: 'Mount Kenya University' },
  { label: 'Mount Marty College', value: 'Mount Marty College' },
  { label: 'Mount Mary College', value: 'Mount Mary College' },
  { label: 'Mount Mercy College', value: 'Mount Mercy College' },
  { label: 'Mount Meru University', value: 'Mount Meru University' },
  { label: 'Mount Olive College', value: 'Mount Olive College' },
  { label: 'Mount Royal College', value: 'Mount Royal College' },
  { label: 'Mount Saint Vincent University', value: 'Mount Saint Vincent University' },
  { label: 'Mount Senario College', value: 'Mount Senario College' },
  { label: 'Mount Sinai School of Medicine', value: 'Mount Sinai School of Medicine' },
  { label: 'Mount St. Clare College', value: 'Mount St. Clare College' },
  { label: 'Mount St. Mary College', value: 'Mount St. Mary College' },
  { label: "Mount St. Mary's College California", value: "Mount St. Mary's College California" },
  { label: "Mount St. Mary's College Maryland", value: "Mount St. Mary's College Maryland" },
  { label: 'Mount Union College', value: 'Mount Union College' },
  { label: 'Mount Vernon College', value: 'Mount Vernon College' },
  { label: 'Mount Vernon Nazarene College', value: 'Mount Vernon Nazarene College' },
  { label: 'Mountain State University', value: 'Mountain State University' },
  { label: 'Mountain View College', value: 'Mountain View College' },
  { label: 'Mountains of the Moon University', value: 'Mountains of the Moon University' },
  { label: 'Mozyr State Pedagogical Institute', value: 'Mozyr State Pedagogical Institute' },
  { label: "Mu' tah University", value: "Mu' tah University" },
  { label: 'Muadzam Shah Polytechnic', value: 'Muadzam Shah Polytechnic' },
  { label: 'Mugla University', value: 'Mugla University' },
  {
    label: 'Muhimbili University of Health and Allied Sciences (MUHAS)',
    value: 'Muhimbili University of Health and Allied Sciences (MUHAS)',
  },
  { label: 'Muhlenberg College', value: 'Muhlenberg College' },
  { label: "Mukogawa Women's University", value: "Mukogawa Women's University" },
  { label: 'Multimedia University', value: 'Multimedia University' },
  { label: 'Multimedia University of Kenya', value: 'Multimedia University of Kenya' },
  { label: 'Multnomah Bible College', value: 'Multnomah Bible College' },
  { label: 'Mulungushi University', value: 'Mulungushi University' },
  { label: 'Murdoch University', value: 'Murdoch University' },
  { label: 'Murmansk State Technical University', value: 'Murmansk State Technical University' },
  { label: 'Murni Nursing College', value: 'Murni Nursing College' },
  { label: 'Muroran Institute of Technology', value: 'Muroran Institute of Technology' },
  { label: 'Murray State University', value: 'Murray State University' },
  { label: 'Musashi Institute of Technology', value: 'Musashi Institute of Technology' },
  { label: 'Musashi University', value: 'Musashi University' },
  { label: 'Musashino Academy of Music', value: 'Musashino Academy of Music' },
  { label: 'Musashino Art University', value: 'Musashino Art University' },
  { label: "Musashino Women's University", value: "Musashino Women's University" },
  { label: 'Muscat College', value: 'Muscat College' },
  { label: 'Music Academy Felix Nowowiejski in Bydgoszcz', value: 'Music Academy Felix Nowowiejski in Bydgoszcz' },
  { label: 'Music Academy Fryderyk Chopin in Warszaw', value: 'Music Academy Fryderyk Chopin in Warszaw' },
  { label: 'Music Academy Ignacy Jana Paderewski in Poznan', value: 'Music Academy Ignacy Jana Paderewski in Poznan' },
  { label: 'Music Academy in Cracow', value: 'Music Academy in Cracow' },
  { label: 'Music Academy in Lodz', value: 'Music Academy in Lodz' },
  { label: 'Music Academy Karol Lipinski in Wroclaw', value: 'Music Academy Karol Lipinski in Wroclaw' },
  { label: 'Music Academy Karol Szymanowski in Katowice', value: 'Music Academy Karol Szymanowski in Katowice' },
  { label: 'Music Academy Stanislaw Moniuszko in Gdansk', value: 'Music Academy Stanislaw Moniuszko in Gdansk' },
  { label: 'Muskingum College', value: 'Muskingum College' },
  { label: 'Mustafa International University', value: 'Mustafa International University' },
  { label: 'Mustafa Kemal University', value: 'Mustafa Kemal University' },
  { label: 'Mutesa 1 Royal University', value: 'Mutesa 1 Royal University' },
  {
    label: 'Muthesius-Hochschule, Fachhochschule für Kunst und Gestaltung',
    value: 'Muthesius-Hochschule, Fachhochschule für Kunst und Gestaltung',
  },
  { label: 'Mwalimu Nyerere Memorial Academy', value: 'Mwalimu Nyerere Memorial Academy' },
  { label: 'Myanmar Aerospace Engineering University', value: 'Myanmar Aerospace Engineering University' },
  { label: 'Mykolo Romerio university', value: 'Mykolo Romerio university' },
  { label: 'Myongji University', value: 'Myongji University' },
  { label: 'Mzumbe University (Chuo Kikuu Mzumbe)', value: 'Mzumbe University (Chuo Kikuu Mzumbe)' },
  { label: 'Mzuzu University', value: 'Mzuzu University' },
  { label: 'NABA - Nuova Accademia di Belle Arti, Milan', value: 'NABA - Nuova Accademia di Belle Arti, Milan' },
  { label: 'NAES College', value: 'NAES College' },
  { label: 'Nagaland University', value: 'Nagaland University' },
  { label: 'Nagano University', value: 'Nagano University' },
  { label: 'Nagaoka University of Technology', value: 'Nagaoka University of Technology' },
  { label: 'Nagasaki Institute of Applied Science', value: 'Nagasaki Institute of Applied Science' },
  { label: 'Nagasaki Prefectural University', value: 'Nagasaki Prefectural University' },
  { label: 'Nagasaki University', value: 'Nagasaki University' },
  { label: 'Nagoya City University', value: 'Nagoya City University' },
  { label: 'Nagoya College of Music', value: 'Nagoya College of Music' },
  { label: 'Nagoya Economics University', value: 'Nagoya Economics University' },
  { label: 'Nagoya Gakuin University', value: 'Nagoya Gakuin University' },
  { label: 'Nagoya Institute of Technology', value: 'Nagoya Institute of Technology' },
  { label: 'Nagoya University', value: 'Nagoya University' },
  { label: 'Nagoya University of Arts', value: 'Nagoya University of Arts' },
  {
    label: 'Nagoya University of Commerce and Business Administration',
    value: 'Nagoya University of Commerce and Business Administration',
  },
  { label: 'Nagoya University of Foreign Studies', value: 'Nagoya University of Foreign Studies' },
  { label: "Nagoya Women's University", value: "Nagoya Women's University" },
  { label: 'Nagpur University', value: 'Nagpur University' },
  { label: 'Naif Arab University for Security Sciences', value: 'Naif Arab University for Security Sciences' },
  { label: 'Nairobi Institute of Business Studies ', value: 'Nairobi Institute of Business Studies ' },
  { label: 'Nairobi Technical Training Institute ', value: 'Nairobi Technical Training Institute ' },
  {
    label: 'Najaf Abad University of Applied Science and Technology',
    value: 'Najaf Abad University of Applied Science and Technology',
  },
  { label: 'Najran University', value: 'Najran University' },
  { label: 'Nakamura Gakuen University', value: 'Nakamura Gakuen University' },
  { label: 'Nakhchivan Private University', value: 'Nakhchivan Private University' },
  { label: 'Nakhchivan State University', value: 'Nakhchivan State University' },
  { label: 'Nalanda Open University', value: 'Nalanda Open University' },
  { label: 'Namik Kemal University', value: 'Namik Kemal University' },
  { label: 'Nanchang University', value: 'Nanchang University' },
  { label: 'Nangarhar University', value: 'Nangarhar University' },
  { label: 'Nanhua University', value: 'Nanhua University' },
  { label: 'Nanjing Agricultural University', value: 'Nanjing Agricultural University' },
  { label: 'Nanjing Forestry University', value: 'Nanjing Forestry University' },
  { label: 'Nanjing Institute of Meteorology', value: 'Nanjing Institute of Meteorology' },
  { label: 'Nanjing Medical University', value: 'Nanjing Medical University' },
  { label: 'Nanjing Normal University', value: 'Nanjing Normal University' },
  { label: 'Nanjing Union Theological Seminary', value: 'Nanjing Union Theological Seminary' },
  { label: 'Nanjing University', value: 'Nanjing University' },
  {
    label: 'Nanjing University of Aeronautics and Astronautics',
    value: 'Nanjing University of Aeronautics and Astronautics',
  },
  { label: 'Nanjing University of Chemical Technology', value: 'Nanjing University of Chemical Technology' },
  { label: 'Nanjing University of Economics', value: 'Nanjing University of Economics' },
  {
    label: 'Nanjing University of Posts and Telecommunications',
    value: 'Nanjing University of Posts and Telecommunications',
  },
  { label: 'Nanjing University of Science and Technology', value: 'Nanjing University of Science and Technology' },
  {
    label: 'Nanjing University of Traditional Chinese Medicine',
    value: 'Nanjing University of Traditional Chinese Medicine',
  },
  { label: 'Nankai University', value: 'Nankai University' },
  { label: 'Nanyang Technological University', value: 'Nanyang Technological University' },
  { label: 'Nanzan University', value: 'Nanzan University' },
  { label: 'Napier University', value: 'Napier University' },
  { label: 'Nara Medical University', value: 'Nara Medical University' },
  { label: 'Nara Sangyo University', value: 'Nara Sangyo University' },
  { label: 'Nara University', value: 'Nara University' },
  { label: 'Nara University of Commerce', value: 'Nara University of Commerce' },
  { label: 'Nara University of Education', value: 'Nara University of Education' },
  { label: "Nara Women's University", value: "Nara Women's University" },
  {
    label: 'Narendra Deva University of Agriculture and Technology',
    value: 'Narendra Deva University of Agriculture and Technology',
  },
  { label: 'Naresuan University', value: 'Naresuan University' },
  { label: 'Narsee Monjee Institute of Management Studies', value: 'Narsee Monjee Institute of Management Studies' },
  { label: 'Naruto University of Education', value: 'Naruto University of Education' },
  { label: 'Nasarawa State University Keffi', value: 'Nasarawa State University Keffi' },
  {
    label: 'National Academy for Physical Education and Sports Bucharest',
    value: 'National Academy for Physical Education and Sports Bucharest',
  },
  {
    label: 'National Academy for Theatre and Film Arts Krustju Sarafov',
    value: 'National Academy for Theatre and Film Arts Krustju Sarafov',
  },
  { label: 'National Academy of Arts', value: 'National Academy of Arts' },
  { label: 'National Academy of Fine Arts', value: 'National Academy of Fine Arts' },
  { label: 'National Academy of Music Pantcho Vladigerov', value: 'National Academy of Music Pantcho Vladigerov' },
  {
    label: 'National Aerospace University Kharkov Aviation Institute',
    value: 'National Aerospace University Kharkov Aviation Institute',
  },
  { label: 'National Agricultural University', value: 'National Agricultural University' },
  { label: 'National American University', value: 'National American University' },
  { label: 'National American University, Albuquerque', value: 'National American University, Albuquerque' },
  { label: 'National American University, Colorado Springs', value: 'National American University, Colorado Springs' },
  { label: 'National American University, Denver', value: 'National American University, Denver' },
  { label: 'National American University, Kansas City', value: 'National American University, Kansas City' },
  { label: 'National American University, Roseville', value: 'National American University, Roseville' },
  { label: 'National American University, Sioux Falls', value: 'National American University, Sioux Falls' },
  { label: 'National Aviation Academy', value: 'National Aviation Academy' },
  { label: 'National Aviation Univesity', value: 'National Aviation Univesity' },
  { label: 'National Central University', value: 'National Central University' },
  { label: 'National Changhua University of Education', value: 'National Changhua University of Education' },
  { label: 'National Cheng Kung University', value: 'National Cheng Kung University' },
  { label: 'National Chengchi University', value: 'National Chengchi University' },
  { label: 'National Chi Nan University', value: 'National Chi Nan University' },
  { label: 'National Chiao Tung University', value: 'National Chiao Tung University' },
  { label: 'National Chiayi University', value: 'National Chiayi University' },
  { label: 'National Chung Cheng University', value: 'National Chung Cheng University' },
  { label: 'National Chung Hsing University, Taichung', value: 'National Chung Hsing University, Taichung' },
  { label: 'National Chung Hsing University, Taipei', value: 'National Chung Hsing University, Taipei' },
  {
    label: 'National College for Medical and Technical Studies',
    value: 'National College for Medical and Technical Studies',
  },
  { label: 'National College of Art and Design', value: 'National College of Art and Design' },
  { label: 'National College of Arts', value: 'National College of Arts' },
  { label: 'National College of Chiropractic', value: 'National College of Chiropractic' },
  { label: 'National College of Ireland', value: 'National College of Ireland' },
  { label: 'National College of Naturopathic Medicine', value: 'National College of Naturopathic Medicine' },
  {
    label: 'National College of Physical Education and Sports',
    value: 'National College of Physical Education and Sports',
  },
  { label: 'National Dairy Research Institute', value: 'National Dairy Research Institute' },
  { label: 'National Defence Academy of Latvia', value: 'National Defence Academy of Latvia' },
  { label: 'National Defence Medical College', value: 'National Defence Medical College' },
  { label: 'National Defense University', value: 'National Defense University' },
  { label: 'National Dong Hwa University', value: 'National Dong Hwa University' },
  { label: 'National Fisheries University', value: 'National Fisheries University' },
  { label: 'National Formosa University', value: 'National Formosa University' },
  { label: 'National Hispanic University', value: 'National Hispanic University' },
  { label: 'National Hsin-Chu Teachers College', value: 'National Hsin-Chu Teachers College' },
  { label: 'National Hualien Teachers College', value: 'National Hualien Teachers College' },
  { label: 'National Ilan University', value: 'National Ilan University' },
  {
    label: 'National Institute of Development Administration',
    value: 'National Institute of Development Administration',
  },
  { label: 'National Institute of Education', value: 'National Institute of Education' },
  {
    label: 'National Institute of Fitness and Sports Kanoya',
    value: 'National Institute of Fitness and Sports Kanoya',
  },
  { label: 'National Institute of Industrial Engineering', value: 'National Institute of Industrial Engineering' },
  {
    label: 'National Institute of Mental Health and Neuro Sciences',
    value: 'National Institute of Mental Health and Neuro Sciences',
  },
  { label: 'National Institute of Technology Kurukshetra', value: 'National Institute of Technology Kurukshetra' },
  { label: 'National Institute of Technology, Calicut', value: 'National Institute of Technology, Calicut' },
  { label: 'National Institute of Technology, Durgapur', value: 'National Institute of Technology, Durgapur' },
  { label: 'National Institute of Technology, Jamshedpur', value: 'National Institute of Technology, Jamshedpur' },
  { label: 'National Institute of Technology, Rourkela', value: 'National Institute of Technology, Rourkela' },
  { label: 'National Institute of Technology, Trichy', value: 'National Institute of Technology, Trichy' },
  { label: 'National Institute of Technology, Warangal', value: 'National Institute of Technology, Warangal' },
  { label: 'National Institute of the Arts', value: 'National Institute of the Arts' },
  {
    label: 'National Kaohsiung First University of Science and Technology',
    value: 'National Kaohsiung First University of Science and Technology',
  },
  { label: 'National Kaohsiung Normal University', value: 'National Kaohsiung Normal University' },
  {
    label: 'National Kaohsiung University of Applied Sciences',
    value: 'National Kaohsiung University of Applied Sciences',
  },
  { label: 'National Law School of India University', value: 'National Law School of India University' },
  { label: 'National Medical University O.O. Bogomolets', value: 'National Medical University O.O. Bogomolets' },
  { label: 'National Metallurgical Academy of Ukraine', value: 'National Metallurgical Academy of Ukraine' },
  { label: 'National Military Academy of Afghanistan', value: 'National Military Academy of Afghanistan' },
  { label: 'National Mining University', value: 'National Mining University' },
  {
    label: 'National Museum Institute of History of Art, Conservation and Museology',
    value: 'National Museum Institute of History of Art, Conservation and Museology',
  },
  { label: 'National Open University', value: 'National Open University' },
  { label: 'National Open University of Nigeria', value: 'National Open University of Nigeria' },
  { label: 'National Pedagogical University M. Dragomanov ', value: 'National Pedagogical University M. Dragomanov ' },
  { label: 'National Pingtung Teachers College', value: 'National Pingtung Teachers College' },
  {
    label: 'National Pingtung University of Science and Technology',
    value: 'National Pingtung University of Science and Technology',
  },
  { label: 'National Pirogov Memorial Medical University', value: 'National Pirogov Memorial Medical University' },
  {
    label: 'National Research University of Electronic Technology',
    value: 'National Research University of Electronic Technology',
  },
  { label: 'National Ribat University', value: 'National Ribat University' },
  { label: 'National Sanskrit University', value: 'National Sanskrit University' },
  {
    label: 'National School of Political and Administrative Studies Bucharest',
    value: 'National School of Political and Administrative Studies Bucharest',
  },
  { label: 'National Sports Academy Sofia', value: 'National Sports Academy Sofia' },
  { label: 'National Sun Yat-Sen University', value: 'National Sun Yat-Sen University' },
  { label: 'National Taichung Teachers College', value: 'National Taichung Teachers College' },
  { label: 'National Tainan Teachers College', value: 'National Tainan Teachers College' },
  { label: 'National Taipei College of Nursing', value: 'National Taipei College of Nursing' },
  { label: 'National Taipei Teachers College', value: 'National Taipei Teachers College' },
  { label: 'National Taipei University', value: 'National Taipei University' },
  { label: 'National Taipei University of Technology', value: 'National Taipei University of Technology' },
  { label: 'National Taitung Teachers College', value: 'National Taitung Teachers College' },
  { label: 'National Taiwan College of Arts', value: 'National Taiwan College of Arts' },
  {
    label: 'National Taiwan College of Physical Education and Sports',
    value: 'National Taiwan College of Physical Education and Sports',
  },
  { label: 'National Taiwan College of the Arts', value: 'National Taiwan College of the Arts' },
  { label: 'National Taiwan Normal University', value: 'National Taiwan Normal University' },
  { label: 'National Taiwan Ocean University', value: 'National Taiwan Ocean University' },
  { label: 'National Taiwan University', value: 'National Taiwan University' },
  {
    label: 'National Taiwan University of Science and Technology',
    value: 'National Taiwan University of Science and Technology',
  },
  { label: 'National Taras Shevchenko University of Kiev', value: 'National Taras Shevchenko University of Kiev' },
  {
    label: 'National Technical University (Kharkiv Polytechnical Institute)',
    value: 'National Technical University (Kharkiv Polytechnical Institute)',
  },
  {
    label: 'National Technical University (Kiev Politechnical Institute)',
    value: 'National Technical University (Kiev Politechnical Institute)',
  },
  { label: 'National Technical University of Athens', value: 'National Technical University of Athens' },
  { label: 'National Technological University', value: 'National Technological University' },
  { label: 'National Theatre Conservatory', value: 'National Theatre Conservatory' },
  { label: 'National Transport University', value: 'National Transport University' },
  { label: 'National Tsinghua University', value: 'National Tsinghua University' },
  { label: 'National United University', value: 'National United University' },
  { label: 'National University', value: 'National University' },
  { label: 'National University', value: 'National University' },
  { label: 'National University', value: 'National University' },
  { label: 'National University of Arts', value: 'National University of Arts' },
  {
    label: 'National University of Computer & Emerging Sciences',
    value: 'National University of Computer & Emerging Sciences',
  },
  { label: 'National University of Defense Technology', value: 'National University of Defense Technology' },
  { label: 'National University of Food Technologies', value: 'National University of Food Technologies' },
  { label: 'National University of Internal Affairs', value: 'National University of Internal Affairs' },
  { label: 'National University of Ireland', value: 'National University of Ireland' },
  { label: 'National University of Ireland, Galway', value: 'National University of Ireland, Galway' },
  { label: 'National University of Ireland, Maynooth', value: 'National University of Ireland, Maynooth' },
  { label: 'National University of Kaohsiung', value: 'National University of Kaohsiung' },
  { label: 'National University of Kiev-Mohyla Academy', value: 'National University of Kiev-Mohyla Academy' },
  { label: 'National University of Laos', value: 'National University of Laos' },
  { label: 'National University of Lesotho', value: 'National University of Lesotho' },
  { label: 'National University of Management', value: 'National University of Management' },
  { label: 'National University of Modern Languages', value: 'National University of Modern Languages' },
  { label: 'National University of Music', value: 'National University of Music' },
  { label: 'National University of Ostroh Academy', value: 'National University of Ostroh Academy' },
  { label: 'National University of Rwanda', value: 'National University of Rwanda' },
  { label: 'National University of Samoa', value: 'National University of Samoa' },
  { label: 'National University of Science and Technology', value: 'National University of Science and Technology' },
  {
    label: 'National University of Science and Technology Bulawayo',
    value: 'National University of Science and Technology Bulawayo',
  },
  { label: 'National University of Shipbuilding', value: 'National University of Shipbuilding' },
  { label: 'National University of Singapore', value: 'National University of Singapore' },
  { label: 'National University of Theater and Film Arts', value: 'National University of Theater and Film Arts' },
  { label: 'National University of Uzbekistan', value: 'National University of Uzbekistan' },
  { label: 'National Yang Ming Medical College', value: 'National Yang Ming Medical College' },
  {
    label: 'National Yunlin University of Science and Technology',
    value: 'National Yunlin University of Science and Technology',
  },
  { label: 'National-Louis University', value: 'National-Louis University' },
  { label: 'Naval Postgraduate School', value: 'Naval Postgraduate School' },
  { label: 'Nawroz University', value: 'Nawroz University' },
  { label: 'Nayanova University', value: 'Nayanova University' },
  { label: 'Nazarene Bible College', value: 'Nazarene Bible College' },
  { label: 'Nazareth College', value: 'Nazareth College' },
  { label: 'Ndejje University', value: 'Ndejje University' },
  { label: 'Near East School of Theology', value: 'Near East School of Theology' },
  { label: 'Near East University', value: 'Near East University' },
  { label: 'Nebraska Christian College', value: 'Nebraska Christian College' },
  {
    label: 'Nebraska Methodist College of Nursing and Allied Health',
    value: 'Nebraska Methodist College of Nursing and Allied Health',
  },
  { label: 'Nebraska Wesleyan University', value: 'Nebraska Wesleyan University' },
  { label: 'Necmettin Erbakan University', value: 'Necmettin Erbakan University' },
  {
    label: 'NED University of Engineering and Technology Karachi',
    value: 'NED University of Engineering and Technology Karachi',
  },
  { label: 'Neelain University', value: 'Neelain University' },
  { label: 'Neijiang Teacher University', value: 'Neijiang Teacher University' },
  { label: 'Nelson Mandela Metropolitan University', value: 'Nelson Mandela Metropolitan University' },
  { label: 'Nepal Sanskrit University', value: 'Nepal Sanskrit University' },
  { label: 'Netaji Subhas Inst of Technology', value: 'Netaji Subhas Inst of Technology' },
  { label: 'Neumann College', value: 'Neumann College' },
  { label: 'Nevsky Institute of Language and Culture', value: 'Nevsky Institute of Language and Culture' },
  { label: 'New Bulgarian University', value: 'New Bulgarian University' },
  { label: 'New College of California', value: 'New College of California' },
  { label: 'New College of Florida', value: 'New College of Florida' },
  { label: 'New Economic School', value: 'New Economic School' },
  { label: 'New England College', value: 'New England College' },
  { label: 'New England College of Optometry', value: 'New England College of Optometry' },
  { label: 'New England Conservatory of Music', value: 'New England Conservatory of Music' },
  { label: 'New England Institute of Technology', value: 'New England Institute of Technology' },
  { label: 'New England School of Art and Design', value: 'New England School of Art and Design' },
  { label: 'New England School of Communications', value: 'New England School of Communications' },
  { label: 'New England School of Law', value: 'New England School of Law' },
  { label: 'New Era College of Arts, Science & Technology', value: 'New Era College of Arts, Science & Technology' },
  { label: 'New Era University', value: 'New Era University' },
  { label: 'New Hampshire College', value: 'New Hampshire College' },
  { label: 'New Jersey City University', value: 'New Jersey City University' },
  { label: 'New Jersey Institute of Technology', value: 'New Jersey Institute of Technology' },
  { label: 'New Mexico Highlands University', value: 'New Mexico Highlands University' },
  { label: 'New Mexico Institute of Mining and Technology', value: 'New Mexico Institute of Mining and Technology' },
  { label: 'New Mexico State University', value: 'New Mexico State University' },
  { label: 'New World University', value: 'New World University' },
  {
    label: 'New York Academy of Art, Graduate School of Figurative Art',
    value: 'New York Academy of Art, Graduate School of Figurative Art',
  },
  { label: 'New York Chiropractic College', value: 'New York Chiropractic College' },
  { label: 'New York College of Podiatric Medicine', value: 'New York College of Podiatric Medicine' },
  { label: 'New York Film Academy', value: 'New York Film Academy' },
  { label: 'New York Institute of Technology', value: 'New York Institute of Technology' },
  { label: 'New York Institute of Technology', value: 'New York Institute of Technology' },
  { label: 'New York Instiute of Technology', value: 'New York Instiute of Technology' },
  { label: 'New York Law School', value: 'New York Law School' },
  { label: 'New York Medical College', value: 'New York Medical College' },
  { label: 'New York School of Interior Design', value: 'New York School of Interior Design' },
  { label: 'New York University', value: 'New York University' },
  { label: 'New York University, Abu Dhabi', value: 'New York University, Abu Dhabi' },
  { label: 'Newberry College', value: 'Newberry College' },
  { label: 'Newcastle University, Medicine Malaysia ', value: 'Newcastle University, Medicine Malaysia ' },
  { label: 'Newman University', value: 'Newman University' },
  { label: 'Newport International University', value: 'Newport International University' },
  {
    label: 'Newports Institute of Communication & Economics',
    value: 'Newports Institute of Communication & Economics',
  },
  { label: 'Newschool of Architecture and Design', value: 'Newschool of Architecture and Design' },
  {
    label: 'NGO Vladikavkaz Institute of Economics, Management, and Law',
    value: 'NGO Vladikavkaz Institute of Economics, Management, and Law',
  },
  { label: 'NHL University of Applied Sciences', value: 'NHL University of Applied Sciences' },
  {
    label: 'NHTV Breda University of Professional Education',
    value: 'NHTV Breda University of Professional Education',
  },
  { label: 'Niagara University', value: 'Niagara University' },
  { label: 'Nicholls State University', value: 'Nicholls State University' },
  { label: 'Nichols College', value: 'Nichols College' },
  { label: 'Nicola Valley Institute of Technology', value: 'Nicola Valley Institute of Technology' },
  { label: 'Nicolaus Copernicus University of Torun', value: 'Nicolaus Copernicus University of Torun' },
  { label: 'Nigde University', value: 'Nigde University' },
  { label: 'Niger Delta University', value: 'Niger Delta University' },
  { label: 'Nigerian Defence Academy', value: 'Nigerian Defence Academy' },
  { label: 'Nigerian Turkish Nile University', value: 'Nigerian Turkish Nile University' },
  { label: 'Nihon Fukushi University', value: 'Nihon Fukushi University' },
  { label: 'Nihon University', value: 'Nihon University' },
  { label: 'Niigata College of Pharmacy', value: 'Niigata College of Pharmacy' },
  { label: 'Niigata Sangyo University', value: 'Niigata Sangyo University' },
  { label: 'Niigata University', value: 'Niigata University' },
  {
    label: 'Niigata University of International and Information Studies',
    value: 'Niigata University of International and Information Studies',
  },
  { label: 'Niigata University of Management', value: 'Niigata University of Management' },
  { label: 'Nilai University College', value: 'Nilai University College' },
  { label: 'Nile University', value: 'Nile University' },
  { label: 'Nile Valley University', value: 'Nile Valley University' },
  { label: 'Ningbo University', value: 'Ningbo University' },
  { label: 'Ningbo University of Technology', value: 'Ningbo University of Technology' },
  { label: 'Ningxia Medical College', value: 'Ningxia Medical College' },
  { label: 'Ningxia University', value: 'Ningxia University' },
  { label: 'Nipissing University', value: 'Nipissing University' },
  { label: 'Nippon Bunri University', value: 'Nippon Bunri University' },
  { label: 'Nippon Dental University', value: 'Nippon Dental University' },
  { label: 'Nippon Institute of Technology', value: 'Nippon Institute of Technology' },
  { label: 'Nippon Medical School', value: 'Nippon Medical School' },
  { label: 'Nippon Sport Science University', value: 'Nippon Sport Science University' },
  { label: 'Nippon Veterinary and Animalscience University', value: 'Nippon Veterinary and Animalscience University' },
  { label: 'Nirma University', value: 'Nirma University' },
  { label: 'Nishikyushu University', value: 'Nishikyushu University' },
  { label: 'Nishinippon Institute of Technology', value: 'Nishinippon Institute of Technology' },
  { label: 'Nisho Gakusha University', value: 'Nisho Gakusha University' },
  { label: 'Nivadhana University', value: 'Nivadhana University' },
  { label: "Nizam's Institute of Medical Sciences", value: "Nizam's Institute of Medical Sciences" },
  { label: 'Nizhny Novgorod State Academy of Medicine', value: 'Nizhny Novgorod State Academy of Medicine' },
  {
    label: 'Nizhny Novgorod State Architectural - Building University',
    value: 'Nizhny Novgorod State Architectural - Building University',
  },
  { label: 'Nizhny Novgorod State Technical University', value: 'Nizhny Novgorod State Technical University' },
  { label: 'Nizhny Novgorod State University', value: 'Nizhny Novgorod State University' },
  { label: 'Njala University', value: 'Njala University' },
  { label: 'Nkumba University', value: 'Nkumba University' },
  { label: 'Nnamdi Azikiwe University', value: 'Nnamdi Azikiwe University' },
  { label: 'Noakhali University of Science and Technology', value: 'Noakhali University of Science and Technology' },
  {
    label: 'Nordakademie, Staatlich anerkannte private Fachhochschule mit dualen Studiengängen',
    value: 'Nordakademie, Staatlich anerkannte private Fachhochschule mit dualen Studiengängen',
  },
  { label: 'Nordic School of Public Health', value: 'Nordic School of Public Health' },
  { label: 'Norfolk State University', value: 'Norfolk State University' },
  { label: 'North Bengal University', value: 'North Bengal University' },
  {
    label: 'North Carolina Agricultural and Technical State University',
    value: 'North Carolina Agricultural and Technical State University',
  },
  { label: 'North Carolina Central University', value: 'North Carolina Central University' },
  { label: 'North Carolina School of the Arts', value: 'North Carolina School of the Arts' },
  { label: 'North Carolina State University', value: 'North Carolina State University' },
  { label: 'North Carolina Wesleyan College', value: 'North Carolina Wesleyan College' },
  { label: 'North Central Bible College', value: 'North Central Bible College' },
  { label: 'North Central College', value: 'North Central College' },
  { label: 'North China Electric Power University', value: 'North China Electric Power University' },
  { label: 'North China University of Technology', value: 'North China University of Technology' },
  { label: 'North Dakota State University', value: 'North Dakota State University' },
  { label: 'North East University Bangladesh', value: 'North East University Bangladesh' },
  { label: 'North Eastern Hill University', value: 'North Eastern Hill University' },
  { label: 'North Georgia College', value: 'North Georgia College' },
  { label: 'North Greenville College', value: 'North Greenville College' },
  { label: 'North Island College', value: 'North Island College' },
  { label: 'North Kazakhstan State University', value: 'North Kazakhstan State University' },
  { label: 'North Maharashtra University', value: 'North Maharashtra University' },
  { label: 'North Ossetian State University', value: 'North Ossetian State University' },
  { label: 'North Park University', value: 'North Park University' },
  { label: 'North South University', value: 'North South University' },
  { label: 'North University of Baia Mare', value: 'North University of Baia Mare' },
  {
    label: 'North West Frontier Province Agricultural University',
    value: 'North West Frontier Province Agricultural University',
  },
  { label: 'North-West State Technical University', value: 'North-West State Technical University' },
  { label: 'Northcentral University', value: 'Northcentral University' },
  { label: 'Northeast Agricultural University', value: 'Northeast Agricultural University' },
  { label: 'Northeast Forest University', value: 'Northeast Forest University' },
  { label: 'Northeast Normal University', value: 'Northeast Normal University' },
  { label: 'Northeast University at Qinhuangdao Campus', value: 'Northeast University at Qinhuangdao Campus' },
  { label: 'Northeastern Illinois University', value: 'Northeastern Illinois University' },
  {
    label: 'Northeastern Ohio University College of Medicine',
    value: 'Northeastern Ohio University College of Medicine',
  },
  { label: 'Northeastern State University', value: 'Northeastern State University' },
  { label: 'Northeastern University', value: 'Northeastern University' },
  { label: 'Northeastern University', value: 'Northeastern University' },
  { label: 'Northern Alberta Institute of Technology', value: 'Northern Alberta Institute of Technology' },
  { label: 'Northern Arizona University', value: 'Northern Arizona University' },
  { label: 'Northern Border University', value: 'Northern Border University' },
  { label: 'Northern Caribbean University', value: 'Northern Caribbean University' },
  { label: 'Northern Illinois University', value: 'Northern Illinois University' },
  {
    label: 'Northern Institute of Technology Management (NIT)',
    value: 'Northern Institute of Technology Management (NIT)',
  },
  { label: 'Northern Jiaotong University', value: 'Northern Jiaotong University' },
  { label: 'Northern Kentucky University', value: 'Northern Kentucky University' },
  { label: 'Northern Lights College', value: 'Northern Lights College' },
  { label: 'Northern Michigan University', value: 'Northern Michigan University' },
  { label: 'Northern Sichuan Medical College', value: 'Northern Sichuan Medical College' },
  { label: 'Northern State Medical University', value: 'Northern State Medical University' },
  { label: 'Northern State University', value: 'Northern State University' },
  { label: 'Northern University Bangladesh', value: 'Northern University Bangladesh' },
  { label: 'Northern Virginia Community College', value: 'Northern Virginia Community College' },
  { label: 'Northface University', value: 'Northface University' },
  { label: 'Northland College', value: 'Northland College' },
  { label: 'Northrise University', value: 'Northrise University' },
  { label: 'Northwest A&F University', value: 'Northwest A&F University' },
  { label: 'Northwest Christian College', value: 'Northwest Christian College' },
  { label: 'Northwest College of Art', value: 'Northwest College of Art' },
  { label: 'Northwest Missouri State University', value: 'Northwest Missouri State University' },
  { label: 'Northwest Nazarene University', value: 'Northwest Nazarene University' },
  { label: 'Northwest Normal University Lanzhou', value: 'Northwest Normal University Lanzhou' },
  { label: "Northwest Polytechnical University Xi'an", value: "Northwest Polytechnical University Xi'an" },
  { label: 'Northwest University', value: 'Northwest University' },
  { label: "Northwest University Xi'an", value: "Northwest University Xi'an" },
  { label: 'Northwestern College Iowa', value: 'Northwestern College Iowa' },
  { label: 'Northwestern College of Chiropractic', value: 'Northwestern College of Chiropractic' },
  { label: 'Northwestern College St. Paul', value: 'Northwestern College St. Paul' },
  { label: 'Northwestern Oklahoma State University', value: 'Northwestern Oklahoma State University' },
  { label: 'Northwestern State University of Louisiana', value: 'Northwestern State University of Louisiana' },
  { label: 'Northwestern University', value: 'Northwestern University' },
  { label: 'Northwestern University In Qatar', value: 'Northwestern University In Qatar' },
  { label: 'Northwestern University of the Philippines', value: 'Northwestern University of the Philippines' },
  { label: 'Northwood University', value: 'Northwood University' },
  { label: 'Northwood University, Florida Campus', value: 'Northwood University, Florida Campus' },
  { label: 'Northwood University, Texas Campus', value: 'Northwood University, Texas Campus' },
  { label: 'Norton University', value: 'Norton University' },
  { label: 'Nortre Dame Seishin University', value: 'Nortre Dame Seishin University' },
  {
    label: "Norway's Information Technology University College",
    value: "Norway's Information Technology University College",
  },
  { label: 'Norwegian College of Veterinary Medicine', value: 'Norwegian College of Veterinary Medicine' },
  {
    label: 'Norwegian School of Economics and Business Administration',
    value: 'Norwegian School of Economics and Business Administration',
  },
  { label: 'Norwegian School of Management BI', value: 'Norwegian School of Management BI' },
  { label: 'Norwegian State Academy of Music', value: 'Norwegian State Academy of Music' },
  {
    label: 'Norwegian Teacher Academy for Studies in Religion and Education',
    value: 'Norwegian Teacher Academy for Studies in Religion and Education',
  },
  { label: 'Norwegian University of Life Sciences', value: 'Norwegian University of Life Sciences' },
  { label: 'Norwegian University of Science and Technology', value: 'Norwegian University of Science and Technology' },
  {
    label: 'Norwegian University of Sport and Physical Education',
    value: 'Norwegian University of Sport and Physical Education',
  },
  { label: 'Norwich University', value: 'Norwich University' },
  { label: 'Notre Dame College', value: 'Notre Dame College' },
  { label: 'Notre Dame College of Ohio', value: 'Notre Dame College of Ohio' },
  { label: 'Notre Dame de Namur University', value: 'Notre Dame de Namur University' },
  { label: 'Notre Dame of Marbel University', value: 'Notre Dame of Marbel University' },
  { label: 'Notre Dame University', value: 'Notre Dame University' },
  { label: 'Notre Dame University', value: 'Notre Dame University' },
  { label: "Notre Dame Women's College", value: "Notre Dame Women's College" },
  { label: 'Nottingham Trent University', value: 'Nottingham Trent University' },
  { label: 'Nova Scotia Agricultural College', value: 'Nova Scotia Agricultural College' },
  { label: 'Nova Scotia College of Art and Design', value: 'Nova Scotia College of Art and Design' },
  { label: 'Nova Southeastern University', value: 'Nova Southeastern University' },
  { label: 'Novena University', value: 'Novena University' },
  { label: 'Novgorod State University', value: 'Novgorod State University' },
  {
    label: 'Novosibirsk State Academy of Economics and Mangement',
    value: 'Novosibirsk State Academy of Economics and Mangement',
  },
  { label: 'Novosibirsk State Agricultural University', value: 'Novosibirsk State Agricultural University' },
  { label: 'Novosibirsk State Medical Academy', value: 'Novosibirsk State Medical Academy' },
  { label: 'Novosibirsk State Music Academy M. Glinka', value: 'Novosibirsk State Music Academy M. Glinka' },
  { label: 'Novosibirsk State Technical University', value: 'Novosibirsk State Technical University' },
  { label: 'Novosibirsk State University', value: 'Novosibirsk State University' },
  {
    label: 'NTB Interstate University of Applied Sciences of Technology',
    value: 'NTB Interstate University of Applied Sciences of Technology',
  },
  { label: 'NTI University', value: 'NTI University' },
  {
    label: 'Nuclear Institute for Agriculture and Biology (NIAB)',
    value: 'Nuclear Institute for Agriculture and Biology (NIAB)',
  },
  { label: 'Nugaal University', value: 'Nugaal University' },
  { label: 'Nukus State Teachers Training Institute', value: 'Nukus State Teachers Training Institute' },
  { label: 'Nyack College', value: 'Nyack College' },
  { label: "O'More College of Design", value: "O'More College of Design" },
  { label: 'Oakland City University', value: 'Oakland City University' },
  { label: 'Oakland University', value: 'Oakland University' },
  { label: 'Oakton Community College', value: 'Oakton Community College' },
  { label: 'Oakwood College', value: 'Oakwood College' },
  { label: 'Obafemi Awolowo University Ile-Ife', value: 'Obafemi Awolowo University Ile-Ife' },
  { label: 'Oberlin College', value: 'Oberlin College' },
  {
    label: 'Obihiro University of Agriculture and Veterinary Medicine',
    value: 'Obihiro University of Agriculture and Veterinary Medicine',
  },
  { label: 'Obirin University', value: 'Obirin University' },
  {
    label: 'Obninsk State Technical University for Nuclear Power Engineering',
    value: 'Obninsk State Technical University for Nuclear Power Engineering',
  },
  { label: 'Obong University', value: 'Obong University' },
  { label: 'Occidental College', value: 'Occidental College' },
  { label: 'Ocean University of China', value: 'Ocean University of China' },
  { label: "Ochanomizu Women's University", value: "Ochanomizu Women's University" },
  { label: 'October 6 university', value: 'October 6 university' },
  { label: 'Odense University', value: 'Odense University' },
  { label: 'Odessa National Academy of Food Technologies', value: 'Odessa National Academy of Food Technologies' },
  { label: 'Odessa National I.I. Mecnikov University', value: 'Odessa National I.I. Mecnikov University' },
  { label: 'Odessa National Marine University', value: 'Odessa National Marine University' },
  { label: 'Odessa National Maritime Academy', value: 'Odessa National Maritime Academy' },
  { label: 'Odessa National Polytechnic University', value: 'Odessa National Polytechnic University' },
  {
    label: 'Odessa State Academy of Construction and Architecture',
    value: 'Odessa State Academy of Construction and Architecture',
  },
  { label: 'Odessa State Medical University', value: 'Odessa State Medical University' },
  { label: 'Odlar Yurdu University', value: 'Odlar Yurdu University' },
  { label: 'Oduduwa University', value: 'Oduduwa University' },
  { label: 'Oglala Lakota College', value: 'Oglala Lakota College' },
  { label: 'Oglethorpe University', value: 'Oglethorpe University' },
  { label: 'Ohio College of Podiatric Medicine', value: 'Ohio College of Podiatric Medicine' },
  { label: 'Ohio Dominican College', value: 'Ohio Dominican College' },
  { label: 'Ohio Northern University', value: 'Ohio Northern University' },
  { label: 'Ohio State University', value: 'Ohio State University' },
  { label: 'Ohio State University - Lima', value: 'Ohio State University - Lima' },
  { label: 'Ohio State University - Mansfield', value: 'Ohio State University - Mansfield' },
  { label: 'Ohio State University - Marion', value: 'Ohio State University - Marion' },
  { label: 'Ohio State University - Newark', value: 'Ohio State University - Newark' },
  { label: 'Ohio University', value: 'Ohio University' },
  { label: 'Ohio University - Chillicothe', value: 'Ohio University - Chillicothe' },
  { label: 'Ohio University - Eastern', value: 'Ohio University - Eastern' },
  { label: 'Ohio University - Lancaster', value: 'Ohio University - Lancaster' },
  { label: 'Ohio University - Southern', value: 'Ohio University - Southern' },
  { label: 'Ohio University - Zanesville', value: 'Ohio University - Zanesville' },
  { label: 'Ohio Valley College', value: 'Ohio Valley College' },
  { label: 'Ohio Wesleyan University', value: 'Ohio Wesleyan University' },
  { label: 'Ohka Gakuen University', value: 'Ohka Gakuen University' },
  { label: "Ohtani Women's University", value: "Ohtani Women's University" },
  { label: 'Ohu University', value: 'Ohu University' },
  { label: 'Oita Medical University', value: 'Oita Medical University' },
  { label: 'Oita University', value: 'Oita University' },
  { label: 'Oita University of Nursing and Health Sciences', value: 'Oita University of Nursing and Health Sciences' },
  { label: 'Okanagan University College', value: 'Okanagan University College' },
  { label: 'Okayama Prefectural University', value: 'Okayama Prefectural University' },
  { label: 'Okayama Shoka University', value: 'Okayama Shoka University' },
  { label: 'Okayama University', value: 'Okayama University' },
  { label: 'Okayama University of Science', value: 'Okayama University of Science' },
  { label: 'Okinawa International University', value: 'Okinawa International University' },
  { label: 'Okinawa Prefectural University of Fine Arts', value: 'Okinawa Prefectural University of Fine Arts' },
  { label: 'Okinawa University', value: 'Okinawa University' },
  { label: 'Oklahoma Baptist University', value: 'Oklahoma Baptist University' },
  { label: 'Oklahoma Christian University', value: 'Oklahoma Christian University' },
  { label: 'Oklahoma City University', value: 'Oklahoma City University' },
  { label: 'Oklahoma Panhandle State University', value: 'Oklahoma Panhandle State University' },
  { label: 'Oklahoma State University', value: 'Oklahoma State University' },
  {
    label: 'Oklahoma State University - Institute of Technology',
    value: 'Oklahoma State University - Institute of Technology',
  },
  { label: 'Oklahoma State University - Oklahoma City', value: 'Oklahoma State University - Oklahoma City' },
  { label: 'Oklahoma State University - Tulsa', value: 'Oklahoma State University - Tulsa' },
  {
    label: 'Oklahoma State University Center for Health Sciences',
    value: 'Oklahoma State University Center for Health Sciences',
  },
  { label: 'Olabisi Onabanjo University ', value: 'Olabisi Onabanjo University ' },
  { label: 'Old Dominion University', value: 'Old Dominion University' },
  { label: 'Olive-Harvey College', value: 'Olive-Harvey College' },
  { label: 'Olivet College', value: 'Olivet College' },
  { label: 'Olivet Nazarene University', value: 'Olivet Nazarene University' },
  { label: 'Olympia College', value: 'Olympia College' },
  { label: 'Oman College Of Management & Technology', value: 'Oman College Of Management & Technology' },
  { label: 'Omar Al-Mukhtar University', value: 'Omar Al-Mukhtar University' },
  { label: 'Omdurman Ahlia University', value: 'Omdurman Ahlia University' },
  { label: 'Omdurman Islamic University', value: 'Omdurman Islamic University' },
  { label: 'Omsk State Agrarian University', value: 'Omsk State Agrarian University' },
  { label: 'Omsk State Medicical Academy', value: 'Omsk State Medicical Academy' },
  { label: 'Omsk State Pedagogical University', value: 'Omsk State Pedagogical University' },
  { label: 'Omsk State Technical University', value: 'Omsk State Technical University' },
  { label: 'Omsk State Transport University', value: 'Omsk State Transport University' },
  { label: 'Omsk State University', value: 'Omsk State University' },
  {
    label: 'Ondo State University of Science and Technology',
    value: 'Ondo State University of Science and Technology',
  },
  { label: 'Ondokuz Mayis University Samsun', value: 'Ondokuz Mayis University Samsun' },
  { label: 'Ontario College of Art and Design', value: 'Ontario College of Art and Design' },
  {
    label: 'Open International University for Alternative Medicines',
    value: 'Open International University for Alternative Medicines',
  },
  { label: 'Open Polytechnic ', value: 'Open Polytechnic ' },
  { label: 'Open University', value: 'Open University' },
  { label: 'Open University Malaysia', value: 'Open University Malaysia' },
  { label: 'Open University of Cyprus', value: 'Open University of Cyprus' },
  { label: 'Open University of Hong Kong', value: 'Open University of Hong Kong' },
  { label: 'Open University of Israel', value: 'Open University of Israel' },
  { label: 'Open University of Sri Lanka', value: 'Open University of Sri Lanka' },
  { label: 'Open University of Sudan (OUS)', value: 'Open University of Sudan (OUS)' },
  { label: 'Open University of Tanzania', value: 'Open University of Tanzania' },
  { label: 'Open University of the Netherlands', value: 'Open University of the Netherlands' },
  { label: 'Oradea University', value: 'Oradea University' },
  { label: 'Oral Roberts University', value: 'Oral Roberts University' },
  { label: 'Örebro University', value: 'Örebro University' },
  { label: 'Oregon College of Arts and Crafts', value: 'Oregon College of Arts and Crafts' },
  {
    label: 'Oregon Graduate Institute of Science and Technology',
    value: 'Oregon Graduate Institute of Science and Technology',
  },
  { label: 'Oregon Health Sciences University', value: 'Oregon Health Sciences University' },
  { label: 'Oregon Institute of Technology', value: 'Oregon Institute of Technology' },
  { label: 'Oregon State University', value: 'Oregon State University' },
  { label: 'Orenburg State University', value: 'Orenburg State University' },
  { label: 'Oriental University', value: 'Oriental University' },
  {
    label: 'Orissa University of Agriculture and Technology',
    value: 'Orissa University of Agriculture and Technology',
  },
  { label: 'Orkhon University', value: 'Orkhon University' },
  { label: 'Osaka City University', value: 'Osaka City University' },
  { label: 'Osaka College of Music', value: 'Osaka College of Music' },
  { label: 'Osaka Dental University', value: 'Osaka Dental University' },
  { label: 'Osaka Electro-Communication University', value: 'Osaka Electro-Communication University' },
  { label: 'Osaka Gakuin University', value: 'Osaka Gakuin University' },
  { label: 'Osaka Institute of Technology', value: 'Osaka Institute of Technology' },
  { label: 'Osaka International University', value: 'Osaka International University' },
  { label: 'Osaka International University for Women', value: 'Osaka International University for Women' },
  { label: 'Osaka Medical College', value: 'Osaka Medical College' },
  { label: 'Osaka Prefectural University', value: 'Osaka Prefectural University' },
  { label: 'Osaka Sangyo University', value: 'Osaka Sangyo University' },
  { label: "Osaka Shoin Women's College", value: "Osaka Shoin Women's College" },
  { label: 'Osaka University', value: 'Osaka University' },
  { label: 'Osaka University of Arts', value: 'Osaka University of Arts' },
  { label: 'Osaka University of Commerce', value: 'Osaka University of Commerce' },
  { label: 'Osaka University of Economics', value: 'Osaka University of Economics' },
  { label: 'Osaka University of Economics & Law', value: 'Osaka University of Economics & Law' },
  { label: 'Osaka University of Education', value: 'Osaka University of Education' },
  { label: 'Osaka University of Foreign Studies', value: 'Osaka University of Foreign Studies' },
  { label: 'Osaka University of Health and Sport Sciences', value: 'Osaka University of Health and Sport Sciences' },
  { label: 'Osaka University of Pharmaceutical Sciences', value: 'Osaka University of Pharmaceutical Sciences' },
  { label: "Osaka Women's University", value: "Osaka Women's University" },
  { label: 'Oslo School of Architecture', value: 'Oslo School of Architecture' },
  { label: 'Osmangazi University', value: 'Osmangazi University' },
  { label: 'Osmania University', value: 'Osmania University' },
  { label: 'Osun State University', value: 'Osun State University' },
  { label: 'Otani University', value: 'Otani University' },
  { label: 'Otaru University of Commerce', value: 'Otaru University of Commerce' },
  { label: 'Otemae University', value: 'Otemae University' },
  { label: 'Otemon Gakuin University', value: 'Otemon Gakuin University' },
  { label: 'Otis College of Art & Design', value: 'Otis College of Art & Design' },
  { label: "Otsuma Women's University", value: "Otsuma Women's University" },
  { label: 'Ottawa University', value: 'Ottawa University' },
  { label: 'Otterbein College', value: 'Otterbein College' },
  { label: 'Otto-Friedrich Universität Bamberg', value: 'Otto-Friedrich Universität Bamberg' },
  { label: 'Otto-von-Guericke Universität Magdeburg', value: 'Otto-von-Guericke Universität Magdeburg' },
  { label: 'Ouachita Baptist University', value: 'Ouachita Baptist University' },
  { label: 'Oulu Polytechnic', value: 'Oulu Polytechnic' },
  { label: 'Our Lady of Fatima University', value: 'Our Lady of Fatima University' },
  { label: 'Our Lady of Holy Cross College', value: 'Our Lady of Holy Cross College' },
  { label: 'Our Lady of the Lake University', value: 'Our Lady of the Lake University' },
  { label: 'Oxford Brookes University', value: 'Oxford Brookes University' },
  { label: 'Ozark Christian College', value: 'Ozark Christian College' },
  { label: 'Ozyegin University', value: 'Ozyegin University' },
  { label: 'Pabna University of Science and Technology', value: 'Pabna University of Science and Technology' },
  { label: 'Pace University', value: 'Pace University' },
  { label: 'Pace University  Pleasantville/Briarcliff', value: 'Pace University  Pleasantville/Briarcliff' },
  { label: 'Pacific Adventist University', value: 'Pacific Adventist University' },
  { label: 'Pacific College of Oriental Medicine', value: 'Pacific College of Oriental Medicine' },
  { label: 'Pacific Graduate School of Psychology', value: 'Pacific Graduate School of Psychology' },
  { label: 'Pacific International College', value: 'Pacific International College' },
  { label: 'Pacific Lutheran University', value: 'Pacific Lutheran University' },
  { label: 'Pacific Northwest College of Art', value: 'Pacific Northwest College of Art' },
  { label: 'Pacific Oaks College', value: 'Pacific Oaks College' },
  { label: 'Pacific Union College', value: 'Pacific Union College' },
  { label: 'Pacific University', value: 'Pacific University' },
  { label: 'Pacifica Graduate Institute', value: 'Pacifica Graduate Institute' },
  { label: 'Pädagogische Hochschule Erfurt/Mühlhausen', value: 'Pädagogische Hochschule Erfurt/Mühlhausen' },
  { label: 'Pädagogische Hochschule Freiburg', value: 'Pädagogische Hochschule Freiburg' },
  { label: 'Pädagogische Hochschule Heidelberg', value: 'Pädagogische Hochschule Heidelberg' },
  { label: 'Pädagogische Hochschule Karlsruhe', value: 'Pädagogische Hochschule Karlsruhe' },
  { label: 'Pädagogische Hochschule Ludwigsburg', value: 'Pädagogische Hochschule Ludwigsburg' },
  { label: 'Pädagogische Hochschule Schwäbisch Gmünd', value: 'Pädagogische Hochschule Schwäbisch Gmünd' },
  { label: 'Pädagogische Hochschule Weingarten', value: 'Pädagogische Hochschule Weingarten' },
  {
    label: 'Pädagogische Hochschule Zürich (Zurich School of Education)',
    value: 'Pädagogische Hochschule Zürich (Zurich School of Education)',
  },
  { label: 'Paichai University', value: 'Paichai University' },
  { label: 'Paier College of Art', value: 'Paier College of Art' },
  { label: 'Paine College', value: 'Paine College' },
  {
    label: 'Pak-AIMS (American Institute of Management Sciences)',
    value: 'Pak-AIMS (American Institute of Management Sciences)',
  },
  {
    label: 'Pakistan Institute of Engineering and Applied Sciences (PIEAS)',
    value: 'Pakistan Institute of Engineering and Applied Sciences (PIEAS)',
  },
  { label: 'Pakistan Virtual University', value: 'Pakistan Virtual University' },
  { label: 'Paktia University', value: 'Paktia University' },
  { label: 'Palacky University', value: 'Palacky University' },
  { label: 'Palawan State University', value: 'Palawan State University' },
  { label: 'Palestine Polytechnic University', value: 'Palestine Polytechnic University' },
  { label: 'Palestine Technical College-Dier Elbalah', value: 'Palestine Technical College-Dier Elbalah' },
  { label: 'Palestine Technical University - Kadoorie', value: 'Palestine Technical University - Kadoorie' },
  { label: 'Palm Beach Atlantic University', value: 'Palm Beach Atlantic University' },
  { label: 'Palm Beach State College', value: 'Palm Beach State College' },
  { label: 'Palmer College of Chiropractic', value: 'Palmer College of Chiropractic' },
  { label: 'Palmer College of Chiropractic West', value: 'Palmer College of Chiropractic West' },
  { label: 'Pamir University', value: 'Pamir University' },
  { label: 'Pamukkale University', value: 'Pamukkale University' },
  { label: 'Pan Africa Christian University', value: 'Pan Africa Christian University' },
  { label: 'Pan African Christian University College', value: 'Pan African Christian University College' },
  { label: 'Pan-African University', value: 'Pan-African University' },
  { label: 'Pandit Ravi Shankar Shukla University', value: 'Pandit Ravi Shankar Shukla University' },
  { label: 'Pangasinan State University', value: 'Pangasinan State University' },
  { label: 'Panjab University', value: 'Panjab University' },
  { label: 'Pannnasatra University of Cambodia', value: 'Pannnasatra University of Cambodia' },
  {
    label: 'Panteios University of Economics and Political Sciences Athens',
    value: 'Panteios University of Economics and Political Sciences Athens',
  },
  { label: 'Panzhihua University', value: 'Panzhihua University' },
  { label: 'Papua New Guinea University of Technology', value: 'Papua New Guinea University of Technology' },
  { label: 'Paris-Sorbonne University Abu Dhabi', value: 'Paris-Sorbonne University Abu Dhabi' },
  { label: 'Park College', value: 'Park College' },
  { label: 'Parsons School of Design', value: 'Parsons School of Design' },
  { label: 'Parwan University', value: 'Parwan University' },
  { label: 'Pathumthani University', value: 'Pathumthani University' },
  { label: 'Patna University', value: 'Patna University' },
  { label: 'Patuakhali Science and Technology University', value: 'Patuakhali Science and Technology University' },
  { label: 'Paul Quinn College', value: 'Paul Quinn College' },
  { label: 'Paul University', value: 'Paul University' },
  { label: 'Pavlodar University', value: 'Pavlodar University' },
  { label: 'Pavol Jozef Safarik University in Kosice', value: 'Pavol Jozef Safarik University in Kosice' },
  { label: 'Pawel Wlodkowic University College in Plock', value: 'Pawel Wlodkowic University College in Plock' },
  { label: 'Payame Noor University', value: 'Payame Noor University' },
  { label: 'Payame Noor University, Tafresh', value: 'Payame Noor University, Tafresh' },
  { label: 'Payap University Chaiang Mai', value: 'Payap University Chaiang Mai' },
  { label: 'Pázmány Péter Catholic University', value: 'Pázmány Péter Catholic University' },
  { label: 'Peace College', value: 'Peace College' },
  { label: 'Pebble Hills University', value: 'Pebble Hills University' },
  { label: 'Pedagogical State University Ion Creanga', value: 'Pedagogical State University Ion Creanga' },
  { label: 'Pedagogical University of Bydgoszcz', value: 'Pedagogical University of Bydgoszcz' },
  { label: 'Pedagogical University of Czestochowa', value: 'Pedagogical University of Czestochowa' },
  { label: 'Pedagogical University of Kielce', value: 'Pedagogical University of Kielce' },
  { label: 'Pedagogical University of Krakow', value: 'Pedagogical University of Krakow' },
  { label: 'Pedagogical University of Rzeszow', value: 'Pedagogical University of Rzeszow' },
  { label: 'Pedagogical University of Slupsk', value: 'Pedagogical University of Slupsk' },
  {
    label: 'Pedagogical University of the Polish Association for Adult Education in Warsaw',
    value: 'Pedagogical University of the Polish Association for Adult Education in Warsaw',
  },
  { label: 'Pedagogical University of Zielona Gora', value: 'Pedagogical University of Zielona Gora' },
  { label: 'Peking University', value: 'Peking University' },
  { label: 'Penang International Dental College', value: 'Penang International Dental College' },
  { label: 'Pennsylvania Academy of the Fine Arts', value: 'Pennsylvania Academy of the Fine Arts' },
  { label: 'Pennsylvania College of Optometry', value: 'Pennsylvania College of Optometry' },
  { label: 'Pennsylvania Institute of Technology', value: 'Pennsylvania Institute of Technology' },
  { label: 'Pennsylvania State University', value: 'Pennsylvania State University' },
  { label: 'Pennsylvania State University - Abington', value: 'Pennsylvania State University - Abington' },
  { label: 'Pennsylvania State University - Altoona', value: 'Pennsylvania State University - Altoona' },
  {
    label: 'Pennsylvania State University - Berks-Lehigh Valley College',
    value: 'Pennsylvania State University - Berks-Lehigh Valley College',
  },
  { label: 'Pennsylvania State University - Lehigh Valley', value: 'Pennsylvania State University - Lehigh Valley' },
  {
    label: 'Pennsylvania State University - Milton S.Hershey Medical Center',
    value: 'Pennsylvania State University - Milton S.Hershey Medical Center',
  },
  { label: 'Pennsylvania State University - Schuylkill', value: 'Pennsylvania State University - Schuylkill' },
  {
    label: 'Pennsylvania State University at Erie - Behrend College',
    value: 'Pennsylvania State University at Erie - Behrend College',
  },
  {
    label: 'Pennsylvania State University at Harrisburg - The Capital College',
    value: 'Pennsylvania State University at Harrisburg - The Capital College',
  },
  { label: 'Pennsylvania State University Delaware County', value: 'Pennsylvania State University Delaware County' },
  { label: 'Pennsylvania State University Great Valley', value: 'Pennsylvania State University Great Valley' },
  { label: 'Penza State University', value: 'Penza State University' },
  { label: "People' s Friendship University of Russia", value: "People' s Friendship University of Russia" },
  { label: "People's University of Bangladesh", value: "People's University of Bangladesh" },
  { label: 'Pepperdine University', value: 'Pepperdine University' },
  { label: 'Perak Islamic College', value: 'Perak Islamic College' },
  { label: 'Perdana University', value: 'Perdana University' },
  { label: 'Periyar University', value: 'Periyar University' },
  { label: 'Perlis Islamic Higher Learning Institute', value: 'Perlis Islamic Higher Learning Institute' },
  { label: 'Perm State Academy of Agriculture', value: 'Perm State Academy of Agriculture' },
  { label: 'Perm State Medical Academy', value: 'Perm State Medical Academy' },
  { label: 'Perm State Pedagogical University', value: 'Perm State Pedagogical University' },
  { label: 'Perm State Pharmaceutical Academy', value: 'Perm State Pharmaceutical Academy' },
  { label: 'Perm State Technical University', value: 'Perm State Technical University' },
  { label: 'Perm State University', value: 'Perm State University' },
  { label: 'Persian Gulf University', value: 'Persian Gulf University' },
  { label: 'Peru State College', value: 'Peru State College' },
  { label: 'Pes University', value: 'Pes University' },
  { label: 'Petro Mohyla Black Sea State University', value: 'Petro Mohyla Black Sea State University' },
  { label: 'Petroleum University of Technology', value: 'Petroleum University of Technology' },
  { label: 'Petronas Technology University', value: 'Petronas Technology University' },
  { label: 'Petrozavodsk State University', value: 'Petrozavodsk State University' },
  { label: 'Petru Maior University of Targu Mures', value: 'Petru Maior University of Targu Mures' },
  { label: 'Pfeiffer University', value: 'Pfeiffer University' },
  { label: 'Pharos International University', value: 'Pharos International University' },
  { label: 'Philadelphia College of Bible', value: 'Philadelphia College of Bible' },
  { label: 'Philadelphia College of Osteopathic Medicine', value: 'Philadelphia College of Osteopathic Medicine' },
  { label: 'Philadelphia University', value: 'Philadelphia University' },
  { label: 'Philadelphia University', value: 'Philadelphia University' },
  { label: 'Philander Smith College', value: 'Philander Smith College' },
  { label: 'Philippine Christian University', value: 'Philippine Christian University' },
  { label: 'Philippine Military Academy', value: 'Philippine Military Academy' },
  { label: 'Philippine Normal University', value: 'Philippine Normal University' },
  { label: "Philippine Women's University", value: "Philippine Women's University" },
  { label: 'Phillips Graduate Institute', value: 'Phillips Graduate Institute' },
  { label: 'Phillips University', value: 'Phillips University' },
  { label: 'Phillips-Universität Marburg', value: 'Phillips-Universität Marburg' },
  {
    label: 'Philosophisch-Theologische Hochschule der Salesianer Don Boscos',
    value: 'Philosophisch-Theologische Hochschule der Salesianer Don Boscos',
  },
  { label: 'Philosophisch-Theologische Hochschule Münster', value: 'Philosophisch-Theologische Hochschule Münster' },
  {
    label: 'Philosophisch-Theologische Hochschule Sankt Georgen',
    value: 'Philosophisch-Theologische Hochschule Sankt Georgen',
  },
  {
    label: 'Philosophisch-Theologische Hochschule SVD Sankt Augustin',
    value: 'Philosophisch-Theologische Hochschule SVD Sankt Augustin',
  },
  {
    label:
      'Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)',
    value:
      'Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)',
  },
  {
    label: 'Philosophisch-Theologisches Studium Erfurt, Staatlich anerkannte Wissenschaftliche Hochschule',
    value: 'Philosophisch-Theologisches Studium Erfurt, Staatlich anerkannte Wissenschaftliche Hochschule',
  },
  { label: 'Phranakhon Si Ayutthaya Rajabhat University', value: 'Phranakhon Si Ayutthaya Rajabhat University' },
  {
    label: 'Physical Education Academy Eugeniusz Piasecki in Poznan',
    value: 'Physical Education Academy Eugeniusz Piasecki in Poznan',
  },
  { label: 'Physical Education Academy in Katowice', value: 'Physical Education Academy in Katowice' },
  { label: 'Physical Education Academy in Wroclaw', value: 'Physical Education Academy in Wroclaw' },
  {
    label: 'Physical Education Academy Jedrzej Sniadecki in Gdansk',
    value: 'Physical Education Academy Jedrzej Sniadecki in Gdansk',
  },
  {
    label: 'Physical Education Academy Jozef Pilsudski in Warsaw',
    value: 'Physical Education Academy Jozef Pilsudski in Warsaw',
  },
  { label: 'Piedmont Baptist College', value: 'Piedmont Baptist College' },
  { label: 'Piedmont College', value: 'Piedmont College' },
  { label: 'Pikeville College', value: 'Pikeville College' },
  { label: 'Pillsbury Baptist Bible College', value: 'Pillsbury Baptist Bible College' },
  { label: 'Pioneer International University', value: 'Pioneer International University' },
  { label: 'Pittsburg State University', value: 'Pittsburg State University' },
  { label: 'Pitzer College', value: 'Pitzer College' },
  { label: 'Pjatigorsk State Linguistic University', value: 'Pjatigorsk State Linguistic University' },
  { label: 'Planwel University', value: 'Planwel University' },
  { label: 'Plekhanov Russian Academy of Economics', value: 'Plekhanov Russian Academy of Economics' },
  { label: 'Plymouth State College', value: 'Plymouth State College' },
  { label: 'Pohang University of Science and Technology', value: 'Pohang University of Science and Technology' },
  { label: 'Point Loma Nazarene College', value: 'Point Loma Nazarene College' },
  { label: 'Point Park College', value: 'Point Park College' },
  { label: 'Pokhara University', value: 'Pokhara University' },
  { label: 'Police Academy', value: 'Police Academy' },
  { label: 'Police Academy in Bratislava', value: 'Police Academy in Bratislava' },
  { label: 'Police Academy of Latvia', value: 'Police Academy of Latvia' },
  { label: 'Polish Open University in Warsaw (WSZ/POU)', value: 'Polish Open University in Warsaw (WSZ/POU)' },
  {
    label: 'Polish-Japanese Institute of Information Technology in Warsaw',
    value: 'Polish-Japanese Institute of Information Technology in Warsaw',
  },
  { label: 'Politécnico Columbiano Jaime Isaza Cadavid', value: 'Politécnico Columbiano Jaime Isaza Cadavid' },
  { label: 'Politécnico de Guanajuato', value: 'Politécnico de Guanajuato' },
  {
    label: 'Politécnico Grancolombiano - Institución Universitaria',
    value: 'Politécnico Grancolombiano - Institución Universitaria',
  },
  { label: 'Politeknik Negeri Bandung', value: 'Politeknik Negeri Bandung' },
  { label: 'Politeknik Negeri Jakarta', value: 'Politeknik Negeri Jakarta' },
  { label: 'Politeknik Negeri Lhokseumawe', value: 'Politeknik Negeri Lhokseumawe' },
  { label: 'Politeknik Negeri Malang', value: 'Politeknik Negeri Malang' },
  { label: 'Politeknik Negeri Padang', value: 'Politeknik Negeri Padang' },
  { label: 'Politeknik Negeri Pontianak', value: 'Politeknik Negeri Pontianak' },
  { label: 'Politeknik Negeri Sambas', value: 'Politeknik Negeri Sambas' },
  { label: 'Politeknik Negeri Semarang', value: 'Politeknik Negeri Semarang' },
  { label: 'Politeknik Pos Indonesia', value: 'Politeknik Pos Indonesia' },
  { label: 'Polonia University in Czêstochowa', value: 'Polonia University in Czêstochowa' },
  { label: 'Polotsk State University', value: 'Polotsk State University' },
  { label: 'Poltava National Technical University', value: 'Poltava National Technical University' },
  {
    label: 'Poltava University of Consumer Cooperatives in Ukraine',
    value: 'Poltava University of Consumer Cooperatives in Ukraine',
  },
  { label: 'Polytechnic Ibadan', value: 'Polytechnic Ibadan' },
  { label: 'Polytechnic Institute of Bari', value: 'Polytechnic Institute of Bari' },
  { label: 'Polytechnic Institute of Milan', value: 'Polytechnic Institute of Milan' },
  { label: 'Polytechnic Institute of Turin', value: 'Polytechnic Institute of Turin' },
  { label: 'Polytechnic of Namibia', value: 'Polytechnic of Namibia' },
  { label: 'Polytechnic University', value: 'Polytechnic University' },
  { label: 'Polytechnic University', value: 'Polytechnic University' },
  { label: 'Polytechnic University of the Philippines', value: 'Polytechnic University of the Philippines' },
  { label: 'Polytechnic University of Tirane', value: 'Polytechnic University of Tirane' },
  { label: 'Polytechnic University, Long Island Campus', value: 'Polytechnic University, Long Island Campus' },
  {
    label: 'Polytechnic University, Westchester Graduate Center',
    value: 'Polytechnic University, Westchester Graduate Center',
  },
  { label: 'Polytechnical University of Kabul', value: 'Polytechnical University of Kabul' },
  { label: 'Pomeranian Academy of Medicine in Szczecin', value: 'Pomeranian Academy of Medicine in Szczecin' },
  { label: 'Pomona College', value: 'Pomona College' },
  { label: 'Pomor State University', value: 'Pomor State University' },
  { label: 'Pomorsk State University', value: 'Pomorsk State University' },
  { label: 'Pondicherry University', value: 'Pondicherry University' },
  { label: 'Pontifcia Università Gregoriana', value: 'Pontifcia Università Gregoriana' },
  { label: 'Pontifcia Università Urbaniana', value: 'Pontifcia Università Urbaniana' },
  {
    label: 'Pontifcia Universitas a S.Thomas Aquinate in Urbe',
    value: 'Pontifcia Universitas a S.Thomas Aquinate in Urbe',
  },
  { label: 'Pontifcia Universitas Lateranensis', value: 'Pontifcia Universitas Lateranensis' },
  { label: 'Pontifica Università Gregoriana', value: 'Pontifica Università Gregoriana' },
  { label: 'Pontificia Universidad Catolica de Chile', value: 'Pontificia Universidad Catolica de Chile' },
  { label: 'Pontificia Universidad Catolica de Puerto Rico', value: 'Pontificia Universidad Catolica de Puerto Rico' },
  { label: 'Pontificia Universidad Católica del Ecuador', value: 'Pontificia Universidad Católica del Ecuador' },
  { label: 'Pontificia Universidad Católica del Perú', value: 'Pontificia Universidad Católica del Perú' },
  {
    label: 'Pontificia Universidad Católica Madre y Maestra (PUCMM)',
    value: 'Pontificia Universidad Católica Madre y Maestra (PUCMM)',
  },
  { label: 'Pontificia Universidad Javeriana', value: 'Pontificia Universidad Javeriana' },
  { label: 'Pontifícia Universidade Católica de Campinas', value: 'Pontifícia Universidade Católica de Campinas' },
  {
    label: 'Pontifícia Universidade Católica de Minas Gerais',
    value: 'Pontifícia Universidade Católica de Minas Gerais',
  },
  { label: 'Pontifícia Universidade Católica de São Paulo', value: 'Pontifícia Universidade Católica de São Paulo' },
  { label: 'Pontificia Universidade Católica do Paraná', value: 'Pontificia Universidade Católica do Paraná' },
  {
    label: 'Pontifícia Universidade Católica do Rio de Janeiro',
    value: 'Pontifícia Universidade Católica do Rio de Janeiro',
  },
  {
    label: 'Pontifícia Universidade Católica do Rio Grande do Sul',
    value: 'Pontifícia Universidade Católica do Rio Grande do Sul',
  },
  { label: 'Pontificia Università della Santa Croce', value: 'Pontificia Università della Santa Croce' },
  { label: 'Pontificia Università Lateranense', value: 'Pontificia Università Lateranense' },
  { label: 'Pontificia Università S. Tommaso', value: 'Pontificia Università S. Tommaso' },
  { label: 'Pontificio Ateneo Antonianum', value: 'Pontificio Ateneo Antonianum' },
  { label: 'Pontificio Istituto Orientale', value: 'Pontificio Istituto Orientale' },
  { label: 'Poole Gakuin University', value: 'Poole Gakuin University' },
  { label: 'Popakademie Baden-Württemberg', value: 'Popakademie Baden-Württemberg' },
  { label: 'Port Dickson Polytechnic', value: 'Port Dickson Polytechnic' },
  { label: 'Port Said University', value: 'Port Said University' },
  { label: 'Portland Community College', value: 'Portland Community College' },
  { label: 'Portland State University', value: 'Portland State University' },
  { label: 'Post University of Waterbury', value: 'Post University of Waterbury' },
  { label: 'Postgraduate Institute of Agriculture (PGIA)', value: 'Postgraduate Institute of Agriculture (PGIA)' },
  {
    label: 'Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)',
    value: 'Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)',
  },
  {
    label: 'Postgraduate lnstitute of Medical Education and Research',
    value: 'Postgraduate lnstitute of Medical Education and Research',
  },
  {
    label: 'Posts & Telecommunications Institute of Technology',
    value: 'Posts & Telecommunications Institute of Technology',
  },
  { label: 'Potti Sreeramulu Telugu University', value: 'Potti Sreeramulu Telugu University' },
  { label: 'Power and Water Institute of Technology', value: 'Power and Water Institute of Technology' },
  { label: 'Poznan School of Banking', value: 'Poznan School of Banking' },
  { label: 'Poznan University of Life Sciences', value: 'Poznan University of Life Sciences' },
  { label: 'Prague College', value: 'Prague College' },
  { label: 'Prague Institute of Chemical Technology', value: 'Prague Institute of Chemical Technology' },
  { label: 'Prague International University', value: 'Prague International University' },
  {
    label: 'Prairie View Agricultural and Mechanical University',
    value: 'Prairie View Agricultural and Mechanical University',
  },
  { label: 'Prasetiya Mulya Business School', value: 'Prasetiya Mulya Business School' },
  { label: 'Pratt Institute', value: 'Pratt Institute' },
  { label: 'Precarpathian University', value: 'Precarpathian University' },
  { label: 'Premier University', value: 'Premier University' },
  { label: 'Prempeh College', value: 'Prempeh College' },
  { label: 'Presbyterian College', value: 'Presbyterian College' },
  { label: 'Presbyterian University College', value: 'Presbyterian University College' },
  { label: 'Presbyterian University of East Africa', value: 'Presbyterian University of East Africa' },
  { label: 'Prescott College', value: 'Prescott College' },
  { label: 'Presidency University', value: 'Presidency University' },
  {
    label: 'Preston Institute of Management Sciences and Technology (PIMSAT)',
    value: 'Preston Institute of Management Sciences and Technology (PIMSAT)',
  },
  { label: 'Preston University', value: 'Preston University' },
  { label: 'Preston University, Pakistan Campus', value: 'Preston University, Pakistan Campus' },
  { label: 'Primier International University Perak', value: 'Primier International University Perak' },
  { label: 'Prince Mohammad Bin Fahd University', value: 'Prince Mohammad Bin Fahd University' },
  { label: 'Prince of Songkla University', value: 'Prince of Songkla University' },
  {
    label: 'Prince Sultan College for Tourism and Hotel Scinces',
    value: 'Prince Sultan College for Tourism and Hotel Scinces',
  },
  { label: 'Prince Sultan University', value: 'Prince Sultan University' },
  { label: 'Princess Nora Bint Abdulrahman University', value: 'Princess Nora Bint Abdulrahman University' },
  { label: 'Princess Sumaya University for Technology', value: 'Princess Sumaya University for Technology' },
  { label: 'Princeton University', value: 'Princeton University' },
  { label: 'Principia College', value: 'Principia College' },
  { label: 'Private College of Dentistry and Pharmacy', value: 'Private College of Dentistry and Pharmacy' },
  {
    label: 'Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz',
    value: 'Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz',
  },
  { label: 'Private Fachhochschule Göttingen', value: 'Private Fachhochschule Göttingen' },
  { label: 'Private FernFachhochschule Darmstadt', value: 'Private FernFachhochschule Darmstadt' },
  { label: 'Private Hanseuniversität', value: 'Private Hanseuniversität' },
  {
    label: 'Private Universität für Medizinische Informatik und Technik Tirol',
    value: 'Private Universität für Medizinische Informatik und Technik Tirol',
  },
  { label: 'Private Universität Witten/Herdecke', value: 'Private Universität Witten/Herdecke' },
  { label: 'Privredna Akademija (Business Academy)', value: 'Privredna Akademija (Business Academy)' },
  { label: 'Providence College', value: 'Providence College' },
  { label: 'Providence University', value: 'Providence University' },
  { label: 'PSG College of Technology', value: 'PSG College of Technology' },
  { label: 'PTPL College', value: 'PTPL College' },
  { label: 'PTPL College', value: 'PTPL College' },
  { label: 'Puget Sound Christian College', value: 'Puget Sound Christian College' },
  { label: 'Pukyong National University', value: 'Pukyong National University' },
  { label: 'Punjab Agricultural University', value: 'Punjab Agricultural University' },
  { label: 'Punjab Engineering College Deemed University', value: 'Punjab Engineering College Deemed University' },
  { label: 'Punjab Institute of Computer science', value: 'Punjab Institute of Computer science' },
  { label: 'Punjab Technical University', value: 'Punjab Technical University' },
  {
    label: 'Punjab University College of Information Tecnology',
    value: 'Punjab University College of Information Tecnology',
  },
  { label: 'Punjabi University Patiala', value: 'Punjabi University Patiala' },
  { label: 'Puntland State University', value: 'Puntland State University' },
  { label: 'Purbanchal University', value: 'Purbanchal University' },
  { label: 'Purdue University', value: 'Purdue University' },
  { label: 'Purdue University Calumet', value: 'Purdue University Calumet' },
  { label: 'Purdue University North Central', value: 'Purdue University North Central' },
  { label: 'Pusan National University', value: 'Pusan National University' },
  { label: 'Pusan National University of Education', value: 'Pusan National University of Education' },
  { label: 'Pusan University of Foreign Studies', value: 'Pusan University of Foreign Studies' },
  { label: "Pusan Women's University", value: "Pusan Women's University" },
  { label: 'Pwani University', value: 'Pwani University' },
  { label: 'Pyongtaek University ', value: 'Pyongtaek University ' },
  { label: 'Pyongyang University of Science and Technology', value: 'Pyongyang University of Science and Technology' },
  { label: 'Pyramid Education Center', value: 'Pyramid Education Center' },
  { label: 'Qafqaz University', value: 'Qafqaz University' },
  { label: 'Qassim Private College', value: 'Qassim Private College' },
  { label: 'Qassim University', value: 'Qassim University' },
  {
    label: 'Qauid-e-Awam University of Engineering Sciences & Technology',
    value: 'Qauid-e-Awam University of Engineering Sciences & Technology',
  },
  { label: 'Qazvin University of Medical Sciences', value: 'Qazvin University of Medical Sciences' },
  { label: 'Qingdao University', value: 'Qingdao University' },
  { label: 'Qingdao University of Science and Technology', value: 'Qingdao University of Science and Technology' },
  { label: 'Qinghai Normal University', value: 'Qinghai Normal University' },
  { label: 'Qinghai Radio & Television University', value: 'Qinghai Radio & Television University' },
  { label: 'Qinghai University', value: 'Qinghai University' },
  { label: 'Qiongzhou University', value: 'Qiongzhou University' },
  { label: 'Qom University', value: 'Qom University' },
  { label: 'Qom University of Medical Sciences', value: 'Qom University of Medical Sciences' },
  { label: 'Qom University of Technology', value: 'Qom University of Technology' },
  { label: 'Quaid-i-Azam University', value: 'Quaid-i-Azam University' },
  { label: 'Quantum-Veritas International University', value: 'Quantum-Veritas International University' },
  { label: 'Queen Arwa University', value: 'Queen Arwa University' },
  {
    label: 'Queen Mary and Westfield College, University of London',
    value: 'Queen Mary and Westfield College, University of London',
  },
  { label: "Queen's University", value: "Queen's University" },
  { label: 'Queens College', value: 'Queens College' },
  { label: 'Queens University', value: 'Queens University' },
  { label: 'Queensland University of Technology', value: 'Queensland University of Technology' },
  { label: 'Quest University', value: 'Quest University' },
  { label: 'Qufu Normal University', value: 'Qufu Normal University' },
  { label: 'Quincy University', value: 'Quincy University' },
  { label: 'Quinnipiac College', value: 'Quinnipiac College' },
  {
    label: 'Qurtuba University of Science and Infromation Technology',
    value: 'Qurtuba University of Science and Infromation Technology',
  },
  { label: 'Rabindra Bharati University', value: 'Rabindra Bharati University' },
  { label: 'Radboud University of Nijmegen', value: 'Radboud University of Nijmegen' },
  { label: 'Radford University', value: 'Radford University' },
  { label: 'Raffles University', value: 'Raffles University' },
  { label: 'Rafsanjan University of Medical Sciences', value: 'Rafsanjan University of Medical Sciences' },
  { label: 'Raghebe Esfahani University', value: 'Raghebe Esfahani University' },
  {
    label: 'Rajamangala University of Technology, Lanna Chiang Rai',
    value: 'Rajamangala University of Technology, Lanna Chiang Rai',
  },
  {
    label: 'Rajamangala University of Technology, Lanna Nan',
    value: 'Rajamangala University of Technology, Lanna Nan',
  },
  {
    label: 'Rajamangala University of Technology, Phra Nakhon',
    value: 'Rajamangala University of Technology, Phra Nakhon',
  },
  { label: 'Rajarata University of Sri Lanka', value: 'Rajarata University of Sri Lanka' },
  { label: 'Rajasthan Agricultural University, Bikaner', value: 'Rajasthan Agricultural University, Bikaner' },
  { label: 'Rajasthan Technical University', value: 'Rajasthan Technical University' },
  { label: 'Rajasthan Vidyapeeth University', value: 'Rajasthan Vidyapeeth University' },
  { label: 'Rajendra Agricultural University', value: 'Rajendra Agricultural University' },
  {
    label: 'Rajitlal Institute of Technology & Health Sciences (RITHS)',
    value: 'Rajitlal Institute of Technology & Health Sciences (RITHS)',
  },
  {
    label: 'Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)',
    value: 'Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)',
  },
  { label: 'Rajiv Gandhi University', value: 'Rajiv Gandhi University' },
  {
    label: 'Rajshahi University of Engineering and Technology',
    value: 'Rajshahi University of Engineering and Technology',
  },
  { label: 'Rak Medical & Health Sciences University', value: 'Rak Medical & Health Sciences University' },
  { label: 'Rakuno Gakuen University', value: 'Rakuno Gakuen University' },
  { label: 'Ramapo College of New Jersey', value: 'Ramapo College of New Jersey' },
  { label: 'Ramkhamhaeng University', value: 'Ramkhamhaeng University' },
  { label: 'Rana Institute of Higher Education', value: 'Rana Institute of Higher Education' },
  { label: 'Ranchi University', value: 'Ranchi University' },
  { label: 'Rand Graduate School of Policy Studies', value: 'Rand Graduate School of Policy Studies' },
  { label: 'Randolph-Macon College', value: 'Randolph-Macon College' },
  { label: "Randolph-Macon Woman's College", value: "Randolph-Macon Woman's College" },
  { label: 'Rangsit University', value: 'Rangsit University' },
  { label: 'Rani Durgavati University', value: 'Rani Durgavati University' },
  { label: 'Rasmussen College', value: 'Rasmussen College' },
  { label: 'Rasmussen College, Florida Campuses', value: 'Rasmussen College, Florida Campuses' },
  { label: 'Rasmussen College, Illinois Campuses', value: 'Rasmussen College, Illinois Campuses' },
  { label: 'Rasmussen College, Minnesota Campuses', value: 'Rasmussen College, Minnesota Campuses' },
  { label: 'Rasmussen College, North Dakota Campuses', value: 'Rasmussen College, North Dakota Campuses' },
  { label: 'Rasmussen College, Wisconsin Campuses', value: 'Rasmussen College, Wisconsin Campuses' },
  { label: 'Razi University', value: 'Razi University' },
  { label: 'RCSI-Medical University of Bahrain', value: 'RCSI-Medical University of Bahrain' },
  { label: 'Red Sea University', value: 'Red Sea University' },
  { label: 'Redeemer College', value: 'Redeemer College' },
  { label: 'Redeemers University', value: 'Redeemers University' },
  { label: 'Reed College', value: 'Reed College' },
  { label: 'Reformed Bible College', value: 'Reformed Bible College' },
  { label: 'Reformed Church University', value: 'Reformed Church University' },
  { label: 'Reformed Theological Academy of Debrecen', value: 'Reformed Theological Academy of Debrecen' },
  { label: 'Regent College', value: 'Regent College' },
  { label: 'Regent International University', value: 'Regent International University' },
  { label: 'Regent University', value: 'Regent University' },
  {
    label: 'Regent University College of Science and Technology  ',
    value: 'Regent University College of Science and Technology  ',
  },
  { label: 'Regis College', value: 'Regis College' },
  { label: 'Regis University', value: 'Regis University' },
  { label: 'Reinhardt College', value: 'Reinhardt College' },
  { label: 'Reitaku University', value: 'Reitaku University' },
  { label: 'Renaissance University', value: 'Renaissance University' },
  { label: 'Renmin University of China', value: 'Renmin University of China' },
  { label: 'Rensselaer Polytechnic Institute', value: 'Rensselaer Polytechnic Institute' },
  {
    label: 'Research College of Nursing - Rockhurst University',
    value: 'Research College of Nursing - Rockhurst University',
  },
  { label: 'Reykjavík University', value: 'Reykjavík University' },
  { label: 'Rezekne Higher School', value: 'Rezekne Higher School' },
  {
    label: 'Rheinisch Westfälische Technische Hochschule Aachen',
    value: 'Rheinisch Westfälische Technische Hochschule Aachen',
  },
  { label: 'Rheinische Fachhochschule Köln', value: 'Rheinische Fachhochschule Köln' },
  { label: 'Rheinische Friedrich-Wilhelms Universität Bonn', value: 'Rheinische Friedrich-Wilhelms Universität Bonn' },
  { label: 'Rhode Island College', value: 'Rhode Island College' },
  { label: 'Rhode Island School of Design', value: 'Rhode Island School of Design' },
  { label: 'Rhodes College', value: 'Rhodes College' },
  { label: 'Rhodes University', value: 'Rhodes University' },
  { label: 'Riara University School of Business and Law', value: 'Riara University School of Business and Law' },
  { label: 'Rice University', value: 'Rice University' },
  { label: 'Richard Stockton College of New Jersey', value: 'Richard Stockton College of New Jersey' },
  {
    label: 'Richmond University - The American International University in London',
    value: 'Richmond University - The American International University in London',
  },
  { label: 'Rider University', value: 'Rider University' },
  { label: 'Riga Aviation University', value: 'Riga Aviation University' },
  {
    label: 'Riga Teacher Training and Educational Management Academy',
    value: 'Riga Teacher Training and Educational Management Academy',
  },
  { label: 'Riga Technical University', value: 'Riga Technical University' },
  { label: "Rikkyo University (St. Paul's University)", value: "Rikkyo University (St. Paul's University)" },
  { label: 'Ringling College of Art and Design', value: 'Ringling College of Art and Design' },
  { label: 'Riphah International Univeristy', value: 'Riphah International Univeristy' },
  { label: 'Ripon College', value: 'Ripon College' },
  { label: 'Rissho University', value: 'Rissho University' },
  { label: 'Ritsumeikan Asia Pacific University', value: 'Ritsumeikan Asia Pacific University' },
  { label: 'Ritsumeikan University', value: 'Ritsumeikan University' },
  {
    label: 'Rivers State University of Science and Technology',
    value: 'Rivers State University of Science and Technology',
  },
  { label: 'Rivier College', value: 'Rivier College' },
  { label: 'Riyadh College of Dentistry and Pharmacy', value: 'Riyadh College of Dentistry and Pharmacy' },
  { label: 'Rizal Technological University', value: 'Rizal Technological University' },
  { label: 'RKDF University', value: 'RKDF University' },
  { label: 'RMIT International University Vietnam', value: 'RMIT International University Vietnam' },
  { label: 'Roanoke Bible College', value: 'Roanoke Bible College' },
  { label: 'Roanoke College', value: 'Roanoke College' },
  { label: 'Robert Morris College', value: 'Robert Morris College' },
  { label: 'Robert Morris College of Chicago', value: 'Robert Morris College of Chicago' },
  { label: 'Roberts Wesleyan College', value: 'Roberts Wesleyan College' },
  { label: 'Rochester College', value: 'Rochester College' },
  { label: 'Rochester Institute of Technology', value: 'Rochester Institute of Technology' },
  { label: 'Rochester Institute of Technology, Dubai', value: 'Rochester Institute of Technology, Dubai' },
  { label: 'Rock Valley College', value: 'Rock Valley College' },
  { label: 'Rockford College', value: 'Rockford College' },
  { label: 'Rockhurst College', value: 'Rockhurst College' },
  { label: 'Rocky Mountain College', value: 'Rocky Mountain College' },
  { label: 'Rocky Mountain College of Art and Design', value: 'Rocky Mountain College of Art and Design' },
  { label: 'Roehampton University of Surrey', value: 'Roehampton University of Surrey' },
  { label: 'Roger Williams University', value: 'Roger Williams University' },
  { label: 'Rogers State University', value: 'Rogers State University' },
  { label: 'Rollins College', value: 'Rollins College' },
  { label: 'Romanian-American University', value: 'Romanian-American University' },
  { label: 'Roosevelt Academy University College', value: 'Roosevelt Academy University College' },
  { label: 'Roosevelt University', value: 'Roosevelt University' },
  { label: 'Rose-Hulman Institute of Technology', value: 'Rose-Hulman Institute of Technology' },
  { label: 'Rosemont College', value: 'Rosemont College' },
  { label: 'Roskilde University', value: 'Roskilde University' },
  { label: 'Ross University Caribbean School of Medicine', value: 'Ross University Caribbean School of Medicine' },
  {
    label: 'Ross University Caribbean School of Veterinary Medicine',
    value: 'Ross University Caribbean School of Veterinary Medicine',
  },
  { label: 'Ross University, School of Medicine', value: 'Ross University, School of Medicine' },
  { label: 'Rostov State Medical University', value: 'Rostov State Medical University' },
  { label: 'Rostov State University', value: 'Rostov State University' },
  { label: 'Rovaniemi University of Applied Sciences', value: 'Rovaniemi University of Applied Sciences' },
  { label: 'Rowan University', value: 'Rowan University' },
  { label: 'Royal Academy of Music', value: 'Royal Academy of Music' },
  { label: 'Royal Academy of Music, University of London', value: 'Royal Academy of Music, University of London' },
  { label: 'Royal College of Art', value: 'Royal College of Art' },
  { label: 'Royal College of Music, University of London', value: 'Royal College of Music, University of London' },
  { label: 'Royal College of Physicians of Ireland', value: 'Royal College of Physicians of Ireland' },
  { label: 'Royal College of Surgeons', value: 'Royal College of Surgeons' },
  {
    label: 'Royal Danish Academy of Fine Arts, School of Architecture',
    value: 'Royal Danish Academy of Fine Arts, School of Architecture',
  },
  {
    label: 'Royal Danish Academy of Fine Arts, School of Visual Art',
    value: 'Royal Danish Academy of Fine Arts, School of Visual Art',
  },
  { label: 'Royal Danish Academy of Music', value: 'Royal Danish Academy of Music' },
  { label: 'Royal Danish School of Educational Sciences', value: 'Royal Danish School of Educational Sciences' },
  { label: 'Royal Danish School of Pharmacy', value: 'Royal Danish School of Pharmacy' },
  {
    label: 'Royal Free Hospital School of Medicine, University of London',
    value: 'Royal Free Hospital School of Medicine, University of London',
  },
  { label: 'Royal Holloway and Bedford New College', value: 'Royal Holloway and Bedford New College' },
  { label: 'Royal lnstitute of Technology', value: 'Royal lnstitute of Technology' },
  { label: 'Royal Melbourne Institute of Technology', value: 'Royal Melbourne Institute of Technology' },
  { label: 'Royal Military Academy', value: 'Royal Military Academy' },
  { label: 'Royal Military College of Canada', value: 'Royal Military College of Canada' },
  { label: 'Royal Roads University', value: 'Royal Roads University' },
  {
    label: 'Royal School of Library and Information Science',
    value: 'Royal School of Library and Information Science',
  },
  { label: 'Royal University of Agriculture', value: 'Royal University of Agriculture' },
  { label: 'Royal University of Bhutan', value: 'Royal University of Bhutan' },
  { label: 'Royal University of Fine Arts', value: 'Royal University of Fine Arts' },
  { label: 'Royal University of Law and Economics', value: 'Royal University of Law and Economics' },
  { label: 'Royal University of Phnom Penh', value: 'Royal University of Phnom Penh' },
  { label: 'Royal Veterinary and Agricultural University', value: 'Royal Veterinary and Agricultural University' },
  { label: 'Ruhr-Universität Bochum', value: 'Ruhr-Universität Bochum' },
  { label: 'Ruprecht-Karls-Universität Heidelberg', value: 'Ruprecht-Karls-Universität Heidelberg' },
  { label: 'Rusangu University', value: 'Rusangu University' },
  { label: 'Rush University', value: 'Rush University' },
  { label: 'Russel Sage College', value: 'Russel Sage College' },
  { label: 'Russell Berrie Nanotechnology Institute', value: 'Russell Berrie Nanotechnology Institute' },
  { label: 'Russian Academy of Arts', value: 'Russian Academy of Arts' },
  { label: 'Russian Academy of Theatre Arts', value: 'Russian Academy of Theatre Arts' },
  { label: 'Russian Customs Academy, Vladivostok Branch', value: 'Russian Customs Academy, Vladivostok Branch' },
  {
    label: 'Russian State Geological Prospecting University ',
    value: 'Russian State Geological Prospecting University ',
  },
  { label: 'Russian State Hydrometeorological University', value: 'Russian State Hydrometeorological University' },
  { label: 'Russian State Medical University', value: 'Russian State Medical University' },
  { label: 'Russian State University for the Humanities', value: 'Russian State University for the Humanities' },
  { label: 'Russian-Armenian (Slavonic) State University', value: 'Russian-Armenian (Slavonic) State University' },
  { label: 'Rust College', value: 'Rust College' },
  { label: 'Rutgers, The State University of New Jersey', value: 'Rutgers, The State University of New Jersey' },
  {
    label: 'Rutgers, The State University of New Jersey - Camden',
    value: 'Rutgers, The State University of New Jersey - Camden',
  },
  {
    label: 'Rutgers, The State University of New Jersey - Newark',
    value: 'Rutgers, The State University of New Jersey - Newark',
  },
  { label: 'Ryazan State Pedagogical University', value: 'Ryazan State Pedagogical University' },
  { label: 'Rybinsk State Academy of Aviational Technology', value: 'Rybinsk State Academy of Aviational Technology' },
  { label: 'Ryerson Polytechnic University', value: 'Ryerson Polytechnic University' },
  {
    label: 'Ryszard Lazarski University of Commerce and Law in Warsaw',
    value: 'Ryszard Lazarski University of Commerce and Law in Warsaw',
  },
  { label: 'Ryukoku University', value: 'Ryukoku University' },
  { label: 'Ryutsu Keizai University', value: 'Ryutsu Keizai University' },
  {
    label: 'Saad College of Nursing and Allied Health Sciences',
    value: 'Saad College of Nursing and Allied Health Sciences',
  },
  { label: 'Saba University', value: 'Saba University' },
  { label: 'Sabanci University', value: 'Sabanci University' },
  { label: 'Sabaragamuwa University of Sri Lanka', value: 'Sabaragamuwa University of Sri Lanka' },
  { label: 'Sabzevar School of Medical Sciences', value: 'Sabzevar School of Medical Sciences' },
  { label: 'Sabzevar Teacher Training University', value: 'Sabzevar Teacher Training University' },
  { label: 'Sacred Heart University', value: 'Sacred Heart University' },
  { label: 'Sadat Academy for Management Sciences', value: 'Sadat Academy for Management Sciences' },
  { label: 'Sadat Institute of Higher Education', value: 'Sadat Institute of Higher Education' },
  { label: 'Sadjad Institute of Technology', value: 'Sadjad Institute of Technology' },
  { label: 'Sadra University', value: 'Sadra University' },
  { label: 'Saga Medical School', value: 'Saga Medical School' },
  { label: 'Saga University', value: 'Saga University' },
  { label: "Sagami Women's University", value: "Sagami Women's University" },
  { label: 'Sage Graduate School', value: 'Sage Graduate School' },
  { label: 'Saginaw Valley State University', value: 'Saginaw Valley State University' },
  { label: 'Sahand University of Technology', value: 'Sahand University of Technology' },
  { label: 'Sahmyook University', value: 'Sahmyook University' },
  { label: 'Saigon University', value: 'Saigon University' },
  { label: 'Saint Ferdinand College', value: 'Saint Ferdinand College' },
  {
    label: "Saint George's Hospital Medical School, University of London",
    value: "Saint George's Hospital Medical School, University of London",
  },
  { label: 'Saint Louis College', value: 'Saint Louis College' },
  { label: 'Saint Louis University', value: 'Saint Louis University' },
  { label: "Saint Mary's University", value: "Saint Mary's University" },
  { label: 'Saint Paul University', value: 'Saint Paul University' },
  { label: 'Saitama Institute of Technology', value: 'Saitama Institute of Technology' },
  { label: 'Saitama Medical School', value: 'Saitama Medical School' },
  { label: 'Saitama Prefectural University', value: 'Saitama Prefectural University' },
  { label: 'Saitama University', value: 'Saitama University' },
  { label: 'Saito College', value: 'Saito College' },
  { label: 'Sakarya University', value: 'Sakarya University' },
  { label: 'Sakhalin State University', value: 'Sakhalin State University' },
  { label: 'Sakushin Gakuin University', value: 'Sakushin Gakuin University' },
  { label: 'Salahddin University (Kurdistan Region)', value: 'Salahddin University (Kurdistan Region)' },
  { label: 'Salam University', value: 'Salam University' },
  { label: 'Salem College', value: 'Salem College' },
  { label: 'Salem International University', value: 'Salem International University' },
  { label: 'Salem State College', value: 'Salem State College' },
  { label: 'Salem Teikyo University', value: 'Salem Teikyo University' },
  { label: 'Salem University', value: 'Salem University' },
  { label: 'Salisbury State University', value: 'Salisbury State University' },
  { label: 'Salve Regina University', value: 'Salve Regina University' },
  { label: 'Sam Houston State University', value: 'Sam Houston State University' },
  { label: 'Samar State University', value: 'Samar State University' },
  {
    label: 'Samara State Academy for Railway Transportation',
    value: 'Samara State Academy for Railway Transportation',
  },
  {
    label: 'Samara State Academy of Architecture and Civil Engineering',
    value: 'Samara State Academy of Architecture and Civil Engineering',
  },
  { label: 'Samara State Aerospace University', value: 'Samara State Aerospace University' },
  { label: 'Samara State Agricultural Academy', value: 'Samara State Agricultural Academy' },
  { label: 'Samara State Medical University', value: 'Samara State Medical University' },
  { label: 'Samara State Technical University', value: 'Samara State Technical University' },
  { label: 'Samara State University', value: 'Samara State University' },
  { label: 'Samara State University of Economics', value: 'Samara State University of Economics' },
  { label: 'Samara State University of Teacher Training', value: 'Samara State University of Teacher Training' },
  { label: 'Samarkand State University', value: 'Samarkand State University' },
  { label: 'Sambalpur University', value: 'Sambalpur University' },
  { label: 'Samchok National University', value: 'Samchok National University' },
  { label: 'Samford University', value: 'Samford University' },
  { label: 'Sami Shamoon College of Engineering', value: 'Sami Shamoon College of Engineering' },
  { label: 'Sampurnanand Sanskrit University', value: 'Sampurnanand Sanskrit University' },
  { label: 'Samuel Merritt College', value: 'Samuel Merritt College' },
  { label: 'San Beda College', value: 'San Beda College' },
  { label: 'San Diego State University', value: 'San Diego State University' },
  { label: 'San Diego University for Integrative Studies', value: 'San Diego University for Integrative Studies' },
  { label: 'San Francisco Art Institute', value: 'San Francisco Art Institute' },
  { label: 'San Francisco Conservatory of Music', value: 'San Francisco Conservatory of Music' },
  { label: 'San Francisco State University', value: 'San Francisco State University' },
  { label: 'San Joaquin College of Law', value: 'San Joaquin College of Law' },
  { label: 'San Jose Christian College', value: 'San Jose Christian College' },
  { label: 'San Jose State University', value: 'San Jose State University' },
  { label: 'San Juan Bautista School of Medicine', value: 'San Juan Bautista School of Medicine' },
  { label: "Sana'a University", value: "Sana'a University" },
  { label: 'Sanaag University of Science and Technology', value: 'Sanaag University of Science and Technology' },
  { label: 'Sanford-Brown Institute', value: 'Sanford-Brown Institute' },
  { label: 'Sang Ji University', value: 'Sang Ji University' },
  { label: 'Sang Myung University', value: 'Sang Myung University' },
  {
    label: 'Sanjay Gandhi Postgraduate lnstitute of Medical Sciences',
    value: 'Sanjay Gandhi Postgraduate lnstitute of Medical Sciences',
  },
  { label: 'Sankei University', value: 'Sankei University' },
  { label: 'Sanno University', value: 'Sanno University' },
  { label: 'Santa Clara University', value: 'Santa Clara University' },
  { label: 'Sanyo Gakuen University', value: 'Sanyo Gakuen University' },
  { label: 'Sapporo Gakuin University', value: 'Sapporo Gakuin University' },
  { label: 'Sapporo International University', value: 'Sapporo International University' },
  { label: 'Sapporo Medical University', value: 'Sapporo Medical University' },
  { label: 'Sapporo University', value: 'Sapporo University' },
  { label: 'Sarah Lawrence College', value: 'Sarah Lawrence College' },
  { label: 'Sarajevo Film Academy', value: 'Sarajevo Film Academy' },
  { label: 'Saratov State Academy of Law', value: 'Saratov State Academy of Law' },
  { label: 'Saratov State Agrarian University', value: 'Saratov State Agrarian University' },
  { label: 'Saratov State Medical University', value: 'Saratov State Medical University' },
  { label: 'Saratov State Socio-Economic University', value: 'Saratov State Socio-Economic University' },
  { label: 'Saratov State Technical University', value: 'Saratov State Technical University' },
  { label: 'Saratov State University', value: 'Saratov State University' },
  { label: 'Sardar Patel University', value: 'Sardar Patel University' },
  {
    label: 'Sardar Vallabhai Patel University of Agriculture Amd Technology',
    value: 'Sardar Vallabhai Patel University of Agriculture Amd Technology',
  },
  {
    label: 'Sardarkrushinagar Dantiwada Agricultural University',
    value: 'Sardarkrushinagar Dantiwada Agricultural University',
  },
  {
    label: 'Sarhad University of Science & Information Technology, Peshawar ',
    value: 'Sarhad University of Science & Information Technology, Peshawar ',
  },
  { label: 'Saskatchewan Indian Federated College', value: 'Saskatchewan Indian Federated College' },
  { label: 'SASTRA Deemed University', value: 'SASTRA Deemed University' },
  { label: 'Satakunta University Of Applied Scinces', value: 'Satakunta University Of Applied Scinces' },
  { label: 'Sathyabama University', value: 'Sathyabama University' },
  { label: 'Sauder School of Business', value: 'Sauder School of Business' },
  { label: 'Saudi Electronic University', value: 'Saudi Electronic University' },
  { label: 'Saurashtra University', value: 'Saurashtra University' },
  { label: 'Savannah College of Art and Design', value: 'Savannah College of Art and Design' },
  { label: 'Savannah State University', value: 'Savannah State University' },
  { label: 'Saxion Universities ', value: 'Saxion Universities ' },
  { label: 'Saybrook Institute', value: 'Saybrook Institute' },
  { label: 'Scandinavian Art and Business Institute', value: 'Scandinavian Art and Business Institute' },
  { label: 'Schiller International University', value: 'Schiller International University' },
  {
    label: 'Schiller International University, American College of Switzerland',
    value: 'Schiller International University, American College of Switzerland',
  },
  { label: 'Schiller International University, Heidelberg', value: 'Schiller International University, Heidelberg' },
  { label: 'Schiller International University, London', value: 'Schiller International University, London' },
  { label: 'Schiller International University, Madrid', value: 'Schiller International University, Madrid' },
  { label: 'Schiller International University, Paris', value: 'Schiller International University, Paris' },
  { label: 'Schiller International University, Strasbourg', value: 'Schiller International University, Strasbourg' },
  { label: 'Scholl College of Podiatric Medicine', value: 'Scholl College of Podiatric Medicine' },
  { label: 'School for International Training', value: 'School for International Training' },
  { label: 'School of Banking and Management in Cracow', value: 'School of Banking and Management in Cracow' },
  { label: 'School of Business and Finance', value: 'School of Business and Finance' },
  { label: 'School of Design and Crafts', value: 'School of Design and Crafts' },
  { label: 'School of Economic Sciences', value: 'School of Economic Sciences' },
  { label: 'School of Education Pisa', value: 'School of Education Pisa' },
  { label: 'School of Finance and Banking', value: 'School of Finance and Banking' },
  { label: 'School of Management', value: 'School of Management' },
  { label: 'School of Management Fribourg', value: 'School of Management Fribourg' },
  {
    label: 'School of Oriental and African Studies, University of London',
    value: 'School of Oriental and African Studies, University of London',
  },
  { label: 'School of Pharmacy, University of London', value: 'School of Pharmacy, University of London' },
  { label: 'School of Planning and Architecture', value: 'School of Planning and Architecture' },
  {
    label: 'School of Slavonic and East European Studies, University of London',
    value: 'School of Slavonic and East European Studies, University of London',
  },
  { label: 'School of the Museum of Fine Arts', value: 'School of the Museum of Fine Arts' },
  { label: 'School of the Visual Arts', value: 'School of the Visual Arts' },
  { label: 'Schreiner College', value: 'Schreiner College' },
  { label: 'Science University of Tokyo', value: 'Science University of Tokyo' },
  { label: 'Science University of Tokyo in Yamaguchi', value: 'Science University of Tokyo in Yamaguchi' },
  { label: 'Scott Christian University', value: 'Scott Christian University' },
  { label: 'Scripps College', value: 'Scripps College' },
  { label: 'Seattle Pacific University', value: 'Seattle Pacific University' },
  { label: 'Seattle University', value: 'Seattle University' },
  { label: 'Sebatian Kolowa University College', value: 'Sebatian Kolowa University College' },
  { label: 'Seberang Perai Polytechnic', value: 'Seberang Perai Polytechnic' },
  { label: 'Sebha University', value: 'Sebha University' },
  { label: 'Séchenyi István University', value: 'Séchenyi István University' },
  { label: 'Second University of Naples', value: 'Second University of Naples' },
  { label: 'Segi University College', value: 'Segi University College' },
  { label: 'Seian University of Art & Design', value: 'Seian University of Art & Design' },
  { label: 'Seigakuin University', value: 'Seigakuin University' },
  { label: 'Seijo University', value: 'Seijo University' },
  { label: 'Seikei University', value: 'Seikei University' },
  { label: 'Seinan Gakuin University', value: 'Seinan Gakuin University' },
  { label: 'Seisen University', value: 'Seisen University' },
  { label: 'Seiwa College', value: 'Seiwa College' },
  { label: 'Sejong University', value: 'Sejong University' },
  { label: 'Sekolah Tinggi Akuntansi Negara (STAN)', value: 'Sekolah Tinggi Akuntansi Negara (STAN)' },
  { label: 'Selangor Islamic University College', value: 'Selangor Islamic University College' },
  { label: 'Selcuk University', value: 'Selcuk University' },
  { label: 'Selkirk College', value: 'Selkirk College' },
  { label: 'Semera University', value: 'Semera University' },
  { label: 'Semey State Medical University', value: 'Semey State Medical University' },
  { label: 'Semey State University', value: 'Semey State University' },
  { label: 'Semmelweis University of Medical Sciences', value: 'Semmelweis University of Medical Sciences' },
  { label: 'Semnan University', value: 'Semnan University' },
  { label: 'Semnan University of Medical Sciences', value: 'Semnan University of Medical Sciences' },
  { label: 'Semyung University', value: 'Semyung University' },
  { label: 'Sendai University', value: 'Sendai University' },
  { label: 'Seneca College', value: 'Seneca College' },
  { label: 'Senshu University', value: 'Senshu University' },
  { label: 'Senzoku Gakuen College', value: 'Senzoku Gakuen College' },
  { label: 'Seokyeong University', value: 'Seokyeong University' },
  { label: 'Seokyeong University', value: 'Seokyeong University' },
  { label: 'Seonam University', value: 'Seonam University' },
  { label: 'Seoul City University', value: 'Seoul City University' },
  { label: 'Seoul National University', value: 'Seoul National University' },
  { label: 'Seoul National University of Education', value: 'Seoul National University of Education' },
  { label: 'Seoul National University of Technology', value: 'Seoul National University of Technology' },
  { label: 'Seoul Teological University', value: 'Seoul Teological University' },
  { label: "Seoul Women's University", value: "Seoul Women's University" },
  { label: 'Seowon University', value: 'Seowon University' },
  { label: 'Seton Hall University', value: 'Seton Hall University' },
  { label: 'Seton Hill College', value: 'Seton Hill College' },
  { label: 'Setsunan University', value: 'Setsunan University' },
  { label: 'Sevastopol National Technical University', value: 'Sevastopol National Technical University' },
  { label: 'Shaanxi Normal University', value: 'Shaanxi Normal University' },
  { label: 'Shah Abdul Latif University Khairpur', value: 'Shah Abdul Latif University Khairpur' },
  { label: 'Shahed University', value: 'Shahed University' },
  { label: 'Shaheed Beheshti University', value: 'Shaheed Beheshti University' },
  {
    label: 'Shaheed Beheshti University of Medical Sciences',
    value: 'Shaheed Beheshti University of Medical Sciences',
  },
  { label: 'Shaheed Benazir Bhutto Women University ', value: 'Shaheed Benazir Bhutto Women University ' },
  { label: 'Shaheed Chamran University', value: 'Shaheed Chamran University' },
  { label: 'Shaheed Rajaei Teacher Training University', value: 'Shaheed Rajaei Teacher Training University' },
  {
    label: 'Shaheed Zulifkar Ali Bhutto Institute of Science and Technology',
    value: 'Shaheed Zulifkar Ali Bhutto Institute of Science and Technology',
  },
  { label: 'Shahid Bahonar University of Kerman', value: 'Shahid Bahonar University of Kerman' },
  { label: 'Shahjalal University of Science and Technology', value: 'Shahjalal University of Science and Technology' },
  { label: 'Shahputra College', value: 'Shahputra College' },
  { label: 'Shahrekord University', value: 'Shahrekord University' },
  { label: 'Shahrekord University of Medical Sciences', value: 'Shahrekord University of Medical Sciences' },
  { label: 'Shahrood University of Medical Sciences', value: 'Shahrood University of Medical Sciences' },
  { label: 'Shahrood University of Technology', value: 'Shahrood University of Technology' },
  { label: 'Shaikh Zayed University', value: 'Shaikh Zayed University' },
  { label: 'Shandong Agricultural University', value: 'Shandong Agricultural University' },
  { label: 'Shandong Economic University', value: 'Shandong Economic University' },
  { label: 'Shandong Medical University', value: 'Shandong Medical University' },
  { label: 'Shandong Normal University', value: 'Shandong Normal University' },
  { label: 'Shandong University', value: 'Shandong University' },
  { label: 'Shandong University of Art and Design', value: 'Shandong University of Art and Design' },
  { label: 'Shandong University of Science and Technology', value: 'Shandong University of Science and Technology' },
  { label: 'Shandong University of Technology', value: 'Shandong University of Technology' },
  {
    label: 'Shandong University of Triaditional Chinese Medicine',
    value: 'Shandong University of Triaditional Chinese Medicine',
  },
  { label: 'Shanghai Business School', value: 'Shanghai Business School' },
  { label: 'Shanghai City College', value: 'Shanghai City College' },
  { label: 'Shanghai Customs College', value: 'Shanghai Customs College' },
  { label: 'Shanghai Dainji University', value: 'Shanghai Dainji University' },
  { label: 'Shanghai Institue of Foreign Trade', value: 'Shanghai Institue of Foreign Trade' },
  { label: 'Shanghai International Studies University', value: 'Shanghai International Studies University' },
  { label: 'Shanghai Jiaotong University', value: 'Shanghai Jiaotong University' },
  { label: 'Shanghai Lida Polytechnic Institute', value: 'Shanghai Lida Polytechnic Institute' },
  { label: 'Shanghai Lixin University of Commerce', value: 'Shanghai Lixin University of Commerce' },
  { label: 'Shanghai Maritime University', value: 'Shanghai Maritime University' },
  { label: 'Shanghai Medical University', value: 'Shanghai Medical University' },
  { label: 'Shanghai Normal University', value: 'Shanghai Normal University' },
  { label: 'Shanghai Ocean University', value: 'Shanghai Ocean University' },
  { label: 'Shanghai Ouhua Vocational Technical College', value: 'Shanghai Ouhua Vocational Technical College' },
  { label: 'Shanghai Sanda University', value: 'Shanghai Sanda University' },
  { label: 'Shanghai Second Medical University', value: 'Shanghai Second Medical University' },
  { label: 'Shanghai Second Polytechnic University', value: 'Shanghai Second Polytechnic University' },
  { label: 'Shanghai Sipo Polytechnic', value: 'Shanghai Sipo Polytechnic' },
  { label: 'Shanghai Television University', value: 'Shanghai Television University' },
  { label: 'Shanghai TieDao University', value: 'Shanghai TieDao University' },
  { label: 'Shanghai University', value: 'Shanghai University' },
  { label: 'Shanghai University of Engineering Science', value: 'Shanghai University of Engineering Science' },
  { label: 'Shanghai University of Finance and Economics', value: 'Shanghai University of Finance and Economics' },
  { label: 'Shanghai University of Science and Technology', value: 'Shanghai University of Science and Technology' },
  {
    label: 'Shanghai University of Traditional Chinese Medicine and Pharmacology',
    value: 'Shanghai University of Traditional Chinese Medicine and Pharmacology',
  },
  {
    label: 'Shanmugha Arts Science Technology and Research Academy',
    value: 'Shanmugha Arts Science Technology and Research Academy',
  },
  { label: 'Shannon College of Hotel Management', value: 'Shannon College of Hotel Management' },
  { label: 'Shantou University', value: 'Shantou University' },
  { label: 'Shanxi Agricultural University', value: 'Shanxi Agricultural University' },
  { label: 'Shanxi Normal University', value: 'Shanxi Normal University' },
  { label: 'Shanxi University', value: 'Shanxi University' },
  { label: 'Shaoguan University', value: 'Shaoguan University' },
  { label: 'Shaqra University', value: 'Shaqra University' },
  { label: 'Sharif University of Technology', value: 'Sharif University of Technology' },
  { label: 'Sharif University of Technology, Kish Campus', value: 'Sharif University of Technology, Kish Campus' },
  { label: 'Shaw University', value: 'Shaw University' },
  { label: 'Shawnee Community College', value: 'Shawnee Community College' },
  { label: 'Shawnee State University', value: 'Shawnee State University' },
  { label: 'Sheffield Hallam University', value: 'Sheffield Hallam University' },
  { label: 'Sheikhbahaee University', value: 'Sheikhbahaee University' },
  { label: 'Sheldon Jackson College', value: 'Sheldon Jackson College' },
  { label: 'Shenandoah University', value: 'Shenandoah University' },
  { label: 'Shendi University', value: 'Shendi University' },
  { label: 'Shenkar School of Engineering & Design', value: 'Shenkar School of Engineering & Design' },
  { label: 'Shenyang Institute of Chemical Technology', value: 'Shenyang Institute of Chemical Technology' },
  { label: 'Shenyang Jianzhu University', value: 'Shenyang Jianzhu University' },
  { label: 'Shenyang Pharmaceutical University', value: 'Shenyang Pharmaceutical University' },
  { label: 'Shenyang Polytechnic University', value: 'Shenyang Polytechnic University' },
  { label: 'Shenyang University', value: 'Shenyang University' },
  { label: 'Shenzhen University', value: 'Shenzhen University' },
  { label: 'Shepherd College', value: 'Shepherd College' },
  {
    label: 'Sher-E-Kashmir University of Agricultural Sciences and Technology',
    value: 'Sher-E-Kashmir University of Agricultural Sciences and Technology',
  },
  { label: 'Sheridan College', value: 'Sheridan College' },
  { label: 'Sherman College of Straight Chiropractic', value: 'Sherman College of Straight Chiropractic' },
  { label: 'Shibaura Institute of Technology', value: 'Shibaura Institute of Technology' },
  { label: 'Shiga University', value: 'Shiga University' },
  { label: 'Shiga University of Medical Science', value: 'Shiga University of Medical Science' },
  { label: 'Shih Chien University', value: 'Shih Chien University' },
  { label: 'Shih Hsin University', value: 'Shih Hsin University' },
  { label: 'Shihezi University ', value: 'Shihezi University ' },
  { label: 'Shikoku Christian College', value: 'Shikoku Christian College' },
  { label: 'Shikoku University', value: 'Shikoku University' },
  { label: 'Shimane University', value: 'Shimane University' },
  { label: 'Shimane University, Faculty of Medicine', value: 'Shimane University, Faculty of Medicine' },
  { label: 'Shimer College', value: 'Shimer College' },
  { label: 'Shimonoseki City University', value: 'Shimonoseki City University' },
  { label: 'Shinawatra University', value: 'Shinawatra University' },
  { label: 'Shinshu University', value: 'Shinshu University' },
  { label: 'Shippensburg University of Pennsylvania', value: 'Shippensburg University of Pennsylvania' },
  { label: "Shirayuri Women's College", value: "Shirayuri Women's College" },
  { label: 'Shiraz Payamnoor University', value: 'Shiraz Payamnoor University' },
  { label: 'Shiraz University', value: 'Shiraz University' },
  { label: 'Shiraz University of Medical Sciences', value: 'Shiraz University of Medical Sciences' },
  { label: 'Shiraz University of Technology', value: 'Shiraz University of Technology' },
  { label: 'Shivaji University', value: 'Shivaji University' },
  { label: 'Shizuoka Prefectural University', value: 'Shizuoka Prefectural University' },
  { label: 'Shizuoka Sangyo University', value: 'Shizuoka Sangyo University' },
  { label: 'Shizuoka University', value: 'Shizuoka University' },
  { label: 'Shobhit University, Meerut', value: 'Shobhit University, Meerut' },
  { label: 'Shokei College', value: 'Shokei College' },
  { label: 'Shomal University', value: 'Shomal University' },
  { label: 'Shonan Institute of Technology', value: 'Shonan Institute of Technology' },
  { label: 'Shoreline Community College', value: 'Shoreline Community College' },
  { label: 'Shorter College', value: 'Shorter College' },
  { label: 'Shoumen University Konstantin Preslavski', value: 'Shoumen University Konstantin Preslavski' },
  { label: 'Showa College of Pharmaceutical Sciences', value: 'Showa College of Pharmaceutical Sciences' },
  { label: 'Showa University', value: 'Showa University' },
  { label: "Showa Women's University", value: "Showa Women's University" },
  {
    label: "Shreemati Nathibai Damodar Thackersey Women's University",
    value: "Shreemati Nathibai Damodar Thackersey Women's University",
  },
  { label: 'Shri Jagannath Sanskrit University', value: 'Shri Jagannath Sanskrit University' },
  {
    label: 'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha',
    value: 'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha',
  },
  { label: 'Shri Mata Vaishno Devi University', value: 'Shri Mata Vaishno Devi University' },
  { label: 'Shridhar University', value: 'Shridhar University' },
  { label: 'Shuchiin College', value: 'Shuchiin College' },
  { label: "Shujitsu Women's University", value: "Shujitsu Women's University" },
  { label: 'Shukutoku University', value: 'Shukutoku University' },
  { label: 'Shumei University', value: 'Shumei University' },
  { label: 'Siam Technology College', value: 'Siam Technology College' },
  { label: 'Siam University', value: 'Siam University' },
  { label: 'Siauliai University', value: 'Siauliai University' },
  { label: 'Sibelius Academy', value: 'Sibelius Academy' },
  { label: 'Siberian Academy of Public Services', value: 'Siberian Academy of Public Services' },
  { label: 'Siberian State Industrial University', value: 'Siberian State Industrial University' },
  { label: 'Siberian State Medical University', value: 'Siberian State Medical University' },
  { label: 'Siberian State Transport University', value: 'Siberian State Transport University' },
  {
    label: 'Siberian State University of Telecommunications and Informatics',
    value: 'Siberian State University of Telecommunications and Informatics',
  },
  { label: 'Sichuan Agricultural University', value: 'Sichuan Agricultural University' },
  { label: 'Sichuan Fine Art Institute', value: 'Sichuan Fine Art Institute' },
  { label: 'Sichuan International Studies University', value: 'Sichuan International Studies University' },
  { label: 'Sichuan Normal University', value: 'Sichuan Normal University' },
  { label: 'Sichuan University', value: 'Sichuan University' },
  { label: 'Sido Kanhu Murmu University', value: 'Sido Kanhu Murmu University' },
  { label: 'Siebold University of Nagasaki', value: 'Siebold University of Nagasaki' },
  { label: 'Siena College', value: 'Siena College' },
  { label: 'Siena Heights University', value: 'Siena Heights University' },
  { label: 'Sierra Nevada College', value: 'Sierra Nevada College' },
  { label: "Sikha 'O' Anushandhan University", value: "Sikha 'O' Anushandhan University" },
  {
    label: 'Sikkim Manipal University of Health, Medical and Technological Sciences',
    value: 'Sikkim Manipal University of Health, Medical and Technological Sciences',
  },
  { label: 'Silesian Academy of Medicine in Katowice', value: 'Silesian Academy of Medicine in Katowice' },
  { label: 'Silesian School of Economics and Languages', value: 'Silesian School of Economics and Languages' },
  { label: 'Silesian Technical University of Gliwice', value: 'Silesian Technical University of Gliwice' },
  { label: 'Silesian University', value: 'Silesian University' },
  { label: 'Silla University', value: 'Silla University' },
  { label: 'Silliman University', value: 'Silliman University' },
  { label: 'Silpakorn University', value: 'Silpakorn University' },
  { label: 'Silver Lake College', value: 'Silver Lake College' },
  { label: 'Simmons College', value: 'Simmons College' },
  { label: 'Simon Fraser University', value: 'Simon Fraser University' },
  { label: "Simon's Rock College", value: "Simon's Rock College" },
  { label: 'Simpson College', value: 'Simpson College' },
  { label: 'Simpson College Iowa', value: 'Simpson College Iowa' },
  { label: 'Sinai University', value: 'Sinai University' },
  { label: 'Sindh Agricultural University', value: 'Sindh Agricultural University' },
  { label: 'Sindh Madressatul Islam University', value: 'Sindh Madressatul Islam University' },
  { label: 'Singapore Institute of Management (SIM)', value: 'Singapore Institute of Management (SIM)' },
  { label: 'Singapore Management University', value: 'Singapore Management University' },
  { label: 'Singhania University Rajasthan', value: 'Singhania University Rajasthan' },
  { label: 'Singidunum University', value: 'Singidunum University' },
  { label: 'Sinnar University', value: 'Sinnar University' },
  { label: 'Sinte Gleska University', value: 'Sinte Gleska University' },
  { label: 'Sir Syed Institute Of Technology Islamabad', value: 'Sir Syed Institute Of Technology Islamabad' },
  {
    label: 'Sir Syed University of Engineering and Technology',
    value: 'Sir Syed University of Engineering and Technology',
  },
  { label: 'Sirte University', value: 'Sirte University' },
  { label: 'Sistan & Balouchestan University', value: 'Sistan & Balouchestan University' },
  { label: 'Sistema Universitario Ana G. Méndez', value: 'Sistema Universitario Ana G. Méndez' },
  { label: 'Skadron College', value: 'Skadron College' },
  { label: 'Skidmore College', value: 'Skidmore College' },
  {
    label: 'Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology',
    value: 'Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology',
  },
  { label: 'Skyline University College, Sharjah', value: 'Skyline University College, Sharjah' },
  { label: 'Slippery Rock University', value: 'Slippery Rock University' },
  { label: 'Slobomir P Univerzitet', value: 'Slobomir P Univerzitet' },
  { label: 'Slovak Medical University', value: 'Slovak Medical University' },
  { label: 'Slovak University of Agriculture in Nitra', value: 'Slovak University of Agriculture in Nitra' },
  { label: 'Slovak University of Technology in Bratislava', value: 'Slovak University of Technology in Bratislava' },
  { label: 'Smith College', value: 'Smith College' },
  { label: 'SMK Fomra Institute of Technology', value: 'SMK Fomra Institute of Technology' },
  { label: 'Smolensk Humanitarian University', value: 'Smolensk Humanitarian University' },
  { label: 'Smolensk State Medical Academy', value: 'Smolensk State Medical Academy' },
  { label: 'Smolny University', value: 'Smolny University' },
  { label: 'Soai University', value: 'Soai University' },
  { label: 'Södertörn University College', value: 'Södertörn University College' },
  { label: 'Sofia University St. Kliment Ohridski', value: 'Sofia University St. Kliment Ohridski' },
  { label: 'Sogang University', value: 'Sogang University' },
  { label: 'Sohag University', value: 'Sohag University' },
  { label: 'Sohar University', value: 'Sohar University' },
  { label: 'Sojourner-Douglass College', value: 'Sojourner-Douglass College' },
  { label: 'Soka University', value: 'Soka University' },
  { label: 'Soka University of America', value: 'Soka University of America' },
  { label: 'Sokoine University of Agriculture', value: 'Sokoine University of Agriculture' },
  { label: 'Sokoto State University ', value: 'Sokoto State University ' },
  { label: 'Solapur University', value: 'Solapur University' },
  { label: 'Solusi University', value: 'Solusi University' },
  { label: 'Somaliland University of Technology', value: 'Somaliland University of Technology' },
  { label: "Sonoda Women's University", value: "Sonoda Women's University" },
  { label: 'Sonoma State University', value: 'Sonoma State University' },
  { label: 'Soochow University', value: 'Soochow University' },
  { label: "Sookmyung Women's University", value: "Sookmyung Women's University" },
  { label: 'Soonchunhyang University', value: 'Soonchunhyang University' },
  { label: 'Soongsil University', value: 'Soongsil University' },
  { label: 'Sophia University', value: 'Sophia University' },
  { label: 'Soran University', value: 'Soran University' },
  { label: 'Sotheby´s Institute of Art - London', value: 'Sotheby´s Institute of Art - London' },
  {
    label: 'South Asian Institute of Technology And Medicine (SAITM) ',
    value: 'South Asian Institute of Technology And Medicine (SAITM) ',
  },
  { label: 'South Asian University', value: 'South Asian University' },
  { label: 'South Bank University', value: 'South Bank University' },
  { label: 'South Carelian Polytechnic', value: 'South Carelian Polytechnic' },
  { label: 'South Carolina State University', value: 'South Carolina State University' },
  { label: 'South China Agricultural University', value: 'South China Agricultural University' },
  { label: 'South China Construction University', value: 'South China Construction University' },
  { label: 'South China Normal University', value: 'South China Normal University' },
  { label: 'South China University', value: 'South China University' },
  { label: 'South China University of Technology', value: 'South China University of Technology' },
  { label: 'South Dakota School of Mines and Technology', value: 'South Dakota School of Mines and Technology' },
  { label: 'South Dakota State University', value: 'South Dakota State University' },
  { label: 'South East European University', value: 'South East European University' },
  { label: 'South Eastern Kenya University', value: 'South Eastern Kenya University' },
  { label: 'South Eastern University of Sri Lanka', value: 'South Eastern University of Sri Lanka' },
  {
    label: 'South Florida Bible College & Theological Seminary',
    value: 'South Florida Bible College & Theological Seminary',
  },
  { label: 'South Gujarat University', value: 'South Gujarat University' },
  { label: 'South Texas College of Law', value: 'South Texas College of Law' },
  {
    label: 'South University of Science and Technology of China ',
    value: 'South University of Science and Technology of China ',
  },
  { label: 'South Valley University', value: 'South Valley University' },
  { label: 'South West University Yucai College', value: 'South West University Yucai College' },
  { label: 'South-East Asia University', value: 'South-East Asia University' },
  { label: 'South-West University Neofit Rilski', value: 'South-West University Neofit Rilski' },
  { label: 'Southampton Solent University', value: 'Southampton Solent University' },
  { label: 'Southeast Missouri State University', value: 'Southeast Missouri State University' },
  { label: 'Southeast University', value: 'Southeast University' },
  { label: 'Southeast University', value: 'Southeast University' },
  { label: 'Southeastern Baptist College', value: 'Southeastern Baptist College' },
  { label: 'Southeastern Bible College', value: 'Southeastern Bible College' },
  { label: 'Southeastern College of the Assemblies of God', value: 'Southeastern College of the Assemblies of God' },
  { label: 'Southeastern Louisiana University', value: 'Southeastern Louisiana University' },
  { label: 'Southeastern Oklahoma State University', value: 'Southeastern Oklahoma State University' },
  { label: 'Southeastern University', value: 'Southeastern University' },
  { label: 'Southern Adventist University', value: 'Southern Adventist University' },
  { label: 'Southern Alberta Institute of Technology', value: 'Southern Alberta Institute of Technology' },
  { label: 'Southern Arkansas University', value: 'Southern Arkansas University' },
  { label: 'Southern California College', value: 'Southern California College' },
  { label: 'Southern California College of Optometry', value: 'Southern California College of Optometry' },
  { label: 'Southern California Institute of Architecture', value: 'Southern California Institute of Architecture' },
  { label: 'Southern College of Optometry', value: 'Southern College of Optometry' },
  { label: 'Southern Connecticut State University', value: 'Southern Connecticut State University' },
  { label: 'Southern Cross University', value: 'Southern Cross University' },
  { label: 'Southern Denmark Business School', value: 'Southern Denmark Business School' },
  { label: 'Southern Illinois University at Carbondale', value: 'Southern Illinois University at Carbondale' },
  { label: 'Southern Illinois University at Edwardsville', value: 'Southern Illinois University at Edwardsville' },
  { label: 'Southern Medial University', value: 'Southern Medial University' },
  { label: 'Southern Methodist University', value: 'Southern Methodist University' },
  { label: 'Southern Nazarene University', value: 'Southern Nazarene University' },
  { label: 'Southern New Hampshire University', value: 'Southern New Hampshire University' },
  { label: 'Southern Oregon University', value: 'Southern Oregon University' },
  { label: 'Southern Polytechnic State Univerisity', value: 'Southern Polytechnic State Univerisity' },
  { label: 'Southern Taiwan University of Technology', value: 'Southern Taiwan University of Technology' },
  { label: 'Southern University - Baton Rouge', value: 'Southern University - Baton Rouge' },
  { label: 'Southern University - New Orleans', value: 'Southern University - New Orleans' },
  { label: 'Southern University - Shreveport', value: 'Southern University - Shreveport' },
  { label: 'Southern University Bangladesh', value: 'Southern University Bangladesh' },
  { label: 'Southern Ural State University', value: 'Southern Ural State University' },
  { label: 'Southern Utah University', value: 'Southern Utah University' },
  { label: 'Southern Vermont College', value: 'Southern Vermont College' },
  { label: 'Southern Wesleyan University', value: 'Southern Wesleyan University' },
  { label: 'Southern Yangtze University', value: 'Southern Yangtze University' },
  { label: 'Southwest Agricultural University', value: 'Southwest Agricultural University' },
  { label: 'Southwest Baptist University', value: 'Southwest Baptist University' },
  { label: 'Southwest Forestry University', value: 'Southwest Forestry University' },
  { label: 'Southwest Jiaotong University', value: 'Southwest Jiaotong University' },
  { label: 'Southwest Missouri State University', value: 'Southwest Missouri State University' },
  {
    label: 'Southwest Missouri State University - West Plains',
    value: 'Southwest Missouri State University - West Plains',
  },
  { label: 'Southwest Petroleum University', value: 'Southwest Petroleum University' },
  { label: 'Southwest State University', value: 'Southwest State University' },
  { label: 'Southwest Texas State University', value: 'Southwest Texas State University' },
  { label: 'Southwest University', value: 'Southwest University' },
  { label: 'Southwest University', value: 'Southwest University' },
  { label: 'Southwest University of Finance and Economics', value: 'Southwest University of Finance and Economics' },
  { label: 'Southwest University of Nationalities', value: 'Southwest University of Nationalities' },
  {
    label: 'Southwest University of Political Science and Law',
    value: 'Southwest University of Political Science and Law',
  },
  { label: 'Southwest University of Science and Technology', value: 'Southwest University of Science and Technology' },
  { label: 'Southwestern Adventist University', value: 'Southwestern Adventist University' },
  { label: 'Southwestern Assemblies of God University', value: 'Southwestern Assemblies of God University' },
  { label: 'Southwestern Baptist Theological Seminary', value: 'Southwestern Baptist Theological Seminary' },
  { label: 'Southwestern Christian College', value: 'Southwestern Christian College' },
  { label: 'Southwestern Christian University', value: 'Southwestern Christian University' },
  { label: 'Southwestern College Kansas', value: 'Southwestern College Kansas' },
  { label: 'Southwestern College Santa Fe', value: 'Southwestern College Santa Fe' },
  { label: 'Southwestern Oklahoma State University', value: 'Southwestern Oklahoma State University' },
  { label: 'Southwestern University', value: 'Southwestern University' },
  { label: 'Southwestern University', value: 'Southwestern University' },
  { label: 'Southwestern University School of Law', value: 'Southwestern University School of Law' },
  {
    label: 'Soutsaka Institute of management and Technology',
    value: 'Soutsaka Institute of management and Technology',
  },
  { label: 'Spalding University', value: 'Spalding University' },
  { label: 'Spartan University of Health Sciences', value: 'Spartan University of Health Sciences' },
  { label: 'Spelman College', value: 'Spelman College' },
  { label: 'Spertus Institute of Jewish Studies', value: 'Spertus Institute of Jewish Studies' },
  { label: 'Spiru Haret University', value: 'Spiru Haret University' },
  { label: 'Spring Arbor College', value: 'Spring Arbor College' },
  { label: 'Spring Hill College', value: 'Spring Hill College' },
  { label: 'Springfield College', value: 'Springfield College' },
  {
    label: 'Sree Chitra Tirunal Institute for Medical Sciences and Technology',
    value: 'Sree Chitra Tirunal Institute for Medical Sciences and Technology',
  },
  { label: 'Sree Rama Krishna Degree College', value: 'Sree Rama Krishna Degree College' },
  { label: 'Sree Sankaracharya University of Sanskrit', value: 'Sree Sankaracharya University of Sanskrit' },
  { label: 'SRH University of Applied Sciences', value: 'SRH University of Applied Sciences' },
  {
    label: 'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya',
    value: 'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya',
  },
  { label: 'Sri Krishnadevaraya University', value: 'Sri Krishnadevaraya University' },
  {
    label: 'Sri Lanka Institute of Advanced Technical Education',
    value: 'Sri Lanka Institute of Advanced Technical Education',
  },
  {
    label: 'Sri Lanka Institute of Information Technology (SLIT)',
    value: 'Sri Lanka Institute of Information Technology (SLIT)',
  },
  { label: "Sri Padmavati Women's University", value: "Sri Padmavati Women's University" },
  {
    label: 'Sri Ramachardra Medical College and Research Institute',
    value: 'Sri Ramachardra Medical College and Research Institute',
  },
  {
    label: 'Sri Sant Gajanan Maharaj College of Engineering',
    value: 'Sri Sant Gajanan Maharaj College of Engineering',
  },
  { label: 'Sri Sathya Sai Institute of Higher Learning', value: 'Sri Sathya Sai Institute of Higher Learning' },
  { label: 'Sri Venkateswara Institute of Medical Sciences', value: 'Sri Venkateswara Institute of Medical Sciences' },
  { label: 'Sri Venkateswara University', value: 'Sri Venkateswara University' },
  { label: 'Srinakharinwirot University', value: 'Srinakharinwirot University' },
  { label: 'Sripatum University', value: 'Sripatum University' },
  {
    label: 'SRM Institute Of Science & Technology ( Deemed University)',
    value: 'SRM Institute Of Science & Technology ( Deemed University)',
  },
  {
    label: 'St Clements University - Higher Education School',
    value: 'St Clements University - Higher Education School',
  },
  { label: "St Theresa's Medical University", value: "St Theresa's Medical University" },
  { label: 'St. Ambrose University', value: 'St. Ambrose University' },
  { label: 'St. Andrew Georgian University', value: 'St. Andrew Georgian University' },
  { label: "St. Andrew's University", value: "St. Andrew's University" },
  { label: 'St. Andrews Presbyterian College', value: 'St. Andrews Presbyterian College' },
  { label: 'St. Anne University', value: 'St. Anne University' },
  { label: 'St. Anselm College', value: 'St. Anselm College' },
  { label: 'St. Anthony College of Nursing', value: 'St. Anthony College of Nursing' },
  { label: 'St. Augustine International University', value: 'St. Augustine International University' },
  { label: 'St. Augustine University of Tanzania', value: 'St. Augustine University of Tanzania' },
  { label: "St. Augustine's College North Carolina", value: "St. Augustine's College North Carolina" },
  { label: "St. Bernard's Institute", value: "St. Bernard's Institute" },
  { label: 'St. Bonaventure University', value: 'St. Bonaventure University' },
  { label: 'St. Clair College', value: 'St. Clair College' },
  { label: 'St. Cloud State University', value: 'St. Cloud State University' },
  { label: 'St. Edwards University', value: 'St. Edwards University' },
  {
    label: 'St. Elizabeth’s College of Health and Social Sciences in Bratislava',
    value: 'St. Elizabeth’s College of Health and Social Sciences in Bratislava',
  },
  { label: 'St. Francis College, Brooklyn Heights', value: 'St. Francis College, Brooklyn Heights' },
  { label: 'St. Francis College, Fort Wayne', value: 'St. Francis College, Fort Wayne' },
  { label: 'St. Francis College, Loretto', value: 'St. Francis College, Loretto' },
  { label: 'St. Francis Medical Center College of Nursing', value: 'St. Francis Medical Center College of Nursing' },
  { label: 'St. Francis Xavier University', value: 'St. Francis Xavier University' },
  { label: "St. George's University", value: "St. George's University" },
  { label: "St. George's University", value: "St. George's University" },
  { label: 'St. John Fisher College', value: 'St. John Fisher College' },
  {
    label: "St. John's & St. Mary's Institute of Technology",
    value: "St. John's & St. Mary's Institute of Technology",
  },
  { label: "St. John's College Maryland", value: "St. John's College Maryland" },
  { label: "St. John's College New Mexico", value: "St. John's College New Mexico" },
  { label: "St. John's Seminary", value: "St. John's Seminary" },
  { label: "St. John's University", value: "St. John's University" },
  { label: "St. John's University", value: "St. John's University" },
  { label: 'St. Joseph College', value: 'St. Joseph College' },
  { label: 'St. Joseph College of Nursing', value: 'St. Joseph College of Nursing' },
  { label: 'St. Joseph Group of Institutes', value: 'St. Joseph Group of Institutes' },
  { label: 'St. Joseph University Beirut', value: 'St. Joseph University Beirut' },
  { label: "St. Joseph's College", value: "St. Joseph's College" },
  { label: "St. Joseph's College New York", value: "St. Joseph's College New York" },
  { label: "St. Joseph's College New York, Suffolk Campus", value: "St. Joseph's College New York, Suffolk Campus" },
  { label: "St. Joseph's College of Maine", value: "St. Joseph's College of Maine" },
  { label: "St. Joseph's University", value: "St. Joseph's University" },
  { label: 'St. Lawrence University', value: 'St. Lawrence University' },
  { label: 'St. Leo College', value: 'St. Leo College' },
  { label: 'St. Louis Christian College', value: 'St. Louis Christian College' },
  { label: 'St. Louis College of Pharmacy', value: 'St. Louis College of Pharmacy' },
  { label: 'St. Louis University', value: 'St. Louis University' },
  { label: "St. Luke' s College of Nursing", value: "St. Luke' s College of Nursing" },
  { label: "St. Luke's College", value: "St. Luke's College" },
  { label: 'St. Marianna University School of Medicine', value: 'St. Marianna University School of Medicine' },
  { label: "St. Martin's College", value: "St. Martin's College" },
  { label: 'St. Mary College', value: 'St. Mary College' },
  { label: 'St. Mary-of-the-Woods College', value: 'St. Mary-of-the-Woods College' },
  { label: "St. Mary's College Indiana", value: "St. Mary's College Indiana" },
  { label: "St. Mary's College of California", value: "St. Mary's College of California" },
  { label: "St. Mary's College of Maryland", value: "St. Mary's College of Maryland" },
  { label: "St. Mary's University", value: "St. Mary's University" },
  { label: "St. Mary's University of Minnesota", value: "St. Mary's University of Minnesota" },
  { label: "St. Mary's University of San Antonio", value: "St. Mary's University of San Antonio" },
  { label: 'St. Matthews University', value: 'St. Matthews University' },
  { label: 'St. Meinrad College', value: 'St. Meinrad College' },
  { label: "St. Michael's College", value: "St. Michael's College" },
  { label: 'St. Norbert College', value: 'St. Norbert College' },
  { label: 'St. Olaf College', value: 'St. Olaf College' },
  { label: 'St. Paul University', value: 'St. Paul University' },
  { label: "St. Paul's College", value: "St. Paul's College" },
  { label: 'St. Pauls University', value: 'St. Pauls University' },
  { label: "St. Peter's College", value: "St. Peter's College" },
  { label: 'St. Petersburg College', value: 'St. Petersburg College' },
  {
    label: 'St. Petersburg Institute of Arts and Restoration',
    value: 'St. Petersburg Institute of Arts and Restoration',
  },
  {
    label: 'St. Petersburg Institute of Sales Management and Economics',
    value: 'St. Petersburg Institute of Sales Management and Economics',
  },
  {
    label: 'St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture',
    value: 'St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture',
  },
  {
    label: 'St. Petersburg State Academy for Engineering Economics (ENGECON)',
    value: 'St. Petersburg State Academy for Engineering Economics (ENGECON)',
  },
  { label: 'St. Petersburg State Agrarian University', value: 'St. Petersburg State Agrarian University' },
  {
    label: 'St. Petersburg State Chemical Pharmaceutical Academy',
    value: 'St. Petersburg State Chemical Pharmaceutical Academy',
  },
  { label: 'St. Petersburg State Cinema and TV University', value: 'St. Petersburg State Cinema and TV University' },
  { label: 'St. Petersburg State Conservatory', value: 'St. Petersburg State Conservatory' },
  {
    label: 'St. Petersburg State Electrotechnical University',
    value: 'St. Petersburg State Electrotechnical University',
  },
  { label: 'St. Petersburg State Forest-Technical Academy', value: 'St. Petersburg State Forest-Technical Academy' },
  {
    label: 'St. Petersburg State Institute of Technology (Technological University)',
    value: 'St. Petersburg State Institute of Technology (Technological University)',
  },
  {
    label: 'St. Petersburg State Marine Technical University',
    value: 'St. Petersburg State Marine Technical University',
  },
  { label: 'St. Petersburg State Medical Academy', value: 'St. Petersburg State Medical Academy' },
  {
    label: 'St. Petersburg State Mining Institute (Technical University)',
    value: 'St. Petersburg State Mining Institute (Technical University)',
  },
  { label: 'St. Petersburg State Mountain Institut', value: 'St. Petersburg State Mountain Institut' },
  { label: 'St. Petersburg State Pediatric Medical Academy', value: 'St. Petersburg State Pediatric Medical Academy' },
  { label: 'St. Petersburg State Polytechnical University', value: 'St. Petersburg State Polytechnical University' },
  { label: 'St. Petersburg State Technical University', value: 'St. Petersburg State Technical University' },
  {
    label: 'St. Petersburg State Technological University of Plant Polymers',
    value: 'St. Petersburg State Technological University of Plant Polymers',
  },
  { label: 'St. Petersburg State Theatre Arts Academy', value: 'St. Petersburg State Theatre Arts Academy' },
  { label: 'St. Petersburg State University', value: 'St. Petersburg State University' },
  {
    label: 'St. Petersburg State University of Aerospace Instrumentation',
    value: 'St. Petersburg State University of Aerospace Instrumentation',
  },
  {
    label: 'St. Petersburg State University of Architecture and Civil Engineering',
    value: 'St. Petersburg State University of Architecture and Civil Engineering',
  },
  {
    label: 'St. Petersburg State University of Civil Aviation',
    value: 'St. Petersburg State University of Civil Aviation',
  },
  {
    label: 'St. Petersburg State University of Culture and Arts',
    value: 'St. Petersburg State University of Culture and Arts',
  },
  {
    label: 'St. Petersburg State University of Culture and Arts',
    value: 'St. Petersburg State University of Culture and Arts',
  },
  {
    label: 'St. Petersburg State University of Economics and Finance',
    value: 'St. Petersburg State University of Economics and Finance',
  },
  {
    label: 'St. Petersburg State University of Engineering and Economics',
    value: 'St. Petersburg State University of Engineering and Economics',
  },
  {
    label: 'St. Petersburg State University of Information Technologies, Mechanics and Optics',
    value: 'St. Petersburg State University of Information Technologies, Mechanics and Optics',
  },
  {
    label: 'St. Petersburg State University of Technology and Design',
    value: 'St. Petersburg State University of Technology and Design',
  },
  {
    label: 'St. Petersburg State University of Telecommunication',
    value: 'St. Petersburg State University of Telecommunication',
  },
  {
    label: 'St. Petersburg State University of Waterway Communications',
    value: 'St. Petersburg State University of Waterway Communications',
  },
  { label: 'St. Petersburg StateMechnikov  Medical Academy', value: 'St. Petersburg StateMechnikov  Medical Academy' },
  { label: 'St. Thomas Aquinas College', value: 'St. Thomas Aquinas College' },
  { label: 'St. Thomas University', value: 'St. Thomas University' },
  { label: 'St. Thomas University', value: 'St. Thomas University' },
  { label: 'St. Vincent College', value: 'St. Vincent College' },
  { label: 'St. Xavier University', value: 'St. Xavier University' },
  { label: 'St.Cyril and Methodius University', value: 'St.Cyril and Methodius University' },
  { label: "St.James's School of Medicine, Bonaire", value: "St.James's School of Medicine, Bonaire" },
  { label: 'St.Kliment Ohridski University', value: 'St.Kliment Ohridski University' },
  { label: "St.Patrick's International College, London", value: "St.Patrick's International College, London" },
  {
    label: 'Staatlich anerkannte Fachhochschule für Kunsttherapie',
    value: 'Staatlich anerkannte Fachhochschule für Kunsttherapie',
  },
  { label: 'Staatliche Hochschule für Musik', value: 'Staatliche Hochschule für Musik' },
  { label: 'Staffordshire University', value: 'Staffordshire University' },
  { label: 'Stamford International University', value: 'Stamford International University' },
  { label: 'Stamford University', value: 'Stamford University' },
  { label: 'Standford Online University', value: 'Standford Online University' },
  { label: 'Standford University', value: 'Standford University' },
  { label: 'Stanford University', value: 'Stanford University' },
  { label: 'Staszic Pila College', value: 'Staszic Pila College' },
  { label: 'State Engineering University of Armenia', value: 'State Engineering University of Armenia' },
  {
    label: 'State Film, Television and Theatre Higher School Leon Schiller in Lodz',
    value: 'State Film, Television and Theatre Higher School Leon Schiller in Lodz',
  },
  { label: 'State Maritine Academy', value: 'State Maritine Academy' },
  { label: 'State Pedagogical University in Kryvyi Rih', value: 'State Pedagogical University in Kryvyi Rih' },
  { label: 'State University of Aerospace Technologies', value: 'State University of Aerospace Technologies' },
  { label: 'State University of Management', value: 'State University of Management' },
  { label: 'State University of New York (SUNY)', value: 'State University of New York (SUNY)' },
  { label: 'State University of New York at Albany', value: 'State University of New York at Albany' },
  { label: 'State University of New York at Binghamton', value: 'State University of New York at Binghamton' },
  { label: 'State University of New York at Buffalo', value: 'State University of New York at Buffalo' },
  { label: 'State University of New York at New Paltz', value: 'State University of New York at New Paltz' },
  { label: 'State University of New York at Oswego', value: 'State University of New York at Oswego' },
  { label: 'State University of New York at Stony Brook', value: 'State University of New York at Stony Brook' },
  {
    label: 'State University of New York College at Brockport',
    value: 'State University of New York College at Brockport',
  },
  {
    label: 'State University of New York College at Cortland',
    value: 'State University of New York College at Cortland',
  },
  {
    label: 'State University of New York College at Fredonia',
    value: 'State University of New York College at Fredonia',
  },
  {
    label: 'State University of New York College at Geneseo',
    value: 'State University of New York College at Geneseo',
  },
  {
    label: 'State University of New York College at Old Westbury',
    value: 'State University of New York College at Old Westbury',
  },
  {
    label: 'State University of New York College at Oneonta',
    value: 'State University of New York College at Oneonta',
  },
  {
    label: 'State University of New York College at Plattsburgh',
    value: 'State University of New York College at Plattsburgh',
  },
  {
    label: 'State University of New York College at Potsdam',
    value: 'State University of New York College at Potsdam',
  },
  {
    label: 'State University of New York College at Purchase',
    value: 'State University of New York College at Purchase',
  },
  {
    label: 'State University of New York College of Agriculture and Technology at Cobleskill',
    value: 'State University of New York College of Agriculture and Technology at Cobleskill',
  },
  {
    label: 'State University of New York College of Environmental Science and Forestry',
    value: 'State University of New York College of Environmental Science and Forestry',
  },
  {
    label: 'State University of New York College of Optometry',
    value: 'State University of New York College of Optometry',
  },
  {
    label: 'State University of New York College of Technology at Alfred',
    value: 'State University of New York College of Technology at Alfred',
  },
  {
    label: 'State University of New York College of Technology at Farmingdale',
    value: 'State University of New York College of Technology at Farmingdale',
  },
  {
    label: 'State University of New York Downstate Medical Center',
    value: 'State University of New York Downstate Medical Center',
  },
  {
    label: 'State University of New York Empire State College',
    value: 'State University of New York Empire State College',
  },
  {
    label: 'State University of New York Health Sience Centre Syracuse',
    value: 'State University of New York Health Sience Centre Syracuse',
  },
  {
    label: 'State University of New York Institute of Technology at Utica/Rome',
    value: 'State University of New York Institute of Technology at Utica/Rome',
  },
  { label: 'State University of New York Maritime College', value: 'State University of New York Maritime College' },
  {
    label: 'State University of New York School of Engineering and Applied Sciences',
    value: 'State University of New York School of Engineering and Applied Sciences',
  },
  {
    label: 'State University of New York Upstate Medical University ',
    value: 'State University of New York Upstate Medical University ',
  },
  { label: 'State University of Tetova', value: 'State University of Tetova' },
  { label: 'State University of West Georgia', value: 'State University of West Georgia' },
  { label: 'Stavropol State Technical University', value: 'Stavropol State Technical University' },
  { label: 'Stavropol State University', value: 'Stavropol State University' },
  { label: 'Stefan University', value: 'Stefan University' },
  { label: 'Steinbeis-Hochschule-Berlin', value: 'Steinbeis-Hochschule-Berlin' },
  { label: 'Stenden University', value: 'Stenden University' },
  { label: 'Stephen F. Austin State University', value: 'Stephen F. Austin State University' },
  { label: 'Stephens College', value: 'Stephens College' },
  { label: 'Sterling College', value: 'Sterling College' },
  { label: 'Sterlitamak State Pedagogical Institute', value: 'Sterlitamak State Pedagogical Institute' },
  { label: 'Stetson University', value: 'Stetson University' },
  { label: 'Stevens Institute of Technology', value: 'Stevens Institute of Technology' },
  { label: 'Stie Perbanas Surabaya', value: 'Stie Perbanas Surabaya' },
  { label: 'Stillman College', value: 'Stillman College' },
  { label: 'STMIK AMIKOM Yogyakarta', value: 'STMIK AMIKOM Yogyakarta' },
  { label: 'STMIK Sinar Nusantara', value: 'STMIK Sinar Nusantara' },
  { label: 'Stockholm Institute of Education', value: 'Stockholm Institute of Education' },
  { label: 'Stockholm School of Economics', value: 'Stockholm School of Economics' },
  { label: 'Stockholm School of Economics in Riga', value: 'Stockholm School of Economics in Riga' },
  { label: 'Stockholm University', value: 'Stockholm University' },
  { label: 'Stonehill College', value: 'Stonehill College' },
  { label: 'Stratford College London', value: 'Stratford College London' },
  { label: 'Strathmore University', value: 'Strathmore University' },
  { label: 'Strayer University', value: 'Strayer University' },
  { label: 'Suan Dusit Rajabhat University', value: 'Suan Dusit Rajabhat University' },
  { label: 'Sudan Academy of Sciences', value: 'Sudan Academy of Sciences' },
  { label: 'Sudan University for Science and Technology', value: 'Sudan University for Science and Technology' },
  { label: 'Suez Canal University', value: 'Suez Canal University' },
  { label: 'Suffolk University', value: 'Suffolk University' },
  { label: "Sugino Women's College", value: "Sugino Women's College" },
  { label: 'Sugiyama Jogakuen University', value: 'Sugiyama Jogakuen University' },
  { label: 'Sukhothai Thammathirat Open University', value: 'Sukhothai Thammathirat Open University' },
  { label: 'Sul Ross State University', value: 'Sul Ross State University' },
  { label: 'Sulaimani Polytechnic University', value: 'Sulaimani Polytechnic University' },
  { label: 'Suleyman Demirel University', value: 'Suleyman Demirel University' },
  { label: 'Suleyman Demirel University', value: 'Suleyman Demirel University' },
  { label: 'Sullivan College', value: 'Sullivan College' },
  { label: 'Sultan Abdul Halim Muadzam Shah Polytechnic', value: 'Sultan Abdul Halim Muadzam Shah Polytechnic' },
  { label: 'Sultan Ahmad Shah Islamic College', value: 'Sultan Ahmad Shah Islamic College' },
  { label: 'Sultan Azlan Shah Polytechnic ', value: 'Sultan Azlan Shah Polytechnic ' },
  { label: 'Sultan Haji Ahmad Shah Polytechnic', value: 'Sultan Haji Ahmad Shah Polytechnic' },
  { label: 'Sultan Idris Shah Polytechnic', value: 'Sultan Idris Shah Polytechnic' },
  {
    label: 'Sultan Ismail Petra International Islamic College',
    value: 'Sultan Ismail Petra International Islamic College',
  },
  { label: 'Sultan Mizan Zainal Abidin Polytechnic', value: 'Sultan Mizan Zainal Abidin Polytechnic' },
  { label: 'Sultan Qaboos University', value: 'Sultan Qaboos University' },
  { label: 'Sultan Salahuddin Abdul Aziz Shah Polytechnic', value: 'Sultan Salahuddin Abdul Aziz Shah Polytechnic' },
  { label: 'Sultanah Bahiyah Polytechnic', value: 'Sultanah Bahiyah Polytechnic' },
  { label: 'Sumgait State University', value: 'Sumgait State University' },
  { label: 'Sumy National Agrarian University', value: 'Sumy National Agrarian University' },
  { label: 'Sumy State University', value: 'Sumy State University' },
  { label: 'Sun Yat-Sen University of Medical Sciences', value: 'Sun Yat-Sen University of Medical Sciences' },
  { label: 'Sunchon National University', value: 'Sunchon National University' },
  { label: 'Sung Kyun Kwan University', value: 'Sung Kyun Kwan University' },
  { label: 'Sungkyul University', value: 'Sungkyul University' },
  { label: "Sungshin Women's University", value: "Sungshin Women's University" },
  { label: 'Sunmoon University', value: 'Sunmoon University' },
  { label: 'Sunrise University Alwar', value: 'Sunrise University Alwar' },
  { label: 'Sunway University College', value: 'Sunway University College' },
  { label: 'Sur University College', value: 'Sur University College' },
  { label: 'Suranaree University of Technology', value: 'Suranaree University of Technology' },
  { label: 'Surgut State University', value: 'Surgut State University' },
  { label: 'Surugadai University', value: 'Surugadai University' },
  { label: 'Susquehanna University', value: 'Susquehanna University' },
  { label: 'Suwon Catholic University', value: 'Suwon Catholic University' },
  { label: 'Suwon University', value: 'Suwon University' },
  { label: 'Suzhou University', value: 'Suzhou University' },
  { label: 'Suzuka International University', value: 'Suzuka International University' },
  { label: 'Suzuka University of Medical Science', value: 'Suzuka University of Medical Science' },
  { label: 'Svey Reong University', value: 'Svey Reong University' },
  { label: 'Swami Ramanand Teerth Marathwada University', value: 'Swami Ramanand Teerth Marathwada University' },
  { label: 'Swansea Metropolitan University', value: 'Swansea Metropolitan University' },
  { label: 'Swarthmore College', value: 'Swarthmore College' },
  {
    label: 'Swedish School of Economics and Business Administration, Finland',
    value: 'Swedish School of Economics and Business Administration, Finland',
  },
  { label: 'Swedish University of Agricultural Sciences', value: 'Swedish University of Agricultural Sciences' },
  { label: 'Sweet Briar College', value: 'Sweet Briar College' },
  { label: 'Swinburne University of Technology', value: 'Swinburne University of Technology' },
  {
    label: 'Swinburne University of Technology, Sarawak Campus',
    value: 'Swinburne University of Technology, Sarawak Campus',
  },
  { label: 'Swiss Business School Zurich (SBS)', value: 'Swiss Business School Zurich (SBS)' },
  {
    label: 'Swiss Federal Institute of Technology, Lausanne',
    value: 'Swiss Federal Institute of Technology, Lausanne',
  },
  { label: 'Swiss Federal Institute of Technology, Zurich', value: 'Swiss Federal Institute of Technology, Zurich' },
  { label: 'Swiss German University', value: 'Swiss German University' },
  { label: 'Swiss Management Center', value: 'Swiss Management Center' },
  { label: 'Syktyvkar State University', value: 'Syktyvkar State University' },
  { label: 'Sylhet Agricultural University', value: 'Sylhet Agricultural University' },
  { label: 'Sylhet Engineering College', value: 'Sylhet Engineering College' },
  { label: 'Sylhet International University', value: 'Sylhet International University' },
  { label: 'Symbiosis International University', value: 'Symbiosis International University' },
  { label: 'Synergy University, Dubai Campus', value: 'Synergy University, Dubai Campus' },
  { label: 'Syracuse University', value: 'Syracuse University' },
  { label: 'Szent István University', value: 'Szent István University' },
  { label: 'Tabari Institute of Higher Education', value: 'Tabari Institute of Higher Education' },
  { label: 'Tabor College', value: 'Tabor College' },
  { label: 'Tabriz College of Technology', value: 'Tabriz College of Technology' },
  { label: 'Tabriz Islamic Art University', value: 'Tabriz Islamic Art University' },
  { label: 'Tabriz University', value: 'Tabriz University' },
  { label: 'Tabriz University of Medical Sciences', value: 'Tabriz University of Medical Sciences' },
  { label: 'Taegu National University of Education', value: 'Taegu National University of Education' },
  { label: 'Taegu University', value: 'Taegu University' },
  { label: 'Taejon National University of Technology', value: 'Taejon National University of Technology' },
  { label: 'Taejon University', value: 'Taejon University' },
  { label: 'Taeshin Christian University', value: 'Taeshin Christian University' },
  { label: 'Tafila Technical University ', value: 'Tafila Technical University ' },
  { label: 'Taganrog State Pedagogical Institute', value: 'Taganrog State Pedagogical Institute' },
  { label: 'Taganrog State University of Radioengineering', value: 'Taganrog State University of Radioengineering' },
  { label: 'Tai Solarin University of Education', value: 'Tai Solarin University of Education' },
  { label: 'Taibah University', value: 'Taibah University' },
  { label: 'TaiChung Healthcare and Management University', value: 'TaiChung Healthcare and Management University' },
  { label: 'Taif University', value: 'Taif University' },
  { label: 'Tainan National College of the Arts', value: 'Tainan National College of the Arts' },
  { label: 'Taipei Medical College', value: 'Taipei Medical College' },
  { label: 'Taipei Municipal Teachers College', value: 'Taipei Municipal Teachers College' },
  { label: 'Taipei Physical Education College', value: 'Taipei Physical Education College' },
  { label: 'Taisho University', value: 'Taisho University' },
  { label: 'Taiyuan University of Technology', value: 'Taiyuan University of Technology' },
  { label: 'Taiz University', value: 'Taiz University' },
  { label: 'Taizhou College', value: 'Taizhou College' },
  { label: 'Taj Institute of Higher Education', value: 'Taj Institute of Higher Education' },
  { label: 'Taj International College', value: 'Taj International College' },
  { label: 'Tajik Agrarian University', value: 'Tajik Agrarian University' },
  { label: 'Tajik National University', value: 'Tajik National University' },
  { label: 'Tajikistan International University', value: 'Tajikistan International University' },
  { label: 'Takachiho University', value: 'Takachiho University' },
  { label: 'Takamatsu University', value: 'Takamatsu University' },
  { label: 'Takarazuka University of Art and Design', value: 'Takarazuka University of Art and Design' },
  { label: 'Takasaki City University of Economics', value: 'Takasaki City University of Economics' },
  { label: 'Takestan Islamic Azad University', value: 'Takestan Islamic Azad University' },
  { label: 'Takhar University', value: 'Takhar University' },
  {
    label: 'Takhatmal Shrivallabh Homoeopathic Medical College',
    value: 'Takhatmal Shrivallabh Homoeopathic Medical College',
  },
  { label: 'Takoradi Polytechnic ', value: 'Takoradi Polytechnic ' },
  { label: 'Takushoku University', value: 'Takushoku University' },
  { label: 'Talladega College', value: 'Talladega College' },
  { label: 'Tallinn University', value: 'Tallinn University' },
  { label: 'Tallinn University of Technology', value: 'Tallinn University of Technology' },
  { label: 'Tama Art University', value: 'Tama Art University' },
  {
    label: 'Tama Institute of Management and Information Sciences',
    value: 'Tama Institute of Management and Information Sciences',
  },
  { label: 'Tamagawa University', value: 'Tamagawa University' },
  { label: 'Tambov State Technical University', value: 'Tambov State Technical University' },
  { label: 'Tamil Nadu Agricultural University', value: 'Tamil Nadu Agricultural University' },
  { label: 'Tamil Nadu Dr. Ambedkar Law University', value: 'Tamil Nadu Dr. Ambedkar Law University' },
  { label: 'Tamil Nadu Dr. M.G.R. Medical University', value: 'Tamil Nadu Dr. M.G.R. Medical University' },
  {
    label: 'Tamil Nadu Veterinary and Animal Sciences University',
    value: 'Tamil Nadu Veterinary and Animal Sciences University',
  },
  { label: 'Tamil University', value: 'Tamil University' },
  { label: 'Tamkang University', value: 'Tamkang University' },
  { label: 'Tampere Polytechnic', value: 'Tampere Polytechnic' },
  { label: 'Tampere University of Technology', value: 'Tampere University of Technology' },
  { label: 'Tansian University', value: 'Tansian University' },
  { label: 'Tanta University', value: 'Tanta University' },
  { label: 'Taoist College Singapore', value: 'Taoist College Singapore' },
  { label: 'Taraba State University', value: 'Taraba State University' },
  { label: 'Tarbiat Modares University', value: 'Tarbiat Modares University' },
  { label: 'Targu-Mures University of Theatre', value: 'Targu-Mures University of Theatre' },
  { label: 'Tarim University', value: 'Tarim University' },
  { label: 'Tarlac State University', value: 'Tarlac State University' },
  { label: 'Tarleton State University', value: 'Tarleton State University' },
  { label: 'Tartu Health Care College', value: 'Tartu Health Care College' },
  { label: 'Tashkent Automobile and Roads Institute', value: 'Tashkent Automobile and Roads Institute' },
  {
    label: 'Tashkent Institute of Irrigation and Melioration',
    value: 'Tashkent Institute of Irrigation and Melioration',
  },
  { label: 'Tashkent Islam University', value: 'Tashkent Islam University' },
  { label: 'Tashkent Medical Academy', value: 'Tashkent Medical Academy' },
  { label: 'Tashkent Pharmaceutical Institute', value: 'Tashkent Pharmaceutical Institute' },
  { label: 'Tashkent School of Finance', value: 'Tashkent School of Finance' },
  { label: 'Tashkent State Agrarian University', value: 'Tashkent State Agrarian University' },
  { label: 'Tashkent State Technical University', value: 'Tashkent State Technical University' },
  { label: 'Tashkent State University of Economics', value: 'Tashkent State University of Economics' },
  { label: 'Tashkent State University of Oriental Studies ', value: 'Tashkent State University of Oriental Studies ' },
  {
    label: 'Tashkent University of Information Technologies',
    value: 'Tashkent University of Information Technologies',
  },
  { label: 'Tata Institute of Social Sciences', value: 'Tata Institute of Social Sciences' },
  { label: 'Tatung Institute of Technology', value: 'Tatung Institute of Technology' },
  { label: 'TaTung University ', value: 'TaTung University ' },
  { label: 'Taurida National V.I.Vernadsky University', value: 'Taurida National V.I.Vernadsky University' },
  { label: 'Tay Nguyen University', value: 'Tay Nguyen University' },
  { label: 'Taylor University', value: 'Taylor University' },
  { label: 'Taylor University, Fort Wayne Campus', value: 'Taylor University, Fort Wayne Campus' },
  { label: "Taylor's University College", value: "Taylor's University College" },
  { label: 'Tbilisi State Medical University', value: 'Tbilisi State Medical University' },
  { label: 'Tbilisi State University', value: 'Tbilisi State University' },
  { label: 'Tbilisi Teaching University', value: 'Tbilisi Teaching University' },
  {
    label: 'Teacher Training College of Revalidation, Rehabilitation and Physical Education',
    value: 'Teacher Training College of Revalidation, Rehabilitation and Physical Education',
  },
  { label: 'Teachers College, Columbia University', value: 'Teachers College, Columbia University' },
  {
    label: 'Technical Institute of Dubnica in Dubnica nad Váhom',
    value: 'Technical Institute of Dubnica in Dubnica nad Váhom',
  },
  { label: 'Technical University in Kosice', value: 'Technical University in Kosice' },
  { label: 'Technical University in Zvolen', value: 'Technical University in Zvolen' },
  { label: 'Technical University of Bialystok', value: 'Technical University of Bialystok' },
  { label: 'Technical University of Budapest', value: 'Technical University of Budapest' },
  {
    label: 'Technical University of Civil Engineering Bucharest',
    value: 'Technical University of Civil Engineering Bucharest',
  },
  { label: 'Technical University of Cluj-Napoca', value: 'Technical University of Cluj-Napoca' },
  { label: 'Technical University of Cracow', value: 'Technical University of Cracow' },
  { label: 'Technical University of Crete', value: 'Technical University of Crete' },
  { label: 'Technical University of Czestochowa', value: 'Technical University of Czestochowa' },
  { label: 'Technical University of Denmark', value: 'Technical University of Denmark' },
  { label: 'Technical University of Gabrovo', value: 'Technical University of Gabrovo' },
  { label: 'Technical University of Gdansk', value: 'Technical University of Gdansk' },
  { label: 'Technical University of Iasi', value: 'Technical University of Iasi' },
  { label: 'Technical University of Kenya', value: 'Technical University of Kenya' },
  { label: 'Technical University of Kielce', value: 'Technical University of Kielce' },
  { label: 'Technical University of Koszalin', value: 'Technical University of Koszalin' },
  { label: 'Technical University of Liberec', value: 'Technical University of Liberec' },
  { label: 'Technical University of Lodz', value: 'Technical University of Lodz' },
  { label: 'Technical University of Lublin', value: 'Technical University of Lublin' },
  {
    label: 'Technical University of Mining and Metallurgy Ostrava',
    value: 'Technical University of Mining and Metallurgy Ostrava',
  },
  { label: 'Technical University of Mombasa', value: 'Technical University of Mombasa' },
  { label: 'Technical University of Opole', value: 'Technical University of Opole' },
  { label: 'Technical University of Poznan', value: 'Technical University of Poznan' },
  { label: 'Technical University of Radom', value: 'Technical University of Radom' },
  { label: 'Technical University of Rzeszow', value: 'Technical University of Rzeszow' },
  { label: 'Technical University of Sofia', value: 'Technical University of Sofia' },
  { label: 'Technical University of Szczecin', value: 'Technical University of Szczecin' },
  { label: 'Technical University of Timisoara', value: 'Technical University of Timisoara' },
  { label: 'Technical University of Varna', value: 'Technical University of Varna' },
  { label: 'Technical University of Warsaw', value: 'Technical University of Warsaw' },
  { label: 'Technical University of Wroclaw', value: 'Technical University of Wroclaw' },
  { label: 'Technical University of Zielona Gora', value: 'Technical University of Zielona Gora' },
  { label: 'Technikum Wien', value: 'Technikum Wien' },
  { label: 'Technion - Israel Institute of Technology', value: 'Technion - Israel Institute of Technology' },
  { label: 'Technische Fachhochschule Berlin', value: 'Technische Fachhochschule Berlin' },
  {
    label: 'Technische Fachhochschule Georg Agricola für Rohstoff, Energie und, Umwelt zu Bochum',
    value: 'Technische Fachhochschule Georg Agricola für Rohstoff, Energie und, Umwelt zu Bochum',
  },
  { label: 'Technische Fachhochschule Wildau', value: 'Technische Fachhochschule Wildau' },
  { label: 'Technische Universität Bergakademie Freiberg', value: 'Technische Universität Bergakademie Freiberg' },
  { label: 'Technische Universität Berlin', value: 'Technische Universität Berlin' },
  {
    label: 'Technische Universität Carolo-Wilhelmina Braunschweig',
    value: 'Technische Universität Carolo-Wilhelmina Braunschweig',
  },
  { label: 'Technische Universität Chemnitz', value: 'Technische Universität Chemnitz' },
  { label: 'Technische Universität Clausthal', value: 'Technische Universität Clausthal' },
  { label: 'Technische Universität Darmstadt', value: 'Technische Universität Darmstadt' },
  { label: 'Technische Universität Dresden', value: 'Technische Universität Dresden' },
  { label: 'Technische Universität Graz', value: 'Technische Universität Graz' },
  { label: 'Technische Universität Hamburg-Harburg', value: 'Technische Universität Hamburg-Harburg' },
  { label: 'Technische Universität Ilmenau', value: 'Technische Universität Ilmenau' },
  { label: 'Technische Universität München', value: 'Technische Universität München' },
  { label: 'Technische Universität Wien', value: 'Technische Universität Wien' },
  { label: 'Technological Education Institute of Athens', value: 'Technological Education Institute of Athens' },
  { label: 'Technological Education Institute of Epiros', value: 'Technological Education Institute of Epiros' },
  {
    label: 'Technological Education Institute of Halkida, Euboea',
    value: 'Technological Education Institute of Halkida, Euboea',
  },
  { label: 'Technological Education Institute of Heraklion', value: 'Technological Education Institute of Heraklion' },
  { label: 'Technological Education Institute of Kalamata', value: 'Technological Education Institute of Kalamata' },
  { label: 'Technological Education Institute of Kavala', value: 'Technological Education Institute of Kavala' },
  { label: 'Technological Education Institute of Kozani', value: 'Technological Education Institute of Kozani' },
  { label: 'Technological Education Institute of Lamia', value: 'Technological Education Institute of Lamia' },
  { label: 'Technological Education Institute of Larissa', value: 'Technological Education Institute of Larissa' },
  { label: 'Technological Education Institute of Mesologgi', value: 'Technological Education Institute of Mesologgi' },
  { label: 'Technological Education Institute of Patras', value: 'Technological Education Institute of Patras' },
  { label: 'Technological Education Institute of Piraeus', value: 'Technological Education Institute of Piraeus' },
  { label: 'Technological Education Institute of Serres', value: 'Technological Education Institute of Serres' },
  {
    label: 'Technological Education Institute of Thessaloniki',
    value: 'Technological Education Institute of Thessaloniki',
  },
  { label: 'Technological University (Banmaw)', value: 'Technological University (Banmaw)' },
  { label: 'Technological University (Dawei)', value: 'Technological University (Dawei)' },
  { label: 'Technological University (Hinthada)', value: 'Technological University (Hinthada)' },
  { label: 'Technological University (Hmawbi)', value: 'Technological University (Hmawbi)' },
  { label: 'Technological University (Hpaan)', value: 'Technological University (Hpaan)' },
  { label: 'Technological University (Kalay)', value: 'Technological University (Kalay)' },
  { label: 'Technological University (Kyaing Tong)', value: 'Technological University (Kyaing Tong)' },
  { label: 'Technological University (Kyaukse)', value: 'Technological University (Kyaukse)' },
  { label: 'Technological University (Lashio)', value: 'Technological University (Lashio)' },
  { label: 'Technological University (Loikaw)', value: 'Technological University (Loikaw)' },
  { label: 'Technological University (Magway)', value: 'Technological University (Magway)' },
  { label: 'Technological University (Mandalay)', value: 'Technological University (Mandalay)' },
  { label: 'Technological University (Maubin)', value: 'Technological University (Maubin)' },
  { label: 'Technological University (Mawlamyaing)', value: 'Technological University (Mawlamyaing)' },
  { label: 'Technological University (Meikhtila)', value: 'Technological University (Meikhtila)' },
  { label: 'Technological University (Monywa)', value: 'Technological University (Monywa)' },
  { label: 'Technological University (Myeik)', value: 'Technological University (Myeik)' },
  { label: 'Technological University (Myitkyina) ', value: 'Technological University (Myitkyina) ' },
  { label: 'Technological University (Pakokku)', value: 'Technological University (Pakokku)' },
  { label: 'Technological University (Pathein)', value: 'Technological University (Pathein)' },
  { label: 'Technological University (Pinlon)', value: 'Technological University (Pinlon)' },
  { label: 'Technological University (Pyay)', value: 'Technological University (Pyay)' },
  { label: 'Technological University (Sittwe)', value: 'Technological University (Sittwe)' },
  { label: 'Technological University (Taunggyi)', value: 'Technological University (Taunggyi)' },
  { label: 'Technological University (Taungoo)', value: 'Technological University (Taungoo)' },
  { label: 'Technological University (Thanlyin)', value: 'Technological University (Thanlyin)' },
  { label: 'Technological University (West Yangon)', value: 'Technological University (West Yangon)' },
  { label: 'Technological University (Yangon)', value: 'Technological University (Yangon)' },
  { label: 'Technological University of Iceland', value: 'Technological University of Iceland' },
  { label: 'Technological University of Podillya', value: 'Technological University of Podillya' },
  { label: 'Technological University of the Philippines', value: 'Technological University of the Philippines' },
  { label: 'Tecnologico de Baja California, Universidad', value: 'Tecnologico de Baja California, Universidad' },
  { label: 'Tehran University of Medical Sciences', value: 'Tehran University of Medical Sciences' },
  { label: 'Tehran University of Teacher Training', value: 'Tehran University of Teacher Training' },
  { label: 'Teikyo Heisei University', value: 'Teikyo Heisei University' },
  { label: 'Teikyo University of Science and Technology', value: 'Teikyo University of Science and Technology' },
  { label: 'Tel Aviv University', value: 'Tel Aviv University' },
  { label: 'Télé-université, Université du Québec', value: 'Télé-université, Université du Québec' },
  { label: 'Telugu University', value: 'Telugu University' },
  { label: 'Temple University', value: 'Temple University' },
  { label: 'Temple University Japan', value: 'Temple University Japan' },
  { label: 'Temple University School of Podiatric Medicine', value: 'Temple University School of Podiatric Medicine' },
  { label: 'Tennessee State University', value: 'Tennessee State University' },
  { label: 'Tennessee Technological University', value: 'Tennessee Technological University' },
  { label: 'Tennessee Temple University', value: 'Tennessee Temple University' },
  { label: 'Tennessee Wesleyan College', value: 'Tennessee Wesleyan College' },
  { label: 'Tenri University', value: 'Tenri University' },
  { label: 'Ternopil Academy of National Economy', value: 'Ternopil Academy of National Economy' },
  {
    label: "Ternopil State Ivan Pul'uj Technical University",
    value: "Ternopil State Ivan Pul'uj Technical University",
  },
  { label: 'Texas A&M International University', value: 'Texas A&M International University' },
  { label: 'Texas A&M University', value: 'Texas A&M University' },
  { label: 'Texas A&M University - Commerce', value: 'Texas A&M University - Commerce' },
  { label: 'Texas A&M University - Corpus Christi', value: 'Texas A&M University - Corpus Christi' },
  { label: 'Texas A&M University - Galveston', value: 'Texas A&M University - Galveston' },
  { label: 'Texas A&M University - Kingsville', value: 'Texas A&M University - Kingsville' },
  { label: 'Texas A&M University - Texarkana', value: 'Texas A&M University - Texarkana' },
  { label: 'Texas Chiropractic College', value: 'Texas Chiropractic College' },
  { label: 'Texas Christian University', value: 'Texas Christian University' },
  { label: 'Texas College', value: 'Texas College' },
  { label: 'Texas College of Osteopathic Medicine', value: 'Texas College of Osteopathic Medicine' },
  { label: 'Texas Lutheran University', value: 'Texas Lutheran University' },
  { label: 'Texas Southern University', value: 'Texas Southern University' },
  { label: 'Texas Tech University', value: 'Texas Tech University' },
  { label: 'Texas Tech University Health Science Center', value: 'Texas Tech University Health Science Center' },
  { label: 'Texas Wesleyan University', value: 'Texas Wesleyan University' },
  { label: "Texas Woman's University", value: "Texas Woman's University" },
  { label: 'Texila American University', value: 'Texila American University' },
  { label: 'Textile Institute of Pakistan', value: 'Textile Institute of Pakistan' },
  { label: 'Tezpur University', value: 'Tezpur University' },
  { label: 'Tezukayama Gakuin University', value: 'Tezukayama Gakuin University' },
  { label: 'Tezukayama University', value: 'Tezukayama University' },
  {
    label: 'Thainguyen University of Agriculture and Forestry',
    value: 'Thainguyen University of Agriculture and Forestry',
  },
  { label: 'Thaksin University', value: 'Thaksin University' },
  { label: 'Thamar University', value: 'Thamar University' },
  { label: 'Thames Valley University', value: 'Thames Valley University' },
  { label: 'Thammasat University', value: 'Thammasat University' },
  { label: 'Thapar Institute of Engineering and Technology', value: 'Thapar Institute of Engineering and Technology' },
  { label: 'The Achievers University', value: 'The Achievers University' },
  { label: 'The American College', value: 'The American College' },
  { label: 'The American University in London', value: 'The American University in London' },
  { label: 'The Art Institute of Boston', value: 'The Art Institute of Boston' },
  { label: 'The Art Institutes International Portland', value: 'The Art Institutes International Portland' },
  { label: 'The Art Institutes International San Francisco', value: 'The Art Institutes International San Francisco' },
  {
    label: "The Bahá'í Institute For Higher Education (BIHE)",
    value: "The Bahá'í Institute For Higher Education (BIHE)",
  },
  { label: 'The Boston Conservatory', value: 'The Boston Conservatory' },
  { label: 'The Catholic University of America', value: 'The Catholic University of America' },
  { label: 'The Chicago School of Professional Psychology', value: 'The Chicago School of Professional Psychology' },
  { label: 'The College of Insurance', value: 'The College of Insurance' },
  { label: 'The College of New Jersey', value: 'The College of New Jersey' },
  { label: 'The College of Santa Fe', value: 'The College of Santa Fe' },
  { label: 'The College of St. Scholastica', value: 'The College of St. Scholastica' },
  { label: 'The College of The Bahamas', value: 'The College of The Bahamas' },
  { label: 'The College of Westchester', value: 'The College of Westchester' },
  { label: 'The College of Wooster', value: 'The College of Wooster' },
  {
    label: 'The Cooper Union for the Advancement of Science and Art',
    value: 'The Cooper Union for the Advancement of Science and Art',
  },
  { label: 'The Corcoran College of Art', value: 'The Corcoran College of Art' },
  { label: 'The CTL Eurocollege', value: 'The CTL Eurocollege' },
  { label: 'The Curtis Institute of Music', value: 'The Curtis Institute of Music' },
  { label: 'The Defiance College', value: 'The Defiance College' },
  { label: 'The Dickinson School of Law', value: 'The Dickinson School of Law' },
  { label: 'The Emirates Academy of Hotel Managment', value: 'The Emirates Academy of Hotel Managment' },
  { label: 'The Federal Polytechnic Offa', value: 'The Federal Polytechnic Offa' },
  { label: 'The Global College Lahore', value: 'The Global College Lahore' },
  {
    label: 'The Government Sadiq College Women University Bahawalpur',
    value: 'The Government Sadiq College Women University Bahawalpur',
  },
  { label: 'The Illinois Institute of Art-Chicago', value: 'The Illinois Institute of Art-Chicago' },
  { label: 'The Interdisciplinary Center Herzliya', value: 'The Interdisciplinary Center Herzliya' },
  { label: 'The Johns Hopkins University', value: 'The Johns Hopkins University' },
  { label: 'The Juilliard School', value: 'The Juilliard School' },
  { label: 'The Kingdom University', value: 'The Kingdom University' },
  { label: 'The Leadership Institute of Seattle', value: 'The Leadership Institute of Seattle' },
  { label: 'The Manchester Metropolitan University', value: 'The Manchester Metropolitan University' },
  { label: 'The Maryland Institute, College of Art', value: 'The Maryland Institute, College of Art' },
  { label: "The Master's College", value: "The Master's College" },
  { label: 'The McGregor School of Antioch University', value: 'The McGregor School of Antioch University' },
  { label: 'The Naropa Institute', value: 'The Naropa Institute' },
  { label: 'The New College', value: 'The New College' },
  { label: 'The New School', value: 'The New School' },
  { label: 'The Petroleum Institute', value: 'The Petroleum Institute' },
  { label: "The Queen's University Belfast", value: "The Queen's University Belfast" },
  { label: 'The Robert Gordon University', value: 'The Robert Gordon University' },
  { label: 'The Rockefeller University', value: 'The Rockefeller University' },
  { label: 'The School of the Art Institute of Chicago', value: 'The School of the Art Institute of Chicago' },
  { label: 'The Scripps Research Institute', value: 'The Scripps Research Institute' },
  { label: 'The Southern Christian University', value: 'The Southern Christian University' },
  { label: 'The Superior College ', value: 'The Superior College ' },
  { label: 'The Tulane University of New Orleans', value: 'The Tulane University of New Orleans' },
  { label: 'The Union Institute', value: 'The Union Institute' },
  { label: 'The University of Nottingham Ningbo China', value: 'The University of Nottingham Ningbo China' },
  {
    label: 'The World Islamic Sciences & Education University',
    value: 'The World Islamic Sciences & Education University',
  },
  { label: 'Theatre Academy Finland', value: 'Theatre Academy Finland' },
  { label: 'Theologische Fakultät Paderborn', value: 'Theologische Fakultät Paderborn' },
  { label: 'Theologische Fakultät Trier', value: 'Theologische Fakultät Trier' },
  { label: 'Theologische Hochschule Friedensau', value: 'Theologische Hochschule Friedensau' },
  { label: 'Thi Qar University', value: 'Thi Qar University' },
  { label: 'Thiagarajar College of Engineering', value: 'Thiagarajar College of Engineering' },
  { label: 'Thiel College', value: 'Thiel College' },
  { label: 'Thierry Graduate School of Leadership', value: 'Thierry Graduate School of Leadership' },
  { label: 'Third University of Rome', value: 'Third University of Rome' },
  { label: 'Thiruvalluvar University', value: 'Thiruvalluvar University' },
  { label: 'Thomas A. Edison State College', value: 'Thomas A. Edison State College' },
  { label: 'Thomas Aquinas College', value: 'Thomas Aquinas College' },
  { label: 'Thomas College Maine', value: 'Thomas College Maine' },
  { label: 'Thomas Jefferson University', value: 'Thomas Jefferson University' },
  { label: 'Thomas More College', value: 'Thomas More College' },
  { label: 'Thomas More College of Liberal Arts', value: 'Thomas More College of Liberal Arts' },
  { label: 'Thomas University', value: 'Thomas University' },
  { label: 'Thompson Rivers University', value: 'Thompson Rivers University' },
  { label: 'Thunderbird School of Global Management', value: 'Thunderbird School of Global Management' },
  { label: 'Tianjin Academy of Fine Art', value: 'Tianjin Academy of Fine Art' },
  { label: 'Tianjin Medical University', value: 'Tianjin Medical University' },
  { label: 'Tianjin Normal University', value: 'Tianjin Normal University' },
  { label: 'Tianjin Open University', value: 'Tianjin Open University' },
  { label: 'Tianjin Polytechnic University', value: 'Tianjin Polytechnic University' },
  { label: 'Tianjin University', value: 'Tianjin University' },
  { label: 'Tianjin University of Commerce', value: 'Tianjin University of Commerce' },
  { label: 'Tianjin University of Finance & Economics', value: 'Tianjin University of Finance & Economics' },
  { label: 'Tianjin University of Technology', value: 'Tianjin University of Technology' },
  {
    label: 'Tibet Agricultural and Animal Husbandry College',
    value: 'Tibet Agricultural and Animal Husbandry College',
  },
  { label: 'Tibet Tibetan Medical College', value: 'Tibet Tibetan Medical College' },
  { label: 'Tibet University', value: 'Tibet University' },
  { label: 'Tibet University of Nationalities', value: 'Tibet University of Nationalities' },
  { label: 'Tierärztliche Hochschule Hannover', value: 'Tierärztliche Hochschule Hannover' },
  { label: 'Tietgen Business College', value: 'Tietgen Business College' },
  { label: 'Tiffin University', value: 'Tiffin University' },
  { label: 'Tiffin University Prague', value: 'Tiffin University Prague' },
  { label: 'Tikrit University', value: 'Tikrit University' },
  { label: 'Tilak Maharashtra Vidyapeeth', value: 'Tilak Maharashtra Vidyapeeth' },
  { label: 'Tilburg University', value: 'Tilburg University' },
  { label: 'Tilka Manjhi Bhagalpur University', value: 'Tilka Manjhi Bhagalpur University' },
  { label: 'Timirjazev Moscow Academy of Agricultutre', value: 'Timirjazev Moscow Academy of Agricultutre' },
  { label: 'Tiraspol State University', value: 'Tiraspol State University' },
  { label: 'Tishreen University', value: 'Tishreen University' },
  { label: 'Tobb Economics and Technology University', value: 'Tobb Economics and Technology University' },
  { label: 'Toccoa Falls College', value: 'Toccoa Falls College' },
  { label: 'Togliatti State University', value: 'Togliatti State University' },
  { label: 'Toho College of Music', value: 'Toho College of Music' },
  { label: 'Toho Gakuen School of Music', value: 'Toho Gakuen School of Music' },
  { label: 'Toho University', value: 'Toho University' },
  { label: 'Tohoku Bunka Gakuen University', value: 'Tohoku Bunka Gakuen University' },
  { label: 'Tohoku College of Pharmacy', value: 'Tohoku College of Pharmacy' },
  { label: 'Tohoku Fukushi University', value: 'Tohoku Fukushi University' },
  { label: 'Tohoku Gakuin University', value: 'Tohoku Gakuin University' },
  { label: 'Tohoku Institute of Technology', value: 'Tohoku Institute of Technology' },
  { label: 'Tohoku University', value: 'Tohoku University' },
  { label: 'Tohoku University of Art and Design', value: 'Tohoku University of Art and Design' },
  { label: "Tohoku Women's College", value: "Tohoku Women's College" },
  { label: 'Tohwa University', value: 'Tohwa University' },
  { label: 'Toin University of Yokohama', value: 'Toin University of Yokohama' },
  { label: 'Tokai Gakuen University', value: 'Tokai Gakuen University' },
  { label: 'Tokai University', value: 'Tokai University' },
  { label: 'Tokai University Educational System', value: 'Tokai University Educational System' },
  { label: "Tokai Women's College", value: "Tokai Women's College" },
  { label: 'Tokiwa University', value: 'Tokiwa University' },
  { label: 'Tokoha Gakuen University', value: 'Tokoha Gakuen University' },
  { label: 'Tokushima Bunri University', value: 'Tokushima Bunri University' },
  { label: 'Tokushima University', value: 'Tokushima University' },
  { label: 'Tokuyama University', value: 'Tokuyama University' },
  { label: 'Tokyo College of Music', value: 'Tokyo College of Music' },
  { label: 'Tokyo Denki University', value: 'Tokyo Denki University' },
  { label: 'Tokyo Dental College', value: 'Tokyo Dental College' },
  { label: 'Tokyo Engineering University', value: 'Tokyo Engineering University' },
  { label: 'Tokyo Gakugei University', value: 'Tokyo Gakugei University' },
  { label: 'Tokyo Institute of Polytechnics', value: 'Tokyo Institute of Polytechnics' },
  { label: 'Tokyo Institute of Technology', value: 'Tokyo Institute of Technology' },
  { label: 'Tokyo International University', value: 'Tokyo International University' },
  { label: 'Tokyo Kasei University', value: 'Tokyo Kasei University' },
  { label: 'Tokyo Keizai University', value: 'Tokyo Keizai University' },
  { label: 'Tokyo Medical and Dental University', value: 'Tokyo Medical and Dental University' },
  { label: 'Tokyo Medical College', value: 'Tokyo Medical College' },
  { label: 'Tokyo Metropolitan Institute of Technology', value: 'Tokyo Metropolitan Institute of Technology' },
  { label: 'Tokyo Metropolitan University', value: 'Tokyo Metropolitan University' },
  {
    label: 'Tokyo Metropolitan University of Health Sciences',
    value: 'Tokyo Metropolitan University of Health Sciences',
  },
  {
    label: 'Tokyo National University of Fine Arts and Music',
    value: 'Tokyo National University of Fine Arts and Music',
  },
  { label: 'Tokyo University', value: 'Tokyo University' },
  { label: 'Tokyo University of Agriculture', value: 'Tokyo University of Agriculture' },
  { label: 'Tokyo University of Agriculture and Technology', value: 'Tokyo University of Agriculture and Technology' },
  { label: 'Tokyo University of Art and Design', value: 'Tokyo University of Art and Design' },
  { label: 'Tokyo University of Fisheries', value: 'Tokyo University of Fisheries' },
  { label: 'Tokyo University of Foreign Studies', value: 'Tokyo University of Foreign Studies' },
  { label: 'Tokyo University of Information Sciences', value: 'Tokyo University of Information Sciences' },
  { label: 'Tokyo University of Mercantile Marine', value: 'Tokyo University of Mercantile Marine' },
  { label: 'Tokyo University of Pharmacy and Life Science', value: 'Tokyo University of Pharmacy and Life Science' },
  { label: "Tokyo Woman's Christian University", value: "Tokyo Woman's Christian University" },
  { label: "Tokyo Women's College of Physical Education", value: "Tokyo Women's College of Physical Education" },
  { label: "Tokyo Women's Medial College", value: "Tokyo Women's Medial College" },
  { label: 'Tomakomai Komazawa University', value: 'Tomakomai Komazawa University' },
  { label: 'Tomas Bata University in Zlin', value: 'Tomas Bata University in Zlin' },
  { label: 'Tomball College', value: 'Tomball College' },
  { label: 'Tomsk Polytechnic University', value: 'Tomsk Polytechnic University' },
  { label: 'Tomsk State Pedagogical University', value: 'Tomsk State Pedagogical University' },
  { label: 'Tomsk State University', value: 'Tomsk State University' },
  {
    label: 'Tomsk State University of Architecture and Building',
    value: 'Tomsk State University of Architecture and Building',
  },
  {
    label: 'Tomsk State University of Control Systems and Radioelectronics',
    value: 'Tomsk State University of Control Systems and Radioelectronics',
  },
  { label: 'Tongji Medical University', value: 'Tongji Medical University' },
  { label: 'Tongji University', value: 'Tongji University' },
  { label: 'Tongmyung University of Information Technology', value: 'Tongmyung University of Information Technology' },
  {
    label: 'Torbat Heydarieh University of Medical Sciences',
    value: 'Torbat Heydarieh University of Medical Sciences',
  },
  { label: 'Tottori University', value: 'Tottori University' },
  { label: 'Tottori University of Environmental Studies', value: 'Tottori University of Environmental Studies' },
  { label: 'Tougaloo College', value: 'Tougaloo College' },
  { label: 'Touro College', value: 'Touro College' },
  { label: 'Touro University', value: 'Touro University' },
  { label: 'Towson University', value: 'Towson University' },
  { label: 'Toyama Medical and Pharmaceutical University', value: 'Toyama Medical and Pharmaceutical University' },
  { label: 'Toyama Prefectural University', value: 'Toyama Prefectural University' },
  { label: 'Toyama University', value: 'Toyama University' },
  { label: 'Toyama University of International Studies', value: 'Toyama University of International Studies' },
  { label: "Toyo Eiwa Women's University", value: "Toyo Eiwa Women's University" },
  { label: 'Toyo Gakuen University', value: 'Toyo Gakuen University' },
  { label: 'Toyo University', value: 'Toyo University' },
  { label: 'Toyohashi University of Technology', value: 'Toyohashi University of Technology' },
  { label: 'Toyota Technological Institute', value: 'Toyota Technological Institute' },
  { label: 'TPM College', value: 'TPM College' },
  { label: 'Trakia University Stara Zagora', value: 'Trakia University Stara Zagora' },
  { label: 'Trakya University', value: 'Trakya University' },
  { label: 'Transport and Telecommunication Institute', value: 'Transport and Telecommunication Institute' },
  { label: 'Transylvania University', value: 'Transylvania University' },
  { label: 'Trent University', value: 'Trent University' },
  { label: 'Trevecca Nazarene University', value: 'Trevecca Nazarene University' },
  { label: 'Tri-College University', value: 'Tri-College University' },
  { label: 'Tri-State University', value: 'Tri-State University' },
  { label: 'Tribhuvan University Kathmandu', value: 'Tribhuvan University Kathmandu' },
  { label: 'Trident University', value: 'Trident University' },
  { label: 'Trinity Bible College', value: 'Trinity Bible College' },
  { label: 'Trinity Christian College', value: 'Trinity Christian College' },
  { label: 'Trinity College Carmarthen', value: 'Trinity College Carmarthen' },
  { label: 'Trinity College Connecticut', value: 'Trinity College Connecticut' },
  { label: 'Trinity College of Florida', value: 'Trinity College of Florida' },
  { label: 'Trinity College of Music', value: 'Trinity College of Music' },
  { label: 'Trinity College of Vermont', value: 'Trinity College of Vermont' },
  { label: 'Trinity International University', value: 'Trinity International University' },
  {
    label: 'Trinity International University (Excel), Miami',
    value: 'Trinity International University (Excel), Miami',
  },
  { label: 'Trinity Theological Seminary', value: 'Trinity Theological Seminary' },
  { label: 'Trinity University', value: 'Trinity University' },
  { label: 'Trinity University', value: 'Trinity University' },
  { label: 'Trinity University School of Medicine', value: 'Trinity University School of Medicine' },
  { label: 'Trinity Western University', value: 'Trinity Western University' },
  { label: 'Tripura University', value: 'Tripura University' },
  { label: 'Trisakti Institute of Tourism ', value: 'Trisakti Institute of Tourism ' },
  { label: 'Triton College', value: 'Triton College' },
  { label: 'Troy University', value: 'Troy University' },
  { label: 'Troy University, Dothan', value: 'Troy University, Dothan' },
  { label: 'Troy University, Montgomery', value: 'Troy University, Montgomery' },
  { label: 'Troy University, Phenix City', value: 'Troy University, Phenix City' },
  { label: 'Troy University, Troy', value: 'Troy University, Troy' },
  { label: 'Truman College', value: 'Truman College' },
  { label: 'Truman State University', value: 'Truman State University' },
  { label: 'Tshwane University of Technology', value: 'Tshwane University of Technology' },
  { label: 'Tsinghua University', value: 'Tsinghua University' },
  { label: 'Tsuda College', value: 'Tsuda College' },
  { label: 'Tsukuba University', value: 'Tsukuba University' },
  { label: 'Tsuru University', value: 'Tsuru University' },
  { label: 'Tsurumi University', value: 'Tsurumi University' },
  { label: 'Tufts University', value: 'Tufts University' },
  { label: 'Tui Online University', value: 'Tui Online University' },
  { label: 'Tula State University', value: 'Tula State University' },
  { label: 'Tumaini University - Iringa University College', value: 'Tumaini University - Iringa University College' },
  { label: 'Tumkur University', value: 'Tumkur University' },
  { label: 'Tunghai University', value: 'Tunghai University' },
  { label: 'Tunku Abdul Rahman Chinese College', value: 'Tunku Abdul Rahman Chinese College' },
  {
    label: 'Tunku Abdul Rahman University (Chinese University)',
    value: 'Tunku Abdul Rahman University (Chinese University)',
  },
  { label: 'Tunku Syed Sirajuddin Polytechnic', value: 'Tunku Syed Sirajuddin Polytechnic' },
  { label: 'Turar Ryskulov Kazakh Economic University', value: 'Turar Ryskulov Kazakh Economic University' },
  { label: 'Turin Polytechnic University In Tashkent', value: 'Turin Polytechnic University In Tashkent' },
  { label: 'Türkisch-Deutsche Universität', value: 'Türkisch-Deutsche Universität' },
  { label: 'Turkish Military Academy', value: 'Turkish Military Academy' },
  { label: 'Turkish Naval Academy', value: 'Turkish Naval Academy' },
  {
    label: 'Turku School of Economics and Business Administration',
    value: 'Turku School of Economics and Business Administration',
  },
  { label: 'Tusculum College', value: 'Tusculum College' },
  { label: 'Tuskegee University', value: 'Tuskegee University' },
  { label: 'Tuva State University', value: 'Tuva State University' },
  { label: 'Tver State Medical Academy', value: 'Tver State Medical Academy' },
  { label: 'Tver State Technical University', value: 'Tver State Technical University' },
  { label: 'Tver State University', value: 'Tver State University' },
  { label: 'Tyumen State Agricultural Academy', value: 'Tyumen State Agricultural Academy' },
  { label: 'Tyumen State University', value: 'Tyumen State University' },
  { label: 'Tyumen State University of Gas and Oil', value: 'Tyumen State University of Gas and Oil' },
  { label: 'Tzu Chi College of Medicine and Humanities', value: 'Tzu Chi College of Medicine and Humanities' },
  { label: 'Ubonratchathani University', value: 'Ubonratchathani University' },
  { label: 'UCSI University', value: 'UCSI University' },
  { label: 'Udmurt State University', value: 'Udmurt State University' },
  { label: 'Ueno Gakuen College', value: 'Ueno Gakuen College' },
  { label: 'Ufa State Aviation Technical University', value: 'Ufa State Aviation Technical University' },
  { label: 'Ufa State Institute of Arts', value: 'Ufa State Institute of Arts' },
  { label: 'Ufa State Petroleum Technological University', value: 'Ufa State Petroleum Technological University' },
  { label: 'Ufuk University', value: 'Ufuk University' },
  { label: 'Uganda Christian University', value: 'Uganda Christian University' },
  { label: "Uganda Martyr's University", value: "Uganda Martyr's University" },
  { label: 'Uinversity of Babylon', value: 'Uinversity of Babylon' },
  { label: 'Ukhta State Technical University', value: 'Ukhta State Technical University' },
  { label: 'Ukrainian Academy of Banking', value: 'Ukrainian Academy of Banking' },
  { label: 'Ukrainian Academy of Pharmacy', value: 'Ukrainian Academy of Pharmacy' },
  { label: 'Ukrainian Medical Stomatological Academy', value: 'Ukrainian Medical Stomatological Academy' },
  { label: 'Ukrainian National Forestry University', value: 'Ukrainian National Forestry University' },
  { label: 'Ulaanbaatar University', value: 'Ulaanbaatar University' },
  { label: 'Ulsan University', value: 'Ulsan University' },
  { label: 'Uludag University', value: 'Uludag University' },
  { label: 'Ulyanovsk Higher School of Civil Aviation', value: 'Ulyanovsk Higher School of Civil Aviation' },
  { label: 'Ulyanovsk State Agricultural Academy', value: 'Ulyanovsk State Agricultural Academy' },
  { label: 'Ulyanovsk State Pedagogical University', value: 'Ulyanovsk State Pedagogical University' },
  { label: 'Ulyanovsk State Technical University', value: 'Ulyanovsk State Technical University' },
  { label: 'Ulyanovsk State University', value: 'Ulyanovsk State University' },
  { label: "Umaru Musa Yar'Adua University ", value: "Umaru Musa Yar'Adua University " },
  { label: 'Umea University', value: 'Umea University' },
  { label: 'Umm Al-Qura University', value: 'Umm Al-Qura University' },
  { label: 'Ummah University', value: 'Ummah University' },
  { label: 'Umutara Polytechnic', value: 'Umutara Polytechnic' },
  { label: 'UNESCO-IHE Institute for Water Education', value: 'UNESCO-IHE Institute for Water Education' },
  { label: 'Ungku Omar Premier Polytechnic', value: 'Ungku Omar Premier Polytechnic' },
  {
    label: 'Uniformed Services Universty of the Health Sciences',
    value: 'Uniformed Services Universty of the Health Sciences',
  },
  { label: 'Union College', value: 'Union College' },
  { label: 'Union College Kentucky', value: 'Union College Kentucky' },
  { label: 'Union College Nebraska', value: 'Union College Nebraska' },
  { label: 'Union Theological Seminary (UTS)', value: 'Union Theological Seminary (UTS)' },
  { label: 'Union University', value: 'Union University' },
  { label: 'United Arab Emirates University', value: 'United Arab Emirates University' },
  { label: 'United Business Institute', value: 'United Business Institute' },
  { label: 'United International University', value: 'United International University' },
  {
    label: 'United Medical and Dental Schools, University of London',
    value: 'United Medical and Dental Schools, University of London',
  },
  { label: 'United Nations University', value: 'United Nations University' },
  { label: 'United States Air Force Academy', value: 'United States Air Force Academy' },
  { label: 'United States Coast Guard Academy', value: 'United States Coast Guard Academy' },
  { label: 'United States International University', value: 'United States International University' },
  { label: 'United States International University', value: 'United States International University' },
  { label: 'United States Merchant Marine Academy', value: 'United States Merchant Marine Academy' },
  { label: 'United States Military Academy', value: 'United States Military Academy' },
  { label: 'United States Naval Academy', value: 'United States Naval Academy' },
  { label: 'United States Sports Academy', value: 'United States Sports Academy' },
  { label: 'Unitomo Surabaya', value: 'Unitomo Surabaya' },
  { label: 'Unity  University', value: 'Unity  University' },
  { label: 'Unity College', value: 'Unity College' },
  { label: 'Universidad Abierta Interactiva', value: 'Universidad Abierta Interactiva' },
  { label: 'Universidad Abierta Interamericana', value: 'Universidad Abierta Interamericana' },
  { label: 'Universidad Abierta Para Adultos', value: 'Universidad Abierta Para Adultos' },
  { label: 'Universidad Abierta y a Distancia de Panama', value: 'Universidad Abierta y a Distancia de Panama' },
  { label: 'Universidad Academia de Humanismo Cristiano', value: 'Universidad Academia de Humanismo Cristiano' },
  { label: 'Universidad Academia de Humanismo Cristiano', value: 'Universidad Academia de Humanismo Cristiano' },
  { label: 'Universidad Adolfo Ibañez', value: 'Universidad Adolfo Ibañez' },
  { label: 'Universidad Adolfo Ibáñez', value: 'Universidad Adolfo Ibáñez' },
  { label: 'Universidad Adventista de Bolivia', value: 'Universidad Adventista de Bolivia' },
  { label: 'Universidad Adventista de Centro América', value: 'Universidad Adventista de Centro América' },
  { label: 'Universidad Adventista de Chile', value: 'Universidad Adventista de Chile' },
  { label: 'Universidad Adventista de Colombia', value: 'Universidad Adventista de Colombia' },
  { label: 'Universidad Adventista del Plata', value: 'Universidad Adventista del Plata' },
  { label: 'Universidad Adventista Dominicana', value: 'Universidad Adventista Dominicana' },
  { label: 'Universidad Agraria del Ecuador', value: 'Universidad Agraria del Ecuador' },
  { label: 'Universidad Agroforestal Fernando A.Meriño', value: 'Universidad Agroforestal Fernando A.Meriño' },
  { label: 'Universidad Alas Peruanas', value: 'Universidad Alas Peruanas' },
  { label: 'Universidad Albert Einstein', value: 'Universidad Albert Einstein' },
  { label: 'Universidad Alberto Hurtado', value: 'Universidad Alberto Hurtado' },
  { label: 'Universidad Alfonso X El Sabio', value: 'Universidad Alfonso X El Sabio' },
  { label: 'Universidad Amazonica de Pando', value: 'Universidad Amazonica de Pando' },
  { label: 'Universidad Americana', value: 'Universidad Americana' },
  { label: 'Universidad Americana', value: 'Universidad Americana' },
  { label: 'Universidad Anáhuac', value: 'Universidad Anáhuac' },
  { label: 'Universidad Anáhuac del Sur', value: 'Universidad Anáhuac del Sur' },
  { label: 'Universidad Andina del Cusco', value: 'Universidad Andina del Cusco' },
  { label: 'Universidad Andina Nestor Caceares Velasquez', value: 'Universidad Andina Nestor Caceares Velasquez' },
  { label: 'Universidad Andina Simón Bolivar', value: 'Universidad Andina Simón Bolivar' },
  { label: 'Universidad Andina Simón Bolívar', value: 'Universidad Andina Simón Bolívar' },
  { label: 'Universidad Antonio de Nebrija', value: 'Universidad Antonio de Nebrija' },
  { label: 'Universidad Antonio Nariño', value: 'Universidad Antonio Nariño' },
  { label: 'Universidad APEC', value: 'Universidad APEC' },
  { label: 'Universidad Arcis', value: 'Universidad Arcis' },
  { label: 'Universidad Argentina de la Empresa', value: 'Universidad Argentina de la Empresa' },
  { label: 'Universidad Argentina John F. Kennedy', value: 'Universidad Argentina John F. Kennedy' },
  { label: 'Universidad Arturo Prat', value: 'Universidad Arturo Prat' },
  { label: 'Universidad Atlantida Argentina', value: 'Universidad Atlantida Argentina' },
  { label: 'Universidad Austral Buenos Aires', value: 'Universidad Austral Buenos Aires' },
  { label: 'Universidad Austral de Chile', value: 'Universidad Austral de Chile' },
  { label: 'Universidad Autonoma  de Durango', value: 'Universidad Autonoma  de Durango' },
  { label: 'Universidad Autónoma Agraria Antonio Narro', value: 'Universidad Autónoma Agraria Antonio Narro' },
  { label: 'Universidad Autónoma Benito Juárez de Oaxaca', value: 'Universidad Autónoma Benito Juárez de Oaxaca' },
  { label: 'Universidad Autónoma de Aguascalientes', value: 'Universidad Autónoma de Aguascalientes' },
  { label: 'Universidad Autónoma de Asunción', value: 'Universidad Autónoma de Asunción' },
  { label: 'Universidad Autónoma de Baja California', value: 'Universidad Autónoma de Baja California' },
  { label: 'Universidad Autónoma de Baja California Sur', value: 'Universidad Autónoma de Baja California Sur' },
  { label: 'Universidad Autónoma de Bucaramanga', value: 'Universidad Autónoma de Bucaramanga' },
  { label: 'Universidad Autónoma de Campeche', value: 'Universidad Autónoma de Campeche' },
  { label: 'Universidad Autónoma de Centro América', value: 'Universidad Autónoma de Centro América' },
  { label: 'Universidad Autónoma de Chapingo', value: 'Universidad Autónoma de Chapingo' },
  { label: 'Universidad Autónoma de Chiapas', value: 'Universidad Autónoma de Chiapas' },
  { label: 'Universidad Autónoma de Chihuahua', value: 'Universidad Autónoma de Chihuahua' },
  { label: 'Universidad Autonoma de Chiriqui', value: 'Universidad Autonoma de Chiriqui' },
  { label: 'Universidad Autónoma de Ciudad Juárez', value: 'Universidad Autónoma de Ciudad Juárez' },
  { label: 'Universidad Autónoma de Coahuila', value: 'Universidad Autónoma de Coahuila' },
  { label: 'Universidad Autónoma de Colombia', value: 'Universidad Autónoma de Colombia' },
  { label: 'Universidad Autónoma de Entre Ríos', value: 'Universidad Autónoma de Entre Ríos' },
  { label: 'Universidad Autónoma de Fresnillo', value: 'Universidad Autónoma de Fresnillo' },
  { label: 'Universidad Autónoma de Guadalajara', value: 'Universidad Autónoma de Guadalajara' },
  { label: 'Universidad Autónoma de Guerrero', value: 'Universidad Autónoma de Guerrero' },
  { label: 'Universidad Autónoma de la Ciudad de México', value: 'Universidad Autónoma de la Ciudad de México' },
  { label: 'Universidad Autónoma de la Laguna', value: 'Universidad Autónoma de la Laguna' },
  { label: 'Universidad Autónoma de Las Américas', value: 'Universidad Autónoma de Las Américas' },
  { label: 'Universidad Autónoma de Madrid', value: 'Universidad Autónoma de Madrid' },
  { label: 'Universidad Autónoma de Manizales', value: 'Universidad Autónoma de Manizales' },
  { label: 'Universidad Autónoma de Nayarit', value: 'Universidad Autónoma de Nayarit' },
  { label: 'Universidad Autónoma de Nuevo León', value: 'Universidad Autónoma de Nuevo León' },
  { label: 'Universidad Autónoma de Occidente', value: 'Universidad Autónoma de Occidente' },
  { label: 'Universidad Autónoma de Querétaro', value: 'Universidad Autónoma de Querétaro' },
  { label: 'Universidad Autónoma de San Luis Potosí', value: 'Universidad Autónoma de San Luis Potosí' },
  { label: 'Universidad Autónoma de Santa Ana', value: 'Universidad Autónoma de Santa Ana' },
  { label: 'Universidad Autónoma de Santo Domingo', value: 'Universidad Autónoma de Santo Domingo' },
  { label: 'Universidad Autónoma de Sinaloa', value: 'Universidad Autónoma de Sinaloa' },
  { label: 'Universidad Autónoma de Tamaulipas', value: 'Universidad Autónoma de Tamaulipas' },
  { label: 'Universidad Autónoma de Tlaxcala', value: 'Universidad Autónoma de Tlaxcala' },
  { label: 'Universidad Autónoma de Veracruz Villa Rica', value: 'Universidad Autónoma de Veracruz Villa Rica' },
  { label: 'Universidad Autónoma de Yucatán', value: 'Universidad Autónoma de Yucatán' },
  { label: 'Universidad Autónoma de Zacatecas', value: 'Universidad Autónoma de Zacatecas' },
  { label: 'Universidad Autónoma del Beni José Ballivián', value: 'Universidad Autónoma del Beni José Ballivián' },
  { label: 'Universidad Autónoma del Caribe', value: 'Universidad Autónoma del Caribe' },
  { label: 'Universidad Autónoma del Carmen', value: 'Universidad Autónoma del Carmen' },
  { label: 'Universidad Autónoma del Estado de Hidalgo', value: 'Universidad Autónoma del Estado de Hidalgo' },
  { label: 'Universidad Autónoma del Estado de México', value: 'Universidad Autónoma del Estado de México' },
  { label: 'Universidad Autónoma del Estado de Morelos', value: 'Universidad Autónoma del Estado de Morelos' },
  { label: 'Universidad Autónoma del Noreste', value: 'Universidad Autónoma del Noreste' },
  { label: 'Universidad Autónoma del Paraguay', value: 'Universidad Autónoma del Paraguay' },
  { label: 'Universidad Autonoma del Sur', value: 'Universidad Autonoma del Sur' },
  { label: 'Universidad Autonoma España de Durango', value: 'Universidad Autonoma España de Durango' },
  { label: 'Universidad Autónoma Gabriel René Moreno', value: 'Universidad Autónoma Gabriel René Moreno' },
  { label: 'Universidad Autónoma Juan Misael Saracho', value: 'Universidad Autónoma Juan Misael Saracho' },
  { label: 'Universidad Autónoma Latinoamericana', value: 'Universidad Autónoma Latinoamericana' },
  { label: 'Universidad Autónoma Metropolitana', value: 'Universidad Autónoma Metropolitana' },
  {
    label: 'Universidad Autónoma Metropolitana - Azcapotzalco',
    value: 'Universidad Autónoma Metropolitana - Azcapotzalco',
  },
  {
    label: 'Universidad Autónoma Metropolitana - Iztapalapa',
    value: 'Universidad Autónoma Metropolitana - Iztapalapa',
  },
  {
    label: 'Universidad Autónoma Metropolitana - Xochimilco',
    value: 'Universidad Autónoma Metropolitana - Xochimilco',
  },
  { label: 'Universidad Autonoma Monterrey', value: 'Universidad Autonoma Monterrey' },
  { label: 'Universidad Autónoma Tomás Frías', value: 'Universidad Autónoma Tomás Frías' },
  { label: 'Universidad Bellas Artes', value: 'Universidad Bellas Artes' },
  { label: "Universidad Bernardo O'Higgins", value: "Universidad Bernardo O'Higgins" },
  { label: 'Universidad Bicentenaria de Aragua', value: 'Universidad Bicentenaria de Aragua' },
  { label: 'Universidad Blas Pascal', value: 'Universidad Blas Pascal' },
  { label: 'Universidad Bolivariana', value: 'Universidad Bolivariana' },
  { label: 'Universidad Bolivariana de Venezuela', value: 'Universidad Bolivariana de Venezuela' },
  { label: 'Universidad Braulio Carrillo', value: 'Universidad Braulio Carrillo' },
  { label: 'Universidad CAECE, Buenos Aires', value: 'Universidad CAECE, Buenos Aires' },
  { label: 'Universidad Capitain General Gerardo Barrios', value: 'Universidad Capitain General Gerardo Barrios' },
  { label: 'Universidad Cardenal Herrera-CEU', value: 'Universidad Cardenal Herrera-CEU' },
  { label: 'Universidad Carlos III de Madrid', value: 'Universidad Carlos III de Madrid' },
  { label: 'Universidad Casa Grande', value: 'Universidad Casa Grande' },
  { label: 'Universidad Católica Andres Bello', value: 'Universidad Católica Andres Bello' },
  { label: 'Universidad Católica Anselmo Llorente', value: 'Universidad Católica Anselmo Llorente' },
  {
    label: 'Universidad Católica Argentina Santa Maria de los Buenos Aires',
    value: 'Universidad Católica Argentina Santa Maria de los Buenos Aires',
  },
  { label: 'Universidad Católica Boliviana, Cochabamba', value: 'Universidad Católica Boliviana, Cochabamba' },
  { label: 'Universidad Católica Boliviana, La Paz', value: 'Universidad Católica Boliviana, La Paz' },
  { label: 'Universidad Católica Boliviana, Santa Cruz', value: 'Universidad Católica Boliviana, Santa Cruz' },
  { label: 'Universidad Católica Boliviana, Tarija', value: 'Universidad Católica Boliviana, Tarija' },
  {
    label: 'Universidad Católica Cardenal Raúl Silva Henríquez',
    value: 'Universidad Católica Cardenal Raúl Silva Henríquez',
  },
  { label: 'Universidad Católica Cecilio Acosta', value: 'Universidad Católica Cecilio Acosta' },
  { label: 'Universidad Católica de Ávila', value: 'Universidad Católica de Ávila' },
  { label: 'Universidad Catolica de Azogues', value: 'Universidad Catolica de Azogues' },
  { label: 'Universidad Católica de Colombia', value: 'Universidad Católica de Colombia' },
  { label: 'Universidad Católica de Córdoba', value: 'Universidad Católica de Córdoba' },
  { label: 'Universidad Católica de Cuenca', value: 'Universidad Católica de Cuenca' },
  { label: 'Universidad Católica de Cuyo', value: 'Universidad Católica de Cuyo' },
  { label: 'Universidad Católica de Honduras', value: 'Universidad Católica de Honduras' },
  { label: 'Universidad Católica de La Plata', value: 'Universidad Católica de La Plata' },
  {
    label: 'Universidad Catolica de La Santísima Concepción',
    value: 'Universidad Catolica de La Santísima Concepción',
  },
  { label: 'Universidad Católica de Manizales', value: 'Universidad Católica de Manizales' },
  { label: 'Universidad Católica de Occidente', value: 'Universidad Católica de Occidente' },
  { label: 'Universidad Católica de Salta', value: 'Universidad Católica de Salta' },
  { label: 'Universidad Católica de Santa Fé', value: 'Universidad Católica de Santa Fé' },
  { label: 'Universidad Católica de Santa María', value: 'Universidad Católica de Santa María' },
  { label: 'Universidad Católica de Santiago de Guayaquil', value: 'Universidad Católica de Santiago de Guayaquil' },
  { label: 'Universidad Católica de Santiago del Estero', value: 'Universidad Católica de Santiago del Estero' },
  { label: 'Universidad Católica de Santo Domingo', value: 'Universidad Católica de Santo Domingo' },
  { label: 'Universidad Catolica de Temuco', value: 'Universidad Catolica de Temuco' },
  { label: 'Universidad Católica de Valencia', value: 'Universidad Católica de Valencia' },
  { label: 'Universidad Catolica de Valparaiso', value: 'Universidad Catolica de Valparaiso' },
  { label: 'Universidad Catolica del Maule', value: 'Universidad Catolica del Maule' },
  { label: 'Universidad Catolica del Norte', value: 'Universidad Catolica del Norte' },
  { label: 'Universidad Católica del Norte', value: 'Universidad Católica del Norte' },
  { label: 'Universidad Católica del Oriente', value: 'Universidad Católica del Oriente' },
  { label: 'Universidad Católica del Táchira', value: 'Universidad Católica del Táchira' },
  { label: 'Universidad Católica del Uruguay', value: 'Universidad Católica del Uruguay' },
  { label: 'Universidad Católica Madre y Maestra', value: 'Universidad Católica Madre y Maestra' },
  { label: 'Universidad Católica Nordestana', value: 'Universidad Católica Nordestana' },
  {
    label: 'Universidad Católica Nuestra Senora de la Asunción',
    value: 'Universidad Católica Nuestra Senora de la Asunción',
  },
  { label: 'Universidad Católica Popular del Risaralda', value: 'Universidad Católica Popular del Risaralda' },
  { label: 'Universidad Catolica Redemptoris Mater', value: 'Universidad Catolica Redemptoris Mater' },
  { label: 'Universidad Católica San Antonio', value: 'Universidad Católica San Antonio' },
  { label: 'Universidad Católica Santa María La Antigua', value: 'Universidad Católica Santa María La Antigua' },
  {
    label: 'Universidad Católica Santo Toribio de Mogrovejo',
    value: 'Universidad Católica Santo Toribio de Mogrovejo',
  },
  { label: 'Universidad Católica Tecnológica del Cibao', value: 'Universidad Católica Tecnológica del Cibao' },
  { label: 'Universidad Central', value: 'Universidad Central' },
  { label: 'Universidad Central', value: 'Universidad Central' },
  { label: 'Universidad Central', value: 'Universidad Central' },
  { label: 'Universidad Central', value: 'Universidad Central' },
  { label: 'Universidad Central de Bayamón', value: 'Universidad Central de Bayamón' },
  { label: 'Universidad Central de Las Villas', value: 'Universidad Central de Las Villas' },
  { label: 'Universidad Central de Nicaragua', value: 'Universidad Central de Nicaragua' },
  { label: 'Universidad Central de Venezuela', value: 'Universidad Central de Venezuela' },
  { label: 'Universidad Central del Caribe', value: 'Universidad Central del Caribe' },
  { label: 'Universidad Central del Ecuador', value: 'Universidad Central del Ecuador' },
  { label: 'Universidad Central del Este', value: 'Universidad Central del Este' },
  { label: 'Universidad Central del Valle del Cauca', value: 'Universidad Central del Valle del Cauca' },
  {
    label: 'Universidad Central Dominicana de Estudio Profesionales',
    value: 'Universidad Central Dominicana de Estudio Profesionales',
  },
  {
    label: 'Universidad Centro Occidental Lisandro Alvarado',
    value: 'Universidad Centro Occidental Lisandro Alvarado',
  },
  { label: 'Universidad Centroamericana', value: 'Universidad Centroamericana' },
  {
    label: 'Universidad Centroamericana de Ciencias Empresariales (UCEM)',
    value: 'Universidad Centroamericana de Ciencias Empresariales (UCEM)',
  },
  { label: 'Universidad Centroamericana José Simeón Canas', value: 'Universidad Centroamericana José Simeón Canas' },
  { label: 'Universidad CES', value: 'Universidad CES' },
  { label: 'Universidad César Vallejo', value: 'Universidad César Vallejo' },
  { label: 'Universidad Champagnat', value: 'Universidad Champagnat' },
  { label: 'Universidad Champagnat', value: 'Universidad Champagnat' },
  { label: 'Universidad Chapultepéc', value: 'Universidad Chapultepéc' },
  { label: 'Universidad Chileno Britanica de Cultura', value: 'Universidad Chileno Britanica de Cultura' },
  { label: 'Universidad Ciencias Comerciales', value: 'Universidad Ciencias Comerciales' },
  { label: 'Universidad Cientifica del Sur', value: 'Universidad Cientifica del Sur' },
  {
    label: 'Universidad Cientifica Latinoamericana de Hidalgo',
    value: 'Universidad Cientifica Latinoamericana de Hidalgo',
  },
  { label: 'Universidad Columbia del Paraguay', value: 'Universidad Columbia del Paraguay' },
  { label: 'Universidad Complutense de Madrid', value: 'Universidad Complutense de Madrid' },
  { label: 'Universidad Comunera', value: 'Universidad Comunera' },
  { label: 'Universidad Cooperativa de Colombia', value: 'Universidad Cooperativa de Colombia' },
  { label: 'Universidad Cristiana de las Asambleas de Dios', value: 'Universidad Cristiana de las Asambleas de Dios' },
  { label: 'Universidad Cristóbal Colón', value: 'Universidad Cristóbal Colón' },
  { label: 'Universidad Cuauhtémoc', value: 'Universidad Cuauhtémoc' },
  { label: 'Universidad de Aconcagua', value: 'Universidad de Aconcagua' },
  { label: 'Universidad de Alcalá de Henares', value: 'Universidad de Alcalá de Henares' },
  { label: 'Universidad de Alicante', value: 'Universidad de Alicante' },
  { label: 'Universidad de Almería', value: 'Universidad de Almería' },
  { label: 'Universidad de América Bogotá', value: 'Universidad de América Bogotá' },
  { label: 'Universidad de Antioquia', value: 'Universidad de Antioquia' },
  { label: 'Universidad de Antioquía', value: 'Universidad de Antioquía' },
  { label: 'Universidad de Antofagasta', value: 'Universidad de Antofagasta' },
  { label: 'Universidad de Aquino Bolivia', value: 'Universidad de Aquino Bolivia' },
  { label: 'Universidad de Artes, Ciencias y Comunicación', value: 'Universidad de Artes, Ciencias y Comunicación' },
  { label: 'Universidad de Atacama', value: 'Universidad de Atacama' },
  { label: 'Universidad de Belgrano', value: 'Universidad de Belgrano' },
  { label: 'Universidad de Bogotá Jorge Tadeo Lozano', value: 'Universidad de Bogotá Jorge Tadeo Lozano' },
  {
    label: 'Universidad de Bologna - Representación en Buenos Aires',
    value: 'Universidad de Bologna - Representación en Buenos Aires',
  },
  { label: 'Universidad de Buenos Aires', value: 'Universidad de Buenos Aires' },
  { label: 'Universidad de Burgos', value: 'Universidad de Burgos' },
  { label: 'Universidad de Cádiz', value: 'Universidad de Cádiz' },
  { label: 'Universidad de Caldas', value: 'Universidad de Caldas' },
  { label: 'Universidad de Camagüey', value: 'Universidad de Camagüey' },
  { label: 'Universidad de Cantabria', value: 'Universidad de Cantabria' },
  { label: 'Universidad de Carabobo', value: 'Universidad de Carabobo' },
  { label: 'Universidad de Cartagena', value: 'Universidad de Cartagena' },
  { label: 'Universidad de Cartago', value: 'Universidad de Cartago' },
  { label: 'Universidad de Cartago Florencio del Castillo', value: 'Universidad de Cartago Florencio del Castillo' },
  { label: 'Universidad de Castilla La Mancha', value: 'Universidad de Castilla La Mancha' },
  { label: 'Universidad de Celaya', value: 'Universidad de Celaya' },
  { label: 'Universidad de Chiclayo', value: 'Universidad de Chiclayo' },
  { label: 'Universidad de Chile', value: 'Universidad de Chile' },
  { label: 'Universidad de Ciego de Avila', value: 'Universidad de Ciego de Avila' },
  {
    label: 'Universidad de Ciencias Aplicadas y Ambientales (UDCA)',
    value: 'Universidad de Ciencias Aplicadas y Ambientales (UDCA)',
  },
  { label: 'Universidad de Ciencias de la Informatica', value: 'Universidad de Ciencias de la Informatica' },
  {
    label: 'Universidad de Ciencias Empresariales y Sociales',
    value: 'Universidad de Ciencias Empresariales y Sociales',
  },
  { label: 'Universidad de Ciencias y Artes de Chiapas', value: 'Universidad de Ciencias y Artes de Chiapas' },
  { label: 'Universidad de Ciencias y Humanidades', value: 'Universidad de Ciencias y Humanidades' },
  { label: 'Universidad de Cienfuegos', value: 'Universidad de Cienfuegos' },
  { label: 'Universidad de Colima', value: 'Universidad de Colima' },
  { label: 'Universidad de Comunicación Avanzada', value: 'Universidad de Comunicación Avanzada' },
  { label: 'Universidad de Concepcion', value: 'Universidad de Concepcion' },
  { label: 'Universidad de Concepción del Uruguay', value: 'Universidad de Concepción del Uruguay' },
  { label: 'Universidad de Congreso', value: 'Universidad de Congreso' },
  { label: 'Universidad de Córdoba', value: 'Universidad de Córdoba' },
  { label: 'Universidad de Córdoba', value: 'Universidad de Córdoba' },
  { label: 'Universidad de Costa Rica', value: 'Universidad de Costa Rica' },
  { label: 'Universidad de Cuautitlan Izcalli', value: 'Universidad de Cuautitlan Izcalli' },
  { label: 'Universidad de Cuenca', value: 'Universidad de Cuenca' },
  { label: 'Universidad de Deusto', value: 'Universidad de Deusto' },
  { label: 'Universidad de El Salvador', value: 'Universidad de El Salvador' },
  {
    label: 'Universidad de Especialidades del Espíritu Santo',
    value: 'Universidad de Especialidades del Espíritu Santo',
  },
  { label: 'Universidad de Especialidades Turísticas ', value: 'Universidad de Especialidades Turísticas ' },
  { label: 'Universidad de Extremadura', value: 'Universidad de Extremadura' },
  { label: 'Universidad de Flores - Buenos Aires', value: 'Universidad de Flores - Buenos Aires' },
  { label: 'Universidad de Gerona', value: 'Universidad de Gerona' },
  { label: 'Universidad de Granada', value: 'Universidad de Granada' },
  { label: 'Universidad de Granma', value: 'Universidad de Granma' },
  { label: 'Universidad de Guadalajara', value: 'Universidad de Guadalajara' },
  { label: 'Universidad de Guanajuato', value: 'Universidad de Guanajuato' },
  { label: 'Universidad de Guanajuato, Campus León', value: 'Universidad de Guanajuato, Campus León' },
  { label: 'Universidad de Guayaquil', value: 'Universidad de Guayaquil' },
  { label: 'Universidad de Hermosillo', value: 'Universidad de Hermosillo' },
  { label: 'Universidad de Holguín', value: 'Universidad de Holguín' },
  { label: 'Universidad de Huánuco', value: 'Universidad de Huánuco' },
  { label: 'Universidad de Huelva', value: 'Universidad de Huelva' },
  { label: 'Universidad de Iberoamérica', value: 'Universidad de Iberoamérica' },
  { label: 'Universidad de Jaén', value: 'Universidad de Jaén' },
  { label: 'Universidad de La Amazonia', value: 'Universidad de La Amazonia' },
  { label: 'Universidad de La Coruña', value: 'Universidad de La Coruña' },
  { label: 'Universidad de la Cuenca del Plata', value: 'Universidad de la Cuenca del Plata' },
  { label: 'Universidad de la Empresa (UDE)', value: 'Universidad de la Empresa (UDE)' },
  {
    label: 'Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)',
    value: 'Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)',
  },
  { label: 'Universidad de La Frontera', value: 'Universidad de La Frontera' },
  { label: 'Universidad de La Guajira', value: 'Universidad de La Guajira' },
  { label: 'Universidad de La Habana', value: 'Universidad de La Habana' },
  {
    label: 'Universidad de la Integración de las Americas (UNIDAD)',
    value: 'Universidad de la Integración de las Americas (UNIDAD)',
  },
  { label: 'Universidad de La Laguna', value: 'Universidad de La Laguna' },
  { label: 'Universidad de la Marina Mercante', value: 'Universidad de la Marina Mercante' },
  { label: 'Universidad de la República', value: 'Universidad de la República' },
  { label: 'Universidad de La Rioja', value: 'Universidad de La Rioja' },
  { label: 'Universidad de La Sabana', value: 'Universidad de La Sabana' },
  { label: 'Universidad de La Salle', value: 'Universidad de La Salle' },
  { label: 'Universidad de La Salle', value: 'Universidad de La Salle' },
  { label: 'Universidad de La Salle, Bajío', value: 'Universidad de La Salle, Bajío' },
  { label: 'Universidad de La Serena', value: 'Universidad de La Serena' },
  { label: 'Universidad de la Sierra A.C.', value: 'Universidad de la Sierra A.C.' },
  { label: 'Universidad de la Tercera Edad', value: 'Universidad de la Tercera Edad' },
  { label: 'Universidad de Lambayeque', value: 'Universidad de Lambayeque' },
  { label: 'Universidad de las Américas', value: 'Universidad de las Américas' },
  { label: 'Universidad de Las Américas', value: 'Universidad de Las Américas' },
  { label: 'Universidad de las Américas - Puebla', value: 'Universidad de las Américas - Puebla' },
  { label: 'Universidad de las Américas (ULAM)', value: 'Universidad de las Américas (ULAM)' },
  { label: 'Universidad de Las Palmas de Gran Canaria', value: 'Universidad de Las Palmas de Gran Canaria' },
  {
    label: 'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense',
    value: 'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense',
  },
  { label: 'Universidad de León', value: 'Universidad de León' },
  { label: 'Universidad de León', value: 'Universidad de León' },
  { label: 'Universidad de Lima', value: 'Universidad de Lima' },
  { label: 'Universidad de Los Andes', value: 'Universidad de Los Andes' },
  { label: 'Universidad de Los Andes', value: 'Universidad de Los Andes' },
  { label: 'Universidad de Los Andes', value: 'Universidad de Los Andes' },
  { label: 'Universidad de Los Lagos', value: 'Universidad de Los Lagos' },
  { label: 'Universidad de Los Llanos', value: 'Universidad de Los Llanos' },
  { label: 'Universidad de Magallanes', value: 'Universidad de Magallanes' },
  { label: 'Universidad de Málaga', value: 'Universidad de Málaga' },
  { label: 'Universidad de Managua (U de M)', value: 'Universidad de Managua (U de M)' },
  { label: 'Universidad de Manizales', value: 'Universidad de Manizales' },
  { label: 'Universidad de Matanzas Camilo Cienfuegos', value: 'Universidad de Matanzas Camilo Cienfuegos' },
  { label: 'Universidad de Medellín', value: 'Universidad de Medellín' },
  { label: 'Universidad de Mendoza', value: 'Universidad de Mendoza' },
  { label: 'Universidad de Montemorelos', value: 'Universidad de Montemorelos' },
  { label: 'Universidad de Monterrey', value: 'Universidad de Monterrey' },
  { label: 'Universidad de Montevideo', value: 'Universidad de Montevideo' },
  { label: 'Universidad de Morón', value: 'Universidad de Morón' },
  { label: 'Universidad de Murcia', value: 'Universidad de Murcia' },
  { label: 'Universidad de Nariño', value: 'Universidad de Nariño' },
  { label: 'Universidad de Navarra', value: 'Universidad de Navarra' },
  { label: 'Universidad de Norteamerica', value: 'Universidad de Norteamerica' },
  { label: 'Universidad de Occidente', value: 'Universidad de Occidente' },
  { label: 'Universidad de Oriente', value: 'Universidad de Oriente' },
  { label: 'Universidad de Oriente', value: 'Universidad de Oriente' },
  { label: 'Universidad de Oriente', value: 'Universidad de Oriente' },
  { label: 'Universidad de Oviedo', value: 'Universidad de Oviedo' },
  { label: 'Universidad de Palermo', value: 'Universidad de Palermo' },
  { label: 'Universidad de Pamplona', value: 'Universidad de Pamplona' },
  { label: 'Universidad de Panamá', value: 'Universidad de Panamá' },
  { label: 'Universidad de Pinar del Río', value: 'Universidad de Pinar del Río' },
  { label: 'Universidad de Piura', value: 'Universidad de Piura' },
  {
    label: 'Universidad de Playa Ancha de Ciencias de la Educacion',
    value: 'Universidad de Playa Ancha de Ciencias de la Educacion',
  },
  { label: 'Universidad de Puerto Rico', value: 'Universidad de Puerto Rico' },
  { label: 'Universidad de Puerto Rico, Aguadilla', value: 'Universidad de Puerto Rico, Aguadilla' },
  { label: 'Universidad de Puerto Rico, Arecibo', value: 'Universidad de Puerto Rico, Arecibo' },
  { label: 'Universidad de Puerto Rico, Bayamon', value: 'Universidad de Puerto Rico, Bayamon' },
  { label: 'Universidad de Puerto Rico, Carolina', value: 'Universidad de Puerto Rico, Carolina' },
  { label: 'Universidad de Puerto Rico, Cayey', value: 'Universidad de Puerto Rico, Cayey' },
  { label: 'Universidad de Puerto Rico, Ciencias Medicas', value: 'Universidad de Puerto Rico, Ciencias Medicas' },
  { label: 'Universidad de Puerto Rico, Humacao', value: 'Universidad de Puerto Rico, Humacao' },
  { label: 'Universidad de Puerto Rico, Mayaguez', value: 'Universidad de Puerto Rico, Mayaguez' },
  { label: 'Universidad de Puerto Rico, Ponce', value: 'Universidad de Puerto Rico, Ponce' },
  { label: 'Universidad de Puerto Rico, Rio Pedras', value: 'Universidad de Puerto Rico, Rio Pedras' },
  { label: 'Universidad de Puerto Rico, Utuado', value: 'Universidad de Puerto Rico, Utuado' },
  { label: 'Universidad de Quintana Roo', value: 'Universidad de Quintana Roo' },
  { label: 'Universidad de Salamanca', value: 'Universidad de Salamanca' },
  { label: 'Universidad de San Andres', value: 'Universidad de San Andres' },
  { label: 'Universidad de San Andres', value: 'Universidad de San Andres' },
  { label: 'Universidad de San Buenaventura', value: 'Universidad de San Buenaventura' },
  { label: 'Universidad de San Buenaventura Medellin', value: 'Universidad de San Buenaventura Medellin' },
  { label: 'Universidad de San Carlos de Guatemala', value: 'Universidad de San Carlos de Guatemala' },
  { label: 'Universidad de San José', value: 'Universidad de San José' },
  { label: 'Universidad de San Miguel', value: 'Universidad de San Miguel' },
  { label: 'Universidad de San Pablo CEU', value: 'Universidad de San Pablo CEU' },
  { label: 'Universidad de San Pedro Sula', value: 'Universidad de San Pedro Sula' },
  { label: 'Universidad de Santiago de Chile', value: 'Universidad de Santiago de Chile' },
  { label: 'Universidad de Santiago de Compostela', value: 'Universidad de Santiago de Compostela' },
  { label: 'Universidad de Sevilla', value: 'Universidad de Sevilla' },
  { label: 'Universidad de Sonora', value: 'Universidad de Sonora' },
  { label: 'Universidad de Sonsonate', value: 'Universidad de Sonsonate' },
  { label: 'Universidad de Sucre', value: 'Universidad de Sucre' },
  { label: 'Universidad de Talca', value: 'Universidad de Talca' },
  { label: 'Universidad de Tarapacá', value: 'Universidad de Tarapacá' },
  { label: 'Universidad de Valencia', value: 'Universidad de Valencia' },
  { label: 'Universidad de Valladolid', value: 'Universidad de Valladolid' },
  { label: 'Universidad de Valparaiso', value: 'Universidad de Valparaiso' },
  { label: 'Universidad de Vigo', value: 'Universidad de Vigo' },
  { label: 'Universidad de Viña del Mar', value: 'Universidad de Viña del Mar' },
  { label: 'Universidad de Zaragoza', value: 'Universidad de Zaragoza' },
  { label: 'Universidad del Aconcagua', value: 'Universidad del Aconcagua' },
  { label: 'Universidad del Atlántico', value: 'Universidad del Atlántico' },
  { label: 'Universidad del Azuay', value: 'Universidad del Azuay' },
  { label: 'Universidad del Bajío', value: 'Universidad del Bajío' },
  { label: 'Universidad del Bío-Bío', value: 'Universidad del Bío-Bío' },
  { label: 'Universidad del Caribe', value: 'Universidad del Caribe' },
  { label: 'Universidad del Cauca', value: 'Universidad del Cauca' },
  { label: 'Universidad del Centro de México', value: 'Universidad del Centro de México' },
  {
    label: 'Universidad del Centro Educativo Latinoamericano Rosario',
    value: 'Universidad del Centro Educativo Latinoamericano Rosario',
  },
  { label: 'Universidad del Cine - Buenos Aires', value: 'Universidad del Cine - Buenos Aires' },
  { label: 'Universidad del Cono Sur de las Américas', value: 'Universidad del Cono Sur de las Américas' },
  { label: 'Universidad del Desarrollo', value: 'Universidad del Desarrollo' },
  { label: 'Universidad del Istmo', value: 'Universidad del Istmo' },
  { label: 'Universidad del Istmo', value: 'Universidad del Istmo' },
  { label: 'Universidad del Magdalena', value: 'Universidad del Magdalena' },
  { label: 'Universidad del Mar', value: 'Universidad del Mar' },
  { label: 'Universidad del Mar', value: 'Universidad del Mar' },
  { label: 'Universidad del Mayab', value: 'Universidad del Mayab' },
  { label: 'Universidad del Museo Social Argentino', value: 'Universidad del Museo Social Argentino' },
  { label: 'Universidad del Noreste', value: 'Universidad del Noreste' },
  { label: 'Universidad del Noroeste', value: 'Universidad del Noroeste' },
  { label: 'Universidad del Norte', value: 'Universidad del Norte' },
  { label: 'Universidad del Norte', value: 'Universidad del Norte' },
  { label: 'Universidad del Norte', value: 'Universidad del Norte' },
  { label: 'Universidad del Norte Santo Tomás de Aquino', value: 'Universidad del Norte Santo Tomás de Aquino' },
  { label: 'Universidad del Pacifico', value: 'Universidad del Pacifico' },
  { label: 'Universidad del Pacifico', value: 'Universidad del Pacifico' },
  { label: 'Universidad del Pacífico', value: 'Universidad del Pacífico' },
  { label: 'Universidad del País Vasco', value: 'Universidad del País Vasco' },
  { label: 'Universidad del Quindío', value: 'Universidad del Quindío' },
  { label: 'Universidad del Rosario', value: 'Universidad del Rosario' },
  { label: 'Universidad del Sagrado Corazon', value: 'Universidad del Sagrado Corazon' },
  { label: 'Universidad del Salvador', value: 'Universidad del Salvador' },
  { label: 'Universidad del Sinú', value: 'Universidad del Sinú' },
  { label: 'Universidad del Tepeyac', value: 'Universidad del Tepeyac' },
  { label: 'Universidad del Tolima', value: 'Universidad del Tolima' },
  { label: 'Universidad del Turabo', value: 'Universidad del Turabo' },
  { label: 'Universidad del Turismo', value: 'Universidad del Turismo' },
  { label: 'Universidad del Valle de Atemajac', value: 'Universidad del Valle de Atemajac' },
  { label: 'Universidad del Valle de Guatemala', value: 'Universidad del Valle de Guatemala' },
  { label: 'Universidad del Valle de México', value: 'Universidad del Valle de México' },
  { label: 'Universidad del Valle de Puebla', value: 'Universidad del Valle de Puebla' },
  { label: 'Universidad del Valle de Toluca', value: 'Universidad del Valle de Toluca' },
  { label: 'Universidad del Valle del Bravo', value: 'Universidad del Valle del Bravo' },
  { label: 'Universidad del Valle del Cauca', value: 'Universidad del Valle del Cauca' },
  { label: 'Universidad del Zulia', value: 'Universidad del Zulia' },
  { label: 'Universidad Diego Portales', value: 'Universidad Diego Portales' },
  { label: 'Universidad Distral Francisco José de Caldas', value: 'Universidad Distral Francisco José de Caldas' },
  { label: 'Universidad Domingo Savio', value: 'Universidad Domingo Savio' },
  { label: 'Universidad Dominicana O&M', value: 'Universidad Dominicana O&M' },
  { label: 'Universidad Dominico-Americana', value: 'Universidad Dominico-Americana' },
  { label: 'Universidad Don Bosco', value: 'Universidad Don Bosco' },
  { label: 'Universidad Don Vasco', value: 'Universidad Don Vasco' },
  { label: 'Universidad Dr. Andreas Bello', value: 'Universidad Dr. Andreas Bello' },
  { label: 'Universidad Dr. Jose Matias Delgado', value: 'Universidad Dr. Jose Matias Delgado' },
  { label: 'Universidad Dr. Rafael Belloso Chacín', value: 'Universidad Dr. Rafael Belloso Chacín' },
  { label: 'Universidad EAFIT', value: 'Universidad EAFIT' },
  { label: 'Universidad El Bosque', value: 'Universidad El Bosque' },
  { label: 'Universidad Empresarial', value: 'Universidad Empresarial' },
  { label: 'Universidad Empresarial Mateo Kuljis', value: 'Universidad Empresarial Mateo Kuljis' },
  { label: 'Universidad Empresarial Siglio 21', value: 'Universidad Empresarial Siglio 21' },
  { label: 'Universidad España de Durango', value: 'Universidad España de Durango' },
  { label: 'Universidad Especializada de Las Americas', value: 'Universidad Especializada de Las Americas' },
  { label: 'Universidad Estatal a Distancia', value: 'Universidad Estatal a Distancia' },
  { label: 'Universidad Estatal de Bolívar', value: 'Universidad Estatal de Bolívar' },
  { label: 'Universidad Eugenio Maria de Hostos', value: 'Universidad Eugenio Maria de Hostos' },
  { label: 'Universidad Europea de Madrid', value: 'Universidad Europea de Madrid' },
  { label: 'Universidad Evangélica de El Salvador', value: 'Universidad Evangélica de El Salvador' },
  { label: 'Universidad Experimental Felix Adam', value: 'Universidad Experimental Felix Adam' },
  { label: 'Universidad Externado de Colombia', value: 'Universidad Externado de Colombia' },
  { label: 'Universidad Federico Henríquez y Carvajal', value: 'Universidad Federico Henríquez y Carvajal' },
  { label: 'Universidad Femenina del Sagrado Corazón', value: 'Universidad Femenina del Sagrado Corazón' },
  { label: 'Universidad Fermin Toro', value: 'Universidad Fermin Toro' },
  { label: 'Universidad Fidélitas', value: 'Universidad Fidélitas' },
  { label: 'Universidad Finis Terrae', value: 'Universidad Finis Terrae' },
  { label: 'Universidad Franciscana de México', value: 'Universidad Franciscana de México' },
  { label: 'Universidad Francisco de Aguirre', value: 'Universidad Francisco de Aguirre' },
  { label: 'Universidad Francisco de Paula Santander', value: 'Universidad Francisco de Paula Santander' },
  { label: 'Universidad Francisco de Vitoria', value: 'Universidad Francisco de Vitoria' },
  { label: 'Universidad Francisco Gavidia', value: 'Universidad Francisco Gavidia' },
  { label: 'Universidad Francisco Marroquín', value: 'Universidad Francisco Marroquín' },
  { label: 'Universidad Franco-Mexicana', value: 'Universidad Franco-Mexicana' },
  { label: 'Universidad Fray Luca Paccioli', value: 'Universidad Fray Luca Paccioli' },
  { label: 'Universidad Fundepos Alma Mater', value: 'Universidad Fundepos Alma Mater' },
  { label: 'Universidad Gabriela Mistral', value: 'Universidad Gabriela Mistral' },
  { label: 'Universidad Galileo', value: 'Universidad Galileo' },
  { label: 'Universidad Gran Mariscal de Ayacucho', value: 'Universidad Gran Mariscal de Ayacucho' },
  { label: 'Universidad Hispanoamericana', value: 'Universidad Hispanoamericana' },
  { label: 'Universidad Hispanoamericana', value: 'Universidad Hispanoamericana' },
  { label: 'Universidad Hispanoamericana', value: 'Universidad Hispanoamericana' },
  { label: 'Universidad Iberoamericana', value: 'Universidad Iberoamericana' },
  { label: 'Universidad Iberoamericana', value: 'Universidad Iberoamericana' },
  {
    label: 'Universidad Iberoamericana de Ciencia y Tecnologia',
    value: 'Universidad Iberoamericana de Ciencia y Tecnologia',
  },
  {
    label: 'Universidad Iberoamericana de Ciencias y Tecnologia',
    value: 'Universidad Iberoamericana de Ciencias y Tecnologia',
  },
  { label: 'Universidad Iberoamericana, Campus León', value: 'Universidad Iberoamericana, Campus León' },
  { label: 'Universidad ICESI', value: 'Universidad ICESI' },
  { label: 'Universidad INCCA de Colombia', value: 'Universidad INCCA de Colombia' },
  { label: 'Universidad Industrial de Santander', value: 'Universidad Industrial de Santander' },
  { label: 'Universidad Insurgentes Plantel León', value: 'Universidad Insurgentes Plantel León' },
  { label: 'Universidad Interamericana', value: 'Universidad Interamericana' },
  {
    label: 'Universidad Interamericana de Educacion a Distancia de Panama',
    value: 'Universidad Interamericana de Educacion a Distancia de Panama',
  },
  { label: 'Universidad Interamericana de Puerto Rico', value: 'Universidad Interamericana de Puerto Rico' },
  {
    label: 'Universidad Interamericana de Puerto Rico, Bayamón',
    value: 'Universidad Interamericana de Puerto Rico, Bayamón',
  },
  {
    label: 'Universidad Interamericana de Puerto Rico, San Germán',
    value: 'Universidad Interamericana de Puerto Rico, San Germán',
  },
  { label: 'Universidad Intercontinental', value: 'Universidad Intercontinental' },
  { label: 'Universidad Internacional de Andalucía', value: 'Universidad Internacional de Andalucía' },
  {
    label: 'Universidad Internacional de Andalucía, Sede Antonio Machado de Baeza',
    value: 'Universidad Internacional de Andalucía, Sede Antonio Machado de Baeza',
  },
  {
    label: 'Universidad Internacional de Andalucía, Sede La Rábida',
    value: 'Universidad Internacional de Andalucía, Sede La Rábida',
  },
  {
    label: 'Universidad Internacional de Integración de América Latina',
    value: 'Universidad Internacional de Integración de América Latina',
  },
  { label: 'Universidad Internacional de las Américas', value: 'Universidad Internacional de las Américas' },
  { label: 'Universidad Internacional del Ecuador', value: 'Universidad Internacional del Ecuador' },
  { label: 'Universidad Internacional Menéndez Pelayo', value: 'Universidad Internacional Menéndez Pelayo' },
  { label: 'Universidad Internacional SEK, Quito', value: 'Universidad Internacional SEK, Quito' },
  { label: 'Universidad International SEK, Santiago', value: 'Universidad International SEK, Santiago' },
  { label: 'Universidad Isaac Newton', value: 'Universidad Isaac Newton' },
  { label: 'Universidad Isaac Newton', value: 'Universidad Isaac Newton' },
  { label: 'Universidad ISALUD', value: 'Universidad ISALUD' },
  { label: 'Universidad Itaca', value: 'Universidad Itaca' },
  { label: 'Universidad José Antonio Páez', value: 'Universidad José Antonio Páez' },
  { label: 'Universidad José Cecilio del Valle', value: 'Universidad José Cecilio del Valle' },
  { label: 'Universidad José Maria Vargas', value: 'Universidad José Maria Vargas' },
  { label: 'Universidad José Santos Ossa', value: 'Universidad José Santos Ossa' },
  { label: 'Universidad Jóse Vasconcelos', value: 'Universidad Jóse Vasconcelos' },
  { label: 'Universidad Juan Agustín Maza', value: 'Universidad Juan Agustín Maza' },
  { label: 'Universidad Juan Misael Saracho', value: 'Universidad Juan Misael Saracho' },
  { label: 'Universidad Juárez Autónoma de Tabasco', value: 'Universidad Juárez Autónoma de Tabasco' },
  { label: 'Universidad Juárez del Estado de Durango', value: 'Universidad Juárez del Estado de Durango' },
  { label: 'Universidad Justo Sierra', value: 'Universidad Justo Sierra' },
  { label: 'Universidad Kino', value: 'Universidad Kino' },
  { label: 'Universidad la Concordia', value: 'Universidad la Concordia' },
  { label: 'Universidad La Gran Colombia', value: 'Universidad La Gran Colombia' },
  { label: 'Universidad La Republica', value: 'Universidad La Republica' },
  { label: 'Universidad La Salle', value: 'Universidad La Salle' },
  { label: 'Universidad La Salle Cuernavaca', value: 'Universidad La Salle Cuernavaca' },
  { label: 'Universidad Laica Eloy Alfaro de Manabi', value: 'Universidad Laica Eloy Alfaro de Manabi' },
  {
    label: 'Universidad Laica Vicente Rocafuerte de Guayaquil',
    value: 'Universidad Laica Vicente Rocafuerte de Guayaquil',
  },
  { label: 'Universidad Lasallista Benavente', value: 'Universidad Lasallista Benavente' },
  { label: 'Universidad Latina', value: 'Universidad Latina' },
  { label: 'Universidad Latina de Costa Rica', value: 'Universidad Latina de Costa Rica' },
  { label: 'Universidad Latina de Panamá', value: 'Universidad Latina de Panamá' },
  { label: 'Universidad Latinoamericana', value: 'Universidad Latinoamericana' },
  {
    label: 'Universidad Latinoamericana de Ciencia y Tecnología',
    value: 'Universidad Latinoamericana de Ciencia y Tecnología',
  },
  {
    label: 'Universidad Latinoamericana de Ciencia y Tecnologia, Panamá',
    value: 'Universidad Latinoamericana de Ciencia y Tecnologia, Panamá',
  },
  { label: 'Universidad Libre de Colombia', value: 'Universidad Libre de Colombia' },
  { label: 'Universidad Libre de Costa Rica', value: 'Universidad Libre de Costa Rica' },
  { label: 'Universidad Los Andes', value: 'Universidad Los Andes' },
  { label: 'Universidad Los Angeles de Chimbote', value: 'Universidad Los Angeles de Chimbote' },
  { label: 'Universidad Luterana Salvadorena', value: 'Universidad Luterana Salvadorena' },
  { label: 'Universidad Madero', value: 'Universidad Madero' },
  { label: 'Universidad Maimónides', value: 'Universidad Maimónides' },
  { label: 'Universidad Marcelino Champagnat', value: 'Universidad Marcelino Champagnat' },
  { label: 'Universidad Mariana', value: 'Universidad Mariana' },
  { label: 'Universidad Mariano Egaña', value: 'Universidad Mariano Egaña' },
  { label: 'Universidad Mariano Gálvez', value: 'Universidad Mariano Gálvez' },
  { label: 'Universidad Maritima de Chile', value: 'Universidad Maritima de Chile' },
  { label: 'Universidad Mayor', value: 'Universidad Mayor' },
  { label: 'Universidad Mayor de San Andrés', value: 'Universidad Mayor de San Andrés' },
  { label: 'Universidad Mayor de San Simón', value: 'Universidad Mayor de San Simón' },
  { label: 'Universidad Mesoamericana de San Agustín', value: 'Universidad Mesoamericana de San Agustín' },
  { label: 'Universidad Metropolitana', value: 'Universidad Metropolitana' },
  { label: 'Universidad Metropolitana', value: 'Universidad Metropolitana' },
  { label: 'Universidad Metropolitana', value: 'Universidad Metropolitana' },
  { label: 'Universidad Metropolitana Castro Carazo', value: 'Universidad Metropolitana Castro Carazo' },
  { label: 'Universidad Metropolitana de Asunción', value: 'Universidad Metropolitana de Asunción' },
  {
    label: 'Universidad Metropolitana de Ciencias de la Educación',
    value: 'Universidad Metropolitana de Ciencias de la Educación',
  },
  { label: 'Universidad Metropolitana de Honduras', value: 'Universidad Metropolitana de Honduras' },
  { label: 'Universidad Metropolitana de Monterrey', value: 'Universidad Metropolitana de Monterrey' },
  { label: 'Universidad Metropolitana Latin Campus', value: 'Universidad Metropolitana Latin Campus' },
  { label: 'Universidad Mexicana', value: 'Universidad Mexicana' },
  { label: 'Universidad Mexicana del Noreste', value: 'Universidad Mexicana del Noreste' },
  { label: 'Universidad México Americana del Norte', value: 'Universidad México Americana del Norte' },
  { label: 'Universidad México Internacional', value: 'Universidad México Internacional' },
  {
    label: 'Universidad Michoacana de San Nicolás de Hidalgo',
    value: 'Universidad Michoacana de San Nicolás de Hidalgo',
  },
  { label: 'Universidad Miguel de Cervantes', value: 'Universidad Miguel de Cervantes' },
  { label: 'Universidad Miguel Hernández de Elche', value: 'Universidad Miguel Hernández de Elche' },
  { label: 'Universidad Militar Nueva Granada', value: 'Universidad Militar Nueva Granada' },
  { label: 'Universidad Modular Abierta', value: 'Universidad Modular Abierta' },
  { label: 'Universidad Monsenor Oscar Arnulfo Romeo', value: 'Universidad Monsenor Oscar Arnulfo Romeo' },
  { label: 'Universidad Monteávila', value: 'Universidad Monteávila' },
  { label: 'Universidad Motolinía del Pedegral', value: 'Universidad Motolinía del Pedegral' },
  { label: 'Universidad Motolinía del Pedregal', value: 'Universidad Motolinía del Pedregal' },
  { label: 'Universidad Nacional', value: 'Universidad Nacional' },
  { label: 'Universidad Nacional Abierta', value: 'Universidad Nacional Abierta' },
  { label: 'Universidad Nacional Abierta y a Distancia', value: 'Universidad Nacional Abierta y a Distancia' },
  { label: 'Universidad Nacional Agraria', value: 'Universidad Nacional Agraria' },
  { label: 'Universidad Nacional Agraria de la Selva', value: 'Universidad Nacional Agraria de la Selva' },
  { label: 'Universidad Nacional Agraria La Molina', value: 'Universidad Nacional Agraria La Molina' },
  { label: 'Universidad Nacional Agraria La Selva', value: 'Universidad Nacional Agraria La Selva' },
  { label: 'Universidad Nacional Andrés Bello', value: 'Universidad Nacional Andrés Bello' },
  { label: 'Universidad Nacional Autónoma de Honduras', value: 'Universidad Nacional Autónoma de Honduras' },
  { label: 'Universidad Nacional Autónoma de México', value: 'Universidad Nacional Autónoma de México' },
  { label: 'Universidad Nacional Autonoma de Nicaragua', value: 'Universidad Nacional Autonoma de Nicaragua' },
  { label: 'Universidad Nacional Daniel Alcides Carrion', value: 'Universidad Nacional Daniel Alcides Carrion' },
  { label: 'Universidad Nacional de Asunción', value: 'Universidad Nacional de Asunción' },
  { label: 'Universidad Nacional de Cajamarca', value: 'Universidad Nacional de Cajamarca' },
  { label: 'Universidad Nacional de Catamarca', value: 'Universidad Nacional de Catamarca' },
  { label: 'Universidad Nacional de Chimborazo', value: 'Universidad Nacional de Chimborazo' },
  { label: 'Universidad Nacional de Colombia', value: 'Universidad Nacional de Colombia' },
  { label: 'Universidad Nacional de Córdoba', value: 'Universidad Nacional de Córdoba' },
  { label: 'Universidad Nacional de Cuyo', value: 'Universidad Nacional de Cuyo' },
  { label: 'Universidad Nacional de Educación a Distancia', value: 'Universidad Nacional de Educación a Distancia' },
  {
    label: 'Universidad Nacional de Educación Enrique Guzmán y Valle',
    value: 'Universidad Nacional de Educación Enrique Guzmán y Valle',
  },
  { label: 'Universidad Nacional de Entre Ríos', value: 'Universidad Nacional de Entre Ríos' },
  { label: 'Universidad Nacional de Formosa', value: 'Universidad Nacional de Formosa' },
  { label: 'Universidad Nacional de General San Martín', value: 'Universidad Nacional de General San Martín' },
  { label: 'Universidad Nacional de General Sarmiento', value: 'Universidad Nacional de General Sarmiento' },
  { label: 'Universidad Nacional de Guinea Ecuatorial', value: 'Universidad Nacional de Guinea Ecuatorial' },
  { label: 'Universidad Nacional de Ingenieria', value: 'Universidad Nacional de Ingenieria' },
  { label: 'Universidad Nacional de Ingeniería', value: 'Universidad Nacional de Ingeniería' },
  { label: 'Universidad Nacional de Jujuy', value: 'Universidad Nacional de Jujuy' },
  { label: 'Universidad Nacional de la Amazonía Peruana', value: 'Universidad Nacional de la Amazonía Peruana' },
  { label: 'Universidad Nacional de La Libertad, Trujillo', value: 'Universidad Nacional de La Libertad, Trujillo' },
  { label: 'Universidad Nacional de La Matanza', value: 'Universidad Nacional de La Matanza' },
  { label: 'Universidad Nacional de La Pampa', value: 'Universidad Nacional de La Pampa' },
  { label: 'Universidad Nacional de la Patagonia Austral', value: 'Universidad Nacional de la Patagonia Austral' },
  {
    label: 'Universidad Nacional de la Patagonia San Juan Bosco',
    value: 'Universidad Nacional de la Patagonia San Juan Bosco',
  },
  { label: 'Universidad Nacional de La Plata', value: 'Universidad Nacional de La Plata' },
  { label: 'Universidad Nacional de La Rioja', value: 'Universidad Nacional de La Rioja' },
  { label: 'Universidad Nacional de Lanus', value: 'Universidad Nacional de Lanus' },
  { label: 'Universidad Nacional de Loja', value: 'Universidad Nacional de Loja' },
  { label: 'Universidad Nacional de Lomas de Zamora', value: 'Universidad Nacional de Lomas de Zamora' },
  { label: 'Universidad Nacional de Luján', value: 'Universidad Nacional de Luján' },
  { label: 'Universidad Nacional de Mar del Plata', value: 'Universidad Nacional de Mar del Plata' },
  { label: 'Universidad Nacional de Misiones', value: 'Universidad Nacional de Misiones' },
  { label: 'Universidad Nacional de Piura', value: 'Universidad Nacional de Piura' },
  { label: 'Universidad Nacional de Quilmes', value: 'Universidad Nacional de Quilmes' },
  { label: 'Universidad Nacional de Río Cuarto', value: 'Universidad Nacional de Río Cuarto' },
  { label: 'Universidad Nacional de Rosario', value: 'Universidad Nacional de Rosario' },
  { label: 'Universidad Nacional de Salta', value: 'Universidad Nacional de Salta' },
  { label: 'Universidad Nacional de San Agustin', value: 'Universidad Nacional de San Agustin' },
  { label: 'Universidad Nacional de San Antonio Abad', value: 'Universidad Nacional de San Antonio Abad' },
  {
    label: 'Universidad Nacional de San Cristóbal de Huamanga',
    value: 'Universidad Nacional de San Cristóbal de Huamanga',
  },
  { label: 'Universidad Nacional de San Juan', value: 'Universidad Nacional de San Juan' },
  { label: 'Universidad Nacional de San Luis', value: 'Universidad Nacional de San Luis' },
  { label: 'Universidad Nacional de San Martín', value: 'Universidad Nacional de San Martín' },
  { label: 'Universidad Nacional de Santa - Chimbote', value: 'Universidad Nacional de Santa - Chimbote' },
  { label: 'Universidad Nacional de Santiago del Estero', value: 'Universidad Nacional de Santiago del Estero' },
  { label: 'Universidad Nacional de Tres de Febrero', value: 'Universidad Nacional de Tres de Febrero' },
  { label: 'Universidad Nacional de Tucumán', value: 'Universidad Nacional de Tucumán' },
  { label: 'Universidad Nacional de Tumbes', value: 'Universidad Nacional de Tumbes' },
  { label: 'Universidad Nacional de Ucayali', value: 'Universidad Nacional de Ucayali' },
  { label: 'Universidad Nacional de Villa María', value: 'Universidad Nacional de Villa María' },
  { label: 'Universidad Nacional del Altiplano', value: 'Universidad Nacional del Altiplano' },
  { label: 'Universidad Nacional del Callao', value: 'Universidad Nacional del Callao' },
  {
    label: 'Universidad Nacional del Centro de la Provincia de Buenos Aires',
    value: 'Universidad Nacional del Centro de la Provincia de Buenos Aires',
  },
  { label: 'Universidad Nacional del Centro del Perú', value: 'Universidad Nacional del Centro del Perú' },
  { label: 'Universidad Nacional del Comahue', value: 'Universidad Nacional del Comahue' },
  { label: 'Universidad Nacional del Este', value: 'Universidad Nacional del Este' },
  { label: 'Universidad Nacional del Littoral', value: 'Universidad Nacional del Littoral' },
  { label: 'Universidad Nacional del Nordeste', value: 'Universidad Nacional del Nordeste' },
  {
    label: 'Universidad Nacional del Noroeste de la Provincia de Buenos Aires',
    value: 'Universidad Nacional del Noroeste de la Provincia de Buenos Aires',
  },
  { label: 'Universidad Nacional del Sur', value: 'Universidad Nacional del Sur' },
  { label: 'Universidad Nacional Evangélica', value: 'Universidad Nacional Evangélica' },
  { label: 'Universidad Nacional Experimental de Guayana', value: 'Universidad Nacional Experimental de Guayana' },
  {
    label: 'Universidad Nacional Experimental de la Fuerza Armada',
    value: 'Universidad Nacional Experimental de la Fuerza Armada',
  },
  {
    label: 'Universidad Nacional Experimental de los Llanos Occidentales Ezequiel Zamora',
    value: 'Universidad Nacional Experimental de los Llanos Occidentales Ezequiel Zamora',
  },
  { label: 'Universidad Nacional Experimental del Táchira', value: 'Universidad Nacional Experimental del Táchira' },
  {
    label: 'Universidad Nacional Experimental Francisco de Miranda',
    value: 'Universidad Nacional Experimental Francisco de Miranda',
  },
  {
    label: 'Universidad Nacional Experimental Politécnica Antonio José de Sucre',
    value: 'Universidad Nacional Experimental Politécnica Antonio José de Sucre',
  },
  {
    label: 'Universidad Nacional Experimental Rafael Maria Baralt',
    value: 'Universidad Nacional Experimental Rafael Maria Baralt',
  },
  {
    label: 'Universidad Nacional Experimental Romulo Gallegos',
    value: 'Universidad Nacional Experimental Romulo Gallegos',
  },
  {
    label: 'Universidad Nacional Experimental Simón Rodriguez',
    value: 'Universidad Nacional Experimental Simón Rodriguez',
  },
  {
    label: 'Universidad Nacional Experimental Sur del Lago Jesús Maria Semprum',
    value: 'Universidad Nacional Experimental Sur del Lago Jesús Maria Semprum',
  },
  { label: 'Universidad Nacional Federico Villarreal', value: 'Universidad Nacional Federico Villarreal' },
  { label: 'Universidad Nacional Hermilio Valdizan', value: 'Universidad Nacional Hermilio Valdizan' },
  { label: 'Universidad Nacional Jorge Basadre Grohmann', value: 'Universidad Nacional Jorge Basadre Grohmann' },
  {
    label: 'Universidad Nacional José Faustino Sánchez Carrión',
    value: 'Universidad Nacional José Faustino Sánchez Carrión',
  },
  { label: 'Universidad Nacional Mayor de San Marcos', value: 'Universidad Nacional Mayor de San Marcos' },
  { label: 'Universidad Nacional Pedro Henríquez Ureña', value: 'Universidad Nacional Pedro Henríquez Ureña' },
  { label: 'Universidad Nacional Pedro Ruíz Gallo', value: 'Universidad Nacional Pedro Ruíz Gallo' },
  { label: 'Universidad Nacional San Luis Gonzaga', value: 'Universidad Nacional San Luis Gonzaga' },
  {
    label: 'Universidad Nacional Santiago Antúnez de Mayolo',
    value: 'Universidad Nacional Santiago Antúnez de Mayolo',
  },
  { label: 'Universidad Nacional Siglo XX Llallagua', value: 'Universidad Nacional Siglo XX Llallagua' },
  {
    label: 'Universidad Nicaragüense de Ciencia y Tecnológica',
    value: 'Universidad Nicaragüense de Ciencia y Tecnológica',
  },
  { label: 'Universidad Norbert Wiener', value: 'Universidad Norbert Wiener' },
  { label: 'Universidad Notarial Argentina', value: 'Universidad Notarial Argentina' },
  { label: 'Universidad Nuestra Senora de La Paz', value: 'Universidad Nuestra Senora de La Paz' },
  { label: 'Universidad Nueva Esparta', value: 'Universidad Nueva Esparta' },
  { label: 'Universidad Nueva San Salvador', value: 'Universidad Nueva San Salvador' },
  { label: 'Universidad Nuevo Mundo', value: 'Universidad Nuevo Mundo' },
  { label: 'Universidad NUR', value: 'Universidad NUR' },
  { label: 'Universidad Obrera de Mexico', value: 'Universidad Obrera de Mexico' },
  { label: 'Universidad Odontológica Dominicana', value: 'Universidad Odontológica Dominicana' },
  { label: 'Universidad Olmeca', value: 'Universidad Olmeca' },
  { label: 'Universidad ORT Uruguay', value: 'Universidad ORT Uruguay' },
  { label: 'Universidad Pablo de Olavide', value: 'Universidad Pablo de Olavide' },
  { label: 'Universidad Panamericana', value: 'Universidad Panamericana' },
  { label: 'Universidad Panamericana', value: 'Universidad Panamericana' },
  { label: 'Universidad Panamericana de San Salvador', value: 'Universidad Panamericana de San Salvador' },
  { label: 'Universidad para la Cooperación Internacional', value: 'Universidad para la Cooperación Internacional' },
  { label: 'Universidad Particular de Chiclayo', value: 'Universidad Particular de Chiclayo' },
  {
    label: 'Universidad Particular Inca Garcilaso de la Vega',
    value: 'Universidad Particular Inca Garcilaso de la Vega',
  },
  { label: 'Universidad Pedagógica de El Salvador', value: 'Universidad Pedagógica de El Salvador' },
  { label: 'Universidad Pedagógica Experimental Libertador', value: 'Universidad Pedagógica Experimental Libertador' },
  { label: 'Universidad Pedagógica José Martí, Camagüey', value: 'Universidad Pedagógica José Martí, Camagüey' },
  { label: 'Universidad Pedagogica Nacional', value: 'Universidad Pedagogica Nacional' },
  { label: 'Universidad Pedagógica Nacional', value: 'Universidad Pedagógica Nacional' },
  {
    label: 'Universidad Pedagógica Nacional Francisco Morazán',
    value: 'Universidad Pedagógica Nacional Francisco Morazán',
  },
  { label: 'Universidad Pedagógica Nacional, Unidad León', value: 'Universidad Pedagógica Nacional, Unidad León' },
  { label: 'Universidad Pedagógica Veracruzana', value: 'Universidad Pedagógica Veracruzana' },
  {
    label: 'Universidad Pedagógica y Tecnológica de Colombia',
    value: 'Universidad Pedagógica y Tecnológica de Colombia',
  },
  { label: 'Universidad Peruana Cayetano Heredia', value: 'Universidad Peruana Cayetano Heredia' },
  { label: 'Universidad Peruana de Ciencias Aplicadas', value: 'Universidad Peruana de Ciencias Aplicadas' },
  { label: 'Universidad Peruana de Ciencias e Informática', value: 'Universidad Peruana de Ciencias e Informática' },
  { label: 'Universidad Peruana Union', value: 'Universidad Peruana Union' },
  { label: 'Universidad Piloto de Colombia', value: 'Universidad Piloto de Colombia' },
  { label: 'Universidad Politécnica de Cartagena', value: 'Universidad Politécnica de Cartagena' },
  { label: 'Universidad Politécnica de Cataluna', value: 'Universidad Politécnica de Cataluna' },
  { label: 'Universidad Politécnica de El Salvador', value: 'Universidad Politécnica de El Salvador' },
  { label: 'Universidad Politécnica de Madrid', value: 'Universidad Politécnica de Madrid' },
  { label: 'Universidad Politecnica de Nicaragua', value: 'Universidad Politecnica de Nicaragua' },
  { label: 'Universidad Politecnica de Puerto Rico', value: 'Universidad Politecnica de Puerto Rico' },
  { label: 'Universidad Politécnica de Valencia', value: 'Universidad Politécnica de Valencia' },
  { label: 'Universidad Politécnica Salesiana', value: 'Universidad Politécnica Salesiana' },
  { label: 'Universidad Pontificia Bolivariana', value: 'Universidad Pontificia Bolivariana' },
  { label: 'Universidad Pontificia Comillas', value: 'Universidad Pontificia Comillas' },
  { label: 'Universidad Pontificia de Salamanca', value: 'Universidad Pontificia de Salamanca' },
  {
    label: 'Universidad Popular Autonóma del Estado de Puebla',
    value: 'Universidad Popular Autonóma del Estado de Puebla',
  },
  { label: 'Universidad Popular de Nicaragua (UPONIC)', value: 'Universidad Popular de Nicaragua (UPONIC)' },
  { label: 'Universidad Popular del Cesar', value: 'Universidad Popular del Cesar' },
  { label: 'Universidad Privada Abierta Latinoamericana', value: 'Universidad Privada Abierta Latinoamericana' },
  { label: 'Universidad Privada Antenor Orrego', value: 'Universidad Privada Antenor Orrego' },
  { label: 'Universidad Privada Boliviana', value: 'Universidad Privada Boliviana' },
  { label: 'Universidad Privada de Santa Cruz de la Sierra', value: 'Universidad Privada de Santa Cruz de la Sierra' },
  { label: 'Universidad Privada de Tacna', value: 'Universidad Privada de Tacna' },
  { label: 'Universidad Privada del Este', value: 'Universidad Privada del Este' },
  { label: 'Universidad Privada del Norte', value: 'Universidad Privada del Norte' },
  { label: 'Universidad Privada del Valle', value: 'Universidad Privada del Valle' },
  { label: 'Universidad Privada Franz Tamayo (UNIFRANZ)', value: 'Universidad Privada Franz Tamayo (UNIFRANZ)' },
  { label: 'Universidad Privada Juan Mejía Baca', value: 'Universidad Privada Juan Mejía Baca' },
  { label: 'Universidad Privada Los Andes', value: 'Universidad Privada Los Andes' },
  { label: 'Universidad Privada San Juan Bautista', value: 'Universidad Privada San Juan Bautista' },
  { label: 'Universidad Privada San Pedro', value: 'Universidad Privada San Pedro' },
  { label: 'Universidad Privada Sergio Bernales', value: 'Universidad Privada Sergio Bernales' },
  { label: 'Universidad Pública de Navarra', value: 'Universidad Pública de Navarra' },
  { label: 'Universidad Rafael Landívar', value: 'Universidad Rafael Landívar' },
  { label: 'Universidad Rafael Urdaneta', value: 'Universidad Rafael Urdaneta' },
  { label: 'Universidad Regiomontana', value: 'Universidad Regiomontana' },
  { label: 'Universidad Regional del Sureste', value: 'Universidad Regional del Sureste' },
  { label: 'Universidad Regional Miguel Hidalgo', value: 'Universidad Regional Miguel Hidalgo' },
  { label: 'Universidad Rey Juan Carlos', value: 'Universidad Rey Juan Carlos' },
  { label: 'Universidad Ricardo Palma', value: 'Universidad Ricardo Palma' },
  { label: 'Universidad Rural de Guatemala', value: 'Universidad Rural de Guatemala' },
  { label: 'Universidad Salesiana', value: 'Universidad Salesiana' },
  { label: 'Universidad Salesiana de Bolivia', value: 'Universidad Salesiana de Bolivia' },
  { label: 'Universidad Salvadorena Alberto Masferrer', value: 'Universidad Salvadorena Alberto Masferrer' },
  { label: 'Universidad San Francisco de Quito', value: 'Universidad San Francisco de Quito' },
  { label: 'Universidad San Francisco Xavier', value: 'Universidad San Francisco Xavier' },
  { label: 'Universidad San Francisco Xavier de Chuquisaca', value: 'Universidad San Francisco Xavier de Chuquisaca' },
  { label: 'Universidad San Ignacio de Loyola', value: 'Universidad San Ignacio de Loyola' },
  { label: 'Universidad San Juan de la Cruz', value: 'Universidad San Juan de la Cruz' },
  { label: 'Universidad San Juan de la Cruz', value: 'Universidad San Juan de la Cruz' },
  { label: 'Universidad San Marcos', value: 'Universidad San Marcos' },
  { label: 'Universidad San Martin de Porres', value: 'Universidad San Martin de Porres' },
  { label: 'Universidad San Pablo', value: 'Universidad San Pablo' },
  { label: 'Universidad San Sebastian', value: 'Universidad San Sebastian' },
  { label: 'Universidad Santa Fe', value: 'Universidad Santa Fe' },
  { label: 'Universidad Santa María Campus Guayaquil', value: 'Universidad Santa María Campus Guayaquil' },
  { label: 'Universidad Santa Maria Caracas', value: 'Universidad Santa Maria Caracas' },
  { label: 'Universidad Santa Paula', value: 'Universidad Santa Paula' },
  { label: 'Universidad Santiago de Cali', value: 'Universidad Santiago de Cali' },
  { label: 'Universidad Santo Tomás', value: 'Universidad Santo Tomás' },
  { label: 'Universidad Santo Tomás', value: 'Universidad Santo Tomás' },
  { label: 'Universidad Señor de Sipán', value: 'Universidad Señor de Sipán' },
  { label: 'Universidad Sergio Arboleda', value: 'Universidad Sergio Arboleda' },
  { label: 'Universidad Simón Bolivar', value: 'Universidad Simón Bolivar' },
  { label: 'Universidad Simón Bolivar', value: 'Universidad Simón Bolivar' },
  { label: 'Universidad Surcolombiana', value: 'Universidad Surcolombiana' },
  { label: 'Universidad Técnica de Ambato', value: 'Universidad Técnica de Ambato' },
  { label: 'Universidad Técnica de Babahoyo', value: 'Universidad Técnica de Babahoyo' },
  { label: 'Universidad Técnica de Cotopaxi', value: 'Universidad Técnica de Cotopaxi' },
  {
    label: 'Universidad Técnica de Esmeraldas Luis Vargas Torres',
    value: 'Universidad Técnica de Esmeraldas Luis Vargas Torres',
  },
  { label: 'Universidad Técnica de Machala', value: 'Universidad Técnica de Machala' },
  { label: 'Universidad Técnica de Manabí', value: 'Universidad Técnica de Manabí' },
  { label: 'Universidad Técnica de Oruro', value: 'Universidad Técnica de Oruro' },
  { label: 'Universidad Técnica del Norte', value: 'Universidad Técnica del Norte' },
  { label: 'Universidad Técnica Estatal de Quevedo', value: 'Universidad Técnica Estatal de Quevedo' },
  { label: 'Universidad Técnica Federico Santa María', value: 'Universidad Técnica Federico Santa María' },
  { label: 'Universidad Técnica Latinoamericana', value: 'Universidad Técnica Latinoamericana' },
  { label: 'Universidad Técnica Particular de Loja', value: 'Universidad Técnica Particular de Loja' },
  { label: 'Universidad Tecnológica América', value: 'Universidad Tecnológica América' },
  { label: 'Universidad Tecnológica Centroamericana', value: 'Universidad Tecnológica Centroamericana' },
  { label: 'Universidad Tecnológica de El Salvador', value: 'Universidad Tecnológica de El Salvador' },
  { label: 'Universidad Tecnológica de Honduras', value: 'Universidad Tecnológica de Honduras' },
  { label: 'Universidad Tecnologica de la Mixteca', value: 'Universidad Tecnologica de la Mixteca' },
  {
    label: 'Universidad Tecnológica de los Andes de Apurímac',
    value: 'Universidad Tecnológica de los Andes de Apurímac',
  },
  { label: 'Universidad Tecnológica de México', value: 'Universidad Tecnológica de México' },
  { label: 'Universidad Tecnologica de Nezahualcoyotl', value: 'Universidad Tecnologica de Nezahualcoyotl' },
  { label: 'Universidad Tecnológica de Panamá', value: 'Universidad Tecnológica de Panamá' },
  { label: 'Universidad Tecnológica de Pereira', value: 'Universidad Tecnológica de Pereira' },
  { label: 'Universidad Tecnológica de Santiago', value: 'Universidad Tecnológica de Santiago' },
  { label: 'Universidad Tecnológica del Centro', value: 'Universidad Tecnológica del Centro' },
  {
    label: 'Universidad Tecnológica del Choco Diego Luis Cordoba',
    value: 'Universidad Tecnológica del Choco Diego Luis Cordoba',
  },
  { label: 'Universidad Tecnológica del Peru', value: 'Universidad Tecnológica del Peru' },
  { label: 'Universidad Tecnológica del Sur', value: 'Universidad Tecnológica del Sur' },
  { label: 'Universidad Tecnológica Equinoccial', value: 'Universidad Tecnológica Equinoccial' },
  { label: 'Universidad Tecnológica Indoamérica', value: 'Universidad Tecnológica Indoamérica' },
  { label: 'Universidad Tecnologica Israel', value: 'Universidad Tecnologica Israel' },
  { label: 'Universidad Tecnológica Metropolitana', value: 'Universidad Tecnológica Metropolitana' },
  { label: 'Universidad Tecnológica Nacional', value: 'Universidad Tecnológica Nacional' },
  { label: 'Universidad Tecnológica Oteima', value: 'Universidad Tecnológica Oteima' },
  { label: 'Universidad Tecnológica San Antonio de Machala', value: 'Universidad Tecnológica San Antonio de Machala' },
  { label: 'Universidad Tecnologica Vicente Perez Rosales', value: 'Universidad Tecnologica Vicente Perez Rosales' },
  { label: 'Universidad Torcuato di Tella', value: 'Universidad Torcuato di Tella' },
  { label: 'Universidad Ulises Francisco Espaillat', value: 'Universidad Ulises Francisco Espaillat' },
  { label: 'Universidad Valle del Grijalva', value: 'Universidad Valle del Grijalva' },
  { label: 'Universidad Valle del Momboy', value: 'Universidad Valle del Momboy' },
  { label: 'Universidad Veracruzana', value: 'Universidad Veracruzana' },
  { label: 'Universidad Veritas', value: 'Universidad Veritas' },
  { label: 'Universidad Xicotepetl', value: 'Universidad Xicotepetl' },
  { label: 'Universidad Yacambu', value: 'Universidad Yacambu' },
  { label: 'Universidade Aberta Lisboa', value: 'Universidade Aberta Lisboa' },
  { label: 'Universidade Atlântica', value: 'Universidade Atlântica' },
  { label: 'Universidade Autónoma de Lisboa Luís de Camoes', value: 'Universidade Autónoma de Lisboa Luís de Camoes' },
  { label: 'Universidade Bandeirante de São Paulo', value: 'Universidade Bandeirante de São Paulo' },
  { label: 'Universidade Braz Cubas', value: 'Universidade Braz Cubas' },
  { label: 'Universidade Camilo Castelo Branco', value: 'Universidade Camilo Castelo Branco' },
  { label: 'Universidade Candido Mendes', value: 'Universidade Candido Mendes' },
  { label: 'Universidade Castelo Branco', value: 'Universidade Castelo Branco' },
  { label: 'Universidade Católica de Angola', value: 'Universidade Católica de Angola' },
  { label: 'Universidade Católica de Brasília', value: 'Universidade Católica de Brasília' },
  { label: 'Universidade Católica de Goiás', value: 'Universidade Católica de Goiás' },
  { label: 'Universidade Católica de Moçambique', value: 'Universidade Católica de Moçambique' },
  { label: 'Universidade Católica de Pelotas', value: 'Universidade Católica de Pelotas' },
  { label: 'Universidade Católica de Pernambuco', value: 'Universidade Católica de Pernambuco' },
  { label: 'Universidade Católica de Petrópolis', value: 'Universidade Católica de Petrópolis' },
  { label: 'Universidade Católica de Salvador', value: 'Universidade Católica de Salvador' },
  { label: 'Universidade Católica de Santos', value: 'Universidade Católica de Santos' },
  { label: 'Universidade Católica Dom Bosco', value: 'Universidade Católica Dom Bosco' },
  { label: 'Universidade Catolica Portuguesa', value: 'Universidade Catolica Portuguesa' },
  { label: 'Universidade Cidade de São Paulo', value: 'Universidade Cidade de São Paulo' },
  { label: 'Universidade Cruzeiro do Sul', value: 'Universidade Cruzeiro do Sul' },
  { label: 'Universidade da Amazônia', value: 'Universidade da Amazônia' },
  { label: 'Universidade da Beira Interior', value: 'Universidade da Beira Interior' },
  { label: 'Universidade da Madeira', value: 'Universidade da Madeira' },
  { label: 'Universidade da Região da Campanha', value: 'Universidade da Região da Campanha' },
  { label: 'Universidade de Alfenas', value: 'Universidade de Alfenas' },
  { label: 'Universidade de Aveiro', value: 'Universidade de Aveiro' },
  { label: 'Universidade de Brasília', value: 'Universidade de Brasília' },
  { label: 'Universidade de Caxias do Sul', value: 'Universidade de Caxias do Sul' },
  { label: 'Universidade de Coimbra', value: 'Universidade de Coimbra' },
  { label: 'Universidade de Cruz Alta', value: 'Universidade de Cruz Alta' },
  { label: 'Universidade de Evora', value: 'Universidade de Evora' },
  { label: 'Universidade de Fortaleza', value: 'Universidade de Fortaleza' },
  { label: 'Universidade de Franca', value: 'Universidade de Franca' },
  { label: 'Universidade de Guarulhos', value: 'Universidade de Guarulhos' },
  { label: 'Universidade de Itaúna', value: 'Universidade de Itaúna' },
  { label: 'Universidade de Lisboa', value: 'Universidade de Lisboa' },
  { label: 'Universidade de Marília', value: 'Universidade de Marília' },
  { label: 'Universidade de Mogi das Cruzes', value: 'Universidade de Mogi das Cruzes' },
  { label: 'Universidade de Nova Iguaçu', value: 'Universidade de Nova Iguaçu' },
  { label: 'Universidade de Passo Fundo', value: 'Universidade de Passo Fundo' },
  { label: 'Universidade de Pernambuco', value: 'Universidade de Pernambuco' },
  { label: 'Universidade de Ribeirão Preto', value: 'Universidade de Ribeirão Preto' },
  { label: 'Universidade de Santa Cruz do Sul', value: 'Universidade de Santa Cruz do Sul' },
  { label: 'Universidade de Santo Amaro', value: 'Universidade de Santo Amaro' },
  { label: 'Universidade de São Paulo', value: 'Universidade de São Paulo' },
  { label: 'Universidade de Taubaté', value: 'Universidade de Taubaté' },
  { label: 'Universidade de Tocantins', value: 'Universidade de Tocantins' },
  { label: 'Universidade de Tras-os-Montes e Alto Douro', value: 'Universidade de Tras-os-Montes e Alto Douro' },
  { label: 'Universidade de Uberaba', value: 'Universidade de Uberaba' },
  { label: 'Universidade de Vila Velha', value: 'Universidade de Vila Velha' },
  { label: 'Universidade do Algarve', value: 'Universidade do Algarve' },
  { label: 'Universidade do Amazonas', value: 'Universidade do Amazonas' },
  { label: 'Universidade do Estado da Bahia', value: 'Universidade do Estado da Bahia' },
  { label: 'Universidade do Estado de Santa Catarina', value: 'Universidade do Estado de Santa Catarina' },
  { label: 'Universidade do Estado do Rio de Janeiro', value: 'Universidade do Estado do Rio de Janeiro' },
  { label: 'Universidade do Estado do Rio Grande do Norte', value: 'Universidade do Estado do Rio Grande do Norte' },
  { label: 'Universidade do Extremo Sul Catarinense', value: 'Universidade do Extremo Sul Catarinense' },
  { label: 'Universidade do Grande Rio', value: 'Universidade do Grande Rio' },
  { label: 'Universidade do Minho', value: 'Universidade do Minho' },
  { label: 'Universidade do Oeste de Santa Catarina', value: 'Universidade do Oeste de Santa Catarina' },
  { label: 'Universidade do Oeste Paulista', value: 'Universidade do Oeste Paulista' },
  { label: 'Universidade do Porto', value: 'Universidade do Porto' },
  { label: 'Universidade do Rio de Janeiro', value: 'Universidade do Rio de Janeiro' },
  { label: 'Universidade do Rio Grande', value: 'Universidade do Rio Grande' },
  { label: 'Universidade do Sagrado Coração', value: 'Universidade do Sagrado Coração' },
  { label: 'Universidade do Sul de Santa Catarina', value: 'Universidade do Sul de Santa Catarina' },
  { label: 'Universidade do Vale do Itajaí', value: 'Universidade do Vale do Itajaí' },
  { label: 'Universidade dos Acores', value: 'Universidade dos Acores' },
  { label: 'Universidade Eduardo Mondlane', value: 'Universidade Eduardo Mondlane' },
  { label: 'Universidade Estácio de Sá', value: 'Universidade Estácio de Sá' },
  { label: 'Universidade Estadual da Paraíba', value: 'Universidade Estadual da Paraíba' },
  { label: 'Universidade Estadual de Campinas', value: 'Universidade Estadual de Campinas' },
  { label: 'Universidade Estadual de Feira de Santana', value: 'Universidade Estadual de Feira de Santana' },
  { label: 'Universidade Estadual de Londrina', value: 'Universidade Estadual de Londrina' },
  { label: 'Universidade Estadual de Maringá', value: 'Universidade Estadual de Maringá' },
  { label: 'Universidade Estadual de Montes Claros', value: 'Universidade Estadual de Montes Claros' },
  { label: 'Universidade Estadual de Ponta Grossa', value: 'Universidade Estadual de Ponta Grossa' },
  { label: 'Universidade Estadual de Santa Cruz', value: 'Universidade Estadual de Santa Cruz' },
  { label: 'Universidade Estadual do Ceará', value: 'Universidade Estadual do Ceará' },
  { label: 'Universidade Estadual do Centro-Oeste', value: 'Universidade Estadual do Centro-Oeste' },
  { label: 'Universidade Estadual do Maranhão', value: 'Universidade Estadual do Maranhão' },
  { label: 'Universidade Estadual do Norte Fluminense', value: 'Universidade Estadual do Norte Fluminense' },
  { label: 'Universidade Estadual do Oeste do Paraná', value: 'Universidade Estadual do Oeste do Paraná' },
  { label: 'Universidade Estadual do Piauí', value: 'Universidade Estadual do Piauí' },
  { label: 'Universidade Estadual do Vale do Acaraú', value: 'Universidade Estadual do Vale do Acaraú' },
  { label: 'Universidade Estadual Paulista', value: 'Universidade Estadual Paulista' },
  { label: 'Universidade Estadual Sudoeste da Bahia', value: 'Universidade Estadual Sudoeste da Bahia' },
  { label: 'Universidade Federal da Bahia', value: 'Universidade Federal da Bahia' },
  { label: 'Universidade Federal da Grande Dourados', value: 'Universidade Federal da Grande Dourados' },
  { label: 'Universidade Federal da Paraíba', value: 'Universidade Federal da Paraíba' },
  { label: 'Universidade Federal de Alagoas', value: 'Universidade Federal de Alagoas' },
  { label: 'Universidade Federal de Campina Grande', value: 'Universidade Federal de Campina Grande' },
  { label: 'Universidade Federal de Goiás', value: 'Universidade Federal de Goiás' },
  { label: 'Universidade Federal de Juiz de Fora', value: 'Universidade Federal de Juiz de Fora' },
  { label: 'Universidade Federal de Lavras', value: 'Universidade Federal de Lavras' },
  { label: 'Universidade Federal de Mato Grosso', value: 'Universidade Federal de Mato Grosso' },
  { label: 'Universidade Federal de Mato Grosso do Sul', value: 'Universidade Federal de Mato Grosso do Sul' },
  { label: 'Universidade Federal de Minas Gerais', value: 'Universidade Federal de Minas Gerais' },
  { label: 'Universidade Federal de Ouro Preto', value: 'Universidade Federal de Ouro Preto' },
  { label: 'Universidade Federal de Pelotas', value: 'Universidade Federal de Pelotas' },
  { label: 'Universidade Federal de Pernambuco', value: 'Universidade Federal de Pernambuco' },
  { label: 'Universidade Federal de Rondônia', value: 'Universidade Federal de Rondônia' },
  { label: 'Universidade Federal de Roraima', value: 'Universidade Federal de Roraima' },
  { label: 'Universidade Federal de Santa Catarina', value: 'Universidade Federal de Santa Catarina' },
  { label: 'Universidade Federal de Santa Maria', value: 'Universidade Federal de Santa Maria' },
  { label: 'Universidade Federal de São Carlos', value: 'Universidade Federal de São Carlos' },
  { label: 'Universidade Federal de São Paulo', value: 'Universidade Federal de São Paulo' },
  { label: 'Universidade Federal de Sergipe', value: 'Universidade Federal de Sergipe' },
  { label: 'Universidade Federal de Uberlândia', value: 'Universidade Federal de Uberlândia' },
  { label: 'Universidade Federal de Viçosa', value: 'Universidade Federal de Viçosa' },
  { label: 'Universidade Federal do ABC', value: 'Universidade Federal do ABC' },
  { label: 'Universidade Federal do Acre', value: 'Universidade Federal do Acre' },
  { label: 'Universidade Federal do Amapá', value: 'Universidade Federal do Amapá' },
  { label: 'Universidade Federal do Ceará', value: 'Universidade Federal do Ceará' },
  { label: 'Universidade Federal do Espírito Santo', value: 'Universidade Federal do Espírito Santo' },
  { label: 'Universidade Federal do Maranhão', value: 'Universidade Federal do Maranhão' },
  { label: 'Universidade Federal do Pará', value: 'Universidade Federal do Pará' },
  { label: 'Universidade Federal do Paraná', value: 'Universidade Federal do Paraná' },
  { label: 'Universidade Federal do Piauí', value: 'Universidade Federal do Piauí' },
  { label: 'Universidade Federal do Rio de Janeiro', value: 'Universidade Federal do Rio de Janeiro' },
  { label: 'Universidade Federal do Rio Grande do Norte', value: 'Universidade Federal do Rio Grande do Norte' },
  { label: 'Universidade Federal do Rio Grande do Sul', value: 'Universidade Federal do Rio Grande do Sul' },
  { label: 'Universidade Federal do Vale do São Francisco', value: 'Universidade Federal do Vale do São Francisco' },
  { label: 'Universidade Federal Fluminense', value: 'Universidade Federal Fluminense' },
  { label: 'Universidade Federal Rural de Pernambuco', value: 'Universidade Federal Rural de Pernambuco' },
  { label: 'Universidade Federal Rural do Rio de Janeiro', value: 'Universidade Federal Rural do Rio de Janeiro' },
  { label: 'Universidade Federal Rural do Semi-Árido', value: 'Universidade Federal Rural do Semi-Árido' },
  { label: 'Universidade Fernando Pessoa', value: 'Universidade Fernando Pessoa' },
  { label: 'Universidade Gama Filho', value: 'Universidade Gama Filho' },
  { label: 'Universidade Gregorio Semedo', value: 'Universidade Gregorio Semedo' },
  { label: 'Universidade Ibirapuera', value: 'Universidade Ibirapuera' },
  { label: 'Universidade Independente', value: 'Universidade Independente' },
  { label: 'Universidade Independente de Angola', value: 'Universidade Independente de Angola' },
  { label: 'Universidade Internacional Lisboa', value: 'Universidade Internacional Lisboa' },
  { label: 'Universidade Jean Piaget de Cabo Verde', value: 'Universidade Jean Piaget de Cabo Verde' },
  { label: 'Universidade Lusiada', value: 'Universidade Lusiada' },
  { label: 'Universidade Lusíada de Angola', value: 'Universidade Lusíada de Angola' },
  {
    label: 'Universidade Lusófona de Humanidades e Technologias',
    value: 'Universidade Lusófona de Humanidades e Technologias',
  },
  { label: 'Universidade Luterana do Brasil', value: 'Universidade Luterana do Brasil' },
  { label: 'Universidade Mackenzie', value: 'Universidade Mackenzie' },
  { label: 'Universidade Metodista de Angola', value: 'Universidade Metodista de Angola' },
  { label: 'Universidade Metodista de Piracicaba', value: 'Universidade Metodista de Piracicaba' },
  { label: 'Universidade Metropolitana de Angola', value: 'Universidade Metropolitana de Angola' },
  { label: 'Universidade Metropolitana de Santos', value: 'Universidade Metropolitana de Santos' },
  { label: 'Universidade Moderna de Lisboa', value: 'Universidade Moderna de Lisboa' },
  { label: 'Universidade Nova de Lisboa', value: 'Universidade Nova de Lisboa' },
  {
    label: 'Universidade para o Desenvolvimento do Alto Vale do Itajaí',
    value: 'Universidade para o Desenvolvimento do Alto Vale do Itajaí',
  },
  { label: 'Universidade Paulista', value: 'Universidade Paulista' },
  { label: 'Universidade Portucalense Infante D. Henrique', value: 'Universidade Portucalense Infante D. Henrique' },
  { label: 'Universidade Potiguar', value: 'Universidade Potiguar' },
  { label: 'Universidade Regional de Blumenau', value: 'Universidade Regional de Blumenau' },
  { label: 'Universidade Regional do Cariri', value: 'Universidade Regional do Cariri' },
  {
    label: 'Universidade Regional do Noroeste do Estado do Rio Grande do Sul',
    value: 'Universidade Regional do Noroeste do Estado do Rio Grande do Sul',
  },
  {
    label: 'Universidade Regional Integrada do Alto Uruguai e das Missões',
    value: 'Universidade Regional Integrada do Alto Uruguai e das Missões',
  },
  { label: 'Universidade Salgado de Oliveira', value: 'Universidade Salgado de Oliveira' },
  { label: 'Universidade Salvador', value: 'Universidade Salvador' },
  { label: 'Universidade Santa Cecília dos Bandeirantes', value: 'Universidade Santa Cecília dos Bandeirantes' },
  { label: 'Universidade Santa Úrsula', value: 'Universidade Santa Úrsula' },
  { label: 'Universidade São Francisco', value: 'Universidade São Francisco' },
  { label: 'Universidade São Judas Tadeu', value: 'Universidade São Judas Tadeu' },
  { label: 'Universidade São Marcos', value: 'Universidade São Marcos' },
  { label: 'Universidade Tecnica de Angola', value: 'Universidade Tecnica de Angola' },
  { label: 'Universidade Tecnica de Lisboa', value: 'Universidade Tecnica de Lisboa' },
  { label: 'Universidade Tiradentes', value: 'Universidade Tiradentes' },
  { label: 'Universidade Vale do Paraiba', value: 'Universidade Vale do Paraiba' },
  { label: 'Universidade Vale do Rio Doce', value: 'Universidade Vale do Rio Doce' },
  { label: 'Universidade Vale do Rio dos Sinos', value: 'Universidade Vale do Rio dos Sinos' },
  { label: 'Universidade Veiga de Almeida', value: 'Universidade Veiga de Almeida' },
  { label: 'Università Bocconi', value: 'Università Bocconi' },
  { label: 'Università Pontifcia Salesiana', value: 'Università Pontifcia Salesiana' },
  { label: 'Università Pontificia Salesiana', value: 'Università Pontificia Salesiana' },
  { label: 'Universitas 17 Agustus 1945 Banyuwangi', value: 'Universitas 17 Agustus 1945 Banyuwangi' },
  { label: 'Universitas 17 Agustus 1945 Cirebon', value: 'Universitas 17 Agustus 1945 Cirebon' },
  { label: 'Universitas 17 Agustus 1945 Jakarta', value: 'Universitas 17 Agustus 1945 Jakarta' },
  { label: 'Universitas 17 Agustus 1945 Samarinda', value: 'Universitas 17 Agustus 1945 Samarinda' },
  { label: 'Universitas 17 Agustus 1945 Semarang', value: 'Universitas 17 Agustus 1945 Semarang' },
  { label: 'Universitas 17 Agustus 1945 Surabaya', value: 'Universitas 17 Agustus 1945 Surabaya' },
  { label: 'Universitas Advent Indonesia', value: 'Universitas Advent Indonesia' },
  { label: 'Universitas Ahmad Dahlan', value: 'Universitas Ahmad Dahlan' },
  { label: 'Universitas Airlangga', value: 'Universitas Airlangga' },
  { label: 'Universitas Amir Hamzah', value: 'Universitas Amir Hamzah' },
  { label: 'Universitas Andalas', value: 'Universitas Andalas' },
  { label: 'Universitas Atma Jaya Yogyakarta', value: 'Universitas Atma Jaya Yogyakarta' },
  { label: 'Universitas Bakrie', value: 'Universitas Bakrie' },
  { label: 'Universitas Bengkulu', value: 'Universitas Bengkulu' },
  { label: 'Universitas Bhayangkara Jakarta Raya', value: 'Universitas Bhayangkara Jakarta Raya' },
  { label: 'Universitas Bhayangkara Surabaya', value: 'Universitas Bhayangkara Surabaya' },
  { label: 'Universitas Bojonegoro', value: 'Universitas Bojonegoro' },
  { label: 'Universitas Brawijaya', value: 'Universitas Brawijaya' },
  { label: 'Universitas Bunda Mulia Jakarta', value: 'Universitas Bunda Mulia Jakarta' },
  { label: 'Universitas Bung Hatta', value: 'Universitas Bung Hatta' },
  { label: 'Universitas Cenderawasih', value: 'Universitas Cenderawasih' },
  { label: 'Universitas Darma Agung', value: 'Universitas Darma Agung' },
  { label: 'Universitas Darma Persada', value: 'Universitas Darma Persada' },
  { label: 'Universitas Dian Nuswantoro', value: 'Universitas Dian Nuswantoro' },
  { label: 'Universitas Diponegoro', value: 'Universitas Diponegoro' },
  { label: 'Universitas Dr. R. Moestopo', value: 'Universitas Dr. R. Moestopo' },
  { label: 'Universitas Gadjah Mada', value: 'Universitas Gadjah Mada' },
  { label: 'Universitas Gajayana Malang', value: 'Universitas Gajayana Malang' },
  { label: 'Universitas Gunadarma', value: 'Universitas Gunadarma' },
  { label: 'Universitas Haluoleo', value: 'Universitas Haluoleo' },
  { label: 'Universitas Hasanuddin', value: 'Universitas Hasanuddin' },
  { label: 'Universitas HKBP Nommensen', value: 'Universitas HKBP Nommensen' },
  { label: 'Universitas Ibn Chaldun Jakarta', value: 'Universitas Ibn Chaldun Jakarta' },
  { label: 'Universitas Ibn Khaldun Bogor', value: 'Universitas Ibn Khaldun Bogor' },
  { label: 'Universitas Indonesia', value: 'Universitas Indonesia' },
  { label: 'Universitas Indonusa Esa Unggul', value: 'Universitas Indonusa Esa Unggul' },
  { label: 'Universitas Islam Bandung', value: 'Universitas Islam Bandung' },
  { label: 'Universitas Islam Indonesia', value: 'Universitas Islam Indonesia' },
  { label: 'Universitas Islam Jakarta', value: 'Universitas Islam Jakarta' },
  { label: 'Universitas Islam Malang', value: 'Universitas Islam Malang' },
  { label: 'Universitas Islam Nusantara', value: 'Universitas Islam Nusantara' },
  { label: 'Universitas Islam Riau', value: 'Universitas Islam Riau' },
  { label: 'Universitas Islam Sultan Agung', value: 'Universitas Islam Sultan Agung' },
  { label: 'Universitas Islam Sumatera Utara', value: 'Universitas Islam Sumatera Utara' },
  { label: 'Universitas Islam Syekh-Yusuf ', value: 'Universitas Islam Syekh-Yusuf ' },
  { label: 'Universitas Jambi', value: 'Universitas Jambi' },
  { label: 'Universitas Janabadra', value: 'Universitas Janabadra' },
  { label: 'Universitas Jayabaya', value: 'Universitas Jayabaya' },
  { label: 'Universitas Jember', value: 'Universitas Jember' },
  { label: 'Universitas Jenderal Achmad Yani', value: 'Universitas Jenderal Achmad Yani' },
  { label: 'Universitas Jenderal Soedirman', value: 'Universitas Jenderal Soedirman' },
  { label: 'Universitas Katolik Indonesia Atma Jaya', value: 'Universitas Katolik Indonesia Atma Jaya' },
  { label: 'Universitas Katolik Parahyangan', value: 'Universitas Katolik Parahyangan' },
  { label: 'Universitas Katolik Soegijapranoto', value: 'Universitas Katolik Soegijapranoto' },
  { label: 'Universitas Katolik Widya Karya', value: 'Universitas Katolik Widya Karya' },
  { label: 'Universitas Katolik Widya Manadala', value: 'Universitas Katolik Widya Manadala' },
  { label: 'Universitas Kediri', value: 'Universitas Kediri' },
  { label: 'Universitas Khairun', value: 'Universitas Khairun' },
  { label: 'Universitas Klabat', value: 'Universitas Klabat' },
  { label: 'Universitas Komputer Indonesia', value: 'Universitas Komputer Indonesia' },
  { label: 'Universitas Krisnadwipayana', value: 'Universitas Krisnadwipayana' },
  { label: 'Universitas Kristen Duta Wacana', value: 'Universitas Kristen Duta Wacana' },
  { label: 'Universitas Kristen Indonesia', value: 'Universitas Kristen Indonesia' },
  { label: 'Universitas Kristen Indonesia Tomohon', value: 'Universitas Kristen Indonesia Tomohon' },
  { label: 'Universitas Kristen Krida Wacana', value: 'Universitas Kristen Krida Wacana' },
  { label: 'Universitas Kristen Maranatha', value: 'Universitas Kristen Maranatha' },
  { label: 'Universitas Kristen Petra', value: 'Universitas Kristen Petra' },
  { label: 'Universitas Kristen Satya Wacana', value: 'Universitas Kristen Satya Wacana' },
  { label: 'Universitas Lambung Mangkurat', value: 'Universitas Lambung Mangkurat' },
  { label: 'Universitas Lampung', value: 'Universitas Lampung' },
  { label: 'Universitas Lancang Kuning', value: 'Universitas Lancang Kuning' },
  { label: 'Universitas Ma Chung', value: 'Universitas Ma Chung' },
  { label: 'Universitas Madura', value: 'Universitas Madura' },
  { label: 'Universitas Mahasaraswati Denpasar', value: 'Universitas Mahasaraswati Denpasar' },
  { label: 'Universitas Mahendradatta', value: 'Universitas Mahendradatta' },
  { label: 'Universitas Mataram', value: 'Universitas Mataram' },
  { label: 'Universitas Mercu Buana', value: 'Universitas Mercu Buana' },
  { label: 'Universitas Merdeka Madiun', value: 'Universitas Merdeka Madiun' },
  { label: 'Universitas Merdeka Malang', value: 'Universitas Merdeka Malang' },
  { label: 'Universitas Methodist Indonesia', value: 'Universitas Methodist Indonesia' },
  { label: 'Universitas Muhammadiyah Jakarta', value: 'Universitas Muhammadiyah Jakarta' },
  { label: 'Universitas Muhammadiyah Jember', value: 'Universitas Muhammadiyah Jember' },
  { label: 'Universitas Muhammadiyah Magelang', value: 'Universitas Muhammadiyah Magelang' },
  { label: 'Universitas Muhammadiyah Makassar', value: 'Universitas Muhammadiyah Makassar' },
  { label: 'Universitas Muhammadiyah Malang', value: 'Universitas Muhammadiyah Malang' },
  { label: 'Universitas Muhammadiyah Mataram', value: 'Universitas Muhammadiyah Mataram' },
  { label: 'Universitas Muhammadiyah Sumatra Utara', value: 'Universitas Muhammadiyah Sumatra Utara' },
  { label: 'Universitas Muhammadiyah Surakarta', value: 'Universitas Muhammadiyah Surakarta' },
  { label: 'Universitas Muhammadiyah Yogyakarta', value: 'Universitas Muhammadiyah Yogyakarta' },
  { label: 'Universitas Mulawarman', value: 'Universitas Mulawarman' },
  { label: 'Universitas Muria Kudus', value: 'Universitas Muria Kudus' },
  { label: 'Universitas Muslim Indonesia', value: 'Universitas Muslim Indonesia' },
  { label: 'Universitas Narotama', value: 'Universitas Narotama' },
  { label: 'Universitas Nasional Jakarta', value: 'Universitas Nasional Jakarta' },
  { label: 'Universitas Nasional Pasim', value: 'Universitas Nasional Pasim' },
  { label: 'Universitas Negeri Gorontalo', value: 'Universitas Negeri Gorontalo' },
  { label: 'Universitas Negeri Jakarta', value: 'Universitas Negeri Jakarta' },
  { label: 'Universitas Negeri Makassar', value: 'Universitas Negeri Makassar' },
  { label: 'Universitas Negeri Malang', value: 'Universitas Negeri Malang' },
  { label: 'Universitas Negeri Manado', value: 'Universitas Negeri Manado' },
  { label: 'Universitas Negeri Medan', value: 'Universitas Negeri Medan' },
  { label: 'Universitas Negeri Padang', value: 'Universitas Negeri Padang' },
  { label: 'Universitas Negeri Semarang', value: 'Universitas Negeri Semarang' },
  { label: 'Universitas Negeri Surabaya', value: 'Universitas Negeri Surabaya' },
  { label: 'Universitas Negeri Yogyakarta', value: 'Universitas Negeri Yogyakarta' },
  { label: 'Universitas Ngurah Rai', value: 'Universitas Ngurah Rai' },
  { label: 'Universitas Nusa Cendana', value: 'Universitas Nusa Cendana' },
  { label: 'Universitas Padjadjaran', value: 'Universitas Padjadjaran' },
  { label: 'Universitas Pakuan', value: 'Universitas Pakuan' },
  { label: 'Universitas Palangka Raya', value: 'Universitas Palangka Raya' },
  { label: 'Universitas Pancasakti Tegal', value: 'Universitas Pancasakti Tegal' },
  { label: 'Universitas Pancasila', value: 'Universitas Pancasila' },
  { label: 'Universitas Paramadina Mulya', value: 'Universitas Paramadina Mulya' },
  { label: 'Universitas Pasundan', value: 'Universitas Pasundan' },
  { label: 'Universitas Pattimura', value: 'Universitas Pattimura' },
  { label: 'Universitas Pekalongan', value: 'Universitas Pekalongan' },
  { label: 'Universitas Pelita Harapan', value: 'Universitas Pelita Harapan' },
  {
    label: 'Universitas Pembangunan Nasional Veteran East Java',
    value: 'Universitas Pembangunan Nasional Veteran East Java',
  },
  { label: 'Universitas Pembangunan Panca Budi', value: 'Universitas Pembangunan Panca Budi' },
  { label: 'Universitas Pendidikan Indonesia', value: 'Universitas Pendidikan Indonesia' },
  { label: 'Universitas Persada Indonesia Y.A.I', value: 'Universitas Persada Indonesia Y.A.I' },
  { label: 'Universitas Pesantren Darul Ulum Jombang', value: 'Universitas Pesantren Darul Ulum Jombang' },
  { label: 'Universitas Proklamasi 45', value: 'Universitas Proklamasi 45' },
  { label: 'Universitas Putera Batam', value: 'Universitas Putera Batam' },
  { label: 'Universitas Riau', value: 'Universitas Riau' },
  { label: 'Universitas Sam Ratulangi', value: 'Universitas Sam Ratulangi' },
  { label: 'Universitas Sanata Dharma', value: 'Universitas Sanata Dharma' },
  { label: 'Universitas Sarjanawiyata Tamansiswa', value: 'Universitas Sarjanawiyata Tamansiswa' },
  { label: 'Universitas Sebelas Maret', value: 'Universitas Sebelas Maret' },
  { label: 'Universitas Semarang', value: 'Universitas Semarang' },
  { label: 'Universitas Siliwangi', value: 'Universitas Siliwangi' },
  { label: 'Universitas Simalungun', value: 'Universitas Simalungun' },
  { label: 'Universitas Slamet Riyadi Surakarta', value: 'Universitas Slamet Riyadi Surakarta' },
  { label: 'Universitas Sriwijaya', value: 'Universitas Sriwijaya' },
  { label: 'Universitas Sultan Ageng Tirtayasa', value: 'Universitas Sultan Ageng Tirtayasa' },
  { label: 'Universitas Sumatera Utara', value: 'Universitas Sumatera Utara' },
  { label: 'Universitas Surabaya', value: 'Universitas Surabaya' },
  { label: 'Universitas Swadaya Gunung Djati', value: 'Universitas Swadaya Gunung Djati' },
  { label: 'Universitas Syiah Kuala', value: 'Universitas Syiah Kuala' },
  { label: 'Universitas Tadulako', value: 'Universitas Tadulako' },
  { label: 'Universitas Tanjungpura', value: 'Universitas Tanjungpura' },
  { label: 'Universitas Tarumanagara', value: 'Universitas Tarumanagara' },
  { label: 'Universitas Terbuka Indonesia', value: 'Universitas Terbuka Indonesia' },
  { label: 'Universitas Tidar Magelang', value: 'Universitas Tidar Magelang' },
  { label: 'Universitas Trilogi', value: 'Universitas Trilogi' },
  { label: 'Universitas Trisakti', value: 'Universitas Trisakti' },
  { label: 'Universitas Tunas Pembangunan', value: 'Universitas Tunas Pembangunan' },
  { label: 'Universitas Udayana', value: 'Universitas Udayana' },
  { label: 'Universitas Widya Gama Malang', value: 'Universitas Widya Gama Malang' },
  { label: 'Universitas Widyatama', value: 'Universitas Widyatama' },
  { label: 'Universitas Wijaya Kusuma Purwokerto', value: 'Universitas Wijaya Kusuma Purwokerto' },
  { label: 'Universitas Wijaya Kusuma Surabaya', value: 'Universitas Wijaya Kusuma Surabaya' },
  { label: 'Universitas Wisnuwardhana', value: 'Universitas Wisnuwardhana' },
  { label: 'Universitas Yarsi', value: 'Universitas Yarsi' },
  { label: 'Universität Augsburg', value: 'Universität Augsburg' },
  { label: 'Universitat Autónoma de Barcelona', value: 'Universitat Autónoma de Barcelona' },
  { label: 'Universität Bayreuth', value: 'Universität Bayreuth' },
  { label: 'Universität Bielefeld', value: 'Universität Bielefeld' },
  { label: 'Universität Bremen', value: 'Universität Bremen' },
  { label: 'Universitat de Barcelona', value: 'Universitat de Barcelona' },
  { label: 'Universitat de les Illes Balears', value: 'Universitat de les Illes Balears' },
  { label: 'Universitat de Lleida', value: 'Universitat de Lleida' },
  { label: 'Universitat de Vic', value: 'Universitat de Vic' },
  { label: 'Universität der Bundeswehr Hamburg', value: 'Universität der Bundeswehr Hamburg' },
  { label: 'Universität der Bundeswehr München', value: 'Universität der Bundeswehr München' },
  { label: 'Universität des Saarlandes', value: 'Universität des Saarlandes' },
  { label: 'Universität Dortmund', value: 'Universität Dortmund' },
  { label: 'Universität Duisburg-Essen', value: 'Universität Duisburg-Essen' },
  { label: 'Universität Erfurt', value: 'Universität Erfurt' },
  { label: 'Universität Flensburg', value: 'Universität Flensburg' },
  {
    label: 'Universität Fridericana Karlsruhe (Technische Hochschule)',
    value: 'Universität Fridericana Karlsruhe (Technische Hochschule)',
  },
  { label: 'Universität für angewandte Kunst Wien', value: 'Universität für angewandte Kunst Wien' },
  { label: 'Universität für Bodenkultur Wien', value: 'Universität für Bodenkultur Wien' },
  {
    label: 'Universität für künstlerische und industrielle Gestaltung Linz',
    value: 'Universität für künstlerische und industrielle Gestaltung Linz',
  },
  {
    label: 'Universität für Musik und darstellende Kunst  Wien',
    value: 'Universität für Musik und darstellende Kunst  Wien',
  },
  {
    label: 'Universität für Musik und darstellende Kunst Graz',
    value: 'Universität für Musik und darstellende Kunst Graz',
  },
  { label: 'Universität Graz', value: 'Universität Graz' },
  { label: 'Universität Hamburg', value: 'Universität Hamburg' },
  { label: 'Universität Hannover', value: 'Universität Hannover' },
  { label: 'Universität Hildesheim', value: 'Universität Hildesheim' },
  { label: 'Universität Hohenheim', value: 'Universität Hohenheim' },
  { label: 'Universität Innsbruck', value: 'Universität Innsbruck' },
  { label: 'Universitat Internacional de Catalunya', value: 'Universitat Internacional de Catalunya' },
  { label: 'Universitat Jaume I de Castellón', value: 'Universitat Jaume I de Castellón' },
  { label: 'Universität Kaiserslautern', value: 'Universität Kaiserslautern' },
  { label: 'Universität Kassel', value: 'Universität Kassel' },
  { label: 'Universität Klagenfurt', value: 'Universität Klagenfurt' },
  { label: 'Universität Koblenz-Landau', value: 'Universität Koblenz-Landau' },
  { label: 'Universität Köln', value: 'Universität Köln' },
  { label: 'Universität Konstanz', value: 'Universität Konstanz' },
  { label: 'Universität Leipzig', value: 'Universität Leipzig' },
  { label: 'Universität Liechtenstein', value: 'Universität Liechtenstein' },
  { label: 'Universität Linz', value: 'Universität Linz' },
  { label: 'Universität Lüneburg', value: 'Universität Lüneburg' },
  { label: 'Universität Mannheim', value: 'Universität Mannheim' },
  { label: 'Universität Mozarteum Salzburg', value: 'Universität Mozarteum Salzburg' },
  { label: 'Universitat Oberta de Catalunya', value: 'Universitat Oberta de Catalunya' },
  { label: 'Universität Osnabrück', value: 'Universität Osnabrück' },
  { label: 'Universität Paderborn', value: 'Universität Paderborn' },
  { label: 'Universität Passau', value: 'Universität Passau' },
  { label: 'Universitat Pompeu Fabra', value: 'Universitat Pompeu Fabra' },
  { label: 'Universität Potsdam', value: 'Universität Potsdam' },
  { label: 'Universitat Rámon Llull', value: 'Universitat Rámon Llull' },
  { label: 'Universität Regensburg', value: 'Universität Regensburg' },
  { label: 'Universität Rostock', value: 'Universität Rostock' },
  { label: 'Universitat Rovira I Virgili Tarragona', value: 'Universitat Rovira I Virgili Tarragona' },
  { label: 'Universität Salzburg', value: 'Universität Salzburg' },
  { label: 'Universität Siegen', value: 'Universität Siegen' },
  { label: 'Universität Stuttgart', value: 'Universität Stuttgart' },
  { label: 'Universität Trier', value: 'Universität Trier' },
  { label: 'Universität Ulm', value: 'Universität Ulm' },
  { label: 'Universität Vienna', value: 'Universität Vienna' },
  { label: 'Universitatea de Vest Vasile Goldi&#351; ', value: 'Universitatea de Vest Vasile Goldi&#351; ' },
  { label: 'Université Abdelmalek Essadi', value: 'Université Abdelmalek Essadi' },
  { label: 'Université Abdou Moumouni de Niamey', value: 'Université Abdou Moumouni de Niamey' },
  { label: 'Université Abou Bekr Belkaid, Tlemcen', value: 'Université Abou Bekr Belkaid, Tlemcen' },
  { label: 'Université Alioune Diop de Bambey', value: 'Université Alioune Diop de Bambey' },
  { label: 'Universite Amadou Hampaté Ba', value: 'Universite Amadou Hampaté Ba' },
  { label: 'Université Amar Telidji', value: 'Université Amar Telidji' },
  { label: 'Université Badji Mokhtar', value: 'Université Badji Mokhtar' },
  { label: 'Université Blaise Pascal (Clermont-Ferrand II)', value: 'Université Blaise Pascal (Clermont-Ferrand II)' },
  { label: 'Université Bordeaux I', value: 'Université Bordeaux I' },
  { label: 'Université Cadi Ayyad', value: 'Université Cadi Ayyad' },
  { label: 'Université Catholique de Bukavu', value: 'Université Catholique de Bukavu' },
  { label: 'Université Catholique de Kabgayi', value: 'Université Catholique de Kabgayi' },
  { label: "Université Catholique de l' Ouest", value: "Université Catholique de l' Ouest" },
  { label: 'Université Catholique de Lille', value: 'Université Catholique de Lille' },
  { label: 'Université Catholique de Louvain', value: 'Université Catholique de Louvain' },
  { label: 'Université Catholique de Lyon', value: 'Université Catholique de Lyon' },
  { label: 'Université Centrale', value: 'Université Centrale' },
  { label: 'Université Charles-de-Gaulle (Lille III)', value: 'Université Charles-de-Gaulle (Lille III)' },
  { label: 'Université Cheikh Anta Diop', value: 'Université Cheikh Anta Diop' },
  { label: 'Université Chouaib Doukkali', value: 'Université Chouaib Doukkali' },
  { label: 'Université Chrétienne Bilingue du Congo', value: 'Université Chrétienne Bilingue du Congo' },
  { label: "Université Chrétienne du Nord d'Haiti", value: "Université Chrétienne du Nord d'Haiti" },
  { label: 'Université Claude Bernard (Lyon I)', value: 'Université Claude Bernard (Lyon I)' },
  { label: "Université d'Abobo-Adjamé", value: "Université d'Abobo-Adjamé" },
  { label: "Université d'Abomey-Calavi (UAC)", value: "Université d'Abomey-Calavi (UAC)" },
  { label: "Université d'Aix-Marseille III", value: "Université d'Aix-Marseille III" },
  { label: "Université d'Alger", value: "Université d'Alger" },
  { label: "Université d'Alger 3", value: "Université d'Alger 3" },
  { label: "Université d'Angers", value: "Université d'Angers" },
  { label: "Université d'Antananarivo", value: "Université d'Antananarivo" },
  { label: "Université d'Antsiranana", value: "Université d'Antsiranana" },
  { label: "Université d'Artois", value: "Université d'Artois" },
  { label: "Université d'Auvergne (Clermont-Ferrand I)", value: "Université d'Auvergne (Clermont-Ferrand I)" },
  { label: "Université d'Avignon", value: "Université d'Avignon" },
  { label: "Université d'Etat d'Haiti", value: "Université d'Etat d'Haiti" },
  { label: "Université d'Evry Val d'Essonne", value: "Université d'Evry Val d'Essonne" },
  { label: "Université d'Oran Es-Senia", value: "Université d'Oran Es-Senia" },
  { label: "Université d'Orléans", value: "Université d'Orléans" },
  { label: 'Université Dakar Bourguiba', value: 'Université Dakar Bourguiba' },
  { label: 'Université de 7 Novembre à Carthage', value: 'Université de 7 Novembre à Carthage' },
  { label: 'Université de Bamako', value: 'Université de Bamako' },
  { label: 'Université de Bamenda', value: 'Université de Bamenda' },
  { label: 'Université de Bandundu Ville', value: 'Université de Bandundu Ville' },
  { label: 'Université de Bangui', value: 'Université de Bangui' },
  { label: 'Université de Batna', value: 'Université de Batna' },
  { label: 'Université de Béjaia', value: 'Université de Béjaia' },
  { label: 'Université de Biskra', value: 'Université de Biskra' },
  { label: 'Université de Blida', value: 'Université de Blida' },
  { label: 'Université de Bouaké', value: 'Université de Bouaké' },
  { label: 'Université de Bourgogne', value: 'Université de Bourgogne' },
  { label: 'Université de Bretagne Occidentale', value: 'Université de Bretagne Occidentale' },
  { label: 'Université de Bretagne Sud', value: 'Université de Bretagne Sud' },
  { label: 'Université de Buéa', value: 'Université de Buéa' },
  { label: 'Université de Caen Basse Normandie', value: 'Université de Caen Basse Normandie' },
  { label: 'Université de Cergy-Pontoise', value: 'Université de Cergy-Pontoise' },
  { label: 'Université de Chlef', value: 'Université de Chlef' },
  { label: 'Université de Cocody', value: 'Université de Cocody' },
  { label: 'Université de Corse Pascal Paoli', value: 'Université de Corse Pascal Paoli' },
  { label: 'Université de Djibouti', value: 'Université de Djibouti' },
  { label: 'Université de Douala', value: 'Université de Douala' },
  { label: 'Université de Dschang', value: 'Université de Dschang' },
  { label: 'Université de Fianarantsoa', value: 'Université de Fianarantsoa' },
  { label: 'Université de Franche-Comté', value: 'Université de Franche-Comté' },
  { label: 'Université de Gabès', value: 'Université de Gabès' },
  { label: 'Université de Gafsa', value: 'Université de Gafsa' },
  { label: 'Université de Guelma', value: 'Université de Guelma' },
  { label: 'Université de Haute-Alsace', value: 'Université de Haute-Alsace' },
  { label: 'Université de Jendouba', value: 'Université de Jendouba' },
  { label: 'Université de Kairouan', value: 'Université de Kairouan' },
  { label: 'Université de Kamina', value: 'Université de Kamina' },
  { label: 'Université de Kikwite', value: 'Université de Kikwite' },
  { label: 'Université de Kinshasa', value: 'Université de Kinshasa' },
  { label: 'Université de Kisangani', value: 'Université de Kisangani' },
  { label: 'Université de Kolwezi', value: 'Université de Kolwezi' },
  { label: "Université de l'Uélé", value: "Université de l'Uélé" },
  { label: 'Université de la Manouba', value: 'Université de la Manouba' },
  {
    label: 'Université de la Méditerranée (Aix Marseille II)',
    value: 'Université de la Méditerranée (Aix Marseille II)',
  },
  { label: 'Université de la Polynésie Française', value: 'Université de la Polynésie Française' },
  { label: 'Université de la Reunion', value: 'Université de la Reunion' },
  { label: 'Université de La Rochelle', value: 'Université de La Rochelle' },
  { label: 'Université de Liège', value: 'Université de Liège' },
  { label: 'Université de Limoges', value: 'Université de Limoges' },
  { label: 'Université de Lomé', value: 'Université de Lomé' },
  { label: 'Université de Lubumbashi', value: 'Université de Lubumbashi' },
  { label: "Université de M'sila", value: "Université de M'sila" },
  { label: 'Université de Mahajanga', value: 'Université de Mahajanga' },
  { label: 'Université de Marne la Vallée', value: 'Université de Marne la Vallée' },
  { label: 'Université de Metz', value: 'Université de Metz' },
  { label: 'Université de Monastir', value: 'Université de Monastir' },
  { label: 'Université de Mons-Hainaut', value: 'Université de Mons-Hainaut' },
  { label: 'Université de Montpellier I', value: 'Université de Montpellier I' },
  { label: 'Université de Montpellier II', value: 'Université de Montpellier II' },
  { label: 'Université de Montréal', value: 'Université de Montréal' },
  { label: 'Université de Mostaganem', value: 'Université de Mostaganem' },
  { label: "Université de N'Djamena", value: "Université de N'Djamena" },
  { label: 'Université de Nantes', value: 'Université de Nantes' },
  { label: 'Université de Ngaoundéré', value: 'Université de Ngaoundéré' },
  { label: 'Université de Nice-Sophia Antipolis', value: 'Université de Nice-Sophia Antipolis' },
  { label: 'Université de Nouakchott', value: 'Université de Nouakchott' },
  { label: 'Université de Ouagadougou', value: 'Université de Ouagadougou' },
  { label: "Université de Pau et des Pays de l'Adour", value: "Université de Pau et des Pays de l'Adour" },
  { label: 'Université de Perpignan', value: 'Université de Perpignan' },
  { label: 'Université de Picardie Jules-Verne', value: 'Université de Picardie Jules-Verne' },
  { label: 'Université de Poitiers', value: 'Université de Poitiers' },
  { label: 'Université de Provence (Aix-Marseille I)', value: 'Université de Provence (Aix-Marseille I)' },
  { label: 'Université de Reims Champagne-Ardenne', value: 'Université de Reims Champagne-Ardenne' },
  { label: 'Université de Rouen - Haute Normandie', value: 'Université de Rouen - Haute Normandie' },
  { label: 'Université de Saida', value: 'Université de Saida' },
  { label: 'Université de Savoie', value: 'Université de Savoie' },
  { label: 'Université de Sétif', value: 'Université de Sétif' },
  { label: 'Université de Sfax', value: 'Université de Sfax' },
  { label: 'Université de Sherbrooke', value: 'Université de Sherbrooke' },
  { label: 'Université de Sidi-Bel-Abbès (Djillali Liabès)', value: 'Université de Sidi-Bel-Abbès (Djillali Liabès)' },
  { label: 'Université de Skikda', value: 'Université de Skikda' },
  {
    label: 'Université de Technologie de Belfort Montbéliard',
    value: 'Université de Technologie de Belfort Montbéliard',
  },
  { label: 'Université de Technologie de Compiègne', value: 'Université de Technologie de Compiègne' },
  { label: 'Université de Technologie de Troyes', value: 'Université de Technologie de Troyes' },
  { label: 'Université de Thiès', value: 'Université de Thiès' },
  { label: 'Université de Toamasina', value: 'Université de Toamasina' },
  { label: 'Université de Toliary', value: 'Université de Toliary' },
  { label: 'Université de Toulouse', value: 'Université de Toulouse' },
  { label: 'Université de Toulouse-le-Mirail (Toulouse II)', value: 'Université de Toulouse-le-Mirail (Toulouse II)' },
  { label: 'Université de Tunis', value: 'Université de Tunis' },
  { label: 'Université de Tunis El Manar', value: 'Université de Tunis El Manar' },
  {
    label: 'Université de Valenciennes et du Hainaut-Cambrésis',
    value: 'Université de Valenciennes et du Hainaut-Cambrésis',
  },
  {
    label: 'Université de Versailles Saint-Quentin-en-Yvelines',
    value: 'Université de Versailles Saint-Quentin-en-Yvelines',
  },
  { label: 'Université de Yaoundé I', value: 'Université de Yaoundé I' },
  { label: 'Université de Yaoundé II', value: 'Université de Yaoundé II' },
  { label: 'Université de Ziguinchor', value: 'Université de Ziguinchor' },
  { label: 'Université Denis Diderot (Paris VII)', value: 'Université Denis Diderot (Paris VII)' },
  { label: 'Université des Antilles et de la Guyane', value: 'Université des Antilles et de la Guyane' },
  { label: 'Université des Antilles et de la Guyane', value: 'Université des Antilles et de la Guyane' },
  { label: 'Université des Antilles et de la Guyane', value: 'Université des Antilles et de la Guyane' },
  { label: 'Université des Montagnes', value: 'Université des Montagnes' },
  {
    label: "Université des Sciences et de la Technologie d'Oran",
    value: "Université des Sciences et de la Technologie d'Oran",
  },
  {
    label: 'Université des Sciences et de la Technologie Houari Boumediène',
    value: 'Université des Sciences et de la Technologie Houari Boumediène',
  },
  {
    label: 'Université des Sciences et Technologies de Lille (Lille I)',
    value: 'Université des Sciences et Technologies de Lille (Lille I)',
  },
  {
    label: 'Université des Sciences Humaines (Strasbourg II)',
    value: 'Université des Sciences Humaines (Strasbourg II)',
  },
  {
    label: 'Universite des Sciences Islamiques de Constantine',
    value: 'Universite des Sciences Islamiques de Constantine',
  },
  { label: 'Université des Sciences Sociales (Toulouse I)', value: 'Université des Sciences Sociales (Toulouse I)' },
  { label: 'Université Dr Tahar Moulay', value: 'Université Dr Tahar Moulay' },
  { label: 'Université du Burundi', value: 'Université du Burundi' },
  { label: 'Université du Centre, Sousse', value: 'Université du Centre, Sousse' },
  { label: 'Université du Droit et de la Sante (Lille II)', value: 'Université du Droit et de la Sante (Lille II)' },
  { label: 'Université du Havre', value: 'Université du Havre' },
  { label: "Université du Littoral Cote d'Opale", value: "Université du Littoral Cote d'Opale" },
  { label: 'Université du Maine', value: 'Université du Maine' },
  { label: 'Université du Québec à Chicoutimi', value: 'Université du Québec à Chicoutimi' },
  { label: 'Université du Québec à Montréal', value: 'Université du Québec à Montréal' },
  { label: 'Université du Québec à Rimouski', value: 'Université du Québec à Rimouski' },
  { label: 'Université du Québec à Trois-Rivières', value: 'Université du Québec à Trois-Rivières' },
  { label: 'Université du Québec en Abitibi-Témiscamingue', value: 'Université du Québec en Abitibi-Témiscamingue' },
  { label: 'Université du Québec en Outaouais', value: 'Université du Québec en Outaouais' },
  { label: 'Université du Sahel', value: 'Université du Sahel' },
  { label: 'Université du Sud, Toulon et Var', value: 'Université du Sud, Toulon et Var' },
  { label: "Université Épiscopale d'Haiti", value: "Université Épiscopale d'Haiti" },
  { label: 'Université Euro-Afrique', value: 'Université Euro-Afrique' },
  { label: 'Université Ez-Zitouna', value: 'Université Ez-Zitouna' },
  { label: "Université Française d'Égypte", value: "Université Française d'Égypte" },
  { label: 'Université François Rabelais de Tours', value: 'Université François Rabelais de Tours' },
  { label: 'Université Gamal Abdel Nasser de Conakry', value: 'Université Gamal Abdel Nasser de Conakry' },
  { label: 'Université Gaston Berger Saint-Louis', value: 'Université Gaston Berger Saint-Louis' },
  { label: 'Université Hassan Ier - Settat', value: 'Université Hassan Ier - Settat' },
  { label: 'Université Hassan II - Aïn Chock', value: 'Université Hassan II - Aïn Chock' },
  { label: 'Université Hassan II - Mohammadia', value: 'Université Hassan II - Mohammadia' },
  { label: 'Université Henri Poincaré (Nancy I)', value: 'Université Henri Poincaré (Nancy I)' },
  { label: 'Université Ibn Toufail', value: 'Université Ibn Toufail' },
  { label: 'Université Ibn Zohr Agadir', value: 'Université Ibn Zohr Agadir' },
  { label: 'Université Jean Monnet', value: 'Université Jean Monnet' },
  { label: 'Université Jean Moulin (Lyon III)', value: 'Université Jean Moulin (Lyon III)' },
  { label: 'Université Joseph Fourier (Grenoble I)', value: 'Université Joseph Fourier (Grenoble I)' },
  { label: 'Université Julius Nyerere Kankan', value: 'Université Julius Nyerere Kankan' },
  { label: 'Université Kasdi Merbah Ouargla', value: 'Université Kasdi Merbah Ouargla' },
  { label: 'Université Kofi Annan', value: 'Université Kofi Annan' },
  { label: 'Université Kongo', value: 'Université Kongo' },
  { label: 'Université Laval', value: 'Université Laval' },
  { label: 'Université Libre de Bruxelles', value: 'Université Libre de Bruxelles' },
  { label: 'Université Libre de Tunis', value: 'Université Libre de Tunis' },
  { label: 'Université Louis Pasteur (Strasbourg I)', value: 'Université Louis Pasteur (Strasbourg I)' },
  { label: 'Université Lumiére (Lyon II)', value: 'Université Lumiére (Lyon II)' },
  { label: 'Université Lumière de Bujumbura', value: 'Université Lumière de Bujumbura' },
  { label: "Université M'hamed Bouguerra de Boumerdes", value: "Université M'hamed Bouguerra de Boumerdes" },
  { label: 'Université Mentouri Constantine', value: 'Université Mentouri Constantine' },
  { label: 'Université Michel de Montaigne (Bordeaux III )', value: 'Université Michel de Montaigne (Bordeaux III )' },
  { label: 'Université Mohammed Ier', value: 'Université Mohammed Ier' },
  { label: 'Université Mohammed V - Agdal', value: 'Université Mohammed V - Agdal' },
  { label: 'Université Mohammed V - Souissi', value: 'Université Mohammed V - Souissi' },
  { label: 'Université Montesquieu (Bordeaux IV)', value: 'Université Montesquieu (Bordeaux IV)' },
  { label: 'Université Moulay Ismail Meknès', value: 'Université Moulay Ismail Meknès' },
  { label: 'Université Mouloud Mammeri de Tizi Ouzou', value: 'Université Mouloud Mammeri de Tizi Ouzou' },
  { label: 'Université Nancy II', value: 'Université Nancy II' },
  { label: "Université Notre Dame d'Haïti", value: "Université Notre Dame d'Haïti" },
  { label: 'Université Omar Bongo', value: 'Université Omar Bongo' },
  { label: 'Université Panthéon-Assas (Paris II)', value: 'Université Panthéon-Assas (Paris II)' },
  { label: 'Université Panthéon-Sorbonne (Paris I)', value: 'Université Panthéon-Sorbonne (Paris I)' },
  { label: 'Université Paris Nanterre (Paris X)', value: 'Université Paris Nanterre (Paris X)' },
  { label: 'Université Paris Nord (Paris XIII)', value: 'Université Paris Nord (Paris XIII)' },
  { label: 'Université Paris Sud (Paris XI)', value: 'Université Paris Sud (Paris XI)' },
  { label: 'Université Paris-Dauphine (Paris IX)', value: 'Université Paris-Dauphine (Paris IX)' },
  { label: 'Université Paris-Sorbonne (Paris IV)', value: 'Université Paris-Sorbonne (Paris IV)' },
  { label: 'Université Paul Sabatier (Toulouse III)', value: 'Université Paul Sabatier (Toulouse III)' },
  { label: 'Université Paul Valéry (Montpellier III)', value: 'Université Paul Valéry (Montpellier III)' },
  { label: 'Université Pierre et Marie Curie (Paris VI)', value: 'Université Pierre et Marie Curie (Paris VI)' },
  { label: 'Université Pierre Mendes-France (Grenoble II)', value: 'Université Pierre Mendes-France (Grenoble II)' },
  { label: 'Université Protestante au Congo', value: 'Université Protestante au Congo' },
  { label: 'Université Quisqueya', value: 'Université Quisqueya' },
  { label: 'Université René Descartes (Paris V)', value: 'Université René Descartes (Paris V)' },
  { label: 'Université Rennes I', value: 'Université Rennes I' },
  { label: 'Université Rennes II - Haute-Bretagne', value: 'Université Rennes II - Haute-Bretagne' },
  { label: 'Université Robert Schuman (Strasbourg III)', value: 'Université Robert Schuman (Strasbourg III)' },
  { label: "Université Senghor d'Alexandrie", value: "Université Senghor d'Alexandrie" },
  { label: 'Université Shalom de Bunia', value: 'Université Shalom de Bunia' },
  { label: 'Université Sidi Mohammed Ben Abdellah', value: 'Université Sidi Mohammed Ben Abdellah' },
  { label: 'Université Sorbonne-Nouvelle (Paris III)', value: 'Université Sorbonne-Nouvelle (Paris III)' },
  { label: 'Université Stendhal (Grenoble III)', value: 'Université Stendhal (Grenoble III)' },
  { label: 'Université Thierno Amadou Diallo', value: 'Université Thierno Amadou Diallo' },
  { label: 'Université Val-de-Marne (Paris XII)', value: 'Université Val-de-Marne (Paris XII)' },
  { label: 'Université Victor Segalen (Bordeaux II)', value: 'Université Victor Segalen (Bordeaux II)' },
  { label: 'Université Vincennes Saint-Denis (Paris VIII)', value: 'Université Vincennes Saint-Denis (Paris VIII)' },
  { label: 'Université Virtuelle de Tunis', value: 'Université Virtuelle de Tunis' },
  { label: 'Universiteit Antwerpen', value: 'Universiteit Antwerpen' },
  { label: 'Universiteit Antwerpen Management School', value: 'Universiteit Antwerpen Management School' },
  { label: 'Universiteit Antwerpen, UFSIA', value: 'Universiteit Antwerpen, UFSIA' },
  { label: 'Universiteit Doesburg (UNDO)', value: 'Universiteit Doesburg (UNDO)' },
  { label: 'Universiteit Gent', value: 'Universiteit Gent' },
  { label: 'Universiti Darul Iman', value: 'Universiti Darul Iman' },
  { label: 'Universiti Industri Selangor', value: 'Universiti Industri Selangor' },
  { label: 'Universiti Islam Sultan Sharif Ali', value: 'Universiti Islam Sultan Sharif Ali' },
  { label: 'Universiti Kebangsaan Malaysia', value: 'Universiti Kebangsaan Malaysia' },
  { label: 'Universiti Kuala Lumpur', value: 'Universiti Kuala Lumpur' },
  {
    label: 'Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)',
    value: 'Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)',
  },
  { label: 'Universiti Malaya', value: 'Universiti Malaya' },
  { label: 'Universiti Malaysia Kelantan', value: 'Universiti Malaysia Kelantan' },
  { label: 'Universiti Malaysia Perlis', value: 'Universiti Malaysia Perlis' },
  { label: 'Universiti Malaysia Sabah', value: 'Universiti Malaysia Sabah' },
  { label: 'Universiti Malaysia Sarawak', value: 'Universiti Malaysia Sarawak' },
  { label: 'Universiti Malaysia Terengganu', value: 'Universiti Malaysia Terengganu' },
  { label: 'Universiti Pendidikan Sultan Idris', value: 'Universiti Pendidikan Sultan Idris' },
  { label: 'Universiti Putra Malaysia', value: 'Universiti Putra Malaysia' },
  { label: 'Universiti Sains Malaysia', value: 'Universiti Sains Malaysia' },
  { label: 'Universiti Sultan Zainal Abidin', value: 'Universiti Sultan Zainal Abidin' },
  { label: 'Universiti Teknikal Malaysia Melaka', value: 'Universiti Teknikal Malaysia Melaka' },
  { label: 'Universiti Teknologi Malaysia', value: 'Universiti Teknologi Malaysia' },
  { label: 'Universiti Teknologi Mara', value: 'Universiti Teknologi Mara' },
  { label: 'Universiti Teknologi Petronas', value: 'Universiti Teknologi Petronas' },
  { label: 'Universiti Tenaga Nasional', value: 'Universiti Tenaga Nasional' },
  { label: 'Universiti Tun Abdul Razak', value: 'Universiti Tun Abdul Razak' },
  { label: 'Universiti Tun Hussein Onn Malaysia', value: 'Universiti Tun Hussein Onn Malaysia' },
  { label: 'Universiti Tunku Abdul Rahman', value: 'Universiti Tunku Abdul Rahman' },
  { label: 'Universiti Utara Malaysia', value: 'Universiti Utara Malaysia' },
  { label: 'University “Pavaresia” Vlore', value: 'University “Pavaresia” Vlore' },
  { label: 'University Aurel Vlaicu Arad', value: 'University Aurel Vlaicu Arad' },
  { label: 'University Campus Suffolk', value: 'University Campus Suffolk' },
  { label: 'University Canada West', value: 'University Canada West' },
  { label: 'University Center César Ritz', value: 'University Center César Ritz' },
  { label: 'University Centre of the Westfjords', value: 'University Centre of the Westfjords' },
  { label: 'University College Bahrain', value: 'University College Bahrain' },
  { label: 'University College Cork', value: 'University College Cork' },
  { label: 'University College Dublin', value: 'University College Dublin' },
  {
    label: 'University College London, University of London',
    value: 'University College London, University of London',
  },
  { label: 'University College of Applied Sciences', value: 'University College of Applied Sciences' },
  { label: 'University College of Arts, Crafts and Design', value: 'University College of Arts, Crafts and Design' },
  { label: 'University College of Borås', value: 'University College of Borås' },
  { label: 'University College of Cape Breton', value: 'University College of Cape Breton' },
  { label: 'University College of Gävle', value: 'University College of Gävle' },
  { label: 'University College of Kristianstad', value: 'University College of Kristianstad' },
  { label: 'University College of Nabi Akram', value: 'University College of Nabi Akram' },
  { label: 'University College of Saint-Boniface', value: 'University College of Saint-Boniface' },
  { label: 'University College of Skövde', value: 'University College of Skövde' },
  {
    label: 'University College of Technology & Innovation (UCTI)',
    value: 'University College of Technology & Innovation (UCTI)',
  },
  { label: 'University College of the Cariboo', value: 'University College of the Cariboo' },
  { label: 'University College of the Fraser Valley', value: 'University College of the Fraser Valley' },
  { label: 'University College of Trollhättan/Uddevalla', value: 'University College of Trollhättan/Uddevalla' },
  { label: 'University for Development Studies', value: 'University for Development Studies' },
  { label: 'University for Humanistics (UH)', value: 'University for Humanistics (UH)' },
  {
    label: 'University for Information Science and Technology  St. Paul The Apostle ',
    value: 'University for Information Science and Technology  St. Paul The Apostle ',
  },
  { label: 'University for Peace', value: 'University for Peace' },
  { label: 'University Goce Delcev', value: 'University Goce Delcev' },
  { label: 'University Institute of Architecture Venice', value: 'University Institute of Architecture Venice' },
  { label: 'University Institute of Modern Languages', value: 'University Institute of Modern Languages' },
  { label: 'University Institute of Naval Studies', value: 'University Institute of Naval Studies' },
  { label: 'University Institute of Oriental Studies', value: 'University Institute of Oriental Studies' },
  {
    label: 'University Institute of Teacher Training Suor Orsola Benincasa',
    value: 'University Institute of Teacher Training Suor Orsola Benincasa',
  },
  { label: 'University Konstantina Filozov in Nitra', value: 'University Konstantina Filozov in Nitra' },
  { label: 'University Lucian Blaga of Sibiu', value: 'University Lucian Blaga of Sibiu' },
  { label: 'University Malaysia Pahang', value: 'University Malaysia Pahang' },
  { label: 'University Marien Ngouabi Brazzaville', value: 'University Marien Ngouabi Brazzaville' },
  { label: 'University Medical School of Debrecen', value: 'University Medical School of Debrecen' },
  { label: 'University Medical School of Pécs', value: 'University Medical School of Pécs' },
  { label: 'University of Aberdeen', value: 'University of Aberdeen' },
  { label: 'University of Abertay Dundee', value: 'University of Abertay Dundee' },
  { label: 'University of Abuja', value: 'University of Abuja' },
  { label: 'University of Adelaide', value: 'University of Adelaide' },
  { label: 'University of Aden', value: 'University of Aden' },
  { label: 'University of Advancing Technology (UAT)', value: 'University of Advancing Technology (UAT)' },
  { label: 'University of Agricultural Sciences, Bangalore', value: 'University of Agricultural Sciences, Bangalore' },
  { label: 'University of Agricultural Sciences, Dharwad', value: 'University of Agricultural Sciences, Dharwad' },
  { label: 'University of Agriculture Abeokuta', value: 'University of Agriculture Abeokuta' },
  {
    label: 'University of Agriculture and Veterinary Medicine Bucharest',
    value: 'University of Agriculture and Veterinary Medicine Bucharest',
  },
  {
    label: 'University of Agriculture and Veterinary Medicine Cluj-Napoca',
    value: 'University of Agriculture and Veterinary Medicine Cluj-Napoca',
  },
  {
    label: 'University of Agriculture and Veterinary Medicine Iasi',
    value: 'University of Agriculture and Veterinary Medicine Iasi',
  },
  {
    label: 'University of Agriculture and Veterinary Medicine Timisoara',
    value: 'University of Agriculture and Veterinary Medicine Timisoara',
  },
  { label: 'University of Agriculture Faisalabad', value: 'University of Agriculture Faisalabad' },
  { label: 'University of Agriculture Makurdi', value: 'University of Agriculture Makurdi' },
  { label: 'University of Aizu', value: 'University of Aizu' },
  { label: 'University of Akron', value: 'University of Akron' },
  { label: 'University of Akureyri', value: 'University of Akureyri' },
  { label: 'University of Al-Qadisiyah', value: 'University of Al-Qadisiyah' },
  { label: 'University of Alabama - Birmingham', value: 'University of Alabama - Birmingham' },
  { label: 'University of Alabama - Huntsville', value: 'University of Alabama - Huntsville' },
  { label: 'University of Alabama - Tuscaloosa', value: 'University of Alabama - Tuscaloosa' },
  { label: 'University of Alanta', value: 'University of Alanta' },
  { label: 'University of Alaska - Anchorage', value: 'University of Alaska - Anchorage' },
  { label: 'University of Alaska - Fairbanks', value: 'University of Alaska - Fairbanks' },
  { label: 'University of Alaska - Southeast', value: 'University of Alaska - Southeast' },
  { label: 'University of Alaska (System)', value: 'University of Alaska (System)' },
  { label: 'University of Alberta', value: 'University of Alberta' },
  { label: 'University of Aleppo', value: 'University of Aleppo' },
  { label: 'University of Alexandra Dubček in Trenčín', value: 'University of Alexandra Dubček in Trenčín' },
  { label: 'University of Amsterdam', value: 'University of Amsterdam' },
  { label: 'University of Anbar', value: 'University of Anbar' },
  { label: 'University of Ancona', value: 'University of Ancona' },
  { label: 'University of Andorra', value: 'University of Andorra' },
  { label: 'University of Applied Science and Technology', value: 'University of Applied Science and Technology' },
  { label: 'University of Applied Sciences', value: 'University of Applied Sciences' },
  { label: 'University of Applied Sciences Aargau', value: 'University of Applied Sciences Aargau' },
  { label: 'University of Applied Sciences Basel (FHBB )', value: 'University of Applied Sciences Basel (FHBB )' },
  { label: 'University of Applied Sciences Chur', value: 'University of Applied Sciences Chur' },
  { label: 'University of Applied Sciences of Moldova', value: 'University of Applied Sciences of Moldova' },
  { label: 'University of Applied Sciences Rapperswil', value: 'University of Applied Sciences Rapperswil' },
  {
    label: 'University of Applied Sciences Solothurn Northwestern Switzerland',
    value: 'University of Applied Sciences Solothurn Northwestern Switzerland',
  },
  { label: 'University of Aquila', value: 'University of Aquila' },
  {
    label: 'University of Architecture, Civil Engineering and Geodesy',
    value: 'University of Architecture, Civil Engineering and Geodesy',
  },
  { label: 'University of Arizona', value: 'University of Arizona' },
  { label: 'University of Arkansas (System)', value: 'University of Arkansas (System)' },
  { label: 'University of Arkansas at Fayetteville', value: 'University of Arkansas at Fayetteville' },
  { label: 'University of Arkansas at Little Rock', value: 'University of Arkansas at Little Rock' },
  { label: 'University of Arkansas at Monticello', value: 'University of Arkansas at Monticello' },
  { label: 'University of Arkansas at Pine Bluff', value: 'University of Arkansas at Pine Bluff' },
  { label: 'University of Arkansas for Medical Sciences', value: 'University of Arkansas for Medical Sciences' },
  { label: 'University of Art and Design Cluj-Napoca', value: 'University of Art and Design Cluj-Napoca' },
  { label: 'University of Art and Design Helsinki', value: 'University of Art and Design Helsinki' },
  { label: 'University of Asia and the Pacific', value: 'University of Asia and the Pacific' },
  { label: 'University of Asia Pacific, Dhanmondi', value: 'University of Asia Pacific, Dhanmondi' },
  { label: 'University of Athens', value: 'University of Athens' },
  { label: 'University of Auckland', value: 'University of Auckland' },
  { label: 'University of Aviation and Technology', value: 'University of Aviation and Technology' },
  { label: 'University of Bacau', value: 'University of Bacau' },
  { label: 'University of Baghdad', value: 'University of Baghdad' },
  { label: 'University of Baguio', value: 'University of Baguio' },
  { label: 'University of Bahrain', value: 'University of Bahrain' },
  { label: 'University of Ballarat', value: 'University of Ballarat' },
  { label: 'University of Balochistan', value: 'University of Balochistan' },
  { label: 'University of Baltimore', value: 'University of Baltimore' },
  { label: 'University of Banja Luka', value: 'University of Banja Luka' },
  { label: 'University of Bari', value: 'University of Bari' },
  { label: 'University of Basel', value: 'University of Basel' },
  { label: 'University of Basrah', value: 'University of Basrah' },
  { label: 'University of Batangas', value: 'University of Batangas' },
  { label: 'University of Bath', value: 'University of Bath' },
  { label: 'University of Bedfordshire', value: 'University of Bedfordshire' },
  { label: 'University of Belgrade', value: 'University of Belgrade' },
  { label: 'University of Belize', value: 'University of Belize' },
  { label: 'University of Benghazi', value: 'University of Benghazi' },
  { label: 'University of Benin', value: 'University of Benin' },
  { label: 'University of Bergamo', value: 'University of Bergamo' },
  { label: 'University of Bergen', value: 'University of Bergen' },
  { label: 'University of Berne', value: 'University of Berne' },
  { label: 'University of Bialystok', value: 'University of Bialystok' },
  { label: 'University of Bihac', value: 'University of Bihac' },
  { label: 'University of Birmingham', value: 'University of Birmingham' },
  { label: 'University of Bohol', value: 'University of Bohol' },
  { label: 'University of Bologna', value: 'University of Bologna' },
  { label: 'University of Bolton', value: 'University of Bolton' },
  { label: 'University of Botswana', value: 'University of Botswana' },
  { label: 'University of Bradford', value: 'University of Bradford' },
  { label: 'University of Brescia', value: 'University of Brescia' },
  { label: 'University of Bridgeport', value: 'University of Bridgeport' },
  { label: 'University of Brighton', value: 'University of Brighton' },
  { label: 'University of Bristol', value: 'University of Bristol' },
  { label: 'University of British Columbia', value: 'University of British Columbia' },
  { label: 'University of Brunei Darussalam', value: 'University of Brunei Darussalam' },
  { label: 'University of Bucharest', value: 'University of Bucharest' },
  { label: 'University of Buckingham', value: 'University of Buckingham' },
  { label: 'University of Bunkyo', value: 'University of Bunkyo' },
  { label: 'University of Burao', value: 'University of Burao' },
  { label: 'University of Burdwan', value: 'University of Burdwan' },
  { label: 'University of Cagayan Valley', value: 'University of Cagayan Valley' },
  { label: 'University of Cagliari', value: 'University of Cagliari' },
  { label: 'University of Calabar', value: 'University of Calabar' },
  { label: 'University of Calabria', value: 'University of Calabria' },
  { label: 'University of Calcutta', value: 'University of Calcutta' },
  { label: 'University of Calgary', value: 'University of Calgary' },
  { label: 'University of Calicut', value: 'University of Calicut' },
  { label: 'University of California System', value: 'University of California System' },
  { label: 'University of California, Berkeley', value: 'University of California, Berkeley' },
  { label: 'University of California, Davis', value: 'University of California, Davis' },
  {
    label: 'University of California, Hastings College of Law',
    value: 'University of California, Hastings College of Law',
  },
  { label: 'University of California, Irvine', value: 'University of California, Irvine' },
  { label: 'University of California, Los Angeles', value: 'University of California, Los Angeles' },
  { label: 'University of California, Merced', value: 'University of California, Merced' },
  { label: 'University of California, Oakland', value: 'University of California, Oakland' },
  { label: 'University of California, Riverside', value: 'University of California, Riverside' },
  { label: 'University of California, San Diego', value: 'University of California, San Diego' },
  { label: 'University of California, San Francisco', value: 'University of California, San Francisco' },
  { label: 'University of California, Santa Barbara', value: 'University of California, Santa Barbara' },
  { label: 'University of California, Santa Cruz', value: 'University of California, Santa Cruz' },
  { label: 'University of Cambodia', value: 'University of Cambodia' },
  { label: 'University of Cambridge', value: 'University of Cambridge' },
  { label: 'University of Camerino', value: 'University of Camerino' },
  { label: 'University of Canberra', value: 'University of Canberra' },
  { label: 'University of Canterbury', value: 'University of Canterbury' },
  { label: 'University of Cape Coast', value: 'University of Cape Coast' },
  { label: 'University of Cape Town', value: 'University of Cape Town' },
  { label: 'University of Cassino', value: 'University of Cassino' },
  { label: 'University of Catania', value: 'University of Catania' },
  { label: 'University of Cebu', value: 'University of Cebu' },
  { label: 'University of Central Arkansas', value: 'University of Central Arkansas' },
  { label: 'University of Central Europe in Skalica', value: 'University of Central Europe in Skalica' },
  { label: 'University of Central Florida', value: 'University of Central Florida' },
  { label: 'University of Central Greece', value: 'University of Central Greece' },
  { label: 'University of Central Lancashire', value: 'University of Central Lancashire' },
  { label: 'University of Central Missouri', value: 'University of Central Missouri' },
  { label: 'University of Central Oklahoma', value: 'University of Central Oklahoma' },
  { label: 'University of Central Punjab', value: 'University of Central Punjab' },
  { label: 'University of Central Texas', value: 'University of Central Texas' },
  { label: 'University of Charleston', value: 'University of Charleston' },
  { label: 'University of Charleston South Carolina', value: 'University of Charleston South Carolina' },
  {
    label: 'University of Chemical Technology and Metallurgy',
    value: 'University of Chemical Technology and Metallurgy',
  },
  { label: 'University of Chester', value: 'University of Chester' },
  { label: 'University of Chicago', value: 'University of Chicago' },
  { label: 'University of Chieti', value: 'University of Chieti' },
  { label: 'University of Chittagong', value: 'University of Chittagong' },
  { label: 'University of Cincinnati', value: 'University of Cincinnati' },
  { label: 'University of Colombo', value: 'University of Colombo' },
  { label: 'University of Colorado at Boulder', value: 'University of Colorado at Boulder' },
  { label: 'University of Colorado at Colorado Springs', value: 'University of Colorado at Colorado Springs' },
  { label: 'University of Colorado at Denver', value: 'University of Colorado at Denver' },
  { label: 'University of Colorado Health Sciences Center', value: 'University of Colorado Health Sciences Center' },
  { label: 'University of Commerce Luigi Bocconi', value: 'University of Commerce Luigi Bocconi' },
  { label: 'University of Computer Studies, Yangon', value: 'University of Computer Studies, Yangon' },
  { label: 'University of Connecticut', value: 'University of Connecticut' },
  { label: 'University of Connecticut at Avery Point', value: 'University of Connecticut at Avery Point' },
  { label: 'University of Connecticut at Hartford', value: 'University of Connecticut at Hartford' },
  { label: 'University of Connecticut at Stamford', value: 'University of Connecticut at Stamford' },
  { label: 'University of Connecticut at Waterbury', value: 'University of Connecticut at Waterbury' },
  { label: 'University of Connecticut Health Center', value: 'University of Connecticut Health Center' },
  { label: 'University of Constanta', value: 'University of Constanta' },
  { label: 'University of Constanta Medical School', value: 'University of Constanta Medical School' },
  { label: 'University of Craiova', value: 'University of Craiova' },
  { label: 'University of Crete', value: 'University of Crete' },
  { label: 'University of Cyprus', value: 'University of Cyprus' },
  { label: 'University of Da Lat', value: 'University of Da Lat' },
  { label: 'University of Da Nang', value: 'University of Da Nang' },
  { label: 'University of Dallas', value: 'University of Dallas' },
  { label: 'University of Dammam', value: 'University of Dammam' },
  { label: 'University of Dar Es Salaam', value: 'University of Dar Es Salaam' },
  { label: 'University of Dayton', value: 'University of Dayton' },
  { label: 'University of Debrecen ', value: 'University of Debrecen ' },
  { label: 'University of Delaware', value: 'University of Delaware' },
  { label: 'University of Delhi', value: 'University of Delhi' },
  { label: 'University of Denver', value: 'University of Denver' },
  { label: 'University of Derby', value: 'University of Derby' },
  { label: 'University of Detroit Mercy', value: 'University of Detroit Mercy' },
  { label: 'University of Development Alternative', value: 'University of Development Alternative' },
  { label: 'University of Dhaka', value: 'University of Dhaka' },
  { label: 'University of Divinity', value: 'University of Divinity' },
  { label: 'University of Dodoma', value: 'University of Dodoma' },
  { label: 'University of Dohuk (Kurdistan Region)', value: 'University of Dohuk (Kurdistan Region)' },
  { label: 'University of Dongola', value: 'University of Dongola' },
  { label: 'University Of Dubai', value: 'University Of Dubai' },
  { label: 'University of Dublin, Trinity College', value: 'University of Dublin, Trinity College' },
  { label: 'University of Dubrovnik', value: 'University of Dubrovnik' },
  { label: 'University of Dubuque', value: 'University of Dubuque' },
  { label: 'University of Dundee', value: 'University of Dundee' },
  { label: 'University of Durham', value: 'University of Durham' },
  { label: 'University of East', value: 'University of East' },
  { label: 'University of East Anglia', value: 'University of East Anglia' },
  { label: 'University of East Asia', value: 'University of East Asia' },
  { label: 'University of East London', value: 'University of East London' },
  { label: 'University of East Srarajevo', value: 'University of East Srarajevo' },
  { label: 'University of Eastern Africa, Baraton', value: 'University of Eastern Africa, Baraton' },
  { label: 'University of Eastern Philippines', value: 'University of Eastern Philippines' },
  { label: 'University of Economics in Bratislava', value: 'University of Economics in Bratislava' },
  { label: 'University of Economics Prague', value: 'University of Economics Prague' },
  { label: 'University of Economics Varna', value: 'University of Economics Varna' },
  { label: 'University of Edinburgh', value: 'University of Edinburgh' },
  { label: 'University of Education', value: 'University of Education' },
  { label: 'University of Education Hradec Kralove', value: 'University of Education Hradec Kralove' },
  { label: 'University of Education, Winneba', value: 'University of Education, Winneba' },
  { label: 'University of El Imam El Mahdi University', value: 'University of El Imam El Mahdi University' },
  { label: 'University of Elbasan Aleksander Xhuvani', value: 'University of Elbasan Aleksander Xhuvani' },
  { label: 'University of Eldoret', value: 'University of Eldoret' },
  { label: 'University of Electro-Communications', value: 'University of Electro-Communications' },
  {
    label: 'University of Electronic Science and Technology of China',
    value: 'University of Electronic Science and Technology of China',
  },
  {
    label: 'University of Engineering and Technology Lahore',
    value: 'University of Engineering and Technology Lahore',
  },
  {
    label: 'University of Engineering and Technology Peshawar',
    value: 'University of Engineering and Technology Peshawar',
  },
  {
    label: 'University of Engineering and Technology Taxila',
    value: 'University of Engineering and Technology Taxila',
  },
  { label: 'University of Essex', value: 'University of Essex' },
  { label: 'University of Evansville', value: 'University of Evansville' },
  { label: 'University of Exeter', value: 'University of Exeter' },
  { label: 'University of Faisalabad', value: 'University of Faisalabad' },
  { label: 'University of Ferrara', value: 'University of Ferrara' },
  { label: 'University of Fiji', value: 'University of Fiji' },
  {
    label: 'University of Finance and Management in Bialystok',
    value: 'University of Finance and Management in Bialystok',
  },
  { label: 'University of Findlay', value: 'University of Findlay' },
  { label: 'University of Fine Arts Belgrade', value: 'University of Fine Arts Belgrade' },
  { label: 'University of Florence', value: 'University of Florence' },
  { label: 'University of Florida', value: 'University of Florida' },
  { label: 'University of Foggia', value: 'University of Foggia' },
  { label: 'University of Food Technology', value: 'University of Food Technology' },
  { label: 'University of Forestry Sofia', value: 'University of Forestry Sofia' },
  { label: 'University of Fort Hare', value: 'University of Fort Hare' },
  { label: 'University of Fribourg', value: 'University of Fribourg' },
  { label: 'University of Gadarif', value: 'University of Gadarif' },
  { label: 'University of Galatzi', value: 'University of Galatzi' },
  { label: 'University of Gastronomic Sciences', value: 'University of Gastronomic Sciences' },
  { label: 'University of Gdansk', value: 'University of Gdansk' },
  { label: 'University of Geneva', value: 'University of Geneva' },
  { label: 'University of Genoa', value: 'University of Genoa' },
  { label: 'University of Georgia', value: 'University of Georgia' },
  { label: 'University of Gezira', value: 'University of Gezira' },
  { label: 'University of Ghana', value: 'University of Ghana' },
  { label: 'University of Gjirokstra Eqerem Cabej', value: 'University of Gjirokstra Eqerem Cabej' },
  { label: 'University of Glamorgan', value: 'University of Glamorgan' },
  { label: 'University of Glasgow', value: 'University of Glasgow' },
  { label: 'University of Gloucestershire', value: 'University of Gloucestershire' },
  { label: 'University of Goroka', value: 'University of Goroka' },
  { label: 'University of Great Falls', value: 'University of Great Falls' },
  { label: 'University of Greenland', value: 'University of Greenland' },
  { label: 'University of Greenwich', value: 'University of Greenwich' },
  { label: 'University of Grigol Robakidze', value: 'University of Grigol Robakidze' },
  { label: 'University of Groningen', value: 'University of Groningen' },
  { label: 'University of Guam', value: 'University of Guam' },
  { label: 'University of Guelph', value: 'University of Guelph' },
  { label: 'University of Gujrat', value: 'University of Gujrat' },
  { label: 'University of Guyana', value: 'University of Guyana' },
  { label: 'University of Haifa', value: 'University of Haifa' },
  { label: 'University of Hail', value: 'University of Hail' },
  { label: 'University of Halifax, Birmingham Campus', value: 'University of Halifax, Birmingham Campus' },
  { label: 'University of Hargeisa', value: 'University of Hargeisa' },
  { label: 'University of Hartford', value: 'University of Hartford' },
  { label: 'University of Hawaii - Hilo', value: 'University of Hawaii - Hilo' },
  { label: 'University of Hawaii - Manoa', value: 'University of Hawaii - Manoa' },
  { label: 'University Of Hawaii - System', value: 'University Of Hawaii - System' },
  { label: 'University of Hawaii - West Oahu', value: 'University of Hawaii - West Oahu' },
  { label: 'University of Health and Science', value: 'University of Health and Science' },
  { label: 'University of Health Sciences', value: 'University of Health Sciences' },
  { label: 'University of Health Sciences Andhra Pradesh', value: 'University of Health Sciences Andhra Pradesh' },
  { label: 'University of Health Sciences Antigua', value: 'University of Health Sciences Antigua' },
  { label: 'University of Helsinki', value: 'University of Helsinki' },
  { label: 'University of Hertfordshire', value: 'University of Hertfordshire' },
  { label: 'University of Holy Quran and Islamic Siences', value: 'University of Holy Quran and Islamic Siences' },
  { label: 'University of Hong Kong', value: 'University of Hong Kong' },
  { label: 'University of Hormozgan', value: 'University of Hormozgan' },
  { label: 'University of Horticulture and Food Industry', value: 'University of Horticulture and Food Industry' },
  { label: 'University of Houston', value: 'University of Houston' },
  { label: 'University of Houston, Clear Lake', value: 'University of Houston, Clear Lake' },
  { label: 'University of Houston, Downtown', value: 'University of Houston, Downtown' },
  { label: 'University of Houston, Victoria', value: 'University of Houston, Victoria' },
  { label: 'University of Huddersfield', value: 'University of Huddersfield' },
  { label: 'University of Hull', value: 'University of Hull' },
  {
    label: 'University of Human Development (Kurdistan Region)',
    value: 'University of Human Development (Kurdistan Region)',
  },
  { label: 'University of Hyderabad', value: 'University of Hyderabad' },
  { label: 'University of Hydraulic Electric Engineering', value: 'University of Hydraulic Electric Engineering' },
  { label: 'University of Iasi', value: 'University of Iasi' },
  { label: 'University of Ibadan', value: 'University of Ibadan' },
  { label: 'University of Iceland', value: 'University of Iceland' },
  { label: 'University of Idaho', value: 'University of Idaho' },
  { label: 'University of Illinois', value: 'University of Illinois' },
  { label: 'University of Illinois at Chicago', value: 'University of Illinois at Chicago' },
  { label: 'University of Illinois at Springfield', value: 'University of Illinois at Springfield' },
  { label: 'University of Illinois at Urbana-Champaign', value: 'University of Illinois at Urbana-Champaign' },
  { label: 'University of Ilorin', value: 'University of Ilorin' },
  { label: 'University of Indianapolis', value: 'University of Indianapolis' },
  { label: 'University of Indianapolis in Athens', value: 'University of Indianapolis in Athens' },
  {
    label: 'University of Information Technology & Sciences',
    value: 'University of Information Technology & Sciences',
  },
  {
    label: 'University of Information Technology and Management in Rzeszow',
    value: 'University of Information Technology and Management in Rzeszow',
  },
  { label: 'University of Insubria', value: 'University of Insubria' },
  { label: 'University of Insurance and Banking in Warsaw', value: 'University of Insurance and Banking in Warsaw' },
  { label: 'University of International Business', value: 'University of International Business' },
  {
    label: 'University of International Business and Economics',
    value: 'University of International Business and Economics',
  },
  { label: 'University of Ioannina', value: 'University of Ioannina' },
  { label: 'University of Iowa', value: 'University of Iowa' },
  { label: 'University of Islamic Studies', value: 'University of Islamic Studies' },
  {
    label: 'University of Italian Studies for Foreigners of Perugia',
    value: 'University of Italian Studies for Foreigners of Perugia',
  },
  {
    label: 'University of Italian Studies for Foreigners of Siena',
    value: 'University of Italian Studies for Foreigners of Siena',
  },
  { label: 'University of Jaffna', value: 'University of Jaffna' },
  { label: 'University of Jammu', value: 'University of Jammu' },
  { label: 'University of Jan Evangelista Purkyne', value: 'University of Jan Evangelista Purkyne' },
  { label: 'University of Jazeera', value: 'University of Jazeera' },
  { label: 'University of Jinan', value: 'University of Jinan' },
  { label: 'University of Joensuu', value: 'University of Joensuu' },
  { label: 'University of Johannesburg', value: 'University of Johannesburg' },
  { label: 'University of Jordan', value: 'University of Jordan' },
  { label: 'University of Jos', value: 'University of Jos' },
  { label: 'University of Juba', value: 'University of Juba' },
  { label: 'University of Jyväskylä', value: 'University of Jyväskylä' },
  { label: 'University of Kabianga', value: 'University of Kabianga' },
  { label: 'University of Kalamoon', value: 'University of Kalamoon' },
  { label: 'University of Kalyani', value: 'University of Kalyani' },
  { label: 'University of Kansas', value: 'University of Kansas' },
  { label: 'University of Karachi', value: 'University of Karachi' },
  { label: 'University of Kashmir', value: 'University of Kashmir' },
  { label: 'University of Kelaniya', value: 'University of Kelaniya' },
  { label: 'University of Kent at Canterbury', value: 'University of Kent at Canterbury' },
  { label: 'University of Kentucky', value: 'University of Kentucky' },
  { label: 'University of Kerala', value: 'University of Kerala' },
  { label: 'University of Khartoum', value: 'University of Khartoum' },
  { label: "University of King's College", value: "University of King's College" },
  { label: 'University of Kirkuk', value: 'University of Kirkuk' },
  { label: 'University of Korca Fan Noli', value: 'University of Korca Fan Noli' },
  { label: 'University of Kordofan', value: 'University of Kordofan' },
  { label: 'University of Kota', value: 'University of Kota' },
  { label: 'University of Kragujevac', value: 'University of Kragujevac' },
  { label: 'University of Kufa', value: 'University of Kufa' },
  { label: 'University of Kuopio', value: 'University of Kuopio' },
  { label: 'University of Kurdistan', value: 'University of Kurdistan' },
  { label: 'University of Kurdistan - Hawler', value: 'University of Kurdistan - Hawler' },
  { label: 'University of KwaZulu-Natal', value: 'University of KwaZulu-Natal' },
  { label: 'University of La Verne', value: 'University of La Verne' },
  { label: 'University of Lagos', value: 'University of Lagos' },
  { label: 'University of Lahore', value: 'University of Lahore' },
  { label: 'University of Lancaster', value: 'University of Lancaster' },
  { label: 'University of Lapland', value: 'University of Lapland' },
  { label: 'University of Latvia', value: 'University of Latvia' },
  { label: 'University of Lausanne', value: 'University of Lausanne' },
  { label: 'University of Lavasanat Pnu', value: 'University of Lavasanat Pnu' },
  { label: 'University of LaVerne in Athens', value: 'University of LaVerne in Athens' },
  { label: 'University of Lecce', value: 'University of Lecce' },
  { label: 'University of Leeds', value: 'University of Leeds' },
  { label: 'University of Leicester', value: 'University of Leicester' },
  { label: 'University of Lethbridge', value: 'University of Lethbridge' },
  { label: 'University of Liberal Arts', value: 'University of Liberal Arts' },
  { label: 'University of Liberia', value: 'University of Liberia' },
  { label: 'University of Limerick', value: 'University of Limerick' },
  { label: 'University of Limpopo', value: 'University of Limpopo' },
  { label: 'University of Lincoln', value: 'University of Lincoln' },
  { label: 'University of Liverpool', value: 'University of Liverpool' },
  { label: 'University of Livingstonia', value: 'University of Livingstonia' },
  { label: 'University of Ljubljana', value: 'University of Ljubljana' },
  { label: 'University of lloilo', value: 'University of lloilo' },
  { label: 'University of Lodz', value: 'University of Lodz' },
  { label: 'University of London', value: 'University of London' },
  { label: 'University of Louisiana at Lafayette', value: 'University of Louisiana at Lafayette' },
  { label: 'University of Louisiana at Monroe', value: 'University of Louisiana at Monroe' },
  { label: 'University of Louisville', value: 'University of Louisville' },
  {
    label: 'University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.',
    value: 'University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.',
  },
  { label: 'University of Lucerne', value: 'University of Lucerne' },
  { label: 'University of Lucknow', value: 'University of Lucknow' },
  { label: 'University of Lusaka', value: 'University of Lusaka' },
  { label: 'University of Luxemburg', value: 'University of Luxemburg' },
  { label: 'University of Maastricht', value: 'University of Maastricht' },
  { label: 'University of Macau', value: 'University of Macau' },
  { label: 'University of Macedonia', value: 'University of Macedonia' },
  { label: 'University of Macerata', value: 'University of Macerata' },
  { label: 'University of Madras', value: 'University of Madras' },
  { label: 'University of Maiduguri', value: 'University of Maiduguri' },
  { label: 'University of Maine (System)', value: 'University of Maine (System)' },
  { label: 'University of Maine, Augusta', value: 'University of Maine, Augusta' },
  { label: 'University of Maine, Farmington', value: 'University of Maine, Farmington' },
  { label: 'University of Maine, Fort Kent', value: 'University of Maine, Fort Kent' },
  { label: 'University of Maine, Machias', value: 'University of Maine, Machias' },
  { label: 'University of Maine, Orono', value: 'University of Maine, Orono' },
  { label: 'University of Maine, Presque Isle', value: 'University of Maine, Presque Isle' },
  { label: 'University of Malakand', value: 'University of Malakand' },
  { label: 'University of Malawi', value: 'University of Malawi' },
  { label: 'University of Malta', value: 'University of Malta' },
  { label: 'University of Management & Technology', value: 'University of Management & Technology' },
  { label: 'University of Management and Marketing', value: 'University of Management and Marketing' },
  { label: 'University of Management and Technology', value: 'University of Management and Technology' },
  { label: 'University of Management and Technology ', value: 'University of Management and Technology ' },
  { label: 'University of Manchester', value: 'University of Manchester' },
  { label: 'University of Manila', value: 'University of Manila' },
  { label: 'University of Manitoba', value: 'University of Manitoba' },
  { label: 'University of Maribor', value: 'University of Maribor' },
  {
    label: 'University of Marketing and Distribution Sciences',
    value: 'University of Marketing and Distribution Sciences',
  },
  { label: 'University of Mary', value: 'University of Mary' },
  { label: 'University of Mary Hardin-Baylor', value: 'University of Mary Hardin-Baylor' },
  { label: 'University of Maryland (System)', value: 'University of Maryland (System)' },
  { label: 'University of Maryland at Baltimore', value: 'University of Maryland at Baltimore' },
  { label: 'University of Maryland at College Park', value: 'University of Maryland at College Park' },
  { label: 'University of Maryland Baltimore County', value: 'University of Maryland Baltimore County' },
  { label: 'University of Maryland Eastern Shore', value: 'University of Maryland Eastern Shore' },
  { label: 'University of Maryland Medicine', value: 'University of Maryland Medicine' },
  { label: 'University of Maryland University College', value: 'University of Maryland University College' },
  { label: 'University of Mashhad Pnu', value: 'University of Mashhad Pnu' },
  { label: 'University of Massachusetts (System)', value: 'University of Massachusetts (System)' },
  { label: 'University of Massachusetts at Amherst', value: 'University of Massachusetts at Amherst' },
  { label: 'University of Massachusetts at Boston', value: 'University of Massachusetts at Boston' },
  { label: 'University of Massachusetts at Dartmouth', value: 'University of Massachusetts at Dartmouth' },
  { label: 'University of Massachusetts at Lowell', value: 'University of Massachusetts at Lowell' },
  {
    label: 'University of Massachusetts Medical Center at Worcester',
    value: 'University of Massachusetts Medical Center at Worcester',
  },
  { label: 'University of Mauritius', value: 'University of Mauritius' },
  { label: 'University of Media Arts', value: 'University of Media Arts' },
  {
    label: 'University Of Medical Sciences & Technology (UMST)',
    value: 'University Of Medical Sciences & Technology (UMST)',
  },
  { label: 'University of Medicine 1, Yangon', value: 'University of Medicine 1, Yangon' },
  {
    label: 'University of Medicine and Dentistry of New Jersey',
    value: 'University of Medicine and Dentistry of New Jersey',
  },
  {
    label: 'University of Medicine and Pharmacology of Oradea',
    value: 'University of Medicine and Pharmacology of Oradea',
  },
  {
    label: 'University of Medicine and Pharmacy of Bucharest',
    value: 'University of Medicine and Pharmacy of Bucharest',
  },
  {
    label: 'University of Medicine and Pharmacy of Cluj-Napoca',
    value: 'University of Medicine and Pharmacy of Cluj-Napoca',
  },
  { label: 'University of Medicine and Pharmacy of Iasi', value: 'University of Medicine and Pharmacy of Iasi' },
  {
    label: 'University of Medicine and Pharmacy of Targu Mures',
    value: 'University of Medicine and Pharmacy of Targu Mures',
  },
  {
    label: 'University of Medicine and Pharmacy of Timisoara',
    value: 'University of Medicine and Pharmacy of Timisoara',
  },
  { label: 'University of Medicine Magway', value: 'University of Medicine Magway' },
  { label: 'University of Melbourne', value: 'University of Melbourne' },
  { label: 'University of Memphis', value: 'University of Memphis' },
  { label: 'University of Messina', value: 'University of Messina' },
  { label: 'University of Miami', value: 'University of Miami' },
  { label: 'University of Michigan - Ann Arbor', value: 'University of Michigan - Ann Arbor' },
  { label: 'University of Michigan - Dearborn', value: 'University of Michigan - Dearborn' },
  { label: 'University of Michigan - Flint', value: 'University of Michigan - Flint' },
  { label: 'University of Milan', value: 'University of Milan' },
  { label: 'University of Milan - Bicocca', value: 'University of Milan - Bicocca' },
  { label: 'University of Mindanao', value: 'University of Mindanao' },
  { label: 'University of Mining and Geology St. Ivan Rils', value: 'University of Mining and Geology St. Ivan Rils' },
  { label: 'University of Minnesota - Crookston', value: 'University of Minnesota - Crookston' },
  { label: 'University of Minnesota - Duluth', value: 'University of Minnesota - Duluth' },
  { label: 'University of Minnesota - Morris', value: 'University of Minnesota - Morris' },
  { label: 'University of Minnesota - Twin Cities Campus', value: 'University of Minnesota - Twin Cities Campus' },
  { label: 'University of Miskolc', value: 'University of Miskolc' },
  { label: 'University of Mississippi', value: 'University of Mississippi' },
  { label: 'University of Mississippi Medical Center', value: 'University of Mississippi Medical Center' },
  { label: 'University of Missouri - Columbia', value: 'University of Missouri - Columbia' },
  { label: 'University of Missouri - Kansas City', value: 'University of Missouri - Kansas City' },
  { label: 'University of Missouri - Saint Louis', value: 'University of Missouri - Saint Louis' },
  { label: 'University of Mkar', value: 'University of Mkar' },
  { label: 'University of Mobile', value: 'University of Mobile' },
  { label: 'University of Modena', value: 'University of Modena' },
  { label: 'University of Modern Sciences', value: 'University of Modern Sciences' },
  { label: 'University of Mohaghegh', value: 'University of Mohaghegh' },
  { label: 'University of Molise', value: 'University of Molise' },
  { label: 'University of Moncton', value: 'University of Moncton' },
  { label: 'University of Moncton, Edmundston', value: 'University of Moncton, Edmundston' },
  { label: 'University of Moncton, Shippagan', value: 'University of Moncton, Shippagan' },
  { label: 'University of Montana', value: 'University of Montana' },
  { label: 'University of Montana Western', value: 'University of Montana Western' },
  { label: 'University of Montenegro', value: 'University of Montenegro' },
  { label: 'University of Montevallo', value: 'University of Montevallo' },
  { label: 'University of Moratuwa', value: 'University of Moratuwa' },
  { label: 'University of Mostar', value: 'University of Mostar' },
  { label: 'University of Mosul', value: 'University of Mosul' },
  { label: 'University of Mumbai', value: 'University of Mumbai' },
  { label: 'University of Munawwar-ul-Islam', value: 'University of Munawwar-ul-Islam' },
  { label: 'University of Music and Fine Arts', value: 'University of Music and Fine Arts' },
  { label: 'University of Mustansiriyah', value: 'University of Mustansiriyah' },
  { label: 'University of Mysore', value: 'University of Mysore' },
  { label: 'University of Nairobi', value: 'University of Nairobi' },
  { label: 'University of Namibia', value: 'University of Namibia' },
  { label: 'University of Naples Federico II', value: 'University of Naples Federico II' },
  { label: 'University of National and World Economy', value: 'University of National and World Economy' },
  { label: 'University of Nebraska - Kearney', value: 'University of Nebraska - Kearney' },
  { label: 'University of Nebraska - Lincoln', value: 'University of Nebraska - Lincoln' },
  { label: 'University of Nebraska - Omaha', value: 'University of Nebraska - Omaha' },
  { label: 'University of Nebraska (System)', value: 'University of Nebraska (System)' },
  { label: 'University of Nebraska Medical Center', value: 'University of Nebraska Medical Center' },
  { label: 'University of Negros Occidental-Recoletos', value: 'University of Negros Occidental-Recoletos' },
  { label: 'University of Neuchatel', value: 'University of Neuchatel' },
  { label: 'University of Nevada - Las Vegas', value: 'University of Nevada - Las Vegas' },
  { label: 'University of Nevada - Reno', value: 'University of Nevada - Reno' },
  { label: 'University of New Brunswick', value: 'University of New Brunswick' },
  { label: 'University of New Brunswick, Saint John', value: 'University of New Brunswick, Saint John' },
  { label: 'University of New Caledonia', value: 'University of New Caledonia' },
  { label: 'University of New England', value: 'University of New England' },
  { label: 'University of New England', value: 'University of New England' },
  {
    label: 'University of New England, Westbrook College Campus',
    value: 'University of New England, Westbrook College Campus',
  },
  { label: 'University of New Hampshire', value: 'University of New Hampshire' },
  { label: 'University of New Hampshire - Manchester', value: 'University of New Hampshire - Manchester' },
  { label: 'University of New Haven', value: 'University of New Haven' },
  { label: 'University of New Mexico', value: 'University of New Mexico' },
  { label: 'University of New Orleans', value: 'University of New Orleans' },
  { label: 'University of New South Wales', value: 'University of New South Wales' },
  { label: 'University of New York in Prague', value: 'University of New York in Prague' },
  { label: 'University of New York Tirana', value: 'University of New York Tirana' },
  { label: 'University of Newcastle', value: 'University of Newcastle' },
  { label: 'University of Newcastle-upon-Tyne', value: 'University of Newcastle-upon-Tyne' },
  { label: 'University of Nicosia', value: 'University of Nicosia' },
  { label: 'University of Nigeria', value: 'University of Nigeria' },
  { label: 'University of Nijenrode', value: 'University of Nijenrode' },
  { label: 'University of Nis', value: 'University of Nis' },
  { label: 'University of Nizwa', value: 'University of Nizwa' },
  { label: 'University of North Alabama', value: 'University of North Alabama' },
  { label: 'University of North America', value: 'University of North America' },
  { label: 'University of North Bengal', value: 'University of North Bengal' },
  { label: 'University of North Carolina at Asheville', value: 'University of North Carolina at Asheville' },
  { label: 'University of North Carolina at Chapel Hill', value: 'University of North Carolina at Chapel Hill' },
  { label: 'University of North Carolina at Charlotte', value: 'University of North Carolina at Charlotte' },
  { label: 'University of North Carolina at Greensboro', value: 'University of North Carolina at Greensboro' },
  { label: 'University of North Carolina at Pembroke', value: 'University of North Carolina at Pembroke' },
  { label: 'University of North Carolina at Wilmington', value: 'University of North Carolina at Wilmington' },
  { label: 'University of North Dakota', value: 'University of North Dakota' },
  { label: 'University of North Florida', value: 'University of North Florida' },
  { label: 'University of North London', value: 'University of North London' },
  { label: 'University of North Texas', value: 'University of North Texas' },
  {
    label: 'University of North Texas Health Science Center at Fort Worth',
    value: 'University of North Texas Health Science Center at Fort Worth',
  },
  { label: 'University of North West', value: 'University of North West' },
  { label: 'University of Northampton', value: 'University of Northampton' },
  { label: 'University of Northeastern Philippines', value: 'University of Northeastern Philippines' },
  { label: 'University of Northern Bahr El-Ghazal', value: 'University of Northern Bahr El-Ghazal' },
  { label: 'University of Northern British Columbia', value: 'University of Northern British Columbia' },
  { label: 'University of Northern Colorado', value: 'University of Northern Colorado' },
  { label: 'University of Northern Iowa', value: 'University of Northern Iowa' },
  { label: 'University of Northern Philippines', value: 'University of Northern Philippines' },
  { label: 'University of Northern Virginia', value: 'University of Northern Virginia' },
  { label: 'University of Northern Virginia, Prague Campus', value: 'University of Northern Virginia, Prague Campus' },
  { label: 'University of Northern Washington', value: 'University of Northern Washington' },
  { label: 'University of Northumbria at Newcastle', value: 'University of Northumbria at Newcastle' },
  { label: 'University of NorthWest', value: 'University of NorthWest' },
  { label: 'University of NorthWest', value: 'University of NorthWest' },
  { label: 'University of Notre Dame', value: 'University of Notre Dame' },
  { label: 'University of Notre Dame Australia', value: 'University of Notre Dame Australia' },
  { label: 'University of Nottingham', value: 'University of Nottingham' },
  { label: 'University of Nottingham, Malaysia Campus', value: 'University of Nottingham, Malaysia Campus' },
  { label: 'University of Nova Gorica', value: 'University of Nova Gorica' },
  { label: 'University of Novi Sad', value: 'University of Novi Sad' },
  { label: 'University of Nueva Caceres', value: 'University of Nueva Caceres' },
  {
    label: 'University of Occupational and Environmental Health, Japan',
    value: 'University of Occupational and Environmental Health, Japan',
  },
  { label: 'University of Oklahoma', value: 'University of Oklahoma' },
  { label: 'University of Oklahoma Health Sciences Center', value: 'University of Oklahoma Health Sciences Center' },
  { label: 'University of Ontario Institute of Technology', value: 'University of Ontario Institute of Technology' },
  { label: 'University of Opole', value: 'University of Opole' },
  { label: 'University of Oradea', value: 'University of Oradea' },
  { label: 'University of Oregon', value: 'University of Oregon' },
  { label: 'University of Osijek', value: 'University of Osijek' },
  { label: 'University of Oslo', value: 'University of Oslo' },
  {
    label: 'University of Osteopathic Medicine and Health Science',
    value: 'University of Osteopathic Medicine and Health Science',
  },
  { label: 'University of Ostrava', value: 'University of Ostrava' },
  { label: 'University of Otago', value: 'University of Otago' },
  { label: 'University of Ottawa', value: 'University of Ottawa' },
  { label: 'University of Oulu', value: 'University of Oulu' },
  { label: 'University of Oxford', value: 'University of Oxford' },
  { label: 'University of Padua', value: 'University of Padua' },
  { label: 'University of Paisley', value: 'University of Paisley' },
  { label: 'University of Palermo', value: 'University of Palermo' },
  { label: 'University of Palestine', value: 'University of Palestine' },
  { label: 'University of Pangasinan', value: 'University of Pangasinan' },
  { label: 'University of Pannonia', value: 'University of Pannonia' },
  { label: 'University of Papua New Guinea', value: 'University of Papua New Guinea' },
  { label: 'University of Pardubice', value: 'University of Pardubice' },
  { label: 'University of Parma', value: 'University of Parma' },
  { label: 'University of Patras', value: 'University of Patras' },
  { label: 'University of Pavia', value: 'University of Pavia' },
  { label: 'University of Pecs', value: 'University of Pecs' },
  { label: 'University of Peloponnese', value: 'University of Peloponnese' },
  { label: 'University of Pennsylvania', value: 'University of Pennsylvania' },
  { label: 'University of Peradeniya', value: 'University of Peradeniya' },
  { label: 'University of Performing Arts in Bratislava', value: 'University of Performing Arts in Bratislava' },
  { label: 'University of Perpetual Help', value: 'University of Perpetual Help' },
  { label: 'University of Perpetual Help, System Dalta', value: 'University of Perpetual Help, System Dalta' },
  { label: 'University of Perugia', value: 'University of Perugia' },
  { label: 'University of Peshawar', value: 'University of Peshawar' },
  { label: 'University of Petra', value: 'University of Petra' },
  { label: 'University of Petroleum (East China)', value: 'University of Petroleum (East China)' },
  { label: 'University of Petrosani', value: 'University of Petrosani' },
  { label: 'University of Phoenix', value: 'University of Phoenix' },
  { label: 'University of Piraeus', value: 'University of Piraeus' },
  { label: 'University of Pisa', value: 'University of Pisa' },
  { label: 'University of Pitesti', value: 'University of Pitesti' },
  { label: 'University of Pittsburgh', value: 'University of Pittsburgh' },
  { label: 'University of Pittsburgh at Bradford', value: 'University of Pittsburgh at Bradford' },
  { label: 'University of Pittsburgh at Greensburg', value: 'University of Pittsburgh at Greensburg' },
  { label: 'University of Pittsburgh at Johnstown', value: 'University of Pittsburgh at Johnstown' },
  { label: 'University of Plovdiv', value: 'University of Plovdiv' },
  { label: 'University of Plymouth', value: 'University of Plymouth' },
  { label: 'University of Portharcourt', value: 'University of Portharcourt' },
  { label: 'University of Portland', value: 'University of Portland' },
  { label: 'University of Portsmouth', value: 'University of Portsmouth' },
  { label: 'University of Presov', value: 'University of Presov' },
  { label: 'University of Pretoria', value: 'University of Pretoria' },
  { label: 'University of Primorska', value: 'University of Primorska' },
  { label: 'University of Prince Edward Island', value: 'University of Prince Edward Island' },
  { label: 'University of Priština', value: 'University of Priština' },
  { label: 'University of Puget Sound', value: 'University of Puget Sound' },
  { label: 'University of Pula', value: 'University of Pula' },
  { label: 'University of Pune', value: 'University of Pune' },
  { label: 'University of Qatar', value: 'University of Qatar' },
  { label: 'University of Québec', value: 'University of Québec' },
  { label: 'University of Queensland', value: 'University of Queensland' },
  { label: 'University of Rajasthan', value: 'University of Rajasthan' },
  { label: 'University of Rajshahi', value: 'University of Rajshahi' },
  { label: 'University of Reading', value: 'University of Reading' },
  { label: 'University of Redlands', value: 'University of Redlands' },
  { label: 'University of Reggio Calabria', value: 'University of Reggio Calabria' },
  { label: 'University of Regina', value: 'University of Regina' },
  { label: 'University of Regina Carmeli', value: 'University of Regina Carmeli' },
  { label: 'University of Resita', value: 'University of Resita' },
  { label: 'University of Rhode Island', value: 'University of Rhode Island' },
  { label: 'University of Richmond', value: 'University of Richmond' },
  { label: 'University of Rijeka', value: 'University of Rijeka' },
  { label: 'University of Rio Grande', value: 'University of Rio Grande' },
  { label: 'University of Rochester', value: 'University of Rochester' },
  { label: 'University of Roma La Sapienza', value: 'University of Roma La Sapienza' },
  { label: 'University of Roma Tor Vergata', value: 'University of Roma Tor Vergata' },
  { label: 'University of Roorkee', value: 'University of Roorkee' },
  { label: 'University of Rousse', value: 'University of Rousse' },
  { label: 'University of Ruhuna', value: 'University of Ruhuna' },
  { label: 'University of Sadat City', value: 'University of Sadat City' },
  { label: 'University of Saint Joseph', value: 'University of Saint Joseph' },
  { label: 'University of Saint La Salle', value: 'University of Saint La Salle' },
  { label: 'University of Salerno', value: 'University of Salerno' },
  { label: 'University of Salford', value: 'University of Salford' },
  { label: 'University of San Agustin', value: 'University of San Agustin' },
  { label: 'University of San Carlos', value: 'University of San Carlos' },
  { label: 'University of San Diego', value: 'University of San Diego' },
  { label: 'University of San Francisco', value: 'University of San Francisco' },
  { label: 'University of San Jose Recoletos', value: 'University of San Jose Recoletos' },
  { label: 'University of San Marino', value: 'University of San Marino' },
  { label: 'University of Sannio', value: 'University of Sannio' },
  { label: 'University of Santo Tomas', value: 'University of Santo Tomas' },
  { label: 'University of Sarajevo', value: 'University of Sarajevo' },
  { label: 'University of Sargodha', value: 'University of Sargodha' },
  { label: 'University of Saskatchewan', value: 'University of Saskatchewan' },
  { label: 'University of Sassari', value: 'University of Sassari' },
  { label: 'University of Science & Technology Bannu', value: 'University of Science & Technology Bannu' },
  { label: 'University of Science & Technology Chittagong', value: 'University of Science & Technology Chittagong' },
  { label: 'University of Science and Arts of Oklahoma', value: 'University of Science and Arts of Oklahoma' },
  { label: 'University of Science and Culture', value: 'University of Science and Culture' },
  { label: 'University of Science and Technology', value: 'University of Science and Technology' },
  { label: 'University of Science and Technology', value: 'University of Science and Technology' },
  { label: 'University of Science and Technology Beijing', value: 'University of Science and Technology Beijing' },
  { label: 'University of Science and Technology of China', value: 'University of Science and Technology of China' },
  { label: "University of Science and Technology Sana'a", value: "University of Science and Technology Sana'a" },
  { label: 'University of Science, Arts and Technology', value: 'University of Science, Arts and Technology' },
  { label: 'University of Scranton', value: 'University of Scranton' },
  { label: 'University of Seychelles', value: 'University of Seychelles' },
  {
    label: 'University of Seychelles - American Institute of Medicine',
    value: 'University of Seychelles - American Institute of Medicine',
  },
  { label: 'University of Sharjah', value: 'University of Sharjah' },
  { label: 'University of Sheffield', value: 'University of Sheffield' },
  { label: 'University of Shiga Prefecture', value: 'University of Shiga Prefecture' },
  { label: 'University of Shkodra Luigj Gurakuqi', value: 'University of Shkodra Luigj Gurakuqi' },
  { label: 'University of Sibiu', value: 'University of Sibiu' },
  { label: 'University of Siena', value: 'University of Siena' },
  { label: 'University of Sierra Leone', value: 'University of Sierra Leone' },
  { label: 'University of Silesia', value: 'University of Silesia' },
  { label: 'University of Silvaner', value: 'University of Silvaner' },
  { label: 'University of Sindh', value: 'University of Sindh' },
  {
    label: 'University of Sint Eustatius School of Medicine',
    value: 'University of Sint Eustatius School of Medicine',
  },
  { label: 'University of Sioux Falls', value: 'University of Sioux Falls' },
  {
    label: 'University of Social Welfare and Rehabilitation Scinences',
    value: 'University of Social Welfare and Rehabilitation Scinences',
  },
  { label: 'University of Somalia', value: 'University of Somalia' },
  { label: 'University of Sopron', value: 'University of Sopron' },
  { label: 'University of South Africa', value: 'University of South Africa' },
  { label: 'University of South Alabama', value: 'University of South Alabama' },
  { label: 'University of South Asia', value: 'University of South Asia' },
  { label: 'University of South Australia', value: 'University of South Australia' },
  { label: 'University of South Bohemia', value: 'University of South Bohemia' },
  { label: 'University of South Carolina', value: 'University of South Carolina' },
  { label: 'University of South Carolina - Aiken', value: 'University of South Carolina - Aiken' },
  { label: 'University of South Carolina - Beaufort', value: 'University of South Carolina - Beaufort' },
  { label: 'University of South Carolina - Lancaster', value: 'University of South Carolina - Lancaster' },
  { label: 'University of South Carolina - Salkehatchie', value: 'University of South Carolina - Salkehatchie' },
  { label: 'University of South Carolina - Spartanburg', value: 'University of South Carolina - Spartanburg' },
  { label: 'University of South Carolina - Sumter', value: 'University of South Carolina - Sumter' },
  { label: 'University of South Carolina - Union', value: 'University of South Carolina - Union' },
  { label: 'University of South Dakota', value: 'University of South Dakota' },
  { label: 'University of South Florida', value: 'University of South Florida' },
  { label: 'University of Southampton', value: 'University of Southampton' },
  { label: 'University of Southeastern Philippines', value: 'University of Southeastern Philippines' },
  { label: 'University of Southern California', value: 'University of Southern California' },
  { label: 'University of Southern Denmark', value: 'University of Southern Denmark' },
  {
    label: 'University of Southern Europe (Monaco Business School)',
    value: 'University of Southern Europe (Monaco Business School)',
  },
  { label: 'University of Southern Indiana', value: 'University of Southern Indiana' },
  { label: 'University of Southern Maine', value: 'University of Southern Maine' },
  { label: 'University of Southern Mindanao', value: 'University of Southern Mindanao' },
  { label: 'University of Southern Mississippi', value: 'University of Southern Mississippi' },
  { label: 'University of Southern Philippines Foundation', value: 'University of Southern Philippines Foundation' },
  { label: 'University of Southern Queensland', value: 'University of Southern Queensland' },
  { label: 'University of Split', value: 'University of Split' },
  { label: 'University of Sri Jayawardenapura', value: 'University of Sri Jayawardenapura' },
  { label: 'University of St. Andrews', value: 'University of St. Andrews' },
  {
    label: 'University of St. Cyril and Methodius in Trnava',
    value: 'University of St. Cyril and Methodius in Trnava',
  },
  { label: 'University of St. Francis', value: 'University of St. Francis' },
  { label: 'University of St. Gallen', value: 'University of St. Gallen' },
  { label: "University of St. Jerome's College", value: "University of St. Jerome's College" },
  { label: "University of St. Michael's College", value: "University of St. Michael's College" },
  { label: 'University of St. Thomas, Houston', value: 'University of St. Thomas, Houston' },
  { label: 'University of St. Thomas, St. Paul', value: 'University of St. Thomas, St. Paul' },
  { label: 'University of Stavanger', value: 'University of Stavanger' },
  { label: 'University of Stellenbosch', value: 'University of Stellenbosch' },
  { label: 'University of Stirling', value: 'University of Stirling' },
  { label: 'University of Strathclyde', value: 'University of Strathclyde' },
  { label: 'University of Suceava', value: 'University of Suceava' },
  { label: 'University of Sudbury', value: 'University of Sudbury' },
  { label: 'University of Sulaimania (Kurdistan Region)', value: 'University of Sulaimania (Kurdistan Region)' },
  { label: 'University of Sunderland', value: 'University of Sunderland' },
  { label: 'University of Surrey', value: 'University of Surrey' },
  { label: 'University of Sussex', value: 'University of Sussex' },
  { label: 'University of SV Cyril and Methodius in Trnava', value: 'University of SV Cyril and Methodius in Trnava' },
  { label: 'University of Swaziland', value: 'University of Swaziland' },
  { label: 'University of Switzerland', value: 'University of Switzerland' },
  { label: 'University of Sydney', value: 'University of Sydney' },
  { label: 'University of Szczecin', value: 'University of Szczecin' },
  { label: 'University of Szeged', value: 'University of Szeged' },
  { label: 'University of Tabuk', value: 'University of Tabuk' },
  { label: 'University of Tampa', value: 'University of Tampa' },
  { label: 'University of Tampere', value: 'University of Tampere' },
  { label: 'University of Targu Jiu', value: 'University of Targu Jiu' },
  { label: 'University of Tartu', value: 'University of Tartu' },
  { label: 'University of Tasmania', value: 'University of Tasmania' },
  {
    label: 'University of Technical Education Ho Chi Minh City',
    value: 'University of Technical Education Ho Chi Minh City',
  },
  { label: 'University of Technology', value: 'University of Technology' },
  { label: 'University of Technology and Life Sciences', value: 'University of Technology and Life Sciences' },
  { label: 'University of Technology Jamaica', value: 'University of Technology Jamaica' },
  { label: 'University of Technology Phnom Penh', value: 'University of Technology Phnom Penh' },
  { label: 'University of Technology Sydney', value: 'University of Technology Sydney' },
  { label: 'University of Teesside', value: 'University of Teesside' },
  { label: 'University of Tehran', value: 'University of Tehran' },
  { label: 'University of Tennessee - Chattanooga', value: 'University of Tennessee - Chattanooga' },
  { label: 'University of Tennessee - Knoxville', value: 'University of Tennessee - Knoxville' },
  { label: 'University of Tennessee - Martin', value: 'University of Tennessee - Martin' },
  { label: 'University of Tennessee - Memphis', value: 'University of Tennessee - Memphis' },
  { label: 'University of Tennessee Space Institute', value: 'University of Tennessee Space Institute' },
  { label: 'University of Teramo', value: 'University of Teramo' },
  { label: 'University of Texas', value: 'University of Texas' },
  { label: 'University of Texas at Arlington', value: 'University of Texas at Arlington' },
  { label: 'University of Texas at Austin', value: 'University of Texas at Austin' },
  { label: 'University of Texas at Brownsville', value: 'University of Texas at Brownsville' },
  { label: 'University of Texas at Dallas', value: 'University of Texas at Dallas' },
  { label: 'University of Texas at El Paso', value: 'University of Texas at El Paso' },
  { label: 'University of Texas at San Antonio', value: 'University of Texas at San Antonio' },
  { label: 'University of Texas at Tyler', value: 'University of Texas at Tyler' },
  { label: 'University of Texas Health Center at Houston', value: 'University of Texas Health Center at Houston' },
  { label: 'University of Texas Health Center at Tyler', value: 'University of Texas Health Center at Tyler' },
  {
    label: 'University of Texas Health Science Center at San Antonio',
    value: 'University of Texas Health Science Center at San Antonio',
  },
  {
    label: 'University of Texas M.D. Anderson Cancer Center',
    value: 'University of Texas M.D. Anderson Cancer Center',
  },
  { label: 'University of Texas Medical Branch Galveston', value: 'University of Texas Medical Branch Galveston' },
  { label: 'University of Texas of the Permian Basin', value: 'University of Texas of the Permian Basin' },
  { label: 'University of Texas Pan American', value: 'University of Texas Pan American' },
  {
    label: 'University of Texas Southwestern Medical Center at Dallas',
    value: 'University of Texas Southwestern Medical Center at Dallas',
  },
  { label: 'University of the Air', value: 'University of the Air' },
  { label: 'University of the Arts', value: 'University of the Arts' },
  { label: 'University of the Assumption', value: 'University of the Assumption' },
  { label: 'University of the City of Manila', value: 'University of the City of Manila' },
  { label: 'University of the Cordilleras', value: 'University of the Cordilleras' },
  { label: 'University of the District of Columbia', value: 'University of the District of Columbia' },
  { label: 'University of the East, Coloocan', value: 'University of the East, Coloocan' },
  { label: 'University of the East, Manila', value: 'University of the East, Manila' },
  {
    label: 'University of the East, Ramon Magsaysay Memorial Medical Center',
    value: 'University of the East, Ramon Magsaysay Memorial Medical Center',
  },
  { label: 'University of the Faroe Islands', value: 'University of the Faroe Islands' },
  { label: 'University of the Free State', value: 'University of the Free State' },
  { label: 'University of the Gambia', value: 'University of the Gambia' },
  { label: 'University of the Humanities', value: 'University of the Humanities' },
  { label: 'University of the Immaculate Conception', value: 'University of the Immaculate Conception' },
  { label: 'University of the Incarnate World', value: 'University of the Incarnate World' },
  {
    label: 'University of the Italian-speaking Part of Switzerland',
    value: 'University of the Italian-speaking Part of Switzerland',
  },
  {
    label: 'University of the Netherlands Antilles, Curacao',
    value: 'University of the Netherlands Antilles, Curacao',
  },
  { label: 'University of the Ozarks', value: 'University of the Ozarks' },
  { label: 'University of the Pacific', value: 'University of the Pacific' },
  { label: 'University of the Philippines Baguio', value: 'University of the Philippines Baguio' },
  { label: 'University of the Philippines Diliman', value: 'University of the Philippines Diliman' },
  { label: 'University of the Philippines Los Banos', value: 'University of the Philippines Los Banos' },
  { label: 'University of the Philippines Manila', value: 'University of the Philippines Manila' },
  { label: 'University of the Philippines Mindanao', value: 'University of the Philippines Mindanao' },
  { label: 'University of the Philippines Open University', value: 'University of the Philippines Open University' },
  { label: 'University of the Philippines Visayas', value: 'University of the Philippines Visayas' },
  {
    label: 'University of the Philippines Visayas - Cebu High School',
    value: 'University of the Philippines Visayas - Cebu High School',
  },
  { label: 'University of the Punjab', value: 'University of the Punjab' },
  { label: 'University of the Punjab, Gujranwala Campus', value: 'University of the Punjab, Gujranwala Campus' },
  { label: 'University of the Ryukyus', value: 'University of the Ryukyus' },
  { label: 'University of the Sacred Heart Tokyo', value: 'University of the Sacred Heart Tokyo' },
  { label: 'University of the Sciences in Philadelphia', value: 'University of the Sciences in Philadelphia' },
  { label: 'University of the South', value: 'University of the South' },
  { label: 'University of the South Pacific', value: 'University of the South Pacific' },
  { label: 'University of the South Pacific', value: 'University of the South Pacific' },
  { label: 'University of the South Pacific Centre', value: 'University of the South Pacific Centre' },
  {
    label: 'University of the South Pacific School of Agriculture',
    value: 'University of the South Pacific School of Agriculture',
  },
  { label: 'University of the Southern Caribbean', value: 'University of the Southern Caribbean' },
  { label: 'University of the Southwest', value: 'University of the Southwest' },
  { label: 'University of the Sunshine Coast', value: 'University of the Sunshine Coast' },
  { label: 'University of the Thai Chamber of Commerce', value: 'University of the Thai Chamber of Commerce' },
  { label: 'University of the Virgin Islands', value: 'University of the Virgin Islands' },
  { label: 'University of the Visayas', value: 'University of the Visayas' },
  { label: 'University of the Visual & Performing Arts', value: 'University of the Visual & Performing Arts' },
  { label: 'University of the West Indies St. Augustine', value: 'University of the West Indies St. Augustine' },
  { label: 'University of the West Indies, Cave Hill', value: 'University of the West Indies, Cave Hill' },
  { label: 'University of the West Indies, Mona', value: 'University of the West Indies, Mona' },
  { label: 'University of the West of England, Bristol', value: 'University of the West of England, Bristol' },
  { label: 'University of the Western Cape', value: 'University of the Western Cape' },
  { label: 'University of Thessaly', value: 'University of Thessaly' },
  { label: 'University of Tirana', value: 'University of Tirana' },
  { label: 'University of Toledo', value: 'University of Toledo' },
  { label: 'University of Toronto', value: 'University of Toronto' },
  { label: 'University of Toronto, Mississauga', value: 'University of Toronto, Mississauga' },
  { label: 'University of Toronto, Scarborough', value: 'University of Toronto, Scarborough' },
  { label: 'University of Transport and Communications', value: 'University of Transport and Communications' },
  { label: 'University of Trento', value: 'University of Trento' },
  { label: 'University of Trieste', value: 'University of Trieste' },
  { label: 'University of Trinidad and Tobago', value: 'University of Trinidad and Tobago' },
  { label: 'University of Trinity College', value: 'University of Trinity College' },
  { label: 'University of Tripoli', value: 'University of Tripoli' },
  { label: 'University of Trnava', value: 'University of Trnava' },
  { label: 'University of Tromsø', value: 'University of Tromsø' },
  { label: 'University of Tulsa', value: 'University of Tulsa' },
  { label: 'University of Turin', value: 'University of Turin' },
  { label: 'University of Turku', value: 'University of Turku' },
  { label: 'University of Tuzla', value: 'University of Tuzla' },
  { label: 'University of Twente', value: 'University of Twente' },
  { label: 'University of Udine', value: 'University of Udine' },
  { label: 'University of Ulster', value: 'University of Ulster' },
  { label: 'University of Urbino', value: 'University of Urbino' },
  { label: 'University of Utah', value: 'University of Utah' },
  { label: 'University of Uyo', value: 'University of Uyo' },
  { label: 'University of Vaasa', value: 'University of Vaasa' },
  { label: 'University of Venda', value: 'University of Venda' },
  { label: 'University of Venice', value: 'University of Venice' },
  { label: 'University of Vermont', value: 'University of Vermont' },
  { label: 'University of Verona', value: 'University of Verona' },
  { label: 'University of Veterinary & Animal Science', value: 'University of Veterinary & Animal Science' },
  {
    label: 'University of Veterinary and Pharmaceutical Science',
    value: 'University of Veterinary and Pharmaceutical Science',
  },
  { label: 'University of Veterinary Medicine in Kosice', value: 'University of Veterinary Medicine in Kosice' },
  { label: 'University of Veterinary Science', value: 'University of Veterinary Science' },
  { label: 'University of Victoria', value: 'University of Victoria' },
  { label: 'University of Virginia', value: 'University of Virginia' },
  { label: 'University of Virginia, College at Wise', value: 'University of Virginia, College at Wise' },
  { label: 'University of Vlora Ismail Qemali', value: 'University of Vlora Ismail Qemali' },
  { label: 'University of Wah', value: 'University of Wah' },
  { label: 'University of Waikato', value: 'University of Waikato' },
  { label: 'University of Wales', value: 'University of Wales' },
  { label: 'University of Wales College of Medicine', value: 'University of Wales College of Medicine' },
  { label: 'University of Wales Institute, Cardiff', value: 'University of Wales Institute, Cardiff' },
  { label: 'University of Wales, Aberystwyth', value: 'University of Wales, Aberystwyth' },
  { label: 'University of Wales, Bangor', value: 'University of Wales, Bangor' },
  { label: 'University of Wales, Lampeter', value: 'University of Wales, Lampeter' },
  { label: 'University of Wales, Newport', value: 'University of Wales, Newport' },
  { label: 'University of Wales, Swansea', value: 'University of Wales, Swansea' },
  { label: 'University of Wamia and Masuria in Olsztyn', value: 'University of Wamia and Masuria in Olsztyn' },
  { label: 'University of Warsaw', value: 'University of Warsaw' },
  { label: 'University of Warwick', value: 'University of Warwick' },
  { label: 'University of Washington', value: 'University of Washington' },
  { label: 'University of Washington, Tacoma', value: 'University of Washington, Tacoma' },
  { label: 'University of Wassit', value: 'University of Wassit' },
  { label: 'University of Waterloo', value: 'University of Waterloo' },
  { label: 'University of West Alabama', value: 'University of West Alabama' },
  { label: 'University of West Bohemia', value: 'University of West Bohemia' },
  { label: 'University of West Florida', value: 'University of West Florida' },
  { label: 'University of West Hungary', value: 'University of West Hungary' },
  { label: 'University of West Los Angeles', value: 'University of West Los Angeles' },
  { label: 'University of Western Australia', value: 'University of Western Australia' },
  { label: 'University of Western Macedonia', value: 'University of Western Macedonia' },
  { label: 'University of Western Ontario', value: 'University of Western Ontario' },
  { label: 'University of Western Sydney', value: 'University of Western Sydney' },
  { label: 'University of Westminster', value: 'University of Westminster' },
  { label: 'University of Windsor', value: 'University of Windsor' },
  { label: 'University of Winnipeg', value: 'University of Winnipeg' },
  { label: 'University of Wisconsin - Eau Claire', value: 'University of Wisconsin - Eau Claire' },
  { label: 'University of Wisconsin - Green Bay', value: 'University of Wisconsin - Green Bay' },
  { label: 'University of Wisconsin - La Crosse', value: 'University of Wisconsin - La Crosse' },
  { label: 'University of Wisconsin - Madison', value: 'University of Wisconsin - Madison' },
  { label: 'University of Wisconsin - Milwaukee', value: 'University of Wisconsin - Milwaukee' },
  { label: 'University of Wisconsin - Oshkosh', value: 'University of Wisconsin - Oshkosh' },
  { label: 'University of Wisconsin - Parkside', value: 'University of Wisconsin - Parkside' },
  { label: 'University of Wisconsin - Platteville', value: 'University of Wisconsin - Platteville' },
  { label: 'University of Wisconsin - River Falls', value: 'University of Wisconsin - River Falls' },
  { label: 'University of Wisconsin - Stevens Point', value: 'University of Wisconsin - Stevens Point' },
  { label: 'University of Wisconsin - Stout', value: 'University of Wisconsin - Stout' },
  { label: 'University of Wisconsin - Superior', value: 'University of Wisconsin - Superior' },
  { label: 'University of Wisconsin - Whitewater', value: 'University of Wisconsin - Whitewater' },
  { label: 'University of Witwatersrand', value: 'University of Witwatersrand' },
  { label: 'University of Wollongong', value: 'University of Wollongong' },
  { label: 'University of Wollongong - Dubai Campus', value: 'University of Wollongong - Dubai Campus' },
  { label: 'University of Wolverhampton', value: 'University of Wolverhampton' },
  { label: 'University of Worcester', value: 'University of Worcester' },
  { label: 'University of World Economy and Diplomacy', value: 'University of World Economy and Diplomacy' },
  { label: 'University of Wroclaw', value: 'University of Wroclaw' },
  { label: 'University of Wyoming', value: 'University of Wyoming' },
  { label: 'University of York', value: 'University of York' },
  { label: 'University of Zadar', value: 'University of Zadar' },
  { label: 'University of Zagreb', value: 'University of Zagreb' },
  { label: 'University of Zambia', value: 'University of Zambia' },
  { label: 'University of Zenica', value: 'University of Zenica' },
  { label: 'University of Zilinska', value: 'University of Zilinska' },
  { label: 'University of Zimbabwe', value: 'University of Zimbabwe' },
  { label: 'University of Zululand', value: 'University of Zululand' },
  { label: 'University of Zürich', value: 'University of Zürich' },
  { label: 'University Oil- Gas Ploiesti', value: 'University Oil- Gas Ploiesti' },
  { label: 'University Petre Andrei Iasi', value: 'University Petre Andrei Iasi' },
  { label: 'University Politehnica of Bucharest', value: 'University Politehnica of Bucharest' },
  { label: 'University School of Physical Education', value: 'University School of Physical Education' },
  { label: 'University Titu Maiorescu', value: 'University Titu Maiorescu' },
  { label: 'University Transilvany of Brasov', value: 'University Transilvany of Brasov' },
  { label: 'University Tun Abdul Razak', value: 'University Tun Abdul Razak' },
  { label: 'University Vitez In Travnik', value: 'University Vitez In Travnik' },
  { label: 'Univerzitet u Mostaru Dzemal Bijedic', value: 'Univerzitet u Mostaru Dzemal Bijedic' },
  { label: 'Univesidade Agostinho Neto', value: 'Univesidade Agostinho Neto' },
  { label: 'Upper Iowa University', value: 'Upper Iowa University' },
  { label: 'Upper Nile University', value: 'Upper Nile University' },
  { label: 'Uppsala University', value: 'Uppsala University' },
  { label: 'Ural Academy of Public Administration', value: 'Ural Academy of Public Administration' },
  { label: 'Ural Gorkij State University', value: 'Ural Gorkij State University' },
  { label: 'Ural State Academy of Architecture and Arts', value: 'Ural State Academy of Architecture and Arts' },
  { label: 'Ural State Academy of Law', value: 'Ural State Academy of Law' },
  { label: 'Ural State Academy of Mining and Geology', value: 'Ural State Academy of Mining and Geology' },
  { label: 'Ural State Academy of Railway Transport', value: 'Ural State Academy of Railway Transport' },
  { label: 'Ural State Conservatory', value: 'Ural State Conservatory' },
  { label: 'Ural State Forestry Technical Academy', value: 'Ural State Forestry Technical Academy' },
  { label: 'Ural State Technical University', value: 'Ural State Technical University' },
  { label: 'Ural State University of Economics', value: 'Ural State University of Economics' },
  { label: 'Urbana University', value: 'Urbana University' },
  { label: 'Urmia University', value: 'Urmia University' },
  { label: 'Urmia University of Medical Sciences', value: 'Urmia University of Medical Sciences' },
  { label: 'Urmia University of Technology', value: 'Urmia University of Technology' },
  { label: 'Ursinus College', value: 'Ursinus College' },
  { label: 'Ursuline College', value: 'Ursuline College' },
  { label: 'Urumqi Vocational University', value: 'Urumqi Vocational University' },
  { label: 'Usmanu Danfodiyo University Sokoto', value: 'Usmanu Danfodiyo University Sokoto' },
  { label: 'Utah State University', value: 'Utah State University' },
  { label: 'Utah Valley State College', value: 'Utah Valley State College' },
  { label: 'Utica College', value: 'Utica College' },
  { label: 'Utkal University', value: 'Utkal University' },
  { label: 'Utrecht University', value: 'Utrecht University' },
  { label: 'Utsunomiya University', value: 'Utsunomiya University' },
  { label: 'Uttar Pradesh Technical University', value: 'Uttar Pradesh Technical University' },
  { label: 'Uttarakhand Open University ', value: 'Uttarakhand Open University ' },
  { label: 'Uttarakhand Technical University', value: 'Uttarakhand Technical University' },
  { label: 'Uva Wellassa University', value: 'Uva Wellassa University' },
  { label: 'Uzbek State World Languages University', value: 'Uzbek State World Languages University' },
  { label: 'Uzhgorod National University', value: 'Uzhgorod National University' },
  { label: 'Uzima University College ', value: 'Uzima University College ' },
  { label: 'Vaal University of Technology', value: 'Vaal University of Technology' },
  { label: 'Vaasa University of Applied Sciences', value: 'Vaasa University of Applied Sciences' },
  { label: 'Vaganova Academy of Russian Ballet', value: 'Vaganova Academy of Russian Ballet' },
  { label: 'Valahia University of Targoviste', value: 'Valahia University of Targoviste' },
  { label: 'Valdosta State University', value: 'Valdosta State University' },
  { label: 'Vali-e-Asr University', value: 'Vali-e-Asr University' },
  { label: 'Valley City State University', value: 'Valley City State University' },
  { label: 'Valley Forge Christian College', value: 'Valley Forge Christian College' },
  { label: 'Valley View University', value: 'Valley View University' },
  { label: 'Valparaiso University', value: 'Valparaiso University' },
  { label: 'Vancouver Community College', value: 'Vancouver Community College' },
  { label: 'Vanderbilt University', value: 'Vanderbilt University' },
  { label: 'VanderCook College of Music', value: 'VanderCook College of Music' },
  { label: 'Vanguard University of Southern California', value: 'Vanguard University of Southern California' },
  { label: 'Vardhaman Mahaveer Open University', value: 'Vardhaman Mahaveer Open University' },
  { label: 'Varna Free University', value: 'Varna Free University' },
  { label: 'Vassar College', value: 'Vassar College' },
  { label: 'Växjö University', value: 'Växjö University' },
  { label: 'VBS Purvanchal University', value: 'VBS Purvanchal University' },
  { label: 'Veer Kunwar Singh University', value: 'Veer Kunwar Singh University' },
  { label: 'Veer Surendra Sai University of Technology', value: 'Veer Surendra Sai University of Technology' },
  { label: 'Veliko Turnovo University Cyril and Methodius', value: 'Veliko Turnovo University Cyril and Methodius' },
  { label: 'Vellore Institute of Technology', value: 'Vellore Institute of Technology' },
  { label: 'Vennard College', value: 'Vennard College' },
  { label: 'Veritas University', value: 'Veritas University' },
  { label: 'Vermont Law School', value: 'Vermont Law School' },
  { label: 'Vermont Technical College', value: 'Vermont Technical College' },
  { label: 'Vesalius College', value: 'Vesalius College' },
  { label: 'Veterinärmedizinische Universität Wien', value: 'Veterinärmedizinische Universität Wien' },
  { label: 'Victoria International University', value: 'Victoria International University' },
  { label: 'Victoria University', value: 'Victoria University' },
  { label: 'Victoria University of Wellington', value: 'Victoria University of Wellington' },
  {
    label: 'Victoria University Toronto, University of Toronto',
    value: 'Victoria University Toronto, University of Toronto',
  },
  { label: 'Vidyasagar University', value: 'Vidyasagar University' },
  { label: 'Vidzeme University College', value: 'Vidzeme University College' },
  { label: 'Vietnam Maritime University', value: 'Vietnam Maritime University' },
  { label: 'Vietnam National University Hanoi', value: 'Vietnam National University Hanoi' },
  { label: 'Vietnam National University Ho Chi Minh City', value: 'Vietnam National University Ho Chi Minh City' },
  { label: 'Vietnam National University of Agriculture', value: 'Vietnam National University of Agriculture' },
  { label: 'Vignan University', value: 'Vignan University' },
  { label: 'Vikram University', value: 'Vikram University' },
  { label: 'Vikrama Simhapuri University', value: 'Vikrama Simhapuri University' },
  { label: 'Villa Julie College', value: 'Villa Julie College' },
  { label: 'Villanova University', value: 'Villanova University' },
  { label: 'Vilnius Academy of Arts', value: 'Vilnius Academy of Arts' },
  { label: 'Vilnius Gediminas Technical University', value: 'Vilnius Gediminas Technical University' },
  { label: 'Vilnius Pedagogical University', value: 'Vilnius Pedagogical University' },
  { label: 'Vilnius University', value: 'Vilnius University' },
  {
    label: "Vinayaka Mission's Research Foundation-Deemed University",
    value: "Vinayaka Mission's Research Foundation-Deemed University",
  },
  { label: 'Vinnica National Technical University', value: 'Vinnica National Technical University' },
  { label: 'Vinoba Bhave University', value: 'Vinoba Bhave University' },
  { label: 'Virgen Milagrosa University Foundation', value: 'Virgen Milagrosa University Foundation' },
  { label: 'Virginia College', value: 'Virginia College' },
  { label: 'Virginia Commonwealth University', value: 'Virginia Commonwealth University' },
  { label: 'Virginia Intermont College', value: 'Virginia Intermont College' },
  { label: 'Virginia International University', value: 'Virginia International University' },
  { label: 'Virginia Military Institute', value: 'Virginia Military Institute' },
  {
    label: 'Virginia Polytechnic Institute and State University (Virginia Tech)',
    value: 'Virginia Polytechnic Institute and State University (Virginia Tech)',
  },
  { label: 'Virginia State University', value: 'Virginia State University' },
  { label: 'Virginia Union University', value: 'Virginia Union University' },
  { label: 'Virginia Wesleyan College', value: 'Virginia Wesleyan College' },
  { label: 'Virtual University of Pakistan', value: 'Virtual University of Pakistan' },
  { label: 'Visva-Bharati University', value: 'Visva-Bharati University' },
  { label: 'Visvesvaraya National Institute of Technology', value: 'Visvesvaraya National Institute of Technology' },
  { label: 'Visvesvaraya Technological University', value: 'Visvesvaraya Technological University' },
  { label: 'Vitebsk State Academy of Veterinary Medicine', value: 'Vitebsk State Academy of Veterinary Medicine' },
  { label: 'Vitebsk State Medical University', value: 'Vitebsk State Medical University' },
  { label: 'Vitebsk State Technological University', value: 'Vitebsk State Technological University' },
  { label: 'Vitebsk State University', value: 'Vitebsk State University' },
  { label: 'Viterbo College', value: 'Viterbo College' },
  { label: 'Viterbo State University', value: 'Viterbo State University' },
  { label: 'Vladimir State University', value: 'Vladimir State University' },
  { label: 'Vladivostock State University of Economics', value: 'Vladivostock State University of Economics' },
  { label: 'Vlerick Leuven Gent Management School', value: 'Vlerick Leuven Gent Management School' },
  { label: 'Volga State Academy of Water Transport', value: 'Volga State Academy of Water Transport' },
  { label: 'Volgograd Medical Academy', value: 'Volgograd Medical Academy' },
  { label: 'Volgograd State Academy of Physical Education', value: 'Volgograd State Academy of Physical Education' },
  { label: 'Volgograd State Pedagogical University', value: 'Volgograd State Pedagogical University' },
  { label: 'Volgograd State Technical University', value: 'Volgograd State Technical University' },
  { label: 'Volgograd State University', value: 'Volgograd State University' },
  {
    label: 'Volgograd State University of Architecture and Civil Engineering (VolgGASU)',
    value: 'Volgograd State University of Architecture and Civil Engineering (VolgGASU)',
  },
  { label: 'Vologda State Pedagogical University', value: 'Vologda State Pedagogical University' },
  { label: 'Volyn National University Lesja Ukrainka', value: 'Volyn National University Lesja Ukrainka' },
  { label: 'Vongchavalitkul University', value: 'Vongchavalitkul University' },
  { label: 'Voorhees College', value: 'Voorhees College' },
  { label: 'Voronezh State Academy of Technology', value: 'Voronezh State Academy of Technology' },
  { label: 'Voronezh State Agricultural University', value: 'Voronezh State Agricultural University' },
  { label: 'Voronezh State Medical Academy', value: 'Voronezh State Medical Academy' },
  { label: 'Voronezh State Pedagogical University', value: 'Voronezh State Pedagogical University' },
  { label: 'Voronezh State Technical University', value: 'Voronezh State Technical University' },
  { label: 'Voronezh State University', value: 'Voronezh State University' },
  { label: 'Vrije Universiteit Brussel', value: 'Vrije Universiteit Brussel' },
  { label: 'Vyatka State Pedagogical University', value: 'Vyatka State Pedagogical University' },
  { label: 'Vytautas Magnus University', value: 'Vytautas Magnus University' },
  { label: 'Wabash College', value: 'Wabash College' },
  { label: 'Wadi International University', value: 'Wadi International University' },
  { label: 'Wageningen University', value: 'Wageningen University' },
  { label: 'Wagner College', value: 'Wagner College' },
  { label: 'Wah Medical College', value: 'Wah Medical College' },
  { label: 'Wakayama Medical College', value: 'Wakayama Medical College' },
  { label: 'Wakayama University', value: 'Wakayama University' },
  { label: 'Wake Forest University', value: 'Wake Forest University' },
  { label: 'Wakkanai Hokusei Gakuen University', value: 'Wakkanai Hokusei Gakuen University' },
  { label: 'Wako University', value: 'Wako University' },
  { label: 'Walailak University', value: 'Walailak University' },
  { label: 'Walcz College', value: 'Walcz College' },
  { label: 'Walden University', value: 'Walden University' },
  { label: 'Walla Walla College', value: 'Walla Walla College' },
  {
    label: 'Walsh College of Accountancy and Business Administration',
    value: 'Walsh College of Accountancy and Business Administration',
  },
  { label: 'Walsh University', value: 'Walsh University' },
  {
    label: 'Walter Sisulu University for Technology and Science',
    value: 'Walter Sisulu University for Technology and Science',
  },
  { label: 'Warburg Institute, University of London', value: 'Warburg Institute, University of London' },
  { label: 'Warnborough University', value: 'Warnborough University' },
  { label: 'Warnborough University', value: 'Warnborough University' },
  { label: 'Warner Pacific College', value: 'Warner Pacific College' },
  { label: 'Warner Southern College', value: 'Warner Southern College' },
  { label: 'Warren Wilson College', value: 'Warren Wilson College' },
  { label: 'Warsaw School of Economics', value: 'Warsaw School of Economics' },
  { label: 'Warsaw School of Information Technology', value: 'Warsaw School of Information Technology' },
  { label: 'Warsaw School of Social Psychology', value: 'Warsaw School of Social Psychology' },
  { label: 'Wartburg College', value: 'Wartburg College' },
  { label: 'Waseda University', value: 'Waseda University' },
  { label: 'Washburn University', value: 'Washburn University' },
  { label: 'Washington and Lee University', value: 'Washington and Lee University' },
  { label: 'Washington Bible College', value: 'Washington Bible College' },
  { label: 'Washington College', value: 'Washington College' },
  { label: 'Washington State University', value: 'Washington State University' },
  { label: 'Washington State University, Spokane', value: 'Washington State University, Spokane' },
  { label: 'Washington State University, Tri-Cities', value: 'Washington State University, Tri-Cities' },
  { label: 'Washington State University, Vancouver', value: 'Washington State University, Vancouver' },
  { label: 'Washington University in St. Louis', value: 'Washington University in St. Louis' },
  { label: 'Water Resources University', value: 'Water Resources University' },
  { label: 'Waterford Institute Of Technology', value: 'Waterford Institute Of Technology' },
  { label: 'Wawasan Open University', value: 'Wawasan Open University' },
  { label: 'Wayamba University of Sri Lanka ', value: 'Wayamba University of Sri Lanka ' },
  { label: 'Wayland Baptist University', value: 'Wayland Baptist University' },
  { label: 'Wayne State College', value: 'Wayne State College' },
  { label: 'Wayne State University', value: 'Wayne State University' },
  { label: 'Waynesburg College', value: 'Waynesburg College' },
  { label: "Wayo Women's University", value: "Wayo Women's University" },
  { label: 'Webb Institute', value: 'Webb Institute' },
  { label: 'Webber College', value: 'Webber College' },
  { label: 'Weber State University', value: 'Weber State University' },
  { label: 'Webster University', value: 'Webster University' },
  { label: 'Webster University Geneva', value: 'Webster University Geneva' },
  { label: 'Webster University North Florida', value: 'Webster University North Florida' },
  { label: 'Webster University, Thailand', value: 'Webster University, Thailand' },
  { label: 'Webster University, Vienna', value: 'Webster University, Vienna' },
  { label: 'Weill Medical College of Cornell University', value: 'Weill Medical College of Cornell University' },
  { label: 'Weizmann Institute of Science', value: 'Weizmann Institute of Science' },
  { label: 'Wellesley College', value: 'Wellesley College' },
  { label: 'Wells College', value: 'Wells College' },
  { label: 'Wellspring University', value: 'Wellspring University' },
  { label: 'Wentworth Institute of Technology', value: 'Wentworth Institute of Technology' },
  { label: 'Wenzhou Medical College', value: 'Wenzhou Medical College' },
  { label: 'Wenzhou University', value: 'Wenzhou University' },
  { label: 'Wesley College', value: 'Wesley College' },
  { label: 'Wesley College Mississippi', value: 'Wesley College Mississippi' },
  { label: 'Wesley University of Science and Technology', value: 'Wesley University of Science and Technology' },
  { label: 'Wesleyan College', value: 'Wesleyan College' },
  { label: 'Wesleyan University', value: 'Wesleyan University' },
  { label: 'Wesleyan University Philippines', value: 'Wesleyan University Philippines' },
  {
    label: 'West Bengal University of Animal and Fishery Sciences',
    value: 'West Bengal University of Animal and Fishery Sciences',
  },
  { label: 'West Bengal University of Technology', value: 'West Bengal University of Technology' },
  { label: 'West Chester University of Pennsylvania', value: 'West Chester University of Pennsylvania' },
  { label: 'West China University of Medical Sciences', value: 'West China University of Medical Sciences' },
  { label: 'West Coast University', value: 'West Coast University' },
  { label: 'West Coast University (WCU)', value: 'West Coast University (WCU)' },
  { label: 'West Coast University (WCU) ', value: 'West Coast University (WCU) ' },
  { label: 'West Liberty State College', value: 'West Liberty State College' },
  { label: 'West Minster International College', value: 'West Minster International College' },
  { label: 'West Pomeranian Business School in Szczecin', value: 'West Pomeranian Business School in Szczecin' },
  { label: 'West Suburban College of Nursing', value: 'West Suburban College of Nursing' },
  { label: 'West Texas A&M University', value: 'West Texas A&M University' },
  { label: 'West University of Timisoara', value: 'West University of Timisoara' },
  { label: 'West Virginia School of Osteopathic Medicine', value: 'West Virginia School of Osteopathic Medicine' },
  { label: 'West Virginia State College', value: 'West Virginia State College' },
  { label: 'West Virginia University', value: 'West Virginia University' },
  {
    label: 'West Virginia University Institute of Technology',
    value: 'West Virginia University Institute of Technology',
  },
  { label: 'West Virginia Wesleyan College', value: 'West Virginia Wesleyan College' },
  { label: 'West Visayas State University', value: 'West Visayas State University' },
  { label: 'Westbrook University ', value: 'Westbrook University ' },
  { label: 'Western Baptist College', value: 'Western Baptist College' },
  { label: 'Western Bible College', value: 'Western Bible College' },
  { label: 'Western Carolina University', value: 'Western Carolina University' },
  { label: 'Western Connecticut State University', value: 'Western Connecticut State University' },
  { label: 'Western Delta University', value: 'Western Delta University' },
  { label: 'Western Galilee College', value: 'Western Galilee College' },
  { label: 'Western Governors University', value: 'Western Governors University' },
  { label: 'Western Illinois University', value: 'Western Illinois University' },
  { label: 'Western International University', value: 'Western International University' },
  { label: 'Western Kazakhstan Agricultural University', value: 'Western Kazakhstan Agricultural University' },
  { label: 'Western Kentucky University', value: 'Western Kentucky University' },
  { label: 'Western Maryland College', value: 'Western Maryland College' },
  { label: 'Western Michigan University', value: 'Western Michigan University' },
  { label: 'Western Mindanao State University', value: 'Western Mindanao State University' },
  { label: 'Western New England College', value: 'Western New England College' },
  { label: 'Western New Mexico University', value: 'Western New Mexico University' },
  { label: 'Western Oregon University', value: 'Western Oregon University' },
  { label: 'Western State College', value: 'Western State College' },
  { label: 'Western State University College of Law', value: 'Western State University College of Law' },
  {
    label: 'Western State University College of Law - Orange County',
    value: 'Western State University College of Law - Orange County',
  },
  { label: 'Western States Chiropractic College', value: 'Western States Chiropractic College' },
  { label: 'Western University', value: 'Western University' },
  { label: 'Western University', value: 'Western University' },
  { label: 'Western Washington University', value: 'Western Washington University' },
  { label: 'Westfälische Wilhelms-Universität Münster', value: 'Westfälische Wilhelms-Universität Münster' },
  { label: 'Westfield State College', value: 'Westfield State College' },
  { label: 'Westhill University', value: 'Westhill University' },
  { label: 'Westminster College Fulton', value: 'Westminster College Fulton' },
  { label: 'Westminster College New Wilmington', value: 'Westminster College New Wilmington' },
  { label: 'Westminster College of Salt Lake City', value: 'Westminster College of Salt Lake City' },
  {
    label: 'Westminster International University in Tashkent',
    value: 'Westminster International University in Tashkent',
  },
  { label: 'Westmont College', value: 'Westmont College' },
  { label: 'Westsächsische Hochschule Zwickau (FH)', value: 'Westsächsische Hochschule Zwickau (FH)' },
  { label: 'Westwood College', value: 'Westwood College' },
  { label: 'Wheaton College Massachusetts', value: 'Wheaton College Massachusetts' },
  { label: 'Wheeling Jesuit University', value: 'Wheeling Jesuit University' },
  { label: 'Wheelock College', value: 'Wheelock College' },
  { label: 'Whitman College', value: 'Whitman College' },
  { label: 'Whittier College', value: 'Whittier College' },
  { label: 'Whitworth College', value: 'Whitworth College' },
  { label: 'Wichita State University', value: 'Wichita State University' },
  { label: 'Widener University', value: 'Widener University' },
  { label: 'Wilberforce University', value: 'Wilberforce University' },
  { label: 'Wilbur Wright College', value: 'Wilbur Wright College' },
  { label: 'Wiley College', value: 'Wiley College' },
  { label: 'Wilfrid Laurier University', value: 'Wilfrid Laurier University' },
  { label: 'Wilkes University', value: 'Wilkes University' },
  { label: 'Willamette University', value: 'Willamette University' },
  { label: 'William and Catherine Booth College', value: 'William and Catherine Booth College' },
  { label: 'William Carey College', value: 'William Carey College' },
  { label: 'William Gilbert College', value: 'William Gilbert College' },
  { label: 'William Jewell College', value: 'William Jewell College' },
  { label: 'William Mitchell College of Law', value: 'William Mitchell College of Law' },
  { label: 'William Paterson University', value: 'William Paterson University' },
  { label: 'William Penn College', value: 'William Penn College' },
  { label: 'William Tyndale College', value: 'William Tyndale College' },
  { label: 'William Woods University', value: 'William Woods University' },
  { label: 'Williams Baptist College', value: 'Williams Baptist College' },
  { label: 'Williams College', value: 'Williams College' },
  { label: 'Wilmington College', value: 'Wilmington College' },
  { label: 'Wilmington College', value: 'Wilmington College' },
  { label: 'Wilson College', value: 'Wilson College' },
  { label: 'Wimbledon School of Art', value: 'Wimbledon School of Art' },
  { label: 'Windsor University School of Medicine', value: 'Windsor University School of Medicine' },
  { label: 'Wingate University', value: 'Wingate University' },
  { label: 'Winona State University', value: 'Winona State University' },
  { label: 'Winston-Salem State University', value: 'Winston-Salem State University' },
  { label: 'Winthrop University', value: 'Winthrop University' },
  { label: 'Wirtschaftsuniversität Wien', value: 'Wirtschaftsuniversität Wien' },
  { label: 'Wisconsin Lutheran College', value: 'Wisconsin Lutheran College' },
  { label: 'Wisconsin School of Professional Psychology', value: 'Wisconsin School of Professional Psychology' },
  {
    label: 'Wissenschaftliche Hochschule für Unternehmensführung, Otto-Beisheim Hochschule',
    value: 'Wissenschaftliche Hochschule für Unternehmensführung, Otto-Beisheim Hochschule',
  },
  { label: 'Wittenberg University', value: 'Wittenberg University' },
  { label: 'Wittenborg University', value: 'Wittenborg University' },
  { label: 'Wofford College', value: 'Wofford College' },
  { label: 'Wolaita Sodo University', value: 'Wolaita Sodo University' },
  { label: 'Wolkite University', value: 'Wolkite University' },
  { label: 'Wollega University', value: 'Wollega University' },
  { label: 'Wollo University', value: 'Wollo University' },
  { label: "Women's College of Fine Arts", value: "Women's College of Fine Arts" },
  { label: "Women's University in Africa", value: "Women's University in Africa" },
  { label: 'Wonkwang University', value: 'Wonkwang University' },
  { label: 'Woodbury University', value: 'Woodbury University' },
  { label: 'Woosuk University', value: 'Woosuk University' },
  { label: 'Worcester Polytechnic Institute', value: 'Worcester Polytechnic Institute' },
  { label: 'Worcester State College', value: 'Worcester State College' },
  { label: 'World Maritime University', value: 'World Maritime University' },
  { label: 'World University of Bangladesh', value: 'World University of Bangladesh' },
  { label: 'Wright Institute', value: 'Wright Institute' },
  { label: 'Wright State University', value: 'Wright State University' },
  { label: 'Wudanshan Taoist College ', value: 'Wudanshan Taoist College ' },
  { label: 'Wuhan Automobile Polytechnical University', value: 'Wuhan Automobile Polytechnical University' },
  {
    label: 'Wuhan Technical University of Surveying and Mapping',
    value: 'Wuhan Technical University of Surveying and Mapping',
  },
  { label: 'Wuhan Transportation University', value: 'Wuhan Transportation University' },
  { label: 'Wuhan University', value: 'Wuhan University' },
  {
    label: 'Wuhan University of Hydraulic and Electric Engineering',
    value: 'Wuhan University of Hydraulic and Electric Engineering',
  },
  { label: 'Wuhan University of Technology', value: 'Wuhan University of Technology' },
  { label: 'Wuhan University School of Medicine', value: 'Wuhan University School of Medicine' },
  { label: 'Wuxi University of Light Industry', value: 'Wuxi University of Light Industry' },
  { label: 'Wuyi University', value: 'Wuyi University' },
  { label: 'Xavier University', value: 'Xavier University' },
  { label: 'Xavier University', value: 'Xavier University' },
  { label: 'Xavier University of Louisiana', value: 'Xavier University of Louisiana' },
  { label: "Xi'an Academy of Fine Art", value: "Xi'an Academy of Fine Art" },
  { label: "Xi'an Highway University", value: "Xi'an Highway University" },
  { label: "Xi'an International Studies University", value: "Xi'an International Studies University" },
  { label: "Xi'an Jiaotong University", value: "Xi'an Jiaotong University" },
  { label: "Xi'an Jiaotong-Liverpool University", value: "Xi'an Jiaotong-Liverpool University" },
  {
    label: "Xi'an University of Architecture and Technology",
    value: "Xi'an University of Architecture and Technology",
  },
  {
    label: "Xi'an University of Electronic Science and Technology",
    value: "Xi'an University of Electronic Science and Technology",
  },
  { label: "Xi'an University of Technology", value: "Xi'an University of Technology" },
  { label: 'Xiamen University', value: 'Xiamen University' },
  { label: 'Xiangtan Normal University', value: 'Xiangtan Normal University' },
  { label: 'Xiangtan University', value: 'Xiangtan University' },
  { label: 'Xihua University', value: 'Xihua University' },
  { label: 'Xijiang University', value: 'Xijiang University' },
  { label: 'Xinghai Conservatory of Music', value: 'Xinghai Conservatory of Music' },
  { label: 'Xinjiang Agriculture University', value: 'Xinjiang Agriculture University' },
  { label: 'Xinjiang Normal University', value: 'Xinjiang Normal University' },
  { label: 'Xinjiang University', value: 'Xinjiang University' },
  { label: 'Xinjiang University of Finance and Economics', value: 'Xinjiang University of Finance and Economics' },
  { label: 'Xuzhou Normal University', value: 'Xuzhou Normal University' },
  { label: 'Yaba College of Technology', value: 'Yaba College of Technology' },
  { label: 'Yakutsk State University', value: 'Yakutsk State University' },
  { label: 'Yale University', value: 'Yale University' },
  { label: 'Yalova University', value: 'Yalova University' },
  { label: 'Yamagata University', value: 'Yamagata University' },
  { label: 'Yamaguchi Prefectural University', value: 'Yamaguchi Prefectural University' },
  { label: 'Yamaguchi University', value: 'Yamaguchi University' },
  { label: 'Yamanashi Gakuin University', value: 'Yamanashi Gakuin University' },
  { label: 'Yamanashi Medical University', value: 'Yamanashi Medical University' },
  { label: 'Yamanashi University', value: 'Yamanashi University' },
  { label: 'Yan Shan University', value: 'Yan Shan University' },
  { label: "Yan'an University", value: "Yan'an University" },
  { label: 'Yanbian University', value: 'Yanbian University' },
  { label: 'Yanbian University of Science and Technology', value: 'Yanbian University of Science and Technology' },
  { label: 'Yanbu Industrial College', value: 'Yanbu Industrial College' },
  { label: 'Yanbu Technical Institute', value: 'Yanbu Technical Institute' },
  { label: 'Yanbu University College', value: 'Yanbu University College' },
  { label: 'Yangtze Normal University', value: 'Yangtze Normal University' },
  { label: 'Yangtze University', value: 'Yangtze University' },
  { label: 'Yangzhou University', value: 'Yangzhou University' },
  {
    label: 'Yantai Education Institute & Yantai Television University',
    value: 'Yantai Education Institute & Yantai Television University',
  },
  { label: 'Yantai University', value: 'Yantai University' },
  { label: 'Yarmouk Private University ', value: 'Yarmouk Private University ' },
  { label: 'Yarmouk University', value: 'Yarmouk University' },
  {
    label: 'Yaroslavl International University of Business and New Technologies',
    value: 'Yaroslavl International University of Business and New Technologies',
  },
  { label: 'Yaroslavl State Medical Academy', value: 'Yaroslavl State Medical Academy' },
  { label: 'Yaroslavl State Pedagogical University', value: 'Yaroslavl State Pedagogical University' },
  { label: 'Yaroslavl State Technical University', value: 'Yaroslavl State Technical University' },
  { label: 'Yaroslavl State Theatre Institute', value: 'Yaroslavl State Theatre Institute' },
  { label: 'Yaroslavl State University', value: 'Yaroslavl State University' },
  { label: 'Yasar University', value: 'Yasar University' },
  {
    label: 'Yashawantrao Chavan Maharashtra Open University',
    value: 'Yashawantrao Chavan Maharashtra Open University',
  },
  { label: "Yasuda Women's University", value: "Yasuda Women's University" },
  { label: 'Yasuj University', value: 'Yasuj University' },
  { label: 'Yasuj University of Medical Sciences', value: 'Yasuj University of Medical Sciences' },
  { label: 'Yazd Medical University', value: 'Yazd Medical University' },
  { label: 'Yazd University', value: 'Yazd University' },
  { label: 'Yeditepe University', value: 'Yeditepe University' },
  { label: 'Yelets State University', value: 'Yelets State University' },
  { label: 'Yemenia University', value: 'Yemenia University' },
  { label: 'Yerevan Haibusak University', value: 'Yerevan Haibusak University' },
  { label: 'Yerevan State Medical University', value: 'Yerevan State Medical University' },
  { label: 'Yerevan State University', value: 'Yerevan State University' },
  { label: 'Yeshiva University', value: 'Yeshiva University' },
  { label: 'Yeungnam University', value: 'Yeungnam University' },
  { label: 'Yildirim Beyazit University', value: 'Yildirim Beyazit University' },
  { label: 'Yildiz Technical University', value: 'Yildiz Technical University' },
  { label: 'Yokkaichi University', value: 'Yokkaichi University' },
  { label: 'Yokohama City University', value: 'Yokohama City University' },
  { label: 'Yokohama College of Commerce', value: 'Yokohama College of Commerce' },
  { label: 'Yokohama National University', value: 'Yokohama National University' },
  { label: 'Yonok University', value: 'Yonok University' },
  { label: 'Yonsei University', value: 'Yonsei University' },
  { label: 'York College Nebraska', value: 'York College Nebraska' },
  { label: 'York College of Pennsylvania', value: 'York College of Pennsylvania' },
  { label: 'York University', value: 'York University' },
  { label: 'York University', value: 'York University' },
  { label: 'Yorker International University', value: 'Yorker International University' },
  { label: 'Yorker International University, Athens', value: 'Yorker International University, Athens' },
  { label: 'Yorker International University, Milano', value: 'Yorker International University, Milano' },
  { label: 'Yosu National University', value: 'Yosu National University' },
  { label: 'Youngstown State University', value: 'Youngstown State University' },
  { label: 'YPC-iTWEB College', value: 'YPC-iTWEB College' },
  { label: 'Yuan Ze University', value: 'Yuan Ze University' },
  { label: 'Yugra State University', value: 'Yugra State University' },
  { label: 'Yukon College', value: 'Yukon College' },
  { label: 'Yunnan Agriculture University', value: 'Yunnan Agriculture University' },
  { label: 'Yunnan Normal University', value: 'Yunnan Normal University' },
  { label: 'Yunnan University', value: 'Yunnan University' },
  { label: 'Yüzüncü Yil (Centennial) University', value: 'Yüzüncü Yil (Centennial) University' },
  { label: 'Zabol University', value: 'Zabol University' },
  {
    label: 'Zachodniopomorska School of Science and Engineering, ',
    value: 'Zachodniopomorska School of Science and Engineering, ',
  },
  { label: 'Zagazig University', value: 'Zagazig University' },
  { label: 'Zagreb School of Economics and Management', value: 'Zagreb School of Economics and Management' },
  { label: 'Zahedan University of Medical Sciences', value: 'Zahedan University of Medical Sciences' },
  { label: 'Zaman University', value: 'Zaman University' },
  { label: 'Zambian Open University', value: 'Zambian Open University' },
  {
    label: 'Zanjan Institute for Advanced Studies in Basic Sciences',
    value: 'Zanjan Institute for Advanced Studies in Basic Sciences',
  },
  { label: 'Zanjan University', value: 'Zanjan University' },
  { label: 'Zanjan University of Medical Sciences', value: 'Zanjan University of Medical Sciences' },
  { label: 'Zaporizhzhe National Technical University', value: 'Zaporizhzhe National Technical University' },
  { label: 'Zaporizhzhe National University', value: 'Zaporizhzhe National University' },
  { label: 'Zarka Private University', value: 'Zarka Private University' },
  { label: 'Zawiya Academy', value: 'Zawiya Academy' },
  { label: 'Zawiya University', value: 'Zawiya University' },
  { label: 'Zayed University', value: 'Zayed University' },
  { label: 'Zetech College', value: 'Zetech College' },
  { label: 'Zhanjiang Ocean University', value: 'Zhanjiang Ocean University' },
  { label: 'Zhejiang Forestry University', value: 'Zhejiang Forestry University' },
  { label: 'Zhejiang Gongshang University', value: 'Zhejiang Gongshang University' },
  { label: 'Zhejiang Normal University', value: 'Zhejiang Normal University' },
  { label: 'Zhejiang Sci-Tech University ', value: 'Zhejiang Sci-Tech University ' },
  { label: 'Zhejiang University', value: 'Zhejiang University' },
  { label: 'Zhejiang University of Technology', value: 'Zhejiang University of Technology' },
  { label: 'Zhengda Software College', value: 'Zhengda Software College' },
  { label: 'Zhengzhou Grain University', value: 'Zhengzhou Grain University' },
  { label: 'Zhengzhou University of Technology', value: 'Zhengzhou University of Technology' },
  { label: 'Zhetysu State University ', value: 'Zhetysu State University ' },
  { label: 'Zhezkazgan Baikonurov University', value: 'Zhezkazgan Baikonurov University' },
  { label: 'Zhongnan University of Economics and Law', value: 'Zhongnan University of Economics and Law' },
  { label: 'Zhongnan University of Finance and Economics', value: 'Zhongnan University of Finance and Economics' },
  { label: 'Zhongnan University of Technology', value: 'Zhongnan University of Technology' },
  { label: 'Zhongshan University', value: 'Zhongshan University' },
  { label: 'Zia-ud-Din Medical University', value: 'Zia-ud-Din Medical University' },
  { label: 'Zimbabwe Ezekiel Guti University', value: 'Zimbabwe Ezekiel Guti University' },
  { label: 'Zimbabwe Open University', value: 'Zimbabwe Open University' },
  { label: 'Zonguldak Karaelmas University', value: 'Zonguldak Karaelmas University' },
  {
    label: 'Zurich University of Applied Sciences Winterthur',
    value: 'Zurich University of Applied Sciences Winterthur',
  },
  { label: 'Zuyd University', value: 'Zuyd University' },
];
