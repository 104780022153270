import { Box, Button, Modal } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SubmissionQuotaModalProps {
  onConfirm: () => boolean;
  onDismiss: () => void;
  remainingSubmissions: number;
  submissionLimit?: number;
}

const SubmissionQuotaModal: React.FC<SubmissionQuotaModalProps> = (props) => {
  const { onConfirm, onDismiss, remainingSubmissions, submissionLimit } = props;
  const { t } = useTranslation('leaderboard');

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <Modal
      visible
      onDismiss={handleDismiss}
      header={t('submissionQuotaModal.title')}
      footer={
        <Box variant="span" float="right">
          <Box display="inline-block" padding={{ right: 's' }}>
            <Button variant="link" onClick={handleDismiss}>
              {t('cancel', { ns: 'translation' })}
            </Button>
          </Box>
          <Button
            variant="primary"
            onClick={() => {
              if (onConfirm()) {
                handleDismiss();
              }
            }}
          >
            {t('continue', { ns: 'translation' })}
          </Button>
        </Box>
      }
    >
      <Box margin="xl">{t('submissionQuotaModal.content', { submissionLimit, remainingSubmissions })}</Box>
    </Modal>
  );
};

export default SubmissionQuotaModal;
