import { LearningModuleChaptersSectionData, LearningModulePageProps } from 'types/LearningModuleTypes';
import { LearningModuleHomeData } from 'common/constants/LearningModuleHomeCardData';
import { LEARN_LESSON1_HELP_PANEL_URL } from 'common/constants/urls';
import { IntroToMLChapterId, IntroToMLChapterTitle } from 'common/constants/learningModule/introToML';

const IntroToMLChaptersSection: LearningModuleChaptersSectionData = [
  {
    title: 'IntroToMLChapters.getStarted',
    chaptersCard: [
      {
        cardActionId: IntroToMLChapterId.CHAPTER_1,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        chapterDescription: 'Chapters.Lesson1.description',
        id: IntroToMLChapterId.CHAPTER_1,
        chapterTitle: IntroToMLChapterTitle.CHAPTER_1,
        duration: 'Chapters.Lesson1.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_2,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_2,
        chapterDescription: 'Chapters.Lesson2.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_2,
        duration: 'Chapters.Lesson2.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_3,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_3,
        chapterDescription: 'Chapters.Lesson3.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_3,
        duration: 'Chapters.Lesson3.duration',
        isLoading: false,
      },
    ],
  },
  {
    title: 'IntroToMLChapters.fiveStepOfML',
    chaptersCard: [
      {
        cardActionId: IntroToMLChapterId.CHAPTER_4,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_4,
        chapterDescription: 'Chapters.Lesson4.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_4,
        duration: 'Chapters.Lesson4.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_5,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_5,
        chapterDescription: 'Chapters.Lesson5.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_5,
        duration: 'Chapters.Lesson5.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_6,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_6,
        chapterDescription: 'Chapters.Lesson6.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_6,
        duration: 'Chapters.Lesson6.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_7,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_7,
        chapterDescription: 'Chapters.Lesson7.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_7,
        duration: 'Chapters.Lesson7.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_8,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_8,
        chapterDescription: 'Chapters.Lesson8.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_8,
        duration: 'Chapters.Lesson8.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_9,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_9,
        chapterDescription: 'Chapters.Lesson9.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_9,
        duration: 'Chapters.Lesson9.duration',
        isLoading: false,
      },
    ],
  },
  {
    title: 'IntroToMLChapters.exampleOfML',
    chaptersCard: [
      {
        cardActionId: IntroToMLChapterId.CHAPTER_10,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_10,
        chapterDescription: 'Chapters.Lesson10.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_10,
        duration: 'Chapters.Lesson10.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_11,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_11,
        chapterDescription: 'Chapters.Lesson11.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_11,
        duration: 'Chapters.Lesson11.duration',
        isLoading: false,
      },
      {
        cardActionId: IntroToMLChapterId.CHAPTER_12,
        cardActionLabel: 'IntroToMLChapters.startChapter',
        id: IntroToMLChapterId.CHAPTER_12,
        chapterDescription: 'Chapters.Lesson12.description',
        chapterTitle: IntroToMLChapterTitle.CHAPTER_12,
        duration: 'Chapters.Lesson12.duration',
        isLoading: false,
      },
    ],
  },
];

export const IntroToMLPageData: LearningModulePageProps = {
  ...LearningModuleHomeData[0],
  description: 'LearnOverview.IntroToMLDesc',
  helpId: LEARN_LESSON1_HELP_PANEL_URL,
  sections: IntroToMLChaptersSection,
  udacityUrl: 'https://www.udacity.com/course/aws-machine-learning-foundations--ud065',
};
