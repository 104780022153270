import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MODEL_SLICE, ModelAction, emptyResource } from 'common/constants';
import { validateModel } from 'services/deepRacerLite';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResource } from 'store/types';
import { addLoadingCasesWithNestedState } from 'store/utils/loadingCases';
import { DeepRacerLiteTypes } from 'types/DeepRacerLiteTypes';

export interface ModelState {
  validateModel: AsyncResource<DeepRacerLiteTypes.ErrorDetailsList>;
}

export interface TestRewardFuncArgs {
  code: string;
  trackArn: string;
  trackDirection: DeepRacerLiteTypes.TrackDirection;
}

const initialState: ModelState = {
  validateModel: emptyResource,
};

export const validateReinforcementModel = createAsyncThunk<
  DeepRacerLiteTypes.ErrorDetailsList | undefined,
  TestRewardFuncArgs
>(ModelAction.VALIDATE_MODEL, async (rewardFunctionArgs, { dispatch }) => {
  try {
    const result = await validateModel(
      rewardFunctionArgs.code,
      rewardFunctionArgs.trackArn,
      rewardFunctionArgs.trackDirection
    );
    return result.ErrorDetailsList;
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const modelsSlice = createSlice({
  name: MODEL_SLICE,
  initialState,
  reducers: {
    clearValidateModel: (state) => {
      state.validateModel = emptyResource;
    },
  },
  extraReducers: (builder) => {
    addLoadingCasesWithNestedState(builder, ModelAction.VALIDATE_MODEL, MODEL_SLICE, 'validateModel');
  },
});

export const { clearValidateModel } = modelsSlice.actions;

export default modelsSlice.reducer;
