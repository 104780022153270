import { Box, Checkbox, Container, Header, Select, SpaceBetween } from '@amzn/awsui-components-react';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DEEP_RACER_LEAGUE_RULES, LEAGUE_2024_TERMS_AND_CONDITIONS_ID } from 'common/constants';
import { DeepRacerLiteTypes } from 'types';
import { getCountryDetails } from '@amzn/aws-deepracer-ui-components/utils/regionUtils';
import { COUNTRIES } from '@amzn/aws-deepracer-ui-components/data/countries';

import '../style.css';

interface LeagueRequirementsProps {
  leaderboard: DeepRacerLiteTypes.Leaderboard;
  userCompetitionCountry?: DeepRacerLiteTypes.CountryCode;
  setCompeitionCountry: (countryCode: DeepRacerLiteTypes.CountryCode) => void;
  isTermsChecked: boolean;
  setIsTermsChecked: (isChecked: boolean) => void;
  setIsAlertVisible: (isVisible: boolean) => void;
}

const LeagueRequirements: React.FC<LeagueRequirementsProps> = (props) => {
  const {
    leaderboard,
    userCompetitionCountry,
    setCompeitionCountry,
    isTermsChecked,
    setIsTermsChecked,
    setIsAlertVisible,
  } = props;
  const { t } = useTranslation('leaderboard');
  const countryOption = useMemo(() => {
    const userCountryOption = getCountryDetails(userCompetitionCountry);
    if (userCountryOption === undefined) return null;
    return { label: userCountryOption?.label, value: userCountryOption?.value };
  }, [userCompetitionCountry]);

  return (
    <Container header={<Header variant="h2">{t('leagueRequirementsComponent.title')}</Header>}>
      <SpaceBetween size="l">
        {/* Country select list */}
        {leaderboard?.TermsConditionId === LEAGUE_2024_TERMS_AND_CONDITIONS_ID && !leaderboard?.TermsAccepted && (
          <SpaceBetween size="s">
            <Box>
              <Box fontWeight="bold">{t('leagueRequirementsComponent.countryOfResidenceLabel')}</Box>
              <Box color="text-body-secondary">{t('leagueRequirementsComponent.countryOfResidenceDescription')}</Box>
            </Box>
            <Select
              selectedOption={countryOption}
              onChange={({ detail }) => {
                setIsAlertVisible(false);
                setCompeitionCountry((detail.selectedOption?.value ?? '') as DeepRacerLiteTypes.CountryCode);
              }}
              options={COUNTRIES}
              selectedAriaLabel="Selected"
              loadingText="Loading countries"
              placeholder="Select country"
              empty="No countries"
              filteringType="auto"
              className="modelsSelect"
            />
          </SpaceBetween>
        )}
        {/* T&Cs section */}
        <SpaceBetween size="m">
          <Box>
            <Box fontWeight="bold">{t('leagueRequirementsComponent.termsConditionsLabel')}</Box>
            <Box color="text-body-secondary">
              <span>
                &nbsp;
                {'I have read, understood, and unconditionally agree to the terms and conditions of the 2024' +
                  ' AWS DeepRacer Student League, including without limitation, the publicity rights in section 10 of the '}
                <a href={DEEP_RACER_LEAGUE_RULES}>terms and conditions.</a>
              </span>
            </Box>
          </Box>
          <Box margin={{ top: 'm', bottom: 'xl' }}>
            <Checkbox
              onChange={({ detail }) => {
                setIsTermsChecked(detail.checked);
              }}
              checked={isTermsChecked}
            >
              {t('leagueRequirementsComponent.checkBoxLabel', {
                year: '2024',
              })}
            </Checkbox>
          </Box>
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};

export default LeagueRequirements;
