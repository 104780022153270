import React from 'react';

import { Box } from '@amzn/awsui-components-react';

import { YOUTUBE_NO_COOKIE_URL } from 'common/constants';

export interface YoutubeNoCookieProps {
  height: string;
  title: string;
  width?: string;
  youtubeId: string;
}

const YoutubeNoCookie = ({ height, title, width = '100%', youtubeId }: YoutubeNoCookieProps): JSX.Element => {
  return (
    <Box data-analytics="youtube-Box" data-analytics-type="eventDetail" variant="div">
      <iframe
        width={width}
        height={height}
        src={`${YOUTUBE_NO_COOKIE_URL}${youtubeId}`}
        title={title}
        allow="accelerometer; encrypted-media; gyroscope;"
      ></iframe>
    </Box>
  );
};

export default YoutubeNoCookie;
