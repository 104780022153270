import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Grid, Link, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz3.10.1',
  title: 'Chapters.Lesson10.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.10.1.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson10.Wizard1.examplesIntroTitle',
      youtubeId: 'c5V4q-JtMJo',
    },
    {
      id: 'wz3.10.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard1.content1',
    },
    {
      id: 'wz3.10.1.30',
      widgetType: WizardContentType.KEY_BULLET_PAIRS,
      bulletList: [
        {
          id: 'wz3.10.1.30-1',
          title: 'Chapters.Lesson10.Wizard1.content2',
          text: 'Chapters.Lesson10.Wizard1.list1',
        },
        {
          id: 'wz3.10.1.30-2',
          title: 'Chapters.Lesson10.Wizard1.content3',
          text: 'Chapters.Lesson10.Wizard1.list2',
        },
        {
          id: 'wz3.10.1.30-3',
          title: 'Chapters.Lesson10.Wizard1.content4',
          text: 'Chapters.Lesson10.Wizard1.list3',
        },
      ],
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz3.10.2',
  title: 'Chapters.Lesson10.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.10.2.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson10.Wizard2.example1Title',
      youtubeId: 'CY5PQ0aJ-ig',
    },
    {
      id: 'wz3.10.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard2.content1',
    },
    {
      id: 'wz3.10.3.30',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.houseValue,
      imageAltText: 'Chapters.Lesson10.Wizard2.imageAltText1',
      width: '50%',
      height: '50%',
    },
    {
      id: 'wz3.10.2.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard2.content2',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz3.10.3',
  title: 'Chapters.Lesson10.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.10.3.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard3.content1',
    },
    {
      id: 'wz3.10.3.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard3.content2',
    },
    {
      id: 'wz3.10.2.30',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.labeledData,
      imageAltText: 'Chapters.Lesson10.Wizard3.imageAltText1',
      width: '40%',
      height: '40%',
    },
  ],
};

const wz4: WizardStepsProp = {
  id: 'wz3.10.4',
  title: 'Chapters.Lesson10.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.10.4.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard4.content1',
    },
    {
      id: 'wz3.10.4.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        { id: 'wz3.10.4.20-1', text: 'Chapters.Lesson10.Wizard4.list1' },
        { id: 'wz3.10.4.20-2', text: 'Chapters.Lesson10.Wizard4.list2' },
        { id: 'wz3.10.4.20-3', text: 'Chapters.Lesson10.Wizard4.list3' },
      ],
    },
    {
      id: 'wz3.10.4.30',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.dataTable,
      imageAltText: 'Chapters.Lesson10.Wizard4.imageAltText1',
      width: '40%',
      height: '40%',
    },
    {
      id: 'wz3.10.4.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard4.content2',
    },
    {
      id: 'wz3.10.4.50',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.lotSize,
      imageAltText: 'Chapters.Lesson10.Wizard4.imageAltText2',
      width: '30%',
      height: '30%',
    },
  ],
};

const wz5: WizardStepsProp = {
  id: 'wz3.10.5',
  title: 'Chapters.Lesson10.Wizard5.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.10.5.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard5.content1',
    },
    {
      id: 'wz3.10.5.20',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson10.Wizard5.heading1',
    },
    {
      id: 'wz3.10.5.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard5.content2',
    },
    {
      id: 'wz3.10.5.40',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson10.Wizard5.heading2',
    },
    {
      id: 'wz3.10.5.50',
      widgetType: WizardContentType.P_BODY_CUSTOM,
      children: () => (
        <Box variant="p">
          <Trans i18nKey="Chapters.Lesson10.Wizard5.content3">
            {' '}
            <Link external target="_blank" href="https://scikit-learn.org/stable"></Link> has tools that can handle the
            implementation of the model training algorithm for you.
          </Trans>
        </Box>
      ),
    },
  ],
};

const wz6: WizardStepsProp = {
  id: 'wz3.10.6',
  title: 'Chapters.Lesson10.Wizard6.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.10.6.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard6.content1',
    },
    {
      id: 'wz3.10.6.20',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.rms,
      imageAltText: 'Chapters.Lesson10.Wizard6.imageAltText1',
      width: '20%',
      height: '20%',
    },
    {
      id: 'wz3.10.6.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard6.content2',
    },
    {
      id: 'wz3.10.6.40',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.rmsChart,
      imageAltText: 'Chapters.Lesson10.Wizard6.imageAltText2',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz3.10.6.50',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson10.Wizard6.heading1',
    },
    {
      id: 'wz3.10.6.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard6.content3',
    },
  ],
};

const wz7: WizardStepsProp = {
  id: 'wz3.10.7',
  title: 'Chapters.Lesson10.Wizard7.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.10.7.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard7.content1',
    },
    {
      id: 'wz3.10.7.20',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.inf1,
      imageAltText: 'Chapters.Lesson10.Wizard7.imageAltText1',
      width: '40%',
      height: '40%',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz8',
  title: 'Chapters.Lesson10.Wizard8.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz3.10.8.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson10.Wizard8.content1',
    },
    {
      id: 'wz3.10.8.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz3.10.8.20-1',
          text: 'Chapters.Lesson10.Wizard8.list1',
        },
        {
          id: 'wz3.10.8.20-2',
          text: 'Chapters.Lesson10.Wizard8.list2',
        },
        {
          id: 'wz3.10.8.20-3',
          text: 'Chapters.Lesson10.Wizard8.list3',
        },
        {
          id: 'wz3.10.8.20-4',
          text: 'Chapters.Lesson10.Wizard8.list4',
        },
      ],
    },
  ],
};

export const Lesson10 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz8',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz3.10.8.40',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson10.Wizard8.heading1',
      },
      {
        id: 'wz3.10.8.50',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson10.Wizard8.content2',
      },
      {
        id: 'summaryContent2.wz1.5.1.003',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <Button variant="normal" onClick={onClick}>
            <Trans i18nKey="WizardI18nStrings.StartChapter"></Trans>
          </Button>
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(wz4.title) as string,
          content: <WizardContent {...wz4} />,
        },
        {
          title: t(wz5.title) as string,
          content: <WizardContent {...wz5} />,
        },
        {
          title: t(wz6.title) as string,
          content: <WizardContent {...wz6} />,
        },
        {
          title: t(wz7.title) as string,
          content: <WizardContent {...wz7} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <Grid gridDefinition={[{ colspan: { default: 12, xs: 6 } }, { colspan: { default: 12, xs: 6 } }]}>
                <WizardContent {...summaryContent2} />
              </Grid>
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
