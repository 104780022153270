import { useTranslation } from 'react-i18next';

import { Box, Link, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';

const wz1: WizardStepsProp = {
  id: 'wz6.20.1',
  title: 'Chapters.Lesson20.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.20.1.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson20.Wizard1.youtubeTitle',
      youtubeId: 'jdIIzeNK5ak',
    },
    {
      id: 'wz6.20.1.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard1.content1',
    },
    {
      id: 'wz6.20.1.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard1.content2',
    },
    {
      id: 'wz6.20.1.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard1.content3',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz6.20.2',
  title: 'Chapters.Lesson20.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.20.2.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson20.Wizard2.youtubeTitle',
      youtubeId: 'hJNe6BM2p64',
    },
    {
      id: 'wz6.20.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content1',
    },
    {
      id: 'wz6.20.2.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content2',
    },
    {
      id: 'wz6.20.2.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content3',
    },
    {
      id: 'wz6.20.2.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content4',
    },
    {
      id: 'wz6.20.2.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content5',
    },
    {
      id: 'wz6.20.2.70',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content6',
    },
    {
      id: 'wz6.20.2.80',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        { id: 'wz6.20.1.80-1', text: 'Chapters.Lesson20.Wizard2.list1' },
        { id: 'wz6.20.1.80-2', text: 'Chapters.Lesson20.Wizard2.list2' },
      ],
    },
    {
      id: 'wz6.20.2.90',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content7',
    },
    {
      id: 'wz6.20.2.100',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content8',
    },
    {
      id: 'wz6.20.2.110',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content9',
    },
    {
      id: 'wz6.20.2.120',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard2.content10',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz6.20.3',
  title: 'Chapters.Lesson20.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.20.3.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson20.Wizard3.youtubeTitle',
      youtubeId: 'qdOnF5rVOZ0',
    },
    {
      id: 'wz6.20.3.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard3.content1',
    },
    {
      id: 'wz6.20.3.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard3.content2',
    },
    {
      id: 'wz6.20.3.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard3.content3',
    },
    {
      id: 'wz6.20.3.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard3.content4',
    },
    {
      id: 'wz6.20.3.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard3.content5',
    },
  ],
};

const wz4: WizardStepsProp = {
  id: 'wz6.20.4',
  title: 'Chapters.Lesson20.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.20.4.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson20.Wizard4.youtubeTitle',
      youtubeId: 'ZiS6ERox0UI',
    },
    {
      id: 'wz6.20.4.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard4.content1',
    },
    {
      id: 'wz6.20.4.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard4.content2',
    },
    {
      id: 'wz6.20.4.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard4.content3',
    },
    {
      id: 'wz6.20.4.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard4.content4',
    },
  ],
};

const wz5: WizardStepsProp = {
  id: 'wz6.20.5',
  title: 'Chapters.Lesson20.Wizard5.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.20.5.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson20.Wizard5.youtubeTitle',
      youtubeId: 'wwgm3eA-vx8',
    },
    {
      id: 'wz6.20.5.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard5.content1',
    },
    {
      id: 'wz6.20.5.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard5.content2',
    },
    {
      id: 'wz6.20.5.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard5.content3',
    },
    {
      id: 'wz6.20.5.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard5.content4',
    },
    {
      id: 'wz6.20.5.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard5.content5',
    },
    {
      id: 'wz6.20.5.70',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard5.content6',
    },
  ],
};

const wz6: WizardStepsProp = {
  id: 'wz6.20.6',
  title: 'Chapters.Lesson20.Wizard6.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.20.6.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson20.Wizard6.youtubeTitle',
      youtubeId: '06PUFbmrDpE',
    },
    {
      id: 'wz6.20.6.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content1',
    },
    {
      id: 'wz6.20.6.30',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ol',
      bulletList: [
        { id: 'wz6.20.6.30-1', text: 'Chapters.Lesson20.Wizard6.list1' },
        { id: 'wz6.20.6.30-2', text: 'Chapters.Lesson20.Wizard6.list2' },
        { id: 'wz6.20.6.30-3', text: 'Chapters.Lesson20.Wizard6.list3' },
      ],
    },
    {
      id: 'wz6.20.6.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content2',
    },
    {
      id: 'wz6.20.6.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content3',
    },
    {
      id: 'wz6.20.6.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content4',
    },
    {
      id: 'wz6.20.6.70',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content5',
    },
    {
      id: 'wz6.20.6.80',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content6',
    },
    {
      id: 'wz6.20.6.90',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content7',
    },
    {
      id: 'wz6.20.6.100',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson20.Wizard6.content8',
    },
  ],
};

const wz7: WizardStepsProp = {
  id: 'wz6.20.7',
  title: 'Chapters.Lesson20.Wizard7.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz6.20.7.10',
      widgetType: WizardContentType.P_BODY_CUSTOM,
      children: () => (
        <Box variant="p">
          Well done on completing this deep dive into how the AWS DeepRacer device works and the unique challenges
          associated with running inference at edge. As you have seen, Intel’s OpenVINO toolkit works hand-in-hand with
          AWS DeepRacer to make the real-time inference possible through model optimization. If you would like more
          information about the OpenVINO toolkit, see&nbsp;
          <Link external target="_blank" href="http://intel.com/openVINO">
            intel.com/openVINO
          </Link>
          .
        </Box>
      ),
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Lesson20 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(wz4.title) as string,
          content: <WizardContent {...wz4} />,
        },
        {
          title: t(wz5.title) as string,
          content: <WizardContent {...wz5} />,
        },
        {
          title: t(wz6.title) as string,
          content: <WizardContent {...wz6} />,
        },
        {
          title: t(wz7.title) as string,
          content: <WizardContent {...wz7} />,
        },
      ]}
    />
  );
};
