import { getIdToken } from 'store/utils/authUtils';
import ServiceProvider from 'services/ServiceProvider';
import { DeepRacerLiteTypes, DeepRacerLiteService } from 'types';
import { DR_LITE_NAMESPACE_ID } from 'services/constants';

/**
 * Get the list of all assessmentTrackers
 * @param AssessmentId
 * @returns NextToken, LmsQuizAssessmentTrackerList
 */
export const getAssessmentTrackers = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.ListLmsQuizAssessmentTrackersResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getAssessmentTrackersRequest: DeepRacerLiteTypes.ListLmsQuizAssessmentTrackersRequest = {
      assessmentId: assessmentId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.listLmsQuizAssessmentTrackers(getAssessmentTrackersRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get assessmentTrackers by assessmentTrackerId
 * @param AssessmentId
 * @param AssessmentTrackerId
 * @returns LmsQuizAssessmentTrackerData
 */
export const getAssessmentTracker = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid,
  assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getAssessmentTrackerRequest: DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerRequest = {
      assessmentId: assessmentId,
      assessmentTrackerId: assessmentTrackerId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getLmsQuizAssessmentTracker(getAssessmentTrackerRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get current question by assessmentTrackerId
 * @param AssessmentId
 * @param AssessmentTrackerId
 * @returns GetLmsQuizAssessmentTrackerQuestionCurrentResponse
 */
export const getCurrentQuestion = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid,
  assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerQuestionCurrentResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getCurrentQuestionRequest: DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerQuestionCurrentRequest = {
      assessmentId: assessmentId,
      assessmentTrackerId: assessmentTrackerId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getLmsQuizAssessmentTrackerQuestionCurrent(getCurrentQuestionRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get next question by assessmentTrackerId
 * @param AssessmentId
 * @param AssessmentTrackerId
 * @returns GetLmsQuizAssessmentTrackerQuestionNextResponse
 */
export const getNextQuestion = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid,
  assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerQuestionNextResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getNextQuestionRequest: DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerQuestionNextRequest = {
      assessmentId: assessmentId,
      assessmentTrackerId: assessmentTrackerId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getLmsQuizAssessmentTrackerQuestionNext(getNextQuestionRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get prev question by assessmentTrackerId
 * @param AssessmentId
 * @param AssessmentTrackerId
 * @returns GetLmsQuizAssessmentTrackerQuestionPrevResponse
 */
export const getPrevQuestion = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid,
  assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerQuestionPrevResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getPrevQuestionRequest: DeepRacerLiteTypes.GetLmsQuizAssessmentTrackerQuestionPrevRequest = {
      assessmentId: assessmentId,
      assessmentTrackerId: assessmentTrackerId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getLmsQuizAssessmentTrackerQuestionPrev(getPrevQuestionRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Post answer by assessmentTrackerId
 * @param AssessmentId
 * @param AssessmentTrackerId
 * @returns CreateLmsQuizAssessmentTrackerAnswerResponse
 */
export const postAnswerQuestion = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid,
  assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid,
  questionNo: DeepRacerLiteTypes.Long,
  answers: DeepRacerLiteTypes.StringList
): Promise<DeepRacerLiteTypes.CreateLmsQuizAssessmentTrackerAnswerResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const postAnswerRequest: DeepRacerLiteTypes.CreateLmsQuizAssessmentTrackerAnswerRequest = {
      assessmentId,
      assessmentTrackerId,
      questionNo,
      answers,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.createLmsQuizAssessmentTrackerAnswer(postAnswerRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Post create assessment tracker for assessmentId
 * @param AssessmentId
 * @returns CreateLmsQuizAssessmentTrackerResponse
 */
export const postCreateAssessmentTracker = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.CreateLmsQuizAssessmentTrackerResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const postCreateAssessmentTrackerRequest: DeepRacerLiteTypes.CreateLmsQuizAssessmentTrackerRequest = {
      assessmentId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.createLmsQuizAssessmentTracker(postCreateAssessmentTrackerRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Patch start assessment tracker for assessmentId
 * @param AssessmentId AssessmentTrackerId
 * @returns StartLmsQuizAssessmentTrackerResponse
 */
export const patchStartAssessmentTracker = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid,
  assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.StartLmsQuizAssessmentTrackerResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const postStartAssessmentTrackerRequest: DeepRacerLiteTypes.StartLmsQuizAssessmentTrackerRequest = {
      assessmentId,
      assessmentTrackerId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.startLmsQuizAssessmentTracker(postStartAssessmentTrackerRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Patch finish assessment tracker for assessmentId
 * @param AssessmentId AssessmentTrackerId
 * @returns FinishLmsQuizAssessmentTrackerResponse
 */
export const patchFinishAssessmentTracker = async (
  assessmentId: DeepRacerLiteTypes.AsteroidUuid,
  assessmentTrackerId: DeepRacerLiteTypes.AsteroidUuid
): Promise<DeepRacerLiteTypes.FinishLmsQuizAssessmentTrackerResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const postFinishAssessmentTrackerRequest: DeepRacerLiteTypes.FinishLmsQuizAssessmentTrackerRequest = {
      assessmentId,
      assessmentTrackerId,
      nameSpaceId: DR_LITE_NAMESPACE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.finishLmsQuizAssessmentTracker(postFinishAssessmentTrackerRequest, idToken);
  } catch (error) {
    return Promise.reject(error);
  }
};
