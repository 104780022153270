import React from 'react';
import Checkbox, { CheckboxProps } from '@amzn/awsui-components-react/polaris/checkbox';
import { useField } from 'formik';

interface CheckboxWrapperProps extends Omit<CheckboxProps, 'checked'> {
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
  name: string;
}

export const CheckboxFieldView: React.FC<CheckboxWrapperProps> = ({
  dataAnalyticsLabel,
  dataAnalyticsTypeLabel,
  name,
  onChange,
  onBlur,
  ...otherProps
}) => {
  const [field, , helpers] = useField({ name });

  const handleChange: CheckboxProps['onChange'] = (event) => {
    helpers.setValue(event.detail.checked);
    onChange?.(event);
  };

  const handleBlur: CheckboxProps['onBlur'] = (event) => {
    helpers.setTouched(true);
    onBlur?.(event);
  };

  const checkboxFieldProps = {
    ...field,
    ...otherProps,
    checked: field.value,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <Checkbox
      data-analytics={dataAnalyticsLabel}
      data-analytics-type={dataAnalyticsTypeLabel}
      {...checkboxFieldProps}
    />
  );
};

export const CheckboxField = React.memo(CheckboxFieldView);
