import { COUNTS_PER_PAGE, SCHOOL_LIST } from 'common/constants';

export const getItemsFromSchoolList = ({ filteringText = '', pageNumber = 1 }) => {
  const totalPages = Math.ceil(SCHOOL_LIST.length / COUNTS_PER_PAGE);
  const lowerCaseFilteringText = filteringText.toLocaleLowerCase();
  let items: any[] = [];
  if (filteringText) {
    items = SCHOOL_LIST.filter((school) => school.value.toLocaleLowerCase().includes(lowerCaseFilteringText));
  } else {
    if (pageNumber >= totalPages) {
      items = SCHOOL_LIST.slice(COUNTS_PER_PAGE * (totalPages - 1));
    } else {
      items = SCHOOL_LIST.slice(COUNTS_PER_PAGE * (pageNumber - 1), COUNTS_PER_PAGE * pageNumber);
    }
  }
  return { items, hasNext: !filteringText && pageNumber < totalPages };
};
