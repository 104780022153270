/* istanbul ignore file */
import {
  QuizAssessmentResult,
  QuizAssessmentStatus,
} from '@amzn/aws-deep-racer-lite-external-service-type-script-client';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { SpaceBetween, Container, Header, Button, Link, Spinner, Badge } from '@amzn/awsui-components-react';
import pageCatalog, { DRLitePage } from 'pages/pageCatalog';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  clearCreateAssessmentTracker,
  createAssessmentTracker,
  fetchAssessmentTrackers,
  getAssessmentTrackerError,
  getAssessmentTrackers,
  getAssessmentTrackersIsLoading,
  getCreateAssessmentTracker,
  getCreateAssessmentTrackerIsLoading,
} from 'store/lmsQuiz';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { toggleConfettiRunning } from 'store/confetti';
import { DR_LITE_SCHOLARSHIP_ASSESSMENT_ID } from 'services/constants';
import { getOptedInEsperanza, getProfileData } from 'store/profile';
import { useTranslation } from 'react-i18next';

const AssessmentResult = () => {
  const { t } = useTranslation('assessment', { keyPrefix: 'assessmentResult' });
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [firstCreateAssessmentTrackerCallMade, setfirstCreateAssessmentTrackerCallMade] = useState(false);
  const assessmentTrackers = useAppSelector(getAssessmentTrackers);
  const assessmentTrackersisLoading = useAppSelector(getAssessmentTrackersIsLoading);
  const assessmentTrackerError = useAppSelector(getAssessmentTrackerError);
  const createAssessmentTrackerResponse = useAppSelector(getCreateAssessmentTracker);
  const createAssessmentTrackerIsLoading = useAppSelector(getCreateAssessmentTrackerIsLoading);
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  const profileData = useAppSelector(getProfileData);

  // redirect if not opted in esperanza
  useEffect(() => {
    if (!optedInEsperanza && profileData) {
      history.push(pageCatalog[DRLitePage.LEARNING_HOME].getPath());
    }
  }, [history, optedInEsperanza, profileData]);

  // fetch assessmentTrackers from server for assessmentId on mount
  useEffect(() => {
    dispatch(fetchAssessmentTrackers({ assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID }));
    return () => {
      dispatch(clearCreateAssessmentTracker());
    };
  }, [dispatch]);

  // check if assessmentTrackers is empty and then create new tracker
  useEffect(() => {
    // if assessmentTrackers length is 0, create new assessment tracker
    if (
      assessmentTrackers &&
      assessmentTrackers.length === 0 &&
      !assessmentTrackerError &&
      !firstCreateAssessmentTrackerCallMade
    ) {
      dispatch(createAssessmentTracker({ assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID }));
      setfirstCreateAssessmentTrackerCallMade(true);
      return;
    }
    if (assessmentTrackers) {
      const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
      // if status is IN_EVALUATION dispatch fetchAssessmentTrackers
      if (latestAssessmentTracker?.status === QuizAssessmentStatus.IN_EVALUATION) {
        dispatch(
          fetchAssessmentTrackers({
            assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
          })
        );
        return;
      }
      // if status is READY redirect to Start Page
      if (latestAssessmentTracker?.status === QuizAssessmentStatus.READY) {
        history.push(
          pageCatalog.AssessmentStart.getPath({
            assessmentTrackerId: latestAssessmentTracker?.assessmentTrackerId,
          })
        );
        return;
      }
      // if result is PASS show confetti
      if (latestAssessmentTracker?.result === QuizAssessmentResult.PASSED) {
        dispatch(
          toggleConfettiRunning({
            isRunning: true,
          })
        );
        const _confettiTimeout = setTimeout(() => {
          dispatch(
            toggleConfettiRunning({
              isRunning: false,
            })
          );
          clearTimeout(_confettiTimeout);
        }, 10000);
      }
    }
  }, [assessmentTrackerError, assessmentTrackers, dispatch, firstCreateAssessmentTrackerCallMade, history]);

  // listen to createAssessmentTrackerResponse and redirect to assessmentStart page with assessmentTrackerId
  useEffect(() => {
    if (createAssessmentTrackerResponse?.assessmentTrackerId) {
      history.push(
        pageCatalog.AssessmentStart.getPath({
          assessmentTrackerId: createAssessmentTrackerResponse?.assessmentTrackerId,
        })
      );
      dispatch(
        fetchAssessmentTrackers({
          assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
        })
      );
    }
  }, [createAssessmentTrackerResponse, dispatch, history]);

  const handlePrimaryButtonClick = () => {
    if (!assessmentTrackers) return;
    if (assessmentTrackers.length === 0) return;
    const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
    if (latestAssessmentTracker?.status == QuizAssessmentStatus.READY) {
      history.push(
        pageCatalog.AssessmentStart.getPath({
          assessmentTrackerId: latestAssessmentTracker?.assessmentTrackerId,
        })
      );
      return;
    }
    if (latestAssessmentTracker?.status === QuizAssessmentStatus.IN_PROGRESS) {
      history.push(
        pageCatalog.AssessmentQuiz.getPath({
          assessmentTrackerId: latestAssessmentTracker?.assessmentTrackerId,
        })
      );
      return;
    }
    dispatch(createAssessmentTracker({ assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID }));
  };

  const getHeaderText = () => {
    if (!assessmentTrackers) return <></>;
    if (assessmentTrackers.length === 0) return <></>;
    const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
    switch (latestAssessmentTracker?.status) {
      case QuizAssessmentStatus.IN_PROGRESS:
        return <>{t('headerText.inProgress')}</>;
      case QuizAssessmentStatus.IN_EVALUATION:
        return (
          <>
            {t('headerText.inEvaluation')} <Spinner />
          </>
        );
      case QuizAssessmentStatus.COMPLETED:
        if (latestAssessmentTracker?.result === QuizAssessmentResult.PASSED) {
          return <div style={{ color: awsui.colorChartsGreen600 }}>{t('headerText.completedPassed')}</div>;
        }
        switch (latestAssessmentTracker?.attempt) {
          case 1:
            return <>{t('headerText.completedFailed1', { score: latestAssessmentTracker?.score, maxScore: '30' })}</>;
          case 2:
            return <>{t('headerText.completedFailed2', { score: latestAssessmentTracker?.score, maxScore: '30' })}</>;
          case 3:
            return <>{t('headerText.completedFailed3', { score: latestAssessmentTracker?.score, maxScore: '30' })}</>;
          default:
            return <></>;
        }
      default:
        return (
          <>
            {t('headerText.default', {
              score: latestAssessmentTracker?.score,
              maxScore: latestAssessmentTracker?.maxQuestions,
              remainingAttempts: 3 - assessmentTrackers?.length,
            })}
          </>
        );
    }
  };

  const getPrimaryButtonText = () => {
    if (!assessmentTrackers) return <></>;
    const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
    switch (latestAssessmentTracker?.status) {
      case QuizAssessmentStatus.IN_PROGRESS:
        return <>{t('primaryButtonLabel.resume')}</>;
      default:
        return <>{t('primaryButtonLabel.startOver')}</>;
    }
  };

  const isPrimaryButtonEnabled = () => {
    if (!assessmentTrackers) return;
    if (assessmentTrackers.length === 0) return false;
    const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
    if (latestAssessmentTracker?.attempt > 3) return false;
    if (latestAssessmentTracker?.attempt === 3 && latestAssessmentTracker?.status === QuizAssessmentStatus.COMPLETED) {
      return false;
    }
    if (latestAssessmentTracker?.result === QuizAssessmentResult.PASSED) {
      return false;
    }
    return true;
  };

  const getContentLinks = () => (
    <ul style={{ paddingLeft: '16px' }}>
      <li>
        <Link onFollow={() => history.push(pageCatalog[DRLitePage.LEARNING_MODULE_1].getPath())}>
          Introduction to machine learning
        </Link>{' '}
      </li>
      <li>
        <Link onFollow={() => history.push(pageCatalog[DRLitePage.LEARNING_MODULE_2].getPath())}>
          Introduction to reinforcement learning
        </Link>
      </li>
    </ul>
  );

  const getContentText = () => {
    if (!assessmentTrackers) return <></>;
    const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
    if (latestAssessmentTracker?.result === QuizAssessmentResult.FAILED && latestAssessmentTracker?.attempt === 3) {
      return (
        <div>
          <p style={{ marginTop: '0px' }}>{t('content.failed&attemptsUsed')}</p>
          {getContentLinks()}
        </div>
      );
    }
    if (latestAssessmentTracker?.result === QuizAssessmentResult.PASSED) {
      return (
        <div>
          <p style={{ marginTop: '0px' }}>
            <b>
              {t('content.passedScoreNextStepsText', {
                score: latestAssessmentTracker?.score,
                maxQuestions: latestAssessmentTracker?.maxQuestions,
              })}
            </b>
          </p>
          <ol style={{ paddingLeft: '16px' }}>
            <li>
              <p style={{ padding: '0px', marginBottom: '8px' }}>
                <Link onFollow={() => history.push(pageCatalog.CreateModel.getPath())}>Train a DeepRacer model</Link> to
                put your learning into practice. To prequalify for a scholarship, you need to finish the track in under
                2 mins.
              </p>
            </li>
            <li>
              <p style={{ padding: '0px', margin: '0px' }}>
                If you have already trained your model and want to improve your lap time, checkout our{' '}
                <Link onFollow={() => history.push(pageCatalog.ImproveModel.getPath())}>
                  model improvement resources
                </Link>
                .
              </p>
            </li>
          </ol>
        </div>
      );
    }
    return (
      <div>
        <p style={{ marginTop: '0px' }}>{t('content.improveChances')}</p>
        {getContentLinks()}
      </div>
    );
  };

  const getHeaderBadge = () => {
    if (isPrimaryButtonEnabled()) {
      return (
        <Button variant="primary" onClick={handlePrimaryButtonClick} disabled={!isPrimaryButtonEnabled()}>
          {getPrimaryButtonText()}
        </Button>
      );
    }
    if (!assessmentTrackers) return <></>;
    const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
    switch (latestAssessmentTracker?.result) {
      case QuizAssessmentResult.PASSED:
        return <Badge color="green">{t('headerBadge.passed')}</Badge>;
      case QuizAssessmentResult.FAILED:
        if (latestAssessmentTracker?.attempt === 3) {
          return <Badge>{t('headerBadge.failed')}</Badge>;
        }
        return <></>;
      default:
        return <></>;
    }
  };

  if (assessmentTrackersisLoading || createAssessmentTrackerIsLoading) {
    return (
      <SpaceBetween size="l">
        <Container>
          {assessmentTrackersisLoading && <>{t('loadingText')}</>}
          {createAssessmentTrackerIsLoading && <>{t('creatingText')}</>}
          <Spinner />
        </Container>
      </SpaceBetween>
    );
  }

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header variant="h2" actions={getHeaderBadge()}>
            {getHeaderText()}
          </Header>
        }
      >
        {getContentText()}
      </Container>
    </SpaceBetween>
  );
};

export default AssessmentResult;
