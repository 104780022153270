import { Trans, useTranslation } from 'react-i18next';

import { Button, Grid, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { LearningModuleImageMap } from '../../common/ImageMap';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz3.11.1',
  title: 'Chapters.Lesson11.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.11.1.10',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson11.Wizard1.videoTitle1',
      youtubeId: 'XP4-FOvlxVs',
    },
    {
      id: 'wz3.11.1.20',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson11.Wizard1.heading1',
    },
    {
      id: 'wz3.11.1.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard1.content1',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz3.11.2',
  title: 'Chapters.Lesson11.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.11.2.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard2.content1',
    },
    {
      id: 'wz3.11.2.20',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.books,
      imageAltText: 'Chapters.Lesson11.Wizard2.imageAltText1',
      width: '40%',
      height: '40%',
    },
    {
      id: 'wz3.11.2.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard2.content2',
    },
    {
      id: 'wz3.11.2.40',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson11.Wizard2.heading1',
    },
    {
      id: 'wz3.11.2.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard2.content3',
    },
    {
      id: 'wz3.11.2.60',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.unsuperClust,
      imageAltText: 'Chapters.Lesson11.Wizard2.imageAltText2',
      width: '40%',
      height: '40%',
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz3.11.3',
  title: 'Chapters.Lesson11.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.11.3.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard3.content1',
    },
    {
      id: 'wz3.11.3.20',
      widgetType: WizardContentType.HEADER,
      variant: 'h3',
      text: 'Chapters.Lesson11.Wizard3.heading1',
    },
    {
      id: 'wz3.11.3.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard3.content2',
    },
    {
      id: 'wz3.11.3.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard3.content3',
    },
    {
      id: 'wz3.11.3.50',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.bagOfWords,
      imageAltText: 'Chapters.Lesson11.Wizard3.imageAltText1',
      width: '40%',
      height: '40%',
    },
  ],
};

const wz4: WizardStepsProp = {
  id: 'wz3.11.4',
  title: 'Chapters.Lesson11.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.11.4.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard4.content1',
    },
    {
      id: 'wz3.11.4.20',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.k2b,
      imageAltText: 'Chapters.Lesson11.Wizard4.imageAltText1',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz3.11.4.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard4.content2',
    },
  ],
};

const wz5: WizardStepsProp = {
  id: 'wz3.11.5',
  title: 'Chapters.Lesson11.Wizard5.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.11.5.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard5.content1',
    },
    {
      id: 'wz3.11.5.20',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.k19b,
      imageAltText: 'Chapters.Lesson11.Wizard5.imageAltText1',
      width: '30%',
      height: '30%',
    },
    {
      id: 'wz3.11.5.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard5.content2',
    },
  ],
};

const wz6: WizardStepsProp = {
  id: 'wz3.11.6',
  title: 'Chapters.Lesson11.Wizard6.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.11.6.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard6.content1',
    },
    {
      id: 'wz3.11.6.20',
      widgetType: WizardContentType.IMAGE,
      imageId: LearningModuleImageMap.silhou,
      imageAltText: 'Chapters.Lesson11.Wizard6.imageAltText1',
      width: '40%',
      height: '40%',
    },
    {
      id: 'wz3.11.6.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard6.content2',
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz7',
  title: 'Chapters.Lesson11.Wizard7.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz3.11.7.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson11.Wizard7.content1',
    },
    {
      id: 'wz3.11.7.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz3.11.7.20-1',
          text: 'Chapters.Lesson11.Wizard7.list1',
        },
        {
          id: 'wz3.11.7.20-2',
          text: 'Chapters.Lesson11.Wizard7.list2',
        },
        {
          id: 'wz3.11.7.20-3',
          text: 'Chapters.Lesson11.Wizard7.list3',
        },
        {
          id: 'wz3.11.7.20-4',
          text: 'Chapters.Lesson11.Wizard7.list4',
        },
      ],
    },
  ],
};

export const Lesson11 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz8',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz3.11.7.40',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson11.Wizard7.heading1',
      },
      {
        id: 'wz3.11.7.50',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson11.Wizard7.content2',
      },
      {
        id: 'summaryContent2.wz1.5.1.003',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <Button variant="normal" onClick={onClick}>
            <Trans i18nKey="WizardI18nStrings.StartChapter"></Trans>
          </Button>
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(wz4.title) as string,
          content: <WizardContent {...wz4} />,
        },
        {
          title: t(wz5.title) as string,
          content: <WizardContent {...wz5} />,
        },
        {
          title: t(wz6.title) as string,
          content: <WizardContent {...wz6} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <Grid gridDefinition={[{ colspan: { default: 12, xs: 6 } }, { colspan: { default: 12, xs: 6 } }]}>
                <WizardContent {...summaryContent2} />
              </Grid>
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
