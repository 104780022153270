import { useTranslation } from 'react-i18next';

import { SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { EndSection } from '../../common/EndSection';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz5.16.1',
  title: 'Chapters.Lesson16.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.16.1.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson16.Wizard1.content1',
    },
    {
      id: 'wz5.16.1.20',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson16.Wizard1.videoTitle1',
      youtubeId: 'lPo9n_LzYAI',
    },
    {
      id: 'wz5.16.1.30',
      widgetType: WizardContentType.HEADER,
      text: 'Chapters.Lesson16.Wizard1.headingTitle',
    },
    {
      id: 'wz5.16.1.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson16.Wizard1.content2',
    },
    {
      id: 'wz5.16.1.50',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        { id: 'wz5.16.1.50-1', text: 'Chapters.Lesson16.Wizard1.list1' },
        { id: 'wz5.16.1.50-2', text: 'Chapters.Lesson16.Wizard1.list2' },
        { id: 'wz5.16.1.50-3', text: 'Chapters.Lesson16.Wizard1.list3' },
        { id: 'wz5.16.1.50-4', text: 'Chapters.Lesson16.Wizard1.list4' },
        { id: 'wz5.16.1.50-5', text: 'Chapters.Lesson16.Wizard1.list5' },
        { id: 'wz5.16.1.50-6', text: 'Chapters.Lesson16.Wizard1.list6' },
      ],
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz2',
  title: 'Chapters.Lesson16.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz5.16.2.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson16.Wizard2.content1',
    },
  ],
};

export const Lesson16 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz2',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz5.16.2.30',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson16.Wizard2.trainModel',
      },
      {
        id: 'wz5.16.5.40',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson16.Wizard2.content4',
      },
      {
        id: 'summaryContentWz2.50',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <EndSection
            level="Chapters.Lesson16.Wizard2.level"
            timeRequire="Chapters.Lesson16.Wizard2.time"
            prereq="Chapters.Lesson16.Wizard2.prerequisites"
            onClick={onClick}
          />
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
