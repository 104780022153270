import { LEAGUE_START_TIME, MAX_RESULTS } from 'common/constants';
import { getIdToken } from 'store/utils/authUtils';
import { DeepRacerLiteService, DeepRacerLiteTypes } from '../../types/DeepRacerLiteTypes';
import ServiceProvider from '../ServiceProvider';
import { listAllItems } from './common';

/**
 * Get the lists all the Leaderboards
 * @param
 * @returns NextToken, LeaderboardList
 */
export const listLeaderboards = async (
  leagueType: DeepRacerLiteTypes.LeagueType = DeepRacerLiteTypes.LeagueType.LITE
): Promise<DeepRacerLiteTypes.ListLeaderboardsResponse> => {
  try {
    const listLeaderboardsRequest: DeepRacerLiteTypes.ListLeaderboardsRequest = {
      MaxResults: MAX_RESULTS,
      LeagueType: leagueType,
      CutoffTime: LEAGUE_START_TIME,
    };
    const listLeaderboardsResponse: DeepRacerLiteTypes.ListLeaderboardsResponse = {
      Leaderboards: [],
    };
    const result = await Promise.all([
      listAllItems(
        'listLeaderboards',
        {
          ...listLeaderboardsRequest,
          LeaderboardAccessRoles: [DeepRacerLiteTypes.LeaderboardAccessRole.PARTICIPANT],
        },
        { ...listLeaderboardsResponse },
        'Leaderboards'
      ),
    ]);
    result.map((value) => {
      if (value.Leaderboards.length) {
        listLeaderboardsResponse.Leaderboards.push(...value.Leaderboards);
      }
    });
    return listLeaderboardsResponse;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the Leaderboard with arn
 * @param LeaderboardArn
 * @returns Leaderboard
 */
export const getLeaderboard = async (leaderboardArn: string): Promise<DeepRacerLiteTypes.GetLeaderboardResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const getLeaderboardRequest: DeepRacerLiteTypes.GetLeaderboardRequest = {
      LeaderboardArn: leaderboardArn,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.getLeaderboard(getLeaderboardRequest, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the lists all the Leaderboards
 * @param
 * @returns NextToken, LeaderboardList
 */
export const listLeaderboardWinners = async (): Promise<DeepRacerLiteTypes.ListLeaderboardWinnersResponse> => {
  try {
    const listLeaderboardWinnersRequest: DeepRacerLiteTypes.ListLeaderboardWinnersRequest = {
      MaxResults: MAX_RESULTS,
      WinnerCutoffTime: Date.now(),
    };
    const listLeaderboardWinnersResponse: DeepRacerLiteTypes.ListLeaderboardWinnersResponse = {
      Recipients: [],
    };
    return listAllItems(
      'listLeaderboardWinners',
      listLeaderboardWinnersRequest,
      listLeaderboardWinnersResponse,
      'Recipients'
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Get the alias and other profile related info
 * @param
 * @returns GetAliasResponse
 */
export const getAlias = async (): Promise<DeepRacerLiteTypes.GetAliasResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const idToken = await getIdToken();
    return await deepRacerLiteService.getAlias(idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Gets the list of leaderboard evaluation jobs for a specific leaderboard
 * @param
 * @returns NextToken, LeaderboardEvaluationJobsList
 */
export const listLeaderboardEvaluationJobs = async (
  leaderboardArn: string
): Promise<DeepRacerLiteTypes.ListLeaderboardEvaluationsResponse> => {
  try {
    const listLeaderboardEvaluationJobsRequest: DeepRacerLiteTypes.ListLeaderboardEvaluationsRequest = {
      LeaderboardArn: leaderboardArn,
      MaxResults: MAX_RESULTS,
    };
    const listLeaderboardEvaluationJobsResponse: DeepRacerLiteTypes.ListLeaderboardEvaluationsResponse = {
      LeaderboardEvaluationJobs: [],
    };
    const result = await Promise.all([
      listAllItems(
        'listLeaderboardEvaluations',
        {
          ...listLeaderboardEvaluationJobsRequest,
        },
        { ...listLeaderboardEvaluationJobsResponse },
        'LeaderboardEvaluationJobs'
      ),
    ]);
    result.map((value) => {
      if (value.LeaderboardEvaluationJobs.length) {
        listLeaderboardEvaluationJobsResponse.LeaderboardEvaluationJobs.push(...value.LeaderboardEvaluationJobs);
      }
    });
    return listLeaderboardEvaluationJobsResponse;
  } catch (e) {
    return Promise.reject(e);
  }
};
