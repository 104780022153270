import { RootState } from 'store';

export const getCountrySeasonScoresError = (state: RootState) => state?.seasonScores?.countrySeasonScores?.error;
export const getCountrySeasonScoresIsLoading = (state: RootState) =>
  state?.seasonScores?.countrySeasonScores?.isLoading;
export const getCountrySeasonScores = (state: RootState) => state?.seasonScores?.countrySeasonScores?.item;
export const getGlobalSeasonScoresError = (state: RootState) => state?.seasonScores?.globalSeasonScores?.error;
export const getGlobalSeasonScoresIsLoading = (state: RootState) => state?.seasonScores?.globalSeasonScores?.isLoading;
export const getGlobalSeasonScores = (state: RootState) => state?.seasonScores?.globalSeasonScores?.item;
export const getRegionSeasonScoresError = (state: RootState) => state?.seasonScores?.regionSeasonScores?.error;
export const getRegionSeasonScoresIsLoading = (state: RootState) => state?.seasonScores?.regionSeasonScores?.isLoading;
export const getRegionSeasonScores = (state: RootState) => state?.seasonScores?.regionSeasonScores?.item;
