import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Alert, Box, Button, Container, Grid, Header, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';

import { RaceDetails } from 'pages/leaderboard/components/RaceDetails';
import pageCatalog, { DRLitePage, PageRouteParams } from 'pages/pageCatalog';
import { useGetAlias, useGetLeaderboard } from 'store/leaderboard';
import { useListModels } from 'store/model';
import {
  useCreateLeaderboardSubmission,
  useGetRankedUserSubmission,
} from 'store/leaderboardSubmission/leaderboardSubmissionQueries';
import { DeepRacerLiteTypes } from 'types';

import LeagueRequirements from './components/LeagueRequirements';
import SubmissionQuotaModal from './components/SubmissionQuotaModal';
import './style.css';

enum SelectStatusTypes {
  LOADING = 'loading',
  ERROR = 'error',
  FINISHED = 'finished',
}

enum AlertErrorCodes {
  NO_MODEL = 'Select a model',
  TC_NOT_ACCEPTED = 'Please accept terms and conditions',
  NO_COMPETITION_COUNTRY = 'Please select a country',
  NO_REMAINING_SUBMISSIONS = 'You have reached the submission quota and can no longer submit models to this race. Come back next month to compete again.',
}

export const EnterRace = () => {
  const { leaderboardArn } = useParams<PageRouteParams[DRLitePage.ENTER_RACE]>();
  const { t } = useTranslation('leaderboard');
  const history = useHistory();
  const [selectedModel, setSelectedModel] = useState<SelectProps.Option | null>(null);
  const { data: leaderboardData } = useGetLeaderboard(decodeURIComponent(leaderboardArn));
  const leaderboard = leaderboardData?.Leaderboard;
  const { data: modelsListData, isLoading: modelsIsLoading, isError: modelsError } = useListModels();
  const modelsList = modelsListData?.Models;
  const createLeaderboardSubmission = useCreateLeaderboardSubmission();
  const [modelListStatus, setModelListStatus] = useState<SelectProps['statusType']>('loading');
  const [alertVisibility, setAlertVisibility] = useState<boolean>(false);
  const [tcChecked, setTCChecked] = useState<boolean>(true);
  const [alertCode, setAlertCode] = useState<string>('');
  const [listOptions, setListOptions] = useState<SelectProps.Option[]>([]);
  const gridDefinition = [{ colspan: { default: 12, s: 4 } }, { colspan: { default: 12, s: 8 } }];
  const { data: userItem } = useGetAlias();
  const [competitionCountryCode, setCompetitionCountryCode] = useState<DeepRacerLiteTypes.CountryCode>(
    userItem?.CompetitionCountryCode ?? ('' as DeepRacerLiteTypes.CountryCode)
  );
  const [isSubmissionQuotaModalVisible, setIsSubmissionQuotaModalVisible] = useState<boolean>(false);
  const { data: rankedLeaderboardSubmission } = useGetRankedUserSubmission(decodeURIComponent(leaderboardArn));
  const submissionCount = rankedLeaderboardSubmission?.LeaderboardSubmission?.SubmissionCount || 0;
  const submissionsPerUser = leaderboardData?.Leaderboard?.SubmissionsPerUser || 0;
  const remainingSubmissions = submissionsPerUser - submissionCount;
  const showLeagueRequirementsComponent =
    leaderboard?.LeagueType === DeepRacerLiteTypes.LeagueType.LITE && !leaderboard?.TermsAccepted;

  const redirectToLeaderboard = useCallback(() => {
    if (leaderboard?.LeagueType === DeepRacerLiteTypes.LeagueType.COMMUNITY_LITE) {
      history.push(pageCatalog[DRLitePage.COMMUNITY_LEADERBOARD].getPath({ leaderboardArn }));
    } else {
      history.push(pageCatalog[DRLitePage.LEADERBOARD].getPath({ leaderboardArn }));
    }
  }, [history, leaderboard?.LeagueType, leaderboardArn]);

  const createLeaderboardSubmissionMutation = () => {
    createLeaderboardSubmission.mutate(
      {
        leaderboardArn: decodeURIComponent(leaderboardArn),
        modelArn: selectedModel?.value ?? '',
        termsAccepted: true,
        competitionCountryCode:
          leaderboard?.TermsConditionId && !leaderboard?.TermsAccepted && competitionCountryCode
            ? competitionCountryCode
            : null,
      },
      {
        onSuccess: () => {
          redirectToLeaderboard();
        },
      }
    );
  };

  useEffect(() => {
    if (leaderboard?.Status !== DeepRacerLiteTypes.LeaderboardStatus.OPEN) {
      redirectToLeaderboard();
    }
  }, [history, leaderboard, leaderboardArn, redirectToLeaderboard]);

  useEffect(() => {
    if (modelsIsLoading) {
      setModelListStatus(SelectStatusTypes.LOADING);
    } else if (modelsError) {
      setModelListStatus(SelectStatusTypes.ERROR);
    } else if (!modelsIsLoading && modelsList) {
      setModelListStatus(SelectStatusTypes.FINISHED);
    }

    if (modelsList?.length) {
      setListOptions(
        modelsList.map((model) => {
          if (model?.Status === DeepRacerLiteTypes.ModelStatus.READY) {
            return { label: model.ModelName, value: model.ModelArn };
          } else {
            return { label: model.ModelName, value: model.ModelArn, disabled: true };
          }
        })
      );
    }
  }, [modelsIsLoading, modelsError, modelsList]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <Grid gridDefinition={gridDefinition}>
        <RaceDetails leaderboard={leaderboard} />
        <Box variant="div">
          <SpaceBetween direction="vertical" size="xl">
            <Container
              header={<Header variant="h2">{t('chooseModelLabel', { leaderboardName: leaderboard?.Name })}</Header>}
            >
              <SpaceBetween direction="vertical" size="m">
                <p> {t('submitEnterRaceMessage')} </p>
                <Select
                  selectedOption={selectedModel}
                  onChange={({ detail }) => {
                    setAlertVisibility(false);
                    setSelectedModel(detail.selectedOption);
                  }}
                  options={listOptions}
                  selectedAriaLabel="Selected"
                  loadingText="Getting models"
                  placeholder="Choose a model"
                  className="modelsSelect"
                  empty="No models"
                  statusType={modelListStatus}
                />
              </SpaceBetween>
            </Container>
            {showLeagueRequirementsComponent && (
              <Box>
                <LeagueRequirements
                  leaderboard={leaderboard}
                  userCompetitionCountry={competitionCountryCode}
                  setCompeitionCountry={setCompetitionCountryCode}
                  isTermsChecked={tcChecked}
                  setIsTermsChecked={setTCChecked}
                  setIsAlertVisible={setAlertVisibility}
                />
              </Box>
            )}
            <Alert
              onDismiss={() => setAlertVisibility(false)}
              visible={alertVisibility}
              dismissAriaLabel="Close alert"
              type="error"
            >
              {alertCode}
            </Alert>
          </SpaceBetween>
        </Box>
      </Grid>
      <Box variant="div" className="bottomButtons">
        <Button variant="link" onClick={() => history.push(pageCatalog.Leaderboard.getPath({ leaderboardArn }))}>
          {t('cancelLabel')}
        </Button>
        <Button
          variant="primary"
          disabled={!selectedModel?.value}
          onClick={() => {
            if (selectedModel?.value && tcChecked && competitionCountryCode) {
              // since null submissionsPerUser defaults to 0, modal/error should never show pre season 2023
              if (submissionsPerUser === 0 || remainingSubmissions > 4) {
                createLeaderboardSubmissionMutation();
              } else if (remainingSubmissions > 0) {
                setIsSubmissionQuotaModalVisible(true);
              } else {
                setAlertVisibility(true);
                setAlertCode(AlertErrorCodes.NO_REMAINING_SUBMISSIONS);
              }
            } else {
              setAlertVisibility(true);
              if (!selectedModel?.value) {
                setAlertCode(AlertErrorCodes.NO_MODEL);
              } else if (!competitionCountryCode) {
                setAlertCode(AlertErrorCodes.NO_COMPETITION_COUNTRY);
              } else {
                setAlertCode(AlertErrorCodes.TC_NOT_ACCEPTED);
              }
            }
          }}
        >
          {t('enterRaceLabel')}
        </Button>
      </Box>
      {isSubmissionQuotaModalVisible && (
        <SubmissionQuotaModal
          onConfirm={() => {
            createLeaderboardSubmissionMutation();
            return true;
          }}
          onDismiss={() => {
            setIsSubmissionQuotaModalVisible(false);
          }}
          remainingSubmissions={remainingSubmissions}
          submissionLimit={leaderboard?.SubmissionsPerUser}
        />
      )}
    </SpaceBetween>
  );
};
