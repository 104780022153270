import { RootState } from 'store';

export const getTrainingJobError = (state: RootState) => state.trainingJob.trainingJob.error;
export const getTrainingJobIsLoading = (state: RootState) => state.trainingJob.trainingJob.isLoading;
export const getTrainingJob = (state: RootState) => state?.trainingJob?.trainingJob?.item;
export const getTrainingJobsError = (state: RootState) => state.trainingJob.trainingJobs.error;
export const getTrainingJobsIsLoading = (state: RootState) => state.trainingJob.trainingJobs.isLoading;
export const getTrainingJobs = (state: RootState) => state?.trainingJob?.trainingJobs?.items;
export const getStopTrainingJobError = (state: RootState) => state.trainingJob.stopTrainingJob.error;
export const getStopTrainingJobIsLoading = (state: RootState) => state.trainingJob.stopTrainingJob.isLoading;
export const getStopTrainingJob = (state: RootState) => state.trainingJob.stopTrainingJob.item;
