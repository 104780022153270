import { Formik, Form } from 'formik';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box, ColumnLayout, Header, SpaceBetween, Link } from '@amzn/awsui-components-react';
import { GetAliasResponse } from '@amzn/aws-deep-racer-lite-external-service-type-script-client';
import { CancelButton, SubmitButton } from 'common/components/FormButtons';
import Modal from 'common/components/Modal';
import { getCountry, getProfileIsLoading, handleUpdateProfile } from 'store/profile';
import { CompleteSignUpFields } from 'common/constants';
import { SelectFormField } from 'common/components/FormFields';
import { KeyValuePair } from 'common/components/KeyValuePair';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { getCountryDetails } from '@amzn/aws-deepracer-ui-components/utils/regionUtils';
import { COUNTRIES } from '@amzn/aws-deepracer-ui-components/data/countries';

interface CountryFieldProps {
  userData: GetAliasResponse | undefined | null;
}

export const Country: React.FC<CountryFieldProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const country = useAppSelector(getCountry);
  const isLoading = useAppSelector(getProfileIsLoading);

  const onDismiss = () => {
    setVisible(false);
  };

  const handleSubmit = (data) => {
    dispatch(handleUpdateProfile({ profileData: { country: data[CompleteSignUpFields.COUNTRY].value } })).then(
      onDismiss
    );
  };
  const countryOption = useMemo(
    () => getCountryDetails(props?.userData?.CompetitionCountryCode || country),
    [props.userData, country]
  );

  const initialValues = {
    [CompleteSignUpFields.COUNTRY]: countryOption,
  };
  const validationSchema = Yup.object().shape({
    [CompleteSignUpFields.COUNTRY]: Yup.object().required(t('required')),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          header={
            <Header description={t('ProfilePage.WhatIsYourNewCountry')}>
              {t('ProfilePage.EditCountryOfResidency')}
            </Header>
          }
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <CancelButton onClick={onDismiss}>{t('cancel')}</CancelButton>
                <SubmitButton variant="primary" loading={isLoading}>
                  {t('save')}
                </SubmitButton>
              </SpaceBetween>
            </Box>
          }
        >
          <SelectFormField
            label={t('CompleteSignUp.countryLabel')}
            placeholder={t('CompleteSignUp.countryLabel')}
            options={COUNTRIES}
            filteringType="auto"
            name={CompleteSignUpFields.COUNTRY}
          />
        </Modal>
        <ColumnLayout columns={2}>
          <KeyValuePair label={t('ProfilePage.CountryOfResidency')}>
            {countryOption?.label || t('emptyLabel')}
          </KeyValuePair>
          {!props?.userData?.CompetitionCountryCode && (
            <Link onFollow={() => setVisible(true)}>{t('ProfilePage.EditCountryOfResidency')}</Link>
          )}
        </ColumnLayout>
      </Form>
    </Formik>
  );
};
