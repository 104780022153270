export const MODEL_META_DATA =
  '{\n    "action_space": {\n        "steering_angle": {\n            "high": 30,\n            "low": -30\n        },\n        "speed": {\n            "high": 1,\n            "low": 0.5\n        }\n    },\n    "sensor": [\n        "FRONT_FACING_CAMERA"\n    ],\n    "neural_network": "DEEP_CONVOLUTIONAL_NETWORK_SHALLOW",\n    "version": "4",\n    "training_algorithm": "clipped_ppo",\n    "action_space_type": "continuous"\n}';
export const LEAGUE_2024_TERMS_AND_CONDITIONS_ID = 'TNC-2024-LITE-SEASON';
// 03/01/2024 00:00:00 AM UTC for prod T&C cut-off time
export const SEASON_2024_START_TIME = 1709251200000;
//  01/01/2024 or 01/01/2023 00:00:00 AM UTC (including pre-season) for pulling leaderboards
export const LEAGUE_START_TIME = 1704067200000;
export enum TRACK_DIRECTION {
  CLOCKWISE = 'Clockwise',
  COUNTER_CLOCKWISE = 'Counterclockwise',
}
