import Link, { LinkProps } from '@amzn/awsui-components-react/polaris/link';

interface ExternalLinkItemProps extends Pick<LinkProps, 'href' | 'onFollow' | 'color' | 'external'> {
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
  text: string;
}

export const externalLinkProps = {
  external: true,
  externalIconAriaLabel: 'Opens in a new tab',
};

export const InfoLink = ({ id, onFollow }) => (
  <Link variant="info" id={id} onFollow={onFollow}>
    Info
  </Link>
);

// a special case of external link, to be used within a link group, where all of them are external
// and we do not repeat the icon
export const ExternalLinkItem = ({
  dataAnalyticsLabel,
  dataAnalyticsTypeLabel,
  href,
  text,
  onFollow,
  color,
  external = true,
}: ExternalLinkItemProps) => (
  <Link
    data-analytics={dataAnalyticsLabel}
    data-analytics-type={dataAnalyticsTypeLabel}
    external={external}
    color={color}
    onFollow={onFollow}
    href={href}
    ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`}
    target="_blank"
  >
    {text}
  </Link>
);
