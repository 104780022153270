import Modal, { ModalProps } from '@amzn/awsui-components-react/polaris/modal';
import { APP_CONTENT_ID } from 'common/constants';

/**
 *
 * Wrapper of Polaris Modal with root set to Portal app.
 *
 */
export default (props: ModalProps): JSX.Element => {
  return <Modal modalRoot={document.getElementById(APP_CONTENT_ID) || undefined} {...props} />;
};
