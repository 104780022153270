import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@amzn/awsui-components-react/polaris/box';
import Container from '@amzn/awsui-components-react/polaris/container';

import LandingPage4 from 'common/assets/images/landingPage4.svg';
import './styles.css';

export interface ErrorViewProps {
  message?: string;
  onRetry?: () => void;
  title?: string;
}
/**
 * Generic error view component to display full page errors
 * @param props
 * @returns
 */
const ErrorView = (props: ErrorViewProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    message = t('errors.errorBoundary.defaultErrorMessage'),
    title = t('errors.errorBoundary.defaultErrorTitle'),
  } = props;
  return (
    <Box variant="div" margin="l" textAlign="center" className="errorContainer">
      <Container>
        <Box textAlign="center">
          {/* ToDo update to correct image */}
          <img className="image" src={LandingPage4} />
        </Box>
        <Box fontSize="heading-l" textAlign="center" variant="h1" data-nodeId="ErrorViewTitle">
          {title}
        </Box>
        <Box fontSize="body-m" textAlign="center" variant="p" data-nodeId="ErrorViewMessage">
          {message}
        </Box>
      </Container>
    </Box>
  );
};

export default ErrorView;
