/* istanbul ignore file */
import { SpaceBetween, Button, Box, Modal, Container, Spinner } from '@amzn/awsui-components-react';
import pageCatalog, { DRLitePage, PageRouteParams } from 'pages/pageCatalog';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DR_LITE_SCHOLARSHIP_ASSESSMENT_ID } from 'services/constants';
import {
  clearFinishAssessmentTracker,
  clearQuestion,
  fetchAssessmentTracker,
  fetchCurrentQuestion,
  fetchNextQuestion,
  fetchPrevQuestion,
  finishAssessmentTracker,
  getFinishAssessmentTracker,
  getFinishAssessmentTrackerIsLoading,
  getQuestion,
  getQuestionIsLoading,
  postAnswer,
} from 'store/lmsQuiz';
import { getOptedInEsperanza, getProfileData } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { DeepRacerLiteTypes } from 'types';
import QuizCard from './components/QuizCard';
import QuizNavigation from './components/QuizNavigation';

const AssessmentQuiz = () => {
  const { t } = useTranslation('assessment', { keyPrefix: 'assessmentQuiz' });
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { assessmentTrackerId } = useParams<PageRouteParams[DRLitePage.ASSESSMENT_QUIZ]>();
  const [isExitModalVisible, setIsExitModalVisible] = useState(false);
  const [isTimeUpModalVisible, setIsTimeUpModalVisible] = useState(false);
  const [isSubmitConfirmationModalVisible, setIsSubmitConfirmationModalVisible] = useState(false);
  const [currentAnswer, setcurrentAnswer] = useState<DeepRacerLiteTypes.StringList>([]);

  const questionData = useAppSelector(getQuestion);
  const questionIsLoading = useAppSelector(getQuestionIsLoading);
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  const profileData = useAppSelector(getProfileData);
  const finishAssessmentTrackerData = useAppSelector(getFinishAssessmentTracker);
  const finishAssessmentTrackerIsLoading = useAppSelector(getFinishAssessmentTrackerIsLoading);
  const [listenFinishAssessmentTracker, setListenFinishAssessmentTracker] = useState(false);

  // redirect logic if successful finishAssessmentTracker
  useEffect(() => {
    // only if successful finishAssessmentTrackerData api call
    if (finishAssessmentTrackerData) {
      // if exit modal is open, close it
      if (isExitModalVisible) {
        setIsExitModalVisible(false);
      }
      // if submit modal is open, close it
      if (isSubmitConfirmationModalVisible) {
        setIsSubmitConfirmationModalVisible(false);
      }
      // clear finishAssessmentTracker redux data
      dispatch(clearFinishAssessmentTracker());
      // redirect to Quiz result page with 500ms delay
      if (listenFinishAssessmentTracker) {
        setTimeout(() => history.push(pageCatalog.AssessmentResult.getPath()), 500);
      }
    }
  }, [
    dispatch,
    finishAssessmentTrackerData,
    history,
    isExitModalVisible,
    isSubmitConfirmationModalVisible,
    listenFinishAssessmentTracker,
  ]);

  // redirect if not opted in esperanza
  useEffect(() => {
    if (!optedInEsperanza && profileData) {
      history.push(pageCatalog[DRLitePage.LEARNING_HOME].getPath());
    }
  }, [history, optedInEsperanza, profileData]);

  useEffect(() => {
    if (questionData?.selectedAnswer) {
      setcurrentAnswer([...questionData.selectedAnswer]);
    }
  }, [questionData]);

  // fetch question from server on mount
  useEffect(() => {
    dispatch(
      fetchAssessmentTracker({
        assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
        assessmentTrackerId,
      })
    );
    dispatch(
      fetchCurrentQuestion({
        assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
        assessmentTrackerId,
      })
    );
    return () => {
      dispatch(clearQuestion());
    };
  }, [assessmentTrackerId, dispatch]);

  const onExitQuiz = () => {
    setListenFinishAssessmentTracker(true);
    dispatch(
      finishAssessmentTracker({
        assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
        assessmentTrackerId,
      })
    );
  };

  const onSubmitQuiz = () => {
    setListenFinishAssessmentTracker(true);
    dispatch(
      finishAssessmentTracker({
        assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
        assessmentTrackerId,
      })
    );
  };

  if (questionIsLoading) {
    return (
      <SpaceBetween size="l">
        <Container>
          {t('loadingText')} <Spinner />
        </Container>
      </SpaceBetween>
    );
  }

  const isNextDisableOverride = currentAnswer.length === 0;

  return (
    <div>
      <SpaceBetween size="l">
        {questionData && (
          <QuizCard
            header={`Question ${questionData?.questionNo}/30`}
            question={questionData}
            onChange={(value) => setcurrentAnswer([value])}
          />
        )}
        <div style={{ float: 'right' }}>
          {questionData && (
            <QuizNavigation
              nextDisableOverride={isNextDisableOverride}
              question={questionData}
              onExit={() => setIsExitModalVisible(true)}
              onPrev={() => {
                dispatch(
                  postAnswer({
                    assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
                    assessmentTrackerId,
                    questionNo: questionData.questionNo,
                    answers: currentAnswer,
                  })
                );
                dispatch(
                  fetchPrevQuestion({
                    assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
                    assessmentTrackerId,
                  })
                );
                setcurrentAnswer([]);
              }}
              onNext={() => {
                dispatch(
                  postAnswer({
                    assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
                    assessmentTrackerId,
                    questionNo: questionData.questionNo,
                    answers: currentAnswer,
                  })
                );
                dispatch(
                  fetchNextQuestion({
                    assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
                    assessmentTrackerId,
                  })
                );
                setcurrentAnswer([]);
              }}
              onSubmit={() => {
                dispatch(
                  postAnswer({
                    assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
                    assessmentTrackerId,
                    questionNo: questionData.questionNo,
                    answers: currentAnswer,
                  })
                );
                setIsSubmitConfirmationModalVisible(true);
              }}
            />
          )}
        </div>
      </SpaceBetween>
      <Modal
        onDismiss={() => setIsExitModalVisible(false)}
        visible={isExitModalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={onExitQuiz} loading={finishAssessmentTrackerIsLoading}>
                {t('exitModal.exitButtonLabel')}
              </Button>
              <Button variant="primary" onClick={() => setIsExitModalVisible(false)}>
                {t('exitModal.resumeButtonLabel')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t('exitModal.header')}
      >
        <Trans>{t('exitModal.content')}</Trans>
      </Modal>
      <Modal
        onDismiss={() => setIsTimeUpModalVisible(false)}
        visible={isTimeUpModalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => history.push(pageCatalog[DRLitePage.ASSESSMENT_RESULT].getPath())}>
                {t('timeUpModal.cancelButtonLabel')}
              </Button>
              <Button
                variant="primary"
                onClick={() => history.push(pageCatalog[DRLitePage.ASSESSMENT_START].getPath({ assessmentTrackerId }))}
              >
                {t('timeUpModal.startOverButtonLabel')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t('timeUpModal.header')}
      >
        {t('timeUpModal.content')}
      </Modal>
      <Modal
        onDismiss={() => setIsSubmitConfirmationModalVisible(false)}
        visible={isSubmitConfirmationModalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={() => setIsSubmitConfirmationModalVisible(false)}>
                {t('submitModal.reviewButtonLabel')}
              </Button>
              <Button variant="primary" onClick={onSubmitQuiz} loading={finishAssessmentTrackerIsLoading}>
                {t('submitModal.submitButtonLabel')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t('submitModal.header')}
      >
        {t('submitModal.content')}
      </Modal>
    </div>
  );
};

export default AssessmentQuiz;
