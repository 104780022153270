/* eslint-disable no-case-declarations */
/* istanbul ignore file */
import {
  Badge,
  Box,
  Button,
  Container,
  Header,
  Link,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import pageCatalog, { DRLitePage } from 'pages/pageCatalog';
import { getOptedInEsperanza, useGetScholarshipQualificationProgress } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { toggleConfettiRunning } from 'store/confetti';
import {
  QualificationProgress,
  RequirementProgress,
} from '@amzn/aws-deep-racer-lite-external-service-type-script-client';
import { AI_ML_SCHOLARSHIP_LANDING_DOCS, UDACITY_ESPERANZA_CONSOLE } from 'common/constants';

export const ScholarshipTracker = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const optedInEsperanza = useAppSelector(getOptedInEsperanza);
  const { data: ScholarshipQualificationData } = useGetScholarshipQualificationProgress();
  const Scholarship2022Data = ScholarshipQualificationData?.Scholarship2022;

  // logic to toggle confetti
  useEffect(() => {
    if (Scholarship2022Data?.QualificationProgress === QualificationProgress.QUALIFIED) {
      setTimeout(() => {
        dispatch(
          toggleConfettiRunning({
            isRunning: false,
          })
        );
      }, 10000);
      dispatch(
        toggleConfettiRunning({
          isRunning: true,
        })
      );
      () => {
        dispatch(
          toggleConfettiRunning({
            isRunning: false,
          })
        );
      };
    }
  }, [Scholarship2022Data, dispatch]);
  // renders top badge
  const getStatus = () => {
    switch (Scholarship2022Data?.QualificationProgress) {
      case QualificationProgress.NOT_QUALIFIED:
        return (
          <div>
            <Badge color="blue">
              <b>Available now</b>
            </Badge>
          </div>
        );
      case QualificationProgress.QUALIFIED:
        return (
          <div>
            <Badge color="green">
              <b>Prequalified</b>
            </Badge>
          </div>
        );
      case QualificationProgress.NOT_ELIGIBLE:
        return (
          <div>
            <Badge color="grey">
              <b>Not prequalified for 2024</b>
            </Badge>
          </div>
        );
      default:
        return (
          <div>
            <Badge color="grey">
              <b>Evaluating</b>
            </Badge>
          </div>
        );
    }
  };
  // renders header for steps
  const getStepsHeader = () => {
    switch (Scholarship2022Data?.QualificationProgress) {
      case QualificationProgress.QUALIFIED:
        return (
          <div>
            <p style={{ marginTop: '0px' }}>
              <b>You prequalified for the</b>&nbsp;
              <Link external onFollow={() => window.open(AI_ML_SCHOLARSHIP_LANDING_DOCS, '_blank')}>
                AWS AI & ML scholarship
              </Link>
              <Link></Link>
            </p>
          </div>
        );
      case QualificationProgress.NOT_QUALIFIED:
        return (
          <div>
            <p style={{ marginTop: '4px', marginBottom: '8px' }}>
              <b>How do I qualify for a scholarship?</b>
            </p>
          </div>
        );
      case QualificationProgress.NOT_ELIGIBLE:
        return (
          <div>
            <p style={{ margin: '0px' }}>
              <b>What can I do now?</b>
            </p>
            <p style={{ margin: '0px' }}>
              Come back next year and try for the 2024 scholarship round. Meanwhile, continue on your learning journey.
            </p>
          </div>
        );
      default:
        return <></>;
    }
  };
  // renders content
  const getStepsContent = () => {
    if (Scholarship2022Data?.QualificationProgress === QualificationProgress.NOT_ELIGIBLE) {
      return (
        <ul style={{ listStyle: 'none', padding: '0px' }}>
          <li style={{ marginBottom: '8px' }}>
            <Link onFollow={() => history.push(pageCatalog[DRLitePage.LEARNING_MODULE_1].getPath())}>
              Introduction to machine learning
            </Link>{' '}
          </li>
          <li>
            {' '}
            <Link onFollow={() => history.push(pageCatalog[DRLitePage.LEARNING_MODULE_2].getPath())}>
              Introduction to reinforcement learning
            </Link>
          </li>
        </ul>
      );
    }
    if (Scholarship2022Data?.QualificationProgress === QualificationProgress.QUALIFIED) {
      return (
        <div>
          <p style={{ marginBottom: '0px', marginTop: '0px' }}>
            <b>Next steps?</b>
          </p>
          <ul style={{ paddingLeft: '16px', marginTop: '0px' }}>
            <li>
              Visit{' '}
              <Link external onFollow={() => window.open(UDACITY_ESPERANZA_CONSOLE, '_blank')}>
                Udacity
              </Link>{' '}
              and use your one time code:{' '}
              <span className="custom-wrapping">
                <Box margin={{ right: 'xxs' }} display="inline-block">
                  <Popover
                    size="small"
                    position="top"
                    triggerType="custom"
                    dismissButton={false}
                    content={<StatusIndicator type="success">Udacity code copied</StatusIndicator>}
                  >
                    <Button
                      variant="inline-icon"
                      iconName="copy"
                      onClick={() => navigator.clipboard.writeText(Scholarship2022Data?.QualificationCode || '')}
                    />
                  </Popover>
                </Box>
                {Scholarship2022Data?.QualificationCode}
              </span>
            </li>
            <li>Udacity will contact winners by email</li>
          </ul>
        </div>
      );
    }
    const getCompletedLearningModules = () => {
      switch (Scholarship2022Data?.CompletedLearningModules) {
        case RequirementProgress.INCOMPLETE:
          return <StatusIndicator type="info">Not started</StatusIndicator>;
        case RequirementProgress.IN_PROGRESS:
          return <StatusIndicator type="in-progress">In progress</StatusIndicator>;
        case RequirementProgress.COMPLETE:
          return <StatusIndicator type="success">Completed</StatusIndicator>;
        default:
          return <StatusIndicator type="loading">Calculating</StatusIndicator>;
      }
    };
    const getCompletedQuiz = () => {
      switch (Scholarship2022Data?.CompletedQuiz) {
        case RequirementProgress.INCOMPLETE:
          return <StatusIndicator type="info">Not started</StatusIndicator>;
        case RequirementProgress.IN_PROGRESS:
          const remainingAttempts = 3 - Scholarship2022Data?.QuizAttemptsUsed;
          return (
            <StatusIndicator type="in-progress">
              {remainingAttempts} {remainingAttempts > 1 ? 'attempts' : 'attempt'} left
            </StatusIndicator>
          );
        case RequirementProgress.COMPLETE:
          return <StatusIndicator type="success">Completed</StatusIndicator>;
        default:
          return <StatusIndicator type="loading">Calculating</StatusIndicator>;
      }
    };
    const getCompletedModelTraining = () => {
      switch (Scholarship2022Data?.CompletedModelPerformance) {
        case RequirementProgress.COMPLETE:
          return <StatusIndicator type="success">Passed</StatusIndicator>;
        case RequirementProgress.INCOMPLETE:
          return <StatusIndicator type="info">Not started</StatusIndicator>;
        case RequirementProgress.IN_PROGRESS:
          return <StatusIndicator type="in-progress">In progress</StatusIndicator>;
        default:
          return <StatusIndicator type="loading">Calculating</StatusIndicator>;
      }
    };
    return (
      <SpaceBetween size="xs">
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '4px' }}>
          <p style={{ margin: '0px' }}>
            Complete all&nbsp;
            <Link onFollow={() => history.push(pageCatalog[DRLitePage.LEARNING_HOME].getPath())}>learning modules</Link>
            &nbsp;(recommended)
          </p>
          {getCompletedLearningModules()}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '4px' }}>
          <p style={{ margin: '0px' }}>
            Take&nbsp;
            <Link onFollow={() => history.push(pageCatalog[DRLitePage.ASSESSMENT_RESULT].getPath())}>
              AWS AI & ML Scholarship quiz
            </Link>
            &nbsp;and score at least 24/30
          </p>
          {getCompletedQuiz()}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '4px' }}>
          <p style={{ margin: '0px' }}>
            <Link onFollow={() => history.push(pageCatalog.CreateModel.getPath())}>Train a model</Link>
            &nbsp;to complete 1 lap in the student league under 2 minutes.
          </p>
          {getCompletedModelTraining()}
        </div>
      </SpaceBetween>
    );
  };
  return (
    <Container
      className="student-container-height"
      header={
        <Header>
          {optedInEsperanza ? 'Track your scholarship tasks' : 'Enroll in the scholarship program'}
          {getStatus()}
        </Header>
      }
    >
      {optedInEsperanza ? (
        <SpaceBetween size={'xxxs'}>
          <Box>
            Complete your scholarship qualification tasks and submit your application to Udacity by September 30, 2024.
          </Box>
          {getStepsHeader()}
          {getStepsContent()}
        </SpaceBetween>
      ) : (
        <SpaceBetween size={'l'}>
          <Box>
            Opt in and complete your scholarship prequalification tasks and submit your application to Udacity by
            September 30, 2024.
          </Box>
          <SpaceBetween size={'xxs'}>
            <Box fontWeight="bold">How do I enroll for the scholarship program?</Box>
            <Box>
              Enroll by selecting the opt-in check box found in your profile.{' '}
              <Link onFollow={() => history.push(pageCatalog[DRLitePage.PROFILE].getPath())}>Get started.</Link>
            </Box>
          </SpaceBetween>
        </SpaceBetween>
      )}
    </Container>
  );
};
