import { Box, Container, Header, Link } from '@amzn/awsui-components-react';
import pageCatalog from 'pages/pageCatalog';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function NextSteps() {
  const history = useHistory();
  const { t } = useTranslation('modelDetails');
  const nextStepsStrings = t('nextSteps.steps');

  return (
    <Container header={<Header variant="h2">{t('nextSteps.containerHeader')}</Header>}>
      <Box variant="p">{t('nextSteps.contentHeader')}</Box>
      <ul style={{ paddingLeft: '16px' }}>
        <li>
          <Box fontWeight="bold">{nextStepsStrings[0].header}</Box>
          <Box variant="p">
            <Trans components={[<Link onFollow={() => history.push(pageCatalog.ModelsList.getPath())} />]}>
              {nextStepsStrings[0].content}
            </Trans>
          </Box>
        </li>
        <li>
          <Box fontWeight="bold">{nextStepsStrings[1].header}</Box>
          <Box variant="p">
            <Trans>{nextStepsStrings[1].content}</Trans>
          </Box>
        </li>
      </ul>
    </Container>
  );
}

export default NextSteps;
