import { CompleteSignUp } from './profile';
import { ContactUs } from './contactus';
import { EnterRace } from './enterRace';
import { FeatureVertical } from 'types/FeatureVertical';
import { ImproveModel } from './improveModel';
import { IntroToML, IntroToRL, WizardPage } from './learningModule';
import { lazy } from 'react';
import { ModelDetails } from './modelDetails';
import { ModelsList } from './modelsList';
import { Page } from 'types/page';
import { SignInPage, SignUpPage, VerifyEmailPage, ForgotPasswordRequestPage, ForgotPasswordResetPage } from './auth';
import { StudentHome } from './home';
import { LearnModulePageId } from 'common/constants/learningModule/pageIds';
const Account = lazy(() => import('./account'));
const AssessmentQuiz = lazy(() => import('./assessment/AssessmentQuiz'));
const AssessmentResult = lazy(() => import('./assessment/AssessmentResult'));
const AssessmentStart = lazy(() => import('./assessment/AssessmentStart'));
const CloneModel = lazy(() => import('./cloneModel'));
const CommunityRaces = lazy(() => import('./communityRaces'));
const CreateModel = lazy(() => import('./createModel'));
const Exploration = lazy(() => import('./exploration'));
const Leaderboard = lazy(() => import('./leaderboard'));
const LearnOverview = lazy(() => import('./learnOverview'));
const Profile = lazy(() => import('./profile'));
const RaceToken = lazy(() => import('./raceToken'));
const SeasonPointsLeaderboard = lazy(() => import('./seasonPointsLeaderboard'));
const StudentLeague = lazy(() => import('./studentLeague'));

export enum DynamicRouteId {
  LEADERBOARD = 'Leaderboard',
  LEAGUE = 'League',
  MODEL = 'Model',
  SEASON_POINTS_LEADERBOARD = 'SeasonPontsLeaderboard',
}

/**
 * All pages for DeepRacer Lite portal.
 */
export enum DRLitePage {
  ACCOUNT = 'Account',
  ASSESSMENT_QUIZ = 'AssessmentQuiz',
  ASSESSMENT_RESULT = 'AssessmentResult',
  ASSESSMENT_START = 'AssessmentStart',
  CLONE_MODEL = 'CloneModel',
  COMMUNITY_ENTER = 'CommunityRacesEnter',
  COMMUNITY_LANDING = 'CommunityRaces',
  COMMUNITY_LEADERBOARD = 'CommunityRacesLeaderboard',
  COMPLETE_SIGN_UP = 'CompleteSignUp',
  CONTACT_US = 'ContactUs',
  CREATE_MODEL = 'CreateModel',
  ENTER_RACE = 'EnterRace',
  EXPLORATION = 'Exploration',
  FORGOT_PASSWORD_REQUEST = 'ForgotPasswordRequest',
  FORGOT_PASSWORD_RESET = 'ForgotPasswordReset',
  IMPROVE_MODEL = 'ImproveModel',
  LEADERBOARD = 'Leaderboard',
  LEARNING_HOME = 'Learn',
  LEARNING_MODULE_1 = 'LearningModule1',
  LEARNING_MODULE_2 = 'LearningModule2',
  LEARNING_MODULE_WIZARD = 'LearningModuleWizard',
  MODEL = 'Model',
  MODELS_LIST = 'ModelsList',
  PROFILE = 'Profile',
  RACE_TOKEN = 'RaceToken',
  SIGN_IN = 'SignIn',
  SIGN_UP = 'SignUp',
  STUDENT_HOME = 'StudentHome',
  STUDENT_LEAGUE = 'StudentLeague',
  VERIFY_EMAIL = 'VerifyEmail',
  SEASON_LEADERBOARD = 'SeasonPointsLeaderboard',
}

export interface PageRouteParams extends DRLiteRouteParams {
  [DRLitePage.ACCOUNT]: void;
  [DRLitePage.ASSESSMENT_QUIZ]: { assessmentTrackerId: string };
  [DRLitePage.ASSESSMENT_RESULT]: void;
  [DRLitePage.ASSESSMENT_START]: { assessmentTrackerId: string };
  [DRLitePage.CLONE_MODEL]: void;
  [DRLitePage.COMMUNITY_ENTER]: { leaderboardArn: string };
  [DRLitePage.COMMUNITY_LANDING]: void;
  [DRLitePage.COMMUNITY_LEADERBOARD]: { leaderboardArn: string };
  [DRLitePage.COMPLETE_SIGN_UP]: void;
  [DRLitePage.CONTACT_US]: void;
  [DRLitePage.CREATE_MODEL]: void;
  [DRLitePage.ENTER_RACE]: { leaderboardArn: string };
  [DRLitePage.EXPLORATION]: void;
  [DRLitePage.FORGOT_PASSWORD_REQUEST]: void;
  [DRLitePage.FORGOT_PASSWORD_RESET]: void;
  [DRLitePage.IMPROVE_MODEL]: void;
  [DRLitePage.LEADERBOARD]: { leaderboardArn: string };
  [DRLitePage.LEARNING_HOME]: void;
  [DRLitePage.LEARNING_MODULE_1]: void;
  [DRLitePage.LEARNING_MODULE_2]: void;
  [DRLitePage.LEARNING_MODULE_WIZARD]: { wizardId: string };
  [DRLitePage.MODEL]: { modelArn: string };
  [DRLitePage.MODELS_LIST]: void;
  [DRLitePage.PROFILE]: void;
  [DRLitePage.RACE_TOKEN]: { tokenId: string };
  [DRLitePage.SIGN_IN]: void;
  [DRLitePage.SIGN_UP]: void;
  [DRLitePage.STUDENT_HOME]: void;
  [DRLitePage.STUDENT_LEAGUE]: void;
  [DRLitePage.VERIFY_EMAIL]: void;
  [DRLitePage.SEASON_LEADERBOARD]: { seasonType: string };
}

type PageCatalog = {
  [Key in DRLitePage]: Page<Key>;
};

type DRLiteRouteParams = {
  [Key in DRLitePage]: Record<string, string> | void;
};

const pageCatalog: PageCatalog = {
  [DRLitePage.MODELS_LIST]: new Page({
    component: ModelsList,
    route: '/modelsList',
    breadcrumbConfig: {
      parentRoute: DRLitePage.STUDENT_HOME,
      text: 'Models',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.CREATE_MODEL]: new Page({
    component: CreateModel,
    route: '/createModel',
    breadcrumbConfig: {
      parentRoute: DRLitePage.MODELS_LIST,
      text: 'Create model',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.CLONE_MODEL]: new Page({
    component: CloneModel,
    route: '/cloneModel',
    breadcrumbConfig: {
      parentRoute: DRLitePage.MODELS_LIST,
      text: 'Clone model',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.ENTER_RACE]: new Page({
    component: EnterRace,
    route: '/enterRace/:leaderboardArn',
    breadcrumbConfig: {
      text: 'Enter race',
      parentRoute: DRLitePage.LEADERBOARD,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.SEASON_LEADERBOARD]: new Page({
    component: SeasonPointsLeaderboard,
    route: '/league/:seasonType',
    breadcrumbConfig: {
      parentRoute: DRLitePage.STUDENT_LEAGUE,
      text: DynamicRouteId.SEASON_POINTS_LEADERBOARD,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.LEADERBOARD]: new Page({
    component: Leaderboard,
    route: '/leaderboard/:leaderboardArn',
    breadcrumbConfig: {
      parentRoute: DRLitePage.STUDENT_LEAGUE,
      text: 'Leaderboard',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.MODEL]: new Page({
    component: ModelDetails,
    route: '/model/:modelArn',
    breadcrumbConfig: {
      parentRoute: DRLitePage.MODELS_LIST,
      text: DynamicRouteId.MODEL,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.ACCOUNT]: new Page({
    component: Account,
    route: '/account',
    breadcrumbConfig: {
      parentRoute: DRLitePage.STUDENT_HOME,
      text: 'Account',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.PROFILE,
  }),
  [DRLitePage.PROFILE]: new Page({
    component: Profile,
    route: '/profile',
    breadcrumbConfig: {
      parentRoute: DRLitePage.STUDENT_HOME,
      text: 'Profile',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.PROFILE,
  }),
  [DRLitePage.LEARNING_HOME]: new Page({
    component: LearnOverview,
    route: '/learn',
    breadcrumbConfig: {
      parentRoute: DRLitePage.STUDENT_HOME,
      text: 'Learn',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.EVENTS,
  }),
  [DRLitePage.LEARNING_MODULE_1]: new Page({
    component: IntroToML,
    route: `/learn/${LearnModulePageId.INTRO_TO_ML}`,
    breadcrumbConfig: {
      parentRoute: DRLitePage.LEARNING_HOME,
      text: 'Introduction to machine learning',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.EVENTS,
  }),
  [DRLitePage.LEARNING_MODULE_2]: new Page({
    component: IntroToRL,
    route: `/learn/${LearnModulePageId.INTRO_TO_RL}`,
    breadcrumbConfig: {
      parentRoute: DRLitePage.LEARNING_HOME,
      text: 'Introduction to reinforcement learning',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.EVENTS,
  }),
  [DRLitePage.LEARNING_MODULE_WIZARD]: new Page({
    component: WizardPage,
    route: `/learn/chapter/:wizardId`,
    breadcrumbConfig: {
      parentRoute: DRLitePage.LEARNING_MODULE_1,
      text: 'chapter',
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.EVENTS,
  }),
  [DRLitePage.SIGN_IN]: new Page({
    component: SignInPage,
    route: '/signIn',
    navigationHide: true,
    disableContentPaddings: false,
    breadcrumbConfig: {
      text: '',
    },
    toolsHide: true,
    showNotification: true,
    vertical: FeatureVertical.AUTH,
  }),
  [DRLitePage.SIGN_UP]: new Page({
    component: SignUpPage,
    route: '/signUp',
    navigationHide: true,
    disableContentPaddings: false,
    breadcrumbConfig: {
      text: '',
    },
    toolsHide: true,
    showNotification: true,
    vertical: FeatureVertical.AUTH,
  }),
  [DRLitePage.VERIFY_EMAIL]: new Page({
    component: VerifyEmailPage,
    navigationHide: true,
    route: '/verifyEmail',
    disableContentPaddings: false,
    breadcrumbConfig: {
      text: '',
    },
    toolsHide: true,
    showNotification: true,
    requireAuth: false,
    vertical: FeatureVertical.AUTH,
  }),
  [DRLitePage.COMPLETE_SIGN_UP]: new Page({
    component: CompleteSignUp,
    navigationHide: true,
    disableContentPaddings: false,
    route: '/completeSignUp',
    breadcrumbConfig: {
      text: '',
    },
    requireAuth: true,
    toolsHide: false,
    showNotification: true,
    vertical: FeatureVertical.PROFILE,
  }),
  [DRLitePage.FORGOT_PASSWORD_REQUEST]: new Page({
    component: ForgotPasswordRequestPage,
    navigationHide: true,
    route: '/forgotPasswordRequest',
    disableContentPaddings: false,
    breadcrumbConfig: {
      text: '',
    },
    toolsHide: true,
    showNotification: true,
    vertical: FeatureVertical.AUTH,
  }),
  [DRLitePage.FORGOT_PASSWORD_RESET]: new Page({
    component: ForgotPasswordResetPage,
    navigationHide: true,
    route: '/forgotPasswordReset',
    disableContentPaddings: false,
    breadcrumbConfig: {
      text: '',
    },
    toolsHide: true,
    showNotification: true,
    vertical: FeatureVertical.AUTH,
  }),
  [DRLitePage.STUDENT_HOME]: new Page({
    component: StudentHome,
    route: '/home',
    breadcrumbConfig: {
      text: 'AWS DeepRacer Student',
      showBreadcrumbs: false,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.EVENTS,
  }),
  [DRLitePage.STUDENT_LEAGUE]: new Page({
    component: StudentLeague,
    navigationHide: false,
    route: '/league',
    breadcrumbConfig: {
      text: 'Student League',
      parentRoute: DRLitePage.STUDENT_HOME,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.CONTACT_US]: new Page({
    component: ContactUs,
    route: '/contact',
    breadcrumbConfig: {
      text: 'Contact us',
      parentRoute: DRLitePage.STUDENT_HOME,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: false,
    showNotification: true,
    vertical: FeatureVertical.EVENTS,
  }),
  [DRLitePage.IMPROVE_MODEL]: new Page({
    component: ImproveModel,
    route: '/modelsList/improveModel',
    breadcrumbConfig: {
      text: 'Improve your model',
      parentRoute: DRLitePage.MODELS_LIST,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.EXPLORATION]: new Page({
    component: Exploration,
    navigationHide: true,
    route: '/exploration',
    breadcrumbConfig: {
      text: 'exploration',
      showBreadcrumbs: false,
    },
    toolsHide: true,
    requireAuth: false,
    showNotification: false,
    disableContentPaddings: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.ASSESSMENT_START]: new Page({
    component: AssessmentStart,
    navigationHide: false,
    route: '/ai-ml-scholarship-quiz/:assessmentTrackerId/info',
    breadcrumbConfig: {
      text: 'AWS AI & ML Scholarship quiz', // todo: make dynamic for future quizzes
      parentRoute: DRLitePage.STUDENT_HOME,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.ASSESSMENT_RESULT]: new Page({
    component: AssessmentResult,
    navigationHide: false,
    route: '/ai-ml-scholarship-quiz',
    breadcrumbConfig: {
      text: 'AWS AI & ML Scholarship quiz', // todo: make dynamic for future quizzes
      parentRoute: DRLitePage.STUDENT_HOME,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.ASSESSMENT_QUIZ]: new Page({
    component: AssessmentQuiz,
    navigationHide: false,
    route: '/ai-ml-scholarship-quiz/:assessmentTrackerId/quiz',
    breadcrumbConfig: {
      text: 'Quiz',
      parentRoute: DRLitePage.ASSESSMENT_RESULT,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.COMMUNITY_LANDING]: new Page({
    component: CommunityRaces,
    navigationHide: false,
    route: '/communityLeaderboards',
    breadcrumbConfig: {
      text: 'Community races',
      parentRoute: DRLitePage.STUDENT_HOME,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.COMMUNITY_LEADERBOARD]: new Page({
    component: Leaderboard,
    route: '/communityLeaderboard/:leaderboardArn',
    breadcrumbConfig: {
      parentRoute: DRLitePage.COMMUNITY_LANDING,
      text: DynamicRouteId.LEADERBOARD,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.COMMUNITY_ENTER]: new Page({
    component: EnterRace,
    route: '/communityEnterRace/:leaderboardArn',
    breadcrumbConfig: {
      text: 'Enter race',
      parentRoute: DRLitePage.COMMUNITY_LEADERBOARD,
      showBreadcrumbs: true,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
  [DRLitePage.RACE_TOKEN]: new Page({
    component: RaceToken,
    route: '/raceToken/:tokenId',
    breadcrumbConfig: {
      text: 'Race Token',
      parentRoute: DRLitePage.COMMUNITY_LANDING,
      showBreadcrumbs: false,
    },
    toolsHide: false,
    requireAuth: true,
    showNotification: true,
    vertical: FeatureVertical.DR,
  }),
};

export default pageCatalog;
