import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { InputFormField } from 'common/components/FormFields';
import { VALID_DELETE_ACCOUNT_PATTERN, APP_CONTENT_ID } from 'common/constants';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import pageCatalog from 'pages/pageCatalog';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { deleteProfile, getProfileIsLoading } from 'store/profile';
import { getAuthIsLoading } from 'store/auth';
import { displayErrorNotification, displaySuccessNotification } from 'store/notifications';

interface DeleteAccountModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const initialValues = { text: '' };
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isAuthLoading = useAppSelector(getAuthIsLoading);
  const isProfileLoading = useAppSelector(getProfileIsLoading);

  const handleDeleteAccount = async () => {
    await dispatch(deleteProfile())
      .then(unwrapResult)
      .then(() => {
        setIsOpen(false);
        dispatch(displaySuccessNotification({ content: t('DeleteAccountModal.SuccessMessage') }));
        history.push({
          pathname: pageCatalog.SignIn.getPath(),
        });
      })
      .catch((error) => {
        setIsOpen(false);
        dispatch(displayErrorNotification({ content: error.message }));
      });
  };

  const validationSchema = Yup.object().shape({
    text: Yup.string()
      .required(t('required'))
      .matches(VALID_DELETE_ACCOUNT_PATTERN, t('DeleteAccountModal.DeleteField')),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleDeleteAccount} validationSchema={validationSchema}>
      <Modal
        modalRoot={document.getElementById(APP_CONTENT_ID) || undefined}
        onDismiss={() => setIsOpen(false)}
        visible={isOpen}
        closeAriaLabel={t('DeleteAccountModal.CloseModal')}
        size="medium"
        header={t('DeleteAccountModal.DeleteAccountHeader')}
      >
        <Box fontWeight="bold" margin={{ bottom: 'l' }}>
          {t('DeleteAccountModal.DeleteAccountDesc')}
        </Box>

        <Form>
          <Box>
            <SpaceBetween size="m">
              <InputFormField
                label={t('DeleteAccountModal.DeleteAccountInputHeader')}
                placeholder={t('DeleteAccountModal.Delete')}
                name="text"
                type="text"
              />
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" onClick={() => setIsOpen(false)}>
                    {t('DeleteAccountModal.Cancel')}
                  </Button>
                  <Button loading={isAuthLoading || isProfileLoading} variant="primary" formAction="submit">
                    {t('DeleteAccountModal.Delete')}
                  </Button>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </Box>
        </Form>
      </Modal>
    </Formik>
  );
};
