import Grid from '@amzn/awsui-components-react/polaris/grid';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { optionTypeLabels } from '@amzn/react-avataaar';
import { Form, useFormikContext } from 'formik';

import { SelectFormField } from 'common/components/FormFields';
import { AvatarConfigDataOption, AvatarConfigSelectOptionMap } from 'common/constants';
import {
  accessoriesOptions,
  clothingColorOptions,
  clothingOptions,
  convertSelectOptionsToAvatarConfig,
  eyebrowOptions,
  eyeOptions,
  facialHairColorOptions,
  facialHairOptions,
  hairColorOptions,
  hatColorOptions,
  isAccessoriesEnabled,
  isClothingColorEnabled,
  isDefaultAvatar as getIsDefaultAvatar,
  isFacialHairColorEnabled,
  isFacialHairEnabled,
  isGraphicShirt,
  isHairColorEnabled,
  isHatColorEnabled,
  mouthOptions,
  skinColorOptions,
  topOptions,
  tShirtGraphicOptions,
} from 'common/utils/avatar';
import { useHandleAvatarOptionChange } from '../hooks/useHandleAvatarOptionChange';

const AvatarConfigDialogForm: React.FC = () => {
  const { values: formSelectOptions } = useFormikContext<AvatarConfigSelectOptionMap>();
  const { handleClothingChange, handleFacialHairChange, handleTopChange } = useHandleAvatarOptionChange();

  const formValues = convertSelectOptionsToAvatarConfig(formSelectOptions);
  const isDefaultAvatar = getIsDefaultAvatar(formValues.top);

  return (
    <Form>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } },
          { colspan: { default: 12, xs: 4 } },
        ]}
      >
        <SpaceBetween size="l">
          <SelectFormField
            label={optionTypeLabels.skinColorLabel}
            name={AvatarConfigDataOption.SKIN_COLOR}
            disabled={isDefaultAvatar}
            options={skinColorOptions}
          />
          <SelectFormField
            label={optionTypeLabels.topLabel}
            name={AvatarConfigDataOption.TOP}
            options={topOptions}
            onChange={handleTopChange}
          />
          <SelectFormField
            disabled={isDefaultAvatar || !isHairColorEnabled(formValues.top)}
            label={optionTypeLabels.hairColorLabel}
            name={AvatarConfigDataOption.HAIR_COLOR}
            options={hairColorOptions}
          />
          <SelectFormField
            disabled={isDefaultAvatar || !isHatColorEnabled(formValues.top)}
            label={optionTypeLabels.hatColorLabel}
            name={AvatarConfigDataOption.HAT_COLOR}
            options={hatColorOptions}
          />
          <SelectFormField
            disabled={isDefaultAvatar || !isAccessoriesEnabled(formValues.top)}
            label={optionTypeLabels.accessoriesLabel}
            name={AvatarConfigDataOption.ACCESSORIES}
            options={accessoriesOptions}
          />
        </SpaceBetween>
        <SpaceBetween size="l">
          <SelectFormField
            disabled={isDefaultAvatar || !isFacialHairEnabled(formValues.top)}
            label={optionTypeLabels.facialHairLabel}
            name={AvatarConfigDataOption.FACIAL_HAIR}
            options={facialHairOptions}
            onChange={handleFacialHairChange}
          />
          <SelectFormField
            disabled={isDefaultAvatar || !isFacialHairColorEnabled(formValues.top, formValues.facialHair)}
            label={optionTypeLabels.facialHairColorLabel}
            name={AvatarConfigDataOption.FACIAL_HAIR_COLOR}
            options={facialHairColorOptions}
          />
          <SelectFormField
            disabled={isDefaultAvatar}
            label={optionTypeLabels.eyeLabel}
            name={AvatarConfigDataOption.EYES}
            options={eyeOptions}
          />
          <SelectFormField
            disabled={isDefaultAvatar}
            label={optionTypeLabels.eyebrowLabel}
            name={AvatarConfigDataOption.EYEBROWS}
            options={eyebrowOptions}
          />
        </SpaceBetween>
        <SpaceBetween size="l">
          <SelectFormField
            disabled={isDefaultAvatar}
            label={optionTypeLabels.clothingLabel}
            name={AvatarConfigDataOption.CLOTHING}
            options={clothingOptions}
            onChange={handleClothingChange}
          />
          <SelectFormField
            disabled={isDefaultAvatar || !isClothingColorEnabled(formValues.clothing)}
            label={optionTypeLabels.clothingColorLabel}
            name={AvatarConfigDataOption.CLOTHING_COLOR}
            options={clothingColorOptions}
          />
          <SelectFormField
            disabled={isDefaultAvatar || !isGraphicShirt(formValues.clothing)}
            label={optionTypeLabels.graphicLabel}
            name={AvatarConfigDataOption.TSHIRT_GRAPHIC}
            options={tShirtGraphicOptions}
          />
          <SelectFormField
            disabled={isDefaultAvatar}
            label={optionTypeLabels.mouthLabel}
            name={AvatarConfigDataOption.MOUTH}
            options={mouthOptions}
          />
        </SpaceBetween>
      </Grid>
    </Form>
  );
};

export default AvatarConfigDialogForm;
