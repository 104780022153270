import type { LearningModulePageProps } from 'types/LearningModuleTypes';
import { LearningModuleHomeData } from 'common/constants/LearningModuleHomeCardData';
import { LEARN_LESSON2_HELP_PANEL_URL } from 'common/constants/urls';
import { IntroToRLChapterId, IntroToRLChapterTitle } from 'common/constants/learningModule/introToRL';

export const IntroToRLPageData: LearningModulePageProps = {
  ...LearningModuleHomeData[1],
  description: 'LearnOverview.IntroToRLDesc',
  helpId: LEARN_LESSON2_HELP_PANEL_URL,
  sections: [
    {
      title: 'IntroToRLChapters.getStarted',
      chaptersCard: [
        {
          cardActionId: IntroToRLChapterId.CHAPTER_1,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson13.description',
          id: IntroToRLChapterId.CHAPTER_1,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_1,
          duration: 'Chapters.Lesson13.duration',
          isLoading: false,
        },
        {
          cardActionId: IntroToRLChapterId.CHAPTER_2,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson14.description',
          id: IntroToRLChapterId.CHAPTER_2,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_2,
          duration: 'Chapters.Lesson14.duration',
          isLoading: false,
        },
        {
          cardActionId: IntroToRLChapterId.CHAPTER_3,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson15.description',
          id: IntroToRLChapterId.CHAPTER_3,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_3,
          duration: 'Chapters.Lesson15.duration',
          isLoading: false,
        },
      ],
    },
    {
      title: 'IntroToRLChapters.RLwithDeepRacerHeading',
      chaptersCard: [
        {
          cardActionId: IntroToRLChapterId.CHAPTER_4,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson16.description',
          id: IntroToRLChapterId.CHAPTER_4,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_4,
          duration: 'Chapters.Lesson16.duration',
          isLoading: false,
        },
        {
          cardActionId: IntroToRLChapterId.CHAPTER_5,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson17.description',
          id: IntroToRLChapterId.CHAPTER_5,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_5,
          duration: 'Chapters.Lesson17.duration',
          isLoading: false,
        },
      ],
    },
    {
      title: 'IntroToRLChapters.deepDives',
      chaptersCard: [
        {
          cardActionId: IntroToRLChapterId.CHAPTER_6,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson18.description',
          id: IntroToRLChapterId.CHAPTER_6,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_6,
          duration: 'Chapters.Lesson18.duration',
          isLoading: false,
        },
        {
          cardActionId: IntroToRLChapterId.CHAPTER_7,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson19.description',
          id: IntroToRLChapterId.CHAPTER_7,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_7,
          duration: 'Chapters.Lesson19.duration',
          isLoading: false,
        },
        {
          cardActionId: IntroToRLChapterId.CHAPTER_8,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson20.description',
          id: IntroToRLChapterId.CHAPTER_8,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_8,
          duration: 'Chapters.Lesson20.duration',
          isLoading: false,
        },
        {
          cardActionId: IntroToRLChapterId.CHAPTER_9,
          cardActionLabel: 'IntroToRLChapters.startChapter',
          chapterDescription: 'Chapters.Lesson21.description',
          id: IntroToRLChapterId.CHAPTER_9,
          chapterTitle: IntroToRLChapterTitle.CHAPTER_9,
          duration: 'Chapters.Lesson21.duration',
          isLoading: false,
        },
      ],
    },
  ],
};
