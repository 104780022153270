import { DeepRacerLiteTypes } from 'types';

export const isModelInTerminalState = (status: any) => {
  if (!status) return true;
  return status === DeepRacerLiteTypes.ModelStatus.READY || status === DeepRacerLiteTypes.ModelStatus.ERROR;
};

export const isJobInTerminalState = (status: any) => {
  if (!status) return true;
  return (
    status === DeepRacerLiteTypes.ActivityJobStatus.COMPLETED || status === DeepRacerLiteTypes.ActivityJobStatus.FAILED
  );
};

export const isJobPreInProgressState = (status: any) => {
  if (!status) return false;
  return (
    status === DeepRacerLiteTypes.ActivityJobStatus.INITIALIZING ||
    status === DeepRacerLiteTypes.ActivityJobStatus.CREATED
  );
};

export const isJobPostInProgressState = (status: any) => {
  if (!status) return false;
  return status === DeepRacerLiteTypes.ActivityJobStatus.STOPPING;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getTrackImage = async (arn: string) => {
  const id = arn.split('track/')[1];
  return await import(`common/assets/images/tracks/${id.toLowerCase()}.png`)
    .then((image) => {
      return image.default;
    })
    .catch((e) => {
      return null;
    });
};
