import React from 'react';
import FormField, { FormFieldProps } from '@amzn/awsui-components-react/polaris/form-field';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { useField } from 'formik';

interface SelectFormWrapperProps extends Omit<SelectProps, 'selectedOption'> {
  name: string;
  validate?: (value: any) => undefined | string | Promise<any>;
}

type SelectFormFieldPropTypes = FormFieldProps & SelectFormWrapperProps;

export const SelectFormFieldView: React.FC<SelectFormFieldPropTypes> = ({
  description,
  constraintText,
  label,
  stretch,
  onChange,
  onBlur,
  name,
  validate,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const handleChange: SelectProps['onChange'] = (event) => {
    helpers.setValue(event.detail.selectedOption);
    onChange?.(event);
  };

  const handleBlur: SelectProps['onBlur'] = (event) => {
    helpers.setTouched(true);
    onBlur?.(event);
  };

  const formFieldProps = {
    description,
    errorText: meta.touched && meta.error ? meta.error : null,
    constraintText,
    label,
    stretch,
  };

  const selectFieldProps = {
    ...field,
    ...otherProps,
    selectedOption: field.value,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <FormField {...formFieldProps}>
      <Select {...selectFieldProps} />
    </FormField>
  );
};

export const SelectFormField = React.memo(SelectFormFieldView);
