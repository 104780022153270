import { FieldHelperProps } from 'formik';
import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import { CompleteSignUpFields, OTHER_VAL, UNKNOWN_OPTION, SELF_IDENTIFY_OPTION } from 'common/constants';
import { AsteroidTypes } from 'types';

export const isSelfIdentified = (selectedOptions: readonly MultiselectProps.Option[]) =>
  selectedOptions.some((option) => option.value === SELF_IDENTIFY_OPTION.value);

export const isNotDisclosed = (selectedOptions: readonly MultiselectProps.Option[]) =>
  selectedOptions.some((option) => option.value === UNKNOWN_OPTION.value);

export const onMultiselectChange = (
  selectedOptions: readonly MultiselectProps.Option[],
  helper: FieldHelperProps<any>
) => {
  if (isNotDisclosed(selectedOptions)) {
    helper.setValue([UNKNOWN_OPTION]);
  }
  if (isSelfIdentified(selectedOptions)) {
    helper.setValue([SELF_IDENTIFY_OPTION]);
  }
};

export const convertSelectionsToStringList = (
  selectedOptions: readonly MultiselectProps.Option[],
  valueEntered?: string
): string[] | null => {
  if (selectedOptions.length === 0 || isNotDisclosed(selectedOptions)) {
    return null;
  }
  if (isSelfIdentified(selectedOptions)) {
    return valueEntered ? [valueEntered] : null;
  }
  return selectedOptions.map((option) => option.value as string);
};

export const convertEsperanzaFormDataToUpdateProfileRequest = (data): AsteroidTypes.UpdateMyProfileRequest => {
  return {
    addPrograms: [AsteroidTypes.Program.ESPERANZA_2024],
    raceList: convertSelectionsToStringList(data[CompleteSignUpFields.RACE], data[CompleteSignUpFields.RACE_ENTERED]),
    genderList: convertSelectionsToStringList(
      data[CompleteSignUpFields.GENDER],
      data[CompleteSignUpFields.GENDER_ENTERED]
    ),
  };
};

export const convertFormDataToUpdateProfileRequest = (data): AsteroidTypes.UpdateMyProfileRequest => {
  let school = data[CompleteSignUpFields.SCHOOL_ENTERED];
  if (data[CompleteSignUpFields.SCHOOL].value !== OTHER_VAL) {
    school = data[CompleteSignUpFields.SCHOOL].value;
  }
  let major = data[CompleteSignUpFields.MAJOR_ENTERED];
  if (data[CompleteSignUpFields.MAJOR].value !== OTHER_VAL) {
    major = data[CompleteSignUpFields.MAJOR].value;
  }
  const profileData: AsteroidTypes.UpdateMyProfileRequest = {
    firstName: data[CompleteSignUpFields.FIRSTNAME],
    middleName: data[CompleteSignUpFields.MIDDLENAME] || null,
    lastName: data[CompleteSignUpFields.LASTNAME],
    schoolDetails: {
      schoolName: school,
      academicMajor: major,
      yearOfGraduation: data[CompleteSignUpFields.YEAR_OF_GRAD] === '' ? null : data[CompleteSignUpFields.YEAR_OF_GRAD],
    },
    country: data[CompleteSignUpFields.COUNTRY].value,
  };

  let esperanzaData = {};
  if (data[CompleteSignUpFields.OPT_IN]) {
    esperanzaData = convertEsperanzaFormDataToUpdateProfileRequest(data);
  }
  return { ...profileData, ...esperanzaData };
};
