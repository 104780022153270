import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  LEAGUE_2024_ASTEROID_COUNTRY_BASED_CATEGORY,
  LEAGUE_2024_ASTEROID_GLOBAL_BASED_CATEGORY,
  LEAGUE_2024_ASTEROID_REGION_BASED_CATEGORY,
  SEASON_SCORES_SLICE,
  SeasonScoresAction,
  emptyResource,
} from 'common/constants';
import { getPublicChallengeGlobalScores } from 'services/asteroidPublic';
import { displayErrorNotification } from 'store/notifications';
import { AsyncResource } from 'store/types';
import { addLoadingCasesWithNestedState } from 'store/utils/loadingCases';
import { AsteroidPublicTypes } from 'types';

export interface SeasonScoresState {
  countrySeasonScores: AsyncResource<AsteroidPublicTypes.GetChallengeGlobalScoresResponse>;
  regionSeasonScores: AsyncResource<AsteroidPublicTypes.GetChallengeGlobalScoresResponse>;
  globalSeasonScores: AsyncResource<AsteroidPublicTypes.GetChallengeGlobalScoresResponse>;
}

const initialState: SeasonScoresState = {
  countrySeasonScores: emptyResource,
  regionSeasonScores: emptyResource,
  globalSeasonScores: emptyResource,
};

export const fetchCountrySeasonScores = createAsyncThunk<
  AsteroidPublicTypes.GetChallengeGlobalScoresResponse | undefined,
  { challengeId: string; profileId?: string }
>(SeasonScoresAction.GET_COUNTRY_SEASON_SCORES, async ({ challengeId, profileId }, { dispatch }) => {
  try {
    return await getPublicChallengeGlobalScores(LEAGUE_2024_ASTEROID_COUNTRY_BASED_CATEGORY, challengeId, profileId);
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const fetchRegionSeasonScores = createAsyncThunk<
  AsteroidPublicTypes.GetChallengeGlobalScoresResponse | undefined,
  { challengeId: string; profileId?: string }
>(SeasonScoresAction.GET_REGION_SEASON_SCORES, async ({ challengeId, profileId }, { dispatch }) => {
  try {
    return await getPublicChallengeGlobalScores(LEAGUE_2024_ASTEROID_REGION_BASED_CATEGORY, challengeId, profileId);
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const fetchGlobalSeasonScores = createAsyncThunk<
  AsteroidPublicTypes.GetChallengeGlobalScoresResponse | undefined,
  { challengeId: string; profileId?: string }
>(SeasonScoresAction.GET_GLOBAL_SEASON_SCORES, async ({ challengeId, profileId }, { dispatch }) => {
  try {
    return await getPublicChallengeGlobalScores(LEAGUE_2024_ASTEROID_GLOBAL_BASED_CATEGORY, challengeId, profileId);
  } catch (error: any) {
    dispatch(displayErrorNotification({ content: error?.message, autoDismiss: true }));
    throw error;
  }
});

export const seasonScoresSlice = createSlice({
  name: SEASON_SCORES_SLICE,
  initialState,
  reducers: {
    clearCountrySeasonScores: (state) => {
      state.countrySeasonScores = emptyResource;
    },
    clearGlobalSeasonScores: (state) => {
      state.globalSeasonScores = emptyResource;
    },
    clearRegionSeasonScores: (state) => {
      state.regionSeasonScores = emptyResource;
    },
  },
  extraReducers: (builder) => {
    addLoadingCasesWithNestedState(
      builder,
      SeasonScoresAction.GET_COUNTRY_SEASON_SCORES,
      SEASON_SCORES_SLICE,
      'countrySeasonScores'
    );
    addLoadingCasesWithNestedState(
      builder,
      SeasonScoresAction.GET_GLOBAL_SEASON_SCORES,
      SEASON_SCORES_SLICE,
      'globalSeasonScores'
    );
    addLoadingCasesWithNestedState(
      builder,
      SeasonScoresAction.GET_REGION_SEASON_SCORES,
      SEASON_SCORES_SLICE,
      'regionSeasonScores'
    );
  },
});

export const { clearCountrySeasonScores, clearGlobalSeasonScores, clearRegionSeasonScores } = seasonScoresSlice.actions;

export default seasonScoresSlice.reducer;
