import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Header, Container } from '@amzn/awsui-components-react';
import Button from '@amzn/awsui-components-react/polaris/button';

import pageCatalog, { DRLitePage } from 'pages/pageCatalog';
import { useAppSelector } from 'store/utils/hooks';
import { getFirstName } from 'store/profile';
import '../styles.css';

export const IntroToML = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'HomePage.IntroToML' });
  const history = useHistory();
  const userEmail = useAppSelector(getFirstName);

  return (
    <Container
      data-analytics="introToMl-Container"
      data-analytics-type="eventContext"
      className="student-container-height"
      header={<Header>{t('Heading', { userEmail })}</Header>}
    >
      <Box variant="h4" margin={{ bottom: 's' }}>
        {t('Title')}
      </Box>
      <Box variant="p" margin={{ bottom: 's' }}>
        {t('Message')}
      </Box>
      <Button
        data-analytics="chapters-Button"
        data-analytics-type="eventDetail"
        variant="primary"
        onClick={() => history.push(pageCatalog[DRLitePage.LEARNING_MODULE_1].getPath())}
      >
        {t('Button')}
      </Button>
    </Container>
  );
};
