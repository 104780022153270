import Button, { ButtonProps } from '@amzn/awsui-components-react/polaris/button';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { downloadAsset } from 'common/utils/s3';
import { displayErrorNotification } from 'store/notifications';
import { useAppDispatch } from 'store/utils/hooks';

interface AssetDownloadButtonProps extends ButtonProps {
  arn: string;
}

const AssetDownloadButton: React.FC<AssetDownloadButtonProps> = ({ arn, children, ...props }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDownloadClick = useCallback(async () => {
    try {
      setIsDownloading(true);
      await downloadAsset(arn);
    } catch (e: any) {
      dispatch(displayErrorNotification({ content: e?.message, autoDismiss: true }));
    } finally {
      setIsDownloading(false);
    }
  }, [arn, dispatch]);

  return (
    <Button
      data-analytics="download-Button"
      data-analytics-type="eventDetail"
      loading={isDownloading}
      onClick={handleDownloadClick}
      {...props}
    >
      {children ?? t('download')}
    </Button>
  );
};

export default AssetDownloadButton;
