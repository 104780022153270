import { Trans, useTranslation } from 'react-i18next';

import { Button, Grid, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz1.7.1',
  title: 'Chapters.Lesson7.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz1.7.1.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard1.content1',
    },
    {
      id: 'wz1.7.1.20',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '550px',
      width: '100%',
      title: 'Chapters.Lesson7.Wizard1.title',
      youtubeId: '7x6fG0bA5q8',
    },
    {
      id: 'wz1.7.1.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard1.content2',
    },
  ],
};

const wz2: WizardStepsProp = {
  id: 'wz2.7.2',
  title: 'Chapters.Lesson7.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz2.7.2.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard2.content1',
    },
    {
      id: 'wz2.7.2.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard2.content2',
    },
    {
      id: 'wz2.7.2.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard2.content3',
    },
    {
      id: 'wz2.7.2.40',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz2.7.2.40-1',
          text: 'Chapters.Lesson7.Wizard2.list1',
        },
        {
          id: 'wz2.7.2.40-2',
          text: 'Chapters.Lesson7.Wizard2.list2',
        },
      ],
    },
  ],
};

const wz3: WizardStepsProp = {
  id: 'wz3.7.3',
  title: 'Chapters.Lesson7.Wizard3.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz3.7.3.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard3.content1',
    },
    {
      id: 'wz3.7.3.20',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard3.heading1',
    },
    {
      id: 'wz3.7.3.30',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz3.7.3.30-1',
          text: 'Chapters.Lesson7.Wizard3.list1',
        },
        {
          id: 'wz3.7.3.30-2',
          text: 'Chapters.Lesson7.Wizard3.list2',
        },
      ],
    },
    {
      id: 'wz3.7.3.40',
      widgetType: WizardContentType.HEADER,
      variant: 'h2',
      text: 'Chapters.Lesson7.Wizard3.heading2',
    },
    {
      id: 'wz3.7.3.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard3.heading3',
    },
    {
      id: 'wz3.7.3.60',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz3.7.3.60-1',
          text: 'Chapters.Lesson7.Wizard3.list3',
        },
        {
          id: 'wz3.7.3.60-2',
          text: 'Chapters.Lesson7.Wizard3.list4',
        },
        {
          id: 'wz3.7.3.60-3',
          text: 'Chapters.Lesson7.Wizard3.list5',
        },
      ],
    },
    {
      id: 'wz3.7.3.60',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard3.content2',
    },
  ],
};

const wz4: WizardStepsProp = {
  id: 'wz4.7.4',
  title: 'Chapters.Lesson7.Wizard4.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz4.7.4.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard4.content1',
    },
    {
      id: 'wz4.7.4.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ol',
      bulletList: [
        {
          id: 'wz4.7.4.20-1',
          text: 'Chapters.Lesson7.Wizard4.list1',
        },
        {
          id: 'wz4.7.4.20-2',
          text: 'Chapters.Lesson7.Wizard4.list2',
        },
        {
          id: 'wz4.7.4.20-3',
          text: 'Chapters.Lesson7.Wizard4.list3',
        },
        {
          id: 'wz4.7.4.20-3',
          text: 'Chapters.Lesson7.Wizard4.list4',
        },
      ],
    },
    {
      id: 'wz4.7.4.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard4.content2',
    },
  ],
};

const wz5: WizardStepsProp = {
  id: 'wz5.7.5',
  title: 'Chapters.Lesson7.Wizard5.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz5.7.5.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard5.content1',
    },
    {
      id: 'wz3.7.5.20',
      widgetType: WizardContentType.P_BODY,
      variant: 'h2',
      text: 'Chapters.Lesson7.Wizard5.heading1',
    },
    {
      id: 'wz5.7.5.30',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard5.content2',
    },
    {
      id: 'wz3.7.3.40',
      widgetType: WizardContentType.P_BODY,
      variant: 'h2',
      text: 'Chapters.Lesson7.Wizard5.heading2',
    },
    {
      id: 'wz5.7.5.50',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard5.content3',
    },
    {
      id: 'wz3.7.5.60',
      widgetType: WizardContentType.P_BODY,
      variant: 'h2',
      text: 'Chapters.Lesson7.Wizard5.heading3',
    },
    {
      id: 'wz5.7.5.70',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard5.content4',
    },
    {
      id: 'wz5.7.5.80',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz5.7.5.80-1',
          text: 'Chapters.Lesson7.Wizard5.list1',
        },
        {
          id: 'wz5.7.5.80-2',
          text: 'Chapters.Lesson7.Wizard5.list2',
        },
        {
          id: 'wz5.7.5.80-3',
          text: 'Chapters.Lesson7.Wizard5.list3',
        },
        {
          id: 'wz5.7.5.80-3',
          text: 'Chapters.Lesson7.Wizard5.list4',
        },
      ],
    },
    {
      id: 'wz5.7.5.90',
      widgetType: WizardContentType.P_BODY,
      variant: 'h2',
      text: 'Chapters.Lesson7.Wizard5.heading4',
    },
    {
      id: 'wz5.7.5.100',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz5.7.5.100-1',
          text: 'Chapters.Lesson7.Wizard5.list5',
        },
        {
          id: 'wz5.7.5.100-2',
          text: 'Chapters.Lesson7.Wizard5.list6',
        },
      ],
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz6',
  title: 'Chapters.Lesson7.Wizard6.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz6.6.7.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson7.Wizard6.content1',
    },
    {
      id: 'wz6.6.7.20',
      widgetType: WizardContentType.BULLET,
      bulletType: 'ul',
      bulletList: [
        {
          id: 'wz6.6.7.20-1',
          text: 'Chapters.Lesson7.Wizard6.list1',
        },
        {
          id: 'wz6.6.7.20-2',
          text: 'Chapters.Lesson7.Wizard6.list2',
        },
        {
          id: 'wz6.6.7.20-3',
          text: 'Chapters.Lesson7.Wizard6.list3',
        },
        {
          id: 'wz6.6.7.20-4',
          text: 'Chapters.Lesson7.Wizard6.list4',
        },
        {
          id: 'wz6.6.7.20-5',
          text: 'Chapters.Lesson7.Wizard6.list5',
        },
      ],
    },
  ],
};

export const Lesson7 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz7',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz7.7.7.40',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson7.Wizard6.heading2',
      },
      {
        id: 'wz7.7.7.50',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson7.Wizard6.content2',
      },
      {
        id: 'summaryContent2.wz1.5.1.003',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <Button variant="normal" onClick={onClick}>
            <Trans i18nKey="WizardI18nStrings.StartChapter"></Trans>
          </Button>
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(wz2.title) as string,
          content: <WizardContent {...wz2} />,
        },
        {
          title: t(wz3.title) as string,
          content: <WizardContent {...wz3} />,
        },
        {
          title: t(wz4.title) as string,
          content: <WizardContent {...wz4} />,
        },
        {
          title: t(wz5.title) as string,
          content: <WizardContent {...wz5} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <Grid gridDefinition={[{ colspan: { default: 12, xs: 6 } }, { colspan: { default: 12, xs: 6 } }]}>
                <WizardContent {...summaryContent2} />
              </Grid>
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
