const SECONDS_IN_HOUR = 3600;

export const padNumber = (num: number, minDigits: number): string => {
  return new Intl.NumberFormat('iso', { minimumIntegerDigits: minDigits }).format(num);
};

export const secondsToHoursFormat = (
  totalTimeInSeconds: number,
  omitZeroHours = false,
  omitSeconds = false
): string => {
  let seconds = Math.floor(totalTimeInSeconds);
  const hours = Math.floor(seconds / SECONDS_IN_HOUR);
  seconds %= SECONDS_IN_HOUR;
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  const hoursPrefix = hours === 0 && omitZeroHours ? '' : padNumber(hours, 2) + ':';
  if (omitSeconds) {
    return hoursPrefix + padNumber(minutes, 2);
  }
  return hoursPrefix + padNumber(minutes, 2) + ':' + padNumber(seconds, 2);
};

export const millisToTimeUnits = (millis: number, omitZeroHours = false, omitSeconds = false): string => {
  const ms = millis % 1000;
  if (omitSeconds) {
    return `${secondsToHoursFormat(Math.floor(millis / 1000), omitZeroHours, omitSeconds)}`;
  }
  return `${secondsToHoursFormat(Math.floor(millis / 1000), omitZeroHours, omitSeconds)}.${padNumber(ms, 3)}`;
};

export const canEnterRace = (startTime: number | null | undefined, endTime: number | null | undefined) => {
  const currentTime = Date.now();
  if (startTime && endTime) {
    return startTime <= currentTime && endTime >= currentTime;
  }
  return false;
};

export const secondsToMilliseconds = (seconds: number): number => {
  return (seconds ?? 0) * 1000;
};
