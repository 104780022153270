import React from 'react';
import type { RouteProps } from 'react-router-dom';
import type { DRLitePage, PageRouteParams } from 'pages/pageCatalog';
import type { FeatureVertical } from 'types/FeatureVertical';

export interface RouteBreadCrumbConfig {
  parentRoute?: DRLitePage;
  showBreadcrumbs?: boolean;
  text?: string;
}

interface PageDefinition<T extends DRLitePage> {
  breadcrumbConfig?: RouteBreadCrumbConfig;
  component: React.LazyExoticComponent<React.ComponentType<any>> | (() => JSX.Element);
  disableContentPaddings?: boolean;
  navigationHide?: boolean;
  navigationOpen?: boolean;
  requireAuth?: boolean;
  route: string;
  routeProps: RouteProps;
  showNotification?: boolean;
  toolsHide?: boolean;
  vertical: FeatureVertical;
  getPath: (params: PageRouteParams[T]) => string;
}

export interface PageConstructorParams<T extends DRLitePage>
  extends Omit<PageDefinition<T>, 'getPath' | 'routeProps'> {}

export class Page<T extends DRLitePage> implements PageDefinition<T> {
  readonly component: React.LazyExoticComponent<React.ComponentType<any>> | (() => JSX.Element);

  readonly routeProps: RouteProps;

  readonly route: string;

  readonly breadcrumbConfig: RouteBreadCrumbConfig;

  readonly navigationOpen: boolean;

  readonly toolsHide: boolean;

  readonly navigationHide: boolean;

  readonly disableContentPaddings: boolean;

  readonly requireAuth: boolean;

  readonly showNotification: boolean;

  readonly vertical: FeatureVertical;

  constructor({
    breadcrumbConfig = {},
    component,
    disableContentPaddings = false,
    navigationHide = false,
    navigationOpen = false,
    requireAuth = false,
    route,
    toolsHide = true,
    showNotification = true,
    vertical,
  }: PageConstructorParams<T>) {
    this.component = component;
    this.routeProps = {
      path: route,
      exact: !route.includes('?'),
    };
    this.route = route;
    this.breadcrumbConfig = breadcrumbConfig;
    this.navigationOpen = navigationOpen;
    this.toolsHide = toolsHide;
    this.navigationHide = navigationHide;
    this.disableContentPaddings = disableContentPaddings;
    this.requireAuth = requireAuth;
    this.showNotification = showNotification;
    this.vertical = vertical;
  }

  /**
   * @param params An object containing route params
   * @returns A path that is usable in history.push(path) or internal links/redirects
   */
  getPath(params: PageRouteParams[T]): string {
    let path = this.route;

    if (!params) {
      return path;
    }

    Object.keys(params).forEach((param: string) => {
      path = path.replace(`:${param}`, encodeURIComponent(params[param]));
    });

    return path;
  }
}
