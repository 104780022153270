import { useTranslation } from 'react-i18next';

import { Box, Link, SpaceBetween, Wizard } from '@amzn/awsui-components-react';

import { WizardContent } from '../../common/WizardContent';
import { WizardStepsProp, WizardContentType, WizardStepType } from 'types/LearningModuleTypes';
import { EndSection } from '../../common/EndSection';
import { TFuncKey } from 'i18next';

const wz1: WizardStepsProp = {
  id: 'wz4.15.1',
  title: 'Chapters.Lesson15.Wizard1.title',
  wizardStepType: WizardStepType.WIZARD_STEP,
  contentData: [
    {
      id: 'wz4.15.1.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson15.Wizard1.content1',
    },
    {
      id: 'wz4.15.1.20',
      widgetType: WizardContentType.YOUTUBE_VIDEO,
      height: '500px',
      width: '100%',
      title: 'Chapters.Lesson15.Wizard1.videoTitle1',
      youtubeId: 'eSc_Hi9LZeg',
    },
    {
      id: 'wz4.15.1.30',
      widgetType: WizardContentType.HEADER,
      text: 'Chapters.Lesson15.Wizard1.headingTitle',
    },
    {
      id: 'wz4.15.1.40',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson15.Wizard1.content2',
    },
    {
      id: 'wz4.15.1.50',
      widgetType: WizardContentType.P_BODY_CUSTOM,
      children: () => (
        <Box variant="p">
          To learn more about Hexapawn, you can find the original article written by AI researcher Martin Gardner here:
          “How to build a game-learning machine and then teach it to play and win” by Martin Gardner, Scientific
          American:&nbsp;
          <Link external target="_blank" href="http://cs.williams.edu/~freund/cs136-073/GardnerHexapawn.pdf">
            http://cs.williams.edu/~freund/cs136-073/GardnerHexapawn.pdf
          </Link>
          .
        </Box>
      ),
    },
  ],
};

const summaryContent1: WizardStepsProp = {
  id: 'summaryContent1.wz5',
  title: 'Chapters.Lesson15.Wizard2.title',
  wizardStepType: WizardStepType.WIZARD_LAST_STEP,
  contentData: [
    {
      id: 'wz4.15.5.10',
      widgetType: WizardContentType.P_BODY,
      text: 'Chapters.Lesson15.Wizard2.content1',
    },
  ],
};

export const Lesson15 = ({ activeStepIndex, onCancel, onNavigate, onSubmit, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const summaryContent2: WizardStepsProp = {
    id: 'summaryContent2.wz8',
    title: '' as TFuncKey,
    wizardStepType: WizardStepType.WIZARD_LAST_STEP,
    contentData: [
      {
        id: 'wz4.15.5.30',
        widgetType: WizardContentType.HEADER,
        variant: 'h2',
        text: 'Chapters.Lesson15.Wizard2.trainModel',
      },
      {
        id: 'wz4.15.5.40',
        widgetType: WizardContentType.P_BODY,
        text: 'Chapters.Lesson15.Wizard2.content4',
      },
      {
        id: 'summaryContentWz2.50',
        widgetType: WizardContentType.P_BODY_CUSTOM,
        children: () => (
          <EndSection
            level="Chapters.Lesson15.Wizard2.level"
            timeRequire="Chapters.Lesson15.Wizard2.time"
            prereq="Chapters.Lesson15.Wizard2.prerequisites"
            onClick={onClick}
          />
        ),
      },
    ],
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t('WizardI18nStrings.Section', { stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) => t('WizardI18nStrings.SectionOf', { stepNumber, stepsCount }),
        cancelButton: t('WizardI18nStrings.Cancel'),
        previousButton: t('WizardI18nStrings.Previous'),
        nextButton: t('WizardI18nStrings.Next'),
        submitButton: t('WizardI18nStrings.Finish'),
        optional: t('WizardI18nStrings.Optional'),
      }}
      onCancel={onCancel}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      activeStepIndex={activeStepIndex}
      steps={[
        {
          title: t(wz1.title) as string,
          content: <WizardContent {...wz1} />,
        },
        {
          title: t(summaryContent1.title) as string,
          content: (
            <SpaceBetween size="l">
              <WizardContent {...summaryContent1} />
              <WizardContent {...summaryContent2} />
            </SpaceBetween>
          ),
        },
      ]}
    />
  );
};
