import { useTranslation } from 'react-i18next';
import { Header } from '@amzn/awsui-components-react';
import { InfoLink } from 'common/components/HelpPanel';
import { COMMUNITY_RACES_LANDING_HELP_PANEL_URL } from 'common/constants';

export const PageHeader = (): JSX.Element => {
  const { t } = useTranslation('communityRaces', { keyPrefix: 'LandingPage' });
  return (
    <Header
      variant="h1"
      description={t('HeaderDesc')}
      info={<InfoLink helpId={COMMUNITY_RACES_LANDING_HELP_PANEL_URL} />}
    >
      {t('Header')}
    </Header>
  );
};
