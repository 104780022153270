import { getIdToken } from 'store/utils/authUtils';
import ServiceProvider from 'services/ServiceProvider';
import { AsteroidService, AsteroidTypes } from 'types';
/**
 * Gets the user's profile information
 *
 * @returns Profile data
 */
export const getMyProfile = async (): Promise<AsteroidTypes.ProfileData> => {
  try {
    const asteroidService: AsteroidService = ServiceProvider.getAsteroidService();
    const idToken = await getIdToken();
    const { profile } = await asteroidService.getMyProfile(idToken);
    return profile;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Updates the user's profile information
 *
 * @param profileData Updated profile information
 * @returns The updated profile
 */
export const updateMyProfile = async (
  profileData: AsteroidTypes.UpdateMyProfileRequest
): Promise<AsteroidTypes.ProfileData> => {
  try {
    const asteroidService: AsteroidService = ServiceProvider.getAsteroidService();
    const request: AsteroidTypes.UpdateMyProfileRequest = { ...profileData };
    const idToken = await getIdToken();
    const { profile } = await asteroidService.updateMyProfile(request, idToken);
    return profile;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Delete your account
 *
 * @returns DeleteMyProfileResponse
 */
export const deleteMyProfile = async (): Promise<void> => {
  try {
    const asteroidService: AsteroidService = ServiceProvider.getAsteroidService();
    const idToken = await getIdToken();
    return await asteroidService.deleteMyProfile(idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};
