import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Container, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
  BulletListProp,
  HeaderContentProp,
  ImageContentProp,
  KeyBulletPairsProps,
  PbodayCustomProp,
  PbodyContentProp,
  WizardContentType,
  WizardStepsProp,
  YoutubeContentProp,
} from 'types/LearningModuleTypes';
import YoutubeNoCookie from 'common/components/YoutubeNoCookie';
import './wizardStyles.css';

export const WizardContent = (props: WizardStepsProp) => {
  const {
    dataAnalyticsLabel = 'chapterLesson-Container',
    dataAnalyticsTypeLabel = 'eventContext',
    contentData,
  } = props;
  const { t } = useTranslation();

  const generateContent = (content, index) => {
    switch (content.widgetType) {
      case WizardContentType.P_BODY: {
        const { text } = content as PbodyContentProp;
        return (
          <Grid gridDefinition={[{ colspan: { default: 12, xs: 10 } }]} key={index}>
            <Box data-analytics="pBody-Box" data-analytics-type="eventDetail" variant="p" key={index}>
              <Trans key={`${content.id}-${index}`}>{text}</Trans>
            </Box>
          </Grid>
        );
      }
      case WizardContentType.KEY_BULLET_PAIRS: {
        const { bulletList } = content as KeyBulletPairsProps;
        if (!bulletList?.length) return null;
        const list = bulletList?.map((item) => (
          <Box key={item.id}>
            <p>
              <Trans>{item.title}</Trans>
            </p>
            <ul key={`ul-${item.id}`}>
              <li key={`li-${item.id}`}>
                <Trans>{item.text}</Trans>
              </li>
            </ul>
          </Box>
        ));
        return (
          <Grid gridDefinition={[{ colspan: { default: 12, xs: 10 } }]} key={index}>
            <Box key={index}>{list}</Box>
          </Grid>
        );
      }
      case WizardContentType.BULLET: {
        const { bulletList, bulletType } = content as BulletListProp;
        if (!bulletList?.length) return null;
        const list = bulletList?.map((item) => (
          <li key={item.id}>
            <Trans>{item.text}</Trans>
          </li>
        ));
        const compiledList = bulletType === 'ul' ? <ul key={index}>{list}</ul> : <ol key={index}>{list}</ol>;
        return (
          <Grid gridDefinition={[{ colspan: { default: 12, xs: 10 } }]} key={index}>
            <Box data-analytics="bullet-Box" data-analytics-type="eventDetail">
              {compiledList}
            </Box>
          </Grid>
        );
      }
      case WizardContentType.YOUTUBE_VIDEO: {
        const { height, youtubeId, width, title } = content as YoutubeContentProp;
        return (
          <Grid gridDefinition={[{ colspan: { default: 12, xs: 10 } }]} key={index}>
            <YoutubeNoCookie height={height} youtubeId={youtubeId} width={width} title={t(title) as string} />
          </Grid>
        );
      }
      case WizardContentType.IMAGE: {
        const { id, imageAltText, imageId, width, height } = content as ImageContentProp;
        return (
          <Box textAlign="left" className="img-div-resp" key={id}>
            <img
              className="img-size-resp imageBorderWizard"
              src={imageId}
              alt={t(imageAltText) as string}
              width={width}
              height={height}
            />
          </Box>
        );
      }
      case WizardContentType.HEADER: {
        const { id, variant, text } = content as HeaderContentProp;
        return (
          <Header variant={variant} key={id}>
            <Trans>{text}</Trans>
          </Header>
        );
      }
      case WizardContentType.P_BODY_CUSTOM: {
        const { children, id } = content as PbodayCustomProp;
        return (
          <Grid
            data-analytics="pBodyCustom-Box"
            data-analytics-type="eventDetail"
            gridDefinition={[{ colspan: { default: 12, xs: 10 } }]}
            key={index}
          >
            <Box variant="div" key={id}>
              {children?.()}
            </Box>
          </Grid>
        );
      }
    }
  };

  if (!contentData?.length) return null;

  return (
    <Container
      data-analytics={dataAnalyticsLabel}
      data-analytics-type={dataAnalyticsTypeLabel}
      className="student-container-height"
    >
      <SpaceBetween size="m">
        {contentData.map((content, index) => {
          return generateContent(content, index);
        })}
      </SpaceBetween>
    </Container>
  );
};
