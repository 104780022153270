import { useQuery } from '@tanstack/react-query';
import { getTrack, listTracks } from 'services/deepRacerLite/trackApi';

export enum TrackStateKeysEnum {
  ListTracks = 'ListTracks',
  GetTrack = 'GetTrack',
}

export const useGetTrack = (trackArn: string) => useQuery([TrackStateKeysEnum.GetTrack], () => getTrack(trackArn));

export const useListTracks = () => useQuery([TrackStateKeysEnum.ListTracks], () => listTracks());
