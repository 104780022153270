import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import pageCatalog from 'pages/pageCatalog';
import { getRouteDefinitionByRoutePattern, getPageIdByRoutePattern } from 'pages/utils';
import { Breadcrumbs } from 'common/components/Breadcrumbs';
import { Navigation } from 'common/components/Navigation/Navigation';
import { Notifications } from 'common/components/Notifications';
import { HelpPanel } from 'common/components/HelpPanel';
import { RequiresAuth } from 'common/components/RequiresAuth';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { toggleToolsOpen, isToolsOpen } from 'store/helppanel';
import { displayInfoNotification, getNotifications } from 'store/notifications';
import Footer from 'common/components/Footer';
import ScrollToTop from 'common/components/ScrollToTop';
import { getContentHeader, getContentMaxWidths } from 'utils/appLayout';
import { DRLitePage } from 'pages/pageCatalog';
import { isConfettiRunning } from 'store/confetti';
import TopNavBar from '../TopNavBar';
import { useGetAlias } from 'store/leaderboard';
import {
  ShareableAchievementsAnnouncementContent,
  ShareableAchievementsAnnouncementHeader,
  shouldShowShareableAchievementsAnnouncementBanner,
} from '@amzn/aws-deepracer-ui-components/Banners/ShareableAchievementsAnnouncement';
import { isShareableAchievementsEnabled } from 'utils/featureFlags/currentFeatureFlags';
import { hasBannerBeenDismissed, recordBannerDismissed } from 'common/utils/banners';
import { BannerName } from '@amzn/aws-deepracer-ui-components/utils/storage';

const authRoutes = Object.keys(pageCatalog)
  .map(
    (pageRef) =>
      pageCatalog[pageRef as DRLitePage].requireAuth && (
        <Route
          key={pageRef}
          exact
          path={pageCatalog[pageRef].route}
          component={pageCatalog[pageRef as DRLitePage].component}
        />
      )
  )
  .filter(Boolean);

const noauthRoutes = Object.keys(pageCatalog)
  .map(
    (pageRef) =>
      !pageCatalog[pageRef as DRLitePage].requireAuth && (
        <Route
          key={pageRef}
          exact
          path={pageCatalog[pageRef].route}
          component={pageCatalog[pageRef as DRLitePage].component}
        />
      )
  )
  .filter(Boolean);

export const DefaultLayout = () => {
  const location = useLocation();
  const currPageRef = getRouteDefinitionByRoutePattern(location.pathname);
  const currentPageId = getPageIdByRoutePattern(location.pathname);
  const showBreadcrumbs = currPageRef?.breadcrumbConfig?.showBreadcrumbs;
  const showNotification = currPageRef?.showNotification;
  const disableContentPaddings = currPageRef?.disableContentPaddings;
  const notifications = useAppSelector(getNotifications);
  const [navOpen, setNavOpen] = useState(false);
  const [navHide, setNavHide] = useState(false);
  const [toolsHide, setToolsHide] = useState(false);

  const toolsOpen = useAppSelector(isToolsOpen);
  const dispatch = useAppDispatch();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const confettiRunning = useAppSelector(isConfettiRunning);

  const handleToolsClose = (event) => {
    dispatch(toggleToolsOpen({ open: event.detail.open }));
  };

  const { data: aliasData } = useGetAlias();

  useEffect(() => {
    if (
      shouldShowShareableAchievementsAnnouncementBanner(
        isShareableAchievementsEnabled(),
        hasBannerBeenDismissed(BannerName.SHAREABLE_ACHIEVEMENTS_ANNOUNCEMENT),
        aliasData?.Rewards
      )
    ) {
      dispatch(
        displayInfoNotification({
          content: (
            <ShareableAchievementsAnnouncementContent racerProfilePath={pageCatalog[DRLitePage.PROFILE].getPath()} />
          ),
          header: <ShareableAchievementsAnnouncementHeader />,
          onDismiss: () => {
            recordBannerDismissed(BannerName.SHAREABLE_ACHIEVEMENTS_ANNOUNCEMENT);
          },
        })
      );
    }
  }, [aliasData?.Rewards, dispatch]);

  useEffect(() => {
    if (!navOpen) {
      setNavOpen(!!currPageRef?.navigationOpen);
    }
    // Close navigation when user sign in.
    if (currentPageId === DRLitePage.SIGN_IN) {
      setNavOpen(false);
    }
    setNavHide(!!currPageRef?.navigationHide);
    setToolsHide(!!currPageRef?.toolsHide);
  }, [currPageRef, navOpen, currentPageId]);

  const maxContentWidth = getContentMaxWidths(location.pathname);
  const contentHeader = getContentHeader(location.pathname);

  return (
    <>
      {confettiRunning && <Confetti width={windowWidth} height={windowHeight} />}
      <TopNavBar />
      <ScrollToTop />
      <AppLayout
        data-analytics={currentPageId}
        data-analytics-type="serviceSubSection"
        navigation={<Navigation />}
        notifications={notifications.length > 0 ? showNotification && currPageRef && <Notifications /> : null}
        navigationOpen={navOpen}
        onNavigationChange={({ detail }) => setNavOpen(detail.open)}
        navigationHide={navHide}
        toolsHide={toolsHide}
        toolsOpen={toolsOpen}
        onToolsChange={handleToolsClose}
        tools={<HelpPanel />}
        maxContentWidth={maxContentWidth}
        disableContentPaddings={disableContentPaddings}
        headerSelector={'#headerSection'}
        breadcrumbs={showBreadcrumbs && currPageRef && <Breadcrumbs pageRef={currPageRef} />}
        contentHeader={contentHeader}
        content={
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to={pageCatalog.StudentHome.route} />} />
              {noauthRoutes}
              <RequiresAuth>
                <Switch>
                  {authRoutes}
                  <Redirect to={pageCatalog.StudentHome.route} />
                </Switch>
              </RequiresAuth>
            </Switch>
          </Suspense>
        }
      />
      <Footer />
    </>
  );
};
