import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelpPanelProvider } from '@amzn/deep-racer-walk-up';
import { DefaultLayout } from 'common/components/AppLayout/DefaultLayout';
import ErrorBoundary from 'common/components/ErrorBoundary';
import { APP_CONTENT_ID, HOME_NON_ESPERANZA } from 'common/constants';
import { isDarkModeEnabled, isReactQueryDevToolsEnabled } from 'utils/featureFlags/currentFeatureFlags';
import { getHelpPanelEndpoint } from 'pages/utils';
import { Helmet } from 'react-helmet';
import { getConfig } from '@amzn/aws-deep-racer-lite-client-config';
import '@amzn/awsui-global-styles/polaris.css';
import { getIsDarkMode, setDarkMode } from 'store/darkMode';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { Router } from 'react-router-dom';
import { history } from 'common/utils/history';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(getIsDarkMode);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 3, // Set max retries on failed request to 3 before showing final error thrown by function
        staleTime: 10000,
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    if (isDarkMode && isDarkModeEnabled()) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
  }, [isDarkMode]);

  useEffect(() => {
    const darkModeListenerCallback = (event) => {
      const isDarkMode = event.matches ? true : false;
      dispatch(
        setDarkMode({
          isDarkMode: isDarkMode,
        })
      );
    };
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', darkModeListenerCallback);
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', darkModeListenerCallback);
    };
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      {isReactQueryDevToolsEnabled() && <ReactQueryDevtools initialIsOpen position={'bottom-right'} />}
      <Router history={history}>
        <HelpPanelProvider apiHost={getHelpPanelEndpoint()} initialPageId={HOME_NON_ESPERANZA}>
          <div id={APP_CONTENT_ID}>
            <Helmet>
              <script>
                {`
                  (function (n, i, v, r, s, c, x, z) {
                    x = window.AwsRumClient = { q: [], n: n, i: i, v: v, r: r, c: c };
                    window[n] = function (c, p) {
                      x.q.push({ c: c, p: p });
                    };
                    z = document.createElement('script');
                    z.async = true;
                    z.src = s;
                    document.head.insertBefore(z, document.getElementsByTagName('script')[0]);
                  })(
                    'cwr',
                    '${getConfig('deepRacerLite')?.appMonitorId}',
                    '1.0.0',
                    'us-east-1',
                    'https://client.rum.us-east-1.amazonaws.com/1.0.2/cwr.js',
                    {
                      sessionSampleRate: 1,
                      guestRoleArn: "arn:aws:iam::${
                        getConfig('deepRacerLite')?.serviceAccount
                      }:role/DRLite-RUM-Monitor-us-east-1-${getConfig('deepRacerLite')?.serviceAccount}-Unauth",
                      identityPoolId: '${getConfig('deepRacerLite')?.cloudWatchIdentityPoolId}',
                      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                      telemetries: ['performance', 'errors', 'http'],
                      allowCookies: true,
                      enableXRay: true,
                    }
                  );
                `}
              </script>
            </Helmet>
            <ErrorBoundary>
              <DefaultLayout />
            </ErrorBoundary>
          </div>
        </HelpPanelProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
