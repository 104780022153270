export * from './avatar';
export * from './completeSignUp';
export * from './countries';
export * from './deepracer';
export * from './leaderboard';
export * from './LearningModuleHomeCardData';
export * from './majors';
export * from './redux';
export * from './schools';
export * from './styles';
export * from './urls';
export * from './validation';

export const NOTIFICATION_DISMISS_TIME_IN_MS = 4000;
export const MAX_RESULTS = 100;

// Backend consider token as invalid if it expires in 60 seconds.
// https://tiny.amazon.com/pfmtebfs/codeamazpackAwsSblobf489src
// We will force refresh token 5 mins before it expires.
export const TOKEN_EXPIRING_IN_SECONDS = 300;

export enum DropDownStatus {
  ERROR = 'error',
  FINISHED = 'finished',
  LOADING = 'loading',
  PENDING = 'pending',
}

export const ALREADY_SIGNUP_ERROR = 'User cannot be confirmed. Current status is CONFIRMED';
