import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Box, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import LabeledValue from '@amzn/aws-deepracer-ui-components/LabeledValue';
import '../style.css';
import { useListTracks } from 'store/track';
import { useEffect, useState } from 'react';
import { getTrackImage } from 'common/utils/deepracer';
import { DeepRacerLiteTypes } from 'types';

export const RACE_TYPES = {
  TIME_TRIAL: 'Time trial',
  OBJECT_AVOIDANCE: 'Object avoidance',
  HEAD_TO_HEAD_RACING: 'Head-to-head',
};

export const TIMING_METHOD_CRITERIA = {
  BEST_LAP_TIME: 'Fastest lap time',
  AVG_LAP_TIME: 'Fastest average lap time',
  TOTAL_TIME: 'Achieve the fastest time over 3 continuous laps.',
};

interface RaceDetailsProps {
  leaderboard?: DeepRacerLiteTypes.Leaderboard;
}

export const RaceDetails: React.FC<RaceDetailsProps> = (props) => {
  const { leaderboard } = props;
  const { t } = useTranslation('leaderboard');
  const { data: TracksData, isFetched: tracksIsFetched } = useListTracks();
  const tracks = TracksData?.Tracks;
  const [currentTrack, setCurrentTrack] = useState<DeepRacerLiteTypes.Track>();
  const [currentTrackImage, setcurrentTrackImage] = useState<string>('');

  // get track details for current trackArn
  useEffect(() => {
    if (!currentTrack && tracksIsFetched) {
      const _currentTrack = tracks?.filter((track) => track.TrackArn === leaderboard?.TrackArn)[0];
      setCurrentTrack(_currentTrack);
    }
  }, [currentTrack, leaderboard?.TrackArn, tracks, tracksIsFetched]);

  // get image of current trackArn
  useEffect(() => {
    const getTrackItem = async () => {
      const trackImage = await getTrackImage(leaderboard?.TrackArn ? leaderboard?.TrackArn : '');
      setcurrentTrackImage(trackImage);
    };
    if (leaderboard?.TrackArn) {
      getTrackItem();
    }
  }, [leaderboard?.TrackArn]);

  return (
    <Container header={<Header variant="h2">{t('raceDetails')}</Header>} className="raceDetailsContainer">
      <SpaceBetween direction="vertical" size="m">
        <Box variant="div">
          <b>{t('raceDates')}</b> <br />
          {leaderboard?.LaunchTime
            ? t('startTime') + moment(new Date(leaderboard?.LaunchTime)).format('M/D/YYYY, h:mm A')
            : ''}
          {leaderboard?.LaunchTime ? <br /> : null}
          {leaderboard?.CloseTime
            ? t('endTime') + moment(new Date(leaderboard?.CloseTime)).format('M/D/YYYY, h:mm A')
            : ''}
        </Box>
        <Box variant="div">
          <b>{t('raceType')}</b> <br />
          {leaderboard?.RaceType ? RACE_TYPES[leaderboard?.RaceType] : ''}
        </Box>
        <Box variant="div">
          <b>{t('winningCriteria')}</b> <br />
          {leaderboard?.TimingMethod ? TIMING_METHOD_CRITERIA[leaderboard?.TimingMethod] : ''}
        </Box>
        {!!leaderboard?.SubmissionsPerUser && (
          <LabeledValue label={t('submissionLimit')}>{leaderboard.SubmissionsPerUser}</LabeledValue>
        )}
        {currentTrack && (
          <Box variant="div">
            <b>{t('competitionTrack')}</b> <br />
            {currentTrack?.TrackName} <br />
            {t('trackLength', { length: currentTrack?.TrackLength?.toFixed(2) })} <br />
            {t('trackWidth', { width: currentTrack?.TrackWidth?.toFixed(2) })} <br />
            <img src={currentTrackImage} style={{ maxWidth: '100%' }} />
          </Box>
        )}
      </SpaceBetween>
    </Container>
  );
};
