import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Container, ColumnLayout, Header, SpaceBetween, ExpandableSection } from '@amzn/awsui-components-react';
import { LearningModulePageProps } from 'types/LearningModuleTypes';
import { LearningModuleChaptersCard } from 'common/components/LearningModuleChaptersCard';
import { PreRequisitesList } from '../LearningModulePreRequisites';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { fetchLmsTrackerChapters, getLmsTrackerChapters, getLmsTrackerChaptersIsLoading } from 'store/lmsTracker';
import './style.css';

export const LearningModulePage = ({
  chapters,
  duration,
  level,
  sections,
  preRequisites,
  id,
}: LearningModulePageProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const chaptersMap = useAppSelector(getLmsTrackerChapters) as Record<string, any>;
  const isLoading = useAppSelector(getLmsTrackerChaptersIsLoading);
  useEffect(() => {
    dispatch(fetchLmsTrackerChapters(id));
  }, [dispatch, id]);

  return (
    <Box>
      <SpaceBetween size="m">
        <Container data-analytics="courseInfo-Container" data-analytics-type="eventContext">
          <ColumnLayout columns={4} variant="text-grid">
            <Box variant="div">
              <Box variant="p" fontWeight="bold">
                {t('LearningModuleHomeCard.level')}
              </Box>
              {t(level)}
            </Box>
            <Box variant="div">
              <Box variant="p" fontWeight="bold">
                {t('LearningModuleHomeCard.duration')}
              </Box>
              {t(duration)}
            </Box>
            <Box variant="div">
              <Box variant="p" fontWeight="bold">
                {t('LearningModuleHomeCard.noOfChapters')}
              </Box>
              {chapters}
            </Box>
            <PreRequisitesList preRequisites={preRequisites} showLabelOnEmpty />
          </ColumnLayout>
        </Container>
      </SpaceBetween>
      <Box margin={{ top: 'm' }}>
        <SpaceBetween size="m">
          {sections.map(({ title, chaptersCard }, idx) => (
            <ExpandableSection
              data-analytics={`${String(t(title)).replace(/\s/g, '')}-Container`}
              data-analytics-type="eventContext"
              variant="footer"
              key={`section-${idx}`}
              header={
                <Box className="learning-module-expandable-section">
                  <Header variant="h2">{t(title)}</Header>
                </Box>
              }
              defaultExpanded
            >
              <ColumnLayout columns={3}>
                {chaptersCard.map((cardProps) => (
                  <LearningModuleChaptersCard
                    key={cardProps.id}
                    {...cardProps}
                    chapterData={chaptersMap[cardProps.id]}
                    isLoading={isLoading}
                  />
                ))}
              </ColumnLayout>
            </ExpandableSection>
          ))}
        </SpaceBetween>
      </Box>
    </Box>
  );
};
