import React from 'react';
import FormField, { FormFieldProps } from '@amzn/awsui-components-react/polaris/form-field';
import Textarea, { TextareaProps } from '@amzn/awsui-components-react/polaris/textarea';
import { useField } from 'formik';

interface TextareaFormWrapperProps extends Omit<TextareaProps, 'value'> {
  dataAnalyticsLabel?: string;
  dataAnalyticsTypeLabel?: string;
  name: string;
  validate?: (value: any) => undefined | string | Promise<any>;
}

type TextareaFormFieldPropTypes = FormFieldProps & TextareaFormWrapperProps;

export const TextareaFormFieldView: React.FC<TextareaFormFieldPropTypes> = ({
  dataAnalyticsLabel,
  dataAnalyticsTypeLabel,
  description,
  constraintText,
  label,
  stretch,
  onChange,
  onBlur,
  name,
  validate,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const handleChange: TextareaProps['onChange'] = (event) => {
    helpers.setValue(event.detail.value);
    onChange?.(event);
  };

  const handleBlur: TextareaProps['onBlur'] = (event) => {
    helpers.setTouched(true);
    onBlur?.(event);
  };

  const formFieldProps = {
    description,
    errorText: meta.touched && meta.error ? meta.error : null,
    constraintText,
    label,
    stretch,
  };

  const textAreaFieldProps = {
    ...field,
    ...otherProps,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <FormField {...formFieldProps}>
      <Textarea
        data-analytics={dataAnalyticsLabel}
        data-analytics-type={dataAnalyticsTypeLabel}
        {...textAreaFieldProps}
      />
    </FormField>
  );
};

export const TextareaFormField = React.memo(TextareaFormFieldView);
