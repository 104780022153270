import { useCallback } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ModalProps } from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { CancelButton, SubmitButton } from 'common/components/FormButtons';
import Modal from 'common/components/Modal';
import { AvatarConfigSelectOptionMap } from 'common/constants';
import { convertAvatarConfigToSelectOptions, convertSelectOptionsToAvatarConfig } from 'common/utils/avatar';
import { getAvatarConfig, getProfileIsLoading, handleUpdateProfile } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';

import AvatarConfigDialogAvatar from './components/AvatarConfigDialogAvatar';
import AvatarConfigDialogForm from './components/AvatarConfigDialogForm';

interface AvatarConfigDialogProps extends Pick<ModalProps, 'modalRoot' | 'visible'> {
  setVisible: (isVisible: boolean) => void;
  competitionCountryCode?: string | undefined | null;
}

const AvatarConfigDialog: React.FC<AvatarConfigDialogProps> = (props) => {
  const { modalRoot, visible, setVisible } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation('avatarConfigDialog');
  const avatarProps = useAppSelector(getAvatarConfig);
  const isLoading = useAppSelector(getProfileIsLoading);

  const closeDialog = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleSubmit = useCallback(
    (formData: AvatarConfigSelectOptionMap) => {
      // Convert any select options to the option value
      const avatarConfig = convertSelectOptionsToAvatarConfig(formData);

      dispatch(handleUpdateProfile({ profileData: { avatarConfig } }))
        .unwrap()
        // Only close dialog if change was successful
        .then(() => closeDialog());
    },
    [dispatch, closeDialog]
  );

  return (
    <Formik initialValues={convertAvatarConfigToSelectOptions(avatarProps)} onSubmit={handleSubmit}>
      <Modal
        visible={visible}
        onDismiss={closeDialog}
        {...(modalRoot ? { modalRoot } : {})}
        size="large"
        header={<Header description={t('headerDescription')}>{t('headerTitle')}</Header>}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="s">
              <CancelButton onClick={closeDialog}>{t('cancel', { ns: 'translation' })}</CancelButton>
              <SubmitButton variant="primary" loading={isLoading}>
                {t('save', { ns: 'translation' })}
              </SubmitButton>
            </SpaceBetween>
          </Box>
        }
      >
        <AvatarConfigDialogAvatar competitionCountryCode={props.competitionCountryCode} />
        <AvatarConfigDialogForm />
      </Modal>
    </Formik>
  );
};

export default AvatarConfigDialog;
