import { useField } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Grid, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { CheckboxField } from 'common/components/FormFields';
import { CompleteSignUpFields } from 'common/constants';
import { TERMS_AND_CONDITIONS } from 'common/constants/urls';
import { GenderFields, RaceFields } from './';

export const OptInEsperanzaFields = (): JSX.Element => {
  const { t } = useTranslation();
  const [optInField] = useField({ name: CompleteSignUpFields.OPT_IN });

  return (
    <SpaceBetween size="m">
      <Box margin={{ top: 'l' }}>
        <Grid disableGutters>
          <CheckboxField name={CompleteSignUpFields.OPT_IN}>{t('CompleteSignUp.optInEsperanza')}</CheckboxField>
          <Box margin={{ left: 'xxs' }}>
            <Trans i18nKey="CompleteSignUp.TermsAndConditions">
              I accept the
              <Link external target="_blank" href={TERMS_AND_CONDITIONS}>
                terms and conditions.
              </Link>
            </Trans>
          </Box>
        </Grid>
      </Box>
      {optInField.value && (
        <div style={{ display: 'flex', gap: '32px' }}>
          <GenderFields />
          <RaceFields />
        </div>
      )}
    </SpaceBetween>
  );
};
