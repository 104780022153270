import { useCollection } from '@amzn/awsui-collection-hooks';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import humanizeDuration from 'humanize-duration';
import moment from 'moment';
import pageCatalog from 'pages/pageCatalog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AssetDownloadButton from 'common/components/AssetDownloadButton';
import { InfoLink } from 'common/components/HelpPanel';
import { APP_CONTENT_ID, MODELS_LIST_PAGE_LINK } from 'common/constants';
import { truncateString } from 'common/utils/strings';
import { useGetAlias } from 'store/leaderboard';
import { useDeleteReinforcementModel, useListModels } from 'store/model';
import { DeepRacerLiteTypes } from 'types';
import './style.css';

const PAGE_SIZE = 10;

const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

function EmptyState({ title, subtitle, action }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const ModelsList = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation('modelsList');
  const history = useHistory();
  const { data: modelsListData } = useListModels();
  const models = modelsListData?.Models;
  const deleteReinforcementModelMutation = useDeleteReinforcementModel();
  const { data: aliasDataItem } = useGetAlias();
  const computeMinutesUsed: any = aliasDataItem?.MultiUserProfile?.ComputeMinutesUsed;
  const computeMinutesQueued: any = aliasDataItem?.MultiUserProfile?.ComputeMinutesQueued;
  const maxTotalComputeMinutes: any = aliasDataItem?.MultiUserProfile?.MaxTotalComputeMinutes;
  useEffect(() => {
    // add logic for opening modal here
    if (computeMinutesUsed >= maxTotalComputeMinutes) {
      setModalVisible(true);
    }
  }, [computeMinutesUsed, maxTotalComputeMinutes]);
  const [preferences, setPreferences] = useState<any>({
    pageSize: PAGE_SIZE,
    visibleContent: ['ModelName', 'ModelDescription', 'Status', 'AgentAlgorithm', 'Sensors', 'CreationTime'],
  });

  const isCreateModelButtonDisabled = computeMinutesUsed + computeMinutesQueued >= maxTotalComputeMinutes;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    models ?? [],
    {
      filtering: {
        empty: (
          <EmptyState
            title={t('emptyTitle')}
            subtitle={t('emptySubtitle')}
            action={
              <Button
                onClick={() => history.push(pageCatalog.CreateModel.getPath())}
                disabled={isCreateModelButtonDisabled}
              >
                {t('createModelButton')}
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title={t('noMatchTitle')}
            subtitle={t('noMatchSubtitle')}
            action={
              <Button onClick={() => actions.setFiltering('')} disabled={isCreateModelButtonDisabled}>
                {t('createModelButton')}
              </Button>
            }
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: 'ModelName',
          },
          isDescending: false,
        },
      },
      selection: {},
    }
  );
  const { selectedItems } = collectionProps;

  const isAssetDownloadButtonEnabled = selectedItems?.[0]?.Status === DeepRacerLiteTypes.ModelStatus.READY;

  const getStatusIndicator = (e) => {
    const status = e?.Status.toUpperCase();
    switch (e.Status) {
      case DeepRacerLiteTypes.ModelStatus.READY:
        return <StatusIndicator type="success">{status}</StatusIndicator>;
      case DeepRacerLiteTypes.ModelStatus.CREATED:
      case DeepRacerLiteTypes.ModelStatus.TRAINING:
      case DeepRacerLiteTypes.ModelStatus.COPYING:
      case DeepRacerLiteTypes.ModelStatus.MIGRATING:
      case DeepRacerLiteTypes.ModelStatus.IMPORTING:
      case DeepRacerLiteTypes.ModelStatus.STOPPING:
      case DeepRacerLiteTypes.ModelStatus.EVALUATING:
      case DeepRacerLiteTypes.ModelStatus.DELETING:
        return <StatusIndicator type="in-progress">{status}</StatusIndicator>;
      case DeepRacerLiteTypes.ModelStatus.ERROR:
      case DeepRacerLiteTypes.ModelStatus.MIGRATE_ERROR:
      case DeepRacerLiteTypes.ModelStatus.IMPORT_ERROR:
        return <StatusIndicator type="error">{status}</StatusIndicator>;
      default:
        return <StatusIndicator type="info">{status}</StatusIndicator>;
    }
  };

  const getDisableState = (e: DeepRacerLiteTypes.Model) => {
    switch (e.Status) {
      case DeepRacerLiteTypes.ModelStatus.CREATED:
      case DeepRacerLiteTypes.ModelStatus.COPYING:
      case DeepRacerLiteTypes.ModelStatus.MIGRATING:
      case DeepRacerLiteTypes.ModelStatus.IMPORTING:
      case DeepRacerLiteTypes.ModelStatus.STOPPING:
      case DeepRacerLiteTypes.ModelStatus.DELETING:
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <Table
        data-analytics="modelsList-Table"
        data-analytics-type="eventContext"
        data-test-id="modelsListRoot"
        {...collectionProps}
        isItemDisabled={getDisableState}
        items={items}
        columnDefinitions={[
          {
            id: 'ModelName',
            header: 'Model name',
            cell: (e) => (
              <Link
                data-analytics="modelName-Link"
                data-analytics-type="eventDetail"
                onFollow={() => history.push(pageCatalog.Model.getPath({ modelArn: e.ModelArn }))}
              >
                {truncateString(e.ModelName, 30)}
              </Link>
            ),
            sortingField: 'ModelName',
          },
          {
            id: 'ModelDescription',
            header: 'Model description',
            cell: (e) => truncateString(e.ModelDescription as string, 30),
            sortingField: 'ModelDescription',
          },
          {
            id: 'Status',
            header: 'Status',
            cell: (e) => getStatusIndicator(e),
            sortingField: 'Status',
          },
          {
            id: 'AgentAlgorithm',
            header: 'Agent algorithm',
            cell: (e) => e.AgentAlgorithm,
            sortingField: 'AgentAlgorithm',
          },
          {
            id: 'CreationTime',
            header: 'Creation time',
            cell: (e) => moment(new Date(e.CreationTime)).format('MMM Do YYYY, h:mm a'),
            sortingField: 'CreationTime',
          },
        ]}
        visibleColumns={preferences.visibleContent}
        ariaLabels={{
          selectionGroupLabel: t('selectionGroupLabel'),
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? t('allItemsSelectionItemLabel') : t('allItemsSelectionItemsLabel')
            } ${t('allItemsSelectionSelectedLabel')}`,
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter((i) => i.ModelName === item.ModelName).length;
            return isItemSelected
              ? t('itemSelectionisSelectedLabel', { ModelName: item.ModelName })
              : t('itemSelectionisNotSelectedLabel', { ModelName: item.ModelName });
          },
        }}
        selectionType="single"
        header={
          <Header
            counter={
              selectedItems?.length
                ? '(' + selectedItems?.length + '/' + models?.length + ')'
                : '(' + (models?.length || '0') + ')'
            }
            info={<InfoLink dataAnalyticsTypeLabel="eventDetail" helpId={MODELS_LIST_PAGE_LINK} />}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  data-analytics="delete-Button"
                  data-analytics-type="eventDetail"
                  variant="normal"
                  disabled={selectedItems?.length === 0}
                  onClick={() => {
                    const selectedModel: any = selectedItems?.[0];
                    deleteReinforcementModelMutation.mutate(selectedModel?.ModelArn);
                  }}
                >
                  {t('deleteModelButton')}
                </Button>
                <AssetDownloadButton
                  arn={selectedItems?.[0]?.ModelArn as string}
                  disabled={!isAssetDownloadButtonEnabled}
                />
                <Button
                  data-analytics="clone-Button"
                  data-analytics-type="eventDetail"
                  variant="normal"
                  disabled={selectedItems?.length !== 1}
                  onClick={() =>
                    history.push(pageCatalog.CloneModel.getPath(), {
                      model: selectedItems?.[0],
                    })
                  }
                >
                  {t('cloneModelButton')}
                </Button>
                <Button
                  data-analytics="create-Button"
                  data-analytics-type="eventDetail"
                  variant="primary"
                  onClick={() => history.push(pageCatalog.CreateModel.getPath())}
                  disabled={isCreateModelButtonDisabled}
                >
                  {t('createModelButton')}
                </Button>
              </SpaceBetween>
            }
          >
            {t('header')}
          </Header>
        }
        loading={false}
        loadingText={t('loadingText')}
        filter={
          <TextFilter
            {...filterProps}
            data-analytics="findModel-TextFilter"
            data-analytics-type="eventDetail"
            filteringAriaLabel={t('filteringAriaLabel')}
            filteringPlaceholder={t('filteringPlaceholder')}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        trackBy="ModelArn"
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: t('nextPageLabel'),
              previousPageLabel: t('previousPageLabel'),
              pageLabel: (pageNumber) => t('pageLabel', { pageNumber }),
            }}
          />
        }
        preferences={
          <CollectionPreferences
            data-analytics="visibility-CollectionPreferences"
            data-analytics-type="eventDetail"
            title={t('collectionPreferencesTitle')}
            confirmLabel={t('collectionPreferencesConfirmLabel')}
            cancelLabel={t('collectionPreferencesCancelLabel')}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: t('pageSizePreferenceTitle'),
              options: [
                { value: 10, label: t('pageSizePreferenceOptionsLabel', { count: 10 }) },
                { value: 25, label: t('pageSizePreferenceOptionsLabel', { count: 25 }) },
                { value: 50, label: t('pageSizePreferenceOptionsLabel', { count: 50 }) },
                { value: 100, label: t('pageSizePreferenceOptionsLabel', { count: 100 }) },
              ],
            }}
            visibleContentPreference={{
              title: t('visibleContentPreferenceTitle'),
              options: [
                {
                  label: t('visibleContentPreferenceOptionsLabel'),
                  options: [
                    {
                      id: 'ModelName',
                      label: 'Model name',
                    },
                    {
                      id: 'AgentAlgorithm',
                      label: 'Agent algorithm',
                    },
                    {
                      id: 'ModelDescription',
                      label: 'Model description',
                    },
                    {
                      id: 'CreationTime',
                      label: 'Creation Time',
                    },
                  ],
                },
              ],
            }}
          />
        }
      />
      <Modal
        data-analytics="sponsoredHoursOver-Modal"
        data-analytics-type="eventContext"
        modalRoot={document.getElementById(APP_CONTENT_ID) || undefined}
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-analytics="cancel-Button"
                data-analytics-type="eventDetail"
                variant="link"
                onClick={() => setModalVisible(false)}
              >
                {t('sponsoredHoursExpiredModal.cancelButtonText')}
              </Button>
              <Button
                data-analytics="learningContent-Button"
                data-analytics-type="eventDetail"
                variant="primary"
                onClick={() => {
                  history.push(pageCatalog.Learn.getPath());
                }}
              >
                {t('sponsoredHoursExpiredModal.actionButtonText')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t('sponsoredHoursExpiredModal.header')}
      >
        <p>
          {t('sponsoredHoursExpiredModal.timeUsedComeBack', {
            time: humanizeDuration(maxTotalComputeMinutes * 60 * 1000),
          })}
        </p>
        <br />
        <p>{t('sponsoredHoursExpiredModal.forNowCheckOut')}</p>
      </Modal>
    </>
  );
};
