import { BannerName } from '@amzn/aws-deepracer-ui-components/utils/storage';
import { StorageKey, StorageUtil } from 'common/utils/storage';

export const hasBannerBeenDismissed = (bannerName: BannerName) => {
  const bannerStorage = StorageUtil.localStorage.getItem(StorageKey.BANNERS) ?? {};
  const bannerDetails = bannerStorage[bannerName] ?? {};

  return !!bannerDetails.dismissedAt;
};

export const recordBannerDismissed = (bannerName: BannerName) => {
  const bannerStorage = StorageUtil.localStorage.getItem(StorageKey.BANNERS) ?? {};
  const bannerDetails = bannerStorage[bannerName] ?? {};

  bannerDetails.dismissedAt = Date.now();
  bannerStorage[bannerName] = bannerDetails;

  StorageUtil.localStorage.setItem(StorageKey.BANNERS, bannerStorage);
};
