import { IntroToMLChapterId } from 'common/constants/learningModule/introToML';
import { IntroToRLChapterId } from 'common/constants/learningModule/introToRL';
import { DRLitePage } from 'pages/pageCatalog';

export const ChapterIdToPageId = {
  // ML chapters
  [IntroToMLChapterId.CHAPTER_1]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_2]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_3]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_4]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_5]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_6]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_7]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_8]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_9]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_10]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_11]: DRLitePage.LEARNING_MODULE_1,
  [IntroToMLChapterId.CHAPTER_12]: DRLitePage.LEARNING_MODULE_1,

  // RL chapters
  [IntroToRLChapterId.CHAPTER_1]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_2]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_3]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_4]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_5]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_6]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_7]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_8]: DRLitePage.LEARNING_MODULE_2,
  [IntroToRLChapterId.CHAPTER_9]: DRLitePage.LEARNING_MODULE_2,
};
