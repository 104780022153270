export enum IntroToRLChapterId {
  CHAPTER_1 = '4665931c-a848-4c3b-9abb-d26f47e1bb6a',
  CHAPTER_2 = '5e255a38-7727-4b05-a07c-c46a29f9d6a2',
  CHAPTER_3 = 'f38b8658-e9f1-420e-8d43-be05a9725de2',
  CHAPTER_4 = 'e5d53778-53f6-41a5-84bc-51c452fca8e8',
  CHAPTER_5 = '6ae79e12-a284-4969-a50f-f9a31129d39c',
  CHAPTER_6 = '15965423-7429-4a1e-ac90-9accba85623e',
  CHAPTER_7 = 'ec4ff427-36e6-4657-af14-7fafbcee05d3',
  CHAPTER_8 = 'cad76cbe-8307-44ff-bdfe-cabe536b6ff3',
  CHAPTER_9 = '3f123e21-e7a2-406d-81db-e083808de02d',
}

// Values will be translation keys
export enum IntroToRLChapterTitle {
  CHAPTER_1 = 'IntroToRLChapters.MLRefresher',
  CHAPTER_2 = 'IntroToRLChapters.introToRL',
  CHAPTER_3 = 'IntroToRLChapters.RLinRealWorld',
  CHAPTER_4 = 'IntroToRLChapters.RLwithDeepRacer',
  CHAPTER_5 = 'IntroToRLChapters.trainModel',
  CHAPTER_6 = 'IntroToRLChapters.trainingAlgorithms',
  CHAPTER_7 = 'IntroToRLChapters.rewardFunctions',
  CHAPTER_8 = 'IntroToRLChapters.howDeviceWorks',
  CHAPTER_9 = 'IntroToRLChapters.proTips',
}
