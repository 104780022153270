import { matchPath } from 'react-router-dom';
import type { Page } from 'types/page';
import pageCatalog, { type DRLitePage } from './pageCatalog';
import { getStage, Stage } from '@amzn/aws-deep-racer-lite-client-config';
import {
  DEV_DOC_HELP_PANEL_ENDPOINT,
  PROD_DOC_HELP_PANEL_ENDPOINT,
  PROD_WHAT_IS_DEEPRACER_STUDENT_LEAGUE,
  ALPHA_WHAT_IS_DEEPRACER_STUDENT_LEAGUE,
  ALPHA_ACCOUNT_DOC,
  PROD_ACCOUNT_DOC,
  ALPHA_SCHOLARSHIP,
  PROD_SCHOLARSHIP,
  PROD_WHAT_IS_UDACITY,
  ALPHA_WHAT_IS_UDACITY,
} from 'common/constants';

export const getRouteDefinitionByRoutePattern = (pattern: string): Page<DRLitePage> | undefined => {
  return Object.values(pageCatalog).find(({ route }) => !!matchPath(pattern, { path: route, exact: true }));
};

export const getPageIdByRoutePattern = (pattern: string): string | undefined => {
  return Object.keys(pageCatalog).find(
    (pageId) => !!matchPath(pattern, { path: pageCatalog[pageId].route, exact: true })
  );
};

export const isProd = () => {
  const currentStage = getStage();
  return currentStage === Stage.PROD;
};

export const getHelpPanelEndpoint = () => {
  return isProd() ? PROD_DOC_HELP_PANEL_ENDPOINT : DEV_DOC_HELP_PANEL_ENDPOINT;
};

export const getDocumentEndpoint = () => {
  return isProd() ? PROD_WHAT_IS_DEEPRACER_STUDENT_LEAGUE : ALPHA_WHAT_IS_DEEPRACER_STUDENT_LEAGUE;
};

export const getScholarshipEndpoint = () => {
  return isProd() ? PROD_SCHOLARSHIP : ALPHA_SCHOLARSHIP;
};

export const getUdacityDocEndPoint = () => {
  return isProd() ? PROD_WHAT_IS_UDACITY : ALPHA_WHAT_IS_UDACITY;
};

export const getAccountDocEndpoint = () => {
  return isProd() ? PROD_ACCOUNT_DOC : ALPHA_ACCOUNT_DOC;
};

/**
 * minsToHours converts mins to hours with 2 decimal fix
 * @param mins number in mins
 * @returns hours with 2 decimal
 */
export const minsToHours = (mins: number) => Number((mins / 60).toFixed(2));
