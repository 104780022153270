import { getAssetUrl } from 'services/deepRacerLite';

export const downloadAsset = async (arn: string): Promise<void> => {
  const downloadUrl = (await getAssetUrl(arn))?.Url;

  if (!downloadUrl) {
    return Promise.reject('No url found for requested asset.');
  }

  const downloadLink = document.createElement('a');

  downloadLink.href = downloadUrl;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
