/* istanbul ignore file */
import { FeatureFlags } from './types';

let localFlags: FeatureFlags;

export const getLocalFeatureFlagOverride = (): FeatureFlags => {
  if (localFlags) {
    return localFlags;
  }

  localFlags = {};
  const cookie = window.document?.cookie || '';
  // Find weblab specific cookie ('experiment')
  const localOverrideCookie = cookie.split(' ').find((cookie) => cookie.includes('experiment=')) || '';
  // Clean up the cookie to get ':' separated list of (feature:override)
  const flags = localOverrideCookie.replace(/(experiment=|[";])/g, '').split('&');
  flags.forEach((flag) => {
    const experiment = flag.split(':');
    localFlags[experiment[0]] = experiment[1];
  });
  return localFlags;
};
