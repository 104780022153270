import { Formik, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { InputFormField } from 'common/components/FormFields';
import pageCatalog from 'pages/pageCatalog';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { verifyEmail, getAuthIsLoading, getRegisterAccountEmail, resendCode, getIsResendingCode } from 'store/auth';

const initialValues = { code: '' };

export const VerifyEmailForm: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const confirmAccountEmail = useAppSelector(getRegisterAccountEmail);
  const isLoading = useAppSelector(getAuthIsLoading);
  const isResendingCode = useAppSelector(getIsResendingCode);

  if (!confirmAccountEmail) {
    history.push(pageCatalog.SignUp.getPath());
  }

  const onSubmit = (data: { code?: string }) => {
    let { code } = data;
    code = (code as string)?.trim();
    dispatch(verifyEmail({ username: confirmAccountEmail as string, code }));
  };

  const onResendCode = () => {
    dispatch(resendCode({ username: confirmAccountEmail as string }));
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t('required')),
  });

  return (
    <Container header={<Header description={t('checkSpamHint')}>{t('verifyEmailDesc')}</Header>}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form>
          <SpaceBetween size="m">
            <InputFormField
              label={t('verificationCode')}
              placeholder={t('verificationCodePlaceholder')}
              stretch
              name="code"
              type="text"
            />
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={onResendCode}
                formAction="none"
                loading={isResendingCode}
                disabled={isLoading}
                variant="normal"
              >
                {t('resendCode')}
              </Button>
              <Button variant="primary" formAction="submit" loading={isLoading && !isResendingCode}>
                {t('verifyLabel')}
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        </Form>
      </Formik>
    </Container>
  );
};
