import { useTranslation } from 'react-i18next';

import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';

import { InfoLink } from 'common/components/HelpPanel';
import { COMPLETE_SIGNUP_PAGE_HELP_PANEL_URL } from 'common/constants';
import { Grid } from '@amzn/awsui-components-react';

export const CompleteSignUpHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box className="custom-page-header">
      <Grid gridDefinition={[{ colspan: 9 }]}>
        <Header
          variant="h1"
          description={t('CompleteSignUp.completeSignUpHint')}
          info={<InfoLink helpId={COMPLETE_SIGNUP_PAGE_HELP_PANEL_URL} />}
        >
          {t('CompleteSignUp.completeSignUpForDRL')}
        </Header>
      </Grid>
    </Box>
  );
};
