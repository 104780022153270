/* eslint-disable no-prototype-builtins */
import React from 'react';

import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from '@amzn/awsui-components-react';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { displayInfoNotification } from 'store/notifications';
import {
  ChapterIdToChapterTitle,
  ChapterIdToModuleId,
  ChapterIdToNextChapterId,
  ChapterIdToSectionIds,
} from 'common/constants/learningModule/wizardPage';
import { Lesson1 } from 'common/components/LearningModuleWizard/Part1/Lesson1';
import { Lesson2 } from 'common/components/LearningModuleWizard/Part1/Lesson2';
import { Lesson3 } from 'common/components/LearningModuleWizard/Part1/Lesson3';
import { Lesson4 } from 'common/components/LearningModuleWizard/Part2/Lesson4';
import { Lesson5 } from 'common/components/LearningModuleWizard/Part2/Lesson5';
import { Lesson6 } from 'common/components/LearningModuleWizard/Part2/Lesson6';
import { Lesson7 } from 'common/components/LearningModuleWizard/Part2/Lesson7';
import { Lesson8 } from 'common/components/LearningModuleWizard/Part2/Lesson8';
import { Lesson9 } from 'common/components/LearningModuleWizard/Part2/Lesson9';
import { Lesson10 } from 'common/components/LearningModuleWizard/Part3/Lesson10';
import { Lesson11 } from 'common/components/LearningModuleWizard/Part3/Lesson11';
import { Lesson12 } from 'common/components/LearningModuleWizard/Part3/Lesson12';
import { Lesson13 } from 'common/components/LearningModuleWizard/Part4/Lesson13';
import { Lesson14 } from 'common/components/LearningModuleWizard/Part4/Lesson14';
import { Lesson15 } from 'common/components/LearningModuleWizard/Part4/Lesson15';
import { Lesson16 } from 'common/components/LearningModuleWizard/Part5/Lesson16';
import { Lesson17 } from 'common/components/LearningModuleWizard/Part5/Lesson17';
import { Lesson18 } from 'common/components/LearningModuleWizard/Part6/Lesson18';
import { Lesson19 } from 'common/components/LearningModuleWizard/Part6/Lesson19';
import { Lesson20 } from 'common/components/LearningModuleWizard/Part6/Lesson20';
import { Lesson21 } from 'common/components/LearningModuleWizard/Part6/Lesson21';
import {
  addLmsTrackerChapter,
  addLmsTrackerModule,
  addLmsTrackerSection,
  fetchLmsTrackerChapters,
  fetchLmsTrackerModules,
  fetchLmsTrackerSections,
  getLmsTrackerChapters,
  getLmsTrackerModules,
  getLmsTrackerSections,
  updateLmsTrackerChapter,
} from 'store/lmsTracker';
import { DeepRacerLiteTypes } from 'types';
import { IntroToMLChapterId } from 'common/constants/learningModule/introToML';
import { IntroToRLChapterId } from 'common/constants/learningModule/introToRL';

interface WizardPageParamType {
  wizardId: string;
}

export const WizardPage = (): JSX.Element => {
  const { wizardId } = useParams<WizardPageParamType>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const moduleId = ChapterIdToModuleId[wizardId];
  const modules = useAppSelector(getLmsTrackerModules);
  const chapters = useAppSelector(getLmsTrackerChapters);
  const sections = useAppSelector(getLmsTrackerSections);
  const sectionIds = ChapterIdToSectionIds[wizardId];
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);

  useEffect(() => {
    if (!ChapterIdToChapterTitle.hasOwnProperty(wizardId)) {
      history.push('/learn');
      dispatch(
        displayInfoNotification({
          autoDismiss: true,
          content: 'Entered chapter is currently unavailable, taking you back to learn.',
        })
      );
    }
  }, [wizardId, history, dispatch]);

  useEffect(() => {
    if (!wizardId) return;
    // set active section
    dispatch(fetchLmsTrackerSections({ moduleId, chapterId: wizardId }))
      .then(unwrapResult)
      .then((res) => {
        let idx = 0;
        while (idx < sectionIds.length) {
          const currentSection = res.find((section) => section.sectionId == sectionIds[idx]);
          if (currentSection?.sectionStatus != DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED) {
            setActiveStepIndex(idx);
            break;
          }
          if (idx == sectionIds.length - 1) {
            setActiveStepIndex(idx);
          }
          idx = idx + 1;
        }
      });

    // add current module if not already started
    if (
      !modules?.[moduleId] ||
      modules[moduleId].moduleStatus === DeepRacerLiteTypes.LmsCourseTrackerStatus.NOT_STARTED
    ) {
      dispatch(fetchLmsTrackerModules())
        .then(unwrapResult)
        .then((res) => {
          const currentModule = res.find((module) => module.moduleId == moduleId);
          if (!currentModule || currentModule.moduleStatus == DeepRacerLiteTypes.LmsCourseTrackerStatus.NOT_STARTED) {
            dispatch(addLmsTrackerModule(moduleId));
          }
        });
    }

    // add current chapter if not already started
    if (
      !chapters?.[wizardId] ||
      chapters[wizardId].chapterStatus === DeepRacerLiteTypes.LmsCourseTrackerStatus.NOT_STARTED
    ) {
      dispatch(fetchLmsTrackerChapters(moduleId))
        .then(unwrapResult)
        .then((res) => {
          const currentChapter = res.find((chapter) => chapter.chapterId == wizardId);
          if (
            !currentChapter ||
            currentChapter.chapterStatus == DeepRacerLiteTypes.LmsCourseTrackerStatus.NOT_STARTED
          ) {
            dispatch(addLmsTrackerChapter({ moduleId: moduleId, chapterId: wizardId }));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, moduleId, wizardId]);

  const markSectionComplete = () => {
    if (
      !sections?.[sectionIds[activeStepIndex]] ||
      sections[sectionIds[activeStepIndex]].sectionStatus === DeepRacerLiteTypes.LmsCourseTrackerStatus.NOT_STARTED
    ) {
      dispatch(
        addLmsTrackerSection({
          moduleId,
          chapterId: wizardId,
          sectionId: sectionIds[activeStepIndex],
        })
      );
    }
  };

  const markChapterComplete = () => {
    dispatch(updateLmsTrackerChapter({ moduleId, chapterId: wizardId }));
  };

  const onCancel = () => {
    history.push(`/learn/${moduleId}`);
  };

  const onNavigate = ({ detail }) => {
    markSectionComplete();
    setActiveStepIndex(detail.requestedStepIndex);
  };

  const onSubmit = () => {
    markChapterComplete();
    markSectionComplete();
    history.push(`/learn/${moduleId}`);
  };

  const onClick = () => {
    markChapterComplete();
    markSectionComplete();
    history.push(`/learn/chapter/${ChapterIdToNextChapterId[wizardId]}`);
  };

  const getWizard = (wizardId) => {
    switch (wizardId) {
      case IntroToMLChapterId.CHAPTER_1:
        return (
          <Lesson1
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_2:
        return (
          <Lesson2
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_3:
        return (
          <Lesson3
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_4:
        return (
          <Lesson4
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_5:
        return (
          <Lesson5
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_6:
        return (
          <Lesson6
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_7:
        return (
          <Lesson7
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_8:
        return (
          <Lesson8
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_9:
        return (
          <Lesson9
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_10:
        return (
          <Lesson10
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_11:
        return (
          <Lesson11
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToMLChapterId.CHAPTER_12:
        return (
          <Lesson12 activeStepIndex={activeStepIndex} onCancel={onCancel} onNavigate={onNavigate} onSubmit={onSubmit} />
        );
      case IntroToRLChapterId.CHAPTER_1:
        return (
          <Lesson13
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_2:
        return (
          <Lesson14
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_3:
        return (
          <Lesson15
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_4:
        return (
          <Lesson16
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_5:
        return (
          <Lesson17
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_6:
        return (
          <Lesson18
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_7:
        return (
          <Lesson19
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_8:
        return (
          <Lesson20
            activeStepIndex={activeStepIndex}
            onCancel={onCancel}
            onNavigate={onNavigate}
            onSubmit={onSubmit}
            onClick={onClick}
          />
        );
      case IntroToRLChapterId.CHAPTER_9:
        return (
          <Lesson21 activeStepIndex={activeStepIndex} onCancel={onCancel} onNavigate={onNavigate} onSubmit={onSubmit} />
        );
      default:
        return <p>Coming soon...</p>;
    }
  };

  return <Box variant="div">{getWizard(wizardId)}</Box>;
};
