import React from 'react';
import FlagIconFactory, { FlagIconProps } from 'react-flag-icon-css';

export const FlagIcon = FlagIconFactory(React, { useCssModules: false });

export enum FlagSize {
  SMALL = 'lg',
  MEDIUM = '2x',
  LARGE = '3x',
  EXTRA_LARGE = '4x',
  XXL = '5x',
}

const Flag = ({ size = FlagSize.SMALL, code, className }: FlagIconProps) => (
  <FlagIcon className={className} code={code.toLocaleLowerCase()} size={size} />
);

export default Flag;
