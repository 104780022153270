import { useTranslation } from 'react-i18next';
import { Header } from '@amzn/awsui-components-react';

import { InfoLink } from 'common/components/HelpPanel';
import { ACCOUNT_PAGE_HELP_PANEL_URL } from 'common/constants';

export const PageHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Header
      variant="h1"
      description={t('AccountPage.YourAccountDesc')}
      info={<InfoLink helpId={ACCOUNT_PAGE_HELP_PANEL_URL} />}
    >
      {t('YourAccount')}
    </Header>
  );
};
