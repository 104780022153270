import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, ColumnLayout, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { KeyValuePair } from 'common/components/KeyValuePair';
import { CancelButton, SubmitButton } from 'common/components/FormButtons';
import { InputFormField } from 'common/components/FormFields';
import Modal from 'common/components/Modal';
import { VALID_NICKNAME_PATTERN } from 'common/constants';
import { getNicknameWithIdSuffix, getProfileIsLoading, handleUpdateProfile } from 'store/profile';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';

export const RacerName = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const nickName = useAppSelector(getNicknameWithIdSuffix);
  const isLoading = useAppSelector(getProfileIsLoading);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [shareNickName, setShareNickName] = useState<boolean>(false);

  const onDimiss = () => {
    setShowModal(false);
  };
  const onSubmit = ({ nickName }) => {
    dispatch(handleUpdateProfile({ profileData: { nickName, shareNickName } })).then(onDimiss);
  };

  const initialValues = {
    nickName,
  };

  const validationSchema = Yup.object().shape({
    nickName: Yup.string()
      .required(t('required'))
      .min(1, t('ProfilePage.nicknameFormat'))
      .max(24, t('ProfilePage.nicknameFormat'))
      .matches(VALID_NICKNAME_PATTERN, t('ProfilePage.nicknameFormat')),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form>
        <Modal
          visible={showModal}
          onDismiss={onDimiss}
          header={
            <Header description={t('ProfilePage.WhatIsYourNewRacerName')}>{t('ProfilePage.YourRacerName')}</Header>
          }
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <CancelButton onClick={onDimiss}>{t('cancel')}</CancelButton>
                <SubmitButton variant="primary" loading={isLoading} disabled={!shareNickName}>
                  {t('save')}
                </SubmitButton>
              </SpaceBetween>
            </Box>
          }
        >
          <SpaceBetween size="m">
            <InputFormField
              placeholder={t('ProfilePage.YourRacerName')}
              name="nickName"
              type="text"
              constraintText={t('ProfilePage.nicknameFormat')}
            />
            <Checkbox checked={shareNickName} name="t/c" onChange={({ detail }) => setShareNickName(detail.checked)}>
              &nbsp;
              {t('ProfilePage.shareNickName')}
            </Checkbox>
          </SpaceBetween>
        </Modal>
        <ColumnLayout columns={2}>
          <KeyValuePair label={t('ProfilePage.YourRacerName')}>{nickName || t('emptyLabel')}</KeyValuePair>
          <Link onFollow={() => setShowModal(true)}>{t('ProfilePage.EditRacerName')}</Link>
        </ColumnLayout>
      </Form>
    </Formik>
  );
};
