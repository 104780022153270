import { getIdToken } from 'store/utils/authUtils';
import ServiceProvider from 'services/ServiceProvider';
import { DeepRacerLiteTypes, DeepRacerLiteService } from 'types';
import { DR_LITE_COURSE_ID, DR_LITE_NAMESPACE_ID } from 'services/constants';

export const addLmsTrackerModule = async (
  moduleId: string
): Promise<DeepRacerLiteTypes.CreateLmsTrackerModuleResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.CreateLmsTrackerModuleRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
      moduleId: moduleId,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.createLmsTrackerModule(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getLmsTrackerModules = async (): Promise<DeepRacerLiteTypes.ListLmsTrackerModulesResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.ListLmsTrackerModulesRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.listLmsTrackerModules(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addLmsTrackerChapter = async (
  moduleId: string,
  chapterId: string
): Promise<DeepRacerLiteTypes.CreateLmsTrackerChapterResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.CreateLmsTrackerChapterRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
      moduleId: moduleId,
      chapterId: chapterId,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.createLmsTrackerChapter(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateLmsTrackerChapter = async (
  moduleId: string,
  chapterId: string,
  chapterStatus: DeepRacerLiteTypes.LmsCourseTrackerStatus
): Promise<DeepRacerLiteTypes.UpdateLmsTrackerChapterResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.UpdateLmsTrackerChapterRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
      moduleId: moduleId,
      chapterId: chapterId,
      chapterStatus: chapterStatus,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.updateLmsTrackerChapter(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getLmsTrackerChapters = async (
  moduleId: string
): Promise<DeepRacerLiteTypes.ListLmsTrackerChaptersResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.ListLmsTrackerChaptersRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
      moduleId: moduleId,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.listLmsTrackerChapters(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addLmsTrackerSection = async (
  moduleId: string,
  chapterId: string,
  sectionId: string
): Promise<DeepRacerLiteTypes.CreateLmsTrackerSectionResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.CreateLmsTrackerSectionRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
      moduleId: moduleId,
      chapterId: chapterId,
      sectionId: sectionId,
      sectionStatus: DeepRacerLiteTypes.LmsCourseTrackerStatus.COMPLETED,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.createLmsTrackerSection(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateLmsTrackerSection = async (
  moduleId: string,
  chapterId: string,
  sectionId: string,
  sectionStatus: DeepRacerLiteTypes.LmsCourseTrackerStatus
): Promise<DeepRacerLiteTypes.UpdateLmsTrackerSectionResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.UpdateLmsTrackerSectionRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
      moduleId: moduleId,
      chapterId: chapterId,
      sectionId: sectionId,
      sectionStatus: sectionStatus,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.updateLmsTrackerSection(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getLmsTrackerSections = async (
  moduleId: string,
  chapterId: string
): Promise<DeepRacerLiteTypes.ListLmsTrackerSectionsResponse> => {
  try {
    const deepRacerLiteService: DeepRacerLiteService = ServiceProvider.getDeepRacerLiteService();
    const request: DeepRacerLiteTypes.ListLmsTrackerSectionsRequest = {
      nameSpaceId: DR_LITE_NAMESPACE_ID,
      courseId: DR_LITE_COURSE_ID,
      moduleId: moduleId,
      chapterId: chapterId,
    };
    const idToken = await getIdToken();
    return await deepRacerLiteService.listLmsTrackerSections(request, idToken);
  } catch (e) {
    return Promise.reject(e);
  }
};
