import RaceCards, { type RaceCardLeaderboardData } from '@amzn/aws-deepracer-ui-components/RaceCards';
import { REGIONS as GLOBAL_REGIONS } from '@amzn/aws-deepracer-ui-components/data/global';
import { getChallengeIds } from '@amzn/aws-deepracer-ui-components/utils/regionUtils';
import SeasonPoints from '@amzn/aws-deepracer-ui-components/SeasonPoints';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import { useHelpPanelDispatch } from '@amzn/deep-racer-walk-up/dist/components/HelpPanel/HelpPanelContext';

import { LEAGUE_2024_SEASON_POINTS, SEASON_POINTS_HELP_PANEL_URL } from 'common/constants';
import pageCatalog, { DRLitePage } from 'pages/pageCatalog';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getRankedUserSubmission, listLeaderboardSubmissions } from 'services/deepRacerLite/submissionApi';
import { toggleToolsOpen } from 'store/helppanel';
import { useGetAlias, useListLeaderboards } from 'store/leaderboard';
import { fetchLatestUserSubmission } from 'store/leaderboardSubmission';
import { getProfileData } from 'store/profile';
import { useListTracks } from 'store/track';
import {
  getCountrySeasonScores,
  getGlobalSeasonScores,
  getRegionSeasonScores,
  fetchCountrySeasonScores,
  fetchGlobalSeasonScores,
  fetchRegionSeasonScores,
} from 'store/seasonScores';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { DeepRacerLiteTypes } from 'types';

const CUT_OFF_TIME = 1664582400000;

function StudentLeague() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const updateHelpPanel = useHelpPanelDispatch();
  const { t } = useTranslation('studentLeague');

  const asteroidProfileData = useAppSelector(getProfileData);
  const countrySeasonScores = useAppSelector(getCountrySeasonScores);
  const globalSeasonScores = useAppSelector(getGlobalSeasonScores);
  const regionSeasonScores = useAppSelector(getRegionSeasonScores);

  // States
  const [raceCardLeaderboardsData, setRaceCardLeaderboardsData] = useState<RaceCardLeaderboardData[]>([]);
  const [isRaceCardDataLoading, setIsRaceCardDataLoading] = useState(false);

  // Queries
  const { data: profileData } = useGetAlias();
  const { data: leaderboardsData } = useListLeaderboards();
  const { data: tracksData } = useListTracks();
  const tracks = tracksData?.Tracks ?? [];

  useEffect(() => {
    if (asteroidProfileData?.profileId && profileData) {
      const { studentCountryChallengeId, studentRegionChallengeId } = getChallengeIds(
        profileData.CompetitionCountryCode ?? DeepRacerLiteTypes.CountryCode.US
      );

      dispatch(
        fetchCountrySeasonScores({
          challengeId: studentCountryChallengeId,
          profileId: asteroidProfileData.profileId,
        })
      );

      dispatch(
        fetchGlobalSeasonScores({
          challengeId: GLOBAL_REGIONS[0].studentChallengeId,
          profileId: asteroidProfileData.profileId,
        })
      );

      dispatch(
        fetchRegionSeasonScores({
          challengeId: studentRegionChallengeId,
          profileId: asteroidProfileData.profileId,
        })
      );
    }
  }, [dispatch, profileData, asteroidProfileData]);

  useEffect(() => {
    const getRaceCardLeaderboardsData = async () => {
      if (leaderboardsData?.Leaderboards) {
        setIsRaceCardDataLoading(true);
        const result: RaceCardLeaderboardData[] = await Promise.all(
          leaderboardsData?.Leaderboards?.filter((leaderboard) => leaderboard.LaunchTime >= CUT_OFF_TIME).map(
            async (leaderboard) => {
              return {
                leaderboard,
                leagueTopRacers: (await listLeaderboardSubmissions(leaderboard.Arn, true)).LeagueTopRacers ?? {},
                userRankedSubmission: (await getRankedUserSubmission(leaderboard.Arn)).LeaderboardSubmission,
              };
            }
          )
        );
        setRaceCardLeaderboardsData(result);
        setIsRaceCardDataLoading(false);
      }
    };
    getRaceCardLeaderboardsData();
  }, [leaderboardsData?.Leaderboards]);

  const handleInfoLinkClick = useCallback(() => {
    updateHelpPanel({ pageId: SEASON_POINTS_HELP_PANEL_URL });
    dispatch(toggleToolsOpen({ open: true }));
  }, [dispatch, updateHelpPanel]);

  const handleCardEnterView = useCallback(
    (leaderboardArn: string) => {
      dispatch(fetchLatestUserSubmission(leaderboardArn));
    },
    [dispatch]
  );

  const handleCardPrimaryActionButtonClick = useCallback(
    (leaderboardArn: string) => {
      history.push(pageCatalog[DRLitePage.ENTER_RACE].getPath({ leaderboardArn }));
    },
    [history]
  );

  const handleCardSecondaryActionButtonClick = useCallback(
    (leaderboardArn: string) => {
      history.push(pageCatalog[DRLitePage.LEADERBOARD].getPath({ leaderboardArn }));
    },
    [history]
  );

  return (
    <SpaceBetween direction="vertical" size="l" data-test-id="studentLeague">
      <Container data-analytics="gearUp-Container" data-analytics-type="eventContext">
        <TextContent>
          <h4>{t('SeasonIntroductionHeader')}</h4>
          <br />
          <p>{t('SeasonIntroductionDescription')}</p>
        </TextContent>
      </Container>
      <RaceCards
        isLoading={isRaceCardDataLoading}
        leaderboardsData={raceCardLeaderboardsData}
        onCardEnterView={handleCardEnterView}
        onCardPrimaryActionButtonClick={handleCardPrimaryActionButtonClick}
        onCardSecondaryActionButtonClick={handleCardSecondaryActionButtonClick}
        tracks={tracks}
        userCountryCode={profileData?.CompetitionCountryCode}
      />
      <SeasonPoints
        countryCode={profileData?.CompetitionCountryCode}
        isStudent
        onInfoLinkClick={handleInfoLinkClick}
        topRankedCountryData={countrySeasonScores?.rankedScoreData?.[0]}
        topRankedGlobalData={globalSeasonScores?.rankedScoreData?.[0]}
        topRankedRegionData={regionSeasonScores?.rankedScoreData?.[0]}
        userCountryData={countrySeasonScores?.userScoreData ?? undefined}
        userGlobalData={globalSeasonScores?.userScoreData ?? undefined}
        userRegionData={regionSeasonScores?.userScoreData ?? undefined}
        onViewSeasonLeaderboardClick={() =>
          history.push(pageCatalog[DRLitePage.SEASON_LEADERBOARD].getPath({ seasonType: LEAGUE_2024_SEASON_POINTS }))
        }
      />
      {leaderboardsData?.Leaderboards?.some((lb) => lb.Status === DeepRacerLiteTypes.LeaderboardStatus.CLOSED) && (
        <RaceCards
          isClosedRacesDisplay
          isLoading={isRaceCardDataLoading}
          leaderboardsData={raceCardLeaderboardsData}
          onCardEnterView={handleCardEnterView}
          onCardPrimaryActionButtonClick={handleCardPrimaryActionButtonClick}
          onCardSecondaryActionButtonClick={handleCardSecondaryActionButtonClick}
          tracks={tracks}
          userCountryCode={profileData?.CompetitionCountryCode}
        />
      )}
    </SpaceBetween>
  );
}

export default StudentLeague;
