/* istanbul ignore file */
import {
  QuizAssessmentResult,
  QuizAssessmentStatus,
} from '@amzn/aws-deep-racer-lite-external-service-type-script-client';
import { Box, SpaceBetween, Button } from '@amzn/awsui-components-react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { InfoLink } from 'common/components/HelpPanel';
import { QUIZ_PAGE_HELP_PANEL_URL } from 'common/constants';
import pageCatalog, { DRLitePage, PageRouteParams } from 'pages/pageCatalog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DR_LITE_SCHOLARSHIP_ASSESSMENT_ID } from 'services/constants';
import {
  fetchAssessmentTrackers,
  finishAssessmentTracker,
  getAssessmentTrackers,
  getAssessmentTrackersIsLoading,
} from 'store/lmsQuiz';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import Header from './components/Header';

interface PageHeaderInterface {
  enableCountDown: boolean;
}

export const PageHeader = ({ enableCountDown }: PageHeaderInterface): JSX.Element => {
  const { t } = useTranslation('assessment', { keyPrefix: 'pageHeader' });
  const { assessmentTrackerId } = useParams<PageRouteParams[DRLitePage.ASSESSMENT_QUIZ]>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showTimeUpModal, setshowTimeUpModal] = useState(false);
  const [isCountDownComplete, setisCountDownComplete] = useState(false);
  const assessmentTrackers = useAppSelector(getAssessmentTrackers);
  const assessmentTrackersIsLoading = useAppSelector(getAssessmentTrackersIsLoading);
  // fetch assessment trackers on mount and enableCountDown change
  useEffect(() => {
    dispatch(
      fetchAssessmentTrackers({
        assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
      })
    );
  }, [dispatch, enableCountDown]);
  // useEffect to detect tracker timeout
  useEffect(() => {
    if (isCountDownComplete && enableCountDown && assessmentTrackers) {
      setshowTimeUpModal(true);
    }
  }, [isCountDownComplete, enableCountDown, dispatch, assessmentTrackerId, assessmentTrackers]);
  const getRemainingTime = () => {
    if (!enableCountDown) return -1;
    if (assessmentTrackersIsLoading) return -1;
    if (!assessmentTrackers) return -1;
    const latestAssessmentTracker = [...assessmentTrackers].sort((a, b) => b.attempt - a.attempt)?.[0];
    if (!latestAssessmentTracker?.currentTime || !latestAssessmentTracker?.startTime) return -1;
    const difference: any = latestAssessmentTracker.currentTime - latestAssessmentTracker.startTime;
    const remainingTime = 1800000 - difference;
    if (remainingTime <= 0 && !isCountDownComplete) {
      setisCountDownComplete(true);
    }
    return remainingTime;
  };
  const onTimerComplete = () => {
    setisCountDownComplete(true);
  };
  const getHeaderText = () => {
    if (!assessmentTrackers) return '';
    const latestAssessmentTracker = [...assessmentTrackers]?.sort((a, b) => b.attempt - a.attempt)?.[0];
    const headerPrefix = 'AWS AI & ML Scholarship quiz';
    if (latestAssessmentTracker?.status === QuizAssessmentStatus.COMPLETED) {
      return `${headerPrefix}: Your score`;
    }
    return headerPrefix;
  };
  const getHeaderDescription = () => {
    if (!assessmentTrackers) return '';
    const latestAssessmentTracker = [...assessmentTrackers]?.sort((a, b) => b.attempt - a.attempt)?.[0];
    switch (latestAssessmentTracker?.status) {
      case QuizAssessmentStatus.IN_PROGRESS:
        return t('headerDescription.inProgress');
    }
    switch (latestAssessmentTracker?.result) {
      case QuizAssessmentResult.PASSED:
        return t('headerDescription.passed');
      case QuizAssessmentResult.FAILED:
        switch (latestAssessmentTracker?.attempt) {
          case 1:
            return t('headerDescription.failedAttempt1');
          case 2:
            return t('headerDescription.failedAttempt2');
          case 3:
            return t('headerDescription.failedAttempt3');
        }
        return t('headerDescription.default');
      default:
        return t('headerDescription.default');
    }
  };
  const getTimeModalHeaderText = () => <>{t('timeUpModal.header')}</>;
  const getTimeModalContent = () => <>{t('timeUpModal.content')}</>;
  const handleHomeButtonClick = () => {
    if (!assessmentTrackers) return '';
    const latestAssessmentTracker = [...assessmentTrackers]?.sort((a, b) => b.attempt - a.attempt)?.[0];
    dispatch(
      finishAssessmentTracker({
        assessmentId: DR_LITE_SCHOLARSHIP_ASSESSMENT_ID,
        assessmentTrackerId: latestAssessmentTracker?.assessmentTrackerId,
      })
    );
    setshowTimeUpModal(false);
    setisCountDownComplete(false);
    history.push(pageCatalog.AssessmentResult.getPath());
  };
  return (
    <>
      <Header
        content={getHeaderText()}
        description={getHeaderDescription()}
        enableCountDown={enableCountDown}
        remainingTime={getRemainingTime()}
        onComplete={onTimerComplete}
        info={<InfoLink helpId={QUIZ_PAGE_HELP_PANEL_URL} />}
      />
      <Modal
        onDismiss={handleHomeButtonClick}
        visible={showTimeUpModal}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary" onClick={handleHomeButtonClick} disabled={false}>
                {t('timeUpModal.quizButtonLabel')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={getTimeModalHeaderText()}
      >
        {getTimeModalContent()}
      </Modal>
    </>
  );
};
