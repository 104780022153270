import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Button, Header } from '@amzn/awsui-components-react';
import { MODEL_DETAILS_HELP_PANEL_URL } from 'common/constants';
import { useGetModel } from 'store/model';
import { getTrack } from 'store/track';
import { DeepRacerLiteTypes } from 'types';

import { InfoLink } from 'common/components/HelpPanel';

import { getTrainingJobs, stopTrainingJob } from 'store/trainingJob';
import { useAppDispatch, useAppSelector } from 'store/utils/hooks';
import { isJobInTerminalState } from 'common/utils/deepracer';

export const PageHeader = (): JSX.Element => {
  const match: any = useRouteMatch('/model/:modelArn');
  const modelArn = match?.params?.modelArn;
  const { t } = useTranslation('modelDetails');
  const dispatch = useAppDispatch();
  const { data: ModelData } = useGetModel(decodeURIComponent(modelArn));
  const model = ModelData?.Model;
  const trainingJobs: any = useAppSelector(getTrainingJobs);
  const track: any = useAppSelector(getTrack);
  const [isTraining, setIsTraining] = useState<boolean>(false);

  useEffect(() => {
    if (trainingJobs?.length) {
      if (isJobInTerminalState(trainingJobs[0]?.ActivityJob?.Status?.JobStatus)) {
        setIsTraining(false);
      } else {
        setIsTraining(
          trainingJobs[0]?.ActivityJob?.Status?.JobStatus === DeepRacerLiteTypes.ActivityJobStatus.IN_PROGRESS
        );
      }
    }
  }, [dispatch, trainingJobs, track, t]);

  return (
    <Header
      variant="h1"
      actions={
        isTraining ? (
          <Button onClick={() => dispatch(stopTrainingJob(String(model?.ModelArn)))}>{t('cancelTraining')}</Button>
        ) : null
      }
      info={<InfoLink helpId={MODEL_DETAILS_HELP_PANEL_URL} />}
    >
      {model?.ModelName ? model?.ModelName : t('modelNamePlaceHolder')}
    </Header>
  );
};
