export enum CompleteSignUpFields {
  FIRSTNAME = 'firstname',
  MIDDLENAME = 'middlename',
  LASTNAME = 'lastname',
  SCHOOL = 'school',
  SCHOOL_ENTERED = 'schoolEntered',
  MAJOR = 'major',
  MAJOR_ENTERED = 'majorEntered',
  YEAR_OF_GRAD = 'yearOfGraduation',
  COUNTRY = 'country',
  SELF_CERTIFIED = 'selfCertified',
  GENDER = 'gender',
  GENDER_ENTERED = 'genderEntered',
  RACE = 'race',
  RACE_ENTERED = 'raceEnter',
  OPT_IN = 'optIn',
}

export const OTHER_VAL = 'OTHER';
export const EDUCATOR_VAL = 'N/A - not applicable';
export const UNKNOWN_OPTION = {
  label: 'I prefer not to say',
  value: 'UNKNOWN',
};

// TODO: move labels to translation
export const SELF_IDENTIFY_OPTION = {
  label: 'I prefer to self identify (Select to type)',
  value: OTHER_VAL,
};
export const GENDER_LIST = [
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Non-binary',
    value: 'Non-binary',
  },
  {
    label: 'Transgender',
    value: 'Transgender',
  },
  {
    label: 'Intersex',
    value: 'Intersex',
  },
  UNKNOWN_OPTION,
  SELF_IDENTIFY_OPTION,
];

export const RACE_LIST = [
  {
    label: 'American Indian or Alaskan Native',
    value: 'American Indian or Alaskan Native',
  },
  {
    label: 'Asian',
    value: 'Asian',
  },
  {
    label: 'Black or African American',
    value: 'Black or African American',
  },
  {
    label: 'Hispanic or Latino',
    value: 'Hispanic or Latino',
  },
  {
    label: 'Native Hawaiian or other Pacific Islander',
    value: 'Native Hawaiian or other Pacific Islander',
  },
  {
    label: 'White',
    value: 'White',
  },
  UNKNOWN_OPTION,
  SELF_IDENTIFY_OPTION,
];
