import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { NOTIFICATIONS_SLICE } from 'common/constants';

export interface Notification extends FlashbarProps.MessageDefinition {
  key: string;
  autoDismiss?: boolean;
}

export interface NotificationState {
  items: Notification[];
}

export const initialState: NotificationState = {
  items: [],
};

const notificationsSlice = createSlice({
  name: NOTIFICATIONS_SLICE,
  initialState,
  reducers: {
    displayNotification: (state, action: PayloadAction<Omit<Notification, 'key'>>) => {
      state.items.push({ key: uuidv4(), ...action.payload });
    },
    displaySuccessNotification: (state, action: PayloadAction<Omit<Notification, 'key'>>) => {
      state.items.push({ type: 'success', dismissible: true, key: uuidv4(), ...action.payload });
    },
    displayErrorNotification: (state, action: PayloadAction<Omit<Notification, 'key'>>) => {
      if (!action?.payload?.content) return state;
      state.items.push({ type: 'error', dismissible: true, key: uuidv4(), ...action.payload });
    },
    displayInfoNotification: (state, action: PayloadAction<Omit<Notification, 'key'>>) => {
      state.items.push({ type: 'info', dismissible: true, key: uuidv4(), ...action.payload });
    },
    displayWarningNotification: (state, action: PayloadAction<Omit<Notification, 'key'>>) => {
      state.items.push({ type: 'warning', dismissible: true, key: uuidv4(), ...action.payload });
    },
    removeNotification: (state, action: PayloadAction<Notification>) => {
      state.items = state.items.filter((item: Notification) => item.key !== action.payload.key);
    },
    clearAllNotifications: (state) => {
      state.items = [];
    },
  },
});

export const {
  clearAllNotifications,
  displayErrorNotification,
  displayInfoNotification,
  displayNotification,
  displaySuccessNotification,
  displayWarningNotification,
  removeNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
